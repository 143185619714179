import styled from '@emotion/styled'
import { Form } from 'antd'
import { Box, Input, Text } from 'components'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import CardInput from './CardInput'
import ChangePaymentInfo from './ChangePaymentInfo'

interface Props {
  numberChange: any
  expirationChange: any
  cvcChange: any
  nameChange: any
  name: any
  customerCard: any
}

const PaymentBox = ({ numberChange, expirationChange, cvcChange, nameChange, name, customerCard }: Props) => {
  return (
    <Box mt={16} pv={24} ph={32} style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}>
      {AppStore.loggedUser.stripe?.billings !== undefined ? (
        <>
          <Box mt={16}>
            <Box row>
              <Box flex>
                <Text>{T.settings.paymentDetails} </Text>
              </Box>
              <ChangePaymentInfo
                handleCardCVCChange={cvcChange}
                handleCardExpirationChange={expirationChange}
                handleCardNumberChange={numberChange}
                changeName={nameChange}
              />
            </Box>
            <Separator />
            <Form.Item rules={[{ required: true }]}>
              <StyledInput disabled label={T.settings.nameSurnameAccountholder} value={name} />
            </Form.Item>
            <Form.Item rules={[{ required: true }]}>
              <StyledInput disabled placeholder={customerCard?.number} pattern="[\d| ]{16,22}" />
            </Form.Item>
          </Box>
          <Box
            center
            style={{
              position: 'absolute',
              bottom: -96,
              zIndex: 9999999,
              borderRadius: 20,
            }}
            height={100}
            width={'100%'}
          ></Box>
        </>
      ) : (
        <CardInput
          handleCardCVCChange={cvcChange}
          handleCardExpirationChange={expirationChange}
          handleCardNumberChange={numberChange}
          changeName={nameChange}
          onChangeChanges
        />
      )}
    </Box>
  )
}

export default PaymentBox

const StyledInput = styled(Input)`
  margin-bottom: 12px;
  padding-left: 16px !important;
  font-size: 14px !important;
`

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`
