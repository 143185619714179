import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import { Box, LoginCard, LoginTabs, SignupCard, ZoomedContainer } from 'components'
import { useEffect, useState } from 'react'
import { T, __ } from 'translation/i18n'
import { AppStore } from 'utils'
import {
  TRACKEREVENTS,
  getBrowserProperties,
  getDeviceProperties,
  getLocationProperties,
  getRefUrlProperties,
  getUtmProperties,
} from 'utils/eventsTracker'

const backgroundImages = ['https://asters-general.s3.eu-central-1.amazonaws.com/Asters-background.jpg']

export const LoginPage = (props) => {
  const { loggedUser, onLoginCallback } = props
  const [activeTab, setActiveTab] = useState(AppStore.parampsOption?.whereToRedirect === 'signUp' ? 1 : 0)
  const [activeBackground, setActiveBackground] = useState(Math.floor(Math.random() * backgroundImages.length))

  const changeLang = async () => {
    await localStorage.setItem('firstTime', 'false')
    const lang = navigator.language
    if (lang.split('-')[0] === 'it') {
      await localStorage.setItem('defaultLang', 'it')
    } else {
      await localStorage.setItem('defaultLang', 'en')
      history.go(0)
    }
  }

  const trackSoftwareLaunched = async () => {
    const softwareLaunchTrackProperties = {
      ...getUtmProperties(),
      ...getRefUrlProperties(),
      ...getBrowserProperties(),
      ...getDeviceProperties(),
      ...(await getLocationProperties()),
    }

    Tracker.trackEvent(TRACKEREVENTS.SOFTWARE_LAUNCHED, softwareLaunchTrackProperties)
  }
  //change background image every 5 seconds
  const changeBackground = () => {
    setTimeout(() => {
      setActiveBackground((prev) => (prev + 1) % backgroundImages.length)
      changeBackground()
    }, 10000)
  }

  useEffect(() => {
    backgroundImages.forEach((backgroundImage) => {
      const img = new Image()
      img.src = backgroundImage
    })
    const firstTime = localStorage.getItem('firstTime') ?? 'true'
    if (firstTime === 'true') changeLang()
    changeBackground()

    trackSoftwareLaunched()
  }, [])

  return (
    <LoginPageContainer background={backgroundImages[activeBackground]}>
      <ZoomedContainer>
        {/* ------------------------ LOGIN / REGISTER TABS */}
        <LoginTabs
          active={activeTab}
          options={
            [
              // {
              //   text: __(T.misc.signin),
              //   onClick: () => {
              //     if (activeTab === 1) {
              //       setActiveTab(0)
              //       SignUpAnimation.swapFromSignUpToLogin('FullLogInCard', 'FullSingUpCard')
              //     }e
              //   },
              // },
              // {
              //   text: __(T.misc.signup),
              //   onClick: () => {
              //     if (activeTab === 0) {
              //       setActiveTab(1)
              //       SignUpAnimation.swapFromLoginToSignUp('FullLogInCard', 'FullSingUpCard')
              //     }
              //   },
              // },
            ]
          }
        />

        {/* ------------------------ LOGIN / REGISTER CARDS */}
        <CardContainer>
          <Box>
            <Box
              id={'FullLogInCard'}
              flex
              style={{ position: 'absolute', minWidth: 420, top: 0, x: activeTab === 0 ? 0 : -500 }}
            >
              <LoginCard onLoginCallback={onLoginCallback} onChangeSelected={() => setActiveTab(1)} />
            </Box>

            {/* <Box
              id={'FullSingUpCard'}
              flex
              style={{ position: 'absolute', minWidth: 420, top: 0, x: activeTab === 0 ? +500 : 0 }}
            >
              <SignupCard onLoginCallback={onLoginCallback} onChangeSelected={() => setActiveTab(1)} />
            </Box> */}
          </Box>
        </CardContainer>
      </ZoomedContainer>
    </LoginPageContainer>
  )
}

const LoginPageContainer = styled.div<{ background }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center !important;
  align-items: center !important;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`

const CardContainer = styled.div`
  display: flex;
  padding: 20px 40px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 16px;
  box-shadow: 0px 20px 20px 0px rgb(87 87 87 / 20%);
  min-width: 500px;
  min-height: 400px;
  position: relative;
  overflow: hidden;
  /* background-color: red; */
`
