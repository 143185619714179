import { Media } from 'types'
import { IPostData } from 'types/PostInterface'
import { socialCheckConfig } from 'utils/apiSocialCheckConfig/SocialCheckConfig'

// Valori di base di un post quando lo si crea / resetta
const DEFAULT_POST = {
  _id: 'GLOBAL',
  text: '',
  date: undefined,
  medias: [] as Media[] | [],
} as IPostData

// Numero massimo di caratteri per testi su preview
const { instagram, facebook, x, linkedin, tiktok, youtube } = socialCheckConfig

const MAX_CHARS = {
  instagram: instagram.maxChars,
  facebook: facebook.maxChars,
  twitter: x.maxChars,
  linkedin: linkedin.maxChars,
  tiktok: tiktok.maxChars,
  youtube: youtube.maxTitleChars,
}

const WHITELIST_URL_FUNCTIONALITIES_PROVIDERS = ['facebook', 'linkedin', 'x']

const DEFAULT_URL = {
  id: '',
  shortUrl: '',
  originalUrl: '',
  shortened: false,
  utmConfig: {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  },
  metaData: {
    visible: false,
    title: '',
    description: '',
    image: '',
    favicon: '',
    type: '',
    url: '',
  },
}

// Posizione della navbar
const POST_NAVBAR_ABSOLUTE_TOP = '40%'
const POST_NAVBAR_ABSOLUTE_RIGHT = '3%'

// Dimensioni delle sezioni
// Preview
const PREVIEW_DEFAULT_WIDTH = 923
const PREVIEW_INSTAGRAM_STORY_WIDTH = 438
const PREVIEW_DEFAULT_HEIGHT = 726

// Preview story / reels singoli
const PREVIEW_DEFAULT_WIDTH_ONLY_POST_PREVIEW = 438

// AITexts
const AI_TEXT_DEFAULT_WIDTH = 932
const AI_TEXT_TEXT_DEFAULT_HEIGHT = 564
const AI_TEXT_LINK_DEFAULT_HEIGHT = 504

// Medias
const MEDIAS_DEFAULT_WIDTH = 932

// Extras
const EXTRAS_DEFAULT_WIDTH = 1043

// Socials
const SOCIALS_DEFAULT_WIDTH = 1043

//Sections minor styling constants
const SECTIONS_PADDING = '32px 32px'
const SECTIONS_BORDER_RADIUS = '14px'

const BRAND_VOICE_SOCIALS = ['facebook', 'instagram', 'linkedin']

export {
  AI_TEXT_DEFAULT_WIDTH,
  AI_TEXT_LINK_DEFAULT_HEIGHT,
  AI_TEXT_TEXT_DEFAULT_HEIGHT,
  DEFAULT_POST,
  DEFAULT_URL,
  EXTRAS_DEFAULT_WIDTH,
  MAX_CHARS,
  MEDIAS_DEFAULT_WIDTH,
  POST_NAVBAR_ABSOLUTE_RIGHT,
  POST_NAVBAR_ABSOLUTE_TOP,
  PREVIEW_DEFAULT_HEIGHT,
  PREVIEW_DEFAULT_WIDTH,
  PREVIEW_DEFAULT_WIDTH_ONLY_POST_PREVIEW,
  PREVIEW_INSTAGRAM_STORY_WIDTH,
  SOCIALS_DEFAULT_WIDTH,
  SECTIONS_PADDING,
  SECTIONS_BORDER_RADIUS,
  WHITELIST_URL_FUNCTIONALITIES_PROVIDERS,
  BRAND_VOICE_SOCIALS,
}
