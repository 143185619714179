import styled from '@emotion/styled'
import { Box, Button, FolderModal, Icons, Input, Text } from 'components'
import { AppStore, T, showInfo } from 'utils'
import GigaCounter from './GigaCounter'
import Utils from 'api/Utils'
import { Component } from 'react'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getRefUrlProperties } from 'utils/eventsTracker'
import {
  OWNER_STORAGE_LIMITS_AVAILABLE,
  OWNER_STORAGE_LIMITS_INFINITE,
  OWNER_STORAGE_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'

interface Props {
  onFileChange: (e: any) => void
  inputFileRef: any
  customInputOnChange: (e: any) => void
  refreshMedia: () => void
  path: string
  isModal?: boolean
  background: any
  openEditor: () => void
}

interface State {
  searchText: string
}

export default class MediaHeader extends Component<Props, State> {
  state: State = {
    searchText: '',
  }
  currentURL = window.location.href
  sanitizedURL = this.currentURL.replace(/https?:\/\/[^/]+/, '')

  handleMediaUpload = async () => {
    const check = await AppStore.checkPermission('upload_media')

    if (!OWNER_STORAGE_LIMITS_INFINITE) {
      if (OWNER_STORAGE_LIMITS_USED >= OWNER_STORAGE_LIMITS_AVAILABLE) {
        return showInfo(T.limitUser.max_reached_storage)
      }
    }

    if (check) {
      Tracker.trackEvent(TRACKEREVENTS.UPLOAD_FILE_INITIATED, getRefUrlProperties(this.sanitizedURL))
      this.props.inputFileRef?.current?.click()
    }
  }

  render() {
    const { onFileChange, inputFileRef, customInputOnChange, path, refreshMedia, isModal, background, openEditor } =
      this.props
    return (
      <Box row pt={40} mh={60}>
        <Box row>
          <CustomInput
            aiTag
            aiTagComplete={false}
            aiTagDirection={'right'}
            placeholder={T.postPage.search}
            margin={'0 0 0 18px'}
            prefix={<Icons.search fill={AppStore.theme.o.primary} />}
            onChange={(s: string) => this.setState({ searchText: s })}
            onKeyPress={(e) => {
              const keyCode = e.code || e.key
              if (keyCode == 'Enter') {
                customInputOnChange(this.state.searchText)
              }
            }}
          />
        </Box>

        <input
          multiple
          style={{ display: 'none' }}
          accept="video/*,image/*"
          onChange={onFileChange}
          ref={inputFileRef}
          type="file"
        />

        <Button
          id="upload-media-button"
          ml={25}
          ph={20}
          size="small"
          variant="neutral"
          onClick={this.handleMediaUpload}
          hideBoxShadowOnHover
        >
          <Icons.uploadFile fill={AppStore.theme.o.black} />

          <Text>{T.mediaPage.loadFile}</Text>
        </Button>

        <Button
          id="create-folder-button"
          ml={24}
          ph={20}
          size="small"
          variant="neutral"
          hideBoxShadowOnHover
          onClick={async () => {
            const check = await AppStore.checkPermission('manage_folders')
            if (!check) return
            else {
              Tracker.trackEvent(TRACKEREVENTS.CREATE_FOLDER_INITIATED, {})
              AppStore.openModal({
                id: 'folder-modal',
                body: <FolderModal onConfirm={refreshMedia} path={path} />,
              })
            }
          }}
        >
          <Icons.createFolderImgSection fill={AppStore.theme.o.black} style={{ marginRight: 8 }} />

          <Text>{T.mediaPage.createFolder}</Text>
        </Button>

        {!isModal && (
          <Button
            id="open-template-media"
            ml={25}
            ph={48}
            size="small"
            variant="neutral"
            style={{
              backgroundImage: `url(${background})`,
              objectFit: 'scale-down',
              backgroundSize: '110%',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            onClick={async () => {
              const check = await AppStore.checkPermission('media_editor')
              if (check) {
                // @ts-ignore
                this.setState({ selectedMedias: [] }, () => openEditor())
                Utils.updateUserEditorCount()
              }
            }}
            hideBoxShadowOnHover
          >
            <Icons.imgFolderMark style={{ marginRight: 4, width: 38, height: 38 }} />
            <Text style={{ color: 'white', marginRight: 8 }} weight={700}>
              {T.mediaPage.openEditor}
            </Text>
          </Button>
        )}

        {!isModal && <GigaCounter />}
      </Box>
    )
  }
}

const CustomInput = styled(Input)`
  background: ${() => AppStore.theme.o.grey};
  border-radius: 14px;
  width: 450px;
`
