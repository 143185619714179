import styled from '@emotion/styled'
import ApiKeys from 'api/ApiKeys'
import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import InfiniteLoader from 'pages/ai-discover/UI/InfiniteLoader'
import { useState } from 'react'
import { AppStore, T, showWarning } from 'utils'
import AutopilotCreateKey from './AutopilotCreateKey'
import AutopilotYourKeyCreated from './AutopilotYourKeyCreated'

interface Props {
  setterStateBol: () => void // serve a refreshare le API key
}

const AutopilotGenerateKeyModal = ({ setterStateBol }: Props) => {
  /* -------------------- STATE -------------------- */
  const [selectedScope, setSelectedScope] = useState<{ id: string; label: string; icon: any; selected: boolean }[]>([
    {
      id: 'analytics',
      label: T.headerAutopilotAndModal.analytics,
      icon: <Icons.analytics fill={'currentColor'} height={24} width={24} />,
      selected: false,
    },
    {
      id: 'autopilot',
      label: T.headerAutopilotAndModal.autopilot,
      icon: <Icons.autopilotElectric fill="currentColor" height={24} width={24} />,
      selected: false,
    },
  ])

  const [loadingGenerateKey, setLoadingGenerateKey] = useState<boolean>(false)
  const [generatedKey, setGeneratedKey] = useState<string>('')

  /* -------------------- METHODS -------------------- */
  const onChangeSelectedScope = (id: string) =>
    setSelectedScope((prev) =>
      prev.map((scope) => ({ ...scope, selected: scope.id === id ? !scope.selected : scope.selected }))
    )

  const onGenerateKey = async (inputNameValue: string) => {
    // INFO -->  The value of 'inputNameValue' is being controlled in the component 'AutopilotCreateKey.tsx'.

    // INFO --> value to send to the api ( apiKeyName, scopeSelected)
    const apiKeyName = inputNameValue
    const scopeSelected = selectedScope.find((scope) => scope.selected)

    // Check if a scope is selected
    if (!scopeSelected) {
      showWarning(T.headerAutopilotAndModal.warning.selectScope)
      return
    }
    setLoadingGenerateKey(true)

    const normScopes = selectedScope.map((scope) => ({ id: scope.id, label: scope.label }))
    const generateResult = await ApiKeys.createApiKey(apiKeyName, AppStore.workspaceId!, normScopes)
    const generateKeyString: string = generateResult.data.key.key

    // INFO --> Change component ( from AutopilotCreateKey to AutopilotYourKeyCreated)
    setTimeout(() => {
      setGeneratedKey(generateKeyString)
      setLoadingGenerateKey(false)
      setterStateBol()
    }, 200)
  }
  return (
    <ContainerAutopilotGenerateKey>
      {/* ---------------------- FORM GENERATE KEY  */}
      {!generatedKey && !loadingGenerateKey && (
        <AutopilotCreateKey
          selectedScope={selectedScope}
          onChangeSelectedScope={onChangeSelectedScope}
          onGenerateKey={onGenerateKey}
        />
      )}

      {/* ---------------------- LOADER  */}
      {loadingGenerateKey && (
        <InfiniteLoaderContainer>
          <InfiniteLoader />
        </InfiniteLoaderContainer>
      )}

      {/* ---------------------- GENERATED KEY  */}
      {generatedKey && !loadingGenerateKey && <AutopilotYourKeyCreated apiKeyAutopilot={generatedKey} />}
    </ContainerAutopilotGenerateKey>
  )
}

export default AutopilotGenerateKeyModal

const ContainerAutopilotGenerateKey = styled(Box)`
  position: relative;
  width: 671px;
  min-height: 253px;
  max-height: 253px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 14px;
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const InfiniteLoaderContainer = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`
