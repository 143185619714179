import { CreatePostPage } from 'pages/create-post/CreatePostPage'

const CretePostRouter = [
  //  ------ PREVIEW
  {
    path: '/post/preview/basic',
    element: <CreatePostPage />,
  },
  {
    path: '/post/preview/basic/:socialsIds',
    element: <CreatePostPage />,
  },
  {
    path: '/post/preview/drafts',
    element: <CreatePostPage />,
  },
  {
    path: '/post/preview/templates',
    element: <CreatePostPage />,
  },

  //  ------  AI TEXTS
  {
    path: '/post/text/astersAiText',
    element: <CreatePostPage />,
  },
  {
    path: '/post/text/astersAiText/:text',
    element: <CreatePostPage />,
  },
  {
    path: '/post/text/astersAiLink',
    element: <CreatePostPage />,
  },
  {
    path: '/post/text/astersAiLink/:text',
    element: <CreatePostPage />,
  },
  {
    path: '/post/text/templates',
    element: <CreatePostPage />,
  },

  //  ------  MEDIAS
  {
    path: '/post/media',
    element: <CreatePostPage />,
  },

  {
    path: '/post/media/selected/:mediasIds', //Da vedere in futuro se vogliamo che i medias passino ids e siano già selezionati
    element: <CreatePostPage />,
  },

  //  ------  EXTRAS
  {
    path: '/post/extra',
    element: <CreatePostPage />,
  },

  //  ------  SOCIALS
  {
    path: '/post/socials',
    element: <CreatePostPage />,
  },
  {
    path: '/post/socials/:socialIds',
    element: <CreatePostPage />,
  },
]

export { CretePostRouter }
