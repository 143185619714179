import localeEn from 'antd/es/date-picker/locale/en_US'
import localeIt from 'antd/es/date-picker/locale/it_IT'
import Auth from 'api/Auth'
import Medias from 'api/Medias'
import NotifyPermission from 'api/NotifyPermission'
import RemoteConfig from 'api/RemoteConfig'
import Utils, { RemoteConfigValues } from 'api/Utils'
import Workspaces from 'api/Workspaces'
import { api } from 'api/api'
import { darkTheme, lightTheme } from 'config/themes'
import { IAiDiscover, IArticle, IBoard } from 'pages/ai-discover/aiDiscoverInterface'
import { useAppStore } from 'stores/AppStore'
import { Media, ModalType, ParamsOptions, Post, SocialAccount, User, Workspace } from 'types'
import { IAllNotification, INotifications } from 'types/NotifyInterface'
import { TPermission } from 'types/Permissions'
import { initCohere, initCustomerly, initFB, initUsetiful } from 'utils'
import { getWorkspaceOwnerData } from './Constants/LimitUserConstants'
import { checkIfUserIsPayed, validateSubscription } from './UsersUtils'
import { history, refresh, reloadApp } from './router'
import Socialaccounts from 'api/Socialaccounts'

const isDarkTheme = (): boolean => {
  return (localStorage.getItem('darkTheme') ?? 'true') === 'true'
}

class AppStore {
  token?: string | null
  loggedUser!: User
  discoverUser!: IAiDiscover
  discoverUserBoardList: IBoard[] = []
  discoverTrendArticleList?: IArticle[]
  workspace!: Workspace
  workspaceId!: string | null
  workspaceNotifications!: INotifications[]
  allWorkspacesNotifications!: IAllNotification[]
  storageUsed?: any
  darkTheme: boolean = isDarkTheme()
  theme = isDarkTheme() ? darkTheme : lightTheme
  zoom: any = (window.innerWidth / 1920) * 100
  socialAccounts: SocialAccount[] = []
  locale = this.getLanguageValue() === 'it' ? localeIt : localeEn
  mySubscription?: any
  activeSocial?: SocialAccount
  activePost?: Post
  activeIndex?: number
  lastTextPropt?: string
  userValidated?: boolean
  socialNearToExpire: string[] = []
  socialAccountList: SocialAccount[] = []
  generatedTexts: {
    text: string
    name: string
    like: boolean
    isNotLike: boolean
    saved: boolean
    modelId: string

    socialAcc: string
    ArgumentType: string
    toneofVoice: string
    From: string
    toLanguage: string
    generatedTexts: string
    feedbackNotes: string
    feedbackId?: string
  }[] = []
  notAllowedSections: string[] = [] // Sections not allowed for the user
  linkGeneratedTexts: { text: string; media: Media }[] = []

  linkGeneratedTextsArray: {
    text: string
    name: string
    like: boolean
    isNotLike: boolean
    saved: boolean
    modelId: string

    socialAcc: string
    ArgumentType: string
    toneofVoice: string
    From: string
    toLanguage: string
    generatedTexts: string
    feedbackNotes: string
    feedbackId?: string
  }[] = []
  referralCode?: string
  upgrading?: boolean
  parampsOption?: ParamsOptions
  settings: {
    facebook: { appId: string }
    linkedin: { clientId: string }
    vistaCrello: { apiKey: string }
    stripe: { apiKey: string }
  } = {} as any

  setActiveMenu!: (active: number) => void
  setActivePathMenu!: (path: string) => void
  //create stack of callback when resize
  resizeCallbacks: { id: string; callback: any }[] = []
  addResizeCallback(callback: any, id = new Date().toISOString()) {
    this.resizeCallbacks.push({ id, callback })
  }
  removeResizeCallback(id: string) {
    this.resizeCallbacks = this.resizeCallbacks.filter((cb) => cb.id !== id)
  }

  openModal!: (modal: ModalType) => void
  closeModal!: (id: string) => void
  closeAllModal!: () => void

  reloadRouting!: () => void

  initProviders = () => {
    setTimeout(() => {
      initFB()
      initUsetiful()
      if (!!this.loggedUser) {
        initCustomerly()
        initCohere()
      }
    }, 1000)
  }

  /*
   * @Deprecated
   * Controlla validità sottoscrizione
   */

  validateSubscription = () => {
    validateSubscription
    if (!checkIfUserIsPayed(this.loggedUser)) {
      history.replace('/settings/subscription')
      throw new Error('User is not payed')
    }
  }

  workspacesSetNotifications = async (allWorkspace: Workspace[]) => {
    try {
      allWorkspace.forEach(async (w) => {
        if (w.user === this.loggedUser._id) {
          if (!w?.notificationsPermission) {
            await NotifyPermission.createNotificationSetting(w._id!)
          }
        } else {
          if (w?.collaborators !== undefined) {
            const collaborator = w?.collaborators?.find((c) => {
              return c?.user?._id === this.loggedUser._id
            })
            if (collaborator?.notificationsPermission === undefined) {
              await NotifyPermission.createNotificationSetting(w._id!)
            }
          }
        }
      })
    } catch (e) {
      console.error(e)
    }
  }

  async init() {
    const { setActiveWorkspace } = useAppStore.getState()

    this.token = await localStorage.getItem('token')
    this.workspaceId = await localStorage.getItem('workspaceId')

    if (this.token) {
      this.darkTheme = await isDarkTheme()
      this.theme = this.darkTheme ? darkTheme : lightTheme
      api.setHeader('Authorization', `Bearer ${this.token}`)

      try {
        this.loggedUser = await Auth.loggedUser()

        this.notAllowedSections = await RemoteConfig.verifyAll(this.loggedUser.email)
        this.userValidated = !this.loggedUser?.temp_token
        if (this.loggedUser?.isSocial) this.userValidated = true

        this.settings = (await Utils.settings()).data

        // @deprecated -> guarda UsersUtils.ts
        // this.validateSubscription()

        const allWorkspace = [
          ...(this.loggedUser?.workspaces ?? []),
          ...(this.loggedUser?.collabWorkspace?.map((w) => w.workspace) ?? []),
        ]

        let activeWorkspace = this.workspaceId
          ? [...this.loggedUser.workspaces, ...this.loggedUser?.collabWorkspace?.map((w) => w.workspace)].find(
              (w) => w._id === this.workspaceId
            ) ??
            this.loggedUser.workspaces[0] ??
            this.loggedUser.collabWorkspace[0].workspace
          : this.loggedUser.workspaces[0]

        /* The above TypeScript code is checking if the last visit time is more than 2 hours ago by
        comparing the current time with the time stored in the 'lastSocialCheck' item in the localStorage.
        If the condition is met, it then attempts to refresh the access token for social accounts
        associated with the active workspace. If successful, it updates the 'lastSocialCheck' time in the
        localStorage to the current time. If there is an error during the token refresh process, it
        logs the error message to the console. */
        if (
          localStorage.getItem('lastSocialCheck') &&
          ((new Date().getTime() - new Date(localStorage.getItem('lastSocialCheck') ?? new Date()).getTime()) /
            (1000 * 60 * 60) >
            2 ||
            localStorage.getItem('socialCheck') === 'true')
        ) {
          console.log('entrato nel check social')
          try {
            await Socialaccounts.checkRefreshToken(this.socialAccountList, activeWorkspace._id)
            localStorage.removeItem('socialCheck')
            localStorage.setItem('lastSocialCheck', new Date().toISOString())
          } catch (error) {
            console.error('Failed check social account validation', error)
          }
        }

        !this.notAllowedSections?.some((s) => s === RemoteConfigValues.NotificationDisabled) &&
          this.workspacesSetNotifications(allWorkspace)

        await getWorkspaceOwnerData({ userId: activeWorkspace.owner._id, workspaceId: activeWorkspace._id })

        // Verify if user has permission on active workspace
        const checkActiveWorkspace = await Workspaces.checkPermission(undefined, activeWorkspace._id)
        if (!checkActiveWorkspace) activeWorkspace = this.loggedUser.workspaces[0]

        this.workspace = activeWorkspace

        this.workspaceId = activeWorkspace._id!

        setActiveWorkspace(activeWorkspace)

        this.storageUsed = await (await Medias.getFolderAWS(this.workspace._id ?? '')).data

        await this.refreshSocialaccounts()

        if (this.loggedUser?.mixpanelRegister === undefined) {
          await Auth.mixpanelRegister()
          this.loggedUser.mixpanelRegister = true
        }
      } catch (error) {
        this.setToken(undefined)
      }
    }

    //Init providers
    this.initProviders()
  }

  updateStorageUsed = async () => {
    this.storageUsed = await (await Medias.getFolderAWS(this.workspace._id ?? '')).data
  }

  async getAllSocialAccounts() {
    const allSocialAccounts: SocialAccount[] = []

    if (this.loggedUser.collabWorkspace) {
      await Promise.all(
        this.loggedUser?.collabWorkspace?.map(async (collab) => {
          const collabSocial = await Workspaces.getSocialAccounts(collab.workspace._id!)
          allSocialAccounts.push(...collabSocial)
        })
      )
    }

    await Promise.all(
      this.loggedUser.workspaces.map(async (workspace) => {
        const social = await Workspaces.getSocialAccounts(workspace._id!)
        allSocialAccounts.push(...social)
      })
    )

    this.socialAccountList = allSocialAccounts
  }

  async refreshSocialaccounts(res?, allSocialCall = true) {
    const socialAccounts = res ?? (await Workspaces.getSocialAccounts())

    this.socialAccounts = socialAccounts

    allSocialCall && (await this.getAllSocialAccounts())

    const allSocialsInLoggedUser = [...this.socialAccountList]

    return allSocialsInLoggedUser
  }

  async refreshLoggedUser() {
    this.loggedUser = await Auth.loggedUser()
    this.notAllowedSections = await RemoteConfig.verifyAll(this.loggedUser.email)

    if (this.loggedUser.prefineryId === undefined) {
      Auth.addPrefinery()
    }

    this.reloadRouting()
  }

  changeTheme = async () => {
    this.darkTheme = !this.darkTheme
    localStorage.setItem('darkTheme', this.darkTheme ? 'true' : 'false')

    refresh()
  }

  refreshPage = () => {
    refresh()
  }

  changeLang = async (lang: string) => {
    await localStorage.setItem('defaultLang', lang)
    refresh()
  }
  async setToken(token?: string, removeWorkspace?: boolean) {
    this.token = token
    if (token) {
      await localStorage.setItem('token', token)
      api.setHeader('Authorization', `Bearer ${token}`)
    } else {
      await localStorage.removeItem('token')
      // if (removeWorkspace === undefined) await localStorage.removeItem('workspaceId')
    }
  }

  getLanguageValue() {
    const currentLang = localStorage.getItem('defaultLang')
    return currentLang
  }

  getWorkspaceId = () => {
    localStorage.setItem('workspaceId', this.workspaceId ? this.workspaceId : this.workspace._id!)
    return this.workspaceId ? this.workspaceId : this.workspace._id!
  }

  async setActiveWorkspace(workspaceId: string | null, reload?: boolean) {
    this.workspaceId = workspaceId
    if (workspaceId) {
      await localStorage.setItem('workspaceId', workspaceId)
    } else await localStorage.removeItem('workspaceId')
    if (reload === undefined) reloadApp()
  }

  removeBlurEffect = () => {
    //get element with id menu-container and pages-container
    const menuContainer = document.getElementById('menu-container')
    const pagesContainer = document.getElementById('pages-container')
    //remove blur effect to them
    menuContainer?.classList.remove('blurred')
    pagesContainer?.classList.remove('blurred')
  }

  checkPermission = async (permission: TPermission): Promise<boolean> => {
    const checkPermission = await Workspaces.checkPermission(permission)
    if (!checkPermission) return false
    return true
  }

  downloadPdf = (fileName: string) => {
    const url = 'https://videocitta.s3.eu-central-1.amazonaws.com/dsafvbdg.pdf' // URL del tuo PDF
    const filename = `${fileName}.pdf` // Nome del file che l'utente vedrà in fase di download

    // Crea un link temporaneo nel DOM
    const link = document.createElement('a')
    link.href = url
    link.target = 'blank'
    link.setAttribute('download', filename) // Specifica il nome del file per il download

    // Aggiunge il link al DOM e lo clicca automaticamente
    document.body.appendChild(link)
    link.click()

    // Rimuove il link dal DOM
    document.body.removeChild(link)
  }
}

export default new AppStore()
