import Utils from 'api/Utils'
import { AppStore, T } from 'utils'
import { Box, Button, Page, Text } from 'components'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'

export const ComingSoonComponent = () => {
  return (
    <Box flex center>
      <img src="/images/coming_soon.png" alt="logo" style={{ width: 200 }} />
      <Text weight={700} fontSize={21}>
        {T.indexPage.currentlyDevelopingComingSoon}
      </Text>
      <Text
        style={{
          width: '100ch',
          marginTop: 14,
          marginBottom: 14,
          textAlign: 'center',
          fontSize: 16,
          color: AppStore.theme.o.darkGrey,
          fontWeight: 500,
        }}
      >
        {T.indexPage.visitRoadMap}
      </Text>
      <Button
        variant="neutral"
        width={318}
        size="small"
        mt={20}
        onClick={async () => {
          try {
            const results = await Utils.getFrillUserToken({ id: AppStore.loggedUser._id })
            window.open(`https://news.asters.ai/roadmap?ssoToken=${results}`, '_blank')
            Tracker.trackEvent(TRACKEREVENTS.ROADMAP_VISITED, {})
          } catch (error) {}
        }}
      >
        {T.indexPage.publicRoadMap}
      </Button>
    </Box>
  )
}
