//? LINK TASK VALIDAZIONE POST CON FILE PDF
//? https://asters-ai.atlassian.net/issues/AS-567?jql=text%20~%20%22validation%22%20ORDER%20BY%20created%20DESC

//? Link con probabile info sui media per social
//? https://docs.ayrshare.com/additional-info/image-and-video-requirements

import { Media } from 'types'

export const socialCheckConfig = {
  // ------------- BASIC FUNCTIONS -------------
  /**
   * @param medias - Array of media objects
   * @param text - String of text
   * @returns {boolean} - True if both medias and text are empty
   */
  isEmpty: (medias: Media[], text: string) => {
    return medias.length === 0 && text.length === 0
  },

  /**
   * @param medias - Array of media objects
   * @returns {boolean} - True if media array contains video and images
   */
  containsPhotoAndVideo: (medias: Media[]) => {
    if (medias.length < 2) return false

    const hasImage = medias.some((media) => media.metadata.fileType === 'image')
    const hasVideo = medias.some((media) => media.metadata.fileType === 'video')

    return hasImage && hasVideo
  },

  /**
   * @param text Testo del post
   * @param maxChars Massimo numero di caratteri accettati dal social
   * @returns true se il testo è minore o uguale al massimo numero di caratteri
   */
  checkTextLength: (text: string, maxChars: number) => {
    return text.length <= maxChars
  },

  /**
   * @param mediaExtension - Extension of the media
   * @param validExtensions - Array of valid extensions
   * @returns {boolean} - True if media extension is valid
   */
  checkValidExtension: (mediaExtension: string, validExtensions: string[]) => {
    return validExtensions.includes(mediaExtension)
  },

  /**
   * @param mediaSize - Size of the media
   * @param maxSize - Max size allowed
   * @returns {boolean} - True if media size is less than max size
   */
  checkSize: (mediaSize: number, maxSize: number) => {
    return mediaSize <= maxSize
  },

  /**
   * @param dimension - Dimension (width or height) of the media
   * @param min - Min Dimension (width or height) allowed
   * @param max - Max Dimension (width or height) allowed
   * @returns {boolean} - True if media is between min and max Dimension (width or height)
   */
  checkDimension: (dimension: number, min: number, max: number) => {
    return dimension >= min && dimension <= max
  },

  /**
   * @param aspectRatio - AspectRatio of the media
   * @param min - Min AspectRatio allowed
   * @param max - Max AspectRatio allowed
   * @returns {boolean} - True if media is between min and max AspectRatio
   */
  checkRatioBetween(aspectRatio: string, min: string, max: string) {
    const parseRatio = (ratio) => {
      const [numerator, denominator] = ratio.split(':').map(parseFloat)
      return numerator / denominator
    }

    const minRateo = parseRatio(min)
    const maxRateo = parseRatio(max)
    const target = parseRatio(aspectRatio)

    return target >= minRateo && target <= maxRateo
  },

  /**
   * @param aspectRatio - AspectRatio of the media
   * @param acceptedRatios - Array of accepted AspectRatios
   * @returns {boolean} - True if media AspectRatio is in the accepted AspectRatios
   */
  checkRatioAccepted: (aspectRatio: string, acceptedRatios: string[]) => {
    return acceptedRatios.includes(aspectRatio)
  },

  /**
   * @param fps - Frame per second of the video
   * @param min - Min fps allowed
   * @param max - Max fps allowed
   * @returns {boolean} - True if fps is between min and max fps
   */
  checkFps: (fps: number, min: number, max: number) => {
    return fps >= min && fps <= max
  },

  /**
   * @param duration - Duration of the video
   * @param min - Min duration allowed
   * @param max - Max duration allowed
   * @returns {boolean} - True if duration is between min and max duration
   */
  checkDuration: (duration: number, min: number, max: number) => {
    return duration >= min && duration <= max
  },

  /**
   * @param bitrate - Bitrate of the video
   * @param min - Min bitrate allowed
   * @param max - Max bitrate allowed
   * @returns {boolean} - True if bitrate is between min and max bitrate
   */
  checkBitrate: (bitrate: number, min: number, max: number) => {
    return bitrate >= min && bitrate <= max
  },

  /**
   * Controlla che il primo media sia del tipo specificato
   * @param medias array of media objects
   * @param type  type of media (es: "image" o "video")
   * @returns true if the first media is of the specified type
   */
  checkMediasType: (medias: Media[], type: string) => {
    if (medias.length === 0) return true

    return medias[0].metadata.fileType === type
  },

  // ------------- CAROUSEL FUNCTIONS -------------
  /**
   * @param mediasNum - Number of medias in the carousel
   * @param max - Max number of medias allowed
   * @returns {boolean} - True if number of medias is equal or under the  max value
   */
  checkCarouselDimension: (medias: Media[], max: number) => {
    return medias.length <= max
  },

  /**
   * @param medias - Array of media objects
   * @returns {boolean} - True if all medias have the same aspect ratio
   */
  checkCarouselRatio: (medias: Media[]) => {
    const firstMediaRatio = medias[0].metadata.aspectRatio

    return medias.every((media) => media.metadata.aspectRatio === firstMediaRatio)
  },

  /**
   *
   * @param medias array of medias
   * @param type type of media (es: "image" o "video")
   * @param max  max number of media allowed of that type
   * @returns true if the number of media of that type is less or equal to the max
   */
  checkCarouselTypeDimension: (medias: Media[], type: string, max: number) => {
    const filteredMedias = medias.filter((media) => media.metadata.fileType === type)
    return filteredMedias.length <= max
  },

  /**
   * @param medias array of media objects
   * @returns true if the medias array contains more than 1 media
   */
  checkIfIsCarousel: (medias: Media[]) => {
    return medias.length > 1
  },

  //? Last update: 22/02/2024
  instagram: {
    maxChars: 2200,
    needMedia: true,
    needText: false,
    //? Si possono taggare solo utenti con profili pubblici
    maxPostHashtag: 30,
    maxStoryHashtag: 10,
    maxPostForDay: 50,
    maxMedia: 10, // tutti devono avere lo stesso ratio

    // IMMAGINI
    imgTypes: ['jpeg', 'png'],
    maxImgSize: 10000,
    minImgWidth: 320,
    maxImgWidth: 2048,
    minImgRatio: '4:5',
    maxImgRatio: '1.91:1',

    // VIDEO  (Asters non gestisce video ma solo reel (?))
    videoTypes: ['mp4', 'mov'],
    codecAudio: ['aac'],
    codecVideo: ['hevc', 'h264'],
    bitrateVideo: ['vbr'],
    maxVideoBitrate: 25000,
    maxVideoSize: 100000,
    minVideoFps: 23,
    maxVideoFps: 60,
    maxAudioBitrate: 128,
    minVideoWidth: 320,
    maxVideoWidth: 1920,
    minVideoDuration: 3,
    maxVideoDuration: 60,
    minVideoRatio: '4:5',
    maxVideoRatio: '16:9',

    // REEL (versione video di instagram standard)
    reelTypes: ['mp4', 'mov'],
    reelCodecAudio: ['aac'],
    reelCodecVideo: ['hevc', 'h264'],
    bitrateReel: ['vbr'],
    maxReelBitrate: 25000,
    maxReelSize: 1000000, // 1GB
    minReelWidth: 320,
    maxReelWidth: 1080,
    minReelFps: 23,
    maxReelFps: 60,
    minReelDuration: 3,
    maxReelDuration: 900,
    minReelRatio: '0.01:1',
    maxReelRatio: '10:1',

    // COVER REEL
    reelCoverTypes: ['jpeg'],
    reelCoverColorSpace: ['srgb'],
    maxReelCoverSize: 8000,

    // STORIES
    storiesTypes: ['jpeg', 'mp4', 'mov'],
    storiesSpaceColor: ['srgb'],
    storiesCodecAudio: ['aac'],
    storiesCodecVideo: ['hevc', 'h264'],
    bitrateStories: ['vbr'],
    maxStoriesBitrate: 25000,
    storiesMaxImageSize: 8000,
    minStoriesDuration: 3,
    maxStoriesDuration: 60,
    minStoriesFps: 23,
    maxStoriesFps: 60,
    maxStoriesVideoSize: 100000,
    minStoriesRatio: '0.01:1',
    maxStoriesRatio: '10:1',
  },

  //? Last update: 22/02/2024
  facebook: {
    maxChars: 4500,
    canContainPhotoAndVideo: false,
    needTextOrMedia: true,
    maxPostForDay: 50,
    maxMedia: 100, // SOLO IMG

    // IMMAGINI
    imgTypes: ['jpeg', 'png', 'gif', 'bmp'],
    maxImgSize: 10000,
    minImgWidth: 320,
    maxImgWidth: 2048,
    minImgRatio: '4:5',
    maxImgRatio: '1.91:1',

    // VIDEO
    videoTypes: ['mp4', 'mov', 'mpeg-4'],
    codecAudio: ['aac'],
    codecVideo: ['hevc', 'h264'],
    bitrateVideo: ['vbr'],
    maxVideoSize: 10000000, //10GB (recommended 4GB)
    minVideoFps: 23,
    maxVideoFps: 60,
    maxAudioBitrate: 128,
    minVideoWidth: 320,
    maxVideoWidth: 1920,
    minVideoDuration: 3,
    maxVideoDuration: 60,
    minVideoRatio: '4:5',
    maxVideoRatio: '16:9',
  },

  //? Last update: 22/02/2024
  linkedin: {
    canContainPhotoAndVideo: false,
    needTextOrMedia: true,
    maxPostForDay: 10,
    maxPostForMonth: 100,
    maxChars: 3000,
    maxMedia: 20, // Solo per foto , per video massimo 1

    // Img
    //? Della gif prende solo il primo frame
    imgTypes: ['jpeg', 'png', 'gif', 'webp'],
    maxImgSize: 5000,
    minMediaRatio: '1.9:1',
    maxMediaRatio: '4:5',

    // video
    videoTypes: ['mp4', 'mov'],
    codecVideo: ['h264', 'hevc', 'vp9'],
    maxVideoSize: 5000000, //5GB
    minVideoDuration: 3,
    maxVideoDuration: 900,
    minVideoFps: 30,
    maxVideoFps: 60,
  },

  //? Last update: 22/02/2024
  x: {
    needTextOrMedia: true,
    maxPostForDay: 2400,
    maxChars: 280,
    maxMedia: 4,

    // Img
    imgTypes: ['jpeg', 'png', 'gif', 'webp'],
    imgRatios: ['1:1', '4:5', '16:9'],
    maxImgSize: 5000,

    // Gif
    maxGifSize: 15000,
    maxGifFrame: 350,
    maxGifPixels: 300000000,

    // Video
    videoTypes: ['mp4', 'mov'],
    codecVideo: ['hevc', 'h264'],
    codecAudio: ['aac'],
    maxVideoSize: 1000000, // 1GB
    minVideoFps: 30,
    maxVideoFps: 60,
    minVideoDuration: 0.5,
    maxVideoDuration: 140,
    minVideoRatio: '1:3',
    maxVideoRatio: '3:1',
  },

  //? Last update: 22/02/2024
  youtube: {
    needMedia: true,
    onlyVideo: true,
    needText: true,
    maxPostForDay: 100,
    maxTitleChars: 100,
    maxCharsDescription: 5000,
    maxMedia: 1,

    // video
    videoTypes: ['mp4', 'm4v'],
    codecVideo: ['h264', 'mpeg-4'],
    codecAudio: ['aac'],
    minVideoRatio: '1.5:1',
    maxVideoRatio: '16:9',
    maxVideoSize: 128000000, // 12.8 GB
    minVideoFps: 24,
    maxVideoFps: 60,
    minVideoDuration: 1,
    maxVideoDuration: 900,

    // thumbnail
    thumbnailTypes: ['jpeg', 'png', 'gif', 'mbp'],
    maxThumbnailSize: 2000,
    // thumbnailRatio: '16:9',
  },

  //? Last update: 22/02/2024
  tiktok: {
    needMedia: true,
    needText: false,
    onlyVideo: true,
    maxPostForDay: 2400,
    maxChars: 280,
    maxMedia: 1,

    // Video
    videoTypes: ['mp4', 'webm', 'mov'],
    videoRatios: ['1:1', '16:9'],
    codecVideo: ['h264', 'h265'],
    maxVideoSize: 500000, // 500MB per video <10m , 2GB per video da 10m a 60m
    minVideoDuration: 3,
    maxVideoDuration: 600, // per account verificati si va a 60minuti
    minVideoFps: 23,
    maxVideoFps: 60,

    // Thumbnail
    thumbnailTypes: ['jpeg', 'png'],
    maxThumbnailSize: 2000,
    thumbnailRatios: ['1:1', '16:9'],
  },

  //TODO: Da ricontrollare quando si implementerà pinterest
  pinterest: {
    maxPostForDay: 1000,
    bustinessMaxPostForDay: 5000,
    maxTitleChars: 100,
    maxChars: 500,
    needMedia: true,
    maxMedia: 10,
    mediaTypes: ['jpeg', 'png', 'tiff', 'bmp', 'webp', 'mp4', 'm4v', 'mov'],

    isTypeWeb: (media: any) => media.mimetype.includes('web'),
    typeWebMaxSize: 20000,
    minMediaRatio: '1/1',
    maxMediaRatio: '16/9',
    checkMediaRatio: (width: number, height: number) => {
      const aspectRatio = width / height
      return aspectRatio >= 1 && aspectRatio <= 16.9
    },

    // Img
    maxImgSize: 500,

    // carousel
    carouselDimension: (mediasNum: number) => mediasNum >= 2 && mediasNum <= 5,
    carouselMediaSize: (medias: any[]) => {
      let checkMediaSize = true

      medias.forEach((media) => {
        if (media.mimetype.includes('image') && !media.mimetype.includes('web') && media.size <= 500) {
          return (checkMediaSize = false)
        }

        if (media.mimetype.includes('image') && media.mimetype.includes('web') && media.size <= 20000) {
          return (checkMediaSize = false)
        }

        if (media.mimetype.includes('video') && media.size <= 1000000) {
          return (checkMediaSize = false)
        }
      })

      return checkMediaSize
    },

    // video
    maxVideoSize: 1000000,
    codecVideo: ['h264', 'hevc'],
    minVideoFps: 24,
    maxVideoFps: 30,
    videoFps: (fps: number) => fps <= 30,
    minVideoDuration: 4,
    maxVideoDuration: 300,
    videoDuration: (duration: number) => duration >= 4 && duration <= 300,
  },
}
