/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Button } from 'components'
import { AppStore, T } from 'utils'

/* ------------------- INTERFACES  */

interface Props {}

export const GetDemoButton = ({}: Props) => {
  return (
    <GetDemoButtonContainer
      className="enterprise-demo-request"
      animation="slide"
      size="small"
      disabled={AppStore.loggedUser.enterprise}
    >
      {AppStore.loggedUser.enterprise ? T.plans.alreadyInDemo : T.plans.getDemo}
    </GetDemoButtonContainer>
  )
}

const GetDemoButtonContainer = styled(Button)`
  width: fit-content;
  align-self: center;
  padding: 8px 28px;
  margin-bottom: 16px;
  border-radius: 14px;
  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
    color: ${() => AppStore.theme.o.blueOnLight};
    box-shadow: none;
  }
`
