import styled from '@emotion/styled'

import InstagramPiningModal from 'components/CreatePost/ExtraComponents/InstagramExtras/InstagramPiningModal'
import Emoji from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/GenericComponents/TextInfo/Emoji'
import { UtmModal } from 'components/CreatePost/PreviewComponents/BasicComponents/BasicContentComponents/GenericComponents/Utm/UtmModal'
import { Template } from 'components/CreatePost/TextComponents/Template/Template'
import { AnimatedPlaceholderLogo, Box } from 'components'
import { DraftModal } from 'components/Modal/DraftModal'
import { ConfirmModal, LanguageBox } from 'components/UI'
import CustomMediaModal from 'pages/media/CustomMediaModal'
import MediaTemplateModal from 'pages/media/MediaTemplateModal'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { CrelloResponse, Media } from 'types'
import { IPostData, IPostVariant, IUrl } from 'types/PostInterface'
import { AppStore, T, getSizeZoomed, openVistaCreate } from 'utils'
import CreateHashtagGroup from './Post/CreateHashtagGroupModal'
import { PostMediaModel } from './Post/PostMediaModel'

import ZoomedModal from './Post/ZoomedModal'

import { PostPlanning } from './Post/PostPlanning'

import { FeedbackGenerationModal } from 'components/CreatePost/TextComponents/AiGeneric/FeedbackGenerationModal'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getEditorData } from 'utils/eventsTracker'
import { MixpanelEditorData } from 'utils/eventsTracker/editor/editorInterfaces'
import AutopilotGenerateKeyModal from './AutopilotGenerateKey/AutopilotGenerateKeyModal'

export const showDraftModal = (goToPost?: (bol: boolean) => void) => {
  return AppStore.openModal({
    id: 'draftModal',
    style: { minWidth: getSizeZoomed(90, 'vw') },
    body: <DraftModal onPostClick={() => AppStore.closeModal('draftModal')} goToPost={goToPost} />,
  })
}

export const openZoomedModal = (item, index) =>
  AppStore.openModal({
    id: 'zoom',
    body: <ZoomedModal index={index} item={item} />,
    width: '1000px',
  })

export const openPiningModal = (post: IPostVariant, setNotClosebleModal) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <InstagramPiningModal
        postTags={post.data.tags}
        onEnteringPin={(value) => {
          setNotClosebleModal(value)
        }}
        post={post}
      />
    ),
    style: {
      minHeight: 750,
      minWidth: 750,
      marginBottom: 120,
    },
    TypeEscapeNull: true,
  })
}

// Modale per la selezione del formato del editor
export const openEditor = async (selectedMedia?: any, onCrelloSave?: (crelloResponse: CrelloResponse) => void) => {
  const check = await AppStore.checkPermission('manage_folders')
  if (!check) {
    return
  } else {
    AppStore.openModal({
      width: 1000,
      id: 'media-template',
      body: (
        <MediaTemplateModal
          onTemplateClick={(template) => {
            AppStore.closeAllModal()
            AppStore.reloadRouting()
            setTimeout(() => {
              if (template.designType === 'custom') return openCustomMediaCreate()
              openCrello(template, selectedMedia, onCrelloSave)
            }, 100)
          }}
        />
      ),
    })
  }
}

// Editor modal
export const openCrello = (template, selectedMedia, onCrelloSave) => {
  const { width, height, measureUnits } = template.customDimensions ?? { width: 0, height: 0, measureUnits: 'px' }

  const graphicEditorData: MixpanelEditorData = {
    designType: template.designType,
    width: width,
    height: height,
    measureUnits: measureUnits,
  }

  Tracker.trackEvent(TRACKEREVENTS.GRAPHIC_EDITOR_OPENED, getEditorData(graphicEditorData))

  AppStore.openModal({
    id: 'crello-loader',
    closeIcon: false,
    modal: (
      <div
        style={{
          minWidth: 1000,
          minHeight: 400,
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.45)',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 9,
        }}
      >
        <AnimatedPlaceholderLogo
          containerStyle={{ backgroundColor: 'transparent' }}
          style={{ height: '10vh', width: '10vw' }}
        />
      </div>
    ),
  })
  openVistaCreate(onCrelloSave, {
    sidebarDesignType: template.designType,
    user: {
      //  email: AppStore.loggedUser.email,
      externalUserId: AppStore.loggedUser._id.toString(),
    },
    ...template,
    image: selectedMedia?.[0]?.url ?? '',
  })
}

export const openCustomMediaCreate = () => {
  setTimeout(() => {
    AppStore.openModal({
      width: 500,
      id: 'custom-media-create',
      body: (
        <CustomMediaModal
          onConfirm={(width, height) => {
            AppStore.closeModal('custom-media-create')
            openCrello(
              {
                designType: 'instagramSM',
                customDimensions: { width, height, measureUnits: 'px' },
              },
              [],
              () => {}
            )
          }}
        />
      ),
    })
  }, 300)
}

//TODO:MODALE MEDIA
export const openMediaModal = ({
  onMediaSelected,
  selectedMedias,
  context,
}: {
  onMediaSelected: (medias: Media[]) => void
  selectedMedias: Media[]
  context?: string[]
}) => {
  AppStore.openModal({
    width: 1700,
    id: 'media-modal-selector',
    body: (
      <PostMediaModel
        onMediaSelected={(medias: Media[]) => onMediaSelected(medias)}
        selectedMedias={selectedMedias}
        context={context}
      />
    ),
    style: {
      backgroundColor: 'transparent',
      minWidth: 1600,
      minHeight: 550,
    },
  })
}

//---------------

export const CreateHashtagGroupModal = () => {
  AppStore.openModal({
    id: 'createGroup',
    body: <CreateHashtagGroup />,
    width: '787',
  })
}

// Modale delle lingue
export const openLanguageSelectModal = (outputLanguage, setOutputLanguage) =>
  AppStore.openModal({
    id: 'language-modal',
    body: (
      <Box width={'auto'} height={470} flex>
        <Box row style={{ backgroundColor: `${AppStore.theme.o.surface}`, minHeight: 300 }} ph={32} pv={31}>
          <Box
            mr={10}
            flex
            vcenter
            row
            style={{
              backgroundColor: `${() => AppStore.theme.o.surface}`,
              border: `2px solid ${() => AppStore.theme.o.lightGrey}`,
            }}
          >
            <LanguageBox InputOutput={false} onClick={(e) => setOutputLanguage(e)} language={outputLanguage} />
          </Box>
        </Box>
      </Box>
    ),
    width: '1210',
  })

// Modale delle lingue
export const openTemplateModal = (variantId: string) =>
  AppStore.openModal({
    id: 'add-template-to-extra',
    body: <Template variantId={variantId} />,
    style: {
      minWidth: 930,
      maxHeight: 700,
    },
  })

// Modale delle thumbnail
export const openThumbnailConfirmModal = (onConfirm, onCancel?) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <ConfirmModal
        text={
          <p>
            {T.postPage.youtubeTubeExtra.thumbnailConfirm1} <br />
            <span style={{ marginRight: 5, marginLeft: 5 }} />
            <br />
            {T.postPage.youtubeTubeExtra.thumbnailConfirm2}
          </p>
        }
        cancelText={T.postPage.cancel}
        confirmText={T.settings.confirm}
        onClickCancel={() => {
          onCancel()
          AppStore.closeModal('preview-modal')
        }}
        onClickConfirm={async () => {
          await onConfirm()
          AppStore.closeModal('preview-modal')
        }}
      />
    ),
    style: {
      minHeight: 300,
      minWidth: 700,
      alignContent: 'center',
    },
  })
}

// Modale emoji
export const openEmojidModal = (identifier: string) => {
  const { activePost } = useCreatePostStore.getState()

  AppStore.openModal({
    id: 'preview-modal',
    body: <Emoji identifier={identifier} post={activePost} />,
    style: {
      minHeight: 100,
    },
  })
}

// Planning post modal
export const openShowPlanningOptions = () => {
  AppStore.openModal({
    id: 'preview-modal',
    body: <PostPlanning />,
    style: {
      minWidth: 644,
      maxHeight: 744,
      backgroundColor: 'transparent',
    },
  })
}

// Modale UTM
export const openUTMModal = (
  post: IPostData | IPostVariant,
  visibleLink: IUrl,
  firstComment?: boolean,
  isDescription?: boolean
) => {
  AppStore.openModal({
    id: 'utm-modal',
    body: <UtmModal post={post} visibleLink={visibleLink} firstComment={firstComment} isDescription={isDescription} />,
    style: {
      minHeight: 300,
      minWidth: 800,
    },
  })
}

// Modale per il feedback delle generazioni di testo
export const openFeedbackModal = (isFeedbackPositive: boolean) => {
  AppStore.openModal({
    id: 'generation-feedback-modal',
    body: <FeedbackGenerationModal isFeedbackPositive={isFeedbackPositive} />,
    style: {
      minWidth: 787,
    },
  })
}

/* ----------------- AUTOPILOT MODAL ----------------- */

export const onOpenAutopilotGenerateKeyModal = (setterStateBol: () => void) => {
  return AppStore.openModal({
    id: 'autopilot-modal',
    body: <AutopilotGenerateKeyModal setterStateBol={setterStateBol} />,
    style: {
      minWidth: 671,
      minHeight: 309,
    },
  })
}
