/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box, Icons } from 'components'
import { IDefaultNotificationSetting } from 'types/NotifyInterface'
import { onOpenWhatsappModal } from 'ui/RequestNumber/utils/utilsModal'
import { AppStore, T } from 'utils'
import { Toggler } from './UI'

/* ******************* TYPE ******************* */

type TStyles = 'all-checked' | 'some-checked' | 'nothing-checked'

/* ******************* INTERFACE ******************* */

interface Props {
  notification: IDefaultNotificationSetting
  notifyData: IDefaultNotificationSetting[]
  setNotifyData: (notification: IDefaultNotificationSetting[] | any) => void
  onOpen: () => void
  index: number
  hasUserWhatsapp?: boolean
  onForceUpdate: () => void
}

const NotificationSetting = ({
  notification,
  notifyData,
  setNotifyData,
  onOpen,
  index,
  hasUserWhatsapp,
  onForceUpdate,
}: Props) => {
  /* ____________________ REF ____________________ */

  /* ____________________ CONTEXT ____________________ */

  /* ____________________ VARIABLES ___________________ */

  const { name, description, permissions } = notification
  /* ____________________ STATE ____________________ */

  /* ____________________ METHODS ____________________ */

  const AllPermissionToggle = (type: TStyles) => {
    const allUnderComponents = document.querySelectorAll(`[id^="${index}-${notification.name}-all"]`)
    if (type === 'nothing-checked') {
      allUnderComponents.forEach((component) => {
        component.classList.remove('some-checked')
        component.classList.remove('all-checked')
        component.classList.add('nothing-checked')
      })
    }
    if (type === 'some-checked') {
      allUnderComponents.forEach((component) => {
        component.classList.remove('all-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('some-checked')
      })
    }
    if (type === 'all-checked') {
      allUnderComponents.forEach((component) => {
        component.classList.remove('some-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('all-checked')
      })
    }
  }

  /* ____________________ ALL TOGGLE FUNCTIONS____________________ */
  const getSelectAllValue = (modifyNotification?: IDefaultNotificationSetting): TStyles => {
    const notifications = modifyNotification ? modifyNotification : notification

    const allNonDefaultPermissions = notifications.permissions.filter((permission) => !permission.isDefault)

    const allSelected = allNonDefaultPermissions.every(
      (permission) => permission.type.mail && permission.type.app && permission.type.whatsapp
    )

    if (allSelected) {
      return 'all-checked'
    }

    const someSelected = allNonDefaultPermissions.some(
      (permission) => permission.type.mail || permission.type.app || permission.type.whatsapp
    )

    if (someSelected) {
      return 'some-checked'
    }

    return 'nothing-checked'
  }

  const animateAllUnderComponents = (updatedNotification: IDefaultNotificationSetting) => {
    const allUnderComponents = document.querySelectorAll(`[id^="${index}-${notification.name}-"]`)

    const allActive = getSelectAllValue(updatedNotification)

    allUnderComponents.forEach((component) => {
      if (!hasUserWhatsapp && component.id.includes('-whatsapp')) {
        return
      }

      if (allActive === 'nothing-checked') {
        component.classList.remove('some-checked')
        component.classList.remove('all-checked')
        component.classList.add('nothing-checked')
      } else if (allActive === 'some-checked') {
        component.classList.remove('all-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('some-checked')
      } else if (allActive === 'all-checked') {
        component.classList.remove('some-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('all-checked')
      }
    })
  }

  const handleSelectAll = () => {
    const value = getSelectAllValue()

    const updatedPermissions = notification.permissions.map((permission) => {
      if (!permission.isDefault && hasUserWhatsapp) {
        return {
          ...permission,
          type: {
            ...permission.type,
            mail: value === 'nothing-checked' || value === 'some-checked' ? true : false,
            app: value === 'nothing-checked' || value === 'some-checked' ? true : false,
            whatsapp: value === 'nothing-checked' || value === 'some-checked' ? true : false,
          },
        }
      }

      if (!permission.isDefault && !hasUserWhatsapp) {
        return {
          ...permission,
          type: {
            ...permission.type,
            mail: value === 'nothing-checked' ? true : false,
            app: value === 'nothing-checked' ? true : false,
            whatsapp: false,
          },
        }
      }

      return permission
    })

    const updatedNotification = {
      ...notification,
      permissions: updatedPermissions,
    }

    animateAllUnderComponents(updatedNotification)

    setTimeout(() => {
      setNotifyData((prevData) => prevData.map((notify) => (notify.name === name ? updatedNotification : notify)))
    }, 150)
  }

  /* ____________________ PLATFORM TOGGLE FUNCTIONS____________________ */
  const addPlatformAnimation = (platform: string, allToggleButton?, groupToggleButton?, updatedNot?) => {
    const allUnderComponents = document.querySelectorAll(`[id^="${index}-${notification.name}-${platform}"]`)

    let filtered: any = []
    if (groupToggleButton !== undefined)
      allUnderComponents.forEach((component) => {
        if (component.id === `${index}-${notification.name}-${platform}`) {
          filtered.push(component)
        }
      })
    else filtered = allUnderComponents

    const allActive = getPlatformValue(name, platform, updatedNot)

    if (allToggleButton) AllPermissionToggle(allToggleButton)

    if (allActive === 'nothing-checked') {
      filtered.forEach((component) => {
        component.classList.remove('some-checked')
        component.classList.remove('all-checked')
        component.classList.add('nothing-checked')
      })
    }
    if (allActive === 'some-checked') {
      filtered.forEach((component) => {
        component.classList.remove('all-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('some-checked')
      })
    }
    if (allActive === 'all-checked') {
      filtered.forEach((component) => {
        component.classList.remove('some-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('all-checked')
      })
    }
  }

  const getPlatformValue = (notificationName: string, platform: string, modifyNotification?: any): TStyles => {
    const newNotification = modifyNotification ? modifyNotification : notification

    if (!newNotification) {
      return 'nothing-checked'
    }

    const allNonDefaultPlatformPermissions = newNotification.permissions.filter((permission) => !permission.isDefault)

    const allPlatformSelected = allNonDefaultPlatformPermissions.every((permission) => permission.type[platform])

    if (allPlatformSelected) {
      return 'all-checked'
    }

    const somePlatformSelected = allNonDefaultPlatformPermissions.some((permission) => permission.type[platform])

    if (somePlatformSelected) {
      return 'some-checked'
    }

    return 'nothing-checked'
  }

  const handleSelectPlatform = (notificationName: string, platform: string) => {
    const value = getPlatformValue(notificationName, platform)

    //Se l'utente clicca su whatsapp e non ha whatsapp allora apre la modale
    if (platform === 'whatsapp' && !hasUserWhatsapp) {
      return onOpenWhatsappModal(onForceUpdate)
    }

    const updatedPermissions = notification.permissions.map((permission) => {
      if (!permission.isDefault) {
        return {
          ...permission,
          type: {
            ...permission.type,
            [platform]: value === 'nothing-checked' || value === 'some-checked' ? true : false,
          },
        }
      }
      return permission
    })

    const updatedNotification = {
      ...notification,
      permissions: updatedPermissions,
    }

    const allToggleButton = getSelectAllValue(updatedNotification)

    addPlatformAnimation(platform, allToggleButton, undefined, updatedNotification)

    setTimeout(() => {
      setNotifyData((prevData) =>
        prevData.map((notify) => (notify.name === notificationName ? updatedNotification : notify))
      )
    }, 150)
  }

  /* ____________________ SINGLE TOGGLE FUNCTIONS____________________ */
  const getSinglePermissionValue = (permissionName: string) => {
    if (!notification) {
      return 'nothing-checked'
    }

    const permission = notification.permissions.find((perm) => perm.name === permissionName)

    if (!permission) {
      return 'nothing-checked'
    }

    const isMailSelected = permission.type.mail
    const isAppSelected = permission.type.app
    const isWhatsappSelected = permission.type.whatsapp

    if (isMailSelected && isAppSelected && isWhatsappSelected) {
      return 'all-checked'
    }

    if (isMailSelected || isAppSelected || isWhatsappSelected) {
      return 'some-checked'
    }

    return 'nothing-checked'
  }

  const singlePermissionAnimation = (
    permissionTypeName: string,
    innerIndex: number,
    isCheaked: boolean,
    allToggleButton: TStyles,
    updatedNotifyData: IDefaultNotificationSetting[]
  ) => {
    const allUnderComponents = document.querySelectorAll(
      `[id^="${index}-${notification.name}-${permissionTypeName}-${innerIndex}"]`
    )
    const allActive = getSinglePermissionValue(permissionTypeName)
    AllPermissionToggle(allToggleButton)

    addPlatformAnimation(
      permissionTypeName,
      undefined,
      true,
      updatedNotifyData.find((notify) => notify.name === notification.name)
    )

    if (isCheaked) {
      allUnderComponents.forEach((component) => {
        component.classList.remove('some-checked')
        component.classList.remove('all-checked')
        component.classList.add('nothing-checked')
      })
    } else {
      allUnderComponents.forEach((component) => {
        component.classList.remove('some-checked')
        component.classList.remove('nothing-checked')
        component.classList.add('all-checked')
      })
    }
  }

  const handleSinglePermissionValue = async (
    notificationName: string,
    permissionName: string,
    permissionTypeName: string,
    permissionTypeValue: boolean,
    innerIndex: number
  ) => {
    //Se l'utente clicca su whatsapp e non ha whatsapp allora apre la modale
    if (permissionTypeName === 'whatsapp' && !hasUserWhatsapp) {
      return onOpenWhatsappModal(onForceUpdate)
    }
    const updatedNotifyData = notifyData.map((notify) => {
      if (notify.name === notificationName) {
        const updatedPermissions = notify.permissions.map((permission) => {
          if (permission.name === permissionName) {
            const updatedType = { ...permission.type, [permissionTypeName]: !permissionTypeValue }
            return { ...permission, type: updatedType }
          }
          return permission
        })

        return {
          ...notify,
          permissions: updatedPermissions,
        }
      }
      return notify
    })

    const allToggleButton = getSelectAllValue(updatedNotifyData.find((notify) => notify.name === notificationName))

    singlePermissionAnimation(permissionTypeName, innerIndex, permissionTypeValue, allToggleButton, updatedNotifyData)

    setTimeout(() => {
      setNotifyData(updatedNotifyData)
    }, 150)
  }

  /* ____________________ API CALL ____________________ */

  /* ____________________ USEEFFECT ____________________ */

  return (
    <NotificationSettingContainer>
      <HeaderNotificationSetting id={`${index}-${notification.name}-all`} className={getSelectAllValue()}>
        {description}{' '}
        <Toggler
          id={`${index}-${notification.name}-all`}
          value={getSelectAllValue()}
          setValue={() => handleSelectAll()}
        />
      </HeaderNotificationSetting>

      <ContentNotificationSettings>
        <ContentHeaderNotificationSetting>
          <DropdownAllNotification
            isOpen={notification.open}
            onClick={() => {
              onOpen()
            }}
          >
            {T.settings.allNotifications}
            <Icons.dropdownArrow />
          </DropdownAllNotification>

          <NotificationPlatformContainer>
            <NotificationPlatform id={`${index}-${notification.name}-app`} className={getPlatformValue(name, 'app')}>
              Software
              <Toggler
                id={`${index}-${notification.name}-app`}
                value={() => getPlatformValue(name, 'app')}
                setValue={() => handleSelectPlatform(name, 'app')}
              />
            </NotificationPlatform>

            <NotificationPlatform id={`${index}-${notification.name}-mail`} className={getPlatformValue(name, 'mail')}>
              Email
              <Toggler
                id={`${index}-${notification.name}-mail`}
                value={() => getPlatformValue(name, 'mail')}
                setValue={() => handleSelectPlatform(name, 'mail')}
              />
            </NotificationPlatform>

            <NotificationPlatform
              id={`${index}-${notification.name}-whatsapp`}
              className={getPlatformValue(name, 'whatsapp')}
            >
              WhatsApp
              <Toggler
                id={`${index}-${notification.name}-whatsapp`}
                value={() => getPlatformValue(name, 'whatsapp')}
                setValue={() => handleSelectPlatform(name, 'whatsapp')}
              />
            </NotificationPlatform>
          </NotificationPlatformContainer>
        </ContentHeaderNotificationSetting>

        {notification.open &&
          permissions.length > 0 &&
          permissions
            .filter((item) => item.isDefault === false)
            .map((permission, innerIndex) => (
              <NotificationDetailContainer
                key={Utils.generateRandomUIDKey()}
                className={getSinglePermissionValue(permission.name)}
                id={`${index}-${notification.name}-${innerIndex}`}
              >
                {permission.description}

                {permission && (
                  <ContainerNotificationPermissions>
                    <Toggler
                      id={`${index}-${notification.name}-app-${innerIndex}`}
                      value={permission.type.app ? 'all-checked' : 'nothing-checked'}
                      setValue={() =>
                        handleSinglePermissionValue(name, permission.name, 'app', permission.type.app, innerIndex)
                      }
                    />
                    <Toggler
                      id={`${index}-${notification.name}-mail-${innerIndex}`}
                      value={permission.type.mail ? 'all-checked' : 'nothing-checked'}
                      setValue={() =>
                        handleSinglePermissionValue(name, permission.name, 'mail', permission.type.mail, innerIndex)
                      }
                    />
                    <Toggler
                      id={`${index}-${notification.name}-whatsapp-${innerIndex}`}
                      value={permission.type.whatsapp ? 'all-checked' : 'nothing-checked'}
                      setValue={() =>
                        handleSinglePermissionValue(
                          name,
                          permission.name,
                          'whatsapp',
                          permission.type.whatsapp,
                          innerIndex
                        )
                      }
                    />
                  </ContainerNotificationPermissions>
                )}
              </NotificationDetailContainer>
            ))}
      </ContentNotificationSettings>
    </NotificationSettingContainer>
  )
}

export default NotificationSetting

const NotificationSettingContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const HeaderNotificationSetting = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.21px;

  &.all-checked {
    color: ${() => AppStore.theme.o.blue};
  }

  &.some-checked {
    color: ${() => AppStore.theme.o.black};
  }

  &.nothing-checked {
    color: ${() => AppStore.theme.o.darkGrey};
  }
`

const ContentNotificationSettings = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.grey};
  border-bottom: 0;
`

const ContentHeaderNotificationSetting = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 32px 12px 20px;
  border-bottom: 1px solid ${() => AppStore.theme.o.grey};
`

const DropdownAllNotification = styled(Box) <{ isOpen: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;

  svg {
    fill: ${() => AppStore.theme.o.black};
    transition: 250ms ease-in-out;
    rotate: ${({ isOpen }) => (isOpen ? '0' : '-180deg')};
  }
`

const NotificationPlatformContainer = styled(Box)`
  margin-right: 16px;
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const NotificationPlatform = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border-radius: 14px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;

  &.all-checked {
    background-color: ${() => AppStore.theme.o.lightBlue};
    color: ${() => AppStore.theme.o.blue};
  }

  &.some-checked {
    background-color: ${() => AppStore.theme.o.lightestGrey};
    color: ${() => AppStore.theme.o.black};
  }

  &.nothing-checked {
    background-color: ${() => AppStore.theme.o.lightestGrey};
    color: ${() => AppStore.theme.o.darkGrey};
  }
`

const NotificationDetailContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px 12px 31px;
  border-bottom: 1px solid ${() => AppStore.theme.o.grey};
  border-radius: 0;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;

  &:last-of-type {
    border-radius: 14px;
  }

  &.all-checked {
    color: ${() => AppStore.theme.o.blue};
  }

  &.some-checked {
    color: ${() => AppStore.theme.o.black};
  }

  &.nothing-checked {
    color: ${() => AppStore.theme.o.darkGrey};
  }
`

const ContainerNotificationPermissions = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 78px;

  & div {
    margin-right: 26.8px;

    &:nth-of-type(2) {
      margin-right: 52.8px;
    }
  }
`
