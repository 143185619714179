import i18n, { TOptions } from 'i18next'
import { initReactI18next } from 'react-i18next'
import Cookies from 'universal-cookie'

import { it } from './it'
import { en } from './en'
import es from './es'

const resources = {
  it: { translation: it },
  en: { translation: en },
  es: { translation: es },
}
const cookie = new Cookies()
const appStoreLangauge: string = cookie.get('language')

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resources,
    lng: appStoreLangauge ?? 'en',

    fallbackLng: appStoreLangauge ?? 'en',

    interpolation: {
      escapeValue: false,
    },
  })

////////////////////////////////////////////////////
// HACK: use languages auto-completion
////////////////////////////////////////////////////
interface TranslationCategory {
  [key: string]: string
}

const defaultLang = localStorage.getItem('defaultLang') ?? 'it'

const TRANSLATION_MAP = defaultLang === 'it' ? it : en

export { TRANSLATION_MAP as T }
////////////////////////////////////////////////////

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __ = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap)

// eslint-disable-next-line @typescript-eslint/naming-convention
export const __UP = (key: string, interpolationMap?: TOptions) => i18n.t(key, interpolationMap).toUpperCase()

export default i18n
