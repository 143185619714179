import Medias from 'api/Medias'
import { Box } from 'components/Box'
import { gsap } from 'gsap'
import { ImageFormat, PhotoEditorSDKUI, Tool, UIEvent } from 'photoeditorsdk'
import { Component } from 'react'
import { Media } from 'types'
import { AppStore } from 'utils'

interface Props {
  media: Media
  onChangeMedias: (media) => void
}

export class PhotoEditorSDK extends Component<Props> {
  componentDidMount() {
    this.initEditor()
  }

  dataURItoFormData(dataURI: string): FormData {
    const formData = new FormData()
    const [, data] = dataURI.split(',')
    const decodedData = atob(data)
    const byteArr = new Uint8Array(decodedData.length)
    for (let i = 0; i < decodedData.length; i++) {
      byteArr[i] = decodedData.charCodeAt(i)
    }
    const blob = new Blob([byteArr])
    formData.append('file', blob, 'image.jpg')
    formData.append('name', `image.jpg`)
    formData.append('path', ``)
    formData.append('type', 'image/jpeg')

    return formData
  }

  async initEditor() {
    const editorImg = document.getElementById('editor')
    gsap.set(editorImg, {
      opacity: 0,
    })
    gsap.to(editorImg, {
      delay: 1,
      duration: 0.5,
      opacity: 1,
      ease: 'smooth',
    })

    const editor = await PhotoEditorSDKUI.init({
      theme: AppStore.darkTheme ? 'dark' : 'light',

      container: '#editor',
      image: this.props.media.url,
      license: JSON.stringify({
        api_token: 'jAFZLCR5ZcSJFb1BSdJIgg',
        app_identifiers: [
          'romantic-booth-e26b41.netlify.app',
          'localhost',
          'beta.asters.ai',
          'qty.asters.ai',
          'app.asters.ai',
          'alpha.asters.ai',
        ],
        available_actions: [],
        domains: ['https://api.photoeditorsdk.com'],
        enterprise_license: false,
        expires_at: null,
        features: ['camera', 'library', 'export', 'customassets', 'whitelabel', 'transform'],
        issued_at: 1703435563,
        minimum_sdk_version: '1.0',
        owner: 'Asters',
        platform: 'HTML5',
        products: ['pesdk'],
        version: '2.4',
        signature:
          'YkTQs7L6lKD320paMpdhx4zbL6GuLf7m/8F+4yfRCVHLBV3HFLbW1PvmN1MSlJFwb6N+OgNHnpQwe9f0p9F5mAwS2f4//Vw6dPOwmOHQYCQgAUOoVRm5eEPbZn0p5qRy/Kt5W/FrtIByk0tebPSq3CDtM6uu5XhpNbS2rTOyQ8lgsrGPX5UiOj1KNOcqhw0UEj70i8vmoeEFxhwcCnZ0dz8duQI5Pt8d/rHokz6MQ5WQq/TnZCT+xnjitJkladxgAtKu52Zjm71Ml8WjpZkfzdfXgE7p5YTFKPqZL0svVc/L2w6CsCUrZKeHvxRJvBdtCpU3NKe4C4iC4f+6qP+a1U0Iy0DEbJuxyWlhQeYRYv0kRerjUOsZvzDIci0DosYBdka7lndItV6LULt5btPCPrqB7l95WvK9iBf6zhFDPyRLuQu4q4Z5cQRmdNm9RyJFd8DJzf+d2MAQTR+etYHz3XIpCMYZPim2kQgRVm7Xu2DRPBbc9X16s98aX050KstXmhisxosEfCnxxcSr612CEVwjv+GowNmWstBjKgx4ZWFLZj8floZ3Zm+qT0eEhfolJGhci2Du8kElY+xQglBM+P0Jy/Rq1SueDJcOMJRe9P1Jfs8200vN8y8aI3Wo184w29y+vWkkAXHr3RYTy91rEVg02FKxAi024wUf3XPahwU=',
      }),
      language: 'en',
      export: {
        image: {
          enableDownload: false,
          format: ImageFormat.JPEG,
        },
      },
      tools: [Tool.TRANSFORM],
    })
    editor.on(UIEvent.EXPORT, async (imageSrc) => {
      try {
        await this.props.onChangeMedias(this.props.media)
        const formDatas = this.dataURItoFormData(imageSrc.src)
        const media = await Medias.createFile(formDatas)

        const mediaU = media?.data as any

        const url = await Medias.getUrl(media?.data)
        mediaU.url = url?.data

        await this.props.onChangeMedias(mediaU)
        AppStore.closeAllModal()
      } catch (e) {
        console.error(e)
      }
    })
    editor.on(UIEvent.CLOSE, (imageSrc) => {
      AppStore.closeAllModal()
    })

    const buttons = document.querySelector('[data-test=MainCanvasActionBar]')
    const toolBars = document.querySelector('[data-test="Toolbar"]')
    const mainButton = document.querySelector('[data-test="MainBarButtonExport"]') as any
    const closeButton = document.querySelector('[data-test="MainBarButtonClose"]')

    const editorContainer = document.querySelector('[data-test="EditorContainer"]')

    gsap.set(editorContainer, {
      borderRadius: 14,
      border: '2px solid #4f4f4f',
    })

    gsap.set(closeButton, {
      opacity: 0,
    })

    mainButton.innerHTML = 'Save'

    gsap.set(buttons, {
      duration: 0.5,
      // opacity: 0,
      ease: 'power2.out',
    })

    gsap.set(toolBars, {
      duration: 0.5,
      display: 'none',
      ease: 'power2.out',
    })

    // `background-color: ${AppStore.theme.o.lightGreen};   color: ${AppStore.theme.o.green}; border: 2px solid ${AppStore.theme.o.green};  `
    gsap.set(mainButton, {
      y: 780,
      x: -50,
      position: 'fixed',
      textTransform: 'capitalize',
      backgroundColor: AppStore.theme.o.lightBlue,
      color: AppStore.theme.o.blueOnLight,
      fontSize: 16,
      borderRadius: 14,
      fontFamily: 'Aktiv Grotesk',
      border: `2px solid ${AppStore.theme.o.blueOnLight}`,
      ease: 'power2.out',
      width: 200,
      height: 42,
    })
  }

  render() {
    return <Box id="editor" style={{ width: 1400, height: 700 }} />
  }
}
