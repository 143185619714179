/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider, StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Box, Icons } from 'components'
import { AnimatedDropdown, IItemList } from 'components/UI/AnimatedDropdown'
import dayjs, { Dayjs } from 'dayjs'
import { useEffect, useState } from 'react'
import { AnalyticsDate } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { muiTheme } from 'utils/MuiUtils/MuiThemes'
import { localDate, localLang } from 'utils/MuiUtils/MuiTranslations'

/* ------------------- TYPES  */
type TTemporalUnit = 'day' | 'week' | 'month' | 'quarter' | 'year'

/* ------------------- INTERFACES  */
interface Props {
  onSelectedDateChange: (dates: AnalyticsDate) => void
  selectedDateFilter: AnalyticsDate | null
}

export const DateCompareDropdown = ({ onSelectedDateChange, selectedDateFilter }: Props) => {
  /* ----------------- STATE  */
  const [selectedDate, setSelectedDate] = useState<AnalyticsDate | null>(null)
  const [activeTitle, setActiveTitle] = useState<string>(T.analytics.compare)
  //Stato per definire se è aperto il date picker
  const [openRangePicker, setOpenRangePicker] = useState<boolean>(false)

  // Stato utilizzato per l'opzione "personalizza" per far rimanere aperto il dropdwon a livello di larghezza durante la scelta di una data
  const [dynamicOpenWidth, setDynamicOpenWidth] = useState<boolean>(false)

  /* ----------------- VARIABLES  */
  // Variabile per controllo di style
  const hasSelectedDate: boolean =
    selectedDate !== null && selectedDate?.startDate !== undefined && selectedDate?.endDate !== undefined

  // Lista delle opzioni selezionabili nel dropdown
  const compareOptions: IItemList[] = [
    {
      name: T.date.previousDay,
      action: () => {
        desactivateDatePicker(), handleCompareDate('day')
      },
    },
    {
      name: T.date.previousWeek,
      action: () => {
        desactivateDatePicker(), handleCompareDate('week')
      },
    },
    {
      name: T.date.previousMonth,
      action: () => {
        desactivateDatePicker(), handleCompareDate('month')
      },
    },
    {
      name: T.date.previousQuarter,
      action: () => {
        desactivateDatePicker(), handleCompareDate('quarter')
      },
    },
    {
      name: T.date.previousYear,
      action: () => {
        desactivateDatePicker(), handleCompareDate('year')
      },
    },
    { name: T.analytics.customize, action: () => setOpenRangePicker(true) },
  ]

  /* ----------------- METHODS  */
  const handleCompareDate = (unit: TTemporalUnit) => {
    if (selectedDateFilter) {
      const { startDate, endDate } = selectedDateFilter
      let newStartDate: Dayjs, newEndDate: Dayjs

      switch (unit) {
        case 'day':
          newStartDate = dayjs(startDate).subtract(1, 'day')
          newEndDate = dayjs(endDate).subtract(1, 'day')
          break
        case 'week':
          newStartDate = dayjs(startDate).subtract(1, 'week')
          newEndDate = dayjs(endDate).subtract(1, 'week')
          break
        case 'month':
          newStartDate = dayjs(startDate).subtract(1, 'month')
          newEndDate = dayjs(endDate).subtract(1, 'month')
          break
        case 'quarter':
          newStartDate = dayjs(startDate).subtract(4, 'month')
          newEndDate = dayjs(endDate).subtract(4, 'month')
          break
        case 'year':
          newStartDate = dayjs(startDate).subtract(1, 'year')
          newEndDate = dayjs(endDate).subtract(1, 'year')
          break
        default:
          newStartDate = dayjs()
          newEndDate = dayjs()
          break
      }

      handleAccept([newStartDate, newEndDate])
    }
  }

  const desactivateDatePicker = () => {
    setOpenRangePicker(false)
  }

  const handleAccept = (value) => {
    setSelectedDate({ startDate: value[0], endDate: value[1] })
    onSelectedDateChange({ startDate: value[0], endDate: value[1] })

    //se ha valore su tutti e due i campi chiude il date picker
    if (value[0] && value[1]) {
      setOpenRangePicker(false)
      setDynamicOpenWidth(false)
    }
  }

  const handleReset = () => {
    setSelectedDate(null)
    onSelectedDateChange({ startDate: null, endDate: null })
    setActiveTitle(T.analytics.compare)
  }

  //Resetta il valore del compare se viene resettato il valore del filtro iniziale
  useEffect(() => {
    if (selectedDateFilter === null) {
      handleReset()
    }
  }, [selectedDateFilter])

  return (
    <DateCompareDropdownContainer>
      <AnimatedDropdown
        icon={
          <Icons.compare
            fill={hasSelectedDate ? AppStore.theme.o.green : AppStore.theme.o.black}
            width={24}
            height={24}
          />
        }
        listItems={compareOptions}
        title={activeTitle}
        setTitle={setActiveTitle}
        hasValue={hasSelectedDate}
        handleReset={handleReset}
        isDisabled={selectedDateFilter === null}
        disabledText={T.date.selectDateFirst}
        dynamicOpenWidth={dynamicOpenWidth}
        setDynamicOpenWidth={setDynamicOpenWidth}
      />

      {/* Date picker per la selezione dell'opzione "personalizza" */}
      {openRangePicker && (
        <ContainerDatePicker>
          <ThemeProvider theme={muiTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localLang} adapterLocale={localDate}>
              <StaticDateRangePicker onChange={(newValue) => handleAccept(newValue)} disableFuture />
            </LocalizationProvider>
          </ThemeProvider>
        </ContainerDatePicker>
      )}
    </DateCompareDropdownContainer>
  )
}

const DateCompareDropdownContainer = styled(Box)`
  position: relative;
`

const ContainerDatePicker = styled(Box)`
  position: absolute;
  background-color: ${() => AppStore.theme.o.surface};
  top: 120%;
  right: 0;
  border-radius: 14px;
  gap: 8px;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
`
