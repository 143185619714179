import React, { Component } from 'react'
import NoDataFoundLottie from 'assets/UserKiledTheSub.json'
import Lottie from 'react-lottie'

const userKiledTheSub = {
  loop: true,
  autoplay: true,
  animationData: NoDataFoundLottie,
  rendererSettings: {
    preserveAspectRatio: '',
  },
}

export class UserKiledTheSub extends Component {
  render() {
    return <Lottie isClickToPauseDisabled options={userKiledTheSub} />
  }
}
