import Socialaccounts from 'api/Socialaccounts'
import { RemoteConfigValues } from 'api/Utils'
import Workspaces from 'api/Workspaces'
import ManageNotifications from 'components/ManageNotifications'
import { WorkspaceModal } from 'components/Modal/WorkspaceModal'
import UserPermission from 'components/UserPermission'
import WorkspaceApiKeys from 'components/WorkspaceApiKeys/WorkspaceApiKeys'
import WorkspaceComponent from 'components/WorkspaceComponent'
import WorkspaceManagement from 'components/WorkspaceManagement'
import { image } from 'html2canvas/dist/types/css/types/image'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SocialAccount, Workspace } from 'types'
import { AppStore, showError, T } from 'utils'
import { isVisible } from 'utils/CheckVisibleElement'
import { refresh } from 'utils/router'

const WorkspaceSection = () => {
  const [socialAccounts, setSocialAccounts] = useState<SocialAccount[]>(AppStore.socialAccountList)
  const [search, setSearch] = useState<string>('')
  const [workspaces, setWorkspaces] = useState<Workspace[]>([
    ...AppStore.loggedUser.workspaces,
    ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
  ])
  const [section, setSection] = useState<string>('workspace')
  const [workspaceToManage, setWorkspaceToManage] = useState<Workspace>(AppStore.workspace)
  const [index, setIndex] = useState<number>(0)
  const [forceUpdate, setForceUpdate] = useState(false)

  // ----------------------------- ROUTER
  const navigate = useNavigate()
  const location = useLocation()

  const onWorkspaceConfirmation = () => {
    const allWorkspaces = [
      ...AppStore.loggedUser.workspaces,
      ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
    ]
    AppStore.workspacesSetNotifications(allWorkspaces)

    setWorkspaces(allWorkspaces)
  }

  // ----------------------------- MODALS
  const openWorkspaceModal = (workspace?: Workspace) =>
    AppStore.openModal({
      id: 'workspace-modal',
      body: (
        <WorkspaceModal
          workspace={workspace}
          onConfirm={onWorkspaceConfirmation}
          onWorkspaceDelete={() => setSection('workspace')}
        />
      ),
      style: { minWidth: 720, maxWidth: 720, minHeight: 334, maxHeight: 334 },
    })

  // ----------------------------- METHODS
  const handleForceUpdate = () => {
    setForceUpdate((prev) => !prev)
  }

  const refreshSocialAccounts = async () => {
    try {
      const allSocials = await AppStore.refreshSocialaccounts()
      setSocialAccounts(allSocials as SocialAccount[])
    } catch (error: any) {
      showError(error?.message ?? T.error.error)
    }
  }

  const onSearchChange = (text: string) => {
    setSearch(text)
  }

  const onWorkspaceChange = (workspace) => {
    const allWorkspaces = [
      ...AppStore.loggedUser.workspaces,
      ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
    ].find((w) => w._id === workspace._id)

    const updatedWorkspace = { ...workspace, image: allWorkspaces?.image } as Workspace
    setWorkspaces((prev) =>
      prev.map((w, i) => (w._id?.toString() === updatedWorkspace._id?.toString() ? updatedWorkspace : w))
    )
    setWorkspaceToManage(workspace)
  }

  const onChangeSection = (newSection: string, newWorkspaceToManage?: Workspace) => {
    if (newWorkspaceToManage) {
      const findIndex = workspaces.findIndex((w) => w._id === newWorkspaceToManage._id)
      setWorkspaceToManage(newWorkspaceToManage)
      setIndex(findIndex)
    }

    if (newSection.includes('permissions')) {
      navigate(`/settings/workspace/${newSection}`, { state: { section: newSection } })
      setSection('permissions')
      return
    }

    if (newSection === 'workspace') {
      navigate(`/settings/workspace`, { state: { section: newSection } })
      // refresh()
    } else {
      navigate(`/settings/workspace/${newSection}${newWorkspaceToManage ? '/' + newWorkspaceToManage._id : ''}`, {
        state: { section: newSection },
      })
    }
    setSection(newSection)
  }

  const firstMount = async () => {
    await refreshSocialAccounts()
    setSection(location.state?.section ?? 'workspace')
  }
  // ----------------------------- USEEFFECT
  useEffect(() => {
    firstMount()
  }, [forceUpdate])

  useEffect(() => {
    const sec = location.state?.section
    if (sec && sec.includes('permissions')) setSection('permissions')
    else setSection(sec ?? 'workspace')
  }, [location, window.location])

  return (
    <>
      {section === 'workspace' && (
        <WorkspaceComponent
          handleForceUpdate={handleForceUpdate}
          searchText={search}
          openWorkSpaceModal={openWorkspaceModal}
          workspaces={workspaces}
          socialAccounts={socialAccounts}
          searchChange={onSearchChange}
          changeSection={onChangeSection}
          onWorkspaceChange={onWorkspaceChange}
        />
      )}

      {section === 'management' && (
        <WorkspaceManagement
          changeSection={onChangeSection}
          workspace={workspaceToManage}
          onWorkspaceChange={onWorkspaceChange}
          openWorkspaceModal={openWorkspaceModal}
        />
      )}

      {section === 'api-keys' && <WorkspaceApiKeys changeSection={onChangeSection} allWorkspaces={workspaces} />}

      {section === 'permissions' && (
        <UserPermission
          changeSection={onChangeSection}
          workspace={workspaces[index]}
          onWorkspaceChange={onWorkspaceChange}
        />
      )}

      {isVisible(RemoteConfigValues.NotificationDisabled) && section === 'notification' && (
        <ManageNotifications
          changeSection={onChangeSection}
          workspace={workspaceToManage}
          onWorkspaceChange={onWorkspaceChange}
        />
      )}
    </>
  )
}

export default WorkspaceSection
