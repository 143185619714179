export interface IAllNotification {
  id: string
  notificationLength: number
}
export interface INotifications {
  _id: string
  workspaceId: string
  title: string
  description: string
  type: string // è la funzione che fa partire la notifica a impostare il tipo
  notificationReaded: string[]
  updated_at: Date
  socialAccount?: INotifySocialAccount
  on_click_destination: string
  category: string
}

export interface INotifySocialAccount {
  _id: string
  name: string
  image: string
  provider: string
}

export interface INotificationReaded {
  _id: string // userId
}

export interface INotificationPermission {
  _id?: string // scommentato
  workspaceId: string // scommentato
  userId: string // scommentato
  isDefault: boolean // appena aggiunto

  notificationSetting: IDefaultNotificationSetting[] // questa in be viene chiamata category
}

export interface IDefaultNotificationSetting {
  name: string
  description?: string
  permissions: INotifyPermission[]
  open: boolean // solo fe
}

export interface INotifyPermission {
  description: string
  name: string
  isDefault: boolean
  type: INotifyType
  settingsValue: INotifyType
}

export interface INotifyType {
  app: boolean
  mail: boolean
  whatsapp: boolean
}

export interface IPhoneNumber {
  prefix: string | number
  number: string | number
  otpCode?: string | null
}

export interface IPhoneCountry {
  name: string
  dialCode: string
  countryCode: string
  format: string
}

export enum NotificationLimit {
  DEFAULT = 999,
  REDUCED = 99,
}

export interface DigitsState {
  value: string
  animate: boolean
}

export interface NotificationLabelProps {
  animate: 'enterUp' | 'exitUp' | 'enterDown' | 'exitDown' | 'none'
}
