/* ------------------- IMPORTS */
import { Icons } from 'components'
import { CardContainer, EmptyState, GridContainer, SpinnerAstersLogo } from 'components/UI'
import { ReportCard } from '../UI/ReportCard'
import { IReport, TActiveReportList } from 'types/AnalyticsInferfaces'
import { T, capitalizeFirstLetter } from 'utils'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getReportList } from 'utils/AnalyticsUtils'

/* ------------------- INTERFACES  */
interface Props {
  reportIdSelected: string[]
  handleSelectId: (id: string | string[]) => void
  reportListActive: TActiveReportList | null
  reportListFiltered: IReport[] | null
}

export const ContentDashboardAnalytics = ({
  reportIdSelected,
  handleSelectId,
  reportListActive,
  reportListFiltered,
}: Props) => {
  /* ------------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- VARIABLES  */
  // Lista dei report che ritorna le card
  const reportData = reportListFiltered?.map((report: IReport) => (
    <CardContainer
      content={
        <ReportCard
          content={report}
          someSelected={reportIdSelected.length > 0}
          action={() => handleSelectId(report._id)}
        />
      }
      isSelectable
      isSelected={reportIdSelected.includes(report._id)}
      action={() => handleSelectId(report._id)}
    />
  ))

  return (
    <>
      {/* Fetching data */}
      {reportListFiltered === null && <SpinnerAstersLogo dimension={100} />}

      {/* Showing report cards */}
      {reportListFiltered && reportListFiltered?.length >= 1 && (
        <GridContainer data={reportData} cols={3} colGap={24} rowGap={32} />
      )}

      {/* Showing emptystate */}
      {reportListFiltered && reportListFiltered?.length === 0 && (
        <EmptyState
          text={
            reportListActive === 'all' || reportListActive === null
              ? T.analytics.noReportsAvailable
              : T.analytics.noReportsWith + ' ' + capitalizeFirstLetter(reportListActive)
          }
          icon={<Icons.sadReport />}
          textButton={T.analytics.createYourFirstReport}
          action={() => navigate('/analytics/createReport', { state: { subsection: 'setup' } })}
        />
      )}
    </>
  )
}
