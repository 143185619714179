import { SearchOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { Workspace } from 'types'
import { AppStore, T, showError } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'
import { Input } from './Input'
import { WorkspaceModal } from './Modal/WorkspaceModal'
import { Roles } from './UsersGridSection'
import Workspaces from 'api/Workspaces'
import { useNavigate } from 'react-router-dom'
import { refresh } from 'utils/router'

interface Props {
  listOfRoles: Roles[]
  index: number
  addNewRole: (newRoleName: string) => void
  changeSection: (newSection: string) => void
  userId: string
  collabId: string
  userRole: string
  workspace: Workspace
  onWorkspaceChange: (workspace: Workspace) => void
}

export default function SearchRoleComponent(props: Props) {
  const [listOfRoles, setListOfRoles] = useState<Array<string>>([])
  const [filteredListOfRole, setFilteredListOfRole] = useState<Array<string>>([])
  const [searchText, setSearchText] = useState('')
  const [roleID, setRoleID] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    let list
    const { workspace } = props
    if (workspace.roles) list = workspace.roles?.map((role) => role.name)
    if (props.userRole) {
      const roleFind = workspace.roles?.filter((role) => role?.name === props.userRole)[0]?._id
      if (roleFind !== undefined) setRoleID(roleFind)
    }

    setListOfRoles(list)
  }, [])

  useEffect(() => {
    setFilteredListOfRole(listOfRoles)
  }, [listOfRoles])

  useEffect(() => {
    setFilteredListOfRole(
      listOfRoles.filter((role) => {
        return role.toLowerCase().includes(searchText.toLowerCase())
      })
    )
  }, [searchText])

  const handleSearchText = (text: string) => {
    setSearchText(text)
  }

  const createNewRole = (newRoleName) => {
    props.changeSection(`permissions?newrole=${newRoleName.newRoleName}`)
  }

  const openWorkspaceModal = (workspace?: Workspace) =>
    AppStore.openModal({
      id: 'workspace-modal',
      body: <WorkspaceModal workspace={workspace} />,
      width: '50vw',
    })

  const AddNewRole = (newRoleName) => {
    return (
      <Box row vcenter mt={8} onClick={() => createNewRole(newRoleName)}>
        <AddRoleIcon />
        <AddRoleText>{`${T.teamMembers.create} '${newRoleName.newRoleName}'`}</AddRoleText>
      </Box>
    )
  }

  const changeRole = async (role) => {
    const { userId, workspace, collabId } = props
    const roleId = workspace.roles?.find((r) => r.name === role)?._id
    if (roleId === undefined) {
      showError(T.error.roleNotFound)
    }
    const response = await Workspaces.changeColaboratorRole(collabId, roleId ?? '', workspace._id)
    refresh()

    props.onWorkspaceChange(response as any)
  }

  return (
    <Box
      width={'236px'}
      hideBar
      shadow={AppStore.theme.simpleOuterShadow}
      removeTransition
      style={{
        backgroundColor: AppStore.theme.o.surface,
        position: 'fixed',
        zIndex: 9999,
        padding: 8,
        paddingBottom: 12,
        maxHeight: 268,
        x: -112,
        y: +25,
        overflowY: 'auto',
      }}
      flex
    >
      <Box row m={'8px'}>
        <Input
          style={{ width: 144, height: '28px', marginRight: '8px', borderRadius: '8px' }}
          placeholder={T.teamMembers.search}
          onClick={(e) => e.stopPropagation()}
          prefix={<SearchOutlined />}
          onChange={(txt) => handleSearchText(txt)}
        />
        <Box
          center
          style={{ backgroundColor: AppStore.theme.o.lightGreen, borderRadius: '8px' }}
          width={'48px'}
          height={'28px'}
          onClick={() => {
            props.changeSection(`permissions?id=${roleID}`)
            AppStore.closeAllModal()
          }}
        >
          <EditIcon id={`workspace-row-`} width={16} height={16} />
        </Box>
      </Box>
      {filteredListOfRole.length > 0 ? (
        filteredListOfRole.map((role) => (
          <Box onClick={() => changeRole(role)} style={{ maxHeight: 32, padding: 8, paddingLeft: 16 }} vcenter row>
            <RoleText>{role}</RoleText>
          </Box>
        ))
      ) : (
        <AddNewRole newRoleName={searchText} />
      )}
    </Box>
  )
}

const RoleText = styled.p`
  font-weight: 500;
  font-size: 12px;
  color: ${() => AppStore.theme.o.darkGrey};
`

const AddRoleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${() => AppStore.theme.o.black};
`

const AddRoleIcon = styled(Icons.plus)`
  fill: ${() => AppStore.theme.o.black};
  width: 15px;
  height: 15px;
  margin-right: 12px;
  margin-left: 12px;
`

const EditIcon = styled(Icons.edit)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`
