import gsap from 'gsap'

class PinGsap {
  howManyAnimations = 0

  closeOpenPinedBox = (index: number) => {
    gsap.to(`#iconUser-${index}`, {
      duration: 0.3,
      rotate: 0,
      ease: 'linear',
    })

    gsap.set(`#pinedBox-${index}`, {
      duration: 0.3,
      scale: 1,
      zIndex: 9,
      width: 52,
    })
    gsap.set(`#confirmButton-${index}`, {
      duration: 0.3,
      autoAlpha: 0,
      ease: 'linear',
      zIndex: -1,
    })
    gsap.set(`#closeIcon-${index}`, {
      zIndex: 9999,
    })
  }
  openPinBox = (index: number) => {
    const tl = gsap.timeline()
    gsap.to(`#iconUser-${index}`, {
      duration: 0.3,
      rotate: 360,
      ease: 'linear',
    })
    gsap.to(`#pinedBox-${index}`, {
      duration: 0.3,
      scale: 1,
      zIndex: 9999,
      width: 450,
    })

    gsap.set(`#closeIcon-${index}`, {
      zIndex: -1,
    })
  }

  iconUser = (index: number, start: boolean) => {
    if (start) {
      gsap.to(`#iconUser-${index}`, {
        duration: 0.2,
        zIndex: 100,
        scale: 1.2,
        ease: 'linear',
      })
    } else {
      gsap.set(`#iconUser-${index}`, {
        duration: 0.3,
        zIndex: 99,
        scale: 1,
        ease: 'linear',
      })
    }
  }

  confirmButton = (index: number, start: boolean) => {
    if (start) {
      gsap.to(`#confirmButton-${index}`, {
        duration: 0.3,
        autoAlpha: 1,
        ease: 'linear',
        zIndex: 9999,
      })
    } else {
      gsap.to(`#confirmButton-${index}`, {
        duration: 0.3,
        autoAlpha: 0,
        ease: 'linear',
        zIndex: -1,
      })
    }
  }

  leftArrow = (start: boolean) => {
    if (start) {
      gsap.set('#leftArrow', {
        duration: 0.3,
        x: -10,
        ease: 'linear',
      })
    } else {
      gsap.set('#leftArrow', {
        duration: 0.3,
        x: 0,
        ease: 'linear',
      })
    }
  }
  rightArrow = (start: boolean) => {
    if (start) {
      gsap.set('#rightArrow', {
        duration: 0.3,
        x: 10,
        ease: 'linear',
      })
    } else {
      gsap.set('#rightArrow', {
        duration: 0.3,
        x: 0,
        ease: 'linear',
      })
    }
  }
}

export default new PinGsap()
