import styled from '@emotion/styled'

import { Box, Button, Icons, Text } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'

interface Props {
  filters: [{ item: string }] | undefined
  setFilters: (value: [{ item: string }]) => void
}

export default function FilterModal(props: Props) {
  const [selected, setSelected] = useState([{ item: '' }])
  const [hovered, setHovered] = useState({ item: '' })

  useEffect(() => {
    if (props.filters) {
      setSelected(props.filters)
    } else {
      setSelected([])
    }
  }, [])

  const types = [
    {
      name: 'JPG',
      value: 'jpeg',
    },
    {
      name: 'PNG',
      value: 'png',
    },
    {
      name: 'WEBP',
      value: 'webp',
    },

    {
      name: 'MP4',
      value: 'mp4',
    },

    {
      name: 'GIF',
      value: 'gif',
    },
  ]

  const mainType = [
    {
      name: T.mediaPage.images,
      value: 'image',
    },
    {
      name: 'Video',
      value: 'video',
    },
  ]

  useEffect(() => {
    props.setFilters(selected.length > 0 ? (selected as any) : undefined)
  }, [selected])

  const isSelected = (item: string) => {
    if (selected.find((s) => s.item === item) === undefined) {
      return false
    }
    return true
  }

  const addRemoveToSelected = async (item: string) => {
    if (isSelected(item)) {
      let selectedRemoved = selected.filter((s) => s.item !== item)

      await setSelected(selected.filter((s) => s.item !== item))

      let removeImg = 0

      for (const type of ['jpeg', 'png', 'webp']) {
        const selectedCopy = selectedRemoved.filter((s) => s.item === type).length

        if (selectedCopy >= 1) removeImg++
      }
      if (removeImg !== 3) {
        selectedRemoved = selectedRemoved.filter((s) => s.item !== 'image')
      }

      let removeVideo = 0
      for (const type of ['mp4', 'gif']) {
        const selectedCopy = selectedRemoved.filter((s) => s.item === type).length
        if (selectedCopy >= 1) removeVideo++
      }

      if (removeVideo !== 2) {
        selectedRemoved = selectedRemoved.filter((s) => s.item !== 'video')
      }

      setSelected(selectedRemoved)
    } else {
      let selectedAdded = [...selected, { item }]

      let addImg = true
      for (const type of ['jpeg', 'png', 'webp']) {
        const selectedCopy = selectedAdded.filter((s) => s.item === type).length
        if (selectedCopy <= 0) addImg = false
      }
      if (addImg) {
        selectedAdded = [...selectedAdded, { item: 'image' }]
      }

      let addVideo = true
      for (const type of ['mp4', 'gif']) {
        const selectedCopy = selectedAdded.filter((s) => s.item === type).length
        if (selectedCopy <= 0) addVideo = false
      }
      if (addVideo) {
        selectedAdded = [...selectedAdded, { item: 'video' }]
      }

      setSelected(selectedAdded)
    }
  }

  const ItemBox = (text: string) => {
    return (
      <Box flex>
        <TextTitle>{text}</TextTitle>
      </Box>
    )
  }

  return (
    <Box width={529} height={227} pv={32} ph={48} gap={24} vcenter relative>
      <Box style={{ width: 'auto' }} vcenter row>
        <Box flex>
          <TextTitle>{T.mediaPage.selectFormat}</TextTitle>
        </Box>
        <Box
          center
          style={{
            backgroundColor: AppStore.theme.o.lightGrey,
            border: `1px solid ${AppStore.theme.o.darkGrey}`,
            opacity: selected.length >= 1 ? 1 : 0,
          }}
          pv={4}
          ph={16}
          onClick={() => {
            setSelected([])
          }}
        >
          <Text
            fontSize={12}
            weight={700}
            style={{
              color: selected.length >= 1 ? AppStore.theme.o.black : AppStore.theme.o.darkGrey,
            }}
          >
            {T.mediaPage.deselectAll}
          </Text>
        </Box>
      </Box>

      <Box row gap={24} center>
        <GridMain>
          {mainType.map((type, index) => (
            <Box
              width={124}
              height={40}
              onClick={() => {
                addRemoveToSelected(type.value)
              }}
              style={{
                backgroundColor: isSelected(type.value) ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey,
                border: `1px solid ${
                  isSelected(type.value) ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey
                }`,
              }}
              center
              row
              gap={4}
            >
              {index === 0 ? (
                <Icons.newMedia fill={isSelected(type.value) ? AppStore.theme.o.blueOnLight : AppStore.theme.o.black} />
              ) : (
                <Icons.newVideo fill={isSelected(type.value) ? AppStore.theme.o.blueOnLight : AppStore.theme.o.black} />
              )}
              <TextInner
                style={{
                  color: isSelected(type.value) ? AppStore.theme.o.blueOnLight : AppStore.theme.o.black,
                }}
              >
                {type.name}
              </TextInner>
            </Box>
          ))}
        </GridMain>

        <GridSecondary>
          {types.map((item, index) => (
            <Box
              width={86}
              height={34}
              onClick={() => {
                addRemoveToSelected(item.value)
              }}
              style={{
                backgroundColor: isSelected(item.value) ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey,
                border: `1px solid ${
                  isSelected(item.value) ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey
                }`,
              }}
              center
              row
              vcenter
            >
              {/* {(index === 0 || index === 4) && <Icons.USA />} */}
              <TextInner
                style={{
                  color: isSelected(item.value) ? AppStore.theme.o.blueOnLight : AppStore.theme.o.black,
                }}
              >
                {item.name}
              </TextInner>
            </Box>
          ))}
        </GridSecondary>
      </Box>
    </Box>
  )
}

const GridMain = styled(Box)`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 16px;
`
const GridSecondary = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
`

const TextTitle = styled((props) => <Text {...props} />)`
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 21px !important;
  line-height: 27px !important;
  color: ${() => AppStore.theme.o.darkGrey}!important;
  /* identical to box height */
`

const TextInner = styled((props) => <Text {...props} />)`
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 18px !important;
`
