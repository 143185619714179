/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { TiktokAssets } from './TiktokAssets'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'

/* ------------------- INTERFACES  */
interface Props {}

export const ResultsPreviewTiktok = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  return (
    <ResultsPreviewTiktokContainer>
      <ContainterTiktok>
        <TiktokAssets imgSrc={activePost?.info?.account?.picture} />

        <MediaContainerPost width={'100%'} height={'100%'} arrowsPositions={30} />

        <PreviewText>
          {activePost?.data?.text?.length > 15 ? (
            <>
              {activePost?.data?.text?.slice(0, 15)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
            </>
          ) : (
            activePost?.data?.text
          )}
        </PreviewText>
      </ContainterTiktok>
    </ResultsPreviewTiktokContainer>
  )
}

const ResultsPreviewTiktokContainer = styled(Box)`
  height: 673px;
  width: 324px;
  margin: 0 auto;
  /* border: 1px solid ${() => AppStore.theme.o.grey}; */
  padding: 12px;
`

const ContainterTiktok = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`

const PreviewText = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  position: absolute;
  bottom: 85px;
  left: 15px;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
`
