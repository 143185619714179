/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ___________________ TYPE ___________________ */
type TSkeleton = 'article' | 'board'

interface Props {
  type: TSkeleton
  num: number
  key: string
}

const darkMode = localStorage.getItem('darkTheme')

let gradientColor = ''

if (darkMode === 'true') {
  gradientColor = `linear-gradient(
    120deg,
    hsla(0, 0%, 22%, 0.6) 20%,
    hsl(0, 0%, 22%) 38%,
    hsl(0, 0%, 22%) 40%,
    hsla(0, 0%, 22%, 0.6) 80%
  )`
}

if (darkMode === 'false') {
  gradientColor = `linear-gradient(
    120deg,
    hsl(0, 0%, 90%) 20%,
    hsl(0, 0%, 93%) 38%,
    hsl(0, 0%, 93%) 40%,
    hsl(0, 0%, 90%) 80%
  )`
}

const SkeletonCard = ({ type, num = 1, key }: Props) => {
  const enumSkeletonToView = {
    article: <SkeletonArticle key={key} />,
    board: <SkeletonBoard key={key} />,
  }

  return <>{Array.from(Array(num)).map((ar) => enumSkeletonToView[type])}</>
}

export default SkeletonCard

const SkeletonArticle = styled(Box)`
  width: 351px;
  height: 423px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`

const SkeletonBoard = styled(Box)`
  width: 356px;
  height: 312px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;

  background: ${gradientColor};

  background-size: 200% 100%;
  background-position: 100% 0;
  animation: loading-skeleton 1.5s infinite;
`
