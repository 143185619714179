/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  forceUpdatePage: () => void
}

const PlaceholderPage = ({ forceUpdatePage }: Props) => {
  /* ____________________ STATE ____________________ */
  const [loaderProgress, setLoaderProgress] = useState<number>(0)
  const [userNoPermission, setUserNoPermission] = useState<boolean>(false)

  /* ____________________ METHODS ____________________ */
  const loaderHandler = () => {
    let progress = 0
    const interval = setInterval(() => {
      if (progress >= 100) {
        forceUpdatePage()
        clearInterval(interval)
        setUserNoPermission(true)
        return
      }
      progress += 5
      setLoaderProgress(progress)
    }, 100)
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    loaderHandler()
  }, [])

  return (
    <PlaceholderPageWrapper>
      <PlaceholderContainer>
        {/* --------------- ICON --------------- */}
        <PlaceholderIcon className="placeholder-icon">
          <Icons.inbox fill={'#6d4a05'} width={54.75} height={54.75} />
        </PlaceholderIcon>
        {/* --------------- TEXT --------------- */}
        <TextContainer>{T.AiDiscoverPage.placeholderPageText}</TextContainer>
        {/* --------------- LOADER --------------- */}
        <LoaderContainer>
          {/* --------------- CHECK-PERMISSIONS --------------- */}
          {/* ----- LOADER TEXT ----- */}
          {!userNoPermission && <LoaderText>{T.AiDiscoverPage.placeholderLoadingText}</LoaderText>}
          {/* ----- LOADER PROGRESS-BAR ----- */}
          {!userNoPermission && (
            <LoaderProgress>
              <span style={{ width: `${loaderProgress}%` }}></span>
            </LoaderProgress>
          )}
          {/* --------------- USER DONT HAVE PERMISSIONS --------------- */}
          {userNoPermission && (
            <UserNoPermissionsText>{T.AiDiscoverPage.placeholderNoPermissions}</UserNoPermissionsText>
          )}
        </LoaderContainer>
      </PlaceholderContainer>
    </PlaceholderPageWrapper>
  )
}

export default PlaceholderPage

const PlaceholderPageWrapper = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PlaceholderContainer = styled(Box)`
  max-width: 578px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`
const PlaceholderIcon = styled(Box)`
  min-width: 73px;
  min-height: 73px;
  background-color: ${() => AppStore.theme.o.yellow};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
const TextContainer = styled(Box)`
  font-size: 16px;
  font-weight: 400;
  line-height: 20.48px;
  color: ${() => AppStore.theme.o.black};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 24px;
  border-radius: 24px;
`

const LoaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const LoaderText = styled(Box)`
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.15px;
  color: ${() => AppStore.theme.o.blueOnLight};
`

const LoaderProgress = styled(Box)`
  width: 578px;
  background-color: ${() => AppStore.theme.o.lightGrey};
  height: 4px;
  border-radius: 99px;
  position: relative;

  span {
    width: 50px;
    height: 4px;
    background-color: ${() => AppStore.theme.o.blueOnLight};
    border-radius: 99px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    transition: 0.3s ease;
  }
`

const UserNoPermissionsText = styled(Box)`
  width: 578px;
  font-size: 15px;
  font-weight: 700;
  line-height: 19.2px;
  letter-spacing: 0.15px;
  color: #211d12;
  background-color: #fbeac1;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 16px;

  border-radius: 24px;
`
