import AstersBaseResource from './AstersBaseResource'
import { api } from './api'

export default class ApiKeys extends AstersBaseResource {
  static endpoint = '/apikeys'

  static getApiKeys() {
    return api.get<any>(`${this.endpoint}/`)
  }

  static createApiKey(apikeyName: string, workspaceId: string, scopes: { id: string, label: string }[]) {
    return api.post<any>(`${this.endpoint}/`, { workspace: workspaceId, scopes, name: apikeyName })
  }

  static deleteApiKey(key: string) {
    return api.delete<any>(`${this.endpoint}/`)
  }
}
