import styled from '@emotion/styled'
import { loadStripe } from '@stripe/stripe-js'
import Utils from 'api/Utils'
import { AnimatedPlaceholderLogo, Box, Button, Stepper, Text } from 'components'
import { TStepOption } from 'components/Stepper'
import { DarkLottiePaymentInProgrees } from 'pages/demo/Lotties/DarkLottiePaymentInProgrees'
import { LightLottiePaymentInProgges } from 'pages/demo/Lotties/LightLottiePaymentInProgges'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, getDaysDiff, getPlanType, showError, showSuccess } from 'utils'
import { navigate } from 'utils/router'
import AfterPayment from './AfterPaymentComponent/AfterPayment'
import AddOnPayment from './Subscription/AddOnPayment'
import { SubscriptionPlanSection } from './Subscription/SubscriptionSections/SubscriptionPartOne'
import SubscriptionPartThree from './Subscription/SubscriptionSections/SubscriptionPartThree'
import SubscriptionPartTwo from './Subscription/SubscriptionSections/SubscriptionPartTwo'
import { Service, SubscriptionOption, SubscriptionProvider } from './Subscription/SubscriptionProvider'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getSubscriptionData, getSubscriptionCheckoutData } from 'utils/eventsTracker'
import { SubscribtionStartingState, SubscriptionPlanMode } from 'types/SettingsInterfaces'
import { useLocation, useParams } from 'react-router-dom'

interface Props { }

const SubscriptionSection = ({ }: Props) => {
  const [subscriptionMode, setSubscriptionMode] = useState<SubscriptionPlanMode>(
    // AppStore.upgrading ? 'annual' : 'monthly'
    AppStore.loggedUser.rawPlan?.prices.some((p) => p.id.includes('ANNUAL')) ? 'annual' : 'monthly'
  )
  const [additionalOptions, setAdditionalOptions] = useState<Service[]>(
    JSON.parse(JSON.stringify(SubscriptionProvider.customizableOptions))
  )
  const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionOption[]>(
    JSON.parse(JSON.stringify(SubscriptionProvider.billableOptions))
  )
  const [steps, setSteps] = useState<TStepOption[]>(JSON.parse(JSON.stringify(SubscriptionProvider.subscriptionSteps)))
  const [loading, setLoading] = useState<boolean>(true)
  const [ActiveSubScription, setActiveSubScription] = useState<number>(getPlanType())
  const [externalAddOn, setExternalAddOn] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [startingState, setStartingState] = useState<SubscribtionStartingState | undefined>(undefined)

  /* ------------------------------- ROUTER SERVICE */
  const subsection = useParams().subsection
  const locationState = useLocation().state

  /*   ------------------------------- VARIABLES */
  const activeOption = subscriptionOptions.find((option) => option.active)
  const activeStep = steps.reduce((acc, curr) => (curr.done ? curr : acc), steps[0])

  /* ------------------------------- METHODS */
  const getInitialReferrer = () => {
    const { from } = locationState ? locationState : { from: 'settings' }
    let initialreferrer = 'settings'
    if (from === 'header') initialreferrer = 'Menu Top'
    else if (from === 'settings') initialreferrer = 'Settings'

    return initialreferrer
  }

  //event:Plan customization page is loaded
  const trackPlanCustomizationPageViewed = () => {
    if (activeOption) {
      Tracker.trackEvent(TRACKEREVENTS.PLAN_CUSTOMIZATION_PAGE_VIEWED, {
        initial_referrer: getInitialReferrer(),
        ...getSubscriptionData(subscriptionMode, activeOption),
      })
    }
  }

  const trackPlanCheckout = () => {
    // Verifica se ci sono opzioni attive
    if (activeOption) {
      Tracker.trackEvent(TRACKEREVENTS.PLAN_CHECKOUT_PAGE_VIEWED, {
        initial_referrer: getInitialReferrer(),
        ...getSubscriptionData(subscriptionMode, activeOption),
        ...getSubscriptionCheckoutData({ mode: subscriptionMode, additionalOpt: additionalOptions }, activeOption),
      })
    }
  }

  const setActiveSubscriptionOption = async (index: number) => {
    const newActiveSubOpt = subscriptionOptions

    newActiveSubOpt.forEach((option, i) => {
      if (i === index) option.active = true
      else option.active = false
    })

    setSubscriptionOptions([...newActiveSubOpt])
  }

  const setTypeSubscriptionMode = async (index: number) => {
    setSubscriptionMode(index === 1 ? 'monthly' : 'annual')
  }

  const partChange = () => {
    const active = getPlanType()
    const { part } = locationState ? locationState : { part: '' }
    if (part === 'customization') {
      const allSteps = steps
      setActiveSubscriptionOption(active)
      setTypeSubscriptionMode(1)

      allSteps.forEach((step) => {
        if (step.key === 'customization') {
          step.active = true
          step.done = true
        }
        if (step.key === 'choose') {
          step.active = false
        }
      })

      setSteps([...allSteps])
      setExternalAddOn(true)
    }
  }

  const ChangingSubscription = (goToChangeSub?: boolean) => {
    const { part } = locationState ? locationState : { part: '' }
    if (part === 'ChangingSubscription' || goToChangeSub) {
      // this.setState({ externalAddOn: true })
    }
  }

  const goToChangingSubscription = () => {
    ChangingSubscription(true)
    AppStore.loggedUser.stripe = undefined
    // AppStore.loggedUser!._stripe = AppStore.loggedUser!.stripe
    setRefresh((prev) => !prev)
  }

  const fetchData = async () => {
    try {
      AppStore.mySubscription = await Utils.getMySubscription()
    } catch (error) { }

    setLoading(false)
    if (subsection === 'addon') {
    } else {
      try {
        if (!AppStore.loggedUser.stripe?.customerId) await Utils.createStripeCustomer()
      } catch (error) {
        showError(error)
      }
    }
  }

  useEffect(() => {
    if (activeStep.key === 'choose') {
      Tracker.trackEvent(TRACKEREVENTS.PLAN_PRICING_PAGE_VIEWED, { initial_referrer: getInitialReferrer() })
    }

    const urlParams = new URLSearchParams(window.location.search)

    const plan = urlParams.get('plan')
    const cycle = urlParams.get('cycle')
    const coupon = urlParams.get('coupon') ?? ''

    if ((plan === 'pro' || plan === 'business' || plan === 'agency') && (cycle === 'yearly' || cycle === 'monthly')) {
      navigate('/settings/subscription', { state: { part: 'ChangingSubscription' } })

      if (AppStore.loggedUser.rawPlan !== undefined) {
        AppStore.loggedUser!._rawPlan = AppStore.loggedUser!.rawPlan
        AppStore.loggedUser!._stripe = AppStore.loggedUser!.stripe
      }

      setStartingState({ plan, cycle, coupon })

      setActiveSubscriptionOption(plan === 'pro' ? 0 : plan === 'business' ? 1 : 2)
      setTypeSubscriptionMode(cycle === 'monthly' ? 1 : 0)

      const allSteps = steps
      allSteps.find((step) => step.key === 'customization')!.active = true
      allSteps.find((step) => step.key === 'customization')!.done = true
      allSteps.find((step) => step.key === 'choose')!.active = false
      allSteps.find((step) => step.key === 'customization')!.active = false
      allSteps.find((step) => step.key === 'customization')!.done = true
      allSteps.find((step) => step.key === 'choose')!.active = false
      allSteps.find((step) => step.key === 'choose')!.active = true
      allSteps.find((step) => step.key === 'choose')!.done = true
      allSteps.find((step) => step.key === 'customization')!.done = true
      allSteps.find((step) => step.key === 'customization')!.active = false
      allSteps.find((step) => step.key === 'checkout')!.active = true
      allSteps.find((step) => step.key === 'checkout')!.done = true
      allSteps.find((step) => step.key === 'customization')!.active = false

      setSteps([...allSteps])
      setExternalAddOn(false)
    }

    let active = 0

    if (AppStore.loggedUser.rawPlan?.plan === 'pro') active = 1
    else if (AppStore.loggedUser.rawPlan?.plan === 'business') active = 1
    else active = 2

    if (AppStore.loggedUser.rawPlan?.plan !== undefined) {
      subscriptionOptions.forEach((option, i) => (option.active = i === active))
    }

    fetchData()
    partChange()
    ChangingSubscription()
  }, [])

  if (loading) {
    return <AnimatedPlaceholderLogo containerStyle={{ backgroundColor: 'transparent' }} />
  }

  if (subsection === 'addon') {
    return <AddOnPayment />
  }

  if (AppStore.loggedUser.newSubscription?.status === 'incomplete') {
    return (
      <Box animation="fade" height={0} flex center>
        <Text style={{ marginBottom: 20, fontSize: 20 }}>{T.settings.cardWasentAproved}</Text>
        <Box row style={{ gap: 16 }}>
          <Button
            variant="secondary-small"
            onClick={async () => {
              const secret = AppStore.loggedUser.newSubscription?.latest_invoice.payment_intent.client_secret
              if (!secret) return
              const stripe = await loadStripe(AppStore.settings.stripe.apiKey)
              const res = await stripe?.confirmCardPayment(secret!)
            }}
          >
            {T.settings.retry}
          </Button>
          <Button
            variant="delete-small"
            onClick={() => {
              Utils.cancelSubscription()
            }}
          >
            {T.cancelPlan.cancel}
          </Button>
        </Box>
      </Box>
    )
  }

  if (AppStore.loggedUser.stripe?.subscriptionId && !AppStore.loggedUser.rawPlan && !AppStore.loggedUser._rawPlan) {
    const diff = getDaysDiff(AppStore.loggedUser?.freeTrial) * -1

    return (
      <Box animation="fade" height={0} flex center style={{ gap: 24 }}>
        <Box center p={48} style={{ backgroundColor: AppStore.theme.o.lightGrey }}>
          {diff <= 0 ? (
            <Text style={{ fontSize: 18, fontWeight: 700 }}>{T.settings.weAreProccesingThePayment}</Text>
          ) : (
            <Text style={{ fontSize: 18, fontWeight: 700 }}>
              A fine della tua prova gratuita inzierà il tuo abbonamento selezionato
              {/* At the end of your free trial, your selected subscription will start */}
            </Text>
          )}
          {/* ------------------------------------- Parte lotti */}

          <Box style={{ width: 208, height: 208 }} mb={16}>
            {AppStore.darkTheme ? <DarkLottiePaymentInProgrees /> : <LightLottiePaymentInProgges />}
          </Box>
          {/* ------------------------------------- Parte Buttons */}

          <Box row vcenter mt={48} style={{ gap: 24 }}>
            <Button variant="neutral" style={{ minWidth: 200 }} onClick={goToChangingSubscription}>
              <ButtonText style={{ fontWeight: 700, color: AppStore.theme.o.green }}>
                {T.settings.modifyPlan}
              </ButtonText>
            </Button>
            <Button
              variant="delete-small"
              style={{ minWidth: 200 }}
              onClick={() => {
                showSuccess(T.cancelPlan.cancel_done_whith_success)
                Utils.cancelSubscription()
                setTimeout(() => {
                  window.location.reload()
                }, 2000)
              }}
            >
              <ButtonText style={{ fontWeight: 700, color: AppStore.theme.o.red }}>
                {T.cancelPlan.cancelTotalPlan}
              </ButtonText>
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }
  // -------------------------------------
  const expirationDate = AppStore.loggedUser.rawPlan?.expirationDate
  // if expirationDate (ex. 2022-10-11T22:14:50.000Z) is before now
  if (
    expirationDate &&
    new Date(expirationDate).getTime() > new Date().getTime() &&
    !AppStore.loggedUser._rawPlan &&
    startingState === undefined
  ) {
    return <AfterPayment refreshParent={() => /* forceUpdate() */ { }} />
  }

  if (AppStore.upgrading) {
    setSubscriptionMode(AppStore.upgrading ? 'annual' : 'monthly')
    AppStore.upgrading = false
  }

  if (locationState.part === 'customization' && activeStep.key !== 'customization') {
    setTimeout(() => {
      const allSteps = steps
      allSteps.find((step) => step.key === 'customization')!.active = true
      allSteps.find((step) => step.key === 'customization')!.done = true
      allSteps.find((step) => step.key === 'choose')!.active = false
      setSteps([...allSteps])
      setExternalAddOn(true)
    }, 300)
  }

  if (activeStep.key === 'customization') trackPlanCustomizationPageViewed()
  else if (activeStep.key === 'checkout') trackPlanCheckout()

  return (
    <Box p={5} animation="fade" height={0} flex>
      <Box flex scroll pv={48} pl={80} pr={activeStep.key === 'checkout' ? 45 : 80} style={{ overflow: 'hidden' }}>
        <Stepper style={{ width: 550, minHeight: 50, maxHeight: 50, marginBottom: 32 }} steps={steps} />
        {activeStep.key === 'choose' && (
          <SubscriptionPlanSection
            setToActivePlan={startingState === undefined}
            forceUpdate={() => { } /* forceUpdate() */}
            setActiveSubscriptionOption={setActiveSubscriptionOption}
            setSubscriptionMode={setTypeSubscriptionMode}
            subscriptionMode={subscriptionMode}
            subscriptionOptions={subscriptionOptions}
            onNext={() => {
              const allSteps = steps
              allSteps.find((step) => step.key === 'customization')!.active = true
              allSteps.find((step) => step.key === 'customization')!.done = true
              allSteps.find((step) => step.key === 'choose')!.active = false
              setSteps([...allSteps])
            }}
          />
        )}

        {activeStep.key === 'customization' && (
          <SubscriptionPartTwo
            externalAddOn={externalAddOn}
            additionalOptions={additionalOptions}
            subscriptionMode={subscriptionMode}
            subscriptionOptions={subscriptionOptions}
            changeAdditionalOption={(addOpts) => setAdditionalOptions(addOpts)}
            onNext={() => {
              const allSteps = steps
              allSteps.find((step) => step.key === 'checkout')!.active = true
              allSteps.find((step) => step.key === 'checkout')!.done = true
              allSteps.find((step) => step.key === 'customization')!.active = false
              setSteps([...allSteps])
            }}
            onBack={() => {
              const allSteps = steps
              allSteps.find((step) => step.key === 'choose')!.active = true
              allSteps.find((step) => step.key === 'choose')!.done = true
              allSteps.find((step) => step.key === 'customization')!.done = false
              allSteps.find((step) => step.key === 'customization')!.active = false

              setSteps([...allSteps])
            }}
          />
        )}

        {activeStep.key === 'checkout' && (
          <SubscriptionPartThree
            externalAddOn={externalAddOn}
            additionalOptions={additionalOptions}
            setActiveSubscriptionOption={setActiveSubscriptionOption}
            setSubscriptionMode={setTypeSubscriptionMode}
            subscriptionMode={subscriptionMode}
            subscriptionOptions={subscriptionOptions}
            preselectedCuppon={startingState?.coupon}
            onNext={() => {
              const allSteps = steps
              allSteps.forEach((step) => (step.done = false))
              allSteps.find((step) => step.key === 'choose')!.done = true
              allSteps.find((step) => step.key === 'choose')!.active = true
              allSteps.find((step) => step.key === 'checkout')!.active = false

              setSteps([...allSteps])
            }}
            onBack={() => {
              const allSteps = steps
              allSteps.find((step) => step.key === 'customization')!.active = true
              allSteps.find((step) => step.key === 'customization')!.done = true
              allSteps.find((step) => step.key === 'checkout')!.done = false
              allSteps.find((step) => step.key === 'checkout')!.active = false
              setSteps([...allSteps])
            }}
          />
        )}
      </Box>
    </Box>
  )
}

export default SubscriptionSection

const ButtonText = styled((props) => <Text {...props} />)`
  font-size: 24px;
  font-weight: 700;
`
