import { IReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { api, responseErrorCheck } from './api'
import AstersBaseResource from './AstersBaseResource'
import { AppStore } from 'utils'
import ReactGridLayout from 'react-grid-layout'

// <-------------------------INFERFACE------------------------->

export default class Reports extends AstersBaseResource {
  static endpoint = '/reports'

  static async createReport(reportConfig: IReportConfig) {
    return api
      .post<any>(`${this.endpoint}/create/${AppStore.getWorkspaceId()}`, {
        reportConfig,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async updateReport(reportConfig: IReportConfig) {
    return api
      .put<any>(`${this.endpoint}/update/${AppStore.getWorkspaceId()}/${reportConfig.reportId}`, {
        reportConfig,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async getReportList() {
    return api
      .get<any>(`${this.endpoint}/reportList/${AppStore.loggedUser._id}/${AppStore.getWorkspaceId()}`)
      .then(responseErrorCheck)
  }

  static async getReport(reportId: string) {
    return api
      .get<any>(
        `${this.endpoint}/report/${reportId}/${AppStore.getWorkspaceId()}/${AppStore.getLanguageValue() ?? 'it'}`
      )
      .then(responseErrorCheck)
  }

  static async changeReportName(reportId: string, reportName: string, isReportCard: boolean) {
    return api
      .put<any>(`${this.endpoint}/changeName/${reportId}/${AppStore.getWorkspaceId()}`, {
        reportName,
        returnCompressed: isReportCard,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async changeReportLogo(reportId: string, reportName: string) {
    return api
      .put<any>(`${this.endpoint}/changeLogo/${reportId}/${AppStore.getWorkspaceId()}`, {
        reportName,
        language: AppStore.getLanguageValue() ?? 'it',
      })
      .then(responseErrorCheck)
  }

  static async deleteReport(reportId: string | string[]) {
    return api
      .delete<any>(`${this.endpoint}/delete/${AppStore.getWorkspaceId()}`, { reportId })
      .then(responseErrorCheck)
  }

  static async checkReportName(reportName: string): Promise<boolean> {
    return api
      .get<boolean>(`${this.endpoint}/checkReportName/${AppStore.getWorkspaceId()}/${reportName}`)
      .then(responseErrorCheck)
  }

  static async updateGraphPosition(reportLayout: ReactGridLayout.Layout[], reportId: string): Promise<IReportConfig> {
    return api
      .put<IReportConfig>(
        `${this.endpoint}/updatePosition/${AppStore.getWorkspaceId()}/${
          AppStore.getLanguageValue() ?? 'it'
        }/${reportId}`,
        {
          reportData: reportLayout,
        }
      )
      .then(responseErrorCheck)
  }
}
