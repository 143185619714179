import styled from '@emotion/styled'
import { Box } from 'components/Box'
import React from 'react'
import { AppStore, T } from 'utils'
import { Icons } from 'components/Icons'
import { AnimatedPlaceholderLogo } from 'components/AnimatedPlaceholderLogo'
import { IApiKeys } from 'types/SettingsInterfaces'
import ApiKeySkeletonItem from './ApiKeySkeletonItem'
import { truncateText } from 'pages/ai-discover/commonFunction'

interface Props {
  apiKeys: IApiKeys[] | null
  selectedApiKeys: string[]
  onSelectApiKey: (str: string) => void
}

const ApiKeyGrid = ({ apiKeys, selectedApiKeys, onSelectApiKey }: Props) => {
  /* ------------------ ENUM ------------------ */
  const iconEnum: any = {
    analytics: <Icons.analytics fill={'currentColor'} width={18} height={18} />,
    autopilot: <Icons.autopilotElectric fill={'currentColor'} width={18} height={18} />,
  }

  /* ------------------ METHODS ------------------ */
  const onFormatDate = (date: Date | string) => {
    const dataOra = new Date(date)
    const giorno = String(dataOra.getDate()).padStart(2, '0')
    const mese = String(dataOra.getMonth() + 1).padStart(2, '0')
    const anno = String(dataOra.getFullYear()).slice(2)
    const ore = String(dataOra.getHours()).padStart(2, '0')
    const minuti = String(dataOra.getMinutes()).padStart(2, '0')

    return `${mese}/${giorno}/${anno} ${'alle'} ${ore}:${minuti}`
  }

  const onAddAsteriskOnKey = (key: string) => {
    return key.replace(/(.*)(.{3})/, (_, sostituire, mantenere) => sostituire.replace(/./g, '*') + mantenere)
  }
  return (
    <ApiKeysContainer>
      {/* ----------------------- API HEADER TABLE ----------------------- */}
      <ApiHeaderTable>
        <CellName className="fw-7">
          <CellNameSelector onClick={() => onSelectApiKey('all')}>
            <CellNameSelectorCircle
              className={`${selectedApiKeys.length > 0 && selectedApiKeys.length === apiKeys?.length ? 'active' : ''}`}
            />
          </CellNameSelector>
          {T.settings.apiSection.table.name}
        </CellName>
        <CellKey className="fw-7">{T.settings.apiSection.table.key}</CellKey>
        <CellDate className="fw-7">{T.settings.apiSection.table.createdTime}</CellDate>
        <CellCreateFrom className="fw-7">{T.settings.apiSection.table.createdBy}</CellCreateFrom>
        <CellScope className="fw-7">{T.settings.apiSection.table.scopes}</CellScope>
      </ApiHeaderTable>
      {/* ----------------------- SCROLL CONTAINER ----------------------- */}
      <ScrollContainer>
        {/* ----------------------- API ROW TABLE ----------------------- */}
        {apiKeys &&
          apiKeys.length > 0 &&
          apiKeys.map((k) => (
            <ApiRowTable>
              <CellName className="fw-7">
                <CellNameSelector onClick={() => onSelectApiKey(k.id)}>
                  <CellNameSelectorCircle className={`${selectedApiKeys.includes(k.id) ? 'active' : ''}`} />
                </CellNameSelector>
                <CellNameText data-hover={k.name}>{truncateText(k.name, 20)}</CellNameText>
              </CellName>
              <CellKey>{onAddAsteriskOnKey(k.key)}</CellKey>
              <CellDate className="color-dark-grey">{onFormatDate(k.created_at)}</CellDate>
              <CellCreateFrom className="color-dark-grey" data-hover={k.createdBy}>
                {truncateText(k.createdBy, 35)}
              </CellCreateFrom>
              <CellScope>
                {k.scopes.length > 0 &&
                  k.scopes.map((s) => <CellScopeIconContainer>{iconEnum[s.label.toLowerCase()]}</CellScopeIconContainer>)}
              </CellScope>
            </ApiRowTable>
          ))}

        {apiKeys && apiKeys.length === 0 && <ApiRowTable className="empty">Non sono presenti API key</ApiRowTable>}
        {!apiKeys && <ApiKeySkeletonItem numOfRow={6} />}
      </ScrollContainer>
    </ApiKeysContainer>
  )
}

export default ApiKeyGrid

const ApiKeysContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0;
`

const CellName = styled(Box)`
  position: relative;
  flex: 1.2;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: ${() => AppStore.theme.o.black};
`

const CellNameText = styled(Box)`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.645px;
  border-radius: 0;
  flex-shrink: 1;
  color: ${() => AppStore.theme.o.black};

  &::after {
    padding: 8px 12px;
    content: attr(data-hover);
    opacity: 0;
    transition: 0.3s ease-in-out;
    position: absolute;
    left: 25px;
    top: 0;
    bottom: -1;
    text-wrap: nowrap;
    overflow: hidden;
  }

  &:hover {
    &::after {
      transition: 0.3s ease-in-out;
      opacity: 1;
      content: attr(data-hover);
      position: absolute;
      text-wrap: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      left: 25px;
      top: -10px;
      bottom: -1px;
      padding: 8px 12px;
      background-color: ${() => AppStore.theme.o.surface};
      transition: 0.3s ease;
      color: ${() => AppStore.theme.o.black};
      border-radius: 14px;
      z-index: 1;
    }
  }
`

const CellNameSelector = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
  padding-right: 12px;
  border-radius: 0;

  &.in-row {
    padding-left: 9px;
  }

  &:hover {
    background-color: transparent;
  }
`

const CellNameSelectorCircle = styled(Box)`
  min-height: 14px;
  min-width: 14px;
  max-height: 14px;
  max-width: 14px;
  border-radius: 50%;
  background-color: ${() => AppStore.theme.o.lightGrey};

  &.active {
    background-color: ${() => AppStore.theme.o.green};
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.green};
    opacity: 0.6;
  }
`

const CellKey = styled(Box)`
  flex: 1;

  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.645px;
  border-radius: 0;
  color: ${() => AppStore.theme.o.black};
`

const CellDate = styled(Box)`
  flex: 1;

  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.645px;
  border-radius: 0;
  color: ${() => AppStore.theme.o.black};
`

const CellCreateFrom = styled(Box)`
  flex: 1.2;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.645px;
  border-radius: 0;
  color: ${() => AppStore.theme.o.black};
  position: relative;

  &::after {
    padding: 8px 12px;
    content: attr(data-hover);
    opacity: 0;
    transition: 0.3s ease-in-out;
    position: absolute;
    left: 0;
    top: 0;
    bottom: -1;
    text-wrap: nowrap;
    overflow: hidden;
  }

  &:hover {
    &::after {
      transition: 0.3s ease-in-out;
      opacity: 1;
      font-size: 14px;
      content: attr(data-hover);
      position: absolute;
      text-wrap: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      left: 0;
      top: -10px;
      bottom: -1px;

      padding: 8px 12px;
      background-color: ${() => AppStore.theme.o.surface};
      transition: 0.3s ease;
      color: ${() => AppStore.theme.o.black};
      border-radius: 14px;
      z-index: 1;
    }
  }
`

const CellScope = styled(Box)`
  flex: 0.5;
  overflow: hidden;
  align-self: 'flex-end';
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.645px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 0;
  color: ${() => AppStore.theme.o.black};
`

const CellScopeIconContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${() => AppStore.theme.o.primary_container};
  color: ${() => AppStore.theme.o.primary};
  height: 24px;
  width: 24px;
  border-radius: 8px;
`

const ApiHeaderTable = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 40px 12px 16px;
  gap: 20px;
  border-radius: 0;
  border-bottom: 1px solid ${() => AppStore.theme.o.grey};

  .fw-7 {
    font-weight: 700;
  }
`

const ApiRowTable = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 40px 12px 16px;

  gap: 20px;
  border-radius: 0;
  border-bottom: 1px solid ${() => AppStore.theme.o.grey};

  &.empty {
    font-size: 16px;
    font-weight: 800;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 48px 0;
  }

  .fw-7 {
    font-weight: 700;
  }

  .color-dark-grey {
    color: #676970;
  }
`

const ScrollContainer = styled(Box)`
  border-radius: 0;
  flex: 1;
  max-height: calc(375px - 20px);

  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`
