import gsap from 'gsap'

// cupponSpawn è id del coupon che spawnera on click del aply cuppon
// cupponContainer è id del container che contiene di cuppon

class SubscriptionAnimation {
  howManyAnimations = 0

  addCoupon(id: string) {
    try {
      gsap.to(`#${id}`, { duration: 0.5, opacity: 1, x: 0, ease: 'power2.out', smoothOrigin: true })
    } catch (e) {
      console.error(e)
    }
  }

  removeCoupon(id: string) {
    try {
      gsap.to(`#${id}`, {
        duration: 0.5,
        opacity: 1,
        height: 40,
        width: 120,
        // backgroundColor: 'red',
        x: -1000,

        ease: 'power2.out',
        // smoothOrigin: true,
      })
    } catch (e) {
      console.error(e)
    }
  }

  increseWidth(id: string, height: number, increse?: boolean) {
    try {
      gsap.to(`#${id}`, { duration: increse ? 1 : 0.3, height: height, ease: 'power2.out', smoothOrigin: true })
    } catch (e) {
      console.error(e)
    }
  }
}

export default new SubscriptionAnimation()
