/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons, Text } from 'components'
import { SubscriptionOption } from './SubscriptionProvider'
import { AppStore } from 'utils'
import { ServiceElement } from './ServiceElement'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  selectedOption: SubscriptionOption
}

export const ServicesDisplay = ({ selectedOption }: Props) => {
  return (
    <ServicesDisplayContainer>
      {selectedOption?.services?.map((service) => (
        <ServiceElement service={service} selectedPlan={selectedOption} />
      ))}
    </ServicesDisplayContainer>
  )
}

const ServicesDisplayContainer = styled(Box)`
  display: grid;
  grid-template-columns: 216px 208px;
  grid-gap: 10px;
  column-gap: 48px;
  justify-content: space-between;
  margin: 24px 4px;
  padding: 16px 42px;
  align-self: stretch;
  max-width: 639px;
  border: 1px dashed ${AppStore.theme.o.darkGrey};
`
