/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'

/* ------------------- INTERFACES  */

interface Props {
  sidebar?: React.ReactNode
  content: React.ReactNode
  navigation: React.ReactNode
}

export const PageStructure = ({ sidebar, content, navigation }: Props) => {
  return (
    <PageStructureContainer className="page_structure">
      {sidebar && sidebar}
      {content}
      {navigation}
    </PageStructureContainer>
  )
}

const PageStructureContainer = styled(Box)`
  &.page_structure {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 40px;
    position: relative;
    flex: 1;
    max-height: calc(100% - 24px);
    padding-top: 10px;
  }
`
