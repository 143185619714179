import Auth from 'api/Auth'
import { Box, Text } from 'components'
import { Component } from 'react'
import { AppStore, showError } from 'utils'
import { history } from 'utils/router'

export class MobileDetection extends Component {
  async componentDidMount() {
    if (history.location.pathname === '/token') {
      await AppStore.setToken(history.location.search.substring(14))
    }
    if (history.location.pathname.includes('confirmUser')) {
      try {
        const token = await Auth.confirmUser(history.location.search.substring(11))
        await AppStore.setToken(token)
      } catch (error) {
        await AppStore.setToken()
      }
    }
    if (history.location.pathname.includes('acceptNewEmail')) {
      try {
        const token = await Auth.acceptNewEmail(history.location.search.substring(7))
        await AppStore.setToken(token)
      } catch (error) {
        await AppStore.setToken()
        showError(error)
      }
    }

    await AppStore.init()
  }

  render() {
    return (
      // <Box center>
      //   <Text style={{ color: '#111', fontSize: 16, width: '100vw', textAlign: 'center' }}>
      //     Asters al momento è disponibile da desktop. Aprilo con il tuo PC per accedere subito.
      //   </Text>
      // </Box>
      <iframe
        src="https://lp.asters.ai/registrazione/mobile/"
        frameBorder="0"
        style={{ width: '100%' }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    )
  }
}
