import styled from '@emotion/styled'
import { Select } from 'antd'
import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import { Box, Button, EditableInput, Icons, Spacer, Text, Title } from 'components'
import { Component, useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { IPhoneNumber } from 'types/NotifyInterface'
import RequestNumber from 'ui/RequestNumber/RequestNumber'
import { AppStore, showComingSoonToast, showError, showSuccess, getLastUpdateDate } from 'utils'
import { TRACKEREVENTS, getAccountSettings } from 'utils/eventsTracker'
import { version } from '../../../package.json'
import { isVisible } from 'utils/CheckVisibleElement'
const { Option } = Select

const AccountSection = () => {
  const [email, setEmail] = useState<string>(AppStore.loggedUser.email)
  const [name, setName] = useState<string>(AppStore.loggedUser.name)
  const [password, setPassword] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<IPhoneNumber>(
    AppStore.loggedUser.phoneNumber ?? { prefix: '', number: '', otpCode: null }
  )

  const handleLogOut = () => {
    try {
      Auth.logout()
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_SUCCESSFUL, {})
    } catch (error) {
      Tracker.trackEvent(TRACKEREVENTS.LOGOUT_FAILED, { error_message: 'An error occured. Please try again later' })
    }
  }

  const onFirstMount = () => {
    Tracker.trackEvent(TRACKEREVENTS.ACCOUNT_PAGE_VIEWED, getAccountSettings())
    //get history from url and get the params
    const urlParams = new URLSearchParams(window.location.search)
    const history = urlParams.get('newEmail')
    if (history === 'true') {
      showSuccess(T.success.emailUpdated)
    }
  }

  useEffect(() => {
    onFirstMount()
  }, [])

  return (
    <Box animation="fade" flex mv={64} mh={80}>
      <div id="">
        <TitleContainer>
          <Title style={{ color: AppStore.theme.o.green }}>{T.settings.accountData}</Title>
          {isVisible('settings.account.titleWhatsApp') && (
            <Title style={{ color: AppStore.theme.o.green }}>{T.settings.whatsapp}</Title>
          )}
        </TitleContainer>

        <Spacer />
        <Box row style={{ gap: 72 }}>
          <Box flex className="account-setting-account-data-left-col">
            <EditableInput
              label={T.settings.yourMail}
              value={email}
              variant="email"
              disabled={AppStore.loggedUser.isSocial}
              onConfirmCallback={(_email) => {
                //check if email is valid email
                if (!!_email && _email.includes('@')) {
                  Auth.updateEmail(_email)
                  setEmail(_email)
                  showSuccess(T.success.weSendEmailWhithNewEmail)
                } else {
                  showError(T.error.invalidEmail)
                }
              }}
            />
            <Spacer />
            <EditableInput
              label={T.settings.yourName}
              value={name}
              variant="name"
              onConfirmCallback={(_name) => {
                //check if email is valid email
                if (!!_name) {
                  Auth.updateName(_name)
                  setName(_name)
                  showSuccess(T.success.nameModified)
                } else {
                  showError(T.error.invalidName)
                }
              }}
            />
            <Spacer />
            <EditableInput
              label={T.settings.yourPassword}
              value={password}
              variant="password"
              onConfirmCallback={(_password) => {
                if (!!_password && _password.length > 4) {
                  Auth.updatePassword(_password)
                  setPassword(_password)
                  showSuccess(T.success.passworldUpdated)
                } else {
                  showError(T.error.invalidPassword)
                }
              }}
            />
          </Box>

          <Box flex className="account-setting-account-data-right-col">
            {isVisible('settings.account.requestNumberWhatsApp') && (
              <>
                {/* WHATSAPP request number + otp */}
                <RequestNumber
                  type={'whatsapp'}
                  onReturnData={(data, isValid) => {
                    const { prefix, phone } = data
                    // settaggio dati nello state per il numero di telefono
                    setPhoneNumber((prev) => {
                      return { ...prev, prefix: prefix, number: phone }
                    })
                  }}
                  onReturnOtp={(otp, isValid) => {}}
                  phoneNumber={phoneNumber}
                  canModifyBtn={phoneNumber?.otpCode ? false : phoneNumber?.number ? true : false}
                />
              </>
            )}
          </Box>
        </Box>
      </div>
      <Box row>
        <Box flex>
          <Title style={{ color: AppStore.theme.o.green, marginTop: 48, marginBottom: 16 }}>
            {T.settings.selectLanguage}
          </Title>
          <Box id="change-language" flex>
            <Select
              defaultValue={AppStore.loggedUser.language ?? 'it'}
              onChange={async (language) => {
                await Utils.changeLanguage(language)
                AppStore.changeLang(language)
              }}
            >
              <Option value="it">{T.langauge.ita}</Option>
              <Option value="en">{T.langauge.eng}</Option>
            </Select>
          </Box>
        </Box>
        <Spacer width={'72px'} />
        <Box flex>
          <Title style={{ color: AppStore.theme.o.green, marginTop: 48, marginBottom: AppStore.darkTheme ? 6 : 12 }}>
            {T.settings.visualLightDark}
          </Title>
          <Box id="change-theme-icon" width={145} onClick={AppStore.changeTheme} removeHover>
            {AppStore.darkTheme ? <Icons.darkTheme /> : <Icons.lightTheme />}
          </Box>
        </Box>
      </Box>
      <Box row vcenter>
        <Box flex>
          <Title style={{ color: AppStore.theme.o.green, marginTop: 48, marginBottom: 20 }}>{T.settings.account}</Title>
          <Box flex justify={'space-between'} vcenter row>
            <Box flex>
              <Button variant="neutral" size="small" onClick={handleLogOut}>
                {T.settings.logOut}
              </Button>
            </Box>
            <Spacer width={72} />
            <Box flex>
              <Text
                onClick={showComingSoonToast}
                style={{ fontWeight: 700, textDecoration: 'underline' }}
                color={AppStore.theme.o.red}
              >
                {T.settings.deleteAccount}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountSection

const TitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 72px;

  p {
    width: 50%;
  }
`

const VersionTextContainer = styled(Box)`
  align-items: center;
  margin-top: 55px;
`

const VersionText = styled((props) => <Text {...props} />)`
  color: ${() => AppStore.theme.o.darkGrey} !important;
`
