/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { IReportConfig } from 'types/AnalyticsInferfaces'
import Medias from 'api/Medias'
import { useAnalyticsStore } from 'stores/AnalyticsStore'

/* ------------------- INTERFACES  */
interface Props {
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReportSetup = ({ reportConfig, setReportConfig }: Props) => {
  /* ------------------- VARIABLES */
  const { reportId } = useParams()
  const reportPreview = useAnalyticsStore.getState().reportPreview

  /* ------------------- STATE  */
  const [reportLogo, setReportLogo] = useState<string>(reportConfig.info.reportLogo ?? '')
  const [loading, setloading] = useState(false)
  /* ------------------- USEREF  */
  const inputRef = useRef<HTMLInputElement | null>(null)
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  /* ------------------- METHODS  */
  const handleLogoClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const onFileChangeReportImg = async (event) => {
    const selectedFile = event.target.files[0]
    if (!selectedFile) {
      setReportLogo('')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile, selectedFile.name)
    formData.append('prefix', 'report_logo/')

    setloading(true)
    try {
      const response = await Medias.upload(formData)
      const data = response.data
      if (data && data.presignedUrl) {
        setReportLogo(data.presignedUrl) // Aggiorna l'immagine caricata
      } else {
        setReportLogo('') // Resetta l'immagine se il caricamento fallisce
      }
    } catch (error) {
      console.error(error)
    }
    setloading(false)
  }

  /* ------------------- USEEFFECT  */
  useEffect(() => {
    setTimeout(() => {
      setReportConfig({
        ...reportConfig,
        info: {
          ...reportConfig.info,
          reportLogo: reportLogo,
        },
      })
    }, 200)
  }, [reportLogo])

  useEffect(() => {
    inputRef?.current?.focus()

    //todo: da finire che fetcha se esiste report id (da fare dopo fiera)
    // const getReportData = async () => {
    //   const res = await getReport(reportId)
    //   return res
    // }

    // if (reportId) {
    //   const response = getReportData()
    // setTimeout(() => {
    //   setReportConfig({
    //     ...reportConfig,
    //     info: {
    //       createdBy: AppStore.loggedUser.name,
    //       createdAt: new Date(),
    //       reportName: reportConfig.info.reportName ?? '',
    //       reportLogo: reportLogo.images,
    //     },
    //   })
    // }, 200)

    // } else {
    //   setTimeout(() => {
    //     setReportConfig({
    //       ...reportConfig,
    //       info: {
    //         createdBy: AppStore.loggedUser.name,
    //         updatedAt: new Date(),
    //         reportName: reportConfig.info.reportName ?? '',
    //         reportLogo: reportLogo.images,
    //       },
    //     })
    //   }, 200)
    // }
  }, [])

  return (
    <ReportSetupContainer className="report_setup_container">
      <ReportNameInput
        ref={inputRef}
        className="report_name_input"
        type="text"
        placeholder={T.analytics.enterTheNameOfTheReport}
        value={reportConfig.info.reportName ?? ''}
        onChange={(e) =>
          setReportConfig({
            ...reportConfig,
            info: {
              ...reportConfig.info,
              reportName: e.target.value,
            },
          })
        }
      />

      {!reportConfig.info.reportLogo && (
        <ReportLogoWithNoImg className="report_logo_with_no_img" onClick={handleLogoClick}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileChangeReportImg}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <Icons.uploadFile />
          {T.analytics.uploadLogo}
        </ReportLogoWithNoImg>
      )}

      {reportConfig.info.reportLogo && (
        <ReportLogoWithImg className="report_logo_with_img" onClick={handleLogoClick}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileChangeReportImg}
            style={{ display: 'none' }}
            accept="image/*"
          />
          <img src={reportConfig.info.reportLogo} />
          <Icons.uploadFile />
        </ReportLogoWithImg>
      )}

      <InformativeTextLogo className="informative_text_logo">
        {T.analytics.recommendedDimensionLogo}:
        <br />
        512x512, 1024x1024 e 1920x1920
      </InformativeTextLogo>
    </ReportSetupContainer>
  )
}

const ReportSetupContainer = styled(Box)`
  &.report_setup_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ReportNameInput = styled.input`
  &.report_name_input {
    display: flex;
    width: 299px;
    height: 43px;
    padding: 12px 16px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.surface};
    transition: 300ms ease-in-out;

    :hover,
    :focus {
      background: ${() => AppStore.theme.o.lightestGrey};
    }
  }
`

const ReportLogoWithNoImg = styled(Box)`
  &.report_logo_with_no_img {
    margin-top: 32px;
    width: 146px;
    height: 64px;
    border-radius: 14px;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};

    color: ${() => AppStore.theme.o.black};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    &:hover {
      background: ${() => AppStore.theme.o.grey};
    }

    svg {
      fill: ${() => AppStore.theme.o.black};
    }
  }
`

const ReportLogoWithImg = styled(Box)`
  &.report_logo_with_img {
    margin-top: 32px;
    width: 146px;
    height: 64px;
    border-radius: 14px;
    cursor: pointer;
    position: relative;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};

    img {
      width: 90%;
      height: 90%;
      border-radius: 14px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      object-fit: contain;
    }

    svg {
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      svg {
        border-radius: 12px;
        background-color: rgba(128, 128, 128, 0.5);
        opacity: 1;
        fill: ${() => AppStore.theme.o.black};
      }
    }
  }
`

const InformativeTextLogo = styled(Box)`
  &.informative_text_logo {
    margin-top: 16px;
    color: ${() => AppStore.theme.o.darkGrey};
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
`
