import styled from '@emotion/styled'
import { Box } from 'components'
import VectorBack from 'assets/icons/VectorBack.png'
import VectorFront from 'assets/icons/VectorFront.png'
import { Spacer } from '../Spacer'
import { AppStore } from 'utils'

interface Props {
  currentMonth: number
  currentYear: number
  months: string[]
  setMonth: (month: number) => void
  setYear: (year: number) => void
}

export const LateralCalendar = ({ currentMonth, currentYear, months, setMonth, setYear }: Props) => {
  return (
    <Container width={130} mr={16} pt={12} pb={12} mt={67}>
      <Box height={32} hcenter row>
        <ButtonBox onClick={() => setYear(currentYear - 1)} src={VectorBack}></ButtonBox>
        <YearBox center>{currentYear}</YearBox>
        <ButtonBox onClick={() => setYear(currentYear + 1)} src={VectorFront}></ButtonBox>
      </Box>
      <Spacer />
      <Box center>
        {months.map((v, index) => {
          return (
            <MonthBox
              key={index}
              removeHover
              vcenter
              m={12}
              p={12}
              onClick={() => {
                setMonth(index)
              }}
              selected={index === currentMonth}
            >
              {v}
            </MonthBox>
          )
        })}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.simpleOuterShadow};
  border-radius: 14px;
  height: fit-content;
`
const ButtonBox = styled.img`
  cursor: pointer;
  width: 32px;
  height: 32px;
`
const YearBox = styled(Box)`
  font-size: 18px;
  font-weight: 500;
  color: ${AppStore.theme.o.darkGrey};
`
const MonthBox = styled(Box) <{ selected: boolean }>`
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: ${({ selected }) => (selected ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey)};
  font-size: ${({ selected }) => (selected ? '18px' : '14px')};
  font-weight: ${({ selected }) => (selected ? 'bold' : '500')};
  height: 36px;
  border-radius: 14px;
  transition: 0.3s;
  :hover {
    color: ${() => AppStore.theme.o.blueOnLight};
    font-size: 18px;
    font-weight: bold;
  }
`
