/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import Socialaccounts from 'api/Socialaccounts'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { SocialButtonWithImage } from 'components/SocialButtonWithImage'
import { Spacer } from 'components/Spacer'
import { Text } from 'components/Text'
import { ZoomedContainer } from 'components/ZoomedContainer'
import { is } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { SocialAccount } from 'types'
import { AppStore, showError, showInfo, showSuccess } from 'utils'
import {
  OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE,
  OWNER_SOCIAL_ACCOUNTS_LIMITS_USED,
} from './../../utils/Constants/LimitUserConstants'

/* ------------------- INTERFACES  */
interface Props {
  accountsProp: SocialAccount[]
  saveAccount: (account: SocialAccount) => Promise<void>
  refreshSocials: () => Promise<void>
}

const Widths = [510, 860]

export const AddMetaAccountsModal = ({ accountsProp, saveAccount, refreshSocials }: Props) => {
  /* ----------------- STATE  */
  const [accounts, setAccounts] = useState<SocialAccount[]>(accountsProp)
  const [selectedAccounts, setSelectedAccounts] = useState<SocialAccount[]>([])
  const [step, setStep] = useState<number>(1)
  const [socialAlreadyExist, setSocialAlreadyExist] = useState<SocialAccount[]>([])

  /* ----------------- METHODS  */
  const preSaveAccount = async (account: SocialAccount) => {
    await saveAccount(account)
    setAccounts(accounts.filter((acc) => acc.account_id !== account.account_id))
  }

  const onSpecialRowClick = (account: SocialAccount) => {
    //Check user limits and eventually block the action due to limit reached
    const isAdded = !account.selected
    if (
      isAdded &&
      !OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE &&
      selectedAccounts.length + 1 + OWNER_SOCIAL_ACCOUNTS_LIMITS_USED > OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE
    ) {
      return showInfo(T.limitUser.max_reached_social_accounts)
    }

    account.selected = !account.selected

    const tmpSelectedAccounts = accounts.filter((acc) => acc.selected)

    setSelectedAccounts(tmpSelectedAccounts)
  }

  const handleSelectAll = () => {
    if (accounts.length === selectedAccounts.length) {
      //Rimuove tutti gli account selezionati
      setSelectedAccounts([])
      accounts.forEach((account) => (account.selected = false))
    } else {
      //? Controlla se rientra dentro i limiti dell'owner
      if (!OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE) {
        if (accounts.length + OWNER_SOCIAL_ACCOUNTS_LIMITS_USED > OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE) {
          return showInfo(T.limitUser.cannot_add_all_social)
        }
      }

      //Seleziona tutti gli account
      accounts.forEach((account) => (account.selected = true))
      setSelectedAccounts(accounts)
    }
  }

  //------------------------------------------  STAMPA IL SINGOLO ACCOUNT  ------------------------------------------
  const SpecialSocialAccountRow = (account: SocialAccount, index, type) => {
    return (
      <RowContainer
        vcenter
        row
        pv={10}
        ph={20}
        style={{
          border: account.alreadyExistOnDifferentWorkspace
            ? `2px solid ${AppStore.theme.o.warning}`
            : `1px solid ${AppStore.theme.o.grey}`,
          justifyContent: 'space-between',
        }}
        m={10}
        onClick={step === 1 ? () => onSpecialRowClick(account) : undefined}
      >
        {/* STAMPA  NOME  */}
        <SocialButtonWithImage socialAccount={account} />
        <ColumnContainer>
          <Text style={{ marginLeft: 10, marginRight: 10 }}>
            <b>{account.name}</b>
          </Text>
          {account.alreadyExistOnDifferentWorkspace &&
            socialAlreadyExist.find((s) => s.account_id === account.account_id)?.workspace && (
              <Text style={{ marginLeft: 10, marginRight: 10 }}>
                {`Workspace: ${typeof account.workspace === 'string' ? '' : account.workspace.name}`}
              </Text>
            )}
        </ColumnContainer>

        {type === 1 && (
          <CustomInputCheckbox
            checked={selectedAccounts.map((s) => s.account_id)?.includes(account.account_id)}
            type="checkbox"
          />
        )}

        {type === 2 && addWorkSpaceSelection(account)}
      </RowContainer>
    )
  }

  // -------------------------- è la parte che si vede quando si va step 2 --------------------------
  const addWorkSpaceSelection = (account: SocialAccount) => {
    const defaultSelected = AppStore.workspace._id

    const allWorkspaces = [
      ...AppStore.loggedUser.workspaces,
      ...AppStore.loggedUser.collabWorkspace.map((w) => w.workspace),
    ]

    return (
      <>
        <Text style={{ marginLeft: 10, color: AppStore.theme.o.darkGrey, fontStyle: 'italic', width: '300px' }}>
          {T.settings.selectAmbientation}
        </Text>
        <Spacer />
        <Spacer />
        <Select
          style={{ width: 200 }}
          onSelect={(workspaceID) => {
            const workspace = allWorkspaces.find((w) => w._id === workspaceID)
            account.workspace = workspace!
          }}
          defaultValue={defaultSelected}
        >
          {allWorkspaces.map((workspace) => (
            <Option value={workspace._id}>{workspace.name}</Option>
          ))}
        </Select>
      </>
    )
  }

  const onContinuePress = async () => {
    if (step === 1) {
      setStep(2)
    } else {
      try {
        const selected = accounts.filter((item) => item.selected === true)

        for (let i = 0; i < selected.length; i++) {
          const account = selected[i]
          await saveAccount(account)
        }

        await refreshSocials()
        showSuccess(T.success.socialProfileAdded)
        AppStore.closeAllModal()
      } catch (error) {
        showError(error)
      }
    }
  }

  /* ----------------- API CALL  */
  /**
   * The function `checkSocialAlreadyExist` asynchronously checks if social accounts already exist and
   * returns the result.
   * @returns The function `checkSocialAlreadyExist` is returning a Promise that resolves to an array
   * of `SocialAccount` objects. If an error occurs during the execution of the function, an empty
   * array is returned after displaying the error using the `showError` function.
   */
  const checkSocialAlreadyExist = async (): Promise<SocialAccount[]> => {
    try {
      const accountsChecked = await Socialaccounts.checkSocialExist(accounts.map((a) => a.account_id))

      setAccounts(
        accounts.map((social) => {
          social.alreadyExistOnDifferentWorkspace = accountsChecked.some((s) => s.account_id === social.account_id)
          return social
        })
      )

      return accountsChecked
    } catch (error) {
      showError(error)
      return []
    }
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    checkSocialAlreadyExist().then((socialAccountList) => setSocialAlreadyExist(socialAccountList))
    return () => {
      const style = document.createElement('style')
      style.type = 'text/css'
      style.innerHTML = `
        .ant-select-dropdown {
          zoom: unset;
          transform: scale(${AppStore.zoom}%);
        }
    `
      document.head.appendChild(style)
    }
  }, [])

  return (
    <AddMetaAccountsModalContainer>
      <ZoomedContainer zoom={AppStore.zoom} pv={48} ph={80} hcenter>
        <Text fontSize={21} weight={700}>
          {T.DashboardPage.step}
        </Text>

        <Box row mt={12}>
          <Box height={4} width={172} style={{ backgroundColor: AppStore.theme.o.blueOnLight }}></Box>
          <Spacer></Spacer>
          <Box
            height={4}
            width={172}
            style={{
              backgroundColor: step === 2 ? AppStore.theme.o.blueOnLight : AppStore.theme.o.darkGrey,
            }}
          />
        </Box>
        <Text fontSize={21} weight={700}>
          {step === 1 ? T.settings.socialToConnect : T.settings.setEnv}
        </Text>

        <Box
          p={10}
          mt={20}
          width={Widths[step - 1]}
          style={{
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: AppStore.theme.o.darkGrey,
          }}
        >
          <Box style={{ maxHeight: '50vh' }} scroll>
            <Box flex>
              {step === 1 && (
                <RowContainer
                  onClick={handleSelectAll}
                  row
                  vcenter
                  pv={12}
                  ph={20}
                  style={{ border: `1px solid ${AppStore.theme.o.grey}` }}
                  m={10}
                >
                  <Box flex vcenter>
                    <Text weight={'bold'} style={{ color: AppStore.theme.o.darkGrey, fontSize: 16 }}>
                      {T.settings.addAll}
                    </Text>
                  </Box>
                  <CustomInputCheckbox checked={accounts.length === selectedAccounts.length} type="checkbox" />
                </RowContainer>
              )}
            </Box>

            {step === 1 && accounts.map((account, index) => SpecialSocialAccountRow(account, index, 1))}

            {step === 2 &&
              accounts
                .filter((item) => item.selected === true)
                .map((account, index) => SpecialSocialAccountRow(account, index, 2))}
          </Box>
          {/* ----------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
        </Box>
        {step === 1 && socialAlreadyExist.length > 0 && <ErrorContainer>{T.error.socialExist}</ErrorContainer>}
        <Button
          pv={8}
          ph={20}
          size="smaller"
          style={{ marginTop: 16 }}
          variant="neutral"
          color={AppStore.theme.o.darkGrey}
          onClick={() => {
            if (accounts.filter((item) => item.selected === true).length > 0) {
              onContinuePress()
            } else {
              showInfo(T.DashboardPage.select_at_least_one_social_to_add)
            }
          }}
        >
          {step === 1 ? T.settings.continue : T.settings.confirm}
        </Button>
      </ZoomedContainer>
      {step === 1 && (
        <Box
          style={{
            position: 'absolute',
            bottom: -120,
            zIndex: 9999999,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            color: AppStore.theme.o.darkGrey,
          }}
          height={100}
          width={'100%'}
          center
        >
          <Text weight={700}>{T.settings.notSeeingProfiles1}</Text>
          <Text>
            {T.settings.notSeeingProfiles2}
            <b>
              <a href="https://help.asters.ai/profili-social" style={{ color: '#18828c' }} target="_blank">
                {T.settings.notSeeingProfiles3}
              </a>
            </b>
          </Text>
          <Text>{T.settings.notSeeingProfiles4}</Text>
        </Box>
      )}
      {step === 2 && <BackIcon fill={AppStore.theme.o.black} onClick={() => setStep(1)} />}
    </AddMetaAccountsModalContainer>
  )
}

const AddMetaAccountsModalContainer = styled(React.Fragment)``

const CustomInputCheckbox = styled.input`
  width: 2px;
  height: 2px;
  margin-left: 25px;
  background-color: ${() => AppStore.theme.o.grey};
  border-radius: 50%;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  :checked {
    background-color: #2aad76;
  }
`

const RowContainer = styled(Box)`
  :hover {
    ${(props) => !!props.onClick && `background-color: ${() => AppStore.theme.o.lightGrey};`}
  }
`

const BackIcon = styled(Icons.backward)`
  position: absolute;
  top: 40px;
  left: 40px;
  cursor: pointer;
`

const ErrorContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.warning_container};
  color: ${() => AppStore.theme.o.warning};
  padding: 16px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  width: 590px;
  min-height: 72px;
  margin-top: 16px;
`

const ColumnContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 8px;
`
