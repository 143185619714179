/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { ITextGraph } from 'types/AnalyticsInferfaces'
import { AppStore, formatNumber } from 'utils'

interface Props {
  textGraph: ITextGraph
}

export const TextInfoGraph = ({ textGraph }: Props) => {
  /* ------------------- VARIABLES */
  const hasOnlyOneData = textGraph.data.length === 1

  return (
    <TextInfoGraphContainer>
      {textGraph.data.map((item, index) => {
        const dataLength = item.value.length
        return (
          <DataLabelContainer key={index} style={{ overflow: hasOnlyOneData ? 'visible' : 'hidden' }}>
            <Data style={{ fontSize: hasOnlyOneData ? 64 : dataLength > 6 ? 28 : 32 }}>{formatNumber(item.value)}</Data>
            <Label style={{ fontSize: hasOnlyOneData ? 32 : 12 }}>{item.label}</Label>
          </DataLabelContainer>
        )
      })}
    </TextInfoGraphContainer>
  )
}
const DataLabelContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 8px;
  box-sizing: border-box;
  height: 114px;
  width: 154px;
  align-self: start;
  overflow: hidden;
`

const TextInfoGraphContainer = styled(Box)`
  border-radius: 0px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  align-items: start;
  justify-content: center;
  color: ${() => AppStore.theme.o.black};
  overflow: hidden;
  margin-bottom: 24px;
`

const Data = styled(Box)`
  border-radius: 0px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.96px;
`

const Label = styled(Box)`
  border-radius: 0px;
  padding-bottom: 12px;
  font-family: AktivGrotesk-Bold;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.4px;
`
