import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData } from 'types/PostInterface'

export const formatTitle = () => {
  const { activePost } = useCreatePostStore.getState()

  if ('_id' in activePost && activePost._id === 'GLOBAL') return `Global Post`

  if ('variantId' in activePost) return `${activePost.info?.account.name}`
}

export const getSelectedMedias = () => {
  const { activePost } = useCreatePostStore.getState()
  if (activePost === undefined) return []

  if ('_id' in activePost && activePost._id === 'GLOBAL') {
    return (activePost as IPostData).medias
  }
  if ('variantId' in activePost) {
    return activePost.data.medias
  }

  return []
}
