import { Box } from 'components'
import { Header } from 'components/Header'
import { ReactNode } from 'react'
import { AppStore } from 'utils'
import Background from './Background'
import { SubscriptionChecker } from './SubscriptionChecker'

interface Props {
  children?: any
  noScroll?: boolean
  title?: string
  customButton?: ReactNode
  noHeader?: boolean
  style?: React.CSSProperties
}

export function Page({ style, noHeader, children, noScroll, title, customButton }: Props) {
  return (
    <Box
      flex
      style={{
        overflow: 'hidden',
        ...style,
      }}
      borderRadius={0}
    >
      <SubscriptionChecker />
      <Box
        flex
        borderRadius={0}
        bgPrimaryColor
        id={title + '-page'}
        style={
          noScroll
            ? {}
            : {
                backgroundColor: AppStore.theme.o.background,
                overflow: 'auto',
                overflowX: 'hidden',
              }
        }
      >
        <Background visible={title === 'Post'} />
        <Box
          borderRadius={0}
          flex
          pl={noHeader ? 0 : 80}
          pr={noHeader ? 0 : 40}
          pv={noHeader ? 0 : 8}
          shadow={AppStore.theme.pageInnerShadow}
          style={
            title === 'Analytics' || title === 'Discover' || title === 'Post'
              ? { backgroundColor: AppStore.theme.o.background, overflowY: 'hidden', overflowX: 'scroll' }
              : { backgroundColor: AppStore.theme.o.background }
          }
        >
          {!noHeader && <Header customButton={customButton} title={title} />}
          <Box
            animation="fade"
            flex
            style={
              title === 'Analytics' || title === 'Discover' || title === 'Post'
                ? { overflowY: 'hidden', overflowX: 'scroll' }
                : {}
            }
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
