import { ConfirmModal } from "components/UI"
import { AppStore, T } from "utils"

export const requestConfirmDialog = (title: any, onConfirm: () => void) => {
  AppStore.openModal({
    id: 'preview-modal',
    body: (
      <ConfirmModal
        text={
          title
        }
        cancelText={T.postPage.cancel}
        confirmText={T.settings.confirm}
        onClickCancel={() => {
          AppStore.closeModal('preview-modal')
        }}
        onClickConfirm={async () => {
          await onConfirm()
          AppStore.closeModal('preview-modal')
        }}
      />
    ),
    style: {
      minHeight: 300,
      minWidth: 700,
      alignContent: 'center',
    },
  })
}