import { MixpanelDraftData } from './draftUseInterfaces'

export function getDraftData(draftData): MixpanelDraftData {
  return {
    num_facebook_drafts: draftData.facebook,
    num_instagram_drafts: draftData.instagram,
    num_linkedin_drafts: draftData.linkedin,
    num_tiktok_drafts: draftData.tiktok,
    num_twitter_drafts: draftData.twitter,
    // post_drafts_social: draftData.activeSocialFilters,
    // post_drafts_type: draftData.typeFilter,
  }
}
