import { AppstoreTwoTone } from '@ant-design/icons'
import styled from '@emotion/styled'
import { Box, Button, Icons, Spacer, Text } from 'components'
import moment from 'moment'
import { T } from 'translation/i18n'
import { AppStore, getHumanReadableDate } from 'utils'
import { Promo } from './SubscriptionProvider'
import TotalShow from './TotalShow'

interface Props {
  notClickAble: boolean
  monthPrice: number
  annualPrice: number
  monthBorderColor: string
  subscriptionMode
  monthTextColor: string
  annualBorderColor: string
  annualTextColor: string
  addonPrice: number
  onSubscriptionChange: (index: number) => void
  loading?: boolean
  disabled?: boolean
  externalAddOn: any
  PaymentCost: number
  offerta?: Promo
  total: any
}

export const SummaryBoxSubscription = ({
  notClickAble,
  monthPrice,
  annualPrice,
  monthBorderColor,
  subscriptionMode,
  monthTextColor,
  annualBorderColor,
  annualTextColor,
  addonPrice,
  onSubscriptionChange,
  disabled,
  loading,
  externalAddOn,
  PaymentCost,
  offerta,
  total,
}: Props) => (
  <>
    <Box row vcenter>
      <Text style={{ flex: 1 }} weight={600}>
        {T.settings.mode}
      </Text>
      <SubscribeButton
        loading={loading}
        disabled={disabled}
        disable={disabled}
        variant={disabled ? 'neutral' : 'primary-small'}
        type="submit"
      >
        {AppStore.loggedUser.rawPlan !== undefined ? T.settings.update : T.settings.subscribe}
      </SubscribeButton>
    </Box>
    <Separator />
    {!externalAddOn ? (
      <>
        <Box
          row
          onClick={() => onSubscriptionChange(0)}
          pv={12}
          ph={subscriptionMode === 'monthly' ? 8 : 16}
          style={{ border: `1px solid ${monthBorderColor}` }}
        >
          {subscriptionMode === 'monthly' && (
            <Box mt={4} mr={4}>
              <Icons.checkbox stroke={AppStore.theme.o.green} />
            </Box>
          )}
          {subscriptionMode !== 'monthly' && (
            <Box mt={12} mr={8}>
              <Icons.notCheckedBox fill={AppStore.theme.o.darkGrey} />
            </Box>
          )}
          <Box>
            <Box row style={{ alignItems: 'flex-end' }}>
              <Text weight={'bold'} color={monthTextColor}>
                {monthPrice + addonPrice}€
              </Text>
              <Text color={monthTextColor} style={{ marginLeft: 4, marginBottom: 2 }} fontSize={12}>
                {T.settings.month} ({T.settings.corispondTo}
                {(monthPrice + addonPrice) * 12}€/{T.settings.year} )
              </Text>
            </Box>
            <Text color={monthTextColor} fontSize={12}>
              {T.settings.paymentMothly}
            </Text>
          </Box>
        </Box>
        <Box
          row
          onClick={() => onSubscriptionChange(1)}
          mt={16}
          ph={subscriptionMode !== 'monthly' ? 8 : 16}
          pv={12}
          style={{ border: `1px solid ${annualBorderColor}` }}
        >
          {subscriptionMode !== 'monthly' && (
            <Box mt={4} mr={4}>
              <Icons.checkbox stroke={AppStore.theme.o.green} />
            </Box>
          )}
          {subscriptionMode === 'monthly' && (
            <Box mt={12} mr={8}>
              <Icons.notCheckedBox fill={AppStore.theme.o.darkGrey} />
            </Box>
          )}
          <Box>
            <Box row style={{ alignItems: 'flex-end' }}>
              <Text weight={'bold'} color={annualTextColor}>
                {offerta ? (offerta.cost + addonPrice * 12).toFixed(0) : ((annualPrice + addonPrice) * 12).toFixed(0)}€
              </Text>
              <Text color={annualTextColor} style={{ marginBottom: 2 }} fontSize={12}>
                {T.settings.year} ({T.settings.corispondTo}
                {Math.floor(offerta ? offerta.cost / 12 + addonPrice : annualPrice + addonPrice)}
                €/{T.settings.month})
              </Text>
            </Box>
            <Box row>
              <Text color={annualTextColor} fontSize={12}>
                {T.settings.annualPayment}|
              </Text>
              <Text color={AppStore.theme.o.yellow} fontSize={12} style={{}}>
                {T.settings.saveThe}40%
              </Text>
            </Box>
          </Box>
        </Box>
      </>
    ) : (
      <>
        <Text color={AppStore.theme.o.darkGrey}>{T.settings.youPaid}</Text>
        <Box
          row
          pv={12}
          mt={4}
          ph={subscriptionMode === 'monthly' ? 8 : 16}
          style={{ border: `1px solid ${AppStore.theme.o.darkGrey}` }}
        >
          <Box>
            <Box row style={{ alignItems: 'flex-end' }}>
              <Text weight={'bold'} color={AppStore.theme.o.black}></Text>
              <Text color={AppStore.theme.o.darkGrey} style={{ marginLeft: 4, marginBottom: 2 }} fontSize={16}>
                {PaymentCost * 1.22} €
              </Text>
            </Box>
          </Box>
        </Box>
        <Box mt={16}>
          <Text weight={700} color={AppStore.theme.o.black}>
            {T.settings.youWllPayNextUpdate}
          </Text>
        </Box>
        <Box
          row
          ph={subscriptionMode !== 'monthly' ? 8 : 16}
          pv={12}
          mt={4}
          style={{ border: `1px solid ${AppStore.theme.o.black}` }}
        >
          <Box>
            <Box row style={{ alignItems: 'flex-end' }} ml={12}>
              <Text weight={'bold'} color={AppStore.theme.o.black} fontSize={21}>
                {(total * 1.22).toFixed(2)}€
              </Text>
              <Text color={AppStore.theme.o.black} style={{ marginLeft: 8, marginBottom: 2 }} fontSize={12}>
                {T.settings.importedAt}
                <p style={{ display: 'inline-block', fontWeight: 700, marginLeft: 4 }}>
                  {getHumanReadableDate(AppStore.loggedUser.rawPlan?.expirationDate)}
                </p>
              </Text>
            </Box>
          </Box>
        </Box>
        {total - PaymentCost > 0 ? (
          <Box mt={24}>
            <Text fontSize={16} color={AppStore.theme.o.blueOnLight}>
              {T.settings.differenceToPayNow}
            </Text>
            <Text fontSize={36} color={AppStore.theme.o.blueOnLight}>
              {(total * 1.22 - PaymentCost).toFixed(2)}€
            </Text>
          </Box>
        ) : (
          <Box mt={16}>
            <Text color={AppStore.theme.o.darkGrey} style={{ fontStyle: 'italic' }}>
              {T.settings.willAPliedNextUpdate}
            </Text>
          </Box>
        )}
      </>
    )}
  </>
)

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const SubscribeButton = styled(Button)<{ disable: boolean | undefined }>`
  min-height: 48px !important;
  min-width: 128px !important;
  font-size: 16px;
  ${({ disable }) =>
    disable === true
      ? `background-color: ${AppStore.theme.o.lightGrey}; color: ${AppStore.theme.o.darkGrey}; box-shadow: none!important;onhover-events: none;`
      : `hover : {
    background-color: ${() => AppStore.theme.o.lightBlue};
    color: ${() => AppStore.theme.o.blueOnLight};
    box-shadow: none;
  }`}
`
