import PostHistory from 'api/PostHistory'
import { Tracker } from 'api/Tracker'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { Media } from 'types'
import { IPostData, IPostVariant, IVariantInfo, IVariantSocial, TPreviewPost } from 'types/PostInterface'
import { T, showError, showInfo, showSuccess, showWarning } from 'utils'
import {
  OWNER_POST_INFO_PLANNED,
  OWNER_POST_LIMITS_AVAILABLE,
  OWNER_POST_LIMITS_INFINITE,
  OWNER_POST_LIMITS_USED,
} from 'utils/Constants/LimitUserConstants'
import {
  TRACKEREVENTS,
  getMixpanelPostDirectlyPublished,
  getMixpanelPostDraftData,
  getMixpanelPostScheduledData,
} from 'utils/eventsTracker'
import { checkPostValidation } from './ValidationPost'

export const getSocialsAccount = (): IVariantSocial[] => {
  const { posts } = useCreatePostStore.getState()

  // Utilizziamo un Map per tracciare gli account_id già visti
  const uniqueAccountsMap = new Map<string, IPostVariant>()

  // Iteriamo attraverso i post e aggiungiamo gli account unici al Map
  for (const post of posts) {
    const accountId = post.info.account.account_id

    // Aggiungiamo il post al Map solo se non abbiamo già visto l'account_id
    if (!uniqueAccountsMap.has(accountId)) {
      uniqueAccountsMap.set(accountId, post)
    }
  }

  // Convertiamo i valori del Map in un array

  const filteredAccounts = Array.from(uniqueAccountsMap.values())

  const newFilteredAccounts = filteredAccounts.map((post) => {
    return post.info.account
  })

  return newFilteredAccounts
}

export const getPostByAccountId = (accountId?: string) => {
  const { posts, activeAccountId, globalPost } = useCreatePostStore.getState()
  if (!!accountId) return posts.find((post) => post.info.account.account_id === (accountId ?? activeAccountId))
  if (activeAccountId === 'GLOBAL') return globalPost
  return posts.find((post) => post.info.account.account_id === (accountId ?? activeAccountId))
}

export const getPostByAccountIdType = (accountId: string, type: string) => {
  const { posts, activeAccountId, globalPost } = useCreatePostStore.getState()

  // find post by account id and type
  return posts.find(
    (post) => post.info.account.account_id === (accountId ?? activeAccountId) && post.info.type === type
  )
}

export const getPreviewByVariant = (post: IPostData | IPostVariant): TPreviewPost => {
  if (post === undefined) return 'GLOBAL'

  if ('_id' in post && post._id === 'GLOBAL') return 'GLOBAL'

  if ('variantId' in post) {
    switch (post.info.account.provider) {
      case 'instagram':
        if (post.info.type === 'STANDARD') return 'INSTAGRAM_STANDARD'
        if (post.info.type === 'STORY') return 'INSTAGRAM_STORY'
        return 'INSTAGRAM_STANDARD'

      case 'youtube':
        if (post.info.type === 'STANDARD') return 'YOUTUBE_STANDARD'
        if (post.info.type === 'SHORT') return 'YOUTUBE_SHORT'
        return 'YOUTUBE_STANDARD'

      case 'facebook':
        return 'FACEBOOK'

      case 'x':
        return 'X'

      case 'linkedin':
        return 'LINKEDIN'

      case 'tiktok':
        return 'TIKTOK'
    }
  }

  return 'GLOBAL'
}

// -----------------------------------------------------------------

export const getSinglePostById = (variantId: string) => {
  const { posts } = useCreatePostStore.getState()

  // Find the specific post
  return posts.find((post) => post.variantId === variantId)
}

// -----------------------------------------------------------------
//unique gesture

export const setUnique = (variantId: string) => {
  const { globalPost, uniqueVariants, setUniqueVariants } = useCreatePostStore.getState()

  if (uniqueVariants.includes(variantId)) return

  const post = getSinglePostById(variantId)
  if (!post) return

  const textCheck = post.data.text === globalPost.text
  const mediaCheck = JSON.stringify(post.data.medias) === JSON.stringify(globalPost.medias)

  if (textCheck && mediaCheck) return

  setUniqueVariants([...uniqueVariants, post.variantId])
}

export const removeUnique = (variantId: string) => {
  const { uniqueVariants, setUniqueVariants } = useCreatePostStore.getState()

  const newUniqueVariants = uniqueVariants.filter((id) => id !== variantId)

  setUniqueVariants(newUniqueVariants)
}

export const pullFromGlobal = () => {
  const { globalPost, activePost } = useCreatePostStore.getState()
  const active = activePost as IPostVariant

  const post = getSinglePostById(active.variantId)
  if (!post) return

  updateActive(globalPost)
  removeUnique(active.variantId)
}

export const isUnique = (variantId: string) => {
  const { uniqueVariants } = useCreatePostStore.getState()
  return uniqueVariants.includes(variantId)
}

export const updateAllNonUniquePosts = (newData: IPostData) => {
  const { posts, uniqueVariants } = useCreatePostStore.getState()

  // Loop through all the posts
  posts.forEach((post) => {
    if (!uniqueVariants.includes(post.variantId)) updatePost(post.variantId, newData)
  })
}

/**
 * Aggiorna il post specifico
 *
 * @param variantId Valore variant con la quale si identifica su quale post stiamo operando
 * @param newData Il valore da aggiornare nei "data" nel IPostVariant (es: {text: 'nuovo testo'})
 * @returns Setta la lista dei post aggiornati
 */
export const updatePost = (variantId: string, newData: any) => {
  const { posts, setPosts } = useCreatePostStore.getState()
  // Find the specific post to update
  let post = getSinglePostById(variantId)

  if (!post) return setPosts(posts)

  const updatedPostData: IPostData = { ...post.data, ...newData, _id: undefined }

  post = { ...post, data: updatedPostData }

  setUnique(variantId)

  const postIndex = posts.findIndex((p) => p.variantId === variantId)
  posts[postIndex] = post

  return setPosts(posts)
}

// questo aggiorna il global
export const updateGlobal = (newData: any) => {
  const { globalPost, setGlobalPost } = useCreatePostStore.getState()

  updateAllNonUniquePosts(newData)

  const updatedGlobalData = { ...globalPost, ...newData }

  setGlobalPost(updatedGlobalData)
}

// questo aggiorna il post attivo e anche il suo corrispondente nella lista dei post (se esiste)
// newData è un valore che si trova dentro IPostData
export const updateActive = (newData: any) => {
  //variabili
  const { activePost, setActivePost } = useCreatePostStore.getState()

  //aggiornamento
  let updatedActiveData: any = {}

  if ('_id' in activePost && activePost._id === 'GLOBAL') updatedActiveData = { ...activePost, ...newData }

  if ('variantId' in activePost)
    updatedActiveData = {
      ...activePost,
      data: {
        ...activePost.data,
        ...newData,
      },
    }

  setActivePost(updatedActiveData)

  // ---

  //aggiornamento effetivo del post
  if ('_id' in activePost && activePost._id === 'GLOBAL') return updateGlobal(newData)

  if ('variantId' in activePost) return updatePost(activePost.variantId, newData)
}

// -----------------------------------------------------------------

export const publish = async (postStatus: 'draft' | 'publish-now' | 'schedule') => {
  const { posts, activePost } = useCreatePostStore.getState()
  let checkDraft = true
  const allChecksPost: string[] = []

  let activeChecks: string[] = []

  // Loop through all the posts
  posts.forEach((post) => {
    if (postStatus === 'draft') {
      post.data.status = postStatus
      checkDraft = checkIfDraftIsValid(post)

      Tracker.trackEvent(TRACKEREVENTS.POST_DRAFT_SAVED, getMixpanelPostDraftData(posts, post))
    }

    if (postStatus === 'publish-now') {
      const problems = checkPostValidation(post)
      allChecksPost.push(...problems)

      Tracker.trackEvent(TRACKEREVENTS.POST_DIRECTLY_PUBLISHED, getMixpanelPostDirectlyPublished(posts, post))
    }

    if (postStatus === 'schedule') {
      Tracker.trackEvent(TRACKEREVENTS.POST_SCHEDULED, getMixpanelPostScheduledData(posts, post))
    }
  })

  // --- setActiveChecks
  if ('_id' in activePost && activePost._id === 'GLOBAL') {
    activeChecks = allChecksPost
  }

  if ('variantId' in activePost) {
    activeChecks = checkPostValidation(activePost)
  }

  switch (postStatus) {
    case 'schedule':
      await PostHistory.publishV2()

      //? Notifica l'utente che il post potrebbe fallire a causa del limite di post
      if (
        !OWNER_POST_LIMITS_INFINITE &&
        OWNER_POST_INFO_PLANNED + OWNER_POST_LIMITS_USED >= OWNER_POST_LIMITS_AVAILABLE
      ) {
        showInfo(
          T.limitUser.warning_reaching_max_post(
            OWNER_POST_INFO_PLANNED,
            OWNER_POST_LIMITS_USED,
            OWNER_POST_LIMITS_AVAILABLE
          )
        )
      }

      showSuccess(T.success.postPlanned)
      // if (allChecksPost.length > 0) {
      // }
      // if (allChecksPost.length > 0) showError(T.error.verify_that_all_posting_requirements_are_correct)
      return

    case 'draft':
      if (checkDraft) {
        await PostHistory.publishV2()
        showSuccess(T.success.postSavedInDraft)
        resetPage()
      }
      if (!checkDraft) showError(T.error.emptyDraft)
      return

    case 'publish-now':
      if (allChecksPost.length === 0) {
        await PostHistory.publishV2()

        showSuccess(T.success.postGenerated)
        resetPage()
      }

      if (allChecksPost.length > 0) {
        // Se l'active nn ha errori ma gli altri post si
        if (activeChecks.length === 0 && allChecksPost.length > 0) {
          showError(T.error.verify_that_all_posting_requirements_are_correct)
          return
        }

        // mostra gli errori dell'active
        if (activeChecks.length > 0) {
          activeChecks.forEach((problem, index) => {
            // Per non far comparire tutti i problemi insieme
            setTimeout(() => {
              showWarning(problem)
            }, 500 * index)
          })
          return
        }
      }
      return

    default:
      return
  }
}

export const resetPage = async () => {
  const { clearPost } = useCreatePostStore.getState()

  clearPost()
}

const checkIfDraftIsValid = (draft: IPostVariant): boolean => {
  const { data, info } = draft
  const { text, medias } = data

  if (text.length === 0 && medias.length === 0) return false

  return true
}

// Ritorna il primo typo di media che gli passiamo
export const getFirstMedia = (medias: Media[], type) => {
  const firstVideoInMedia = medias.filter((item: any) => item.mimetype.includes(type))
  if (firstVideoInMedia.length === 0) return undefined
  return firstVideoInMedia[0].url
}

export const determineIsEmpty = (subsec, genText, genLink) => {
  if (subsec === 'astersAiText') return genText.length === 0

  if (subsec === 'astersAiLink') return genLink.length === 0

  return true
}

// Funzioni per spostarsi nel carosello media in preview
export const nextMedia = (selectedIndex: number, setSelectedIndex: (newIndex: number) => void, medias) => {
  const newIndex = selectedIndex + 1
  if (newIndex > medias.length - 1) {
    setSelectedIndex(0)
    return
  }

  setSelectedIndex(newIndex)
}

export const prevMedia = (selectedIndex: number, setSelectedIndex: (newIndex: number) => void, medias) => {
  const newIndex = selectedIndex - 1
  if (newIndex < 0) {
    setSelectedIndex(medias.length - 1)
    return
  }

  setSelectedIndex(newIndex)
}

export const getActiveText = () => {
  const { activePost } = useCreatePostStore.getState()

  if (activePost === undefined) return ''

  if ('variantId' in activePost) return activePost.data.text ?? ''
  if ('_id' in activePost && activePost._id === 'GLOBAL') return activePost.text ?? ''

  return ''
}

// brutto, poi sistemo nei prox giorni
export const getActiveDescription = () => {
  const { activePost } = useCreatePostStore.getState()

  if ('variantId' in activePost) return activePost.data.description
  if ('_id' in activePost && activePost._id === 'GLOBAL') return activePost.description

  return ''
}

export const getFirstCommentText = (post: IPostVariant) => {
  return post.data.firstComment?.text
}

export const getActiveInfo = () => {
  const { activePost } = useCreatePostStore.getState()

  if (activePost === undefined) return {} as IVariantInfo

  if ('_id' in activePost && activePost._id === 'GLOBAL') return {} as IVariantInfo

  if ('variantId' in activePost) return activePost.info ?? ({} as IVariantInfo)

  return {} as IVariantInfo
}

export const getActiveData = () => {
  const { activePost } = useCreatePostStore.getState()

  if ('_id' in activePost && activePost._id === 'GLOBAL') return activePost

  if ('variantId' in activePost) return activePost.data ?? {}

  return {}
}

export const getPostData = (post) => {
  if ('_id' in post && post._id === 'GLOBAL') return post

  if ('variantId' in post) return post.data ?? []

  return []
}

export const getFirstCommentData = (post) => {
  return post.data.firstComment
}

//to be removed : fix yt short preview saving
export const checkYTPreview = (func: any) => {
  const { activePost, activePreviewYT, setActivePreview } = useCreatePostStore.getState()

  if (activePost === undefined) return

  if (activePost._id === 'GLOBAL') return

  if ('variantId' in activePost && activePost.info.account.provider.includes('youtube')) {
    if (activePreviewYT === 'YOUTUBE_STANDARD') setActivePreview('YOUTUBE_STANDARD')
    if (activePreviewYT === 'YOUTUBE_SHORT') setActivePreview('YOUTUBE_SHORT')
  } else {
    typeof func === 'function' && func()
  }
}
