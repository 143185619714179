import React from 'react'
import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Icons } from 'components/Icons'
import { AppStore, T, showInfo } from 'utils'

interface Props {
  apiKeyAutopilot: string
}

const AutopilotYourKeyCreated = ({ apiKeyAutopilot }: Props) => {
  /* ---------------------- METHODS ---------------------- */
  const onCopyApiKey = () => {
    navigator.clipboard.writeText(apiKeyAutopilot)
    showInfo(T.headerAutopilotAndModal.info.copyKey)
  }

  const onCloseModal = () => {
    onCopyApiKey()

    return AppStore.closeAllModal()
  }
  return (
    <YourApiKeyContainer>
      {/* ---------------------- TITLE ON CONTAINER  */}
      <Title>{T.headerAutopilotAndModal.titleCreatedKey}</Title>

      {/* ---------------------- DESCRIPTION ON CONTAINER  */}
      <Description>{T.headerAutopilotAndModal.descriptionApiKeyGenerated}</Description>

      {/* ---------------------- API KEY CONTAINER + BUTTON COPY */}

      <ApyKeyContainer>
        {/* ----------- CONTAINER API KEY */}
        <ApiKeyText>{apiKeyAutopilot}</ApiKeyText>

        {/* ----------- BUTTON COPY API KEY */}
        <Button
          variant={'primary'}
          size="smaller"
          style={{ width: 189, height: 40, fontWeight: 500, gap: 8 }}
          onClick={onCopyApiKey}
        >
          <Icons.copyLink fill="currentColor" /> {'Copia Key'}
        </Button>
      </ApyKeyContainer>
    </YourApiKeyContainer>
  )
}

export default AutopilotYourKeyCreated

const YourApiKeyContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  animation: fade-in 0.3s ease-in-out;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const Title = styled(Box)`
  font-size: 21px;
  font-weight: 600;
  line-height: 26.88px;
  color: ${() => AppStore.theme.o.black};
`

const Description = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.92px;
  color: ${() => AppStore.theme.o.black};
`

const ApyKeyContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const ApiKeyText = styled(Box)`
  background-color: ${() => AppStore.theme.o.background};
  color: ${() => AppStore.theme.o.black};
  height: 44px;
  width: 374px;
  max-width: 374px;
  padding: 12px 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.36px;
  border: 1px solid ${() => AppStore.theme.o.grey};
`
