import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { AppStore, showError, T } from 'utils'
import { Box } from './Box'
import { Button } from './Button'
import { Icons } from './Icons'
import { Input } from './Input'
import { Text } from './Text'
import { SearchOutlined } from '@ant-design/icons'
import Workspaces from 'api/Workspaces'
import { Workspace } from 'types'

export interface Roles {
  id: string
  permissions: {
    id: string
    number: number
  }[]
}

interface Props {
  changeSession?: any
  workspace?: any
  users?: any
  rolesNumber?: any
  onWorkspaceChange: (workspace: Workspace) => void
}

export default function RoleGridSection(props: Props) {
  const [searchText, setSearchText] = useState('')
  const [roles, setRoles] = useState<any>([])
  const [maxPermissions, setMaxPermissions] = useState([
    {
      id: T.teamMembers.rulesTitle.publication,
      number: 4,
    },
    {
      id: T.teamMembers.rulesTitle.calendar,
      number: 1,
    },
    {
      id: T.teamMembers.rulesTitle.media,
      number: 4,
    },
    {
      id: T.teamMembers.rulesTitle.settings,
      number: 6,
    },
  ])
  const [Users, setUsers] = useState<
    Array<{ name: string; email: string; role: string; invitationConfirmed: boolean } | undefined>
  >([])

  const { workspace } = props
  const [rolesArray, setRolesArray] = useState<any>(workspace.roles)
  const [filteredRolesArray, setFilteredRolesArray] = useState<any>(workspace.roles)
  const [orderBy, setOrderBy] = useState({
    id: 'none',
    order: false,
  })
  const sessions = [
    T.teamMembers.tableHeaders.name,
    T.teamMembers.tableHeaders.publication,
    T.teamMembers.tableHeaders.calendar,
    T.teamMembers.tableHeaders.media,
    T.teamMembers.tableHeaders.settings,
  ]

  useEffect(() => {
    setUsers(props.users)
  }, [])

  useEffect(() => {
    setRoles(props.rolesNumber)
  }, [props.rolesNumber])

  useEffect(() => {
    setUsers(props.users)
  }, [props.users])

  useEffect(() => {
    setFilteredRolesArray(
      rolesArray.filter((role) => {
        return role.name.toLowerCase().includes(searchText.toLowerCase())
      })
    )
  }, [searchText])

  const findRole = (roleId: string | undefined, referenceArray?: any) => {
    if (referenceArray !== undefined) {
      if (roleId !== undefined)
        return referenceArray.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
    }
    if (roleId !== undefined) return roles.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
  }

  const NumberComponent = ({ id, roleName }) => {
    // @ts-ignore
    const idMaxNumber = maxPermissions?.filter((permission) => permission.id.toLowerCase() === id.toLowerCase())[0]
      .number
    let backGroundColor = AppStore.theme.o.lightGrey
    let textColor = AppStore.theme.o.darkGrey
    const roleData = roles
      .filter((role) => role.roleName.toLowerCase() === roleName.toLowerCase())[0]
      .rolesNumber.filter((role) => role.name.toLowerCase() === id.toLowerCase())[0].permissionSelected

    if (roleData !== 0) {
      backGroundColor = idMaxNumber === roleData ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey
      textColor = idMaxNumber === roleData ? AppStore.theme.o.blue : AppStore.theme.o.black
    }

    return (
      <Box id={`permission-${id}`} width={120} height={25} center>
        <Box style={{ radius: '50%', backgroundColor: backGroundColor, padding: 4 }} ph={8}>
          <HeaderText style={{ color: textColor, fontSize: 12 }}>
            {roleData}/{idMaxNumber}
          </HeaderText>
        </Box>
      </Box>
    )
  }

  const deleteRole = async (roleId, index) => {
    try {
      const response = await Workspaces.deleteRole(workspace._id ?? '', roleId)

      setFilteredRolesArray(filteredRolesArray.filter((role) => role._id !== roleId))
      props.onWorkspaceChange(response)
      setRolesArray(workspace.roles?.filter((role) => role._id !== roleId))
    } catch (error: any) {
      if (error.message.includes('perm')) showError(T.error.roleStillUsed) // se l'utente non ha i permessi mostra errore
    }
  }

  const filterByRole = (role: string) => {
    const sortedUsers = Users.sort((a, b) => {
      if (a === undefined || b === undefined) return 0
      if (role === 'name') return a.name.localeCompare(b.name)
      const aRole = findRole(a.role)
      const bRole = findRole(b.role)
      if (aRole === undefined || bRole === undefined) return 0
      const aPubblicazione = aRole.permissions?.find((permission) => permission.id === role)
      const bPubblicazione = bRole.permissions?.find((permission) => permission.id === role)
      if (aPubblicazione === undefined || bPubblicazione === undefined) return 0
      if (role === orderBy.id && orderBy.order === true) {
        return aPubblicazione.number - bPubblicazione.number
      }
      return bPubblicazione.number - aPubblicazione.number
    })

    setUsers(sortedUsers)
    if (orderBy.id === role) setOrderBy({ id: role, order: !orderBy.order })
    else
      setOrderBy({
        id: role,
        order: true,
      })
  }

  return (
    <Box removeHover style={{ maxHeight: 570, minWidth: 950 }} hideBar width={'100%'}>
      <Box
        style={{
          height: 48,
          justifyContent: 'space-between',
        }}
        mt={32}
        row
      >
        <Text style={{ fontSize: 21, fontWeight: 700 }}>{T.teamMembers.manageRole}</Text>
        <Input
          style={{
            width: 337,
            border: `1px dotted ${AppStore.theme.o.darkGrey}`,
            backgroundColor: 'transparent',
            height: 42,
          }}
          variant="whiteDotted"
          placeholder={T.teamMembers.searchRole}
          prefix={<SearchOutlined />}
          onChange={(txt) => setSearchText(txt)}
        />

        <Button
          variant="primary"
          size="small"
          style={{ height: 42, maxWidth: 170, borderRadius: 14 }}
          flex
          onClick={() => {
            props.changeSession('permissions?changeName=true' + (workspace._id ? `&id=${workspace._id}` : ''))
            AppStore.closeAllModal()
          }}
          shadow="none"
        >
          <CreateRoleButtonText>{T.teamMembers.addNewRole}</CreateRoleButtonText>
        </Button>
      </Box>

      {/* //---- header ---- ---- ---- ---- ---- ---- ---- ----  */}
      <Box row vcenter id={'header'} ml={25}>
        <Box id={'name'} width={200} height={60} vcenter row>
          <Box height={12} width={12} style={{ backgroundColor: AppStore.theme.o.grey }} ml={-20} mr={8} />
          <HeaderText>{T.teamMembers.tableHeaders.name}</HeaderText>
        </Box>
        <Box row style={{ justifyContent: 'space-around' }} gap={40}>
          {sessions.map((session, index) => {
            if (index !== 0) {
              return (
                <Box
                  id={'header-session-' + index}
                  width={120}
                  hcenter
                  row
                  removeHover
                  center
                  onClick={() => filterByRole(session)}
                >
                  <HeaderText>{session}</HeaderText>
                  <Box>
                    <SortIcon />
                  </Box>
                </Box>
              )
            }
          })}
        </Box>
      </Box>
      <DivisionLine />
      <Box>
        {/* ---- ---- ROLES ---- ---- ---- ---- ---- ---- ---- ----  */}
        {filteredRolesArray.map((role, index) => {
          if (role !== undefined)
            return (
              <>
                <Box relative vcenter row ml={25}>
                  <Box relative vcenter row>
                    <Box id={'user-name'} width={200} height={60} vcenter>
                      <HeaderText>{role.name}</HeaderText>
                    </Box>
                    <Box row style={{ justifyContent: 'space-around' }} gap={40}>
                      {findRole(role.name, roles)?.rolesNumber.map((session, i) => {
                        return (
                          <Box width={120} height={32} vcenter>
                            {NumberComponent({ id: session.name, roleName: role.name })}
                          </Box>
                        )
                      })}
                    </Box>
                    <Box id={`crudIcon-${index}`} gap={24} row ml={24}>
                      <EditIcon
                        onClick={() => {
                          props.changeSession(`permissions?id=${role._id}`)
                          AppStore.closeAllModal()
                        }}
                      />
                      <SquareTrash
                        onClick={() => {
                          deleteRole(role._id, index)
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
                <DivisionLine />
              </>
            )
        })}
      </Box>
    </Box>
  )
}

const SortIcon = styled(Icons.sortIcon)`
  /* fill: ${() => AppStore.theme.o.darkGrey}; */
  width: 12px;
  height: 12px;
  margin-left: 8px;
`

const DivisionLine = styled.div`
  background-color: ${() => AppStore.theme.o.grey};
  height: 1px;
  width: 100%;
`

const HeaderText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${() => AppStore.theme.o.black};
`

const CreateRoleButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${() => AppStore.theme.o.blue};
`

const SquareTrash = styled(Icons.squareTrash)`
  width: 24px;
  height: 24px;
  fill: ${() => AppStore.theme.o.red};
  cursor: pointer;
`

const EditIcon = styled(Icons.edit)`
  width: 24px;
  height: 24px;
  fill: ${() => AppStore.theme.o.darkGrey};
  cursor: pointer;
`
