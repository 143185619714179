/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { EmptyState } from 'components/UI'
import { SocialPicture } from 'components/UI/SocialPicture'
import { ToolTip } from 'pages/settings/Subscription/SubscriptionProvider'
import { SocialAccount } from 'types'
import { GraphType } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { AnalyticsTooltip } from '../AnalyticsTooltip'
import { HighChartsGraph } from './HighChartsGraph'
import { ReferenceValue } from './ReferenceValue'
import { TextInfoGraph } from './singleGraph/TextInfoGraph'

/* ------------------- INTERFACES  */
interface Props {
  type: GraphType
  title: string
  socialProfile: SocialAccount
  labelText?: string
  numData?: number
  datasets?: any
  options?: any
  width?: number
  socialPicture: string
  headerData?: IHeaderGraphData[]
  tooltipLabels?: string[] | string[][] // ["data", "impressions"] | [["data", "reel"], ["data", "carousel"]]
  tooltipInfo: ToolTip
}

interface IHeaderGraphData {
  label: string
  value: string
}

const test: ToolTip = {
  note: 'Note',
  title: 'Title',
  text: 'Text',
}

export const Graph = ({
  type,
  datasets = [],
  title,
  options = {},
  width,
  labelText,
  numData,
  socialProfile,
  headerData,
  tooltipLabels,
  tooltipInfo,
}: Props) => {
  /* ----------------- VARIABLES  */
  // Valore che serve per definire se il grafico ha un errore o no
  const canShowGraph: boolean = datasets[0].data !== null && datasets[0].noDataText === undefined

  const renderGraph = () => {
    switch (type) {
      case 'text':
        const validDataset = datasets.find((dataset) => dataset && dataset.data !== null)
        return <TextInfoGraph textGraph={validDataset} />

      //TODO SISTEMA IL CASE (GREG)
      case 'areaspline':
        return (
          <HighChartsGraph
            type={type}
            dataSeries={datasets[0].data}
            xAxisLabels={datasets[0].dateArray ?? undefined}
            tooltipLabels={tooltipLabels}
          />
        )

      case 'pie' || 'doughnut':
        return (
          <HighChartsGraph type={type} dataSeries={datasets[0].data} xAxisLabels={datasets[0].dateArray ?? undefined} />
        )

      //Highcharts charts
      case 'heatmap':
        return (
          <HighChartsGraph
            type={type}
            dataSeries={datasets[0].data}
            xAxisLabels={datasets[0].xAxis?.categories ?? undefined}
            yAxisLabels={datasets[0].yAxis?.categories ?? undefined}
            tooltipLabels={tooltipLabels}
          />
        )
    }
  }

  return (
    <GraphContainer width={width ?? '100%'}>
      {/* ----------------- HEADER GRAPH CONTAINER */}
      <GraphHeader>
        {/* ----------------- TITLE / ICON CONTAINER */}
        <GraphTitleContainer>
          <SocialPicture
            picture={socialProfile.picture}
            provider={socialProfile.provider}
            dimensionPicture={28}
            dimensionProvider={22}
          />
          {title}
          <TooltipContainer>
            <AnalyticsTooltip tooltip={tooltipInfo} />
          </TooltipContainer>
        </GraphTitleContainer>
        {/* <ReferenceValue valueLabel={labelText} currentValue={referenceValue}> */}
        {headerData && (
          <GraphSubHeader>
            {/* <DropdownAccordion options={['option 1', 'option 2', 'option 3']} /> */}
            {headerData.map((data) => {
              return <ReferenceValue valueLabel={data.label} currentValue={data.value} />
            })}
            {/* <PercentageComparison oldValue={218} percentage={2131} /> */}
          </GraphSubHeader>
        )}
      </GraphHeader>

      {/* ----------------- GRAPH CONTAINER */}
      {/* <GraphWrapper>{rederGraphEnum[type]}</GraphWrapper> */}

      {canShowGraph ? (
        <GraphWrapper isTextGraph={type === 'text'}>{renderGraph()}</GraphWrapper>
      ) : (
        <EmptyState
          noPaddingTop
          text={
            !datasets[0]?.noDataText
              ? T.error.no_data_at_this_moment
              : datasets[0]?.noDataText === 'No post in selected period'
              ? T.error.noPostInSelectedPeriod
              : T.error.no_data_at_this_moment
          }
          icon={<Icons.sadFace fill={AppStore.theme.o.red} />}
        />
      )}

      <DragDropHandle className="drag-handle">
        <Icons.dragDropAnalytics fill={AppStore.theme.o.darkGrey} />
      </DragDropHandle>
    </GraphContainer>
  )
}

const GraphContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  width: 542px;
  max-width: 400px;
  height: 428px;
  min-height: 428px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 12px;
  padding: 16px 24px;
  border: 1px solid ${() => AppStore.theme.o.lightGrey};
  border-radius: 24px;
  justify-content: start;
  cursor: unset !important;
  position: relative;
`

const GraphHeader = styled(Box)`
  gap: 8px;
`

const GraphSubHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 8px;
  height: 52;
  gap: 32px;
`

const GraphTitleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  font-weight: 800;
  line-height: 19.23px;
  letter-spacing: 0.16px;
  text-transform: capitalize;
`

const GraphWrapper = styled(Box)<{ isTextGraph: boolean }>`
  flex: 1;
  width: 100%;
  justify-content: ${(props) => (props.isTextGraph ? 'center' : 'flex-end')};
  margin-bottom: ${(props) => (props.isTextGraph ? '-20px' : '')};
`

const DragDropHandle = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  cursor: move;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 300ms ease-in-out all;
`

const TooltipContainer = styled(Box)`
  margin-left: auto;
`
