import React, { useEffect, useState } from 'react'
import { Box } from 'components'

import styled from '@emotion/styled'
import { AppStore, T } from 'utils'
import { Permissions } from './UserPermission'
interface Props {
  listOfPermissions: Permissions
  sectionIndex: number
  onPermissionUpdate: (rule: string | string[]) => void
}

export default function PermissionsSettingComponent(props: Props) {
  const [permissions, setPermissions] = useState<Permissions>()
  const [fillColorSelected, setFillColorSelected] = useState(AppStore.theme.o.grey)
  const [colorSelected, setColorSelected] = useState(AppStore.theme.o.black)

  useEffect(() => {
    setPermissions(props.listOfPermissions)
  }, [])

  useEffect(() => {
    if (checkIfAllSelected(permissions?.title)) {
      setColorSelected(AppStore.theme.o.green)
      setFillColorSelected(AppStore.theme.o.lightGreen)
    } else {
      setColorSelected(AppStore.theme.o.black)
      setFillColorSelected(AppStore.theme.o.grey)
    }
  }, [permissions])

  const onRuleUpdate = (rule: string | string[]) => {
    props.onPermissionUpdate(rule)
  }

  const checkIfAllSelected = (title: string | undefined) => {
    if (permissions === undefined) return
    if (
      title === T.teamMembers.rulesTitle.publication ||
      title === T.teamMembers.rulesTitle.media ||
      title === T.teamMembers.rulesTitle.calendar
    ) {
      const permissionTrue = permissions?.rules.filter((rule) => rule.enabled === true).length

      if (permissionTrue === permissions?.rules.length - 1) {
        return true
      } else return false
    } else {
      if (permissions) return permissions.rules.every((rule) => rule.enabled === true)
    }
  }

  const handleSectionToggle = () => {
    const prevData = permissions
    if (permissions) {
      setPermissions((prevPermissions) => {
        if (prevPermissions) {
          const prevState = prevPermissions.sectionEnabled
          const updatedPermissions = {
            ...prevPermissions,
            sectionEnabled: !prevPermissions.sectionEnabled,
          }

          if (prevState) {
            updatedPermissions.rules = updatedPermissions.rules.map((rule) => ({
              ...rule,
              enabled: false,
            }))
          } else {
            updatedPermissions.rules = updatedPermissions.rules.map((rule) => {
              if (rule.ruleTitle.includes(T.teamMembers.comingSoon)) {
                return {
                  ...rule,
                  enabled: false,
                }
              } else {
                return {
                  ...rule,
                  enabled: true,
                }
              }
            })
          }

          const arrayToSend: any[] = []
          if (prevData === undefined) return
          for (let i = 0; i < prevData?.rules.length; i++) {
            const rule1 = prevData?.rules[i]
            const rule2 = updatedPermissions.rules[i]

            if (rule1.enabled !== rule2.enabled) {
              arrayToSend.push(rule1.id)
            }
          }

          onRuleUpdate(arrayToSend)

          return updatedPermissions
        }
      })
    }
  }

  const handleRuleToggle = (ruleIndex: number) => {
    if (permissions) {
      setPermissions((prevPermissions) => {
        if (prevPermissions) {
          const updatedPermissions = {
            ...prevPermissions,
            rules: prevPermissions.rules.map((rule, index) => {
              if (index === ruleIndex) {
                onRuleUpdate(rule.id)
                return {
                  ...rule,
                  enabled: !rule.enabled,
                }
              }
              return rule
            }),
          }

          if (!prevPermissions.sectionEnabled) updatedPermissions.sectionEnabled = true

          return updatedPermissions
        }
      })
    }
  }

  return (
    <Box flex width={'464px'} m={'8px'}>
      <PermissionTitleContainer vcenter row>
        <PermissionTitle
          style={{ color: permissions?.sectionEnabled ? AppStore.theme.o.black : AppStore.theme.o.grey }}
        >
          {permissions?.title}
        </PermissionTitle>
        <Box onClick={() => handleSectionToggle()}>
          <Box
            style={{
              width: 32,
              height: 20,
              backgroundColor: permissions?.sectionEnabled ? fillColorSelected : AppStore.theme.o.lightGrey,
              padding: 2,
              outline: `1px solid ${permissions?.sectionEnabled ? colorSelected : AppStore.theme.o.grey}`,
            }}
          >
            <Box
              style={{
                width: 16,
                height: 16,
                backgroundColor: permissions?.sectionEnabled ? colorSelected : AppStore.theme.o.selectPermissionButton,
                x: permissions?.sectionEnabled ? 12 : 0,
              }}
            />
          </Box>
        </Box>
      </PermissionTitleContainer>
      <PermissionContainer>
        {permissions?.rules.map((element, index) => (
          <Box flex row style={{ justifyContent: 'space-between', padding: '12px' }} vcenter>
            <Box
              onClick={() => {
                if (element.ruleTitle.includes(T.teamMembers.comingSoon)) {
                  return
                }
                handleRuleToggle(index)
              }}
              mr={'8px'}
            >
              <Box
                style={{
                  width: 32,
                  height: 20,
                  backgroundColor: element.enabled ? AppStore.theme.o.lightGreen : AppStore.theme.o.lightGrey,
                  padding: 2,
                  outline: `1px solid ${element.enabled ? AppStore.theme.o.green : AppStore.theme.o.grey}`,
                }}
              >
                <Box
                  style={{
                    width: 16,
                    height: 16,
                    backgroundColor: element.enabled ? AppStore.theme.o.green : AppStore.theme.o.selectPermissionButton,
                    x: element.enabled ? 12 : 0,
                  }}
                />
              </Box>
            </Box>
            <PermissionText style={{ color: element.enabled ? AppStore.theme.o.black : AppStore.theme.o.grey }}>
              {element.ruleTitle}
            </PermissionText>
          </Box>
        ))}
      </PermissionContainer>
    </Box>
  )
}

const PermissionTitleContainer = styled(Box)`
  height: 44px;
  width: 464px;
  text-align: center;
  background-color: ${() => AppStore.theme.o.surface};
  z-index: 99;
  justify-content: space-between;
  padding-inline: 12px;
  box-shadow: ${() => AppStore.theme.simpleOuterShadow}!important;
`

const PermissionContainer = styled(Box)`
  width: 464px;
  margin-top: -12px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
  box-sizing: border-box;
  padding: 18px;
`

const PermissionText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
`

const PermissionTitle = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  text-align: center;
  letter-spacing: 0.01em;
`
