/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { YoutubeStandard } from './YoutubeStandard'
import { YoutubeShort } from './YoutubeShort'
import { YoutubeSwitchPreview } from './YoutubeSwitchPreview'

/* ------------------- INTERFACES  */
interface Props {
  active: string
}

export const Youtube = ({ active }: Props) => {
  return (
    <YoutubeContainer>
      {active === 'YOUTUBE_STANDARD' && <YoutubeStandard />}
      {active === 'YOUTUBE_SHORT' && <YoutubeShort />}
    </YoutubeContainer>
  )
}

const YoutubeContainer = styled(Box)`
  width: 100%;
  height: 100%;
`
