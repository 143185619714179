import styled from '@emotion/styled'
import React from 'react'
import { SocialMedias } from 'types'

const SocialIcons = require('assets/icons')

export type SocialIconsProvider = SocialMedias

interface Props {
  social: SocialIconsProvider
  active?: boolean
  style?: React.CSSProperties
}

export function SocialIcon({ active = true, social, style }: Props) {
  return (
    <Icon className={!active ? 'inactive' : ''} src={SocialIcons[`${social?.toLocaleLowerCase()}`]} style={style} />
  )
}

const Icon = styled.img`
  pointer-events: none;
  width: 32px;
  height: 32px;

  user-select: none;

  &.inactive {
    filter: grayscale(1) brightness(1.8) invert(0.2);
  }
`
