/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { TextPostInfo } from './TextInfo/TextPostInfo'
import { openEmojidModal } from 'components/Modal/ModalManager'
import { getActiveDescription, getActiveText, isUnique, updateActive } from 'utils/PostUtils/Post'
import { Utm } from './Utm/Utm'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { GlobalPull } from './GlobalPull'
import { canShowMetadata, getLinkProperties, onPasteLink } from 'utils/PostUtils/ShortUrl'
import { Metadata } from './Metadata/Metadata'

/* ------------------- INTERFACES  */
interface Props {}

export const TextContainer = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
  }))

  /* ----------------- VARIBLES  */
  const showMetadata = canShowMetadata()

  const showUtm = getLinkProperties()!.length > 0

  const isYoutube = activePost?.info?.account?.provider === 'youtube'

  /* ----------------- METHODS  */
  // Calcola l'altezza del Textarea in base alle condizioni date
  const calculateTextareaHeight = () => {
    if (isYoutube) {
      return '55px'
    } else {
      let height = 604 // su figma le preview hanno altezze diverse, questa è messa perchè sembra la più bilanciata a livello di padding

      if (showUtm && utmProviderCheck()) {
        height -= 56
      }

      if (showMetadata && utmProviderCheck()) {
        height -= 160
      }

      return `${height}px`
    }
  }

  const calculateTextareaHeightDescriptionYT = () => {
    let height = 545

    if (showUtm && utmProviderCheck()) {
      height -= 56
    }

    return `${height}px`
  }

  const utmProviderCheck = () => {
    const provider = activePost?.info?.account?.provider
    const bannedProviders = ['instagram', 'tiktok']
    return !bannedProviders.includes(provider)
  }

  const linkCheck = () => {
    return activePost?.data?.urls?.length > 0 || activePost?.urls?.length > 0
  }

  return (
    <TextContainerContainer>
      {/* Textarea  generale */}
      <TextareaContainer
        style={{
          height: calculateTextareaHeight(),
        }}
      >
        {isYoutube ? (
          <TextTitlePost
            id="text-area-create-post"
            value={getActiveText()}
            onChange={(e) => {
              const description = e.target.value
              const activeText = getActiveText()

              updateActive({ text: e.target.value })

              if (description.length > activeText.length + 1) onPasteLink({ activePost, newText: description })
            }}
            placeholder={T.postPage.insertHereTextYouTube}
          />
        ) : (
          <TextAreaPost
            id="text-area-create-post"
            value={getActiveText()}
            onChange={(e) => {
              const description = e.target.value
              const activeText = getActiveText()

              updateActive({ text: e.target.value })

              if (description.length > activeText.length + 1) onPasteLink({ activePost, newText: description })
            }}
            placeholder={T.postPage.insertHereText}
          />
        )}

        {!isYoutube && <EmojiIcon onClick={() => openEmojidModal('text-area-create-post')} />}

        {activePost?._id !== 'GLOBAL' && isUnique(activePost?.variantId) && <GlobalPull />}
      </TextareaContainer>

      {/* Textarea  descrizione youtube */}
      {isYoutube && (
        <TextareaContainer>
          <TextAreaPost
            id="text-area-create-post-description"
            style={{ height: calculateTextareaHeightDescriptionYT() }}
            value={activePost.data.description}
            placeholder={T.postPage.changeDescriptionYouTube}
            onChange={(e) => {
              const description = e.target.value
              const activeDescription = getActiveDescription() ?? ''
              updateActive({ description })
              if (description.length > activeDescription.length + 1)
                onPasteLink({ activePost, newText: description, isDescription: true })
            }}
          />
          <EmojiIcon onClick={() => openEmojidModal('text-area-create-post-description')} />
        </TextareaContainer>
      )}

      {/* Metadata */}
      {linkCheck() && showMetadata && utmProviderCheck() && <Metadata post={activePost} />}

      {/* UTM */}
      {linkCheck() && showUtm && utmProviderCheck() && <Utm post={activePost} isDescription={isYoutube} />}

      {/* Length text check, time to read, Text difficulty */}
      <TextPostInfo />
    </TextContainerContainer>
  )
}

const TextContainerContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 16px;
`

const TextareaContainer = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;
  border: none;
  width: 442px;
`

const EmojiIcon = styled((props) => <Icons.emojiIcons {...props} />)`
  width: 35px;
  height: 35px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  transition: 300ms ease-in-out;
  cursor: pointer;
  fill: ${AppStore.theme.o.darkGrey};

  :hover {
    fill: ${AppStore.theme.o.grey};
  }
`

const TextTitlePost = styled.input`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  resize: none;
  background: ${() => AppStore.theme.o.lightestGrey};
  transition: 300ms ease-in-out;
  color: ${() => AppStore.theme.o.black};
  font-weight: 600;
  border: 1px solid ${() => AppStore.theme.o.grey};
  box-sizing: border-box;

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &::placeholder {
    font-weight: 600;
    color: ${() => AppStore.theme.o.darkGrey};
  }
`

const TextAreaPost = styled.textarea`
  width: 100%;
  height: 100%;
  padding: 16px;
  border-radius: 16px;
  resize: none;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  transition: 300ms ease-in-out;
  color: ${() => AppStore.theme.o.black};
  font-weight: 600;

  &:hover,
  &:focus {
    background: ${() => AppStore.theme.o.lightGrey};
  }

  &::placeholder {
    font-weight: 600;
    color: ${() => AppStore.theme.o.darkGrey};
  }
`
