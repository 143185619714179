import { Box, Button, MultiToggleButton, SocialToggleButton } from 'components'
import { showDraftModal } from 'components/Modal/ModalManager'
import { useLocation } from 'react-router-dom'
import { ISocialFilters, TStatus } from 'stores/interfaces/CalendarPageInterface'
import { T } from 'utils'

/* ___________________ TYPE ___________________ */

/* ___________________ INTERFACE ___________________ */
interface Props {
  refreshPosts: (s: TStatus) => void
  socialFilters: ISocialFilters[]
  setSocialFilters: (s: any) => void
  goToPost?: (bol: boolean) => void
}

const CalendarHeader = ({ refreshPosts, socialFilters, setSocialFilters, goToPost }: Props) => {
  return (
    <Box row mr={48} mt={24} gap={32}>
      <MultiToggleButton
        id="post-status-filters"
        style={{alignItems:"center"}}
        onChange={(s) => refreshPosts(s as TStatus)}
        values={[T.status.all, T.status.planned, T.status.published, T.status.notPublished]}
      />

      <SocialToggleButton id="post-social-filters" socials={socialFilters} setSocial={setSocialFilters} />

      <Button
        id="draft-calendar-page"
        width={160}
        variant="neutral"
        center
        onClick={() => showDraftModal(goToPost)}
        size="small"
      >
        {T.calendarPage.goToPlaned}
      </Button>
    </Box>
  )
}

export default CalendarHeader
