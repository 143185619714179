import Notify from 'api/Notify'
import { AppStore, showError } from 'utils'

// const getNotification = async () => {
//   try {
//     const res = await Notify.getNotification()

//     return res
//   } catch (error: any) {
//     showError(error.message)
//   }
// }

const postMarkNotificationAsRead = async (notificationId: string) => {
  try {
    const res = await Notify.markNotificationAsRead(notificationId)
  } catch (error: any) {
    showError(error.message)
  }
}

const postMarkAllNotificationAsRead = async () => {
  try {
    const res = await Notify.markAllNotificationsAsRead()
  } catch (error: any) {
    showError(error.message)
  }
}

export { postMarkAllNotificationAsRead, postMarkNotificationAsRead }
