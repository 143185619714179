/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { CardCheckBox } from './CardCheckBox'

/* ------------------- INTERFACES  */
interface Props {
  isAllSelected?: boolean
  handleSelectAll?: () => void
  handleDelete?: () => void
  show?: boolean // Booleano per mostrare o meno l'handlecard situazionalmente
}

export const HandleSelectCard = ({ isAllSelected, handleSelectAll, handleDelete, show }: Props) => {
  return (
    <HandleSelectCardContainer className="handle_select_card" style={show ? { display: 'flex' } : { display: 'none' }}>
      {handleSelectAll && (
        <HandleSelectButton className="handle_select_button" onClick={handleSelectAll}>
          {T.AiDiscoverPage.boardCard.selectionAll} <CardCheckBox isSelected={isAllSelected} />
        </HandleSelectButton>
      )}

      {handleDelete && (
        <HandleSelectButton className="handle_select_button delete" onClick={handleDelete}>
          {T.AiDiscoverPage.boardCard.Delete} <Icons.delete fill={AppStore.theme.o.red} width={20} height={20} />
        </HandleSelectButton>
      )}
    </HandleSelectCardContainer>
  )
}

const HandleSelectCardContainer = styled(Box)`
  &.handle_select_card {
    margin: 0 auto;
    height: 56px;
    display: flex;
    flex-direction: row;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    border-radius: 14px;
    background: ${() => AppStore.theme.o.surface};
    box-shadow: ${() => AppStore.theme.bigOuterShadowNoBox};
  }
`

const HandleSelectButton = styled(Box)`
  &.handle_select_button {
    background: ${() => AppStore.theme.o.surface};
    color: ${() => AppStore.theme.o.black};
    border-radius: 12px;
    position: relative;
    height: 40px;
    display: flex;
    flex-direction: row;
    padding: 4px 16px;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-weight: 700;

    &:hover {
      background: ${() => AppStore.theme.o.lightBlue};
    }

    &.delete {
      color: ${() => AppStore.theme.o.red};

      &:hover {
        background: ${() => AppStore.theme.o.lightRed};
      }
    }
  }
`
