/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'

/* ------------------- INTERFACES  */
interface Props {
  imgSrc: string
}

export const TiktokAssets = ({ imgSrc }: Props) => {
  return (
    <TiktokAssetsContainer>
      <AllTopIcons />
      <AllBottomIcons />

      <AddTiktok />
      <ProfileImg style={{ zIndex: 1 }} src={imgSrc} />
    </TiktokAssetsContainer>
  )
}

const TiktokAssetsContainer = styled(Box)``

const AllBottomIcons = styled((props) => <Icons.tiktokAllBottomIcons {...props} />)`
  position: absolute;
  bottom: 30px;
  left: 10px;
  z-index: 20;
`
const AllTopIcons = styled((props) => <Icons.tiktokAllTopIcons {...props} />)`
  position: absolute;
  top: 20px;
  left: 10px;
  z-index: 20;
`

const AddTiktok = styled((props) => <Icons.tiktokAdd {...props} />)`
  z-index: 2;
  top: 286px;
  right: 21px;
  position: absolute;
`
const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  z-index: 0;
  position: absolute;
  top: 260px;
  right: 13px;
`
