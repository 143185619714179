import styled from '@emotion/styled'
import { Input } from 'antd'
import Utils from 'api/Utils'
import { Box, Icons, Text } from 'components'
import gsap from 'gsap'
import BackwardForwardIcons from 'pages/create-post/Components/BackwardForwardIcons'
import PinedComponents from 'pages/create-post/Components/PinedComponents'
import { Component } from 'react'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T, showInfo, showSuccess, showWarning } from 'utils'
import { updatePost } from 'utils/PostUtils/Post'

interface Props {
  post: IPostVariant
  postTags: any
  onEnteringPin: (boolean) => void
}

interface State {
  createdMention: {
    name: string
    cordinates: { x: number; y: number }
    _id: string
  }[]
  boxActive: boolean
  userTagName: string
  setting: {
    left: any
    right: any
    top: any
    bottom: any
    x: any
    y: any
  }
  lastPosition: {
    x: number
    y: number
  }
  eventOn: boolean
  hoverTagOption: number
  modifyingName: string
  isClosing: boolean
  activeImage: number
  activeIdSetter: string
  isHovered: boolean

  notOpenBox: boolean
}

export default class InstagramPiningModal extends Component<Props, State> {
  state: State = {
    createdMention: [],
    boxActive: false,
    userTagName: '',
    setting: {
      left: undefined,
      right: undefined,
      top: undefined,
      bottom: undefined,
      x: undefined,
      y: undefined,
    },
    lastPosition: {
      x: 0,
      y: 0,
    },
    eventOn: false,
    hoverTagOption: -1,
    modifyingName: '',
    isClosing: false,
    activeImage: 0,
    activeIdSetter: '',
    isHovered: false,
    notOpenBox: false,
  }

  componentWillUnmount(): void {
    const { createdMention } = this.state

    const { variantId } = this.props.post
    updatePost(variantId, { tags: createdMention })
  }

  componentDidMount(): void {
    this.setState({ activeIdSetter: this.props.post.data.medias[0]._id })
    if (this.props.post.data.tags !== undefined) this.setState({ createdMention: this.props.post.data.tags as any })
  }

  escapePressed = () => {
    this.setState({ boxActive: false })
  }

  enterPressed = async () => {
    const { userTagName, setting, lastPosition, createdMention, hoverTagOption, activeIdSetter } = this.state

    if (hoverTagOption !== -1) {
    }

    if (userTagName === undefined) {
      showInfo(T.info.haveToEnterUsername)
      return
    }

    const userExist: any = await Utils.ceackIfUserExists({ userName: userTagName })

    if (userExist.data === null) {
      this.setState({ boxActive: false, userTagName: '' })
      showInfo(T.info.userNotFoundInstagram)
      return
    }

    const mentionObject = {
      name: userTagName,
      cordinates: { x: lastPosition.x, y: lastPosition.y },
      _id: activeIdSetter,
    }

    this.setState({
      createdMention: [...createdMention, mentionObject],
      boxActive: false,
      userTagName: '',
      setting: { left: undefined, right: undefined, top: undefined, bottom: undefined, x: undefined, y: undefined },
      lastPosition: { x: 0, y: 0 },
    })
  }

  movePin = (e: any) => {
    {
      const { boxActive, eventOn, hoverTagOption } = this.state
      if (hoverTagOption !== -1) {
        showWarning(T.warning.confirmOrCancelBeforeAddingTag)
        return
      }

      if (this.state.boxActive) {
        return
      }

      // L'indiano ha pianto su questo codice quindi pls non toccare
      //--------------------------------------------------------------------------------------------
      //variabili per prendere valori da html
      const div = document.getElementById('pinDiv') as HTMLDivElement
      const innerPinBox = document.getElementById('innerPinBox') as HTMLDivElement
      const mainBox = document.getElementById('mainBox') as HTMLDivElement
      const innerPinBoxSection = document.getElementById('innerPinBoxSection') as HTMLDivElement

      //dichiarazionid elel variabili per trovare le posizioni dove va spostato il box
      const x: any = ((e.pageX - div.getBoundingClientRect().x) / div.getBoundingClientRect().width).toFixed(2)
      const y: any = ((e.pageY - div.getBoundingClientRect().y) / div.getBoundingClientRect().height).toFixed(2)
      const distanceFromLeft = e.pageX - div.getBoundingClientRect().x
      const distanceFromTop = e.pageY - div.getBoundingClientRect().y
      const addOnValueX = x * ((div.getBoundingClientRect().width - 750) * -1)
      const addOnValueY = y * ((div.getBoundingClientRect().height - 750) * -1)
      const totalX: number = distanceFromLeft + addOnValueX
      const totalY: number = distanceFromTop + addOnValueY

      this.setState({ lastPosition: { x: x, y: y } })

      //--------------------------------------------------------------------------------------------
      //i settings per il box
      const settings = {
        left: undefined as any,
        right: undefined as any,
        top: undefined as any,
        bottom: undefined as any,
        x: undefined as any,
        y: undefined as any,
      }

      //animazione per il box interno ( per resettare la posizione)
      gsap.set(innerPinBox, {
        duration: 0.5,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        x: 0,
        y: 0,
        autoAlpha: 0,
      })

      //impostazioni del setting per il box interno

      if (totalX <= 267) {
        settings.left = 0
      } else if (totalX >= 533) {
        settings.left = -78
      } else {
        settings.left = -39
      }

      if (totalY <= 400) {
        settings.top = 30
      } else {
        settings.y = -40
      }

      gsap.set(mainBox, {
        duration: 0.5,
        left: totalX,
        top: totalY,
      })

      gsap.set(innerPinBox, {
        duration: 0.5,
        left: settings.left,
        right: settings.right,
        top: settings.top,
        bottom: settings.bottom,
        x: settings.x,
        y: settings.y,
        autoAlpha: 1,
      })

      this.setState({ setting: settings })
    }

    this.doSwanAnimation()

    //per nn far apire il seoncod box ameno che nn si confermi o annulli
    this.setState({ boxActive: true })
    this.props.onEnteringPin(true)
  }

  doSwanAnimation = () => {
    const innerPinBoxSection = document.getElementById('innerPinBoxSection') as HTMLDivElement

    const tl = gsap.timeline()

    tl.set(innerPinBoxSection, {
      x: -1000,
    })

    tl.to(innerPinBoxSection, {
      delay: 0.2,
      duration: 0.3,
      x: 0,
    })
  }

  event: any = undefined

  moveCarousel = (index: number) => {
    const { post } = this.props
    const mediasArrayLength = (post.data.medias?.length ?? 0) - 1
    const nextIndex =
      this.state.activeImage + index < 0
        ? mediasArrayLength
        : this.state.activeImage + index > mediasArrayLength
        ? 0
        : this.state.activeImage + index

    if (post.data.medias === undefined) return

    if (post.data.medias[nextIndex] === undefined || post.data.medias[nextIndex].mimetype?.includes('video')) {
      this.moveCarousel(2)
      return
    }
    this.setState({ activeImage: nextIndex, activeIdSetter: post.data.medias[nextIndex]._id })
  }

  deleteMention = (value: any) => {
    this.setState({ createdMention: value })
  }

  render() {
    const { post } = this.props
    const { setting, boxActive, activeIdSetter } = this.state
    const activeMention = this.state.createdMention.filter((item) => item._id === this.state.activeIdSetter)

    return (
      <Box relative>
        <PinedComponents
          boxActive={boxActive}
          deleteMention={this.deleteMention}
          createdMention={this.state.createdMention}
          setIfNotOpenBox={(value: boolean) => this.setState({ notOpenBox: value })}
          setBoxActive={(value: boolean) => this.setState({ boxActive: value })}
          activeIdSetter={activeIdSetter}
          updateMention={(value: any) => this.setState({ createdMention: value })}
        />
        {/* La parte per far comparire il box per pinnare */}
        {/* --------_--------_--------_--------_--------_--------_--------_--------_--------_--------_--------_ */}
        <Box
          id="pinDiv"
          width={750}
          height={750}
          borderRadius={16}
          relative
          onClick={(e) => {
            if (!this.state.notOpenBox) this.movePin(e)
            else {
              showInfo(T.info.cancelOrConfirmPin)
            }
          }}
        >
          <Box
            id={'mainBox'}
            absolute
            style={{
              zIndex: 99,
              minWidth: 30,
              minHeight: 30,
              backgroundColor: 'trasparent',
              maxWidth: 30,
              maxHeight: 30,
              borderRadius: 0,
            }}
          >
            <Box relative style={{ borderRadius: 0, minWidth: 200, minHeight: 100 }}>
              <Box
                id={'innerPinBox'}
                absolute
                style={{
                  width: 500,
                  height: 60,
                  padding: '8px, 16px, 8px, 16px',
                  overflow: 'hidden',
                }}
              >
                <Box
                  id={'innerPinBoxSection'}
                  absolute
                  center
                  style={{
                    minHeight: 36,
                    maxHeight: 36,
                    backgroundColor: AppStore.theme.o.surface,
                    padding: '8px, 16px, 8px, 16px',
                    border: `1px solid ${AppStore.theme.o.darkGrey}`,
                    x: boxActive ? 0 : -1000,
                  }}
                >
                  <Box relative center vcenter row>
                    <Box style={{ maxHeight: 30, marginLeft: 8 }}>
                      <Text style={{ color: AppStore.theme.o.black }}>@</Text>
                    </Box>

                    <Input
                      value={this.state.userTagName}
                      placeholder="Nome utente"
                      maxLength={31}
                      style={{ width: '34ch' }}
                      onChange={(e) => this.setState({ userTagName: e.target.value })}
                    ></Input>
                    <BoxOptions
                      style={{ backgroundColor: AppStore.theme.o.surface }}
                      onClick={() => {
                        this.escapePressed()
                      }}
                      id={'closeIcon'}
                      center
                    >
                      <Icons.tagDelete fill={AppStore.theme.o.red} />
                    </BoxOptions>
                    <BoxOptions
                      style={{
                        backgroundColor: AppStore.theme.o.lightGreen,
                        right: '-80px',
                        border: `1px solid ${AppStore.theme.o.green}`,
                      }}
                      onClick={() => {
                        this.enterPressed()
                      }}
                      id={'confirmIcon'}
                      center
                    >
                      <Icons.tagCreate fill={AppStore.theme.o.green} />
                    </BoxOptions>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <img
            style={{ maxWidth: '750', maxHeight: '750', minWidth: '750', minHeight: '750', borderRadius: 16 }}
            width={750}
            height={750}
            src={this.props.post.data.medias?.[this.state.activeImage].url}
          />
        </Box>

        {(post.data.medias?.length ?? 0) > 1 && <BackwardForwardIcons moveCarousel={this.moveCarousel} />}

        <Box id={'infoBox'}>
          <Box
            style={{
              position: 'absolute',
              bottom: -120,
              zIndex: 9999999,
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              color: AppStore.theme.o.darkGrey,

              alignItems: 'center',
            }}
            height={100}
            width={'100%'}
            center
            ph={24}
          >
            <Text style={{ textAlign: 'center' }}>{T.postPage.sfortunatly_we_cant}</Text>
          </Box>
        </Box>
      </Box>
    )
  }
}

const BoxOptions = styled(Box)`
  position: absolute;
  width: 36px;
  height: 36px;
  right: -40px;

  background-color: ${AppStore.theme.o.surface};
`
