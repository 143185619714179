import styled from '@emotion/styled'
import Socialaccounts from 'api/Socialaccounts'
import { useState } from 'react'
import { T } from 'translation/i18n'
import { SocialAccount, SocialMedias } from 'types'
import { AppStore, showError, showSuccess } from 'utils'
import {
  getFBAccounts,
  getGoogleMyBusinessAuthUrl,
  getIGAccounts,
  getLinkedinAuthUrl,
  getTiktokAuthUrl,
  getTwitterAuthUrl,
  getYoutubeAuthUrl,
} from 'utils/socials'
import { Box } from './Box'
import ChangeWorkspaceModal from './ChangeWorkspaceModal'
import { Icons } from './Icons'
import { SocialButtonWithImage } from './SocialButtonWithImage'
import { Text } from './Text'
import { ConfirmModal } from './UI'

interface Props {
  account: SocialAccount
  key: any
  onClick?: (account: SocialAccount) => void
  onDelete?: (account: SocialAccount) => void
  style?: any
  suggestedClick?: any[]
  forceFailed?
  changeWorkspace?: boolean
  handleForceUpdate?: () => void
}

export const SocialAccountRow = ({
  account,
  key,
  onClick,
  onDelete,
  style,
  suggestedClick,
  forceFailed,
  changeWorkspace,
  handleForceUpdate,
}: Props) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const confirm = () =>
    new Promise(async (resolve) => {
      try {
        await onDelete?.(account)
      } catch (error) {
        showError(error)
      }

      resolve(null)
    })

  const openConfirmModal = () => {
    AppStore.openModal({
      id: 'preview-modal',
      body: (
        <ConfirmModal
          text={
            <p>
              {T.settings.popEnvConfirmAccount} <br /> {T.settings.popEnvConfirm2}
              <span style={{ marginRight: 5, marginLeft: 5 }} />
              {T.settings.popEnvConfirm3}
              <br />
              {T.settings.popEnvConfirm4}
            </p>
          }
          cancelText={T.postPage.cancel}
          confirmText={T.settings.confirm}
          onClickCancel={() => {
            AppStore.closeModal('preview-modal')
          }}
          onClickConfirm={async () => {
            await confirm()
            AppStore.closeModal('preview-modal')
          }}
        />
      ),
      style: {
        minHeight: 300,
        minWidth: 700,
        alignContent: 'center',
      },
    })
  }
  const changeWorkspaceModal = () => {
    AppStore.openModal({
      id: 'preview-modal',
      body: <ChangeWorkspaceModal socialAccount={account} handleForceUpdate={handleForceUpdate} />,
      style: {
        minHeight: 765,
        maxWidth: 470,
      },
    })
  }

  const saveAccount = async (account1: SocialAccount) => {
    try {
      if (!account1.workspace) {
        account1.workspace = AppStore.workspace
      }
      await Socialaccounts.save(account1)
    } catch (error: any) {}
  }

  const refreshSocialAccounts = async () => {
    try {
      const res = await Socialaccounts.search<SocialAccount>({})
      AppStore.refreshSocialaccounts()
    } catch (error: any) {
      showError(error?.message ?? T.error.error)
    }
  }

  const addAccounts = async (social: SocialMedias) => {
    let accounts: SocialAccount[] = []
    switch (social) {
      case 'facebook':
        accounts = await getFBAccounts()
        break
      case 'instagram':
        accounts = await getIGAccounts()
        break
      case 'linkedin':
        return getLinkedinAuthUrl()
      case 'google':
        return getGoogleMyBusinessAuthUrl()
      case 'x':
        return getTwitterAuthUrl()
      case 'youtube':
        return getYoutubeAuthUrl()
      case 'tiktok':
        return getTiktokAuthUrl()
    }

    const accountFounded: any = []
    accounts.forEach((e) => {
      !AppStore.socialAccounts.some((a, i) => {
        if (a.account_id === e.account_id && a.provider === e.provider) {
          saveAccount(e)
          showSuccess(
            T.success.accontIsUpdated.one +
              account.provider +
              T.success.accontIsUpdated.two +
              e.name +
              T.success.accontIsUpdated.three
          )
          //----- ----- per levare la freccetina di refresh ----- -----
          if (AppStore.socialNearToExpire.indexOf(a.account_id) !== -1) {
            AppStore.socialNearToExpire = AppStore.socialNearToExpire.filter(function (item) {
              return item !== a.account_id
            })
          }
          //----- ----- ----- ----- ----- ----- ----- ----- ----- -----
          accountFounded.push(i)
        }
      })
    })

    await AppStore.refreshLoggedUser()
    window.location.reload()
    if (accountFounded[0] === undefined) showError(T.error.errorAccount)
  }

  let animate = false

  if (suggestedClick !== undefined && suggestedClick[0]?.includes(account.name)) {
    if (suggestedClick[1][suggestedClick[0].indexOf(account.name)] === account.provider) {
      animate = true
    }
  }

  const failed = AppStore.socialNearToExpire.indexOf(account.account_id) !== -1 || account.expired || forceFailed
  return (
    <Box
      vcenter
      row
      width={'100%'}
      key={key}
      pv={20}
      ph={20}
      style={{ border: `1px solid ${AppStore.theme.o.grey}`, ...style }}
      infiniteAnnimation={failed}
      infiniteAnnimationColor={animate || failed ? AppStore.theme.o.red : undefined}
    >
      {/* SE IL TOKEN è FAILATO ALLORA CI SATA UN TOKEN FAILeD */}
      {/* mettere tokenFailed */}
      <SocialButtonWithImage
        socialAccount={account}
        onclicked={(e) => {
          addAccounts(e)
        }}
        tokenFailed={animate || failed}
      />
      <Box
        style={{
          marginLeft: 10,
          marginRight: 10,
          flex: 1,

          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          width: 140,
          position: 'relative',
        }}
      >
        <b
          style={{
            color: AppStore.theme.o.black,
            display: 'inline-block',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: 140,
            textOverflow: 'ellipsis',
          }}
        >
          {account.name}
        </b>
        {!failed && (
          <Text fontSize={12} weight={400} color={AppStore.theme.o.darkGrey}>
            {T.socialProfileType[account?.profileType ?? 'profile']}
          </Text>
        )}
        {!failed && (
          <>
            <UserEmailText onHoverStart={() => setShowTooltip(true)} onHoverEnd={() => setShowTooltip(false)}>
              {`${T.misc.connectedBy}: ${typeof account.user === 'string' ? '' : account?.user.email}`}
            </UserEmailText>
            {/* --------- User Email tooltip ---------*/}
            {showTooltip && (
              <span
                style={{
                  padding: 5,
                  paddingInline: 20,
                  top: 64,
                  color: 'white',
                  backgroundColor: 'black',
                  borderRadius: 10,
                  position: 'absolute',
                }}
              >
                {typeof account.user === 'string' ? '' : account.user.email}
              </span>
            )}
          </>
        )}
        {(failed || animate) && (
          <b
            style={{
              fontSize: 9,
              fontWeight: 700,
              color: AppStore.theme.o.red,
            }}
          >
            {T.settings.tokenExpired}
          </b>
        )}
      </Box>
      <Box gap={4} row>
        {!!onDelete && (
          <RedContainer>
            <TrashIcon
              fill={AppStore.theme.o.darkGrey}
              onClick={async () => {
                const check = await AppStore.checkPermission('delete_social_account')
                if (check) openConfirmModal()
              }}
            />
          </RedContainer>
        )}
        {changeWorkspace && (
          <GreenContainer p={3.5}>
            <ChangeWorkspaceIcon
              fill={AppStore.theme.o.darkGrey}
              onClick={() => {
                changeWorkspaceModal()
              }}
            />
          </GreenContainer>
        )}
      </Box>
    </Box>
  )
}

const RedContainer = styled(Box)`
  background-color: transparent;
  :hover {
    background-color: ${() => AppStore.theme.o.lightRed};
  }
`
const GreenContainer = styled(Box)`
  background-color: transparent;
  :hover {
    background-color: ${() => AppStore.theme.o.lightGreen};
  }
`

const ChangeWorkspaceIcon = styled(Icons.changeWorkspace)`
  height: 32px;
  width: 32px;
  cursor: pointer;
  //onHover fill color as white
  &:hover {
    fill: ${() => AppStore.theme.o.green};
  }
  animation: fadeIn 0.5s;
`
const TrashIcon = styled(Icons.trash)`
  height: 40px;
  width: 40px;
  cursor: pointer;
  //onHover fill color as white
  &:hover {
    fill: ${() => AppStore.theme.o.red};
  }
  animation: fadeIn 0.5s;
`

const UserEmailText = styled(Box)`
  display: block;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  color: ${() => AppStore.theme.o.darkGrey};
`
