import styled from '@emotion/styled'
import { Box, Button, Icons, Text } from 'components'
import { useState } from 'react'
import { T } from 'translation/i18n'

import { AppStore } from 'utils'
import { refresh } from 'utils/router'
import { SubscriptionOption } from './SubscriptionProvider'
import { OWNER_COPIES_TOTAL, OWNER_COPIES_USED } from 'utils/Constants/LimitUserConstants'

interface Props {
  subscriptionOptions?: SubscriptionOption[]
  hashtagNum?: number
  textNum?: number
  setHash?: any
  setNum?: any
  navigateTo?: () => void
}

const AddOn = ({ subscriptionOptions, hashtagNum, textNum, setHash, setNum, navigateTo }: Props) => {
  const [extraHashtag, setExtraHashtag] = useState<number>(0)
  const [extraTesto, setExtraTesto] = useState<number>(textNum !== undefined ? textNum - 0 : 0)
  const [buttonShown, setButtonShown] = useState<boolean>(textNum !== undefined ? false : true)

  return (
    <AdditionalOptionContainer center>
      <Box>
        {/* ------- ------- GENERAZIONE TESTO ------- ------- */}
        <InnerConteiner center>
          <Text fontSize={16} weight={500} color={`${AppStore.theme.o.black}`}>
            {T.settings.extraTextGenPrice}
          </Text>
          <Box center row vcenter mt={28}>
            <span style={{ color: AppStore.theme.o.darkGrey, fontSize: 18, fontWeight: 400, marginRight: 8 }}>
              {T.settings.generationUsed}
            </span>

            <span style={{ color: AppStore.theme.o.green, fontSize: 27, fontWeight: 700, marginRight: 8 }}>
              {OWNER_COPIES_USED} {T.settings.zeroOn}
              {extraTesto + OWNER_COPIES_TOTAL}
            </span>
            <Box row vcenter>
              <ButtonIcons
                center
                mr={12}
                onClick={async () => {
                  if (extraTesto - 10 >= 0) {
                    setExtraTesto((prev) => prev - 10)
                    if (setNum !== undefined) setNum(extraTesto)
                  }
                }}
              >
                <Icons.minus fill={AppStore.theme.o.black} />
              </ButtonIcons>
              <Text fontSize={21} weight={500}>
                {extraTesto}
              </Text>
              <ButtonIcons
                center
                ml={12}
                onClick={async () => {
                  setExtraTesto((prev) => prev + 10)
                  if (setNum !== undefined) setNum(extraTesto)
                }}
              >
                <Icons.plus fill={AppStore.theme.o.black} />
              </ButtonIcons>
            </Box>
          </Box>
        </InnerConteiner>
      </Box>
      {buttonShown && (
        <Button
          width={344}
          height={48}
          mt={18}
          variant="secondary"
          size="smaller"
          onClick={() => {
            navigateTo && navigateTo()
            refresh()
          }}
        >
          {T.settings.addGeneration}
        </Button>
      )}
    </AdditionalOptionContainer>
  )
}

export default AddOn

const InnerConteiner = styled(Box)`
  border: 1px dashed ${AppStore.theme.o.darkGrey};
  width: 560px;
  height: 124px;
  padding-right: 26px;
  padding-left: 26px;
  padding-top: 40px;
  padding-bottom: 40px;
`

const AdditionalOptionContainer = styled(Box)<{ active?: boolean }>`
  margin-top: 12px;
  :nth-child(1) {
    margin-top: 0;
  }
`

const ButtonIcons = styled(Box)<{ active?: boolean }>`
  padding: 4px;
  border-radius: 30px;
  height: 24px;
  width: 24px;
  border: 1px solid ${() => AppStore.theme.o.black};
`
