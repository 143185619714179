/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import Utils from 'api/Utils'
import EmptyState from '../UI/EmptyState'
import SkeletonCard from '../UI/SkeletonCard'
import PreviewCard from './PreviewCard'
import { AnimatedPlaceholderLogo, Box } from 'components'
import { useEffect, useRef, useState } from 'react'
import { getArticlebyKeyword } from '../commonFunction'
import { MAX_ARTICLE_NUMBER } from 'utils/Constants/Constants'

import { useDiscoverStore } from 'stores/DiscoverStore'
import { LoadArticlesButton } from '../UI/LoadArticlesButton'

export const SearchContent = () => {
  /* ----------------- STATE  */
  const [canLoadMoreArticles, setCanLoadMoreArticles] = useState<boolean>()
  const [isLoadingNewArticles, setIsLoadingNewArticles] = useState<boolean>(false)
  const [showLoadingAnimation, setShowLoadingAnimation] = useState(false)
  /* ----------------- ZUSTAND  */
  const {
    searchedArticle,
    setSearchedArticle,
    searchedArticlePage,
    setSearchedArticlePage,
    languageFilterSearch,
    dateFilterSearch,
    selectedTopic,
  } = useDiscoverStore((state) => ({
    searchedArticle: state.searchedArticle,
    setSearchedArticle: state.setSearchedArticle,
    searchedArticlePage: state.searchedArticlePage,
    setSearchedArticlePage: state.setSearchedArticlePage,
    languageFilterSearch: state.languageFilterSearch,
    dateFilterSearch: state.dateFilterSearch,
    selectedTopic: state.selectedTopic,
  }))

  /* ----------------- REF  */
  const isFirstRender = useRef(true)
  const loaderRef = useRef<HTMLDivElement>(null)
  /* ____________________ VARIABLES ____________________ */

  const articleNumber = MAX_ARTICLE_NUMBER
  const startDate: string | undefined =
    dateFilterSearch[0] !== undefined ? dateFilterSearch[0].format('YYYY-MM-DD') : undefined
  const endDate: string | undefined =
    dateFilterSearch[1] !== undefined ? dateFilterSearch[1].format('YYYY-MM-DD') : undefined
  let updatedArticlesList = searchedArticle
  let updatedPage = searchedArticlePage

  /* ----------------- METHODS  */
  //Controlla se è possibile caricare altri articoli
  const checkIsPossibleLoadingMoreArticles = () => {
    if (updatedArticlesList) {
      updatedArticlesList.length % articleNumber === 0 ? setCanLoadMoreArticles(true) : setCanLoadMoreArticles(false)
    }
  }

  const handleOnClick = () => {
    // Questo fa partire la chiamata con la chiamata successiva
    updatedPage = updatedPage + 1
    setSearchedArticlePage(searchedArticlePage + 1)
  }

  /* ----------------- API CALL  */
  const fetchTrendingArticles = async () => {
    setShowLoadingAnimation(true)
    setIsLoadingNewArticles(true)
    try {
      const newArticles: any = await getArticlebyKeyword(
        selectedTopic!.title,
        languageFilterSearch[0],
        updatedPage,
        articleNumber,
        startDate,
        endDate
      )

      setSearchedArticle(updatedArticlesList ? [...searchedArticle!, ...newArticles!] : newArticles)

      updatedArticlesList = updatedArticlesList ? [...searchedArticle!, ...newArticles!] : newArticles

      checkIsPossibleLoadingMoreArticles()
    } catch (e) {}
    setIsLoadingNewArticles(false)
  }

  /* ----------------- USEEFFECT  */
  //Primo render che fetcha i dati
  useEffect(() => {
    if (searchedArticle === null || searchedArticle.length === 0) {
      fetchTrendingArticles()
    } else {
      setSearchedArticle(null)
      updatedArticlesList = null
      setSearchedArticlePage(1)
      updatedPage = 1
      fetchTrendingArticles()
    }

    checkIsPossibleLoadingMoreArticles()
  }, [])

  // Reset dei dati al cambio del topic selezionato
  useEffect(() => {
    if (isFirstRender.current) {
    } else {
      setSearchedArticle(null)
      updatedArticlesList = null
      setSearchedArticlePage(1)
      updatedPage = 1
      fetchTrendingArticles()
    }
  }, [selectedTopic?.title])

  // UseEffect per fetchare i dati delle nuove pagine
  useEffect(() => {
    if (isFirstRender.current) {
    } else {
      //Se la pagina è la prima, nn richiama i dati
      if (updatedPage !== 1) fetchTrendingArticles()
    }
  }, [updatedPage])

  //Reset dei risultati al cambio del filtri
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else {
      setSearchedArticle(null)
      updatedArticlesList = null
      setSearchedArticlePage(1)
      updatedPage = 1
      fetchTrendingArticles()
    }
  }, [languageFilterSearch[0], dateFilterSearch[0], dateFilterSearch[1]])

  const loadMoreArticles = (entries: IntersectionObserverEntry[]): void => {
    const target = entries[0] // Ottiene il primo elemento osservato dall'IntersectionObserver

    if (target.isIntersecting && canLoadMoreArticles && !isLoadingNewArticles) {
      handleOnClick()
    }
  }

  useEffect(() => {
    // Crea un nuovo IntersectionObserver che chiama 'loadMoreArticles' quando
    // l'elemento riferito da 'loaderRef' interseca la viewport
    const observer = new IntersectionObserver(loadMoreArticles)

    // Se 'loaderRef.current' è definito (ovvero se il componente è montato),
    // inizia ad osservare questo elemento
    if (loaderRef.current) {
      observer.observe(loaderRef.current)
    }

    // Alla dismissione del componente, interrompe l'osservazione
    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current)
      }
    }
  }, [canLoadMoreArticles, isLoadingNewArticles])

  return (
    <TrendArticlesContainer>
      {/* Mentre la chiamata è in corso mostra skeleton*/}
      {searchedArticle === null && <SkeletonCard key={Utils.generateRandomUIDKey()} type="article" num={9} />}

      {/* Risultato se c'è del contenuto*/}
      {searchedArticle &&
        searchedArticle.length > 0 &&
        searchedArticle.map((article, i) => <PreviewCard key={i + article.uri} article={article} index={i} />)}

      {/* caricamento di nuovi articoli scendendo in basso dalla pagina */}
      {showLoadingAnimation && searchedArticle && searchedArticle.length > 0 && (
        <AnimatedPlaceholderLogo containerStyle={{ backgroundColor: 'transparent', padding: '5px' }} />
      )}
      <div ref={loaderRef} />

      {/* Risultato se non c'è del trendingArticlesByFollowedTopic  */}
      {searchedArticle !== null && searchedArticle.length === 0 && <EmptyState type={'noResults'} />}
    </TrendArticlesContainer>
  )
}

const TrendArticlesContainer = styled(Box)`
  margin: 24px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 39.5px;

  &::-webkit-scrollbar {
    display: none;
  }
`
