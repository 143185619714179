import styled from '@emotion/styled'
import { Tracker } from 'api/Tracker'
import Workspaces from 'api/Workspaces'
import { Box, Button } from 'components'
import { Icons } from 'components/Icons'
import { useEffect, useState } from 'react'
import { Workspace } from 'types'
import { AppStore, T, showError, showInfo } from 'utils'
import { TRACKEREVENTS, createMixpanelWorkspaceTeamData } from 'utils/eventsTracker'
import { navigate } from 'utils/router'
import { RenameRoleModal } from './Modal/RenameRoleModal'
import PermissionsSettingComponent from './PermissionsSettingComponent'

interface Props {
  changeSection: (section: string, workspace?: Workspace) => void
  workspace: Workspace
  onWorkspaceChange: (workspace: Workspace) => void
}

interface Rule {
  id: string
  ruleTitle: string
  enabled: boolean
}

export interface Permissions {
  title: string
  sectionEnabled: boolean
  rules: Rule[]
}

const UserPermission = ({ changeSection, workspace, onWorkspaceChange }: Props) => {
  const [roleName, setRoleName] = useState<string | undefined>('New Role')
  const [rules, setRules] = useState<Array<string>>([])
  const [isRoleExist, setIsRoleExist] = useState(false)
  const permissions: Permissions[] = [
    {
      title: T.teamMembers.rulesTitle.publication,
      sectionEnabled: false,
      rules: [
        {
          id: 'view_comment_post',
          ruleTitle: `${T.teamMembers.rules.viewCommentPost} (${T.teamMembers.comingSoon})`,
          enabled: false,
        },
        {
          id: 'edit_post',
          ruleTitle: T.teamMembers.rules.editPost,
          enabled: false,
        },
        {
          id: 'publish_post',
          ruleTitle: T.teamMembers.rules.publishPost,
          enabled: false,
        },
        {
          id: 'ai_copy_generator',
          ruleTitle: T.teamMembers.rules.aiCopyGenerator,
          enabled: false,
        },
        {
          id: 'manage_draft',
          ruleTitle: T.teamMembers.rules.manageDraft,
          enabled: false,
        },
      ],
    },
    {
      title: T.teamMembers.rulesTitle.media,
      sectionEnabled: false,
      rules: [
        {
          id: 'upload_media',
          ruleTitle: T.teamMembers.rules.uploadMedia,
          enabled: false,
        },
        {
          id: 'manage_media',
          ruleTitle: T.teamMembers.rules.manageMedia,
          enabled: false,
        },
        {
          id: 'manage_folders',
          ruleTitle: T.teamMembers.rules.manageFolders,
          enabled: false,
        },
        {
          id: 'media_editor',
          ruleTitle: T.teamMembers.rules.mediaEditor,
          enabled: false,
        },
        {
          id: 'ai_media_search',
          ruleTitle: `${T.teamMembers.rules.aiMediaSearch} (${T.teamMembers.comingSoon})`,
          enabled: false,
        },
      ],
    },
    {
      title: T.teamMembers.rulesTitle.calendar,
      sectionEnabled: false,
      rules: [
        {
          id: 'calendar_show_posts',
          ruleTitle: T.teamMembers.rules.calendarShowPost,
          enabled: false,
        },
        {
          id: 'export_calendar',
          ruleTitle: `${T.teamMembers.rules.exportCalendar} (${T.teamMembers.comingSoon})`,
          enabled: false,
        },
      ],
    },
    {
      title: T.teamMembers.rulesTitle.settings,
      sectionEnabled: false,
      rules: [
        {
          id: 'link_social_account',
          ruleTitle: T.teamMembers.rules.linkSocialAccount,
          enabled: false,
        },
        {
          id: 'delete_social_account',
          ruleTitle: T.teamMembers.rules.deleteSocialAccount,
          enabled: false,
        },
        // {
        //   id: 'create_workspaces',
        //   ruleTitle: T.teamMembers.rules.createWorkspaces,
        //   enabled: false,
        // },
        {
          id: 'edit_workspaces',
          ruleTitle: T.teamMembers.rules.editWorkspaces,
          enabled: false,
        },
        {
          id: 'delete_workspaces',
          ruleTitle: T.teamMembers.rules.deleteWorkspaces,
          enabled: false,
        },
        {
          id: 'manage_billings',
          ruleTitle: T.teamMembers.rules.manageBillings,
          enabled: false,
        },
      ],
    },
  ]
  const [permissionsToRender, setPermissionsToRender] = useState<Permissions[]>([])
  const url = window.location.search

  const queryParameters = new URLSearchParams(url)
  const token = queryParameters.get('id') ?? ''
  const newRoleName = queryParameters.get('newrole') ?? ''
  const changeName = queryParameters.get('changeName') ?? ''

  const openRenameModal = () =>
    AppStore.openModal({
      id: 'rename-role-modal',
      body: (
        <RenameRoleModal
          onConfirm={(name: string) => {
            setRoleName(name ?? 'role name')
          }}
        />
      ),
    })

  const onChangeSection = (section: string) => {
    // if (isRoleExist) {
    //   modifyRole(token)
    // }
    changeSection(section, workspace)
  }

  useEffect(() => {
    if (newRoleName !== '') {
      setRoleName(newRoleName)
    } else {
      let existRoles
      if (token !== '') {
        const element = workspace.roles?.filter((role) => role._id === token)
        if (element !== undefined && element.length > 0 && Object.keys(element[0]).length !== 0) {
          setIsRoleExist(true)
          existRoles = element[0]
          setRoleName(existRoles.name)
          setRules(existRoles.permissions)

          for (const permission of permissions) {
            for (const rule of permission.rules) {
              if (existRoles.permissions.includes(rule.id)) {
                rule.enabled = true
                permission.sectionEnabled = true
              }
            }
          }
        }
        setPermissionsToRender(permissions)
      }
    }

    if (changeName !== '') {
      setTimeout(() => {
        openRenameModal()
      }, 600)
    }
  }, [])

  const createRole = async () => {
    try {
      const name = roleName !== undefined ? roleName : ''
      const roles = rules.length <= 0 ? [''] : rules
      const description = 'role description'
      const data = {
        name,
        permissions: roles,
        description,
      }
      const response = await Workspaces.addRole(data, workspace._id ?? '')

      onWorkspaceChange(response)
      setTimeout(() => {
        navigate('/settings/workspace', { state: { section: 'workspace' } })
        changeSection('management')
      }, 1000)
    } catch (e) {
      console.error(e)
      showError(T.error.roleAlreadyExists)
    }
  }

  const modifyRole = async (roleId: string) => {
    try {
      const name = roleName !== undefined ? roleName : ''
      const rolesAssigned = rules.length <= 0 ? [''] : rules
      const description = 'undefiened'
      const data = {
        name,
        permissions: rolesAssigned,
        description,
        roleId: roleId,
      }
      const response = await Workspaces.modifyRole(data, workspace._id ?? '', roleId)

      setTimeout(() => {
        onWorkspaceChange(response)
        navigate('/settings/workspace', { state: { section: 'workspace' } })
      }, 1000)
    } catch (e) {
      showError(T.error.genericError)
    }
  }

  function compareAndMergeArrays(firstArray, secondArray) {
    const updatedRules = firstArray.filter((rule) => !secondArray.includes(rule))
    const addedRules = secondArray.filter((rule) => !firstArray.includes(rule))
    return [...updatedRules, ...addedRules]
  }

  const onPermissionUpdate = (rulesReceived: string | string[]) => {
    if (Array.isArray(rulesReceived)) {
      const updatedRules = compareAndMergeArrays(rules, rulesReceived)
      setRules(updatedRules)
    } else {
      if (rules.includes(rulesReceived)) {
        setRules(rules.filter((permission) => permission !== rulesReceived))
      } else {
        setRules([...rules, rulesReceived])
      }
    }
  }
  const evenData = createMixpanelWorkspaceTeamData({
    role_name: roleName,
    role_permission: rules,
    workspace_name: workspace.name,
  })
  return (
    <Box scroll animation="fade" flex pv={64} ph={80} style={{ overflow: 'hidden' }}>
      <Box vcenter row flex style={{ justifyContent: 'space-between' }}>
        <Box row vcenter>
          <BackwardIcon
            id={`workspace-row-1`}
            onClick={() => {
              onChangeSection('management')
            }}
            width={20}
            height={20}
            style={{ marginRight: '12px' }}
          />
          <TitleText>{roleName !== '' ? roleName : 'New Role'}</TitleText>
          <EditIcon id={`workspace-row-2`} onClick={() => openRenameModal()} width={30} height={30} />
        </Box>
        <Box>
          <Button
            variant="primary"
            onClick={() => {
              if (permissionsToRender.length === 0) {
                Tracker.trackEvent(TRACKEREVENTS.WORKSPACE_ROLE_CREATION_SUCCESSFUL, evenData)
                return
              }
              //@sal - roba orribile ---------------- non da risistemare/da rifare tutto il componente team members
              changeName ? createRole() : modifyRole(token)
              onChangeSection('management')
              showInfo(T.info.roleUpdated)
              return
            }}
            size="small"
            ml={12}
            shadow="none"
            style={{ border: `1px solid ${AppStore.theme.o.blue}`, borderRadius: 14, minWidth: 128, height: 36 }}
            flex
          >
            {permissionsToRender.length === 0 ? T.teamMembers.addNewRole : T.settings.confirm}
          </Button>
        </Box>
      </Box>
      <Box style={{ flexWrap: 'wrap', display: 'flex', marginTop: '20px' }} width={'100%'} height={'100%'}>
        {permissionsToRender.length === 0
          ? permissions.map((element, index) => (
              <PermissionsSettingComponent
                listOfPermissions={element}
                sectionIndex={index}
                onPermissionUpdate={onPermissionUpdate}
              />
            ))
          : permissionsToRender.map((element, index) => (
              <PermissionsSettingComponent
                listOfPermissions={element}
                sectionIndex={index}
                onPermissionUpdate={onPermissionUpdate}
              />
            ))}
      </Box>
    </Box>
  )
}

export default UserPermission

const TitleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`

const BackwardIcon = styled(Icons.backward)`
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`

const EditIcon = styled(Icons.edit)`
  margin-left: 12px;
  cursor: pointer;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.blueOnLight};
  }
`
