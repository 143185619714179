/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { DEFAULT_POST } from 'utils/Constants/CreatePostConstants'
import { useState } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant, TVariantType } from 'types/PostInterface'
import { AppStore, T, showInfo } from 'utils'
import { getPostByAccountIdType } from 'utils/PostUtils/Post'
import { checkIsAccountInPosts, onClickSocialCard, removePostTypeFromAccount } from 'utils/PostUtils/SocialPost'

/* ------------------- INTERFACES  */
interface Props {}

// @SAL - Ora il componente è generico. Cambiare nome ed utilizzare anche per YT. Verificare e modificare array
const availableVariants = [
  {
    type: 'STANDARD',
    name: 'Post',
    icon: <Icons.metaGrid />,
    activePreview: 'INSTAGRAM_STANDARD',
  },
  {
    type: 'STORY',
    name: 'Stories',
    icon: <Icons.metaStories />,
    activePreview: 'INSTAGRAM_STORY',
  },
]

export const InstagramSwitchPreview = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost, setActivePost, activePreview } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
    setActivePost: s.setActivePost,
    activePreview: s.activePreview,
  }))

  const isVariantPostPresent = checkIsAccountInPosts(activePost?.info?.account?.account_id, 'STANDARD')
  const isVariantStoryPresent = checkIsAccountInPosts(activePost?.info?.account?.account_id, 'STORY')

  const getActiveVariants = () => {
    if (isVariantPostPresent && !isVariantStoryPresent) {
      return ['STANDARD']
    }

    if (!isVariantPostPresent && isVariantStoryPresent) {
      return ['STORY']
    }

    if (isVariantPostPresent && isVariantStoryPresent) {
      return ['STANDARD', 'STORY']
    }
  }

  /* ----------------- STATE  */
  const [activeVariants, setActiveVariants] = useState(getActiveVariants() as TVariantType[])

  /* ----------------- METHODS  */
  /* ---------------- GESTIONE POST IN STORE ------------------ */

  /**
   * Verifica se il post è presente nello store
   * @param type
   * @returns
   */
  const isPostInStore = (type: TVariantType) => checkIsAccountInPosts(activePost.info.account.account_id, type)

  /**
   * Rimuove il post dallo store
   * @param type
   */
  const removePostFromStore = (type: TVariantType) =>
    removePostTypeFromAccount(activePost.info.account.account_id, type)

  /**
   * Aggiunge il post allo store
   * @param type
   */
  const addPostToStore = (type: TVariantType, remove = false) => {
    if (isPostInStore(type)) {
      // Se il post è già presente nello store e remove è true, viene rimosso
      if (remove) {
        removePostFromStore(type)
        return
      }
    } else {
      // Se il post non è presente nello store, viene aggiunto allo store
      onClickSocialCard(
        {
          _id: activePost.info.account.account_id,
          name: activePost.info.account.name,
          picture: activePost.info.account.picture,
          provider: activePost.info.account.provider,
        },
        type
      )
    }
    // Notifico l'aggiornamento dell'interfaccia
    const post = getPostByAccountIdType(activePost.info.account.account_id, type)
    setActivePost(post ?? DEFAULT_POST)
  }

  /* --------------- GESTIONE BOTTONE PREVIEW ----------------- */

  /**
   *  Gestione del cambio visibilità della variant preview
   * @param variant
   */
  const onVisibilityChange = (variant) => addPostToStore(variant.type)

  /**
   * Bottone per la gestione del cambio visibilità della variant preview
   * @param variant
   * @returns
   */
  const VariantPreviewButton = (variant) => {
    return (
      <ContainerButton onClick={() => onVisibilityChange(variant)} active={variant.activePreview === activePreview}>
        {variant.icon} {variant.name}
      </ContainerButton>
    )
  }

  /* --------------- GESTIONE CHECKBOX ----------------- */

  /**
   * Verifica se la variant è attiva
   * @param variant
   * @returns
   */
  const isVariantActive = (variant) => activeVariants.includes(variant.type)

  /**
   * Gestione del click sulla checkbox
   * @param variant
   */
  const onCheckboxClicked = (variant) => {
    const isActive = isVariantActive(variant)
    const availableToDelete = activeVariants.length > 1
    if (isActive && !availableToDelete) {
      showInfo(T.error.atLeastOneVariantMustBePresent)
      return
    }

    setActiveVariants(isActive ? activeVariants.filter((v) => v !== variant.type) : [...activeVariants, variant.type])

    addPostToStore(variant.type, isActive && availableToDelete)
  }

  /**
   * Bottone per la gestione del click sulla checkbox
   * @param variant
   * @returns
   */
  const VariantCheckBoxButton = (variant) => {
    return (
      <CheckBoxButton active={isVariantActive(variant)} onClick={() => onCheckboxClicked(variant)}>
        <Check active={isVariantActive(variant)} />
      </CheckBoxButton>
    )
  }

  return (
    <InstagramSwitchPreviewContainer>
      <ContainerCheckBoxs>{availableVariants.map((variant) => VariantCheckBoxButton(variant))}</ContainerCheckBoxs>
      <InstagramSwitchContent>
        {availableVariants.map((variant) => VariantPreviewButton(variant))}
      </InstagramSwitchContent>
    </InstagramSwitchPreviewContainer>
  )
}

const InstagramSwitchPreviewContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const InstagramSwitchContent = styled(Box)`
  width: 130px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.surface};
  padding: 16px;
  gap: 12px;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
`

const ContainerButton = styled(Box)<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  border-radius: 14px;
  padding: 4px 16px;
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  background: ${({ active }) => (active ? () => AppStore.theme.o.lightGreen : () => AppStore.theme.o.lightGrey)};
  color: ${({ active }) => (active ? () => AppStore.theme.o.green : () => AppStore.theme.o.black)};

  &:hover {
    background: ${() => AppStore.theme.o.lightGreen};
    color: ${() => AppStore.theme.o.green};
  }
`

const ContainerCheckBoxs = styled(Box)`
  gap: 12px;
`

const CheckBoxButton = styled(Box)<{ active: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: ${() => AppStore.theme.smallOuterShadow};
  background: ${({ active }) => (active ? () => AppStore.theme.o.lightGreen : () => AppStore.theme.o.surface)};

  &:hover {
    background: ${() => AppStore.theme.o.lightGreen};
  }
`

const Check = styled(Box)<{ active: boolean }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  cursor: pointer;
  background: ${({ active }) => (active ? () => AppStore.theme.o.green : () => AppStore.theme.o.grey)};

  &:hover {
    background: ${() => AppStore.theme.o.green};
  }
`
