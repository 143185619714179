import styled from '@emotion/styled'
import { Box, Button, Icons, Text } from 'components'
import { TextArea } from 'components/TextArea'
import { UserKiledTheSub } from 'components/UserKiledTheSub'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/all'
import { Component } from 'react'
import { AppStore, getHumanReadableDate, showError, showInfo, showSuccess } from 'utils'

import Utils from 'api/Utils'
import { T } from 'translation/i18n'

interface State {
  selected: number
  hover: number
  suggection: string
  step: number
  conditionOne: boolean
  conditionTwo: boolean
}
interface Props {
  hidden: any
}

export default class CancelModal extends Component<Props, State> {
  state: State = {
    selected: -1,
    hover: -1,
    step: 0,
    suggection: '',
    conditionOne: false,
    conditionTwo: false,
  }

  resons = T.cancelPlan.resons

  conditions = [
    <Text>
      {T.cancelPlan.conditions1}
      <span style={{ fontWeight: '700' }}>{getHumanReadableDate(AppStore.loggedUser.rawPlan?.expirationDate)} </span>
    </Text>,
    <Text>{T.cancelPlan.conditions2}</Text>,
  ]

  componentDidMount(): void {
    try {
      this.props.hidden(false)

      gsap.registerPlugin(ScrollTrigger)
      const sections = document.querySelectorAll('.ant-modal')

      const innerCont = document.querySelectorAll('.innerCont')
      const preview = document.querySelectorAll('.preview-modal')

      setTimeout(() => {
        Array.from(
          // @ts-ignore
          document.getElementsByClassName('ant-modal') as HTMLCollectionOf<HTMLElement> // @ts-ignore
        )[0].style.contentVisibility = 'revert'
        // Array.from(document.getElementsByClassName('ant-modal-content') as HTMLCollectionOf<HTMLElement>)[0].style.height =
        //   '3000px'
      }, 300)

      // setTimeout(() => {
      //   const x = document.getElementById('preview-modal')
      //   if (x !== null) x.style.visibility = 'revert'
      // }, 2000)

      sections.forEach((section, index) => {
        gsap.set(section, { x: +4000 })
        gsap.set(preview, { x: -4000, autoAlpha: 0 })

        gsap.to(section, {
          duration: 2,
          x: 0,
          autoAlpha: 1,
          ease: 'power4.out',
          display: '',
        })

        gsap.to(innerCont, {
          contentVisibility: 'auto',
          duration: 1,
          opacity: 1,
        })
      })
    } catch (err) {
      console.error(err)
    }
  }

  componentWillUnmount(): void {
    Utils.cancelSub({
      name: AppStore.loggedUser.name,
      motivation: this.state.selected,
      step: this.state.step,
      feedBack: this.state.suggection,
    })
    // OnInsertCancelPlan(this.state.selected, this.state.step, this.state.suggection)
  }
  openCanceConfiermationlModal = () =>
    AppStore.openModal({
      id: 'preview-modal',
      body: <></>,
      style: {
        minHeight: 876,
        minWidth: 696,
      },
    })

  cancelSubscrition = (index: number) => {
    const { conditionOne, conditionTwo } = this.state

    if (index === 2) {
      try {
        if (conditionOne && conditionTwo) {
          showSuccess(T.success.planCanceled)

          try {
            const sections = document.querySelectorAll('.ant-modal')
            gsap.to(sections[0], {
              duration: 2,
              x: -4000,
              ease: 'power2.out',
              autoAlpha: 0,
              display: '',
            })

            setTimeout(() => {
              AppStore.closeAllModal()
              AppStore.refreshLoggedUser()
              window.location.reload()
            }, 500)
          } catch (e) {
            console.error(e)
          }
          this.setState({ step: index })
          // Utils.cancelSubscription()
        } else {
          showError(T.error.plese_check_the_conditions)
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      this.setState({ step: index })
    }
  }

  render() {
    return (
      <Box animation="vibrate">
        <Box ph={48} pb={48} id="innerCont" pt={this.state.step !== 0 ? 48 : 0}>
          {this.state.step === 0 && (
            <Box>
              <Box center mb={40}>
                <Box center flex>
                  <Box style={{ maxWidth: 124, maxHeight: 124 }}>
                    <UserKiledTheSub />
                  </Box>
                </Box>
                <Text weight={700} fontSize={21}>
                  {T.cancelPlan.message1}
                </Text>
              </Box>
              <Text style={{ marginBottom: 32 }} weight={400} fontSize={16}>
                {T.cancelPlan.message2}
                <br /> {T.cancelPlan.message3}
              </Text>
              <Text weight={700} fontSize={21}>
                {T.cancelPlan.message4}
              </Text>

              <Box style={{ gap: 13 }}>
                {this.resons.map((reson, index) => (
                  <SelectionBox
                    row
                    vcenter
                    onClick={() => {
                      this.setState({ selected: index })
                    }}
                    onHoverStart={() => {
                      this.setState({ hover: index })
                    }}
                  >
                    <CustomInputCheckbox
                      checked={index === this.state.selected}
                      style={{
                        backgroundColor:
                          index === this.state.hover ? AppStore.theme.o.darkGrey : AppStore.theme.o.lightGrey,
                      }}
                      type="checkbox"
                    />

                    <Text weight={400} fontSize={16}>
                      {reson}
                    </Text>
                  </SelectionBox>
                ))}
              </Box>
              <Text weight={700} fontSize={21} style={{ marginTop: 40 }}>
                {T.cancelPlan.howCanWeImprove}
              </Text>

              <FlexTextArea
                id="text-area-create-post"
                bgcolor={AppStore.theme.o.lightGrey}
                bgcoloractive={AppStore.theme.o.lightGrey}
                borderactivecolor={AppStore.theme.o.grey}
                bordercolor={'transparent'}
                borderwidth={'1px'}
                height={105}
                onChange={(e) => this.setState({ suggection: e })}
                placeholder={T.settings.insertHereYourFeedBack}
              />
            </Box>
          )}
          {this.state.step >= 1 && (
            <Box>
              <Text weight={700} style={{ marginBottom: 8 }}>
                {T.cancelPlan.cancelPlan}
              </Text>
              {this.conditions.map((condition, index) => (
                <SelectionBox
                  row
                  vcenter
                  onClick={() => {
                    if (index === 0) this.setState({ conditionOne: !this.state.conditionOne })
                    else this.setState({ conditionTwo: !this.state.conditionTwo })
                  }}
                >
                  {/* <CustomInputCheckboxSquare
                    checked={index === 0 ? this.state.conditionOne : this.state.conditionTwo}
                    style={{
                      backgroundColor: index === this.state.hover ? AppStore.theme.o.darkGrey : AppStore.theme.o.lightGrey,
                    }}
                    type="checkbox"
                  /> */}
                  <Box style={{ position: 'fixed' }}>
                    {index === 0 ? (
                      <>
                        {this.state.conditionOne ? (
                          <Icons.reverseCheckbox width={50} height={50} style={{ marginLeft: 2 }} />
                        ) : (
                          <Icons.notCheckedBox
                            width={20}
                            height={20}
                            fill={AppStore.theme.o.lightGrey}
                            style={{ marginRight: 14, marginLeft: 17 }}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        <>
                          {this.state.conditionTwo ? (
                            <Icons.reverseCheckbox width={50} height={50} style={{ marginLeft: 2 }} />
                          ) : (
                            <Icons.notCheckedBox
                              width={20}
                              height={20}
                              fill={AppStore.theme.o.lightGrey}
                              style={{ marginRight: 14, marginLeft: 17 }}
                            />
                          )}
                        </>
                      </>
                    )}
                  </Box>

                  <Text style={{ marginLeft: 50 }} weight={400} fontSize={16}>
                    {condition}
                  </Text>
                </SelectionBox>
              ))}
            </Box>
          )}
          {/* <Box>
            <Icons.checkbox width={500} />
          </Box> */}
          <Box flex row mt={40} vcenter>
            <Box flex>
              <Text
                weight={700}
                fontSize={16}
                style={{ color: AppStore.theme.o.black }}
                onClick={AppStore.closeAllModal}
              >
                {T.cancelPlan.changedMyMind}
              </Text>
            </Box>
            <ButtonCancel
              onClick={() => {
                if (this.state.selected === -1) showInfo(T.info.selectReason)
                else this.cancelSubscrition(this.state.step + 1)
              }}
              variant={
                this.state.step === 0
                  ? this.state.selected === -1
                    ? 'neutral'
                    : 'primary-small'
                  : this.state.conditionOne && this.state.conditionTwo
                  ? 'primary-small'
                  : 'neutral'
              }
            >
              {T.cancelPlan.cancelPlan}
            </ButtonCancel>
          </Box>
          {this.state.step === 0 && (
            <Box center mt={32}>
              <Text style={{ color: AppStore.theme.o.darkGrey }}>
                {T.cancelPlan.youPlanWillRemainUntil}{' '}
                <span style={{ fontWeight: 700 }}>
                  {getHumanReadableDate(AppStore.loggedUser.rawPlan?.expirationDate)}
                </span>
              </Text>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
}

const CustomInputCheckbox = styled.input`
  width: 2px;
  height: 2px;

  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 50%;
  margin-right: 8px;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  :hover {
    background-color: #a3a1aa;
  }
  :checked {
    background-color: #2aad76 !important;
  }
`

const FlexTextArea = styled(TextArea)`
  font-size: 12px !important;
  line-height: 160% !important;
`
const SelectionBox = styled(Box)`
  padding: 8px !important;
`

const ButtonCancel = styled(Button)`
  min-height: 48px !important;
  min-width: 128px !important;
  font-size: 14px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
`
