/* ------------------- IMPORTS */
import { TSubsection } from 'types/CreatePostInferfaces'
import { Template } from './Template/Template'
import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { AnimationContainer } from './AiGeneric/AnimationContainer'
import { AiTagCard } from 'components/UI'
import { AppStore, T } from 'utils'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { BRAND_VOICE_SOCIALS } from '../../../utils/Constants/CreatePostConstants'
import { useEffect } from 'react'
import BetaComponent from 'components/BetaComponent'
import { TextSelector } from './BrandVoiceNeutral/TextSelector'
import { useAiGenerationStore } from 'stores/AiGenerationStore'

/* ------------------- TYPES  */
export type TOptions = 'Brand voice' | 'Neutro' | 'Neutral'
export type TMode = 'Learning mode' | 'Tuning mode'

/* ------------------- INTERFACES  */
interface Props {
  subsection: TSubsection
}

export const showBrandVoice = true

const brandVoiceOptions: TOptions[] = [T.textGenerator.brandVoice as TOptions, T.textGenerator.neutral as TOptions]

const brandVoiceStyles: React.CSSProperties = {
  width: 'auto',
  height: '44px',
  position: 'absolute',
  top: '-20px',
  left: '50%',
  transform: 'translateX(-50%)',
}

export const TextPostContent = ({ subsection }: Props) => {
  /* ------------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost,
  }))

  const { selectedOption, setSelectedOption } = useAiGenerationStore((s) => ({
    selectedOption: s.selectedOption,
    setSelectedOption: s.setSelectedOption,
  }))

  /* ------------------- METHODS  */
  const checkIfCanUseBrandVoice = () => {
    if (activePost._id === 'GLOBAL') return false

    if ('info' in activePost) {
      return BRAND_VOICE_SOCIALS.includes(activePost.info.account.provider)
    }
  }

  const handleChangeOption = (option: string) => {
    setSelectedOption(option as TOptions)
  }

  /* ------------------- VARIABLES  */
  const section = subsection === 'astersAiLink' || subsection === 'astersAiText' ? 'astersAi' : 'templates'

  // Varibile che definisce (per ora utilizzata per test) se l'utente può o meno utilizzare il brandvoce
  const BRANDVOICE_ACTIVATED = true // adesso simula che sia attivo nel piano

  //ipotetica variabile che indica il valore di brandvoice o meno (quello del toggle)
  const isBrandVoiceActive = selectedOption !== T.textGenerator.neutral && showBrandVoice

  const canUseBrandVoice = checkIfCanUseBrandVoice()

  /* ------------------- USEEFFECT  */
  // Se il BRANDVOICE_ACTIVATED non è attivo, viene settato il valore di default a "Neutro"
  //TODO:  Appena BRANDVOICE_ACTIVATED viene creato nall'user, valutare di fare questa operazione direttamente su zustand dove viene creata la variabile selectedOption
  useEffect(() => {
    if (!BRANDVOICE_ACTIVATED) {
      setSelectedOption(T.textGenerator.neutral as TOptions)
    }
  }, [])

  /* ------------------- ENUM  */
  const content = {
    astersAi: (
      <AiTextAndLinkContentContainer>
        {subsection !== 'astersAiLink' && (
          <>
            {showBrandVoice && selectedOption === T.textGenerator.brandVoice && (
              <BetaContainer>
                <BetaComponent />
              </BetaContainer>
            )}
            {/* Selettore brand voice / nuetro  */}
            <TextSelector
              options={brandVoiceOptions}
              styles={brandVoiceStyles}
              onOptionChange={handleChangeOption}
              selectedOption={selectedOption}
            >
              <AiTagCard aiTagComplete={false} aiTagDirection="right" right={'-28px'} />
            </TextSelector>
          </>
        )}

        <AnimationContainer
          isBrandVoiceActive={isBrandVoiceActive}
          canUseBrandVoice={canUseBrandVoice}
          BRANDVOICE_ACTIVATED={BRANDVOICE_ACTIVATED}
        />
      </AiTextAndLinkContentContainer>
    ),
    templates: <Template />,
  }

  return content[section]
}

const AiTextAndLinkContentContainer = styled(Box)`
  padding: 40px 24px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    width: calc(100% + 2px);
    border-radius: 16px;
    z-index: -1;

    /* vecchio gradient */
    /* background: linear-gradient(45deg, #3fe8ab, #45d9e6, #ffb700, #3fe8ab); */

    /* nuovo gradient */
    background-image: linear-gradient(90deg, #3fe8ab, #45d9e6);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 16px;
    background-color: ${() => AppStore.theme.o.surface};
    z-index: -1;
  }
`

const BetaContainer = styled(Box)`
  position: absolute;
  top: -10px;
  left: 29%;
  transform: translateX(-50%);
`
