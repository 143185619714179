import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import React, { useEffect, useRef, useState } from 'react'
import { AppStore, T, showWarning } from 'utils'

interface Props {
  selectedScope: { id: string; label: string; icon: any; selected: boolean }[]
  onChangeSelectedScope: (id: string) => void
  onGenerateKey: (inputNameValue: string) => void
}

const AutopilotCreateKey = ({ selectedScope, onChangeSelectedScope, onGenerateKey }: Props) => {
  /* -------------------- REF -------------------- */
  const inputRef = useRef<HTMLInputElement>(null)

  /* -------------------- METHODS -------------------- */
  const [canWarning, setCanWarning] = useState<boolean>(true)

  /* -------------------- METHODS -------------------- */
  const onChangeInputName = (minChar = 1) => {
    if (!inputRef.current) return

    if (inputRef.current.value.trim().length < (minChar ?? 1) || inputRef.current?.value?.length > 50) {
      inputRef.current.value = inputRef.current.value.slice(0, 50)
      if (canWarning) {
        setCanWarning((prev) => !prev)
        showWarning(T.headerAutopilotAndModal.warning.minMaxChar)
        setTimeout(() => setCanWarning((prev) => !prev), 2000)
      }

      inputRef.current.classList.add('error')
      inputRef.current.focus()

      return false
    }

    inputRef.current.classList.remove('error')
    return true
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const nameIsValid = onChangeInputName(3)

    nameIsValid && onGenerateKey(inputRef.current?.value ?? '')
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <>
      {/* ---------------------- TITLE ON CONTAINER  */}
      <Title>{T.headerAutopilotAndModal.titleCreateKey}</Title>

      {/* ---------------------- NAME INPUT  */}
      <InputNameContainer>
        <Label>{T.headerAutopilotAndModal.inputTitle}</Label>

        <InputName
          ref={inputRef}
          onChange={() => onChangeInputName(1)}
          placeholder={T.headerAutopilotAndModal.placeholderName}
          type="text"
        />
      </InputNameContainer>

      {/* ---------------------- SCOPE CONTAINER  */}
      <ScopeContainer>
        <Label>{T.headerAutopilotAndModal.assignScopeTitle}</Label>
        <ScopeChipContainer>
          {/* --------- BUTTON - ANALYTICS - AUTOPILOT */}
          {selectedScope.map((scope) => (
            <Button
              variant={scope.selected ? 'secondary-revert' : 'neutral'}
              onClick={() => onChangeSelectedScope(scope.id)}
              size="smaller"
              style={{ width: 189, height: 40, fontWeight: 500, gap: 4 }}
            >
              {scope.icon} {scope.label}
            </Button>
          ))}
        </ScopeChipContainer>
      </ScopeContainer>

      {/* ---------------------- BUTTON - GENERATE */}
      <BtnActionContainer>
        <Button
          variant={'primary'}
          size="smaller"
          style={{ width: 144, height: 40, fontWeight: 500 }}
          onClick={onSubmit}
        >
          {T.headerAutopilotAndModal.generate}
        </Button>
      </BtnActionContainer>
    </>
  )
}

export default AutopilotCreateKey

const Title = styled(Box)`
  font-size: 21px;
  font-weight: 600;
  line-height: 26.88px;
  color: ${() => AppStore.theme.o.black};
`
const InputNameContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Label = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  line-height: 15.36px;
  color: ${() => AppStore.theme.o.black};
`

const InputName = styled.input`
  height: 44px;
  color: ${() => AppStore.theme.o.black};
  padding: 12px 16px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.background};
  border-radius: 14px;

  &.error {
    border: 1px solid ${() => AppStore.theme.o.red};
  }
  ::placeholder {
    color: ${() => AppStore.theme.o.grey};
  }
`

const ScopeContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const ScopeChipContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 40px;
`

const BtnActionContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: calc(100% + 16px);
`
