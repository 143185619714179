import styled from '@emotion/styled'
import { Form, Radio } from 'antd'
import Utils from 'api/Utils'
import { Box, Button, Icons, Input, Text } from 'components'

import { Component, useState } from 'react'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { T } from 'translation/i18n'
import { AppStore, showError, showSuccess } from 'utils'
import PaymentBox from '../Subscription/PaymentBox'
import { CustomerCard } from 'types/SettingsInterfaces'

const MyData = () => {
  const [type, setType] = useState<string>(
    AppStore.loggedUser.stripe?.billings.vatNumber === undefined ? 'private' : 'business'
  )
  const [disable, setDisable] = useState<boolean>(true)
  const [applica, setApplica] = useState<boolean>(false)
  const [validification, setValidification] = useState<number>(0)
  const [customerCard, setCustomerCard] = useState<CustomerCard>({
    isError: true,
    number: AppStore.loggedUser.stripe?.billings?.cardNumber,
  })
  const [cardName, setCardName] = useState<string | undefined>(AppStore.loggedUser.stripe?.billings.customerCard)
  const [loader, setLoader] = useState<boolean>(false)
  const [place, setPlace] = useState<any>()

  /* ------------------ METHODS */
  const handleCardNumberChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, number: e, isError: false }
    })
  }
  const handleCardExpirationChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, expiration: e, isError: false }
    })
  }
  const handleCardCVCChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, cvc: e, isError: false }
    })
  }

  const onFinish = async (data) => {
    try {
      const province =
        place.address_components.find((c) => c.types.includes('administrative_area_level_2'))?.short_name ?? ''
      const adress =
        (place.address_components.find((c) => c.types.includes('route'))?.long_name ?? '') +
        ' ' +
        (place.address_components.find((c) => c.types.includes('street_number'))?.long_name ?? '')
      const state = place.address_components.find((c) => c.types.includes('country'))?.short_name ?? ''

      const city =
        place.address_components.find((c) => c.types.includes('administrative_area_level_3'))?.long_name ?? ''

      const postalCode = place.address_components.find((c) => c.types.includes('postal_code'))?.long_name ?? ''
      setLoader(true)
      if (!!place) {
        data.province = province
        data.address = adress
        data.state = state
        data.city = city
        data.postalCode = postalCode
      }
      await Utils.updateInfo({
        billings: data,
      })
      showSuccess(T.success.dataSaved)
      AppStore.refreshLoggedUser()
    } catch (error) {
      showError(error)
    }
    setLoader(false)
    setDisable(true)

    return false
  }

  let fullAddress

  if (AppStore.loggedUser.stripe?.billings?.address)
    fullAddress =
      AppStore.loggedUser.stripe?.billings?.address +
      ', ' +
      AppStore.loggedUser.stripe?.billings?.city +
      ', ' +
      AppStore.loggedUser.stripe?.billings?.postalCode

  return (
    <Box ph={130}>
      <Form
        // onFinishFailed={() => showError('Tutti i campi sono obbligatori')}
        onFinish={onFinish}
        initialValues={{ ...AppStore.loggedUser?.stripe?.billings }}
      >
        {/* ----------_----------_----------_-PERSONAL INFO------_----------_----------_----------_----------_ */}

        <Box
          animate
          mt={16}
          pv={24}
          ph={32}
          style={{ border: `1px dashed ${AppStore.theme.o.darkGrey} `, position: 'relative' }}
        >
          <Box vcenter row>
            <Box flex />
            <Box style={{ position: 'absolute' }}>
              <Text>{T.settings.faturationDetail}</Text>
            </Box>

            {!!disable && (
              <Box ph={10} row onClick={() => setDisable(false)}>
                <Icons.edit fill={!disable ? AppStore.theme.o.green : AppStore.theme.o.darkGrey} />
                <Text
                  fontSize={16}
                  weight={500}
                  style={{
                    marginTop: 4,
                    color: !disable ? AppStore.theme.o.green : AppStore.theme.o.darkGrey,
                  }}
                >
                  {T.settings.modifyYourData}
                </Text>
              </Box>
            )}
            {!disable && (
              <Button loading={loader} width={120} size="smaller" height={32} variant="secondary" type="submit">
                {T.settings.applica}
              </Button>
            )}
          </Box>

          <Separator />
          <Box>
            <Box row>
              <Box flex></Box>
              <Form.Item name="type">
                <Radio.Group defaultValue={type} onChange={(e) => setType(e.target.value)}>
                  <Radio value="private">
                    <Text>{T.settings.private}</Text>
                  </Radio>
                  <Radio value="business">
                    <Text>{T.settings.iva}</Text>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Box>
          </Box>
          <Separator />
          <Box
            style={{ backgroundColor: 'transparent' }}
            onClick={() => setApplica(true)}
            // onMouseLeave={() => this.setState({ applica: false })}
          >
            <Box>
              <Form.Item rules={[{ required: true }]} name="name">
                <StyledInput disabled={disable} label={T.misc.name} placeholder={T.misc.name} />
              </Form.Item>
              <Form.Item rules={[{ required: true }]} name="surname">
                <StyledInput disabled={disable} label={T.settings.surname} placeholder={T.settings.surname} />
              </Form.Item>
              {type === 'business' && (
                <Box animation="fade">
                  <Form.Item rules={[{ required: true }]} name="businessName">
                    <StyledInput
                      disabled={disable}
                      label={T.settings.ragioneSociale}
                      placeholder={T.settings.ragioneSociale}
                    />
                  </Form.Item>
                </Box>
              )}
              {/* <Box row>
              <Box flex>
                <Form.Item rules={[{ required: true }]} name="address">
                  <StyledInput disabled={disable} label="Indirizzo" placeholder="Indirizzo" />
                </Form.Item>
              </Box>
              <Spacer />
              <Form.Item rules={[{ required: true }]} name="postalCode">
                <StyledInput disabled={disable} label="Cap" placeholder="Cap" />
              </Form.Item>
            </Box>
            <Box row>
              <Box flex>
                <Form.Item rules={[{ required: true }]} name="city">
                  <StyledInput disabled={disable} label="Città" placeholder="Città" />
                </Form.Item>
              </Box>A
              <Spacer />
              <Form.Item rules={[{ required: true }]} name="province">
                <StyledInput disabled={disable} label="Provincia" placeholder="Provincia" />
              </Form.Item>
            </Box> */}
              <Box mb={16}>
                <Text weight={700} style={{ marginBottom: 4 }}>
                  {T.settings.completeAddress}
                </Text>
                <ReactGoogleAutocomplete
                  apiKey={process.env.REACT_APP_API}
                  placeholder={T.settings.completeAddress}
                  options={{
                    types: 'addresses',
                  }}
                  defaultValue={fullAddress}
                  onFocus={(e) => {
                    if (disable) e.target.blur()
                  }}
                  style={{
                    borderRadius: 10,
                    backgroundColor: AppStore.theme.o.lightGrey,
                    borderColor: 'transparent',
                    color: AppStore.theme.o.black,
                    paddingLeft: 16,
                    paddingTop: 10,
                    paddingBottom: 10,
                    fontWeight: 500,
                  }}
                  onPlaceSelected={(selectedPlace: any) => {
                    setPlace(selectedPlace)
                  }}
                />
              </Box>

              {type === 'private' && (
                <Box animation="fade">
                  <Form.Item rules={[{ required: true }]} name="fiscalCode">
                    <StyledInput disabled={disable} label={T.settings.fiscalCode} placeholder={T.settings.fiscalCode} />
                  </Form.Item>
                </Box>
              )}
              {type === 'business' && (
                <Box animation="fade">
                  <Form.Item rules={[{ required: true }]} name="vatNumber">
                    <StyledInput disabled={disable} label={T.settings.partitaIva} placeholder={T.settings.partitaIva} />
                  </Form.Item>
                  <Form.Item rules={[{ required: true }]} name="sdipec">
                    <StyledInput
                      disabled={disable}
                      label={T.settings.CodiceSdiOrPEC}
                      placeholder={T.settings.CodiceSdiOrPEC}
                    />
                  </Form.Item>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Form>
      <Form initialValues={AppStore.loggedUser?.stripe?.billings}>
        <PaymentBox
          customerCard={customerCard}
          cvcChange={handleCardCVCChange}
          numberChange={handleCardNumberChange}
          expirationChange={handleCardExpirationChange}
          name={cardName}
          nameChange={(e) => setCardName(e)}
        />
      </Form>
    </Box>
  )
}

export default MyData

const StyledInput = styled(Input)`
  margin-bottom: 12px;
  padding-left: 16px !important;
  font-size: 14px !important;
`

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`

const Applica = styled(Button)`
  width: 120px !important;
  height: 8px !important;

  margin-right: 12px !important;

  :hover {
    box-shadow: none;
  }
`
