import styled from '@emotion/styled'
import { SocialMedias } from 'types'
import { AppStore } from 'utils'
import { Box } from './Box'
import { SocialIcon, SocialIconsProvider } from './SocialIcon'
import { ISocialFilters } from 'stores/interfaces/CalendarPageInterface'

export const SocialToggleButton = ({
  socials,
  setSocial,
  style,
  id = 'social-toggle-buttons',
}: {
  socials: ISocialFilters[]
  style?: React.CSSProperties
  setSocial: (social: ISocialFilters[]) => void
  id?: string
}) => {
  return (
    <Container id={id} style={style}>
      <Box center flex row ph={22}>
        {socials.map((s, index) => (
          <IconContainer
            onClick={() => {
              const socialCopy = [...socials]
              if (
                socialCopy.filter((e) => e.active).length === socialCopy.length ||
                (socialCopy.filter((e) => e.active).length === 1 &&
                  socialCopy.filter((e) => e.active)[0] === socialCopy[index])
              ) {
                socialCopy.forEach((e) => {
                  e.active = !e.active
                })
                socialCopy[index].active = !socialCopy[index].active
                setSocial([...socialCopy])
              } else {
                socialCopy[index].active = !socialCopy[index].active
                setSocial([...socialCopy])
              }
            }}
            center
            mr={22}
            ml={22}
          >
            <SocialIcon active={s.active} social={s.social as SocialIconsProvider} style={{ width: 40, height: 40 }} />
          </IconContainer>
        ))}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  width: fit-content;
  background-color: ${() => AppStore.theme.o.lightGrey};
  border-radius: 14px;
`

const IconContainer = styled(Box)`
  cursor: pointer;
  border-radius: 14px;
  background-color: 'transparent';
  padding: 1px;
`
