import styled from '@emotion/styled'

import { Box, Button, Spacer, Text } from 'components'
import { useState } from 'react'
import { T } from 'translation/i18n'
import { CouponData } from 'types'
import { AppStore, getHumanReadableDate } from 'utils'
import { ServiceRow } from './ServiceRow'
import { SubscriptionOption } from './SubscriptionProvider'
import { SubscriptionPlanMode } from 'types/SettingsInterfaces'
import TotalShow from './TotalShow'

interface Props {
  additionalOptions: any
  activeSubscriptionOption: SubscriptionOption
  loading: any
  disabled: any
  privacyRead: any
  subscriptionMode: SubscriptionPlanMode
  price: any
  addonPrice: any
  normalPrice: any
  coupon?: CouponData
}

const DetailBox = ({
  additionalOptions,
  activeSubscriptionOption,
  loading,
  disabled,
  privacyRead,
  subscriptionMode,
  price,
  addonPrice,
  normalPrice,
  coupon,
}: Props) => {
  const [privacyReaded, setPrivacyReaded] = useState<boolean>(false)

  /* ---------------------- VARIABLES */

  //----------- PREZZO NORMALE
  let total = price + addonPrice
  if (subscriptionMode === 'annual') total = total * 12 //PREZZO NORMALE SE ANNUALE

  //----------- PREZZO NORMALE NEL CASO OFFERTA

  if (activeSubscriptionOption.offer !== undefined && subscriptionMode === 'annual') {
    const { offer } = activeSubscriptionOption
    total = (offer.cost + addonPrice * 12) / 12
  }

  //----------- PREZZO DEL VECHIO SUBSCRIPTION
  const oldSub = !AppStore.mySubscription?.renew_price
    ? 0
    : ((AppStore.mySubscription.renew_price * 1.22).toFixed(2) as any)

  //----------- PREZZO CON IL PROLATE
  const ivaPrice = (total * (subscriptionMode === 'annual' ? 12 : 1) * 1.22) as any //PREZZO CON IVA
  const priceWithProrate = (ivaPrice - (oldSub ?? 0)).toFixed(2) as any

  const totalAddonPrice = subscriptionMode === 'monthly' ? addonPrice : addonPrice * 12

  let totalPrice = Math.floor(normalPrice - totalAddonPrice)

  const starterPrice = Math.floor(normalPrice - totalAddonPrice)

  let offer = 0
  if (coupon) {
    if (coupon.percent_off) {
      offer = (totalPrice * coupon.percent_off) / 100
      totalPrice = totalPrice - offer
    } else if (coupon.amount_off) {
      offer = coupon.amount_off / 100
      totalPrice = totalPrice - offer
    }
  }

  const iva = (totalPrice * 1.22 - totalPrice + totalAddonPrice * 1.22 - totalAddonPrice).toFixed(2)

  return (
    <Box>
      <Text style={{ marginTop: 16 }}>{T.settings.summary}</Text>
      <Separator />
      <Text style={{ marginBottom: 8 }} color={AppStore.theme.o.yellow}>
        {activeSubscriptionOption.label}
      </Text>
      {activeSubscriptionOption.services
        .filter((s) => (s.quantity ?? 0) > 0)
        .map((s) => (
          <ServiceRow service={s} />
        ))}
      <Spacer />
      <Text style={{ marginBottom: 8 }} color={AppStore.theme.o.yellow}>
        {T.settings.addOn}
      </Text>
      {additionalOptions.map((s) => (
        <ServiceRow service={s} />
      ))}
      <Separator />
      <Text color={AppStore.theme.o.darkGrey} fontSize={14} style={{ marginTop: 8 }}>
        {!!AppStore.loggedUser.rawPlan?.expirationDate && (
          <>
            {T.settings.yourPlamWillBeActivated1}
            {getHumanReadableDate(AppStore.loggedUser.rawPlan.expirationDate)} {T.settings.eSara}
            {T.settings.yourPlamWillBeActivated2}
          </>
        )}
        {!AppStore.loggedUser.rawPlan?.expirationDate && <>{T.settings.yourPlamWillBeActivated3}</>}
      </Text>
      {/* .----- .-----.-----.-----.-----.-----.-----.-----.-----.-----.-----.-----.-----k */}
      <Separator />
      <TotalShow
        subscriptionMode={subscriptionMode}
        price={starterPrice}
        label={T.settings.planCost}
        subTitle
        noSubLine
      />
      <Box mv={8}>
        <TotalShow
          subscriptionMode={subscriptionMode}
          price={totalAddonPrice}
          label={T.settings.costAddOn}
          subTitle
          noSubLine
        />
      </Box>

      <Box mb={8}>
        <TotalShow
          subscriptionMode={subscriptionMode}
          label={T.settings.coupon}
          noSubLine
          subTitle
          price={offer * -1}
        />
      </Box>
      <Box mb={8}>
        <TotalShow subscriptionMode={subscriptionMode} label={T.settings.ivaPayment} noSubLine subTitle price={iva} />
      </Box>
      {oldSub !== 0 && (
        <TotalShow subscriptionMode={subscriptionMode} label={T.settings.difrance} noSubLine subTitle price={-oldSub} />
      )}
      <TotalShow
        subscriptionMode={subscriptionMode}
        label={T.settings.total}
        price={Number(totalPrice) + Number(iva) + Number(totalAddonPrice)}
      />
      {/* .----- .-----.-----.-----.-----.-----.-----.-----.-----.-----.-----.-----.-----k */}
      <Box
        row
        vcenter
        style={{ right: 10 }}
        mt={26}
        onClick={() => {
          setPrivacyReaded((prev) => {
            privacyRead(!prev)
            return !prev
          })
        }}
      >
        <Box>
          <CustomInputCheckbox checked={privacyReaded} style={{ marginLeft: 12, marginBottom: 2 }} type="checkbox" />
        </Box>

        <Box ml={4} p={8}>
          <Text color={AppStore.theme.o.black} fontSize={12}>
            {T.settings.confirmPrivacy1}
          </Text>
          <Box width={120} height={20} onClick={() => window.open('https://asters.ai/termini-di-utilizzo')}>
            <Text color={AppStore.theme.o.green} fontSize={14} weight={700}>
              {T.settings.confirmPrivacy2}
            </Text>
          </Box>
        </Box>
      </Box>
      {/* .----- .-----.-----.-----.-----.-----.-----.-----.-----.-----.-----.-----.-----k */}
      <Sottoscrivi
        loading={loading}
        disabled={disabled}
        disable={disabled}
        variant={disabled ? 'neutral' : 'primary-small'}
        type="submit"
      >
        {AppStore.loggedUser.rawPlan !== undefined ? T.postPage.update : T.settings.subscribe}
      </Sottoscrivi>
    </Box>
  )
}

export default DetailBox

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`

interface SubmitBle {
  disable: boolean
}

const Sottoscrivi = styled(Button)<SubmitBle>`
  min-height: 48px !important;
  min-width: 128px !important;
  font-size: 16px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  margin-top: 24px;
  ${({ disable }) =>
    disable === true &&
    `background-color: ${AppStore.theme.o.lightGrey}; color: ${AppStore.theme.o.darkGrey}; box-shadow: none!important;onhover-events: none;`}
`

const CustomInputCheckbox = styled.input`
  width: 2px;
  height: 2px;
  margin-top: 2px;
  background-color: ${() => AppStore.theme.o.grey};
  border-radius: 50%;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  :checked {
    background-color: #2aad76;
  }
`
