/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Text } from 'components'
import { AppStore } from 'utils'

/* ------------------- TYPES  */
type TPossibleColors = 'yellow' | 'blue' | 'purple' | 'black' | 'white' | 'lightPurple'

/* ------------------- INTERFACES  */
interface Props {
  position: 'top-left' | 'top-right' | 'center'
  text: string
  primaryColor?: TPossibleColors
  bgColor?: TPossibleColors
  border?: boolean
  top?: number
}

type IPossibleColors = {
  [key in TPossibleColors]: any
}

export const Chip = ({ text, position, primaryColor = 'white', bgColor = 'yellow', border }: Props) => {
  /* ----------------- VARIABLES  */
  const enumPossibleColors: IPossibleColors = {
    black: AppStore.theme.o.black,
    white: AppStore.theme.o.white,
    yellow: AppStore.theme.o.yellow,
    blue: AppStore.theme.o.blue,
    purple: AppStore.theme.o.betaComponentText,
    lightPurple: AppStore.theme.o.betaComponentBackground,
  }

  return (
    <ChipContainer
      className={position}
      center
      style={{
        backgroundColor: enumPossibleColors[bgColor],
        color: enumPossibleColors[primaryColor],
        border: border ? `1px solid ${enumPossibleColors[primaryColor]}` : 'none',
      }}
    >
      <Text fontSize={12} weight={900} color={enumPossibleColors[primaryColor]}>
        {text}
      </Text>
    </ChipContainer>
  )
}

const ChipContainer = styled(Box)`
  position: absolute;
  top: -8px;

  &.top-left {
    left: -12px;
  }
  &.top-right {
    right: -12px;
  }
  &.center {
    right: auto;
    left: auto;
  }

  border-radius: 14px;
  padding: 8px;
  width: fit-content;
  height: 20px;
`
