import Utils, { SocialType } from 'api/Utils'
import { Box } from 'components'
import AddSocialButton from 'components/CreatePost/SocialComponents/AddSocialButton'
import { SocialGrid } from 'components/CreatePost/SocialComponents/SocialGrid'
import { Divider } from 'components/UI'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { SocialAccount } from 'types'
import { IPostVariant, TVariantType } from 'types/PostInterface'
import { T, showInfo } from 'utils'
import { getPreviewByVariant } from './Post'

// Render social grid for a specific provider
export const renderSocialGridForProvider = (provider: SocialType, accounts: SocialAccount[], isLastItem: boolean) => {
  return (
    <Box key={provider}>
      {accounts.length > 0 && <SocialGrid provider={provider} accounts={accounts} />}

      {accounts.length <= 0 && (
        <>
          <SocialGrid provider={provider} accounts={[]} />
          <AddSocialButton />
        </>
      )}

      {!isLastItem && <Divider mt={40} mb={24} />}
    </Box>
  )
}

// Filter social accounts by search term
export const filterSocialAccountsBySearchTerm = (accounts: SocialAccount[], searchTerm: string) => {
  return accounts.filter((account) => account.name.toLowerCase().includes(searchTerm.toLowerCase()))
}

// Filter social accounts by provider
export const filterSocialAccountsByProvider = (allAddedAccounts: SocialAccount[], provider: SocialType) => {
  return allAddedAccounts.filter((account) => account.provider === provider)
}

// Check if an account is selected in the posts
export const checkIsAccountInPosts = (account_id: string, type?: TVariantType) => {
  const { posts } = useCreatePostStore.getState()

  const isPresent = posts.some((post) => {
    return post?.info?.account?.account_id === account_id
  })

  if (isPresent && type) {
    return posts.some((post) => post?.info?.account?.account_id === account_id && post?.info?.type === type)
  }

  return isPresent
}

export const removePostTypeFromAccount = (account_id: string, type?: TVariantType) => {
  const { posts } = useCreatePostStore.getState()

  const newPosts = posts.filter((post) => {
    if (post?.info?.account?.account_id === account_id) {
      if (type) {
        return post?.info?.type !== type
      }
      return false
    }
    return true
  })

  useCreatePostStore.getState().setPosts(newPosts)
}

// Handle click on social card
// ACCOUNT = SocialAccount | IVariantSocial messo any per problemi di tipizzazione da ricontrollare
// è SocialAccount quando l'account è preso dalla sezione social
// è // è IVariantSocial quando l'account è preso dalla sezione preview (post in simultanea)
export const onClickSocialCard = (
  account: any,
  type?: TVariantType,
  reusableVariantId?: string, // Presenti quando si riutilizza un post
  postId?: string // Presenti quando si riutilizza un post
): string => {
  const { globalPost, posts, setPosts, activePost, setActivePost, setActivePreview, setActiveAccountId } =
    useCreatePostStore.getState()

  const variantId = Utils.generateRandomUIDKey()
  const expired = 'expired'
  // Check if the account token is expired
  if (account[expired]) {
    showInfo(T.error.checkSocialConnection)
    return expired
  }

  // Controlla la variantID è già presente nei post
  const isAlreadyAdded = posts.some((post) => post.variantId === reusableVariantId)
  if (isAlreadyAdded) {
    showInfo(T.error.alreadyWorkingOnThisPost)
    return 'alreadyAdded'
  }

  // Controlla se l'_id è già presente tra gli _id social nei post
  const isSocialIdPresent = checkIsAccountInPosts(account._id ?? account.account_id, type)

  //TODO: Da vedere dove spostare per migliorare la gestibilità, questi sono i valori di default appena aggiunti un social
  const { provider } = account
  let defaultValues = {}

  // Gestione predefinita nel caso in cui il provider non sia 'tiktok' o 'youtube'
  switch (provider) {
    case 'tiktok':
      defaultValues = {
        privacyLevel: 'FOLLOWER_OF_CREATOR',
        duet: true,
        stitch: true,
        commentAvaible: true,
      }
      break
    case 'youtube':
      defaultValues = {
        privacyStatus: 'PUBLIC',
        embedded: false,
        notifySubscriber: false,
        adultOnly: false,
      }
      break
    // Aggiungere altri casi se necessario
    default:
      break
  }

  let newPosts: IPostVariant[] = []

  const defaultInfo = {
    account: {
      account_id: account._id ?? account.account_id,
      socialId: account.account_id ?? account._id,
      name: account.name,
      picture: account.picture,
      provider: account.provider,
      tuned: account.tuned,
      profileType: account.profileType,
    },
    type: type ?? 'STANDARD', // All'aggiunta è inizializzato come "STANDARD"
  }

  const defaultData = { ...globalPost, _id: undefined, ...defaultValues }

  if (!isSocialIdPresent) {
    // aggiungo il post
    newPosts = [
      ...posts,
      {
        variantId: reusableVariantId ? reusableVariantId : variantId,
        data: defaultData, // All'aggiunta è inizializzato come globalPost + valori di default se necessario
        info: defaultInfo,
        _id: postId ?? undefined,
      },
    ]
  } else {
    //
    const postToEdit = posts.find(
      (post) => post.info.account.account_id === account._id && (!type || (type && post.info.type === type))
    )
    newPosts = posts.filter((post) => post.info.account.account_id !== account._id)

    if (!postToEdit) {
      const newSocialAccount = {
        variantId: reusableVariantId ? reusableVariantId : variantId,
        data: defaultData, // All'aggiunta è inizializzato come globalPost + valori di default se necessario
        info: defaultInfo,
        _id: postId ?? undefined,
      }
      newPosts = [...newPosts, newSocialAccount]
    } else {
      newPosts = [...newPosts]
      setActivePreview(getPreviewByVariant(globalPost))
    }

    if ('variantId' in activePost && activePost?.info === postToEdit?.info) {
      setActivePost(globalPost)
      setActivePreview(getPreviewByVariant(globalPost))
      setActiveAccountId('GLOBAL')
    }
  }

  // Se l'_id è presente, rimuovi l'elemento, altrimenti aggiungilo

  setPosts(newPosts)

  return variantId
}

// ----------------- WIP SAL ----------------- //

export const onClickSocialCardNEW = (account: any, type?: TVariantType): string => {
  const { globalPost, posts, setPosts, activePost, setActivePost, setActivePreview, setActiveAccountId } =
    useCreatePostStore.getState()

  const variantId = Utils.generateRandomUIDKey()
  if (account.expired) {
    showInfo('Check social connection')
    return 'expired'
  }

  const isSocialIdPresent = checkIsAccountInPosts(account._id ?? account.account_id, type)
  const defaultValues = getDefaultValues(account.provider)

  const newPost = {
    variantId: variantId,
    data: { ...globalPost, _id: undefined, ...defaultValues },
    info: createDefaultInfo(account, type),
  }

  const newPosts = isSocialIdPresent
    ? updateExistingPost(posts, newPost, account._id ?? account.account_id, type)
    : [...posts, newPost]

  setPosts(newPosts)

  return variantId
}

export const getDefaultValues = (provider) => {
  switch (provider) {
    case 'tiktok':
      return {
        privacyLevel: 'FOLLOWER_OF_CREATOR',
        duet: true,
        stitch: true,
        commentAvaible: true,
      }
    case 'youtube':
      return {
        privacyStatus: 'PUBLIC',
        embedded: false,
        notifySubscriber: false,
        adultOnly: true,
      }
    default:
      return {}
  }
}

export const createDefaultInfo = (account, type) => {
  return {
    account: {
      account_id: account._id ?? account.account_id,
      name: account.name,
      picture: account.picture,
      provider: account.provider,
    },
    type: type ?? 'STANDARD',
  }
}

export const updateExistingPost = (posts, newPost, accountId, type) => {
  const postIndex = posts.findIndex(
    (post) => post.info.account.account_id === accountId && (!type || post.info.type === type)
  )

  if (postIndex !== -1) {
    // Aggiorna il post esistente
    const updatedPosts = [...posts]
    updatedPosts[postIndex] = newPost
    return updatedPosts
  } else {
    // Restituisce l'array originale dei post con l'aggiunta del nuovo post
    return [...posts, newPost]
  }
}
