/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { CardCheckBox } from './CardCheckBox'

/* ------------------- INTERFACES  */

interface Props {
  content: JSX.Element // Il contenuto delle card
  isSelectable?: boolean // Booleano che gestisce se l'elemento è selezionabile con i checkbox
  isSelected?: boolean // Booleano per gestire se l'elemento è selezionato
  action?: () => void // Azione al onClick del checkbox
}

export const CardContainer = ({ content, isSelectable = false, isSelected, action }: Props) => {
  return (
    <CardContainerContainer className="card_container">
      {isSelectable && <CardCheckBox isSelected={isSelected} action={action} />}

      {content}
    </CardContainerContainer>
  )
}

const CardContainerContainer = styled(Box)`
  &.card_container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 0px;
    position: relative;
    overflow: hidden;

    :hover {
      .card_checkbox {
        top: 20px;
      }
    }

    .card_checkbox {
      position: absolute;
      top: -50px;
      right: 24px;
      z-index: 1;

      &.selected {
        top: 20px;
      }

      &.not_setected {
        top: -50px;
      }
    }
  }
`
