import styled from '@emotion/styled'
import Workspaces from 'api/Workspaces'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { AppStore, T } from 'utils'
import { requestConfirmDialog } from 'utils/modalManager'
import { Box } from './Box'
import { Icons } from './Icons'

interface Props {
  handleForceUpdate?: () => void
  admin?: boolean
  user: {
    name: string
    email: string
    role: string
    invitationConfirmed: boolean
    id: string
  }
  index: number
  findRole: (role: string) => any
  NumberComponent: (props: { id: string; roleName: string }) => JSX.Element
  AddRoleComponent: (user: any, index: number) => JSX.Element
}

const truncateRole = (role: string) => {
  const roleName = role ?? ''
  return roleName.length > 20 ? roleName.substring(0, 20) + '...' : role
}

export default function UserRow(props: Props) {
  const { user, index, findRole, NumberComponent, AddRoleComponent, handleForceUpdate } = props
  const [showTooltip, setShowTooltip] = useState(false)

  const { workspaceId } = useParams()

  const openConfirmModal = () => {
    const message = (
      <p>
        {T.settings.remove_user_from_workspace}
        <br />
        {T.settings.remove_user_from_workspace_confirm}
        <br />
        {T.settings.popEnvConfirm4}
      </p>
    )

    const deleteCollaborator = async () => {
      await Workspaces.deleteCollaborator(user.id, workspaceId!)

      handleForceUpdate && handleForceUpdate()
    }
    requestConfirmDialog(message, deleteCollaborator)
  }

  const email = truncateRole(user.email)
  const userName = truncateRole(user.name)

  return (
    <>
      <Box
        id={`user-${user.email}-${index}`}
        row
        ml={25}
        gap={25}
        height={56}
        relative
        vcenter
        style={{ opacity: user.invitationConfirmed ? 1 : 0.3 }}
      >
        <Box row vcenter onHoverStart={() => setShowTooltip(true)} onHoverEnd={() => setShowTooltip(false)}>
          {/* --------- User image ---------*/}
          <Box width={28} height={28} style={{ borderRadius: 50, backgroundColor: AppStore.theme.o.red }} mr={8}></Box>

          {/* --------- User email description ---------*/}
          <Box id={'user-name'} width={150} mt={-4}>
            {user.invitationConfirmed ? <HeaderText>{userName}</HeaderText> : <IconEmail />}
            <EmailText>{email}</EmailText>
          </Box>

          {/* --------- User Email tooltip ---------*/}
          {showTooltip && (
            <span
              style={{
                padding: 5,
                paddingInline: 20,
                left: 30,
                color: 'white',
                backgroundColor: '#000000e9',
                borderRadius: 10,
                position: 'absolute',
              }}
            >
              {user.email}
            </span>
          )}
        </Box>

        {/* --------- Role limits ---------*/}
        {findRole(user?.role)?.rolesNumber.map((session, i) => {
          return NumberComponent({ id: session.name, roleName: user?.role })
        })}

        {/* --------- Change role user ---------*/}
        <Box vcenter ml={16}>
          {AddRoleComponent(user, index)}
        </Box>

        {/* --------- Delete user ---------*/}
        {props.user.id.toLocaleLowerCase() != 'admin' && (
          <Box style={{ position: 'absolute', right: 0 }}>
            <SquareTrash onClick={openConfirmModal} />
          </Box>
        )}
      </Box>
      <DivisionLine />
    </>
  )
}

const SquareTrash = styled(Icons.squareTrash)`
  width: 24px;
  height: 24px;
  fill: ${() => AppStore.theme.o.red};
  cursor: pointer;
`

const IconEmail = styled(Icons.emailToConfirm)`
  fill: ${() => AppStore.theme.o.black};
  height: 15px;
  width: 20px;
  margin-left: -8px;
  margin-top: -4px;
`

const OptionsIcon = styled(Icons.optionsIcon)`
  height: 20px;
  width: 26px;
`

const SortIcon = styled(Icons.sortIcon)`
  /* fill: ${() => AppStore.theme.o.darkGrey}; */
  width: 12px;
  height: 12px;
  margin-left: 8px;
`

const DivisionLine = styled.div`
  background-color: ${() => AppStore.theme.o.grey};
  height: 1px;
  width: 100%;
`

const HeaderText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${() => AppStore.theme.o.black};
`

const EmailText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: ${() => AppStore.theme.o.black};
`

const RoleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`
