/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { analyticsCheckboxIcons } from 'utils/AnalyticsUtils'
import { CheckboxSocialElement } from './CheckboxSocialElement'
import { AppStore, T } from 'utils'
import { Chip } from 'pages/settings/Subscription/Chip'
import { SocialType } from 'api/Utils'

export const CheckboxSocialContainer = () => {
  return (
    <CheckboxSocialContainerContainer className="checkbox_social_container">
      {analyticsCheckboxIcons.active.map((s) => (
        <CheckboxSocialElement socialName={s as SocialType} available={true} />
      ))}

      <ComingSoonContainer className="checkbox_social_container_comingsoon">
        {analyticsCheckboxIcons.comingSoon.map((s) => (
          <CheckboxSocialElement socialName={s as SocialType} available={false} />
        ))}

        <Chip position="center" text={T.analytics.comingSoon} border primaryColor="purple" bgColor="lightPurple" />
      </ComingSoonContainer>
    </CheckboxSocialContainerContainer>
  )
}

const CheckboxSocialContainerContainer = styled(Box)`
  &.checkbox_social_container {
    margin-top: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
`

const ComingSoonContainer = styled(Box)`
  &.checkbox_social_container_comingsoon {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 14px;
    background: ${() => AppStore.theme.o.lightestGrey};
    cursor: not-allowed;
  }
`
