const notificationSetting = [
  {
    name: 'Settings',
    permissions: [
      {
        name: 'Aggiunta profili social',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Rimozione profili social',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Modifica fusorario',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Modifica dei tuoi permessi',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
    ],
  },

  {
    name: 'Billing',
    permissions: [
      {
        name: 'Crediti AI in esaurimento',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Crediti AI terminati',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: "Modifica dell'abbonamento",
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Modifica dei dati di fatturazione',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Nuovi crediti da referral',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Spazio archiviazione in esaurimento',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Spazio archiviazione esaurito',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
    ],
  },

  {
    name: 'Publish',
    permissions: [
      {
        name: 'Post non pubblicato',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Post modificato',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Post pianificato',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
    ],
  },

  {
    name: 'Discover',
    permissions: [
      {
        name: 'Board resa condivisa',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Board eliminata',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Articoli aggiunti in una board',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
      {
        name: 'Nuovo commento in articolo',
        isDefault: false,
        type: {
          mail: false,
          app: false,
          whatsapp: false,
        },
      },
    ],
  },
]

const possibleTypeOfNotifyEN = ['settings', 'billing', 'publish', 'discover', 'inbox', 'autopilot']

export { notificationSetting, possibleTypeOfNotifyEN }
