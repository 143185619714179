import styled from '@emotion/styled'
import { Select } from 'antd'
import { Tracker } from 'api/Tracker'
import Workspaces from 'api/Workspaces'
import { Box, Button, Input } from 'components'
import { gsap } from 'gsap'
import { useEffect, useState } from 'react'
import { Workspace } from 'types'
import { AppStore, T, showInfo, showSuccess } from 'utils'
import { TRACKEREVENTS, getWorkspaceInvitationSubmittedData } from 'utils/eventsTracker'
import { MixpanelWorkspaceInvitationSubmitted } from 'utils/eventsTracker/workspace/workspaceInterfaces'
import { Icons } from './Icons'

interface UserData {
  key: number
  email: string
  role: string
}
interface Props {
  workspace: Workspace
  changeSession: (newSession: string) => void
  onWorkspaceChange: (workspace: Workspace) => void
}

const ManageRoleModal = ({ workspace, changeSession, onWorkspaceChange }: Props) => {
  const [rows, setRows] = useState([{ key: 0, index: 0 }])
  const [roleList, setRoleList] = useState<Array<string>>([])
  const [userData, setUserData] = useState<UserData[]>([{ key: 0, email: '', role: '' }])
  // const navigate = useNavigate()

  useEffect(() => {
    if (workspace.roles) setRoleList(workspace.roles.map((role) => role.name))
  }, [])

  const handleAddRow = () => {
    const newIndex = rows.length
    const existingRow = rows.some((row) => row.key === newIndex)

    if (!existingRow) {
      setRows((prevRows) => [...prevRows, { key: newIndex, index: newIndex }])
      setUserData((prevData) => [...prevData, { key: newIndex, email: '', role: '' }])
    }
  }

  const handleRemoveRow = (key) => {
    setRows((prevRows) => prevRows.filter((row) => row.key !== key))
    setUserData((prevData) => prevData.filter((row) => row.key !== key))
  }

  const InputSection = ({ sectionKey }) => {
    return (
      <Box row>
        <Box removeHover>
          <InputTitle>{T.teamMembers.insertEmail}</InputTitle>
          <Input
            style={{ width: 276, marginRight: 24, borderRadius: 14, border: `1px solid ${AppStore.theme.o.grey}` }}
            placeholder="Email"
            defaultValue={userData[userData.findIndex((user) => user.key === sectionKey)].email}
            prefix={<Email />}
            type="email"
            onChange={(txt) => {
              const oldData = userData
              const findIndex = oldData.findIndex((user) => user.key === sectionKey)
              oldData[findIndex].email = txt
              setUserData(oldData)
            }}
          />
        </Box>
        <Box removeHover>
          <InputTitle>{T.teamMembers.selectRole}</InputTitle>
          <Box vcenter row>
            <Select
              style={{ width: 276, borderRadius: 14, border: `1px solid ${AppStore.theme.o.grey}` }}
              placeholder={userData[userData.findIndex((user) => user.key === sectionKey)].role}
              onChange={(value) => {
                const oldData = userData
                const findIndex = oldData.findIndex((user) => user.key === sectionKey)
                oldData[findIndex].role = value
                setUserData(oldData)
              }}
              key={`input-${sectionKey}`}
              dropdownRender={(menu) =>
                roleList.length === 0 ? (
                  <div>
                    <Box
                      width={'100%'}
                      height={64}
                      onClick={() => {
                        // changeSession('permissions?changeName=true')
                        changeSession('permissions?changeName=true' + (workspace._id ? `&id=${workspace._id}` : ''))

                        AppStore.closeAllModal()
                      }}
                      center
                    >
                      <ButtonText style={{ fontSize: 18 }}>Crea ruolo</ButtonText>
                    </Box>
                  </div>
                ) : (
                  menu
                )
              }
            >
              {roleList.map((roleName) => (
                <Select.Option key={roleName} value={roleName}>
                  {roleName}
                </Select.Option>
              ))}
            </Select>

            {sectionKey !== 0 && (
              <Box
                center
                style={{ backgroundColor: AppStore.theme.o.lightRed }}
                width={40}
                height={40}
                borderRadius={8}
                ml={24}
                onClick={() => handleRemoveRow(sectionKey)}
              >
                <TrashIcon key={`trash-${sectionKey}`} />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    )
  }

  const addUserCollaborator = async () => {
    try {
      for (let i = 0; i < userData.length; i++) {
        try {
          const user = userData[i]
          if (workspace.collaborators?.some((collaborator) => collaborator.user?.email === user.email)) {
            return showInfo(T.info.userAlreadyExistsInCollaborator + user.email)
          }
          if (user.email === '' || user.role === '') return
          const email = user.email
          if (workspace.roles === undefined) return
          const role = workspace.roles.filter((workspaceRole) => workspaceRole.name === user.role)[0]._id
          const response = await Workspaces.addCollaborator({ email }, workspace._id ?? '', role ?? '')
          await onWorkspaceChange(response)

          const invitationSubmittedData: MixpanelWorkspaceInvitationSubmitted = {
            email: email,
            role_name: user.role,
            workspace_name: workspace.name,
            is_multi_invite: userData.length > 1,
          }

          Tracker.trackEvent(
            TRACKEREVENTS.WORKSPACE_INVITATION_SUBMITTED,
            getWorkspaceInvitationSubmittedData(invitationSubmittedData)
          )

          showSuccess(T.teamMembers.successInvite)
        } catch (err) {
          showInfo(err + T.info.onUser + userData[i].email)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  const increseWidth = () => {
    const array = document.getElementsByClassName('ant-modal')
    gsap.to(array[0], {
      width: 740,
      duration: 0.5,
    })
  }
  const decreaseWidth = () => {
    const array = document.getElementsByClassName('ant-modal')
    gsap.to(array[0], {
      width: 669,
      duration: 0.5,
    })
  }

  const handleInviteMembers = async () => {
    await addUserCollaborator()
    AppStore.closeAllModal()
  }

  useEffect(() => {
    if (rows.length > 1) increseWidth()
    else decreaseWidth()
  }, [rows])

  return (
    <Box relative removeHover>
      <Box
        style={{
          boxSizing: 'border-box',
          paddingInline: '48px',
          paddingTop: 24,
          maxHeight: 550,
        }}
        hideBar
        scroll
        removeHover
        flex
      >
        <TitleModal>{T.teamMembers.inviteMembers}</TitleModal>
        {rows.map((row) => (
          <InputSection sectionKey={row.key} />
        ))}
        <Box row vcenter style={{ justifyContent: 'space-between' }}>
          <Button
            id="add-email-button"
            hideBoxShadowOnHover
            mt={16}
            mb={24}
            variant="neutral"
            size="small"
            style={{ width: 147, backgroundColor: AppStore.theme.o.lightestGrey, borderRadius: 14 }}
            onClick={handleAddRow}
            shadow="none"
          >
            <ButtonText>{T.teamMembers.addEmail}</ButtonText>
          </Button>
        </Box>
      </Box>
      <Box absolute style={{ bottom: -80, right: 0 }}>
        <Button
          id="send-email-button"
          hideBoxShadowOnHover
          mt={16}
          mb={24}
          variant="primary"
          size="small"
          style={{
            width: 214,
            backgroundColor: `${AppStore.theme.o.lightBlue}!important`,
            border: `1px solid ${AppStore.theme.o.blue}`,
          }}
          onClick={handleInviteMembers}
        >
          <InviteButtonText>{T.teamMembers.invite}</InviteButtonText>
        </Button>
      </Box>
    </Box>
  )
}

export default ManageRoleModal

const TitleModal = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`

const InputTitle = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${() => AppStore.theme.o.black};
  margin-top: 24px;
  margin-bottom: 4px;
`

const ButtonText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${() => AppStore.theme.o.black};
`

const TrashIcon = styled(Icons.trash)`
  fill: ${() => AppStore.theme.o.red};
  width: 40px;
  height: 40px;
`

const Email = styled(Icons.closedMail)`
  fill: ${() => AppStore.theme.o.black};
  width: 13px;
  height: 11px;
`

const InviteButtonText = styled.p`
  color: ${() => AppStore.theme.o.blue};
  text-align: center;
  font-size: 14px;
  font-family: Aktiv Grotesk;
  font-weight: 700;
`
