import { Box, Button, Icons } from 'components'

import { Component } from 'react'
import { T } from 'translation/i18n'
import { AppStore, showWarning } from 'utils'
import CardInput from './CardInput'
interface Props {
  handleCardNumberChange: any
  handleCardExpirationChange: any
  handleCardCVCChange: any
  changeName: any
}

const ChangePaymentInfo = ({
  handleCardNumberChange,
  handleCardExpirationChange,
  handleCardCVCChange,
  changeName,
}: Props) => {
  const OpenEditCardModal = () =>
    AppStore.openModal({
      onCancel: () => showWarning(T.info.exit_without_confirmation),
      id: 'GropsShowModal',
      body: (
        <>
          <Box mt={16} pv={24} ph={32} style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}>
            <CardInput
              changeName={changeName}
              handleCardCVCChange={handleCardCVCChange}
              handleCardExpirationChange={handleCardExpirationChange}
              handleCardNumberChange={handleCardNumberChange}
            />
          </Box>
        </>
      ),
      width: '570px',
    })

  return (
    <Button
      width={120}
      size="smaller"
      height={32}
      onClick={() => OpenEditCardModal()}
      variant="secondary"
      type="button"
    >
      <Icons.edit fill={AppStore.theme.o.green} style={{ marginRight: 4 }} /> {T.settings.modify}
    </Button>
  )
}

export default ChangePaymentInfo
