/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { YoutubeShortAssets } from './YoutubeShortAssets'

/* ------------------- INTERFACES  */
interface Props {}

export const ResultsPreviewShort = ({}: Props) => {
  return (
    <ResultsPreviewShortContainer>
      <YoutubeShortAssets />
      <MediaContainerPost width={300} height={580} arrowsPositions={20} />
    </ResultsPreviewShortContainer>
  )
}

const ResultsPreviewShortContainer = styled(Box)`
  margin: 0 auto;
  width: 324px;
  height: 649px;
  padding: 12px;
  position: relative;
`
