import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box, Icons } from 'components'
import React, { useEffect, useState } from 'react'
import { AppStore, T, getHumanReadableDate } from 'utils'
import { isVisible } from 'utils/CheckVisibleElement'

interface Props {
  planCost: any
  editOption: any
}

const ReferralComponent = ({ planCost, editOption }: Props) => {
  const [data, setData] = useState<any>(undefined)

  const firstLoad = async () => {
    const prefineryData: any = (await Utils.getPrefineryData()).data
    if (isVisible('referralComponent.firstLoad.shareLink'))
      prefineryData.share_link = data?.share_link?.replace(
        `https://beta.asters.ai`,
        'https://romantic-booth-e26b41.netlify.app'
      )

    setData(prefineryData)
  }

  useEffect(() => {
    firstLoad()
  }, [])

  const sections = [
    {
      data: T.settings.standard,
      value: (AppStore.mySubscription?.renew_price ?? 0).toFixed(2) + planCost,
      onclick: () => editOption(false),
      optText: T.settings.changePlan,
      icon: (
        <Box style={{ maxHeight: 25 }} m={0} p={0}>
          <Icons.edit fill={AppStore.theme.o.green} />
        </Box>
      ),
    },

    {
      data: T.settings.nextRenewal,
      value: getHumanReadableDate(AppStore.loggedUser.rawPlan?.expirationDate),
      onclick: () => editOption(true),
      optText: T.settings.goAnnual,
      icon: <Icons.promotion fill={AppStore.theme.o.green} />,
    },
    {
      data: T.settings.creditsAvailabe,
      value: data ? (data.balance / 100) * -1 : 0,
      onclick: () => {
        const button = document.getElementById('refferal-button')
        if (button) button.click()
      },
      optText: T.settings.earnMoreCredits,
      icon: (
        <Box style={{ maxHeight: 26 }} m={0} p={0}>
          <Icons.referalGift fill={AppStore.theme.o.green} />
        </Box>
      ),
    },
  ]

  return (
    <>
      <Box mb={4} width={639} row>
        {sections.map((element, i) => (
          <Box flex center row style={{ maxWidth: 205 }} vcenter onClick={() => [element.onclick()]} removeHover>
            {planCost.includes(T.settings.year) && i === 1 ? (
              <></>
            ) : (
              <>
                {element.icon}
                <OpstionText>{element.optText}</OpstionText>
              </>
            )}
          </Box>
        ))}
      </Box>
      <Box mb={16} width={639} height={83} row style={{ border: `1px solid ${AppStore.theme.o.grey} ` }} relative>
        <HR
          style={{
            right: 195,
            top: 33,
            borderTop: `1px dashed ${AppStore.theme.o.grey}`,
            height: 0,
            backgroundColor: 'transparent',
          }}
        />
        <HR
          style={{
            left: 195,
            top: 33,
            borderTop: `1px dashed ${AppStore.theme.o.grey}`,
            height: 0,
            backgroundColor: 'transparent',
          }}
        />

        {sections.map((s, index) => (
          <Box flex center relative>
            <MainText style={{ color: index === 2 ? AppStore.theme.o.green : AppStore.theme.o.black }}>
              {s.value}
            </MainText>
            <SecondatyText>{s.data}</SecondatyText>
          </Box>
        ))}
      </Box>
    </>
  )
}

export default ReferralComponent

const HR = styled.hr`
  border: none;
  border-top: 1px dotted ${AppStore.theme.o.grey};
  color: #fff;
  background-color: #fff;
  height: 1px;
  width: 48px;
  transform: rotate(90deg);
  position: absolute;
`

const OpstionText = styled.p`
  color: ${AppStore.theme.o.green};
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`

const MainText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 27px;
`

const SecondatyText = styled.p`
  font-family: 'Aktiv Grotesk';
  color: ${AppStore.theme.o.darkGrey};
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
`
