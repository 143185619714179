import { SocialType } from 'api/Utils'
import { Dayjs } from 'dayjs'
import { SocialAccount } from 'types'

/* ----------------------------------- ENUM  */
export enum GraphType {
  // se si modifica questa parte, modificare anche il file src/utils/AnalyticsUtils.ts ( le default options )
  PIE = 'pie',
  BAR = 'bar',
  LINE = 'line',
  DOUGHNUT = 'doughnut',
  HEATMAP = 'heatmap',
  AREASPLINE = 'areaspline',
  GEOMAP = 'geomap',
  TEXT = 'text',
}

export type IGraphEnum = {
  [key in GraphType]: React.ReactNode
}

export type ISingleGraphEnum = {
  [key in GraphType]: { options: any; dataset: any }
}

/* ------------------------------------INTERFACE  */
export interface IReport {
  _id: string
  info: IInfoReport
  social: IReportSocial[]
}

export interface IReportSocial {
  _id: string
  name: string
  picture: string
  provider: SocialType
}

export interface IReportConfig {
  _id?: string
  info: IInfoReport
  data: IDataReport[] | IReportDataComplete[]
  reportId?: string
  since?: string
  until?: string
}

export interface IInfoReport {
  reportName?: string
  reportLogo?: string
  createdBy?: string // id creatore
  createdAt?: Date
  updatedAt?: Date
}

export interface IDataReport {
  account?: string //id del social account
  provider?: string
  metrics?: string[] //array di id delle metriche
  since?: Date | string 
  until?: Date | string
}

export interface IMetric {
  name: string
  description: string
  provider: string
  graphType: GraphType
  query?: string
  _id?: string
  ref: string
  hideMetric?: boolean
}

export interface ISelectableSocial {
  _id?: string
  picture?: string
  provider?: SocialType
}

export interface AnalyticsDate {
  startDate: Dayjs | null
  endDate: Dayjs | null
}

export interface IReportDataComplete extends IDataReport {
  metricName: string
  metricGraph: GraphType
  metricRef: string
  metricDescription: string
  metricTooltipDescription: string
  // metricData: [IAreaspline] | [Pie] | [Column] | [Heatmap] | []
  metricData: any[]
  socialProfile: SocialAccount
  since: Date | string
  until: Date | string
  x?: string // può essere 0 (sx) o 1 (dx)
  y?: string // prima riga a 0, tutte le altre a 375*1(ogni due numeri, quindi 2,3 -> 1, 3,4 -> 2, 5,6 -> 3, ecc) + 24
  width?: string // 1 se half, 1.95 se full
  config?: {
    tooltip?: {
      labels: string[] | string[][] // ["data", "impressions"] | [["data", "reel"], ["data", "carousel"]]
    }
  }
}

export interface ITextGraphData {
  label: string
  value: string
}

export interface ITextGraph {
  metricRef: string
  title?: string
  data: ITextGraphData[]
  noTextData?: string
}

// ----TYPES----
export type TSection = 'analytics' | 'createReport' | 'report'
export type TSubsection = 'setup' | 'social' | 'metrics'
export enum ESubSection {
  setup = 0,
  social = 1,
  metrics = 2,
}
export type TActiveReportList = 'all' | 'instagram' | 'facebook' | 'youtube' | 'tiktok' | 'linkedin' | 'x'
export type TActiveReportFilter = 'more_recent' | 'less_recent' | 'a_z' | 'z_a'
