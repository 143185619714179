/* ------------------- IMPORTS */
import { Box } from 'components'
import { SpinnerAstersLogo } from './SpinnerAstersLogo'

/* ------------------- INTERFACES  */

interface Props {
  cols: number
  data?: JSX.Element[]
  colGap?: number
  rowGap?: number
  width?: number
  marginTop?: number
  marginLeft?: number
  marginRight?: number
  marginBottom?: number
  isLoading?: boolean
}

export const GridContainer = ({
  cols,
  data,
  colGap = 24,
  rowGap = 24,
  width,
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
  marginBottom = 0,
  isLoading,
}: Props) => {
  return (
    <>
      {isLoading && <SpinnerAstersLogo dimension={100} />}

      {!isLoading && (
        <Box
          style={{
            alignSelf: 'center',
            display: 'grid',
            gridTemplateColumns: `repeat(${cols}, 1fr)`,
            gridColumnGap: `${colGap}px`,
            gridRowGap: `${rowGap}px`,
            width: `${width ? width + 'px' : '100%'}`,
            marginTop: `${marginTop}px`,
            marginLeft: `${marginLeft}px`,
            marginRight: `${marginRight}px`,
            marginBottom: `${marginBottom}px`,
          }}
        >
          {data}
        </Box>
      )}
    </>
  )
}
