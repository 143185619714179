/* ------------------- IMPORTS */
import { Template } from 'components/CreatePost/TextComponents/Template/Template'
import { showDraftModal } from 'components/Modal/ModalManager'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {}

export const DraftsContent = ({}: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()
  /* ----------------- STATE  */
  const [goToPost, setGoToPost] = useState<boolean>(false)
  /* ----------------- USEEFFECT  */
  useEffect(() => {
    showDraftModal(setGoToPost)
  }, [])

  useEffect(() => {
    if (goToPost) {
      navigate('/post/preview/basic')
      AppStore.closeModal('drafts-modal')
    }
  }, [goToPost])

  return <Template />
}
