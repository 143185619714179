import React from 'react'
import succesLotie from 'assets/succes.json'
import Lottie from 'react-lottie'

const succes = {
  loop: true,
  autoplay: true,
  animationData: succesLotie,
  rendererSettings: {
    preserveAspectRatio: '',
  },
}

export const Congratulation = () => {
  return <Lottie isClickToPauseDisabled options={succes} />
}
