/* ------------------- IMPORTS */
import { HandleSelectCard } from 'components/UI'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { IReport } from 'types/AnalyticsInferfaces'
import { deleteReport } from '../../../utils/AnalyticsUtils'

/* ------------------- INTERFACES  */
interface Props {
  reportIdSelected: string[]
  handleSelectId: (id: string | string[]) => void
  reportsList: IReport[] | null
  show?: boolean // Booleano per mostrare o meno l'handlecard situazionalmente
}

export const FooterDashboardAnalytics = ({ reportIdSelected, handleSelectId, reportsList, show }: Props) => {
  /* ----------------- ZUSTAND  */
  const { setReportList } = useAnalyticsStore.getState()

  /* ----------------- METHODS  */
  const handleSelectAllReports = () => {
    if (reportsList !== null) {
      if (reportIdSelected.length === reportsList.length) {
        handleSelectId([])
        // Se `reportIdSelected` ha la stessa lunghezza di `reportList`, svuotalo.
      } else if (reportIdSelected.length === 0) {
        // Se `reportIdSelected` è vuoto, assegna tutti gli ID da `reportList`.
        handleSelectId(reportsList.map((report) => report._id))
      } else {
        // Altrimenti, aggiungi gli ID rimanenti da `reportList` a `reportIdSelected`.
        const newSelectedReports = [...reportIdSelected]

        reportsList.forEach((report) => {
          if (!newSelectedReports.includes(report._id)) {
            newSelectedReports.push(report._id)
          }
        })

        handleSelectId(newSelectedReports)
      }
    }
  }

  const handleDeleteReports = () => {
    /* const newReportList = reportsList!.filter((report) => reportIdSelected.includes(report._id))
    setReportList(newReportList) */
    deleteReport(reportIdSelected)
    const newReportList = useAnalyticsStore
      .getState()
      .reportList!.filter((report) => !reportIdSelected.includes(report._id))
    setReportList(newReportList)
    handleSelectId([])
  }

  return (
    <HandleSelectCard
      isAllSelected={reportIdSelected.length === reportsList?.length}
      handleSelectAll={handleSelectAllReports}
      handleDelete={handleDeleteReports}
      show={show}
    />
  )
}
