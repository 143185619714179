import { useEffect } from 'react'
import styled from '@emotion/styled'
import { AppStore, T } from 'utils'
import { Box, Page } from 'components'
import { DiscoverMenu } from './DiscoverMenu/DiscoverMenu'
import DiscoverAside from './DiscoverAside/DiscoverAside'
import Workspaces from 'api/Workspaces'
import { IAiDiscover } from './aiDiscoverInterface'
import { getBoardsList, getFeedTrendArticles } from './commonFunction'
import DiscoverContent from './DiscoverContent/DiscoverContent'
import AiDiscover from 'api/AiDiscover'
import PlaceholderPage from './UI/PlaceholderPage'
import { defaultLanguage, useDiscoverStore } from 'stores/DiscoverStore'
import { ComingSoonComponent } from 'components/ComingSoonComponent'

const AiDiscoverPage = () => {
  /* ____________________ VARIABLES  ____________________ */
  const userDiscover = AppStore.discoverUser
  const user = AppStore.loggedUser

  /* ____________________ ZUSTAND  ____________________ */
  const { onForceUpdate } = useDiscoverStore((state) => ({
    onForceUpdate: state.onForceUpdate,
  }))

  /* ____________________ METHODS  ____________________ */

  /**
   *  ### per aggiornare il dato di AppStore.discoverUser
   * @param newUserDiscoverData discoverUser Object
   */
  const updatediscoverUser = (newUserDiscoverData: IAiDiscover) => {
    AppStore.discoverUser = newUserDiscoverData
  }

  /* ___________________ API CALL  ____________________ */

  /**
   * se l'utente esiste in aiDiscover allora lo setto in AppStore.discoverUser
   *
   * se l'utente non esiste lo credo e lo setto in AppStore.discoverUser
   */
  const checkUserExist = async () => {
    const data: IAiDiscover = {
      userId: user._id,
      workspaceId: AppStore.workspace._id ?? '',
    }

    // check discoverUser exist
    try {
      const response = await Workspaces.getAiDiscover()

      // if discoverUser not exist create it
      if (!response) {
        // create discoverUser
        const res = await AiDiscover.create(data)
        updatediscoverUser(res)

        return false
      }

      if (response) {
        updatediscoverUser(response)
        return true
      }
    } catch (e: any) {
      if (e.message === 'AiDiscover data not found') {
        const res = await AiDiscover.create(data)
        updatediscoverUser(res)
        if (res) return true
      }
    }
  }

  const firstLoad = async () => {
    setTimeout(async () => {
      const canSeeDiscover = await checkUserExist()

      if (!canSeeDiscover) return

      // Per articoli carousel (trend in generale)
      if (!AppStore.discoverTrendArticleList) getFeedTrendArticles(onForceUpdate, defaultLanguage[0])
      getBoardsList(onForceUpdate)
    }, 1650)
  }

  /* ____________________ USEEFFECT  ____________________ */
  useEffect(() => {
    firstLoad()
  }, [])

  return (
    <Page title={T.AiDiscoverPage.titlePage} style={{ flex: 1, maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
      {!userDiscover && <PlaceholderPage forceUpdatePage={onForceUpdate} />}

      {userDiscover && (
        <PageContainer id="ai-discover-container" className="page-container">
          <DiscoverAside />
          <DiscoverContent />
          <DiscoverMenu />
        </PageContainer>
      )}
    </Page>
  )
}

export default AiDiscoverPage

const PageContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
  position: relative;
  flex: 1;
  max-height: 100%;
  overflow: hidden;
`
