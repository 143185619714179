import AstersBaseResource from 'api/AstersBaseResource'
import { ILimits, IPostInfos, IPostLimits, ISocialPostLimits, IWorkspaceOwner } from 'types'
import AppStore from 'utils/AppStore'

export const INFINITE_SYMBOL = '∞'

//? Post infos
export let OWNER_POST_INFOS: IPostInfos
export let OWNER_POST_INFO_PLANNED: number
export let OWNER_POST_INFO_PUBLISHED: number
export let OWNER_POST_INFO_NOT_PUBLISHED: number
export let OWNER_POST_INFO_DRAFT: number

//? Post Limits
export let OWNER_POST_LIMITS: IPostLimits
export let OWNER_POST_LIMITS_USED: number
export let OWNER_POST_LIMITS_AVAILABLE: number
export let OWNER_POST_LIMITS_INFINITE: boolean
export let OWNER_POST_LIMITS_SOCIALS: ISocialPostLimits

//? Copies
export let OWNER_COPIES: ILimits
export let OWNER_COPIES_USED: number
export let OWNER_COPIES_AVAILABLE: number
export let OWNER_COPIES_EXTRA: number
export let OWNER_COPIES_INFINITE: boolean
export let OWNER_COPIES_TOTAL: number

//? Social Accounts Limits
export let OWNER_SOCIAL_ACCOUNTS_LIMITS: ILimits
export let OWNER_SOCIAL_ACCOUNTS_LIMITS_USED: number
export let OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE: number
export let OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE: boolean

//? Storage Limits
export let OWNER_STORAGE_LIMITS: ILimits
export let OWNER_STORAGE_LIMITS_USED: number
export let OWNER_STORAGE_LIMITS_AVAILABLE: number
export let OWNER_STORAGE_LIMITS_INFINITE: boolean

//? Collaborators Limits
export let OWNER_COLLABORATORS_LIMITS: ILimits
export let OWNER_COLLABORATORS_LIMITS_USED: number
export let OWNER_COLLABORATORS_LIMITS_AVAILABLE: number
export let OWNER_COLLABORATORS_LIMITS_INFINITE: boolean

export type TGetWorkspaceOwnerDataProps = {
  setUpdatedDashboard?: () => void
  userId?: string
  workspaceId?: string
}

/**
 * @returns Ritorna tutto il workspaceOwner, nel mentre setta tutte le variabili per definire i limiti dell'utente
 */
export const getWorkspaceOwnerData = async ({
  setUpdatedDashboard,
  userId,
  workspaceId,
}: TGetWorkspaceOwnerDataProps) => {
  try {
    const workspaceOwner: IWorkspaceOwner = await AstersBaseResource.get(
      userId ?? AppStore.workspace.user,
      workspaceId ?? AppStore.workspaceId
    )

    AppStore.workspace.owner = workspaceOwner
    //? Post infos
    OWNER_POST_INFOS = workspaceOwner.postInfos
    OWNER_POST_INFO_PLANNED = OWNER_POST_INFOS?.planned ?? 0
    OWNER_POST_INFO_PUBLISHED = OWNER_POST_INFOS?.published ?? 0
    OWNER_POST_INFO_NOT_PUBLISHED = OWNER_POST_INFOS?.notPublished ?? 0
    OWNER_POST_INFO_DRAFT = OWNER_POST_INFOS?.draft ?? 0

    //? Post Limits
    OWNER_POST_LIMITS = workspaceOwner.postLimits

    OWNER_POST_LIMITS_USED = OWNER_POST_LIMITS?.used ?? 0
    OWNER_POST_LIMITS_AVAILABLE = OWNER_POST_LIMITS?.available ?? 0
    OWNER_POST_LIMITS_INFINITE = OWNER_POST_LIMITS?.infinite ?? false
    OWNER_POST_LIMITS_SOCIALS = OWNER_POST_LIMITS?.socials ?? {}

    //? Copies
    OWNER_COPIES = workspaceOwner.copies
    OWNER_COPIES_USED = OWNER_COPIES?.used ?? 0
    OWNER_COPIES_AVAILABLE = OWNER_COPIES?.available ?? 0
    OWNER_COPIES_EXTRA = OWNER_COPIES?.extra ?? 0
    OWNER_COPIES_INFINITE = OWNER_COPIES?.infinite ?? false
    OWNER_COPIES_TOTAL = OWNER_COPIES_AVAILABLE + OWNER_COPIES_EXTRA

    //? Social Accounts Limits
    OWNER_SOCIAL_ACCOUNTS_LIMITS = workspaceOwner.socialaccountslimits
    OWNER_SOCIAL_ACCOUNTS_LIMITS_USED = OWNER_SOCIAL_ACCOUNTS_LIMITS?.used ?? 0
    OWNER_SOCIAL_ACCOUNTS_LIMITS_AVAILABLE = OWNER_SOCIAL_ACCOUNTS_LIMITS?.available ?? 0
    OWNER_SOCIAL_ACCOUNTS_LIMITS_INFINITE = OWNER_SOCIAL_ACCOUNTS_LIMITS?.infinite ?? false

    //? Storage Limits
    OWNER_STORAGE_LIMITS = workspaceOwner.storageLimits
    OWNER_STORAGE_LIMITS_USED = OWNER_STORAGE_LIMITS?.used ?? 0
    OWNER_STORAGE_LIMITS_AVAILABLE = OWNER_STORAGE_LIMITS?.available ?? 0
    OWNER_STORAGE_LIMITS_INFINITE = OWNER_STORAGE_LIMITS?.infinite ?? false

    //? Collaborators Limits
    OWNER_COLLABORATORS_LIMITS = workspaceOwner.collaboratorsLimits
    OWNER_COLLABORATORS_LIMITS_USED = OWNER_COLLABORATORS_LIMITS?.used ?? 0
    OWNER_COLLABORATORS_LIMITS_AVAILABLE = OWNER_COLLABORATORS_LIMITS?.available ?? 0
    OWNER_COLLABORATORS_LIMITS_INFINITE = OWNER_COLLABORATORS_LIMITS?.infinite ?? false

    setUpdatedDashboard && (await setUpdatedDashboard())

    return workspaceOwner
  } catch (e) {
    console.error(e)
  }
}
