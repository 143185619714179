import { Box, Text } from 'components'
import { T } from 'translation/i18n'
import AddOn from '../Subscription/AddOn'

const AiCredis = () => {
  return (
    <Box center>
      <Box>
        <Text fontSize={21} weight={900} style={{ marginBottom: 8 }}>
          {T.settings.addCreditAi}
        </Text>
        <AddOn />
      </Box>
    </Box>
  )
}

export default AiCredis
