import Metrics from 'api/Metrics'
import Reports from 'api/Reports'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { T } from 'translation/i18n'
import { IReportConfig } from 'types/AnalyticsInferfaces'
import { showError, showSuccess } from 'utils'
import ResponsiveGridLayout from 'react-grid-layout'

const analyticsCheckboxIcons = {
  active: ['instagram', 'facebook'],
  comingSoon: ['youtube', 'tiktok', 'x', 'linkedin', 'pinterest', 'google'],
}

const createReport = async (reportConfig: IReportConfig) => {
  try {
    const response = await Reports.createReport(reportConfig)

    return response
  } catch (e) {
    console.error(e)
  }
}

const getReportList = async () => {
  try {
    const response = await Reports.getReportList()

    return response
  } catch (e) {}
}

const getReport = async (reportId) => {
  try {
    const response = await Reports.getReport(reportId)

    return response
  } catch (e) {}
}

const changeReportName = async (reportId: string, reportName: string, isReportCard: boolean): Promise<boolean> => {
  const { setReportList, setIsReportUpdating, onSetReportName, setReportUpdateDate } = useAnalyticsStore.getState()
  try {
    setIsReportUpdating(true)
    const response = await Reports.changeReportName(reportId, reportName, isReportCard)

    onSetReportName(reportName)
    setReportUpdateDate(response.info.updatedAt)
    setIsReportUpdating(false)

    // updates report list everywhere
    // getReportList().then((res) => {
    //   setReportList(res)
    // })

    showSuccess(T.success.reportNameChanged)
    return true
  } catch (e: any) {
    if (e.message === 'Report name already exists') {
      showError('Report name already exists')
    }
    return false
  }
}

const changeReportLogo = async (reportId: string, reportName: string) => {
  const { setReportPreview, setIsReportUpdating } = useAnalyticsStore.getState()
  try {
    setIsReportUpdating(true)
    const response = await Reports.changeReportLogo(reportId, reportName)

    setReportPreview(response)
    setIsReportUpdating(false)
    return response
  } catch (e) {
    showError(T.error.serverError)
  }
}

//Da testare in futuro
const updateReport = async (reportId) => {
  try {
    const response = await Reports.updateReport(reportId)

    return response
  } catch (e) {}
}

const deleteReport = async (reportId: string | string[]) => {
  try {
    const response = await Reports.deleteReport(reportId)

    return response
  } catch (e) {}
}

const getMetrics = async () => {
  try {
    const response = await Metrics.getMetrics()
    return response.filter((metric) => !metric?.hideMetric)
  } catch (e) {}
}

const saveLayout = async (layout: ResponsiveGridLayout.Layout[]) => {
  const { setIsReportUpdating, setReportUpdateDate, reportPreview } = useAnalyticsStore.getState()
  if (layout.length === 0) return
  try {
    setIsReportUpdating(true)
    const response = await Reports.updateGraphPosition(layout, reportPreview?._id ?? '')
    if (!response) showError(T.error.serverError)
    setReportUpdateDate(response.info.updatedAt)
    setIsReportUpdating(false)
  } catch (error) {
    showError(T.error.serverError)
  }
}

// --------------- UTILS FUNCTIONS
const formatDate = (updatedAt: Date | string) => {
  if (updatedAt === '') return ''
  if (updatedAt instanceof Date) {
    return (
      updatedAt.toLocaleDateString() +
      ` ${T.singleWord.at} ` +
      updatedAt.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
    )
  } else {
    return (
      new Date(updatedAt!).toLocaleDateString() +
      ` ${T.singleWord.at} ` +
      new Date(updatedAt!).toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })
    )
  }
}

/**
 *
 * @returns [thirtyDaysAgoDate, currentDateString]
 */
const dateRange28DaysAgo = () => {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - 1)
  const currentDateString = currentDate.toISOString().split('T')[0]
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 28)
  const thirtyDaysAgoDate = thirtyDaysAgo.toISOString().split('T')[0]

  return [thirtyDaysAgoDate, currentDateString]
}
/* --------------  */

/* -------------- REACT-GRID-LAYOUT */
const positionGridItem = (i: number, width: number) => {
  return { x: i % 2 === 0 ? 0 : 1, y: calculateYPosition(i), w: width, h: 1 }
}

const calculateYPosition = (i: number) => {
  let y = 0

  if (i >= 2) {
    y = Math.floor((i + 1) / 2)
  }

  return y
}

const enumYposition = {
  0: 0,
  1: 0,
  2: 428 * 1 + 24,
  3: 428 * 1 + 24,
  4: 428 * 2 + 24,
  5: 428 * 2 + 24,
  6: 428 * 3 + 24,
  7: 428 * 3 + 24,
  8: 428 * 4 + 24,
  9: 428 * 4 + 24,
  10: 428 * 5 + 24,
  11: 428 * 5 + 24,
  12: 428 * 6 + 24,
  13: 428 * 6 + 24,
  14: 428 * 7 + 24,
  15: 428 * 7 + 24,
  16: 428 * 8 + 24,
  17: 428 * 8 + 24,
  18: 428 * 9 + 24,
  19: 428 * 9 + 24,
  20: 428 * 10 + 24,
  21: 428 * 10 + 24,
  22: 428 * 11 + 24,
  23: 428 * 11 + 24,
  24: 428 * 12 + 24,
  25: 428 * 12 + 24,
  26: 428 * 13 + 24,
  27: 428 * 13 + 24,
}

export {
  createReport,
  getReportList,
  getReport,
  changeReportName,
  changeReportLogo,
  updateReport,
  deleteReport,
  getMetrics,
  analyticsCheckboxIcons,

  /* --------------- UTILS FUNCTIONS */
  formatDate,
  dateRange28DaysAgo,
  saveLayout,

  /* -------------- REACT-GRID-LAYOUT */
  positionGridItem,
  enumYposition,
}
