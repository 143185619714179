import styled from '@emotion/styled'
import { loadStripe } from '@stripe/stripe-js'
import Utils from 'api/Utils'
import { Box, Button, Text } from 'components'
import SubscriptionAnimation from 'components/Animations/SubscriptionAnimation'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, showError, showInfo, showSuccess, sleep } from 'utils'
import { navigate, refresh } from 'utils/router'
import AddOn from './AddOn'
import CupponBox from './CoupponBox'
import { useParams } from 'react-router-dom'
import { AddonType, CustomerCard } from 'types/SettingsInterfaces'
import { Tracker } from 'api/Tracker'
import {
  TRACKEREVENTS,
  getAiCreditsPageViewedData,
  getAiCreditsPurchaseSuccessful,
  getAiCreditsPurchaseFailed,
} from 'utils/eventsTracker'
import { MixpanelCreditsPage } from 'utils/eventsTracker/aiSection/aiSectionInterfaces'

const AddOnPayment = () => {
  const queryParams = useParams()
  /* ------ Attualmente sono inutilizzati questi state  
  const [storageGeneration, setStorageGeneration] = useState<any>(
    AppStore.loggedUser.rawPlan?.storageGeneration === undefined ? 0 : AppStore.loggedUser.rawPlan?.storageGeneration
  )

const [extraSocialGeneration. setExtraSocialGeeration] = useState<any>(AppStore.loggedUser.rawPlan?.extraSocialGeneration === undefined
    ? 0
    : AppStore.loggedUser.rawPlan?.extraSocialGeneration) */
  const [textNum, setTextNum] = useState<number>(queryParams.Text ? Number(queryParams.Text) : 0)
  const [hastagNum, setHashtagNum] = useState<number>(queryParams.Hashtag ? Number(queryParams.Hashtag) : 0)
  const [addonType, setAddonType] = useState<AddonType>(
    AppStore.loggedUser.stripe?.billings?.vatNumber === undefined ? 'private' : 'business'
  )
  const [paying, setPaying] = useState<boolean>(false)
  const [customerCard, setCustomerCard] = useState<CustomerCard>({
    isError: true,
    number: AppStore.loggedUser.stripe?.billings?.cardNumber,
  })
  const [cardName, setCardname] = useState<string>('')
  const [privacyRead, setPrivacyRead] = useState<boolean>(false)
  const [couponCode, setCouponCode] = useState<string>('')
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(false)
  const [coupon, setCoupon] = useState<any>('')
  const [totalSale, setTotalSale] = useState<number>(0)
  const [shownSale, setShownSale] = useState<number>(0)

  /* ---------------------------- METHODS */

  const setHashtag = (value) => setHashtag(value)
  const setText = (value) => setTextNum(value)

  const handleCardNumberChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, number: e, isError: false }
    })
  }

  const handleCardExpirationChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, expiration: e, isError: false }
    })
  }

  const handleCardCVCChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, cvc: e, isError: false }
    })
  }

  const goDashbord = async () => {
    showSuccess(T.settings.payment_success)
    await sleep(1000)
    navigate('/')
    refresh()
  }

  const getAiCaptionQuantity = (): number => {
    return textNum / 10
  }

  const getSubtotalAndTotalString = () => {
    const { totalPrice, offer } = getTotalPrice()

    const subtotal = (totalPrice / 1.22).toFixed(2) // Total Price / VAT

    const totalString = '€' + totalPrice
    const subtotalString = '€' + subtotal

    return { subtotalString, totalString }
  }

  const { subtotalString, totalString } = getSubtotalAndTotalString()

  const mixpanelPageViewed = () => {
    const pageViewedData: MixpanelCreditsPage = {
      initial_referrer: '',
      product_name: 'AI Captions',
      product_ai_captions_quantity: getAiCaptionQuantity(),
      product_ai_images_quantity: '',
      subtotal: subtotalString,
      total: totalString,
    }

    Tracker.trackEvent(TRACKEREVENTS.AI_CREDITS_CHECKOUT_PAGE_VIEWED, getAiCreditsPageViewedData(pageViewedData))
  }

  const proceedToPayment = async () => {
    try {
      if (privacyRead === false) {
        showInfo(T.settings.you_have_to_accept_the_privacy_policy)
        return
      }
      if (textNum < 10) {
        showInfo(T.settings.you_have_to_buy_at_least_1_test)
        return
      }

      const res: any = await Utils.createPaymentIntent({
        textQuantity: textNum / 10,
        coupon: coupon,
      })

      if (res.invoiceFinal.paid) {
        goDashbord()
      }
      if (res.client_secret) {
        const stripe = await loadStripe(AppStore.settings.stripe.apiKey)
        const confirmation = await stripe?.confirmCardPayment(res.client_secret)

        if (confirmation?.paymentIntent?.status === 'succeeded') {
          goDashbord()
        } else {
          showInfo(T.settings.payment_error)
        }
      }

      if (res.paymentIntent.status === 'succeeded') {
        showSuccess(T.settings.payment_success)
        await sleep(1000)
        navigate('/')

        const purchaseSuccessful: MixpanelCreditsPage = {
          initial_referrer: '',
          product_name: 'AI Captions',
          product_id: 'prod_NZDhdt2s8WqcB5',
          price_id: 'price_1Mo5FxDXRpjmR9xQcdM3Ey57',
          vat: '---------',
          currency: 'EUR - Euro',
          product_ai_captions_quantity: getAiCaptionQuantity(),
          product_ai_images_quantity: '',
          subtotal: subtotalString,
          total: totalString,
          coupon: '---------',
          num_invoice: '---------',
        }

        Tracker.trackEvent(
          TRACKEREVENTS.AI_CREDITS_PURCHASE_SUCCESSFUL,
          getAiCreditsPurchaseSuccessful(purchaseSuccessful)
        )
      } else {
        showError(T.settings.payment_error)

        const purchaseFailed: MixpanelCreditsPage = {
          initial_referrer: '',
          product_name: 'AI Captions',
          product_id: 'prod_NZDhdt2s8WqcB5',
          price_id: 'price_1Mo5FxDXRpjmR9xQcdM3Ey57',
          vat: '---------',
          currency: 'EUR - Euro',
          product_ai_captions_quantity: getAiCaptionQuantity(),
          product_ai_images_quantity: '',
          subtotal: subtotalString,
          total: totalString,
          coupon: '---------',
          error_message: '---------',
        }

        Tracker.trackEvent(TRACKEREVENTS.AI_CREDITS_PURCHASE_FAILED, getAiCreditsPurchaseFailed(purchaseFailed))
      }
      //open the url in the same window
      // if (res?.url) window.location.href = res.url
    } catch (error) {}
  }

  const addCoupon = async (e?: any) => {
    e?.preventDefault()
    if (!couponCode || couponCode === '') return
    setLoadingCoupon(true)
    try {
      const findCooupon = await Utils.getCoupon(couponCode)
      if (!findCooupon || !findCooupon.valid) throw new Error('Coupon non attivo')

      SubscriptionAnimation.addCoupon('cupponSpawn')
      SubscriptionAnimation.increseWidth('cupponContainer', 42)
      setCoupon(findCooupon)
    } catch (error) {
      showError(error)
    }

    setLoadingCoupon(false)
  }

  const getTotalPrice = () => {
    let totalPrice: any = ((textNum / 10) * 3).toFixed(2)
    let offer = 0
    if (coupon) {
      if (coupon.percent_off) {
        offer = (totalPrice * coupon.percent_off) / 100
        totalPrice = (totalPrice - offer).toFixed(2)
      } else if (coupon.amount_off) {
        offer = coupon.amount_off / 100
        totalPrice = (totalPrice - offer).toFixed(2)
      }
    }
    totalPrice = totalPrice * 1.22

    return { totalPrice, offer }
  }

  const firtMount = async () => {
    if (
      AppStore.loggedUser.stripe?.billings !== undefined &&
      AppStore.loggedUser.stripe?.billings.customerCard !== undefined
    ) {
      setCardname(AppStore.loggedUser.stripe?.billings.customerCard)
    }

    // @ts-ignore
    const data: any = (await Utils.getPrefineryData()).data.balance

    setTotalSale(data / 100)
  }

  useEffect(() => {
    firtMount()
    mixpanelPageViewed()
  }, [])

  const { totalPrice, offer } = getTotalPrice()

  return (
    <Box pv={60} ph={80} row>
      <Box>
        <Box style={{ maxHeight: 801, minHeight: 50 }}>
          <StepContainer flex hcenter justify={'end'}>
            <Text weight={'bold'} fontSize={16} color={AppStore.theme.o.black}>
              {T.settings.addOn}
            </Text>
            <UnderLineStep mt={8} />
          </StepContainer>
          <Box mt={40} row>
            {/*  -----------------_ la parte dei add on   -----------------_*/}
            <AddOn setHash={setHashtag} setNum={setText} textNum={textNum} hashtagNum={hastagNum} />
          </Box>
        </Box>

        {/*  -----------------_ del box di pagamento  -----------------_*/}
      </Box>
      {/*  -----------------_ parte CHeack Out -----------------_*/}

      <Box style={{ marginLeft: 20, marginTop: 75 }}>
        <SecondaryPaymentBox>
          <Box>
            <Box vcenter row>
              <Text style={{ flex: 1 }}>{T.settings.summary}</Text>
              <Acquista
                variant={privacyRead === false || textNum < 10 ? 'neutral' : 'primary-small'}
                onClick={proceedToPayment}
              >
                {T.settings.buy}
              </Acquista>
            </Box>
            <CupponBox
              addCoupon={addCoupon}
              loadingCoupon={loadingCoupon}
              coupon={coupon}
              onChangeCuponCode={(e) => setCouponCode(e)}
              onChangeCoupon={() => setCoupon(undefined)}
            />
            <Separator />
            <Text style={{ marginBottom: 8 }} color={AppStore.theme.o.yellow}>
              {T.settings.addOn}
            </Text>
            {/* //-----//-----//-----//-----//-----//-----//-----//-----//-----//-----//----- */}
            {/* detail Box */}
            <Box row>
              <Box row flex>
                <TextSpecial>{T.postPage.textGeneration}</TextSpecial>
              </Box>
              <TextSpecial>{(textNum / 10) * 3} €</TextSpecial>
            </Box>
            {offer > 0 && (
              <Box row>
                <Box row flex>
                  <TextSpecial>coupon</TextSpecial>
                </Box>
                <TextSpecial>-{offer} €</TextSpecial>
              </Box>
            )}
            <Box row>
              <Box row flex>
                <TextSpecial>{T.settings.ivaPayment} </TextSpecial>
              </Box>
              <TextSpecial>{(((textNum / 10) * 3 - (textNum / 10) * 3 * 1.22) * -1).toFixed(2)} €</TextSpecial>
            </Box>
            <Box row>
              <Box row flex>
                <TextSpecial> Sconto </TextSpecial>
              </Box>
              <TextSpecial>{shownSale.toFixed(2)} €</TextSpecial>
            </Box>

            {/* //-----//-----//-----//-----//-----//-----//-----//-----//-----//-----//----- */}
            <Separator />
            <Box vcenter row>
              <Box flex>
                <TextSpecial>{T.settings.total}</TextSpecial>
              </Box>
              <Text fontSize={36} weight={700}>
                {(totalPrice + shownSale).toFixed(2)} €
              </Text>
            </Box>
          </Box>

          <Box
            row
            vcenter
            style={{ right: 10 }}
            mt={4}
            onClick={async () => {
              await setPrivacyRead((prev) => !prev)
            }}
          >
            <Box>
              <CustomInputCheckbox checked={privacyRead} style={{ marginLeft: 12, marginBottom: 2 }} type="checkbox" />
            </Box>

            <Box ml={4} p={8} style={{ maxWidth: 240 }}>
              <Text color={AppStore.theme.o.black} fontSize={12}>
                {T.settings.confirmPrivacy1}
                <span
                  color={AppStore.theme.o.green}
                  onClick={() => window.open('https://asters.ai/termini-di-utilizzo')}
                  style={{ fontSize: 14, fontWeight: 700, marginLeft: 5, color: AppStore.theme.o.green }}
                >
                  {T.settings.confirmPrivacy2}
                </span>
              </Text>
            </Box>
          </Box>
          <Box vcenter row>
            <Acquista
              style={{ flex: 1, marginTop: 24 }}
              variant={privacyRead === false || textNum < 10 ? 'neutral' : 'primary-small'}
              onClick={proceedToPayment}
            >
              {T.settings.buy}
            </Acquista>
          </Box>
        </SecondaryPaymentBox>
      </Box>
    </Box>
  )
}

export default AddOnPayment

const TextSpecial = styled((props) => <Text {...props} />)`
  font-size: 12px;
  font-weight: 500;
`

const UnderLineStep = styled(Box)<{ done?: boolean }>`
  height: 4px;
  width: 100%;
  background-color: ${AppStore.theme.o.blueOnLight};
  transition: all 0.3s ease-in-out;
`

const SecondaryPaymentBox = styled(Box)`
  border: 1px dashed ${AppStore.theme.o.darkGrey};
  width: 333px;
  padding-right: 32px;
  padding-left: 32px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 20px;
  height: fit-content;
`

const StepContainer = styled(Box)`
  margin-left: 4px;
  &:nth-child(1) {
    margin-left: 0px;
  }
`

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`
const Acquista = styled(Button)`
  min-height: 48px !important;
  min-width: 128px !important;
  font-size: 14px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
`

const CustomInputCheckbox = styled.input`
  width: 2px;
  height: 2px;
  margin-top: 2px;
  background-color: ${() => AppStore.theme.o.grey};
  border-radius: 50%;
  vertical-align: middle;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  :checked {
    background-color: #2aad76;
  }
`

/*   async componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<State>, snapshot?: any) {
    const { totalPrice, offer } = this.getTotalPrice()

    if (prevState.textNum !== this.state.textNum) {
      if (this.state.totalSale * -1 >= totalPrice)
        this.setState({
          shownSale: totalPrice * -1,
        })
      else this.setState({ shownSale: this.state.totalSale })
    }
    // const new = (this.state.textNum / 10) * 3
  }
   */
