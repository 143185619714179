/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { BackwardIcon, Box, ForwardIcon } from 'components'
import { useState } from 'react'
import { getSelectedMedias } from 'utils/PostUtils/MediaPost'
import { UploadMedia } from './UploadMedia'
import { getActiveInfo, nextMedia, prevMedia } from 'utils/PostUtils/Post'
import { AppStore, T } from 'utils'
import { canShowMetadata, findVisibleMetadata } from 'utils/PostUtils/ShortUrl'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { truncateText } from 'pages/ai-discover/commonFunction'

/* ------------------- INTERFACES  */
interface Props {
  width?: number | string
  height: number | string
  arrowsPositions: number
}

export const MediaContainerPost = ({ width, height, arrowsPositions }: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as any,
  }))

  /* ----------------- STATE  */
  const [index, setIndex] = useState<number>(0)

  /* ----------------- METHODS  */
  const getMedias = () => getSelectedMedias()

  /* ----------------- VARIABLES  */
  const medias = getMedias()

  const firstMetadata = findVisibleMetadata(activePost)
  const visibleLink = firstMetadata ? firstMetadata[0] : undefined

  const showMetadata = canShowMetadata()

  const isXActive = getActiveInfo().account?.provider === 'x'

  return (
    <MediaContainerPostContainer style={{ width: width ?? '100%', height: height }}>
      {showMetadata && (
        <>
          <MetadataImg
            src={visibleLink?.metaData.ogImg}
            height={isXActive ? '100%' : '294px'}
            style={{
              borderRadius: isXActive ? '14px' : '14px 14px 0px 0px',
            }}
          />

          {!isXActive && (
            <>
              <MetadataTitle>
                {visibleLink?.metaData?.ogTitle?.length && visibleLink?.metaData?.ogTitle?.length > 80 ? (
                  <>
                    {visibleLink?.metaData?.ogTitle?.slice(0, 80)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
                  </>
                ) : (
                  <>{visibleLink?.metaData?.ogTitle}</>
                )}
              </MetadataTitle>
              <MetadataDescription>
                {visibleLink?.metaData?.ogDescription?.length && visibleLink?.metaData?.ogDescription?.length > 80 ? (
                  <>
                    {visibleLink?.metaData?.ogDescription?.slice(0, 80)}{' '}
                    <SeeMoreText>... {T.postPage.more}</SeeMoreText>
                  </>
                ) : (
                  <>{visibleLink?.metaData?.ogDescription}</>
                )}
              </MetadataDescription>
            </>
          )}

          {isXActive && <MetadataTitleX>{truncateText(visibleLink?.metaData?.ogTitle, 40)}</MetadataTitleX>}
        </>
      )}

      {/* Empty state media */}
      {!showMetadata && medias?.length === 0 && <UploadMedia />}

      {/* Diverso dai video  */}
      {!showMetadata && medias?.length > 0 && !medias[index].mimetype?.includes('video') && (
        <MediaImg src={medias[index].url} />
      )}

      {/* Video */}
      {!showMetadata && medias?.length > 0 && medias[index].mimetype?.includes('video') && (
        <MediaVideo controls muted autoPlay playsInline src={medias[index].url} />
      )}

      {/* Carousel controls */}
      {!showMetadata && medias?.length > 1 && (
        <ContainerArrow>
          <BackwardIcon
            onClick={() => prevMedia(index, setIndex, medias)}
            style={{ position: 'relative', left: `-${arrowsPositions}px` }}
          />
          <ForwardIcon
            onClick={() => nextMedia(index, setIndex, medias)}
            style={{ position: 'relative', right: `-${arrowsPositions}px` }}
          />
        </ContainerArrow>
      )}
    </MediaContainerPostContainer>
  )
}

const MediaContainerPostContainer = styled(Box)`
  margin: 0 auto;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const MediaImg = styled.img`
  object-fit: cover;
  min-width: -webkit-fill-available;
  border-radius: 14px;
  width: 100%;
  height: 100%;
`

const MediaVideo = styled.video`
  object-fit: cover;
  min-width: -webkit-fill-available;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  &::-webkit-media-controls-panel {
    display: none !important;
    opacity: 1 !important;
  }
`

const ContainerArrow = styled(Box)`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const MetadataImg = styled.img`
  width: 100%;
`

const MetadataTitle = styled(Box)`
  padding: 8px 16px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
`

const MetadataDescription = styled(Box)`
  padding: 0px 16px 4px;
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
`

const MetadataTitleX = styled(Box)`
  position: absolute;
  bottom: 10px;
  left: 13px;
  color: ${() => AppStore.theme.o.black};
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 4px 8px;
  background-color: ${() => AppStore.theme.o.lightestGrey};
`
