import { useAppStore } from 'stores/AppStore'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'

export default class PostHistory extends AstersBaseResource {
  static endpoint = '/postHistory'

  static paginate<T>(searchParams: any, paginationConfig?: null, requestConfig?: undefined) {
    //add workspace to searchParams
    searchParams.workspace = AppStore.workspace._id
    return super.paginate<T>(searchParams, paginationConfig, requestConfig)
  }

  static publishV2() {
    try {
      const { activeWorkspace } = useAppStore.getState()
      const { posts } = useCreatePostStore.getState()

      const dataObj = {
        posts: posts,
      }

      return api.post(`/postHistory/${activeWorkspace?._id}/createNewPosts`, dataObj).then(responseErrorCheck)
    } catch (err) {
      console.error(err)
    }
  }

  //   //publish post
  //   static publish(id, requestConfig = undefined) {
  //     return api.post(`${this.endpoint}/${id}/publish`, {}, requestConfig).then(responseErrorCheck)
  //   }

  // -----------------  DELETE IL POSTONE  -----------------
  static DeletePost(id) {
    return api.delete(`${this.endpoint}/${id.id._id}`).then(responseErrorCheck)
  }

  // -----------------  DELETE IL POSTONE  -----------------
}
