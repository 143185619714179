import styled from '@emotion/styled'
import { Form } from 'antd'
import { Box, Button, Icons, Input, Text } from 'components'
import SubscriptionAnimation from 'components/Animations/SubscriptionAnimation'
import React, { Component } from 'react'
import { AppStore, T } from 'utils'

interface Props {
  coupon: any
  addCoupon: any
  loadingCoupon: boolean
  onChangeCuponCode: any
  onChangeCoupon: any
}

const CupponBox = ({ coupon, addCoupon, loadingCoupon, onChangeCoupon, onChangeCuponCode }: Props) => {
  return (
    <Box>
      <Box
        row
        style={{
          maxHeight: 200,
          marginBottom: 0,
        }}
        vcenter
      >
        <Form.Item name="coupon" style={{ position: 'relative', marginBottom: 780 }}>
          <StyledInput
            onPressEnter={addCoupon}
            label={T.settings.coupon}
            onChange={(e) => onChangeCuponCode(e)}
            placeholder={T.settings.type_coupon}
          />
        </Form.Item>

        <Button loading={loadingCoupon} onClick={addCoupon} ml={10} mt={20} size="small" variant="secondary-small">
          {T.settings.aplicate}
        </Button>
      </Box>
      <Box>
        <Box
          id={'cupponContainer'}
          style={{
            overflow: 'hidden',
            position: 'relative',
            minWidth: 80,
            // minHeight: 34,
          }}
        >
          <Box
            id={'cupponSpawn'}
            style={{
              gap: 12,
              position: 'absolute',
              backgroundColor: AppStore.theme.o.lightGrey,
              x: coupon === undefined ? -100 : 0,
            }}
            pv={8}
            ph={16}
            row
            vcenter
          >
            <Text>{coupon ? coupon.name : ''} </Text>
            <Icons.close
              width={10}
              onClick={() => {
                onChangeCoupon()
                SubscriptionAnimation.removeCoupon('cupponSpawn')
                SubscriptionAnimation.increseWidth('cupponContainer', 0, true)
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default CupponBox

const StyledInput = styled(Input)`
  margin-bottom: 12px;
  padding-left: 16px !important;
  font-size: 14px !important;
`
