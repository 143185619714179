import { Button } from 'components'
import { T } from 'utils'
import { useNavigate } from 'react-router-dom'

export default function AddSocialButton() {
  const navigate = useNavigate()
  const navigateFunc = () => navigate('/settings/social')

  return (
    <Button
      width={221}
      style={{
        minHeight: 52,
        fontSize: 16,
        fontWeight: 700,
      }}
      variant="secondary"
      onClick={navigateFunc}
    >
      {T.DashboardPage.addSocial}
    </Button>
  )
}
