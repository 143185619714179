/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { InputEditableContent } from 'components/InputEditableContent'
import { SocialPicture } from 'components/UI/SocialPicture'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { IReport } from 'types/AnalyticsInferfaces'
import { AppStore, T, showInfo, showSuccess } from 'utils'
import { changeReportName, formatDate } from 'utils/AnalyticsUtils'

/* ------------------- INTERFACES  */

interface Props {
  content: IReport
  someSelected?: boolean
  action?: () => void
}

export const ReportCard = ({ content, someSelected, action }: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- VARIABLES  */
  const { info, social } = content
  const { createdBy, reportLogo, reportName } = info

  /* ----------------- STATE  */
  const [inputReportName, setInputReportName] = useState<string>(reportName || '')

  /* ----------------- METHODS  */
  const onClickCard = () => {
    if (someSelected) {
      action && action()
    } else {
      navigate(`/analytics/report/${content._id}`)
    }
  }

  // const onLoseFocus = () => {
  //   //! Da sistemare manda sempre solito ID

  //   changeReportName(content._id, inputReportName)
  // }

  /* ----------------- USEEFFECT  */
  //Per renderizzare il reportName corretto nel caso si selezioni un filtro
  useEffect(() => {
    setInputReportName(reportName!)
  }, [content])

  return (
    <ReportCardContainer className="report_card_container" onClick={onClickCard}>
      <InputEditableContent
        value={inputReportName}
        submitAction={(currentReportName) => changeReportName(content._id, currentReportName, true)}
        defaultValue={inputReportName}
        maxWidth={240}
      />
      <ContainerReportInfo className="container_report_info">
        <ReportCardCreatedBy className="report_card_createdBy">
          {T.AiDiscoverPage.boardCard.createdBy} {createdBy}
        </ReportCardCreatedBy>
        <ReportCardUptedAt className="report_card_uptedAt">
          {T.analytics.lastEdit} : {formatDate(info.updatedAt!)}
        </ReportCardUptedAt>
      </ContainerReportInfo>

      <ReportCardAccountsContainer className="report_card_accounts_container">
        {social.map((acc) => (
          <ReportCardAccount className="report_card_account">
            <SocialPicture picture={acc.picture} provider={acc.provider} />
          </ReportCardAccount>
        ))}
      </ReportCardAccountsContainer>
    </ReportCardContainer>
  )
}

const ReportCardContainer = styled(Box)`
  &.report_card_container {
    padding: 24px 24px 24px 16px;
    border-radius: 24px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.surface};
    min-height: 156px;
    width: 356px;
    position: relative;
  }
`

const ContainerReportInfo = styled(Box)`
  &.container_report_info {
    margin: 8px 8px;
  }
`

const ReportCardCreatedBy = styled(Box)`
  &.report_card_createdBy {
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    margin-bottom: 6px;
    height: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-wrap: break-word;
    display: block;
    max-width: 260px;
    border-radius: 0;
  }
`

const ReportCardUptedAt = styled(Box)`
  &.report_card_uptedAt {
    color: ${() => AppStore.theme.o.black};
    font-family: Aktiv Grotesk;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.1px;
    height: 12px;
  }
`

const ReportCardAccountsContainer = styled(Box)`
  &.report_card_accounts_container {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
`

const ReportCardAccount = styled(Box)`
  &.report_card_account {
    position: relative;
    width: 40px;
    height: 36px;
  }
`
