/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T, showInfo } from 'utils'
import { ExtraTitle } from '../GenericExtras/ExtraTitle'
import { openPiningModal } from 'components/Modal/ModalManager'
import { useState } from 'react'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const Tags = ({ post }: Props) => {
  //TODO: FIX openPiningModal

  const [notClosebleModal, setNotClosebleModal] = useState(false)

  return (
    <TagsContainer className="TagsContainer">
      <ExtraTitle text="Tag" />
      <TagButton
        className="TagButton"
        variant="primary"
        style={{
          maxHeight: '40px',
          minHeight: '40px',
        }}
        onClick={() => {
          if (post.data.medias !== undefined && post.data.medias?.length > 0) openPiningModal(post, setNotClosebleModal)
          else showInfo(T.postPage.addMediaFirst)
        }}
      >
        <Icons.tagIcon fill={AppStore.theme.o.blueOnLight} />
        {T.postPage.extraTag}
      </TagButton>
    </TagsContainer>
  )
}

const TagsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const TagButton = styled(Button)`
  color: ${() => AppStore.theme.o.blue};
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;

  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 165px;
`
