import ShortUrl from 'api/ShortUrl'
import { DEFAULT_URL, WHITELIST_URL_FUNCTIONALITIES_PROVIDERS } from 'utils/Constants/CreatePostConstants'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostData, IPostVariant, IUrl } from 'types/PostInterface'
import { T, showInfo, showWarning } from 'utils'
import {
  getActiveDescription,
  getActiveInfo,
  getActiveText,
  getFirstCommentData,
  getFirstCommentText,
  getPostData,
  updateActive,
  updatePost,
} from './Post'
import { isValidUrl } from 'utils/NetworkUtils'

//Metadati - UTM - Shortlink
export const findFirstDiff = (string, string2) => {
  const stringWords = string
  const string2Words = string2

  let index = 0
  for (const word of string2Words) {
    if (word !== stringWords[index]) {
      let diff = ''
      for (let i = index; i < string2Words.length; i++) {
        diff += string2Words[i]
        if (string2Words[i] === ' ' || string2Words[i] === '\n') {
          break
        }
      }
      return diff
    }
    index++
  }

  return ''
}

export const onPasteLink = async (config: {
  activePost: IPostVariant | IPostData
  newText: string
  firstComment?: boolean
  isDescription?: boolean
}) => {
  const { activePost, newText, firstComment, isDescription } = config
  const postData = getPostData(activePost)

  let orginUrl

  if (firstComment === undefined) {
    orginUrl = findFirstDiff(postData.text, newText).replace(/^\n+|\n+$/g, '')
  }

  if (firstComment === true) {
    orginUrl = findFirstDiff(postData.firstComment?.text ?? '', newText).replace(/^\n+|\n+$/g, '')
  }

  // verifica l'url sia valido
  if (!isValidUrl(orginUrl)) return

  showInfo(T.info.shortening_link)
  const results = await ShortUrl.getShortLinkAndMetadata(orginUrl)

  if (results === undefined) return showWarning(T.warning.notValidUrl)

  // Sostituisce "originUrl" con "results.shortUrl" in copyDescription
  const updatedCopyDescription = newText.replace(orginUrl, results.shortUrl)

  const utmConfig = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_term: '',
    utm_content: '',
  }

  const metaData = {
    ogUrl: results?.metaData?.ogUrl,
    ogSiteName: results?.metaData?.ogSiteName,
    ogTitle: results?.metaData?.ogTitle,
    ogDescription: results?.metaData?.ogDescription,
    ogImg: results?.metaData?.ogImg,
    visible: true,
  }

  const newUrlProperty: IUrl = {
    id: results._id,
    shortUrl: results.shortUrl,
    originalUrl: results.origUrl,
    shortened: true,
    utmConfig,
    metaData,
  }

  //Testo normale (sezione preview)
  if (firstComment === undefined) {
    const updateVisibilyMetadatas =
      postData.urls?.length > 0
        ? postData.urls?.map((url) => ({
            ...url,
            metaData: { ...url.metaData, visible: false },
          }))
        : []

    const updateValue = !isDescription ? { text: updatedCopyDescription } : { description: updatedCopyDescription }
    updateActive({ ...updateValue, urls: [...updateVisibilyMetadatas, newUrlProperty] })
  }

  //Testo im first comment (sezione extra)
  if (firstComment === true) {
    if ('variantId' in activePost) {
      const updateVisibilyMetadatas =
        postData.firstComment?.urls?.length > 0
          ? postData.firstComment.urls?.map((url) => ({
              ...url,
              metaData: { ...url.metaData, visible: false },
            }))
          : []

      updatePost(activePost.variantId, {
        firstComment: { text: updatedCopyDescription, urls: [...updateVisibilyMetadatas, newUrlProperty] },
      })
    }
  }
}

// Ritorna il primo metadata visible
export const findVisibleMetadata = (post: IPostData | IPostVariant, firstComment?: boolean): any => {
  if (post === undefined) return

  if (firstComment === undefined && (post as IPostData).urls) {
    if ('_id' in post && post._id === 'GLOBAL') return (post as IPostData).urls?.filter((url) => url.metaData.visible ?? true)
    if ('variantId' in post) return post.data.urls?.filter((url) => url.metaData.visible ?? true)
  }

  if (firstComment === true ) {
    if ('variantId' in post) return post.data.firstComment?.urls?.filter((url) => url.metaData.visible ?? true)
  }

  return []
}

// Di base ritorna l'ultimo url che trova
export const findWorkingUTM = (post: IPostData | IPostVariant, selctedIndex: number, firstComment?: boolean) => {
  if (firstComment === undefined) {
    if ('variantId' in post) return post.data.urls && post.data.urls[selctedIndex]
    if ('_id' in post && post._id === 'GLOBAL') return post.urls && post.urls[selctedIndex]
  }

  if (firstComment === true) {
    if ('variantId' in post) return post.data.firstComment?.urls && post.data.firstComment?.urls[selctedIndex]
  }

  return DEFAULT_URL
}

// Ritorna link completo con i valori utm
export const newUtmUrl = (visibleLink: IUrl, utmValues: any) => {
  try {
    const origUrl = visibleLink.originalUrl ?? ''
    // check if url already has utm params then replace them with the key
    const url = new URL(origUrl)
    const params = new URLSearchParams(url.search)
    const keys = Object.keys(utmValues)
    keys.forEach((key) => {
      params.delete(key)
    })

    // add new params
    keys.forEach((key) => {
      if (utmValues[key] && utmValues[key] !== '') {
        params.append(key, utmValues[key])
      }
    })
    url.search = params.toString()
    return url.toString()
  } catch (error) {
    return visibleLink.originalUrl
  }
}

export const converUtmConfig = (url: IUrl) => {
  return {
    utm_source: url?.utmConfig?.utm_source,
    utm_medium: url?.utmConfig?.utm_medium,
    utm_campaign: url?.utmConfig?.utm_campaign,
    utm_term: url?.utmConfig?.utm_term,
    utm_content: url?.utmConfig?.utm_content,
  }
}

//@refactoring da fare
export const setShortenedLink = (
  post: IPostData | IPostVariant,
  shortValue: boolean,
  visibleLink,
  firstComment?: boolean,
  isDescription?: boolean
) => {
  if (firstComment === undefined) {
    if ('_id' in post && post._id === 'GLOBAL') {
      const newUrls = (post as IPostData).urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      //@refactoring da fare
      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl

      const editedText = currentText?.replace(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls })
    }

    if ('variantId' in post) {
      const newUrls = post.data.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      const currentText = !isDescription ? getActiveText() : getActiveDescription()
      const valueToReplace = visibleLink.shortened ? visibleLink.shortUrl : visibleLink.originalUrl
      const newValue = visibleLink.shortened ? visibleLink.originalUrl : visibleLink.shortUrl
      const editedText = currentText?.replace(valueToReplace, newValue)
      const updateValue = !isDescription ? { text: editedText } : { description: editedText }
      updateActive({ ...updateValue, urls: newUrls })
    }
  }

  if (firstComment === true) {
    if ('variantId' in post) {
      const newUrls = post.data.firstComment?.urls?.map((url) => {
        if (url.id === visibleLink.id) {
          return { ...url, shortened: shortValue }
        }
        return url
      })

      const actualLinkValue = visibleLink.shortened
        ? visibleLink.shortUrl
        : newUtmUrl(visibleLink, converUtmConfig(visibleLink))

      const newText = visibleLink.shortened
        ? getFirstCommentText(post)?.replace(actualLinkValue, newUtmUrl(visibleLink, converUtmConfig(visibleLink)))
        : getFirstCommentText(post)?.replace(actualLinkValue, visibleLink.shortUrl)

      updatePost(post.variantId, { firstComment: { text: newText, urls: newUrls } })
    }
  }
}

export const getLinkProperties = (post?: IPostVariant) => {
  if (post === undefined) {
    return getActiveLinkProperties()
  }
  if (post !== undefined) {
    return getLinkPropertiesFirstComment(post)
  }
}

export const getActiveLinkProperties = () => {
  const { activePost } = useCreatePostStore.getState()

  if (activePost === undefined) return []

  if ('_id' in activePost && activePost._id === 'GLOBAL') return (activePost as IPostData).urls ?? []

  if ('variantId' in activePost) return activePost.data.urls ?? []

  return []
}

export const getLinkPropertiesFirstComment = (post: IPostVariant) => {
  return post.data.firstComment?.urls ?? []
}

// Il parametro "post" viene passato solo quando si lavora in un post specifico nello specifico quando lavoriamo nel primo commento, altrimenti se si lavora nella sezione di preview si utilizza il post attivo
export const canShowMetadata = (post?: IPostVariant) => {
  if (post !== undefined) {
    const firstMetadata = findVisibleMetadata(post, true)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      post.info.account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS.includes(post.info.account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }

  if (!post) {
    const { activePost } = useCreatePostStore.getState()

    const firstMetadata = findVisibleMetadata(activePost)
    const visibleLink = firstMetadata ? firstMetadata[0] : undefined

    return (
      getActiveInfo().account &&
      WHITELIST_URL_FUNCTIONALITIES_PROVIDERS.includes(getActiveInfo().account.provider) &&
      visibleLink &&
      visibleLink.metaData.ogTitle !== undefined
    )
  }
}
