/* ------------------- IMPORTS */
import { SocialType } from 'api/Utils'
import { Icons } from 'components'
import { EmptyState, GridContainer } from 'components/UI'
import { SocialAccountsAddable } from 'pages/create-post/Components/SocialAccountsAddable'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { SocialAccount } from 'types'
import { IDataReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { AppStore, T, capitalizeFirstLetter } from 'utils'

/* ------------------- INTERFACES  */
interface Props {
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReportSocials = ({ reportConfig, setReportConfig }: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  /* ----------------- STATE  */
  const [socialSelected, setSocialSelected] = useState<SocialType>(useAnalyticsStore.getState().selectedSocial)

  /* ------------------- ZUSTAND SUBSCRIPTION  */
  const unsubSocialSelected = useAnalyticsStore.subscribe((state) => {
    state.selectedSocial !== socialSelected && setSocialSelected(state.selectedSocial)
  })

  /* ----------------- METHODS  */
  const onClickSocialCard = (account: SocialAccount) => {
    const selectedIndex = reportConfig.data.findIndex((data) => data.provider === account.provider)

    const updatedReportConfig = { ...reportConfig }

    if (selectedIndex === -1) {
      // Remove any existing account from the same provider
      updatedReportConfig.data = updatedReportConfig.data.filter(
        (data: any) => data.provider !== account.provider
      ) as IDataReport[]

      const data: IDataReport = {
        account: account._id,
        provider: account.provider,
        metrics: [],
      }
      updatedReportConfig.data.push(data)
    } else if (updatedReportConfig.data[selectedIndex].account !== account._id) {
      // If a different account from the same provider is selected, replace it
      updatedReportConfig.data.splice(selectedIndex, 1)

      // @gian-any
      const data: any = {
        account: account._id,
        provider: account.provider,
        metrics: [],
      }

      updatedReportConfig.data.push(data)
    } else {
      // Deselect the account if it's already selected
      updatedReportConfig.data.splice(selectedIndex, 1)
    }

    setReportConfig(updatedReportConfig)
  }

  /* ----------------- RENDER VARIABLES  */
  const socialProfiles = AppStore.socialAccounts
    .filter((account) => account.provider === socialSelected)
    .map((account, index) => (
      <SocialAccountsAddable
        account={account}
        key={index}
        onClick={onClickSocialCard}
        fullWidth
        isSelected={reportConfig.data.some((data) => {
          return data.account === account._id
        })}
      />
    ))

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    return () => {
      unsubSocialSelected()
    }
  }, [])

  return (
    <>
      {/* Lista dei social da selezionare  */}
      {AppStore.socialAccounts.filter((account) => account.provider === socialSelected).length > 0 && (
        <GridContainer cols={3} data={socialProfiles} colGap={10} rowGap={10} width={902} marginTop={48} />
      )}

      {/* Empty state del social  */}
      {AppStore.socialAccounts.filter((account) => account.provider === socialSelected).length === 0 && (
        <EmptyState
          text={T.analytics.noSocialProfilesConnectedTo + ' ' + capitalizeFirstLetter(socialSelected)}
          icon={<Icons.sadBall />}
          fill
          textButton={T.analytics.addProfile}
          action={() => navigate(`/settings/social`)}
        />
      )}
    </>
  )
}
