/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { GetDemoButton } from './GetDemoButton'

/* ------------------- INTERFACES  */

interface Props {}

export const EnterpriseContactUs = ({}: Props) => {
  return (
    <EnterpriseContactUsContainer>
      <Icons.enterpriseCity width={40} height={40} />
      <TextContainer>
        <Title>{T.plans.enterprise.speakWithOurTeam}</Title>
        <Par>{T.plans.enterprise.enterpriseDiscoverText}</Par>
      </TextContainer>
      <GetDemoButton />
    </EnterpriseContactUsContainer>
  )
}

const EnterpriseContactUsContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  margin: 24px 4px;
  padding: 16px 42px;
  align-self: stretch;
  width: 600px;
  height: 480px;
  gap: 24px;
  border: 1px dashed ${AppStore.theme.o.darkGrey};
`

const TextContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const Title = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`

const Par = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;
  font-size: 16px;
  font-weight: bold;
`
