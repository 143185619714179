/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T } from 'utils'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { Tags } from 'components/CreatePost/ExtraComponents/InstagramExtras/Tags'
import { useState } from 'react'
import { openPiningModal } from 'components/Modal/ModalManager'
import handsIcon from '../../../../../../../../src/assets/instagram_preview_icons/hands_icon.png'
import heartIcon from '../../../../../../../../src/assets/instagram_preview_icons/heat_icon.png'

/* ------------------- INTERFACES  */
interface Props {}

export const ResultsPreviewInstagramStandard = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  const [notClosableModal, setNotClosableModal] = useState(false)

  return (
    <ResultsPreviewStandardContainer>
      <PorfileContainer>
        <ProfileImg src={activePost?.info?.account?.picture} />

        <ProfileName>{activePost?.info?.account?.name}</ProfileName>
      </PorfileContainer>

      <MediaContainerPost width={'98%'} height={390} arrowsPositions={20} />

      {activePost?.data?.medias?.length > 0 && (
        <TagLabel onClick={() => openPiningModal(activePost, setNotClosableModal)}>
          <Icons.user fill={AppStore.theme.o.black} width={24} /> {T.postPage.extraTag}
        </TagLabel>
      )}

      <IconsReactionsContainer>
        <Icons.instagramLike fill={AppStore.theme.o.black} />
        <Icons.instagramComment fill={AppStore.theme.o.black} />
        <Icons.instagramShare fill={AppStore.theme.o.black} />
        <Icons.instagramBookMark fill={AppStore.theme.o.black} style={{ marginLeft: 'auto' }} />
      </IconsReactionsContainer>

      <FooterPreview>
        <Likes>12,542 {T.postPage.likes}</Likes>

        <PreviewText>
          {activePost?.data?.text?.length > 100 ? (
            <>
              <ProfileNameSecondary>{activePost?.info?.account?.name}</ProfileNameSecondary>
              {activePost?.data?.text?.slice(0, 100)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
            </>
          ) : (
            <>
              <ProfileNameSecondary>{activePost?.info?.account?.name}</ProfileNameSecondary>
              {activePost?.data?.text}
            </>
          )}
        </PreviewText>

        <Comments>
          {T.postPage.viewAll} 123 {T.postPage.comments}
        </Comments>

        <FooterProfile>
          <RightSection>
            <ProfileImgSecondary src={activePost?.info?.account?.picture} />
            {T.postPage.addComent}
          </RightSection>

          {/* //TODO: Le png vanno riviste, si vedono male, anche l'icona e da chiedere una corretta */}
          <LeftSection>
            {/* <IconsImg src={handsIcon} />
            <IconsImg src={heartIcon} /> */}
            {/* <Icons.addInstagram width={40} /> */}
          </LeftSection>
        </FooterProfile>

        <DatePost>6 {T.postPage.daysAgo}</DatePost>
      </FooterPreview>
    </ResultsPreviewStandardContainer>
  )
}

const ResultsPreviewStandardContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${() => AppStore.theme.o.grey};
  position: relative;
`

const PorfileContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 5px 10px;
`

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

const ProfileName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
`

const IconsReactionsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10.5px;
  gap: 13px;
`

const TagLabel = styled(Box)`
  font-size: 12px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 4px 12px;
  position: absolute;
  top: 390px;
  right: 20px;
  background: ${() => AppStore.theme.o.grey};
  border: 1px dotted ${() => AppStore.theme.o.black};
  font-weight: 700;
  height: 28px;

  &:hover {
    border: 1px dotted ${() => AppStore.theme.o.lightGrey};
  }
`

const FooterPreview = styled(Box)`
  display: flex;
  padding: 10.5px;
`

const Likes = styled(Box)`
  margin-bottom: 5px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
`

const ProfileNameSecondary = styled.span`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
  margin-right: 8px;
`

const Comments = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 130.023% */
`

const FooterProfile = styled(Box)`
  margin: 10.5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const RightSection = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.002px; /* 130.023% */
`

const LeftSection = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10.5px;
`

const DatePost = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10.402px; /* 130.023% */
`

const PreviewText = styled(Box)`
  margin-bottom: 10.8px;
  color: ${() => AppStore.theme.o.black};
  border-radius: 0;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
  word-wrap: break-word;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
`

const ProfileImgSecondary = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const IconsImg = styled.img`
  width: 100%;
  height: 100%;
`
