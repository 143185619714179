/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { Box } from 'components'
import { LanguageBox } from 'components/UI'
import { AppStore } from 'utils'

/* ____________________ MODALS ____________________ */
/**
 *  ### Apre modale per selezionare la lingua
 * @param outputLanguage Stato valore della lingua
 * @param setOutputLanguage Funzione per cambiare valore allora stato del valore della lingua
 * @param fromInputSearchbar Serve per gestire il focus mentre si è all'interno del input searchbar
 */
export const openLanguageSelectModal = (outputLanguage, setOutputLanguage, fromInputSearchbar = false) => {
  const modalBody = () => (
    <LanguageSelectModal
      outputLanguage={outputLanguage}
      setOutputLanguage={setOutputLanguage}
      fromInputSearchbar={fromInputSearchbar}
    />
  )

  AppStore.openModal({
    id: 'language-modal',
    body: modalBody(),
    style: { zIndex: 1001 },
    width: '1210',
    closeIcon: true,
  })
}

/* ******************* INTERFACE ******************* */

interface Props {
  outputLanguage: any
  setOutputLanguage: any
  fromInputSearchbar?: boolean
}

export const LanguageSelectModal = ({ outputLanguage, setOutputLanguage, fromInputSearchbar }: Props) => {
  return (
    <LanguageSelectModalContainer id={`${fromInputSearchbar ? 'languageBoxInput' : ''}`}>
      <LanguageContent>
        <LanguageBoxStyle>
          <LanguageBox
            fromLanguagrSelectModal={true}
            InputOutput={false}
            onClick={(e) => setOutputLanguage(e)}
            language={outputLanguage}
          />
        </LanguageBoxStyle>
      </LanguageContent>
    </LanguageSelectModalContainer>
  )
}

const LanguageSelectModalContainer = styled(Box)`
  display: flex;
  width: auto;
  height: 470px;
`

const LanguageContent = styled(Box)`
  display: flex;
  flex-direction: row;
  min-height: 300px;
  padding: 32px;
  background-color: '${() => AppStore.theme.o.surface}';
`

const LanguageBoxStyle = styled(Box)`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: ${() => AppStore.theme.o.surface};
  border: 2px solid ${() => AppStore.theme.o.lightGrey};
`
