import { Box, Icons } from 'components'
import { Component } from 'react'
import PinGsap from './PinGsap'

interface Props {
  moveCarousel: (direction: number) => void
}

export default class BackwardForwardIcons extends Component<Props> {
  render() {
    const { moveCarousel } = this.props
    return (
      <>
        <Box
          removeHover
          onClick={() => moveCarousel(-1)}
          id={'leftArrow'}
          style={{
            position: 'absolute',
            top: 360,
            left: '-150px',
            borderRadius: '50%',
            minWidth: 120,
            minHeight: 120,
          }}
          center
          onHoverStart={() => {
            PinGsap.leftArrow(true)
          }}
          onHoverEnd={() => {
            PinGsap.leftArrow(false)
          }}
        >
          <Icons.backward style={{ minWidth: 30, minHeight: 40 }} />
        </Box>
        <Box
          id={'rightArrow'}
          onHoverStart={() => {
            PinGsap.rightArrow(true)
          }}
          onHoverEnd={() => {
            PinGsap.rightArrow(false)
          }}
          removeHover
          onClick={() => moveCarousel(1)}
          style={{
            position: 'absolute',
            top: 360,
            right: '-150px',
            borderRadius: '50%',
            minWidth: 120,
            minHeight: 120,
          }}
          center
        >
          <Icons.forward style={{ minWidth: 30, minHeight: 40 }} />
        </Box>
      </>
    )
  }
}
