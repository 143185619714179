import { AppstoreAddOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'

import { Box, Button, SocialAccountRow, Text } from 'components'
import gsap from 'gsap'
import React, { Component } from 'react'
import { AppStore, T, showInfo } from 'utils'

export default class TutorialModal extends Component {
  render() {
    const expiryDates = [
      {
        name: T.common.socials.facebook,
        date: 60,
      },
      {
        name: T.common.socials.instagram,
        date: 60,
      },
      {
        name: T.common.socials.linkedin,
        date: 365,
      },
    ]

    return (
      <Container center pv={24} ph={64}>
        <Text
          style={{
            fontWeight: 700,
            fontSize: 21,
            color: AppStore.theme.o.blueOnLight,
            marginBottom: 28,
          }}
        >
          {T.updateSocial.how_to_update}
        </Text>

        <SecondatyText>{T.updateSocial.onese_closed}</SecondatyText>
        <Box mv={28} width={300} onClick={() => showInfo(T.info.onlyDemo)}>
          <Box style={{ pointerEvents: 'none' }}>
            <SocialAccountRow account={AppStore.socialAccounts[0]} key={'demo'} onDelete={() => {}} forceFailed />
          </Box>
        </Box>

        <SecondatyText>{T.updateSocial.clik_on_icon}</SecondatyText>

        <Box style={{ border: '1px dotted black', gap: 8 }} mt={12} mb={24} pv={8} ph={24}>
          {expiryDates.map((exp, ind) => (
            <SecondatyText>
              {exp.date} {T.updateSocial.days_to} {exp.name}
            </SecondatyText>
          ))}
          <SecondatyText>{T.updateSocial.change_pass} </SecondatyText>
        </Box>

        <SecondatyText style={{ textDecorationLine: 'underline', fontSize: 12 }}>
          <a
            style={{ textDecorationLine: 'underline', fontSize: 12, color: AppStore.theme.o.black }}
            target="_blank"
            href={
              localStorage.getItem('defaultLang') === 'it'
                ? 'https://help.asters.ai/it/articles/380314-il-mio-profilo-social-si-e-scollegato-da-asters-come-ricollegarlo'
                : 'https://help.asters.ai/en/articles/380314-il-mio-profilo-social-si-e-scollegato-da-asters-come-ricollegarlo'
            }
          >
            {T.header.whyHaveToUpdate}
          </a>
        </SecondatyText>
        <ButtonAbsolute
          onClick={() => {
            try {
              localStorage.setItem('TutorialToken', 'true')
              AppStore.closeAllModal()
            } catch (e) {
              console.error(e)
            }
          }}
          center
          onHoverStart={() => {
            gsap.to('#ok', {
              scale: 1.05,
              duration: 0.05,
            })
          }}
          onHoverEnd={() => {
            gsap.to('#ok', {
              scale: 1,
              duration: 0.01,
            })
          }}
          id="ok"
        >
          <ButtonText>{T.updateSocial.ok_understood} </ButtonText>
        </ButtonAbsolute>
      </Container>
    )
  }
}

const ButtonText = styled.p`
  font-size: 14px;
  pointer-events: none;
  font-weight: 700;
  color: ${AppStore.theme.o.blueOnLight};
`

const ButtonAbsolute = styled(Box)`
  position: absolute;
  bottom: -64px;
  border-radius: 14px;
  height: 46px;
  min-width: 181px;
  background-color: white;
  :hover {
    background-color: ${AppStore.theme.o.lightBlue};
  }
`

const SecondatyText = styled((props) => <Text {...props} />)`
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: ${AppStore.theme.o.black};
`

const Container = styled(Box)`
  min-width: 660px;
  min-height: 541px;
  position: relative;
`
