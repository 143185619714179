import { Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { Box, Button, Text } from 'components'
import React from 'react'
import { AppStore, T } from 'utils'
import FilterModal from './FilterModal'
import { set } from 'lodash'

interface Props {
  search: string
  medias: any
  getMediaName: any
  onChangeSelect: (index: number) => void
  sortTypes: any
  filters: [{ item: string }] | undefined
  setFilters: (value: [{ item: string }]) => void
}

export default function MediaSort(props: Props) {
  const { search, medias, getMediaName, onChangeSelect, sortTypes, filters, setFilters } = props

  const openFilterModal = () => {
    AppStore.openModal({
      id: 'preview-modal',
      body: <FilterModal filters={filters} setFilters={setFilters} />,
      style: {
        minWidth: 529,
      },
    })
  }

  return (
    <>
      {medias.filter((m) => m.type === 'file' && getMediaName(m.name).includes(search)).length > 0 && (
        <Box style={{ width: 'auto' }} row vcenter>
          <Box flex>
            <Text style={{ padding: 10 }} fontSize={21} weight={700}>
              {T.mediaPage.file}
            </Text>
          </Box>

          <Box id="sort" width={215} mr={32}>
            <Select
              placeholder={T.mediaPage.fromLatest}
              onChange={async (index) => {
                onChangeSelect(index)
              }}
              style={{ borderRadius: 700 }}
            >
              {sortTypes.map((item, index) => (
                <Option style={{ marginBottom: 4 }} value={`${index}`}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Box>
          <Box width={178} mr={8} center>
            <Button
              variant="neutral"
              style={{ fontSize: 16 }}
              onClick={() => {
                openFilterModal()
              }}
            >
              {T.mediaPage.addFilters}
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
