/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button } from 'components'
import {
  AnalyticsDate,
  IDataReport,
  IReport,
  IReportConfig,
  ISelectableSocial,
  TSubsection,
} from 'types/AnalyticsInferfaces'
import { AppStore, showError, T } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import Reports from 'api/Reports'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { PreviewReport } from '../PreviewReport/PreviewReport'
import { dateRange28DaysAgo } from 'utils/AnalyticsUtils'

/* ------------------- INTERFACES  */
interface Props {
  subsection: TSubsection
  reportConfig: IReportConfig
  setReportConfig: (reportConfig: IReportConfig) => void
}

export const CreateReportFooter = ({ subsection, reportConfig, setReportConfig }: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()
  const { reportId } = useParams()

  /* ----------------- ZUSTAND  */
  const { reportPreview, setReportPreview } = useAnalyticsStore.getState()

  /* ----------------- STATE  */
  const [hasReportNameValue, setHasReportNameValue] = useState(false)
  const [hasSocialValue, setHasSocialValue] = useState(false)
  const [hasMetricValue, setHasMetricValue] = useState(false)

  /* ----------------- METHODS  */
  const checkSetup = async () => {
    const reportName = reportConfig.info?.reportName
    if (!reportName || reportName.trim() === '') {
      setHasReportNameValue(false)
      return
    }

    setHasReportNameValue(true)
  }

  const checkSocials = () => {
    if (reportConfig.data.length >= 1) {
      setHasSocialValue(reportConfig.data.some((dataItem) => dataItem.account))
    } else {
      setHasSocialValue(false)
    }
  }

  const checkMetrics = () => {
    if (reportConfig.data.length > 0) {
      setHasMetricValue(
        (reportConfig.data as IDataReport[]).every((dataItem) => dataItem.metrics && dataItem.metrics.length > 0)
      )
    }
  }

  // ------- METHODS ON BUTTON CLICK
  const onClickSetupBtn = async () => {
    // Check if report name already exists in active workspace
    const checkReportNameExist = !(await Reports.checkReportName(reportConfig.info.reportName ?? ''))

    if (!reportPreview && checkReportNameExist) {
      // se non si modifica un report ed il nome già esiste
      showError('Report name already exists')
      return
    } else if (
      // se si modifica un report ed il nome già esiste
      reportPreview &&
      reportPreview.info.reportName !== reportConfig.info.reportName &&
      checkReportNameExist
    ) {
      showError('Report name already exists')
      return
    }

    navigate(`/analytics/createReport${reportId ? `/${reportId}` : ''}`, { state: { subsection: 'social' } })

    // set report logo to undefined if not chose
    if (reportConfig.info.reportLogo === '') {
      setReportConfig({
        ...reportConfig,
        info: {
          ...reportConfig.info,
          reportLogo: undefined,
        },
      })
    }
  }

  const onClickSocialBtn = () => {
    navigate(`/analytics/createReport${reportId ? `/${reportId}` : ''}`, { state: { subsection: 'metrics' } })
  }

  const onClickMetricBtn = async () => {
    const selectedDatesReports = useAnalyticsStore.getState().selectedDatesReports

    const defaultRange28DaysAgo = dateRange28DaysAgo()

    let sinceDate = defaultRange28DaysAgo[0]
    if (selectedDatesReports !== null && selectedDatesReports.startDate !== null) {
      const start = selectedDatesReports.startDate
      sinceDate = `${start.year()}-${String(start.month() + 1).padStart(2, '0')}-${String(start.date()).padStart(
        2,
        '0'
      )}`
    }

    let untilDate = defaultRange28DaysAgo[1]
    if (selectedDatesReports !== null && selectedDatesReports.endDate !== null) {
      const end = selectedDatesReports.endDate
      untilDate = `${end.year()}-${String(end.month() + 1).padStart(2, '0')}-${String(end.date()).padStart(2, '0')}`
    }

    reportConfig.info.createdBy = AppStore.loggedUser._id

    const idToModifyReport = reportPreview?._id ?? reportId

    const newReportConfig = {
      ...reportConfig,
      data: reportConfig.data.map((data) => {
        return {
          ...data,
          since: reportConfig.since ?? sinceDate,
          until: reportConfig.until ?? untilDate,
        }
      }),
      since: reportConfig.since ?? sinceDate,
      until: reportConfig.until ?? untilDate,
    }

    setReportPreview(null)

    navigate(`/analytics/report`, { state: { newReportConfig, idToModifyReport, subsection: 'loading' } })
  }

  /* ----------------- ENUM  */
  const enumCheck = {
    setup: checkSetup,
    social: checkSocials,
    metrics: checkMetrics,
  }

  const buttonType = {
    setup: (
      <ReportButton
        className="report_button"
        variant={hasReportNameValue ? 'primary' : 'neutral'}
        disabled={!hasReportNameValue}
        onClick={onClickSetupBtn}
      >
        {T.singleWord.next}
      </ReportButton>
    ),
    social: (
      <ReportButton
        className="report_button"
        variant={hasSocialValue ? 'primary' : 'neutral'}
        disabled={!hasSocialValue}
        onClick={onClickSocialBtn}
      >
        {T.singleWord.next}
      </ReportButton>
    ),
    metrics: (
      <ReportButton
        className="report_button"
        variant={hasMetricValue ? 'primary' : 'neutral'}
        disabled={!hasMetricValue}
        //Attualmente porta ad uno dei report creati mockati, poi da cambiare
        onClick={onClickMetricBtn}
      >
        {T.singleWord.confirm}
      </ReportButton>
    ),
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    enumCheck[subsection]()
  })

  return (
    <CreateReportFooterContainer className="create_report_footer_container">
      {buttonType[subsection]}
    </CreateReportFooterContainer>
  )
}

const CreateReportFooterContainer = styled(Box)`
  &.create_report_footer_container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
`

const ReportButton = styled(Button)`
  &.report_button {
    padding: 12px 78px !important;
    font-size: 16px;

    font-style: normal;
    font-weight: 700;
    line-height: normal;
    min-height: 43px;
  }
`
