/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, ZoomedContainer } from 'components'
import { MediaContainer } from 'pages/media/MediaContainer'
import { Media } from 'types'
import { AppStore, T } from 'utils'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  onMediaSelected: (medias: Media[]) => void
  selectedMedias: Media[]
  context?:string[]
}

export const PostMediaModel = ({ onMediaSelected, selectedMedias,context }: Props) => {
  /* ----------------- STATE  */

  /* ----------------- REF  */

  /* ----------------- ZUSTAND  */

  /* ----------------- VARIABLES  */
  let newSelectedMedias: Media[] = selectedMedias ?? []

  /* ----------------- METHODS  */

  /* ----------------- API CALL  */

  /* ----------------- USEEFFECT  */

  return (
    <ZoomedContainer center flex style={{ position: 'relative', padding: '16px 12px', maxWidth: 1800 }}>
      <div
        style={{
          flex: 1,
          overflow: 'auto',
          minWidth: '95%',
          backgroundColor: 'transparent',
          maxHeight: 700,
          minHeight: 650,
        }}
      >
        <MediaContainer
          preselectedMedia={selectedMedias}
          onChangeMedias={(medias) => {
            newSelectedMedias = medias
          }}
          context={context}
          isModal
        />
      </div>
      <InsertContainer>
        <Button
          height={65}
          width={220}
          onClick={() => {
            onMediaSelected(newSelectedMedias)
            AppStore.closeAllModal()
          }}
          variant="primary"
        >
          {T.settings.insert}
        </Button>
      </InsertContainer>
    </ZoomedContainer>
  )
}

const PostMediaModelContainer = styled(Box)``
const InsertContainer = styled(Box)`
  width: 100px;
  position: absolute;
  bottom: -80px;
  right: 122px;
`
