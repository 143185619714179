import { ModalType } from 'types'
import { create } from 'zustand'

export enum PublishJourneySection {
  preview = 0,
  textGeneration = 1,
  medias = 2,
  extras = 3,
  socials = 4,
}

// @todo - spostare in altro file
// @todo - brutto | migliorare e mettere un listener per gestire in automatico da url il valore
export enum PublishInnerJourneySection {
  null = -1,

  preview_create = 0,
  preview_drafts = 1,
  preview_template = 2,

  media_selected = 3,
  media_selector = 4,
  media_editor = 5,

  ai_generation = 0,
  text_generation_templates = 1,
}

export enum ModalTypeEnum {
  MEDIA_EDITOR,
  MEDIA_SELECTOR,
}
/**
 * @todo - Documentazione
 */
interface JourneyStore {
  socialAccounts: any[]
  setSocialAccounts: (socialAccounts: any[]) => void

  publishPostSection: PublishJourneySection
  publishPostInnerSection: PublishInnerJourneySection

  navigatePublishPost(section: PublishJourneySection, innerSection: PublishInnerJourneySection): void
  setPublishPostSection(section: PublishJourneySection): void
  setPublishPostInnerSection(innerSection: PublishInnerJourneySection): void
  resetPublishPost(): void

  // @TEST - test sulla gestione tramite tipo
  openedModal: { type: ModalTypeEnum; params?: any } | undefined
  openModal: (modal?: { type: ModalTypeEnum; params?: any } | undefined) => void
  closeAllModals: () => void

  // send link from discover to generate post
  linkToGeneratePost: string | undefined
  setLinkToGeneratePost: (link?: string) => void

  // roba brutta per fix diretti su prod
  reusablePost: any | undefined
  setReusablePost: (post?: any) => void
}

export const useJourneyStore = create<JourneyStore>((set, get) => ({
  socialAccounts: [],
  setSocialAccounts: (socialAccounts) => {
    set(() => ({ socialAccounts }))
  },

  publishPostSection: 0,
  publishPostInnerSection: 0,

  navigatePublishPost: (section, innerSection) => {
    set(() => ({ publishPostSection: section, publisPostInnerSection: innerSection }))
  },
  setPublishPostSection: (section) => {
    set(() => ({ publishPostSection: section }))
  },
  setPublishPostInnerSection: (innerSection) => {
    set(() => ({ publishPostInnerSection: innerSection }))
  },
  resetPublishPost: () => {
    set(() => ({ publishPostSection: 0, publishPostInnerSection: 0 }))
  },

  // --- modals
  openedModal: undefined,
  openModal: (modal) => {
    set(() => ({ openedModal: modal }))
  },
  closeAllModals: () => set(() => ({ openedModal: undefined })),

  // send link from discover to generate post
  linkToGeneratePost: undefined,
  setLinkToGeneratePost: (link = undefined) => {
    set(() => ({ linkToGeneratePost: link }))
  },

  reusablePost: undefined,
  setReusablePost: (post = undefined) => {
    set(() => ({ reusablePost: post }))
  },
}))
