/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useState } from 'react'
import { AppStore, T } from 'utils'
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
import { ThemeProvider } from '@mui/material/styles'
import dayjs, { Dayjs } from 'dayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts'
import { DateRange } from '@mui/x-date-pickers-pro'
import { localDate, localLang } from 'utils/MuiUtils/MuiTranslations'
import { muiTheme } from 'utils/MuiUtils/MuiThemes'
import { CloseButton } from 'components/UI'
import { AnalyticsDate } from 'types/AnalyticsInferfaces'

/* ------------------- INTERFACES  */
interface Props {
  selectedDate: AnalyticsDate | null
  setSelectedDate: (dates: AnalyticsDate | null | '28dayAgo') => void
  reportDate: string[] | any[]
}

export const DateRangeFilter = ({ reportDate, selectedDate, setSelectedDate }: Props) => {
  /* ----------------- STATE  */
  //Stato per definire se è aperto il date picker
  const [openRangePicker, setOpenRangePicker] = useState<boolean>(false)

  //Stato per resettare il valore di input nel componente di Mui
  const [resetKey, setResetKey] = useState<number | null>(null)

  /* ----------------- VARIABLES  */
  // Variabile per controllo di style
  const hasSelectedDate: boolean =
    selectedDate !== null && selectedDate?.startDate !== undefined && selectedDate?.endDate !== undefined

  const openRangePickerClass = openRangePicker ? 'is-open' : ''
  const hasSelectedDateClass = hasSelectedDate ? 'has-selected-date' : ''

  // Lista dei giorni selezionabili come opzioni nel componente range date picker
  const dateRanges: PickersShortcutsItem<DateRange<Dayjs>>[] = [
    {
      label: T.date.today,
      getValue: () => {
        const today = dayjs()
        return [today.startOf('day'), today.endOf('day')]
      },
    },
    {
      label: T.date.yesterday,
      getValue: () => {
        const yesterday = dayjs().subtract(1, 'day')
        return [yesterday.startOf('day'), yesterday.endOf('day')]
      },
    },
    {
      label: T.date.last7Days,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(6, 'day'), today.endOf('day')]
      },
    },
    {
      label: T.date.lastMonth,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(29, 'day'), today.endOf('day')]
      },
    },
    /* 
    {
      label: T.date.last3Months,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(3, 'month'), today.endOf('day')]
      },
    },
       {
      label: T.date.last6Months,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(6, 'month'), today.endOf('day')]
      },
    },
    {
      label: T.date.last12Months,
      getValue: () => {
        const today = dayjs()
        return [today.subtract(12, 'month'), today.endOf('day')]
      },
    }, */
  ]

  /* ----------------- METHODS  */
  const handleAccept = (value: DateRange<Dayjs>) => {
    setSelectedDate({
      startDate: value[0],
      endDate: value[1],
    })
  }

  const handleReset = (e?: Event) => {
    e && e.stopPropagation()

    setSelectedDate('28dayAgo')

    setResetKey((prevKey) => (prevKey !== null ? prevKey + 1 : 1))
  }

  return (
    <RangePickerContainer className={`${openRangePickerClass} ${hasSelectedDateClass}`}>
      <ContainerDateShow className={`date-show ${selectedDate?.startDate && selectedDate.endDate ? '' : 'empty'}`}>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} localeText={localLang} adapterLocale={localDate}>
            <DateRangePicker
              defaultValue={[dayjs(reportDate[0] ?? ''), dayjs(reportDate[1] ?? '')]}
              // Definisce stile visualizzazione del input
              slots={{ field: SingleInputDateRangeField }}
              slotProps={{
                //Lista dei pulsanti
                shortcuts: {
                  items: dateRanges,
                },

                // Per cambiare il posizionamento del "tooltip" ossia i due calendari che si aprono
                popper: {
                  placement: 'bottom-start',
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        // positioni x e y
                        offset: [30, 15],
                      },
                    },
                  ],
                },
              }}
              key={resetKey} // key necessaria per il reset dei valori nel campo input
              onAccept={handleAccept}
              open={openRangePicker}
              onClose={() => setOpenRangePicker(false)}
              disableFuture //disabilitare i giorni futuri

              // defaultValue={} //da capire se si vuole un valore di defaul appena entranti
            />
          </LocalizationProvider>
        </ThemeProvider>
      </ContainerDateShow>

      <Icons.calendarPost
        className="calendar_icon"
        width={20}
        height={20}
        onClick={() => setOpenRangePicker((prev) => !prev)}
      />

      {/* Pulsante X per il reset del filtro delle date */}
      {hasSelectedDate && <CloseButton action={(e) => handleReset(e)} />}
    </RangePickerContainer>
  )
}

const RangePickerContainer = styled(Box)`
  cursor: pointer;
  position: relative;
  padding: 8px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  flex-direction: row;
  z-index: 1;
  transition: all 0.3s ease-in-out;

  .calendar_icon {
    fill: ${() => AppStore.theme.o.black};
    cursor: 'pointer';
  }

  .date-show {
    //animation open date filter
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    width: fit-content;
    max-width: 0;
    max-height: 20px;
    opacity: 0;
    display: inline-flex;
    z-index: 0;
  }

  &.is-open {
    .date-show {
      transition: all 0.3s ease-in-out;
      max-width: 400px;
      opacity: 1;

      div[class*='MuiFormControl-root'] {
        width: 200px;
      }
    }
  }

  &.has-selected-date {
    border: 1px solid ${() => AppStore.theme.o.green};
    background-color: ${() => AppStore.theme.o.lightGreen};

    .calendar_icon {
      fill: ${() => AppStore.theme.o.green};
    }
  }
`

const ContainerDateShow = styled(Box)`
  border-radius: 0;
  align-items: center;
`
