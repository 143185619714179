/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { AppStore, T } from 'utils'
import { pullFromGlobal } from 'utils/PostUtils/Post'

/* ------------------- INTERFACES  */
interface Props { }

export const GlobalPull = ({ }: Props) => {
  return (
    <GlobalPullContainer variant="neutral" size="smaller" onClick={() => pullFromGlobal()}>
      <Icons.characterCouter fill={AppStore.theme.o.black} style={{ width: 16, height: 16 }} />
      {T.DashboardPage.receve_from_global}
    </GlobalPullContainer>
  )
}

const GlobalPullContainer = styled(Button)`
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  border-radius: 14px;
  padding: 6px 16px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.black};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`
