import styled from '@emotion/styled'
import { Form, Radio } from 'antd'
import Utils from 'api/Utils'
import { BackwardIcon, Box, Input, Spacer, Text, Title, ZoomedContainer } from 'components'
import { Congratulation } from 'components/Congratulation'
import { useEffect, useState } from 'react'
import ReactGoogleAutocomplete from 'react-google-autocomplete'
import { T } from 'translation/i18n'

import { loadStripe } from '@stripe/stripe-js'
import SubscriptionAnimation from 'components/Animations/SubscriptionAnimation'
import { useNavigate } from 'react-router-dom'
import { CouponData } from 'types'
import { CustomerCard, SubscriptionPlanMode } from 'types/SettingsInterfaces'
import { AppStore, getPlanType, showError, sleep } from 'utils'
import AddOn from '../AddOn'
import CupponBox from '../CoupponBox'
import DetailBox from '../DetailBox'
import PaymentBox from '../PaymentBox'
import { CustomizeServiceOption, SubscriptionOption } from '../SubscriptionProvider'
import { SummaryBoxSubscription } from '../SummaryBoxSubscription'
import { OWNER_COPIES_EXTRA } from 'utils/Constants/LimitUserConstants'

interface Props {
  setActiveSubscriptionOption: (index: number) => void
  subscriptionMode: SubscriptionPlanMode
  subscriptionOptions: SubscriptionOption[]
  setSubscriptionMode: (index: number) => void
  additionalOptions: CustomizeServiceOption[]
  onNext: () => void
  onBack: () => void
  externalAddOn: boolean
  preselectedCuppon?: string
}

const SubscriptionPartThree = ({
  subscriptionMode,
  subscriptionOptions,
  setSubscriptionMode,
  additionalOptions,
  onBack,
  externalAddOn,
  preselectedCuppon,
}: Props) => {
  const navigate = useNavigate()

  // const [expiration, setExpiration] = useState<any>('')
  const [type, setType] = useState<'private' | 'business'>(
    AppStore.loggedUser.stripe?.billings?.vatNumber === undefined ? 'private' : 'business'
  )
  const [paying, setPaying] = useState<boolean>(false)
  const [customerCard, setCustomerCard] = useState<CustomerCard>({
    isError: true,
    number:
      AppStore.loggedUser.stripe?.billings?.cardNumber === undefined
        ? ''
        : AppStore.loggedUser.stripe?.billings?.cardNumber,
  })
  const [cardName, setCardName] = useState<any>('')
  const [privacyRead, setPrivacyRead] = useState<boolean>(false)
  // const [refresh, setRefresh] = useState<boolean>(true)
  const [name, setName] = useState<string | undefined>('')
  const [surname, setSurname] = useState<string | undefined>('')
  const [address, setAddress] = useState<string | undefined>('')
  const [province, setProvince] = useState<string | undefined>('')
  const [city, setCity] = useState<string | undefined>('')
  const [postalCode, setPostalCode] = useState<string | undefined>('')
  const [fiscalCode, setFiscalCode] = useState<string | undefined>('')
  const [ragioneSocial, setRagioneSocial] = useState<string | undefined>('')
  const [pIva, setPiva] = useState<string | undefined>('')
  const [spc, setSpc] = useState<string | undefined>('')
  const [additionalPrice, setAdditionalPrice] = useState<any>(0)
  const [couponCode, setCouponCode] = useState<string | undefined>('')
  const [loadingCoupon, setLoadingCoupon] = useState<boolean>(false)
  const [coupon, setCoupon] = useState<CouponData | undefined>(undefined)
  const [place, setPlace] = useState<any>(undefined)

  /* -------------------------- VARIABLES */
  const activeSubscriptionOption = subscriptionOptions.find((o) => o.active) as SubscriptionOption

  const addonPrice = additionalOptions.reduce((acc, curr) => acc + (curr.quantity ?? 0) * (curr.price ?? 0), 0)

  const monthPrice = activeSubscriptionOption.price.monthly
  const annualPrice = activeSubscriptionOption.offer?.cost
    ? activeSubscriptionOption.offer.cost / 12
    : activeSubscriptionOption.price.annual
  const offerO = activeSubscriptionOption.offer

  const monthTextColor = subscriptionMode === 'monthly' ? AppStore.theme.o.black : AppStore.theme.o.darkGrey
  const monthBorderColor = subscriptionMode === 'monthly' ? AppStore.theme.o.blueOnLight : AppStore.theme.o.lightGrey
  const annualTextColor = subscriptionMode === 'annual' ? AppStore.theme.o.black : AppStore.theme.o.darkGrey
  const annualBorderColor = subscriptionMode === 'annual' ? AppStore.theme.o.blueOnLight : AppStore.theme.o.lightGrey
  let total = (subscriptionMode === 'monthly' ? monthPrice : annualPrice) + addonPrice
  if (subscriptionMode === 'annual') {
    total = total * 12
  }

  let fullAddress

  if (AppStore.loggedUser.stripe?.billings?.address) {
    fullAddress =
      AppStore.loggedUser.stripe?.billings?.address +
      ', ' +
      AppStore.loggedUser.stripe?.billings?.city +
      ', ' +
      AppStore.loggedUser.stripe?.billings?.postalCode
  }

  const navigateToAddOn = () => {
    navigate(
      `/settings/subscription/addon?Hashtag=${AppStore.loggedUser.hashtags.available}&Text=${OWNER_COPIES_EXTRA}`
    )
  }

  /* -------------------------- MODALS */
  const openModal = () =>
    AppStore.openModal({
      id: 'addon-modal',
      onCancel: () => {
        //refresh page
        window.location.reload()
      },
      // onCancel: () => navigate('settings/subscription'),
      body: (
        <ZoomedContainer style={{ maxHeight: 550, maxWidth: 772 }}>
          <Box width={772} height={550} center vcenter>
            <Box width={108} height={108}>
              <Congratulation />
            </Box>
            <Text fontSize={21}>{T.settings.paymentDone}</Text>
            <Separator style={{ maxWidth: 600 }} />
            <Text fontSize={16}> {T.settings.wantToAddAi} </Text>
            <AddOn subscriptionOptions={subscriptionOptions} navigateTo={navigateToAddOn} />
          </Box>
        </ZoomedContainer>
      ),
      width: '772px',
    })

  /* -------------------------- METHODS */
  const addCoupon = async (e?: any) => {
    try {
      e?.preventDefault()
    } catch {}
    if (!couponCode || couponCode === '') return
    setLoadingCoupon(true)
    try {
      const getCoupon = await Utils.getCoupon(couponCode)
      if (!getCoupon || !getCoupon.valid) throw new Error('Coupon non attivo')

      if (!!getCoupon.amount_off) {
        const priceMonth = activeSubscriptionOption.price.monthly
        const PriceAnnual = activeSubscriptionOption.price.annual * 12
        const tot = (subscriptionMode === 'monthly' ? priceMonth : PriceAnnual) + addonPrice
        if (tot <= getCoupon.amount_off / 100) {
          throw new Error('Valore coupon maggiore del totale')
        }
      }

      SubscriptionAnimation.addCoupon('cupponSpawn')
      SubscriptionAnimation.increseWidth('cupponContainer', 42)

      setCoupon(getCoupon)
    } catch (error) {
      showError(error)
    }

    setLoadingCoupon(false)
  }

  const handleCardNumberChange = (e) =>
    setCustomerCard((prev) => {
      return { ...prev, number: e, isError: false }
    })

  const handleCardExpirationChange = (e) =>
    setCustomerCard((prev) => {
      return { ...prev, expiration: e, isError: false }
    })

  const handleCardCVCChange = (e) =>
    setCustomerCard((prev) => {
      return { ...prev, cvc: e, isError: false }
    })

  const privacyReed = (value) => setPrivacyRead(value)

  const onFinish = async (data) => {
    try {
      if (!AppStore.loggedUser.stripe?.billings) {
        if (!customerCard?.number || !customerCard?.expiration || !customerCard?.cvc || !place) {
          showError(T.error.allFildRequired)
          return false
        }
      }

      if (!privacyRead) {
        showError(T.error.acceptPrivay)
        return false
      }
      setPaying(true)

      if (!!place) {
        data.province = place.address_components.find((c) =>
          c.types.includes('administrative_area_level_2')
        )?.short_name
        data.address =
          (place.address_components.find((c) => c.types.includes('route'))?.long_name ?? '') +
          ' ' +
          (place.address_components.find((c) => c.types.includes('street_number'))?.long_name ?? '')
        data.state = place.address_components.find((c) => c.types.includes('country'))?.short_name ?? ''
        data.city =
          place.address_components.find((c) => c.types.includes('administrative_area_level_3'))?.long_name ?? ''
        data.postalCode = place.address_components.find((c) => c.types.includes('postal_code'))?.long_name ?? ''
      } else {
        data = {
          ...data,
          address: address,
          province: province,
          city: city,
          postalCode: postalCode,
        }
      }

      if (
        AppStore.loggedUser.rawPlan?.prices.some(
          (p) => p.id === subscriptionOptions.find((o) => o.active)?.stripePrice[subscriptionMode]
        ) &&
        (AppStore.loggedUser.rawPlan?.extraSocialGeneration ?? 0) === (additionalOptions[0].quantity ?? 0) &&
        (AppStore.loggedUser.rawPlan?.storageGeneration ?? 0) === (additionalOptions[1].quantity ?? 0)
      ) {
        showError(T.error.didentChangedPlan)
        return false
      }

      const billings = { ...data, customerCard: cardName }
      if (!!customerCard?.number && !!customerCard?.expiration && !!customerCard?.cvc) {
        await Utils.updatePaymentMethod({
          cardNumber: customerCard!.number,
          expMonth: Number(customerCard!.expiration!.split('/')[0]),
          expYear: Number(customerCard!.expiration!.split('/')[1]),
          cvc: customerCard!.cvc,
          customerCard: cardName,
          billings: billings,
        })
      }
      await sleep(2000)
      const resSub = await Utils.createStripeSubscription({
        prices: [
          {
            priceId: subscriptionOptions.find((o) => o.active)?.stripePrice[subscriptionMode],
          },
          ...additionalOptions
            .filter((o) => (o.quantity ?? 0) > 0)
            .map((o) => ({
              priceId: o.stripePriceLabel,
              quantity: o.quantity ?? 0,
            })),
        ],
        billings: data,
      })

      let finalFase = true

      if (resSub.status === 'incomplete') {
        finalFase = false

        try {
          const secret = resSub?.latest_invoice.payment_intent.client_secret
          if (!secret) return
          const stripe = await loadStripe(AppStore.settings.stripe.apiKey)
          const res = await stripe?.confirmCardPayment(secret!)
          if (res?.error) {
            showError(T.error.paymentDeclined)
          } else {
            finalFase = true
          }
        } catch (error) {
          showError(T.error.subscriptionPayment)
        }
      }

      if (finalFase) {
        openModal()
      }

      setPaying(false)
    } catch (error) {
      showError(error)
      setPaying(false)
      return false
    }
  }

  const possibleToSubmit = () => {
    let condition = true

    let variable
    if (type === 'private') {
      variable = [name, surname, fiscalCode]
    } else {
      variable = [name, surname, pIva, spc, ragioneSocial]
    }

    variable.forEach((element) => {
      if (element === undefined || element === '') {
        condition = false
      }
    })
    if (!address && !place) return false
    if (customerCard?.number !== '' && !privacyRead) return condition
    return false
  }

  /* -------------------------- USE EFFECT */
  useEffect(() => {
    setCouponCode(preselectedCuppon)

    setTimeout(() => {
      if (preselectedCuppon !== undefined) {
        addCoupon(preselectedCuppon)
      }
    }, 1000)
    const active = getPlanType()

    const storageGeneration =
      AppStore.loggedUser.rawPlan?.storageGeneration === undefined ? 0 : AppStore.loggedUser.rawPlan?.storageGeneration

    const extraSocialGeneration =
      AppStore.loggedUser.rawPlan?.extraSocialGeneration === undefined
        ? 0
        : AppStore.loggedUser.rawPlan?.extraSocialGeneration
    if (AppStore.loggedUser.stripe?.billings !== undefined) {
      setName(AppStore.loggedUser.stripe?.billings?.name)
      setSurname(AppStore.loggedUser.stripe?.billings?.surname)
      setAddress(AppStore.loggedUser.stripe?.billings?.address)
      setProvince(AppStore.loggedUser.stripe?.billings?.province)
      setCity(AppStore.loggedUser.stripe?.billings?.city)
      setPostalCode(AppStore.loggedUser.stripe?.billings?.postalCode)
      setFiscalCode(AppStore.loggedUser.stripe?.billings?.fiscalCode)
      setRagioneSocial(AppStore.loggedUser.stripe?.billings?.businessName)
      setPiva(AppStore.loggedUser.stripe?.billings?.vatNumber)
      setSpc(AppStore.loggedUser.stripe?.billings?.sdipec)
      setCardName(AppStore.loggedUser.stripe?.billings?.name ?? 'Not found')
      setAdditionalPrice(
        storageGeneration * 5 + extraSocialGeneration * 2 + active !== -1 ?? subscriptionOptions[active].price.monthly
      )
    }
  })

  return (
    <FormContainer animation="slide" style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
      <Form
        onFinishFailed={() => showError(T.error.allFildRequired)}
        onFinish={onFinish}
        initialValues={AppStore.loggedUser?.stripe?.billings}
        style={{ display: 'flex', flex: 1, overflow: 'hidden' }}
      >
        <Box row flex>
          <Box flex mr={24}>
            <Box mb={16} vcenter row>
              <BackwardIcon onClick={onBack} style={{ marginRight: 8 }} />
              <Title style={{ color: AppStore.theme.o.green }}>{T.settings.payment}</Title>
            </Box>
            <UserDetailsContainer>
              <Box pv={24} ph={32} style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}>
                <Box row>
                  <Box flex>
                    <Text>{T.settings.payment}</Text>
                  </Box>

                  <Form.Item name="type">
                    <Radio.Group defaultValue={type} onChange={(e) => setType(e.target.value)}>
                      <Radio value="private">
                        <Text>{T.settings.private}</Text>
                      </Radio>
                      <Radio value="business">
                        <Text>{T.settings.iva}</Text>
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Box>
                <Separator />
                <Text>{T.settings.yourPlan}</Text>
                <Text fontSize={27} color={AppStore.theme.o.yellow}>
                  {activeSubscriptionOption.label}
                </Text>
              </Box>
              <Box animate mt={16} pv={24} ph={32} style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}>
                <>
                  <Text>{T.settings.faturationDetail}</Text>
                  <Separator />
                  <Form.Item rules={[{ required: true }]} name="name">
                    <StyledInput onChange={(e) => setName(e)} label={T.misc.name} placeholder={T.settings.type_name} />
                  </Form.Item>
                  <Form.Item rules={[{ required: true }]} name="surname">
                    <StyledInput
                      onChange={(e) => setSurname(e)}
                      label={T.settings.surname}
                      placeholder={T.settings.type_surname}
                    />
                  </Form.Item>
                  {type === 'business' && (
                    <Box animation="fade">
                      <Form.Item rules={[{ required: true }]} name="businessName">
                        <StyledInput
                          onChange={(e) => setRagioneSocial(e)}
                          label={T.settings.ragioneSociale}
                          placeholder={T.settings.type_company_name}
                        />
                      </Form.Item>
                    </Box>
                  )}
                  {/* <Box row>
                  <Box flex>
                    <Form.Item rules={[{ required: true }]} name="address">
                      <StyledInput
                        onChange={(e) => this.setState({ address: e })}
                        label="Indirizzo"
                        placeholder="Indirizzo"
                      />
                    </Form.Item>
                  </Box>
                  <Spacer />
                  <Form.Item rules={[{ required: true }]} name="postalCode">
                    <StyledInput onChange={(e) => this.setState({ postalCode: e })} label="Cap" placeholder="Cap" />
                  </Form.Item>
                </Box>
                <Box row>
                  <Box flex>
                    <Form.Item rules={[{ required: true }]} name="city">
                      <StyledInput onChange={(e) => this.setState({ city: e })} label="Città" placeholder="Città" />
                    </Form.Item>
                  </Box>
                  <Spacer />
                  <Form.Item rules={[{ required: true }]} name="province">
                    <StyledInput
                      onChange={(e) => this.setState({ province: e })}
                      label="Provincia"
                      placeholder="Provincia"
                    />
                  </Form.Item>
                </Box> */}
                  <Box mb={8}>
                    <Text weight={700} style={{ marginBottom: 4 }}>
                      {T.settings.completeAddress}
                    </Text>
                    <ReactGoogleAutocomplete
                      apiKey={process.env.REACT_APP_API}
                      placeholder={T.settings.type_address}
                      options={{
                        types: ['address'],
                      }}
                      defaultValue={fullAddress}
                      style={{
                        borderRadius: 10,
                        backgroundColor: AppStore.theme.o.lightGrey,
                        borderColor: 'transparent',
                        color: AppStore.theme.o.black,
                        paddingLeft: 16,
                        paddingTop: 10,
                        paddingBottom: 10,

                        fontWeight: 500,
                      }}
                      onPlaceSelected={(p) => {
                        setPlace(p)
                      }}
                    />
                  </Box>
                  {type === 'private' && (
                    <Box animation="fade">
                      <Form.Item rules={[{ required: true }]} name="fiscalCode">
                        <StyledInput
                          onChange={(e) => setFiscalCode(e)}
                          label={T.settings.fiscalCode}
                          placeholder={T.settings.type_fiscalCode}
                        />
                      </Form.Item>
                    </Box>
                  )}
                  {type === 'business' && (
                    <Box animation="fade">
                      <Form.Item rules={[{ required: true }]} name="vatNumber">
                        <StyledInput
                          onChange={(e) => setPiva(e)}
                          label={T.settings.partitaIva}
                          placeholder={T.settings.type_company_vat}
                        />
                      </Form.Item>
                      <Form.Item rules={[{ required: true }]} name="sdipec">
                        <StyledInput
                          onChange={(e) => setSpc(e)}
                          label={T.settings.CodiceSdiOrPEC}
                          placeholder={T.settings.type_sdi_or_pec}
                        />
                      </Form.Item>
                    </Box>
                  )}
                </>
              </Box>

              <PaymentBox
                customerCard={customerCard}
                cvcChange={handleCardCVCChange}
                numberChange={handleCardNumberChange}
                expirationChange={handleCardExpirationChange}
                name={cardName}
                nameChange={(e) => setCardName(e)}
              />
            </UserDetailsContainer>
          </Box>
          <SummaryContainer>
            <Box width={360} pv={24} ph={32} height={'fit-content'}>
              <SummaryBoxSubscription
                total={total}
                externalAddOn={externalAddOn}
                PaymentCost={(AppStore.mySubscription?.renew_price ?? 0).toFixed(2) as any}
                notClickAble={true}
                disabled={possibleToSubmit()}
                monthPrice={monthPrice}
                annualPrice={annualPrice}
                monthBorderColor={monthBorderColor}
                subscriptionMode={subscriptionMode}
                monthTextColor={monthTextColor}
                annualBorderColor={annualBorderColor}
                annualTextColor={annualTextColor}
                addonPrice={addonPrice}
                onSubscriptionChange={setSubscriptionMode}
                loading={paying}
                offerta={offerO}
              />
              <Spacer />

              <CupponBox
                addCoupon={addCoupon}
                loadingCoupon={loadingCoupon}
                coupon={coupon}
                onChangeCuponCode={(e) => setCouponCode(e)}
                onChangeCoupon={() => setCoupon(undefined)}
              />
              <DetailBox
                coupon={coupon}
                normalPrice={total}
                privacyRead={privacyReed}
                loading={paying}
                disabled={possibleToSubmit()}
                activeSubscriptionOption={activeSubscriptionOption}
                additionalOptions={additionalOptions}
                price={subscriptionMode === 'monthly' ? monthPrice : annualPrice}
                addonPrice={addonPrice}
                subscriptionMode={subscriptionMode}
              />
            </Box>
          </SummaryContainer>
        </Box>
      </Form>
    </FormContainer>
  )
}

export default SubscriptionPartThree

const FormContainer = styled(Box)`
  overflow: visible;
`

const UserDetailsContainer = styled(Box)`
  overflow: scroll;
  max-height: 100%;
  padding-bottom: 54px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const SummaryContainer = styled(Box)`
  overflow: scroll;
  max-height: 100%;
  border: 1px dashed ${AppStore.theme.o.darkGrey};
  padding: 8px 0px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const StyledInput = styled(Input)`
  margin-bottom: 12px;
  padding-left: 16px !important;
  font-size: 14px !important;
`

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`
