import AppStore from './AppStore'
import { history } from 'utils/router'

const courtesyRoutePath = {
  trial_expired: '/settings/subscription/trial-expired',
}

/**
 * Verifica se un utente è pagante o meno.
 * @param loggedUser utente loggato. es.: AppStore.loggedUser
 * @returns boolean
 */
export const checkIfUserIsPayed = (loggedUser) => {
  const isExpirationDateValid =
    loggedUser?.rawPlan?.expirationDate && new Date(loggedUser?.rawPlan?.expirationDate) > new Date()
  const isFreeTrialValid = loggedUser?.freeTrial && new Date(loggedUser?.freeTrial) > new Date()
  const isInspectableRoute = checkCourtesyRoutes(window.location.pathname)
  if (isInspectableRoute) return true
  return isExpirationDateValid || isFreeTrialValid
}

/**
 * Verifica se la pagina è una pagina di cortesia
 * @param pathName
 * @returns boolean
 */
export const checkCourtesyRoutes = (pathName) => {
  return Object.values(courtesyRoutePath).includes(pathName)
}

/**
 * Verifica se l'utente è pagante o meno, se non lo è lo reindirizza alla pagina di cortesia
 * @returns boolean
 */
export const validateSubscription = (navigate) => {
  const subscriptionValid = checkIfUserIsPayed(AppStore.loggedUser)
  !subscriptionValid && navigate(courtesyRoutePath.trial_expired)
  return true
}
