import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { AppStore, T, showInfo } from 'utils'
import { Option, SelectedOpt } from './GeneralComponentsTypes'
import { useNavigate } from 'react-router-dom'

interface Props {
  optEl: Option[]
  i: number
  isActive: (i: number, index: number) => string
  selected: SelectedOpt[]
  setSelected: (newSelected) => void
}

const OptionBox = ({ optEl, i, isActive, selected, setSelected }: Props) => {
  /* ---------------- ROUTER */
  const navigate = useNavigate()

  return (
    <>
      {optEl?.map((option, index) => (
        <InnerContainer
          style={{
            cursor: option?.disabled ? 'not-allowed' : 'pointer',
          }}
          hasInnerOptions={option?.options !== undefined && option?.options?.length > 0}
          disabled={option?.disabled ?? false}
          key={option.id}
          className={`${option.disabled ? 'disabled' : isActive(i, index)}`}
          onClick={() => {
            if (option?.disabled) {
              showInfo('Coming soon')
              return
            }

            let control = ''
            // if function is defined
            if (option.onSelect.function) control = option.onSelect.function()

            // if notice is defined
            option.onSelect.notice && option.onSelect.notice()

            if (control === 'error') return
            // if navigate is defined

            option.onSelect.navigate && navigate(option.onSelect.navigate, { state: option.onSelect.state })
          }}
        >
          {option?.disabled && option.title !== T.analytics.savePdf && (
            <ComingSoon style={{ opacity: 1 }} children={T.analytics.comingSoon} />
          )}

          {option.icon}
          <Text className={`${option.disabled ? 'disabled' : ''}`}>{option.title}</Text>
        </InnerContainer>
      ))}
    </>
  )
}

export default OptionBox

const ComingSoon = styled(Box)`
  opacity: 1 !important;
  padding: 3px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  position: absolute;
  left: -63px;
  bottom: 70px;
  border-radius: 8px;
  border: 0.5px solid #732187;
  background: #faeafe;
  font-weight: 700;
  color: #732187;
`

const InnerContainer = styled(Box)<{ hasInnerOptions: boolean; disabled: boolean }>`
  position: relative;
  min-width: 80px;
  min-height: 80px;
  background-color: ${() => AppStore.theme.o.surface};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 36px;
    height: 36px;

    path {
      fill: ${() => AppStore.theme.o.darkGrey};
    }
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue};

    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }

    text {
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  &.active {
    background-color: ${() => AppStore.theme.o.lightBlue};

    ${({ hasInnerOptions }) =>
      hasInnerOptions
        ? `border-left: 1px solid ${AppStore.theme.o.blue};`
        : `border: 1px solid ${AppStore.theme.o.blue};`}

    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }
    text {
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  &.special {
    width: 9;
  }

  &.disabled {
    svg {
      opacity: 0.5;
    }
  }

  &.disabled:hover {
    background-color: #eafcfe8a;
    opacity: 1;
    svg {
      path {
        fill: #187f88a8;
      }
    }
  }
`

const Text = styled.text`
  font-size: 12px;
  font-style: normal;
  color: ${() => AppStore.theme.o.darkGrey};
  font-weight: 400;

  &.disabled {
    opacity: 0.5;
  }

  &.disabled:hover {
    opacity: 1;
    color: #187f88a8;
  }
`
