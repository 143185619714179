/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useEffect, useState } from 'react'
import { AppStore } from 'utils'
import PreviewBoard from './PreviewBoard'
import BottomBar from './BottomBar'
import { gsap } from 'gsap'
import BoardCard from './BoardCard'
import PreviewCard from './PreviewCard'
import { IArticle, IBoard } from '../aiDiscoverInterface'
import { getBoardArticleList, getBoardsList } from '../commonFunction'
import SkeletonCard from '../UI/SkeletonCard'
import EmptyState from '../UI/EmptyState'
import Utils from 'api/Utils'
import { useDiscoverStore } from 'stores/DiscoverStore'

/* ******************* INTERFACE ******************* */

interface Props {
  openNewBoard: boolean
  onOpenNewBoard: any
}

const BoardPage = ({ openNewBoard, onOpenNewBoard }: Props) => {
  /* ____________________ STATE ____________________ */

  const [isAllSelected, setIsAllSelected] = useState(false)
  const [isAllSelectedArticles, setIsAllSelectedArticles] = useState(false)
  const [selectedBoard, setSelectedBoard] = useState<IBoard[]>([])
  const [selectedArticles, setSelectedArticles] = useState<IArticle[]>([])
  const [allBords, setAllBords] = useState<IBoard[]>([])
  const [boardArticle, setBoardArticle] = useState<IArticle[]>([])
  const [isBottomBarBoardHovered, setIsBottomBarBoardHovered] = useState(false)
  const [isBottomBarArticlesHovered, setIsBottomBarArticlesHovered] = useState(false)

  /* ____________________ REF ____________________ */

  /* ____________________ ZUSTAND  ____________________ */
  const { activeBoard, onForceUpdate, setActiveBoard, createComment, activeDropdown, setActiveDropdown } =
    useDiscoverStore((state) => ({
      activeBoard: state.activeBoard,
      onForceUpdate: state.onForceUpdate,
      setActiveBoard: state.setActiveBoard,
      createComment: state.createComment,
      activeDropdown: state.activeDropdown,
      setActiveDropdown: state.setActiveDropdown,
    }))

  /* ____________________ VARIABLES ____________________ */

  const boardList = AppStore.discoverUserBoardList

  /* ____________________ METHODS ____________________ */

  const openNewBoardHandler = () => {
    setIsAllSelected(false)
    setSelectedBoard([])
    onOpenNewBoard()
  }

  const handleSelectAll = (type: 'article' | 'board') => {
    if (type === 'board') {
      const newSelectedBoards = isAllSelected
        ? []
        : allBords.filter((b) => {
            if (activeDropdown === 'all') return b
            if (activeDropdown === 'private') return b.private
            if (activeDropdown === 'shared') return !b.private
          })

      // Aggiorna lo stato di selezione per ogni board
      newSelectedBoards.forEach((board) => onSelect('board', board))

      setSelectedBoard(newSelectedBoards)
    }

    if (type === 'article') {
      const newSelectedArticles = isAllSelectedArticles ? [] : boardArticle

      setSelectedArticles(newSelectedArticles)
      setIsAllSelectedArticles(newSelectedArticles.length === boardArticle.length)
    }
  }

  const onSelect = (type: 'board' | 'article', data) => {
    if (type === 'board') {
      const index = selectedBoard.indexOf(data)

      let newSelectedBoards: any = []
      if (index !== -1) {
        const spliced = selectedBoard.slice() // Crea una copia dell'array prima di modificarlo
        spliced.splice(index, 1)
        newSelectedBoards = spliced
      } else {
        newSelectedBoards = [...selectedBoard, data]
      }

      setSelectedBoard(newSelectedBoards)

      // check if all items are selected or not

      setIsAllSelected(newSelectedBoards.length === allBords.length)
    }

    if (type === 'article') {
      const index = selectedArticles.indexOf(data)

      let newSelectedArticles: any = []
      if (index !== -1) {
        const spliced = selectedArticles.slice() // Crea una copia dell'array prima di modificarlo
        spliced.splice(index, 1)
        newSelectedArticles = spliced
      } else {
        newSelectedArticles = [...selectedArticles, data]
      }

      setSelectedArticles(newSelectedArticles)

      // check if all items are selected or not
      setIsAllSelectedArticles(newSelectedArticles.length === boardArticle.length)
    }
  }

  const firstMount = () => {
    if (activeBoard) {
      getArticleFromActiveBoard()
    } else {
      setActiveBoard(undefined)
      setBoardArticle([])
    }
  }
  const handleBoardCreationComplete = () => {
    onOpenNewBoard()
  }
  const getArticleFromActiveBoard = async () => {
    if (!activeBoard) return

    const boardArticleList: IArticle[] = await getBoardArticleList(activeBoard._id ?? '')

    setBoardArticle(boardArticleList.reverse())
  }

  const getCommentsNumberForArticle = (articleUri) => {
    const article = activeBoard?.articles.find((art) => art.articleId === articleUri)
    return article ? article.commentsNumber : 0
  }

  /* ____________________ USE EFFECT ____________________ */

  useEffect(firstMount, [])

  useEffect(firstMount, [activeBoard])

  useEffect(() => {
    setSelectedArticles([])
  }, [activeBoard])

  useEffect(() => {
    if (boardList != undefined) {
      setAllBords(boardList)
    }

    if (AppStore.discoverUserBoardList.length === 0) setActiveDropdown('all')
  }, [boardList])

  // Gestione posizione del BottomBar component per board
  useEffect(() => {
    const bottomBar = document.getElementById('manage-board')
    gsap.to(bottomBar, {
      y: isBottomBarBoardHovered || selectedBoard.length > 0 ? -45 : 100, // se ci sono elementi visualizza la barra, altrimenti la nasconde
      duration: 0.3,
    })
  }, [selectedBoard, isBottomBarBoardHovered])

  // Gestione posizione del BottomBar component per articles
  useEffect(() => {
    const bottomBar = document.getElementById('manage-articles')
    gsap.to(bottomBar, {
      y: isBottomBarArticlesHovered || selectedArticles.length > 0 ? -45 : 100, // se ci sono elementi visualizza la barra, altrimenti la nasconde
      duration: 0.3,
    })
  }, [selectedArticles, isBottomBarArticlesHovered])

  useEffect(() => {
    const updateBoard = async () => {
      const newAllBoard: IBoard[] | boolean = await getBoardsList(onForceUpdate)
      if (newAllBoard) {
        setAllBords([...newAllBoard])
        if (activeBoard) {
          const activeB: any = activeBoard
          newAllBoard.filter((b: any) => {
            if (b._id === activeB._id) {
              setActiveBoard(b)
            }
          })
        }
      }
    }

    updateBoard()
  }, [createComment])

  // UseEffect per controllare stato di isAllSelected
  useEffect(() => {
    const visibleBoards = allBords.filter((b) => {
      if (activeDropdown === 'all') return b
      if (activeDropdown === 'private') return b.private
      if (activeDropdown === 'shared') return !b.private
    })

    //Controlla che  ogni elemento di visibleBoards è presente in selectedBoard
    const areAllSelected = visibleBoards.every((board) => selectedBoard.some((selected) => selected._id === board._id))

    setIsAllSelected(areAllSelected)
  }, [selectedBoard, activeDropdown])

  return (
    <BoxContainer className="box-container">
      {!activeBoard ? (
        allBords.length === 0 ||
        allBords.filter((b) => {
          if (activeDropdown === 'all') return b

          if (activeDropdown === 'private') return b.private

          if (activeDropdown === 'shared') return !b.private
        }).length === 0 ? (
          <EmptyState type="board" openNewBoardHandler={openNewBoardHandler} />
        ) : (
          <AllBords flex className="all-board">
            <ScrollContainer>
              {allBords
                .filter((b) => {
                  if (activeDropdown === 'all') return b

                  if (activeDropdown === 'private') return b.private

                  if (activeDropdown === 'shared') return !b.private
                })
                .reverse()
                .map((board, index) => {
                  return (
                    <PreviewBoard
                      key={`preview_board_${index}`}
                      board={board}
                      index={index}
                      onClick={() => onSelect('board', board)}
                      isSelected={selectedBoard.includes(board)}
                      boardSelected={selectedBoard.length > 0}
                    />
                  )
                })}
            </ScrollContainer>
          </AllBords>
        )
      ) : (
        <BoardArticleContainer className="article-board-container">
          {activeBoard.articles.length > 0 && boardArticle.length === 0 ? (
            <SkeletonCard key={Utils.generateRandomUIDKey()} type="article" num={9} />
          ) : boardArticle.length === 0 ? (
            <EmptyState type="boardArticle" />
          ) : (
            boardArticle.map((art, index) => {
              return (
                <PreviewCard
                  key={art.uri}
                  article={art}
                  commentsNumber={getCommentsNumberForArticle(art.uri)}
                  index={index}
                  onClick={() => onSelect('article', art)}
                  isSelected={selectedArticles.includes(art)}
                  articleSelected={selectedArticles.length > 0}
                />
              )
            })
          )}
        </BoardArticleContainer>
      )}

      {/* ---------- CREATE NEW BOARD ---------- */}
      {openNewBoard && (
        <NewBoardContainer>
          <NewBoardLayer className="layer" onClick={openNewBoardHandler} />
          <NewBoardCardContainer className={openNewBoard ? 'show' : ''}>
            <BoardCard
              key={Utils.generateRandomUIDKey()}
              isOpen={openNewBoard}
              isOnlyCreate={true}
              onBoardCreationComplete={handleBoardCreationComplete}
            />
          </NewBoardCardContainer>
        </NewBoardContainer>
      )}

      {/** ---------- BOTTOM BAR BOARD--------- */}
      <BottomBar
        onSelectAll={() => handleSelectAll('board')}
        isAllSelected={isAllSelected}
        selected={selectedBoard}
        setSelected={setSelectedBoard}
        type="manage-board"
        onMouseEnter={() => setIsBottomBarBoardHovered(true)}
        onMouseLeave={() => setIsBottomBarBoardHovered(false)}
      />

      {/** ---------- BOTTOM BAR ARTICLES--------- */}
      <BottomBar
        onSelectAll={() => handleSelectAll('article')}
        isAllSelected={isAllSelectedArticles}
        selected={selectedArticles}
        setSelected={setSelectedArticles}
        refreshArticleOnBoard={getArticleFromActiveBoard}
        type="manage-articles"
        onMouseEnter={() => setIsBottomBarArticlesHovered(true)}
        onMouseLeave={() => setIsBottomBarArticlesHovered(false)}
      />
    </BoxContainer>
  )
}

export default BoardPage

const AllBords = styled(Box)`
  position: relative;
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 24px;

  overflow: hidden;
  align-content: flex-start;

  &::-webkit-scrollbar {
    display: none;
  }
`

const BoardArticleContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 31px;
  padding: 24px;
`

const ScrollContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 24px;
  overflow: visible;

  &::-webkit-scrollbar {
    display: none;
  }
`

const BoxContainer = styled(Box)`
  width: 1164px;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`

const NewBoardContainer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
`

const NewBoardLayer = styled(Box)`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &.layer {
    background-color: rgba(0, 0, 0, 0.5) !important;
    backdrop-filter: blur(4px);
    animation: fadeIn 0.2s ease-in; /* Add fadeIn animation */
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

const NewBoardCardContainer = styled(Box)`
  position: relative;
  width: 351px;
  height: 423px;
  min-height: 423px;
  max-height: 423px;

  /* Add class to show the modal */
  &.show {
    opacity: 1;
    transition: opacity 0.2s ease;
    animation: moveCenter 0.2s ease;
  }

  @keyframes moveCenter {
    from {
      transform: translate(0%, 0%) scale(0.2);
    }
    to {
      transform: translate(0%, 0%) scale(1);
    }
  }
`
