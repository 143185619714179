import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'

export default class PostV2 extends AstersBaseResource {
  static endpoint = '/Postsv2'

  static getPosts<T>() {
    try {
      return api.get<T>(`${this.endpoint}/getAllPostsV2/${AppStore.workspace._id}`).then(responseErrorCheck)
    } catch (err) {
      console.error(err)
    }
  }

  static getDrafts<T>() {
    try {
      return api.get<T>(`${this.endpoint}/getDrafts/${AppStore.workspace._id}`).then(responseErrorCheck)
    } catch (err) {
      console.error(err)
    }
  }

  // -----------------  DELETE  -----------------
  static DeletePost(id) {
    return api.delete(`${this.endpoint}/${AppStore.getWorkspaceId()}/${id}/delete`).then(responseErrorCheck)
  }
}
