import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'

import AiCredis from './AiCredis'
import MyData from './MyData'
import MyPlan from './MyPlan'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'
import { useLocation } from 'react-router-dom'

interface Props {
  refreshParent: () => void
}

interface Scrolls {
  bot?: boolean
}

const AfterPayment = ({ refreshParent }: Props) => {
  const [section, setSection] = useState<string>('MyPlan')
  const [scroll, setScroll] = useState<boolean>(false)
  const [preSection, setPresection] = useState<string>('')

  const locationState = useLocation().state

  const getInitialReferrer = () => {
    const { from } = locationState ? locationState : { from: 'settings' }

    let initialreferrer = ''

    if (from === 'dashboard') {
      initialreferrer = 'Dashboard'
    } else if (from === 'header') {
      initialreferrer = 'Menu Top'
    } else if (from === 'settings') {
      initialreferrer = 'Settings'
    } else {
      initialreferrer = 'Settings'
    }
    return initialreferrer
  }

  const initialReferrer = getInitialReferrer()

  useEffect(() => {
    const { subSection } = locationState ?? { subSection: undefined }
    const { scroll: scrollFromLocation } = locationState ? locationState : { scroll: false }
    if (subSection) setSection(locationState.subSection)
    if (scrollFromLocation === 'true') setScroll(true)
  }, [])

  return (
    <>
      <Box p={20}>
        <Box style={{ maxHeight: 800 }} pv={40} ph={80}>
          <Box row mb={56}>
            <ButtonSelection
              variant={section === 'MyPlan' ? 'secondary' : 'neutral'}
              onClick={() => {
                setSection('MyPlan')
              }}
            >
              {T.settings.yourPlan}
            </ButtonSelection>
            <ButtonSelection
              variant={section === 'CreditiAi' ? 'secondary' : 'neutral'}
              onClick={() => {
                setSection('CreditiAi')
                Tracker.trackEvent(TRACKEREVENTS.AI_CREDITS_PAGE_VIEWED, { initial_referrer: initialReferrer })
              }}
            >
              {T.settings.creditAi}
            </ButtonSelection>
            <ButtonSelection
              variant={section === 'MyData' ? 'secondary' : 'neutral'}
              onClick={() => {
                setSection('MyData')
              }}
            >
              {T.settings.yourData}
            </ButtonSelection>
          </Box>
          <SpecialBox bot={scroll} scroll flex>
            {section === 'MyPlan' && <MyPlan />}
            {section === 'CreditiAi' && <AiCredis />}
            {section === 'MyData' && <MyData />}
          </SpecialBox>
        </Box>
      </Box>
    </>
  )
}

export default AfterPayment

const SpecialBox = styled(Box) <Scrolls>`
  /* transform: rotateX(180deg); */
  ${({ bot }) => bot && `flex-direction: column-reverse;`}
`

const ButtonSelection = styled(Button)`
  width: 298.33px;
  height: 56;
  margin-right: 16px;
  /* &:hover {
    background-color: ${AppStore.theme.o.surface}!important;
    box-shadow: none;
    color: ${AppStore.theme.o.green};
  } */
`
