/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { useNavigate } from 'react-router-dom'
import { TSubsection } from 'types/CreatePostInferfaces'
import { AppStore, T } from 'utils'
import { ActivePostCard } from '../UI/ActivePostCard'

/* ------------------- INTERFACES  */
interface Props {
  subsection: TSubsection
}

export const AiHeader = ({ subsection }: Props) => {
  /* ----------------- ROUTER  */
  const navigate = useNavigate()

  return (
    <HeaderModelsContainer>
      <ActivePostCard />
      <ContainerButtons>
        <Button
          variant={subsection === 'astersAiText' ? 'secondary-shadow' : 'neutral-shadow'}
          width={260}
          height={40}
          style={{ maxHeight: 40, gap: 4, transition: '300ms ease-in-out' }}
          size="small"
          onClick={() => {
            setTimeout(() => {
              navigate(`/post/text/astersAiText`)
            }, 400)
          }}
        >
          <Icons.textIcon
            fill={subsection === 'astersAiText' ? AppStore.theme.o.green : AppStore.theme.o.black}
            style={{ transition: '300ms ease-in-out' }}
          />

          {T.textGenerator.textGenerator}
        </Button>
        <Button
          variant={subsection === 'astersAiLink' ? 'secondary-shadow' : 'neutral-shadow'}
          width={260}
          height={40}
          style={{ maxHeight: 40, gap: 4, transition: '300ms ease-in-out' }}
          size="small"
          onClick={() => {
            setTimeout(() => {
              navigate(`/post/text/astersAiLink`)
            }, 400)
          }}
        >
          <Icons.link
            fill={subsection === 'astersAiLink' ? AppStore.theme.o.green : AppStore.theme.o.black}
            style={{ transition: '300ms ease-in-out' }}
          />
          {T.textGenerator.linkGenerator}
        </Button>
      </ContainerButtons>
    </HeaderModelsContainer>
  )
}

const HeaderModelsContainer = styled(Box)`
  width: 95%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ContainerButtons = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
`
