/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface DynamicContainer {
  isEmpty: boolean
  fromTopEmpty: number | string
  fromTopFull: number | string
  containerEmpty: number | string
  containerFull: number | string
  transition: string
}

interface Props {
  content: React.ReactNode
  padding?: number | string
  bgColor?: string
  borderRadius?: number | string
  dynamicContainer?: DynamicContainer
  height?: number | string
  width?: number | string
}

export const ContentContainerStructure = ({
  content,
  padding,
  bgColor,
  borderRadius,
  dynamicContainer,
  height,
  width,
}: Props) => {
  const Yvalue = dynamicContainer?.isEmpty ? dynamicContainer?.fromTopEmpty : dynamicContainer?.fromTopFull
  const maxHeightValue = height
    ? height
    : dynamicContainer?.isEmpty
    ? dynamicContainer?.containerEmpty
    : dynamicContainer?.containerFull

  return (
    <ContentContainerStructureContainer
      className="content_container_structure"
      style={{
        padding: padding ? padding : '24px',
        backgroundColor: bgColor ? bgColor : AppStore.theme.o.lightestGrey,
        borderRadius: borderRadius ? borderRadius : '24px',
        y: Yvalue ?? 0,
        height: height ?? '100%',
        maxHeight: maxHeightValue ?? '100%',
        width: width ?? '100%',
      }}
    >
      {content}
    </ContentContainerStructureContainer>
  )
}

const ContentContainerStructureContainer = styled(Box)`
  &.content_container_structure {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: scroll;
    width: 100%;
    background-color: ${() => AppStore.theme.o.lightestGrey};
    box-shadow: ${() => AppStore.theme.smallOuterShadow};
    margin: 0 auto;

    &::-webkit-scrollbar {
      display: none;
    }
    & {
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
`
