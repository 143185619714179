import { Input as InputAntd, InputProps as AntdInputProps } from 'antd'
import { EyeInvisibleOutlined, EyeTwoTone, MailOutlined, UnlockOutlined, UserOutlined } from '@ant-design/icons'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Text } from './Text'
import { AppStore } from 'utils'
import { T, __ } from 'translation/i18n'
import { Icons } from './Icons'
import { AiTagCard, TDirections } from './UI/AiTagCard'
import { borderGradientStyles } from 'utils/Constants/Constants'

export interface InputProps extends AntdInputProps {
  onChange?: (e: any) => void
  onClick?: (e: any) => void
  variant?: 'default' | 'name' | 'email' | 'password' | 'numeric' | 'referral' | 'whiteDotted'
  placeholder?: string
  flex?: boolean | number
  height?: string
  label?: string
  value?: string
  disabled?: boolean
  inputRef?: React.Ref<InputAntd>
  borderColor?: string
  removeHover?: boolean
  hoverBorderColor?: string
  pixelBorder?: number
  aiTag?: boolean // booleano per mostrare o meno il tag e bordo che si trova nell'input
  aiTagComplete?: boolean // booleano per mostrare o meno il tag versione completa "Astera AI" o  "AI"
  aiTagDirection?: TDirections // direzione del tag
  margin?: string | number
}

export const Input = ({
  flex,
  variant = 'default',
  placeholder,
  height = '45px',
  onChange,
  onClick,
  label,
  disabled,
  inputRef,
  borderColor,
  removeHover,
  hoverBorderColor,
  pixelBorder,
  aiTag,
  aiTagComplete,
  aiTagDirection,
  margin,
  ...props
}: InputProps) => {
  const [status, setstatus] = useState<'default' | 'hover' | 'selected' | 'error'>('default')
  const [isFilled, setIsFilled] = useState(false)

  const borderGradient = aiTag ? borderGradientStyles : null

  const variants = {
    default: {
      prefix: null,
      placeholder: placeholder ?? 'Text',
    },
    name: {
      prefix: <UserOutlined style={{ color: isFilled ? AppStore.theme.o.black : AppStore.theme.o.darkGrey }} />,
      placeholder: placeholder ?? __(T.misc.name),
    },
    email: {
      prefix: <MailOutlined style={{ color: isFilled ? AppStore.theme.o.black : AppStore.theme.o.darkGrey }} />,
      placeholder: placeholder ?? 'Email',
    },
    password: {
      prefix: <UnlockOutlined style={{ color: isFilled ? AppStore.theme.o.black : AppStore.theme.o.darkGrey }} />,
      placeholder: placeholder ?? 'Password',
    },
    referral: {
      prefix: <Icons.referalGift fill={isFilled ? AppStore.theme.o.black : AppStore.theme.o.darkGrey} />,
      placeholder: placeholder ?? 'Password',
    },
  }

  const onHover = () => setstatus('hover')

  const onLeave = () => {
    setstatus('default')
  }

  if (variant === 'whiteDotted') {
    return (
      <div>
        {label && (
          <Text style={{ marginBottom: 4 }} fontSize={16}>
            <b>{label}</b>
          </Text>
        )}
        <InputCoreWhite
          ref={inputRef}
          onClick={onClick}
          prefix={variants[variant]?.prefix ?? variants.default.prefix}
          onChange={(e) => {
            const value = e.target.value

            onChange?.(value)

            if (value.length > 0) {
              setIsFilled(true)
            } else {
              setIsFilled(false)
            }
          }}
          placeholder={variants[variant]?.placeholder ?? variants.default.placeholder}
          status={'default'}
          disabled={disabled}
          borderColor={borderColor}
          removeHover={removeHover}
          hoverBorderColor={hoverBorderColor}
          pixelBorder={pixelBorder}
          {...props}
        />
      </div>
    )
  }

  if (variant === 'password') {
    return (
      <div>
        {label && (
          <Text style={{ marginBottom: 4 }} fontSize={16}>
            <b>{label}</b>
          </Text>
        )}
        <InputCorePsw
          ref={inputRef}
          onMouseEnter={onHover}
          onMouseLeave={onLeave}
          onClick={onClick}
          onChange={(e) => {
            onChange?.(e.target.value)
            if (e.target.value.length > 0) {
              setIsFilled(true)
            } else {
              setIsFilled(false)
            }
          }}
          prefix={variants[variant].prefix}
          placeholder="Password"
          iconRender={(visible) =>
            visible ? <EyeTwoTone color={AppStore.theme.o.blueOnLight} /> : <EyeInvisibleOutlined />
          }
          status={status}
          disabled={disabled}
          {...props}
        />
      </div>
    )
  }

  return (
    <div style={{ position: 'relative', margin: `${margin}`, ...borderGradient }}>
      {label && (
        <Text style={{ marginBottom: 4 }} fontSize={16}>
          <b>{label}</b>
        </Text>
      )}
      <InputCore
        ref={inputRef}
        onClick={onClick}
        prefix={variants[variant]?.prefix ?? variants.default.prefix}
        onChange={(e) => {
          const value = e.target.value
          //check if variant is numeric and if value contains only numbers
          if (variant === 'numeric' && !value.match(/^[0-9]*$/)) return

          onChange?.(value)

          if (value.length > 0) {
            setIsFilled(true)
          } else {
            setIsFilled(false)
          }
        }}
        placeholder={variants[variant]?.placeholder ?? variants.default.placeholder}
        status={status}
        disabled={disabled}
        {...props}
      />
      {aiTag && <AiTagCard aiTagComplete={aiTagComplete!} aiTagDirection={aiTagDirection!} />}
    </div>
  )
}

const InputCore = styled(InputAntd)<{
  flex?: boolean | number
  status: 'default' | 'hover' | 'selected' | 'error'
  height?: string
  isWhite?: boolean
}>`
  transition: 0.5s;

  min-height: ${({ height }) => height}!important;
  ${(props) => (props.flex === true ? `width: 100%;` : !!props.flex && `flex: ${props.flex};`)}
  border-radius: 10px;
  background-color: ${({ status }) =>
    status === 'default'
      ? AppStore.theme.o.lightGrey
      : status === 'hover'
      ? AppStore.theme.o.lightGrey
      : status === 'selected'
      ? AppStore.theme.o.lightGrey
      : AppStore.theme.o.lightGrey}!important;

  &::placeholder {
    color: ${() => AppStore.theme.o.darkGrey};
    font-weight: 600;
  }
  &:hover {
    border-color: ${() => AppStore.theme.o.grey};
  }
`

const InputCoreWhite = styled(InputAntd)<{
  flex?: boolean | number
  status: 'default'
  height?: string
  isWhite?: boolean
  borderColor?: string
  removeHover?: boolean
  hoverBorderColor?: string
  pixelBorder?: number
}>`
  transition: 0.5s;

  min-height: ${({ height }) => height}!important;
  ${(props) => (props.flex === true ? `width: 100%;` : !!props.flex && `flex: ${props.flex};`)};
  border-radius: 11px;
  border: ${(props) => (props.pixelBorder ? `${props.pixelBorder}px` : '1px')} dashed
    ${(props) => (props.borderColor ? props.borderColor : AppStore.theme.o.darkGrey)};
  background-color: transparent;

  &::placeholder {
    font-weight: 500;
    color: ${() => AppStore.theme.o.black}!important;
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }

  /* {!removeHover && (
    &:hover {
      border: initial!important;
      box-shadow: none!important;
    }
  )} */
`
const InputCorePsw = styled(InputAntd.Password)<{ status?: string; flex?: boolean | number }>`
  min-height: ${({ height }) => height}!important;

  ${(props) => (props.flex === true ? `width: 100%;` : !!props.flex && `flex: ${props.flex};`)}
  border: ${({ status }) =>
    status === 'default'
      ? '1px solid transparent'
      : status === 'hover'
      ? `1px solid ${() => AppStore.theme.o.grey}`
      : status === 'selected'
      ? `1px solid ${() => AppStore.theme.o.grey}`
      : `1px solid ${() => AppStore.theme.o.red}`};
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ status }) =>
    status === 'default'
      ? AppStore.theme.o.lightGrey
      : status === 'hover'
      ? AppStore.theme.o.lightGrey
      : status === 'selected'
      ? AppStore.theme.o.lightGrey
      : AppStore.theme.o.lightGrey};

  &::placeholder {
    font-weight: 600;

    color: ${({ status }) =>
      status === 'default'
        ? AppStore.theme.o.surface
        : status === 'hover'
        ? AppStore.theme.o.surface
        : status === 'selected'
        ? AppStore.theme.o.surface
        : AppStore.theme.o.surface};
  }
`
