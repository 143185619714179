/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import { useNavigate, useParams } from 'react-router-dom'
import { changeReportName, dateRange28DaysAgo, formatDate, getReport } from 'utils/AnalyticsUtils'
import { InputEditableContent } from 'components/InputEditableContent'
import { RefreshButton } from 'components/RefreshButton'
import { DateRangeFilter } from '../UI/DateRangeFilter'
import { DateCompareDropdown } from '../UI/DateCompareDropdown'
import { AnalyticsDate, IDataReport, IReport, IReportConfig } from 'types/AnalyticsInferfaces'
import Reports from 'api/Reports'
import { PreviewReport } from './PreviewReport'
import { AddMetricOnReport } from '../Graph/singleGraph/AddMetricOnReport'
import dayjs from 'dayjs'
import { DownloadReportButton } from '../Graph/singleGraph/DownloadReportButton'
import { isVisible } from 'utils/CheckVisibleElement'

/* ------------------- INTERFACES  */
interface Props {
  onSelectedDateCompareChange: (dates: AnalyticsDate) => void
}

export const PreviewReportHeader = ({ onSelectedDateCompareChange }: Props) => {
  /* ------------------- ROUTER  */
  const { reportId } = useParams()
  const navigate = useNavigate()

  /* ------------------- ZUSTAND  */
  const {
    onSetReportName,
    setFilteredReportList,
    setReportPreview,
    setSelectedDatesReports,
    isReportUpdating,
    reportUpdateDate,
    reportPreviewName,
  } = useAnalyticsStore.getState()
  const { reportPreview } = useAnalyticsStore((state) => ({
    reportPreview: state.reportPreview,
  }))

  /* ----------------- STATE  */
  const [inputReportName, setInputReportName] = useState<string>()
  const [selectedDate, setSelectedDate] = useState<AnalyticsDate | null>(null)

  /* ------------------- ZUSTAND UNSUB  */
  const unsubSelectedDate = useAnalyticsStore.subscribe((state) => {
    state.selectedDatesReports !== selectedDate && setSelectedDate(state.selectedDatesReports)
  })

  /* ------------------- METHOD  */
  const onChangeSelectedDateReports = (dates: AnalyticsDate | null | '28dayAgo') => {
    if (!reportPreview) return

    if (dates === '28dayAgo') {
      setSelectedDatesReports(null)
      createReportConfigFromReport(true)
    } else {
      setSelectedDatesReports(dates)
    }
  }

  const goBackToAnalytics = () => {
    {
      navigate('/analytics')
      setFilteredReportList(null)
      setReportPreview(null)
    }
  }

  const createReportConfigFromReport = async (last28Days = false) => {
    const defaultRange28DaysAgo = dateRange28DaysAgo()

    if (reportPreview === null || selectedDate === null) return

    let sinceDate = defaultRange28DaysAgo[0]
    if (!last28Days && selectedDate !== null && selectedDate.startDate !== null) {
      const start = selectedDate.startDate
      sinceDate = `${start.year()}-${String(start.month() + 1).padStart(2, '0')}-${String(start.date()).padStart(
        2,
        '0'
      )}`
    }

    let untilDate = defaultRange28DaysAgo[1]
    if (!last28Days && selectedDate !== null && selectedDate.endDate !== null) {
      const end = selectedDate.endDate

      untilDate = `${end.year()}-${String(end.month() + 1).padStart(2, '0')}-${String(end.date()).padStart(2, '0')}`
    }

    setReportPreview(null)
    const reportPreviewData: IDataReport[] = []

    reportPreview.data.forEach((r: any) => {
      const existingEl = reportPreviewData?.find((d: any) => d.account === r.socialProfile._id)

      // se esiste già l'account social allora aggiungo solo la metrica
      if (existingEl) {
        existingEl?.metrics?.push(r.metricRef)
      } else {
        reportPreviewData.push({
          account: r.socialProfile._id,
          provider: r.socialProfile.provider,
          metrics: [r.metricRef],
          since: sinceDate,
          until: untilDate,
        })
      }
    })

    const report: IReportConfig = await Reports.updateReport({
      data: reportPreviewData,
      info: reportPreview.info,
      reportId: reportPreview._id,
    })

    const addReport: IReportConfig = await getReport(report._id)

    onSetReportName(addReport.info.reportName!)
    setReportPreview(addReport)
  }

  const handleRefresh = async () => {
    createReportConfigFromReport()
  }

  const refreshReportToActualDate = async () => {
    const startDate = dayjs(selectedDate?.startDate ?? dayjs(reportPreview?.since))
    const endDate = dayjs(selectedDate?.endDate ?? dayjs(reportPreview?.until))
    const daysDifference = endDate.diff(startDate, 'day')
    if (daysDifference === 0) createReportConfigFromReport(true)

    const today = dayjs()
    const newStartDate = today.subtract(daysDifference, 'day')

    setSelectedDatesReports({ startDate: newStartDate, endDate: today })
  }

  /* ------------------- USEEFFECT  */

  useEffect(() => unsubSelectedDate, [])

  useEffect(() => {
    if (reportPreview && reportPreview.info.reportName !== inputReportName) {
      setInputReportName(reportPreview.info.reportName)
    }
  }, [reportPreview])

  useEffect(() => {
    if (reportPreviewName && reportPreviewName !== inputReportName) {
      setInputReportName(reportPreviewName)
    }
  }, [reportPreviewName])

  useEffect(() => {
    handleRefresh()
  }, [selectedDate])

  return window.location.pathname.split('/')[3] && reportPreview ? (
    <HeaderDashboardAnalyticsContainer className={`header_report ${reportId === reportPreview?._id ? 'show' : ''}`}>
      {/* ---------------------- HEADER LEFT  __ last report save text*/}
      <HeaderItemContainer className="header_icon_left">
        <AddMetricOnReport />
        {isVisible('analytics.reporHeader.downloadreportBtn') && <DownloadReportButton />}
      </HeaderItemContainer>

      {/* ---------------------- HEADER CENTER __ report title*/}
      <HeaderItemContainer className="header_text_center-container">
        {/* <Icons.arrowLeft
          width={24}
          height={24}
          fill={AppStore.theme.o.darkGrey}
          style={{ cursor: 'pointer' }}
          onClick={goBackToAnalytics}
        /> */}

        <InputAndDateContainer>
          <InputEditableContent
            value={inputReportName ?? ''}
            submitAction={(currentReportName) => changeReportName(reportId!, currentReportName, false)}
            defaultValue={inputReportName}
          />
          <LastModifyDate>
            {isReportUpdating ? (
              <>{T.info.updating}</>
            ) : (
              <>
                {T.analytics.lastSave}: {formatDate(reportUpdateDate ?? '')}
              </>
            )}
          </LastModifyDate>
        </InputAndDateContainer>
      </HeaderItemContainer>

      {/* ---------------------- HEADER RIGHT __ date picker*/}
      <HeaderItemContainer className="header_text_right">
        <RefreshButton refreshAction={refreshReportToActualDate} />

        <DateRangeFilter
          selectedDate={selectedDate}
          setSelectedDate={onChangeSelectedDateReports}
          reportDate={[reportPreview?.since, reportPreview?.until]}
        />

        {isVisible('analytics.reporHeader.dateCompareDropdown') && (
          <DateCompareDropdown onSelectedDateChange={onSelectedDateCompareChange} selectedDateFilter={selectedDate} />
        )}
      </HeaderItemContainer>
    </HeaderDashboardAnalyticsContainer>
  ) : (
    <HeaderDashboardAnalyticsContainer
      className={`header_report ${reportId === reportPreview?._id ? 'show' : ''}`}
    ></HeaderDashboardAnalyticsContainer>
  )
}

const HeaderDashboardAnalyticsContainer = styled(Box)`
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &.show {
    opacity: 1;
  }
  &.header_report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
  }
`

const HeaderItemContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;

  &.header_icon_left {
    flex: 1;
    color: ${() => AppStore.theme.o.darkGrey};
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 11.63px;
    letter-spacing: 1%;
    align-self: flex-end;

    justify-content: flex-start;
  }

  &.header_text_center-container {
    flex: 1;

    justify-content: center;
    align-items: center;
  }

  &.header_text_right {
    flex: 1;
    justify-content: flex-end;
  }
`

const InputAndDateContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
`

const LastModifyDate = styled.p`
  flex: 1;
  color: ${() => AppStore.theme.o.darkGrey};
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.63px;
  letter-spacing: 1%;
  align-self: center;
  justify-content: center;
  min-width: 200px;
  text-align: center;
`

//todo: fare in modo che si chiuda al scegliere una data
// passare valore effettivo al personalizza come valore
// sistemare funzionamento del dropdown animate
