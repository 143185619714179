import { Box } from 'components/Box'
import React, { useEffect, useState } from 'react'
import { CalendarPost } from './CalendarPost'
import { CalendarAddPostPlaceholder } from './CalendarAddPostPlaceholder'
import { AppStore, T } from 'utils'
import styled from '@emotion/styled'
import { Post, SocialMedias } from 'types'
import moment from 'moment'
import { gsap } from 'gsap'
import { Icons } from 'components/Icons'
import { ISocialFilters } from 'stores/interfaces/CalendarPageInterface'
import { IPostVariant } from 'types/PostInterface'

interface Props {
  posts: IPostVariant[]
  filterPostsModal: (post: IPostVariant, _filterDate) => void
  socialFilters: ISocialFilters[]
  diff: number
  deletePost: (id: string, isBulk: boolean) => void
  d: any
  year: number
  month: number
  goToPost?: (bol: boolean) => void
}

export default function PostModal(props: Props) {
  const [selectedList, setSelectedList] = useState<Array<IPostVariant>>([])
  const [filteredList, setFilteredList] = useState<Array<IPostVariant>>([])
  const [posts, setPosts] = useState<Array<IPostVariant>>([])
  const [activateHover, setActivateHover] = useState<boolean>(false)

  useEffect(() => {
    setActivateHover(selectedList.length > 0 ? true : false)
  }, [selectedList])

  useEffect(() => {
    const filterList = props.posts.filter((p) =>
      props.filterPostsModal(p, `${props.d}/${props.month + 1}/${props.year}`)
    )
    setFilteredList(filterList)
    setPosts(filterList)
    if (filterList.length > 0) {
      increseWidth(filterList.length)
      if (filterList.length > 4) {
        increaseHeight()
      }
    }
  }, [props.posts])

  const deleteSelected = () => {
    selectedList.forEach((post) => props.deletePost(post._id!, selectedList.length > 1))
    AppStore.closeAllModal()
  }

  const setIsSelected = (post: IPostVariant) => {
    if (selectedList.includes(post)) {
      setSelectedList(selectedList.filter((element) => element.data._id !== post.data._id))
    } else {
      setSelectedList([...selectedList, post])
    }
  }

  const increseWidth = (numberOfItems: number) => {
    const array = document.getElementsByClassName('ant-modal')
    const realNumberOfItems = props.diff >= 0 ? numberOfItems + 1 : numberOfItems
    gsap.to(array[0], {
      width: realNumberOfItems >= 4 ? 1184 : 88 + realNumberOfItems * 272,
      duration: 0,
    })
  }
  const increaseHeight = () => {
    const array = document.getElementsByClassName('ant-modal')
    gsap.to(array[0], {
      height: 656,
      duration: 0,
    })
  }

  return (
    <Box style={{ maxHeight: filteredList.length > 4 ? 656 : 363 }} relative hideBar>
      <Box
        style={{
          overflow: 'auto',
          maxHeight: filteredList.length > 4 ? 656 : 363,
        }}
        scroll
        hideBar
      >
        <Box row style={{ flexWrap: 'wrap', paddingInline: 40, paddingTop: 40, paddingBottom: 40 }}>
          {posts
            .sort((a, b) => moment(a.data.date).diff(moment(b.data.date)))
            .map(
              (post, index) =>
                props.socialFilters.filter((filter) => filter.social === post.info.account.provider && filter.active)
                  .length > 0 && (
                  <CalendarPost
                    index={index}
                    key={index}
                    post={post}
                    onDelete={props.deletePost}
                    setIsSelected={setIsSelected}
                    isSelected={selectedList.includes(post) ? true : false}
                    alwaysHover={activateHover}
                    goToPost={props.goToPost}
                  />
                )
            )}
          {props.diff >= 0 && (
            <CalendarAddPostPlaceholder
              date={`${props.d}/${props.month + 1}/${props.year}`}
              goToPost={props.goToPost}
            />
          )}
        </Box>
      </Box>
      {selectedList.length > 0 && (
        <Box center width={'100%'} height={40} style={{ backgroundColor: 'transparent' }} mt={8}>
          <Box
            center
            width={562}
            height={40}
            style={{ backgroundColor: AppStore.theme.o.surface, borderRadius: 14 }}
            row
          >
            <Box
              width={88}
              center
              onClick={() => AppStore.closeAllModal()}
              style={{ color: AppStore.theme.o.black, fontWeight: 500 }}
              removeHover
            >
              {T.calendarPage.cancel}
            </Box>
            <VerticalLine />
            <Box
              width={188}
              center
              onClick={() => {
                setSelectedList([])
              }}
              style={{ color: AppStore.theme.o.black, fontWeight: 500 }}
              row
              removeHover
            >
              <Box
                style={{ backgroundColor: AppStore.theme.o.lightGrey, borderRadius: 4 }}
                width={24}
                height={24}
                center
                mr={8}
              >
                <Icons.notSelectedBox />
              </Box>
              {T.calendarPage.deselectAll}
            </Box>
            <VerticalLine />
            <Box
              width={188}
              center
              onClick={() => {
                setSelectedList(
                  props.posts.filter((p) => props.filterPostsModal(p, `${props.d}/${props.month + 1}/${props.year}`))
                )
              }}
              style={{ color: AppStore.theme.o.black, fontWeight: 500 }}
              row
              removeHover
            >
              <Box
                style={{ backgroundColor: AppStore.theme.o.lightGreen, borderRadius: 4 }}
                width={24}
                height={24}
                center
                mr={8}
              >
                {selectedList.length === filteredList.length ? <Icons.selectedBox /> : <Icons.notSelectedBox />}
              </Box>
              {T.calendarPage.selectAll}
            </Box>
            <VerticalLine />
            <Box
              width={88}
              center
              onClick={deleteSelected}
              style={{ color: AppStore.theme.o.red, fontWeight: 500, marginRight: 8, paddingLeft: 8 }}
              row
              removeHover
            >
              {T.postPage.delete}
              <TrashIcon />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

const TrashIcon = styled(Icons.squareTrash)`
  fill: ${() => AppStore.theme.o.red};
  width: 24px;
  height: 24px;
`

const VerticalLine = styled.div`
  height: 40px;
  width: 1px;
  background-color: ${() => AppStore.theme.o.grey};
`
