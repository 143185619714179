/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useAiGenerationStore } from 'stores/AiGenerationStore'
import { AppStore, T } from 'utils'
import {
  INFINITE_SYMBOL,
  OWNER_COPIES_INFINITE,
  OWNER_COPIES_TOTAL,
  OWNER_COPIES_USED,
} from 'utils/Constants/LimitUserConstants'

/* ------------------- INTERFACES  */
interface Props {}

export const GenerationsRemaining = ({}: Props) => {
  /* ------------------- STATE  */
  const [copiesUsed, setCopiesUsed] = useState(OWNER_COPIES_USED)

  /* ------------------- ZUSTAND  */
  const { generationsQueue } = useAiGenerationStore((s) => ({ generationsQueue: s.generationsQueue }))

  /* ------------------- USEFFECT  */
  useEffect(() => {
    setCopiesUsed(OWNER_COPIES_USED + generationsQueue)
  }, [generationsQueue])

  return (
    <GenerationsRemainingContainer>
      <span>
        {T.textGenerator.remainingGeneration} {copiesUsed}/
        {OWNER_COPIES_INFINITE ? INFINITE_SYMBOL : OWNER_COPIES_TOTAL}
      </span>
    </GenerationsRemainingContainer>
  )
}

const GenerationsRemainingContainer = styled.p`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: 'Lexend';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
  margin-left: 40px;
  margin-top: 4px;
`
