/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { AppStore } from 'utils'

/* ------------------- INTERFACES  */
interface Props {}

export const ResultsPreviewStory = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  return (
    <ResultsPreviewStoryContainer>
      <MediaContainerPost width={390} height={678} arrowsPositions={15} />
      <ProfileContainer>
        <ProfileImg src={activePost?.info?.account?.picture} />
        <ProfileName>{activePost?.info?.account?.name}</ProfileName>
      </ProfileContainer>
    </ResultsPreviewStoryContainer>
  )
}

const ResultsPreviewStoryContainer = styled(Box)`
  width: 390px;
  height: 678px;
  position: relative;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const ProfileContainer = styled(Box)`
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

const ProfileName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.3px;
`
