/* ------------------- IMPORTS */
import { TSubsection } from 'types/CreatePostInferfaces'
import { BasicContent } from './BasicComponents/BasicContent'
import { DraftsContent } from './DraftsComponents/DraftsContent'
import { Template } from '../TextComponents/Template/Template'

/* ------------------- INTERFACES  */
interface Props {
  subsection: TSubsection
}

export const PreviewContent = ({ subsection }: Props) => {
  const content = {
    basic: <BasicContent />,
    drafts: <DraftsContent />,
    templates: <Template />,
  }

  return content[subsection]
}
