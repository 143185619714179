export { default as l_facebook } from '../icons/logo_facebook.svg'
export { default as l_google } from '../icons/logo_google.svg'

export { default as google } from '../icons/social/G-Business.png'
export { default as linkedin } from '../icons/social/Linkedin.png'
export { default as facebook } from './social/Facebook.png'
export { default as instagram } from './social/Instagram.png'
export { default as pinterest } from './social/Pinterest.png'
export { default as tiktok } from './social/Tiktok.png'
export { default as x } from './social/X.png'

//? Change to "Youtube2D.png" if youtube wants the official icon
// export { default as youtube } from './social/YouTube2D.png'
export { default as youtube } from './social/Youtube.png'

export { default as editorIcon } from './editorIcon.png'

export { default as grid } from '../icons/grid.svg'
export { default as hashtag } from '../icons/hashtag-icon.svg'
export { default as list } from '../icons/list.svg'
export { default as questionMark } from '../icons/questionmark-icon.svg'

import { ReactComponent as CalendarPost } from './CalendarPost.svg'
import { ReactComponent as Arrow, ReactComponent as ChangeArrow } from './ChangeArrow.svg'
import { ReactComponent as ImgEmpty } from './ImgEmpty.svg'
import { ReactComponent as AccountPlaceholder } from './account-placeholder.svg'
import { ReactComponent as ActiveBox } from './active-box.svg'
import { ReactComponent as Add } from './add-icon.svg'
import { ReactComponent as AddQueue } from './add-queue.svg'
import { ReactComponent as AddImageWorkspace } from './addImageWorkspace.svg'
import { ReactComponent as AddSocial } from './addSocial.svg'
import { ReactComponent as AddBoard } from './addboard.svg'
import { ReactComponent as Advanced } from './advanced.svg'
import { ReactComponent as Alarms } from './alarms.svg'
import { ReactComponent as AllSocials } from './allSocial.svg'
import { ReactComponent as Analytics } from './analytics-icon.svg'
import { ReactComponent as ArrowLeft } from './arrowLeft.svg'
import { ReactComponent as ArrowRight } from './arrowRight.svg'
import { ReactComponent as Logo } from './asters-icon.svg'
import { ReactComponent as AstersAi } from './astersAi.svg'
import { ReactComponent as backWard } from './backward-icon.svg'
import { ReactComponent as Base } from './base.svg'
import { ReactComponent as BoardsDiscover } from './boardsDiscover.svg'
import { ReactComponent as BoardsIconMenu } from './boardsIconMenu.svg'
import { ReactComponent as BookMark } from './bookMark.svg'
import { ReactComponent as BoxedPlus } from './boxed-plus.svg'
import { ReactComponent as BoxGreen } from './boxgreen.svg'
import { ReactComponent as Calendar } from './calendar-icon.svg'
import { ReactComponent as Cancel } from './cancel.svg'
import { ReactComponent as Card } from './card.svg'
import { ReactComponent as Carosello } from './carosello.svg'
import { ReactComponent as Carousel } from './carousel-icon.svg'
import { ReactComponent as Change } from './change.svg'
import { ReactComponent as ChangeLink } from './changeLink.svg'
import { ReactComponent as ChangeWorkspace } from './changeWorkspace.svg'
import { ReactComponent as CharacterCouter } from './characterCouter.svg'
import { ReactComponent as checkbox } from './checkbox.svg'
import { ReactComponent as checkBoxGrey } from './checkboxgrey.svg'
import { ReactComponent as Close } from './close-icon.svg'
import { ReactComponent as CloseIcon } from './closeIcon.svg'
import { ReactComponent as Close_icon } from './close_icon.svg'
import { ReactComponent as ClosedMail } from './closedMail.svg'
import { ReactComponent as Code } from './code.svg'
import { ReactComponent as Collaboration } from './collaboration.svg'
import { ReactComponent as CommentsWithPoints } from './commentsWithPoints.svg'
import { ReactComponent as TagIcon } from './coolIcon.svg'
import { ReactComponent as CopyLink } from './copyLink.svg'
import { ReactComponent as CreateFolderImgSection } from './create-folder-img-section.svg'
import { ReactComponent as createPostCalendar } from './create-post-calendar-icon.svg'
import { ReactComponent as CreatePost } from './create-post-icon.svg'
import { ReactComponent as CreateFolder } from './createFolder.svg'
import { ReactComponent as CreditCard } from './creditCard.svg'
import { ReactComponent as Crop } from './crop.svg'
import { ReactComponent as DarkTheme } from './dark-theme.svg'
import { ReactComponent as Dashboard } from './dashboard-icon.svg'
import { ReactComponent as Delete } from './delete-icon.svg'
import { ReactComponent as Desktop } from './desktop.svg'
import { ReactComponent as DoubleArrowLeft } from './doubleArrowLeft.svg'
import { ReactComponent as DoubleArrowRight } from './doubleArrowRight.svg'
import { ReactComponent as DownIcon } from './down-icon.svg'
import { ReactComponent as Download } from './download-icon.svg'
import { ReactComponent as DownloadPreview } from './downloadPreview.svg'
import { ReactComponent as DragDrop } from './dragDrop.svg'
import { ReactComponent as DropdownArrow } from './dropdownArrow.svg'
import { ReactComponent as Edit } from './edit-icon.svg'
import { ReactComponent as EmailIconFillAble } from './emailIconFillAble.svg'
import { ReactComponent as EmailToConfirm } from './emailToConfirm.svg'
import { ReactComponent as EmojiIcons } from './emojiIcons.svg'
import { ReactComponent as FacebookIcon } from './facebook-icon.svg'
import { ReactComponent as FacebookPreviewFooter } from './facebook-preview-footer.svg'
import { ReactComponent as FacebookPreviewIcon } from './facebook-preview-icon.svg'
import { ReactComponent as FacebookIconFillAble } from './facebookIconFillAble.svg'
import { ReactComponent as FacebookIconNoColorIcon } from './facebookIconNoColorIcon.svg'
import { ReactComponent as FacebookLike } from './facebookLike.svg'
import { ReactComponent as FacebookLikeDarkMode } from './facebookLikeDarkMode.svg'
import { ReactComponent as FbComment } from './fbComment.svg'
import { ReactComponent as FbLike } from './fbLike.svg'
import { ReactComponent as FbMap } from './fbMap.svg'
import { ReactComponent as FbShare } from './fbShare.svg'
import { ReactComponent as Folder } from './folder.svg'
import { ReactComponent as FollowIconMenu } from './followIconMenu.svg'
import { ReactComponent as forWard } from './forward-icon.svg'
import { ReactComponent as FrameIndicator } from './frameIndicator.svg'
import { ReactComponent as Germany } from './germany.svg'
import { ReactComponent as GoogleMyBusinessPreviewIcon } from './google-preview-business-icon.svg'
import { ReactComponent as GoogleMyBusinessIcon } from './googleMyBusiness-icon.svg'
import { ReactComponent as Hash } from './hash.svg'
import { ReactComponent as Heart } from './heart.svg'
import { ReactComponent as ImgFolderMark } from './imgFolderMark.svg'
import { ReactComponent as Inbox } from './inbox-icon.svg'
import { ReactComponent as Info } from './info.svg'
import { ReactComponent as InstagramIcon } from './instagram-icon.svg'
import { ReactComponent as InstagramPreviewFooter } from './instagram-preview-footer.svg'
import { ReactComponent as InstagramPreviewIcon } from './instagram-preview-icon.svg'
import { ReactComponent as InstagramBookMark } from './instagramBookMark.svg'
import { ReactComponent as InstagramComment } from './instagramComment.svg'
import { ReactComponent as InstagramLike } from './instagramLike.svg'
import { ReactComponent as InstagramNoColorIcon } from './instagramNoColorIcon.svg'
import { ReactComponent as InstagramShare } from './instagramShare.svg'
import { ReactComponent as IstagramPrimaryAction } from './istagramPrimaryAction.svg'
import { ReactComponent as Italy } from './italy.svg'
import { ReactComponent as LargeGeneration } from './largeGeneration.svg'
import { ReactComponent as LightTheme } from './light-theme.svg'
import { ReactComponent as Link } from './link.svg'
import { ReactComponent as LinkedinIcon } from './linkedin-icon.svg'
import { ReactComponent as LinkedinPreviewIcon } from './linkedin-preview-icon.svg'
import { ReactComponent as LinkedinComment } from './linkedinComment.svg'
import { ReactComponent as LinkedinIconFillAble } from './linkedinIconFillAble.svg'
import { ReactComponent as LinkedinIconNoColorIcon } from './linkedinIconNoColorIcon.svg'
import { ReactComponent as LinkedinLike } from './linkedinLike.svg'
import { ReactComponent as LinkedinRepost } from './linkedinRepost.svg'
import { ReactComponent as LinkedinSend } from './linkedinSend.svg'
import { ReactComponent as LiveChatIcon } from './live-chat-icon.svg'
import { ReactComponent as Lock } from './lock.svg'
import { ReactComponent as LogoAstersFullSmall } from './logo_asters_full_small.svg'
import { ReactComponent as Media } from './media-icon.svg'
import { ReactComponent as Medias } from './medias.svg'
import { ReactComponent as MediumGeneration } from './mediumGeneration.svg'
import { ReactComponent as Message } from './message.svg'
import { ReactComponent as MetaGrid } from './metaGrid.svg'
import { ReactComponent as MetaStories } from './metaStories.svg'
import { ReactComponent as Minus } from './minus.svg'
import { ReactComponent as Mobile } from './mobile.svg'
import { ReactComponent as Move } from './move-icon.svg'
import { ReactComponent as NavbarMask } from './navbar-bubble-mask.svg'
import { ReactComponent as NewMedia } from './newMedia.svg'
import { ReactComponent as NewVideo } from './newVideo.svg'
import { ReactComponent as NoImage } from './noImage.svg'
import { ReactComponent as Norway } from './norway.svg'
import { ReactComponent as NotSelectedBox } from './not-selected-box.svg'
import { ReactComponent as NotCheckedBox } from './notCheckedBox.svg'
import { ReactComponent as NotLock } from './notLock.svg'
import { ReactComponent as Notifications } from './notifications.svg'
import { ReactComponent as OnBoarding } from './onboarding.svg'
import { ReactComponent as OpensSectionArrow } from './opensSectionArrow.svg'
import { ReactComponent as OptionsIcon } from './optionsIcon.svg'
import { ReactComponent as Padlock } from './padlock.svg'
import { ReactComponent as PauseVideoIcon } from './pauseVideoIcon.svg'
import { ReactComponent as Plan } from './plan.svg'
import { ReactComponent as PlanPost } from './planPost.svg'
import { ReactComponent as PlayVideo } from './playVideo.svg'
import { ReactComponent as PlayVideoIcon } from './playVideoIcon.svg'
import { ReactComponent as Plus } from './plus.svg'
import { ReactComponent as Position } from './position.svg'
import { ReactComponent as Post } from './post.svg'
import { ReactComponent as PostInsta } from './postInsta.svg'
import { ReactComponent as Preview } from './preview.svg'
import { ReactComponent as PrivateBoard } from './privateBoard.svg'
import { ReactComponent as Profile } from './profile.svg'
import { ReactComponent as Promotion } from './promotion.svg'
import { ReactComponent as PublicBoard } from './publicBoard.svg'
import { ReactComponent as PublishNow } from './publish-now-icon.svg'
import { ReactComponent as ReadingTime } from './readingTime.svg'
import { ReactComponent as ReferalGift } from './referal-gift.svg'
import { ReactComponent as Refresh } from './refresh.svg'
import { ReactComponent as Reshare } from './reshare.svg'
import { ReactComponent as Reversecheckbox } from './reverse_checkbox.svg'
import { ReactComponent as SadBoard } from './sadBoard.svg'
import { ReactComponent as SadSearchbar } from './sadSearchbar.svg'
import { ReactComponent as SadStar } from './sadStar.svg'
import { ReactComponent as SadFace } from './sadface.svg'
import { ReactComponent as SaveDraft } from './save-draft.svg'
import { ReactComponent as Save } from './save.svg'
import { ReactComponent as SaveIcon } from './saveIcon.svg'
import { ReactComponent as SearchIcon } from './search-icon.svg'
import { ReactComponent as Search } from './search.svg'
import { ReactComponent as SearchIconMenu } from './searchIconMenu.svg'
import { ReactComponent as SelectedBox } from './selected-box.svg'
import { ReactComponent as ServiceCenterIcon } from './service-center-icon.svg'
import { ReactComponent as SettingsIcon } from './setting-icon.svg'
import { ReactComponent as Settings } from './settings.svg'
import { ReactComponent as Share } from './share.svg'
import { ReactComponent as ShortGeneration } from './shortGeneration.svg'
import { ReactComponent as SimpleCheck } from './simpleCheck.svg'
import { ReactComponent as SmallEdit } from './smallEdit.svg'
import { ReactComponent as SmallGps } from './smallGps.svg'
import { ReactComponent as SmallLink } from './smallLink.svg'
import { ReactComponent as SocialProfile } from './social-profile.svg'
import { ReactComponent as Socials } from './socials.svg'
import { ReactComponent as SortIcon } from './sortIcon.svg'
import { ReactComponent as Spain } from './spain.svg'
import { ReactComponent as SquareMini } from './squareMini.svg'
import { ReactComponent as SquareTrash } from './squareTrash.svg'
import { ReactComponent as Star } from './star.svg'
import { ReactComponent as StarDiscover } from './starDiscover.svg'
import { ReactComponent as StarEmpty } from './starEmpty.svg'
import { ReactComponent as StarFilled } from './starFilled.svg'
import { ReactComponent as Subscription } from './subscription.svg'
import { ReactComponent as Tablet } from './tablet.svg'
import { ReactComponent as TagCreate } from './tagCreate.svg'
import { ReactComponent as TagDelete } from './tagDelete.svg'
import { ReactComponent as TagEdit } from './tagEdit.svg'
import { ReactComponent as TagEditDelete } from './tagEditDelete.svg'
import { ReactComponent as TeamMembers } from './teamMembers.svg'
import { ReactComponent as TelegramIconFillAble } from './telegramIconFillAble.svg'
import { ReactComponent as TemplateImage } from './template-image.svg'
import { ReactComponent as TemplateVideo } from './template-video.svg'
import { ReactComponent as Template } from './template.svg'
import { ReactComponent as Testi } from './testi.svg'
import { ReactComponent as Text } from './text.svg'
import { ReactComponent as TextDiffculty } from './textDifficulty.svg'
import { ReactComponent as ThumbDown } from './thumbDown.svg'
import { ReactComponent as ThumbUp } from './thumbUp.svg'
import { ReactComponent as TiktokAdd } from './tiktokAdd.svg'
import { ReactComponent as TiktokAllBottomIcons } from './tiktokAllBottomIcons.svg'
import { ReactComponent as TiktokAllTopIcons } from './tiktokAllTopIcons.svg'
import { ReactComponent as ToolFolder } from './toolFolder.svg'
import { ReactComponent as Trash } from './trash.svg'
import { ReactComponent as TrashBin } from './trashBin.svg'
import { ReactComponent as TrashRole } from './trashRole.svg'
import { ReactComponent as TwitterIcon } from './twitter-icon.svg'
import { ReactComponent as TwitterPreviewFooter } from './twitter-preview-footer.svg'
import { ReactComponent as TwitterPreviewIcon } from './twitter-preview-icon.svg'
import { ReactComponent as TwitterComment } from './twitterComment.svg'
import { ReactComponent as TwitterHeart } from './twitterHeart.svg'
import { ReactComponent as TwitterIconFillAble } from './twitterIconFillAble.svg'
import { ReactComponent as TwitterIconNoColorIcon } from './twitterIconNoColorIcon.svg'
import { ReactComponent as TwitterLikes } from './twitterLikes.svg'
import { ReactComponent as TwitterRepost } from './twitterRepost.svg'
import { ReactComponent as TwitterShare } from './twitterShare.svg'
import { ReactComponent as DarkUploadFile } from './uploadDark.svg'
import { ReactComponent as UploadFile } from './uploadFile.svg'
import { ReactComponent as Usa } from './usa.svg'
import { ReactComponent as User } from './user.svg'
import { ReactComponent as UserCC } from './userCC.svg'
import { ReactComponent as Users } from './users.svg'
import { ReactComponent as Video } from './video-icon.svg'
import { ReactComponent as WhatAppIconFillAble } from './whatAppIconFillAble.svg'
import { ReactComponent as WorkSpaces } from './workspaces.svg'
import { ReactComponent as Zoom } from './zoom.svg'
import { ReactComponent as NotifyBell } from './notifyBell.svg'
import { ReactComponent as FourDotMenu } from './fourDotMenu.svg'
import { ReactComponent as CryBell } from './cryBell.svg'
import { ReactComponent as NotifyToRead } from './notifyToRead.svg'
import { ReactComponent as NotifyReaded } from './notifyReaded.svg'
import { ReactComponent as YoutubeDownload } from './youtubeDownload.svg'
import { ReactComponent as YoutubeSettings } from './youtubeSettings.svg'
import { ReactComponent as Pen } from './pen.svg'
import { ReactComponent as Expand } from './expand.svg'
import { ReactComponent as Collapse } from './collapse.svg'
import { ReactComponent as Utm } from './utm.svg'

import { ReactComponent as RoundedCheckmark } from './roundedCheckmark.svg'
import { ReactComponent as DropdownFilter } from './dropdownFilter.svg'
import { ReactComponent as SetUp } from './setUp.svg'
import { ReactComponent as Social } from './social.svg'
import { ReactComponent as Metriche } from './metriche.svg'
import { ReactComponent as Condividi } from './condividi.svg'
import { ReactComponent as SalvaPdf } from './salvaPdf.svg'
import { ReactComponent as LinkIcons } from './linkIcons.svg'
import { ReactComponent as DashboardIcon } from './dashboardIcon.svg'
import { ReactComponent as CreateIcons } from './create.svg'
import { ReactComponent as SadReport } from './sadReport.svg'
import { ReactComponent as SadBall } from './sadBall.svg'
import { ReactComponent as SaveIcons } from './saveIcons.svg'
import { ReactComponent as EnterpriseCity } from './enterpriseCity.svg'
import { ReactComponent as XIcon } from './xIcon.svg'
import { ReactComponent as AddSocialAccount } from './add-social-account.svg'
import { ReactComponent as ThreeDots } from './threeDots.svg'
import { ReactComponent as YoutubeAllTopIcons } from './youtubeAllTopIcons.svg'
import { ReactComponent as YoutubeAllRightIcons } from './youtubeAllRightIcons.svg'
import { ReactComponent as YoutubeAllBottomIcons } from './youtubeAllBottomIcons.svg'
import { ReactComponent as PostWarning } from './postWarning.svg'
import { ReactComponent as AddInstagram } from './addInstagram.svg'
import { ReactComponent as CloseButton } from './closeButton.svg'
import { ReactComponent as Compare } from './compare.svg'
import { ReactComponent as DragDropAnalytics } from './dragDropAnalytics.svg'
import { ReactComponent as SadFaceWithPen } from './sadFaceWithPen.svg'
import { ReactComponent as MagicWand } from './magicWand.svg'
import { ReactComponent as Sun } from './sun.svg'
import { ReactComponent as DottedLine } from './dottedLine.svg'
import { ReactComponent as AutopilotElectric } from './autopilotElectric.svg'
import { ReactComponent as TextIcon } from './textIcon.svg'
import { ReactComponent as CalendarIcon } from './calendarIcon.svg'
import { ReactComponent as PositiveCheck } from './positiveCheck.svg'
import { ReactComponent as NegativeCheck } from './negativeCheck.svg'
import { ReactComponent as Generation } from './generation.svg'
import { ReactComponent as Person } from './person.svg'
import { ReactComponent as Collaborators } from './collaborators.svg'
import { ReactComponent as HappyPost } from './happyPost.svg'

export const img = {
  logo: Logo,
  profile: Profile,
  analytics: Analytics,
  inbox: Inbox,
  media: Media,
  calendar: Calendar,
  dashboard: Dashboard,
  createPost: CreatePost,
  createPostCalendar: createPostCalendar,
  backWard: backWard,
  forWard: forWard,
  close: Close,
  carousel: Carousel,
  video: Video,
  navbarMask: NavbarMask,
  settings: Settings,
  notifications: Notifications,
  alarms: Alarms,
  onBoarding: OnBoarding,
  boxedPlus: BoxedPlus,
  plus: Plus,
  minus: Minus,
  instagramPreviewIcon: InstagramPreviewIcon,
  facebookPreviewIcon: FacebookPreviewIcon,
  twitterPreviewIcon: TwitterPreviewIcon,
  linkedinPreviewIcon: LinkedinPreviewIcon,
  googleMyBusinessPreviewIcon: GoogleMyBusinessPreviewIcon,
  instagramPreviewFooter: InstagramPreviewFooter,
  facebookPreviewFooter: FacebookPreviewFooter,
  twitterPreviewFooter: TwitterPreviewFooter,
  socialProfile: SocialProfile,
  workSpaces: WorkSpaces,
  subscription: Subscription,
  lightTheme: LightTheme,
  darkTheme: DarkTheme,
  folder: Folder,
  edit: Edit,
  add: Add,
  selectedBox: SelectedBox,
  notSelectedBox: NotSelectedBox,
  download: Download,
  delete: Delete,
  move: Move,
  addQueue: AddQueue,
  plan: Plan,
  publishNow: PublishNow,
  saveDraft: SaveDraft,
  downIcon: DownIcon,
  instagramIcon: InstagramIcon,
  facebookIcon: FacebookIcon,
  twitterIcon: TwitterIcon,
  linkedinIcon: LinkedinIcon,
  googleMyBusinessIcon: GoogleMyBusinessIcon,
  liveChatIcon: LiveChatIcon,
  serviceCenterIcon: ServiceCenterIcon,
  accountPlaceholder: AccountPlaceholder,
  activeBox: ActiveBox,
  trash: Trash,
  templateImage: TemplateImage,
  templateVideo: TemplateVideo,
  message: Message,
  smallGps: SmallGps,
  post: Post,
  usa: Usa,
  germany: Germany,
  italy: Italy,
  spain: Spain,
  norway: Norway,
  heart: Heart,
  text: Text,
  checkbox: checkbox,
  noImage: NoImage,
  zoom: Zoom,
  notCheckedBox: NotCheckedBox,
  change: Change,
  logoAstersFullSmall: LogoAstersFullSmall,
  istagramPrimaryAction: IstagramPrimaryAction,
  bookMark: BookMark,
  imgFolderMark: ImgFolderMark,
  reversecheckbox: Reversecheckbox,
  uploadFile: UploadFile,
  createFolder: CreateFolder,
  emojiIcons: EmojiIcons,
  squareMini: SquareMini,
  changeArrow: ChangeArrow,
  postInsta: PostInsta,
  arrow: Arrow,
  createFolderImgSection: CreateFolderImgSection,
  imgEmpty: ImgEmpty,
  referalGift: ReferalGift,
  reshare: Reshare,
  card: Card,
  share: Share,
  linkedinIconFillAble: LinkedinIconFillAble,
  facebookIconFillAble: FacebookIconFillAble,
  twitterIconFillAble: TwitterIconFillAble,
  whatAppIconFillAble: WhatAppIconFillAble,
  telegramIconFillAble: TelegramIconFillAble,
  emailIconFillAble: EmailIconFillAble,
  link: Link,
  user: User,
  creditCard: CreditCard,
  copyLink: CopyLink,
  openSectionArrow: OpensSectionArrow,

  darkUploadFile: DarkUploadFile,
  preview: Preview,
  testi: Testi,
  medias: Medias,
  socials: Socials,
  astersAi: AstersAi,
  template: Template,
  carosello: Carosello,
  allSocials: AllSocials,
  instagramNoColorIcon: InstagramNoColorIcon,
  facebookIconNoColorIcon: FacebookIconNoColorIcon,
  linkedinIconNoColorIcon: LinkedinIconNoColorIcon,
  twitterIconNoColorIcon: TwitterIconNoColorIcon,
  save: Save,
  code: Code,
  planPost: PlanPost,
  userCircle: UserCC,
  dragDrop: DragDrop,
  position: Position,
  characterCouter: CharacterCouter,
  readingTime: ReadingTime,
  addSocial: AddSocial,
  base: Base,
  advanced: Advanced,
  toolFolder: ToolFolder,
  textDifficulty: TextDiffculty,
  fbMap: FbMap,
  facebookLike: FacebookLike,
  facebookLikeDarkMode: FacebookLikeDarkMode,
  fbLike: FbLike,
  fbComment: FbComment,
  fbShare: FbShare,
  linkedinLike: LinkedinLike,
  linkedinComment: LinkedinComment,
  linkedinRepost: LinkedinRepost,
  linkedinSend: LinkedinSend,
  instagramLike: InstagramLike,
  instagramComment: InstagramComment,
  instagramShare: InstagramShare,
  instagramBookMark: InstagramBookMark,
  twitterComment: TwitterComment,
  twitterRepost: TwitterRepost,
  twitterHeart: TwitterHeart,
  twitterLikes: TwitterLikes,
  twitterShare: TwitterShare,
  tagIcon: TagIcon,
  smallEdit: SmallEdit,
  smallLink: SmallLink,
  tagEdit: TagEdit,
  tagDelete: TagDelete,
  tagCreate: TagCreate,
  tagEditDelete: TagEditDelete,
  mobile: Mobile,
  tablet: Tablet,
  desktop: Desktop,
  downloadPreview: DownloadPreview,
  promotion: Promotion,
  changeLink: ChangeLink,
  star: Star,
  playVideo: PlayVideo,
  cancel: Cancel,
  newMedia: NewMedia,
  newVideo: NewVideo,
  shortGeneration: ShortGeneration,
  mediumGeneration: MediumGeneration,
  largeGeneration: LargeGeneration,
  emailToConfirm: EmailToConfirm,
  trashRole: TrashRole,
  optionsIcon: OptionsIcon,
  sortIcon: SortIcon,
  squareTrash: SquareTrash,
  search: Search,
  closedMail: ClosedMail,
  teamMembers: TeamMembers,
  crop: Crop,
  addImageWorkspace: AddImageWorkspace,
  changeWorkspace: ChangeWorkspace,
  calendarPost: CalendarPost,
  doubleArrowLeft: DoubleArrowLeft,
  arrowLeft: ArrowLeft,
  doubleArrowRight: DoubleArrowRight,
  arrowRight: ArrowRight,
  frameIndicator: FrameIndicator,
  playVideoIcon: PlayVideoIcon,
  pauseVideoIcon: PauseVideoIcon,
  metaGrid: MetaGrid,
  metaStories: MetaStories,
  saveIcon: SaveIcon,
  thumbUp: ThumbUp,
  thumbDown: ThumbDown,
  privateBoard: PrivateBoard,
  publicBoard: PublicBoard,
  simpleCheck: SimpleCheck,
  starEmpty: StarEmpty,
  starFilled: StarFilled,
  lock: Lock,
  notLock: NotLock,
  addboard: AddBoard,
  sadFace: SadFace,
  BoxGreen: BoxGreen,
  checkBoxGrey: checkBoxGrey,
  sadStar: SadStar,
  sadBoard: SadBoard,
  sadSearchbar: SadSearchbar,
  settingsIcon: SettingsIcon,
  searchIcon: SearchIcon,
  hash: Hash,
  info: Info,
  closeIcon: CloseIcon,
  padlock: Padlock,
  collaboration: Collaboration,
  dropdownArrow: DropdownArrow,
  commentsWithPoints: CommentsWithPoints,
  users: Users,
  trashBin: TrashBin,
  searchIconMenu: SearchIconMenu,
  followIconMenu: FollowIconMenu,
  boardsIconMenu: BoardsIconMenu,
  starDiscover: StarDiscover,
  boardsDiscover: BoardsDiscover,
  refresh: Refresh,
  close_icon: Close_icon,
  notifyBell: NotifyBell,
  fourDotMenu: FourDotMenu,
  cryBell: CryBell,
  tiktokAllBottomIcons: TiktokAllBottomIcons,
  tiktokAllTopIcons: TiktokAllTopIcons,
  tiktokAdd: TiktokAdd,
  notifyToRead: NotifyToRead,
  notifyReaded: NotifyReaded,
  youtubeDownload: YoutubeDownload,
  youtubeSettings: YoutubeSettings,
  pen: Pen,
  roundedCheckmark: RoundedCheckmark,
  dropdownFilter: DropdownFilter,
  Dashboard: DashboardIcon,
  Preview: Preview,
  SetUp: SetUp,
  Social: Social,
  Metriche: Metriche,
  Condividi: Condividi,
  SalvaPdf: SalvaPdf,
  Link: LinkIcons,
  Create: CreateIcons,
  sadReport: SadReport,
  sadBall: SadBall,
  utm: Utm,
  expand: Expand,
  collapse: Collapse,
  saveIcons: SaveIcons,
  enterpriseCity: EnterpriseCity,
  xIcon: XIcon,
  addSocialAccount: AddSocialAccount,
  threeDots: ThreeDots,
  youtubeAllTopIcons: YoutubeAllTopIcons,
  youtubeAllRightIcons: YoutubeAllRightIcons,
  youtubeAllBottomIcons: YoutubeAllBottomIcons,
  postWarning: PostWarning,
  addInstagram: AddInstagram,
  closeButton: CloseButton,
  compare: Compare,
  dragDropAnalytics: DragDropAnalytics,
  sadFaceWithPen: SadFaceWithPen,
  magicWand: MagicWand,
  sun: Sun,
  dottedLine: DottedLine,
  textIcon: TextIcon,
  autopilotElectric: AutopilotElectric,
  calendarIcon: CalendarIcon,
  positiveCheck: PositiveCheck,
  negativeCheck: NegativeCheck,
  generation: Generation,
  person: Person,
  collaborators: Collaborators,
  happyPost: HappyPost,
}
