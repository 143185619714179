import { Page } from 'components'
import { ComingSoonComponent } from 'components/ComingSoonComponent'
import { NavigationStructure } from 'components/UI/NavigationStructure'
import { PageStructure } from 'components/UI/PageStructure'
import { matchPath, useLocation } from 'react-router-dom'
import { TSection, TSubsection } from 'types/AnalyticsInferfaces'

import { AnalyticsContent } from '../../components/Analytics/AnalyticsContent'
import { AnalyticsSidebar } from '../../components/Analytics/AnalyticsSidebar'
import { AnalyticsNavigation } from 'components/Analytics/NavigationsBar/AnalyticsNavigation'
import { useEffect, useState } from 'react'

export const AnalyticsPage = () => {
  /* ------------------- VARIABLES  ------------------*/
  const location = useLocation()
  const section = (location.pathname.split('/')[2] ?? 'analytics') as TSection
  const subsection = (location.state?.subsection ?? 'setup') as TSubsection

  return (
    <Page title="Analytics">
      <PageStructure
        sidebar={<AnalyticsSidebar section={section} />}
        content={<AnalyticsContent section={section} subsection={subsection} />}
        navigation={
          <NavigationStructure
            showNavigation={true}
            content={<AnalyticsNavigation section={section} subsection={subsection} />}
          />
        }
      />
    </Page>
  )
}
