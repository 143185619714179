/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'

/* ------------------- INTERFACES  */
interface Props {}

export const YoutubeShortAssets = ({}: Props) => {
  return (
    <YoutubeShortAssetsContainer>
      <TopIcons />

      <RightIcons />

      <BottomIcons />
    </YoutubeShortAssetsContainer>
  )
}

const YoutubeShortAssetsContainer = styled(Box)``

const TopIcons = styled((props) => <Icons.youtubeAllTopIcons {...props} />)`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 20;
`

const RightIcons = styled((props) => <Icons.youtubeAllRightIcons {...props} />)`
  position: absolute;
  bottom: 100px;
  right: 20px;
  z-index: 20;
`

const BottomIcons = styled((props) => <Icons.youtubeAllBottomIcons {...props} />)`
  position: absolute;
  bottom: 0;
  left: 12px;
  z-index: 20;
  border-radius: 0 0 16px 16px;
`
