import styled from '@emotion/styled'
import { Box, Icons, Text } from 'components'
import { Component } from 'react'

import { AppStore } from 'utils'

import { T } from 'translation/i18n'

interface Props {
  onCreatePost: () => void
  moveFile: () => void
  renameFile: () => void
  downloadImages: () => void
  onDeleteMedias: () => void
  editImage: () => void
  selectedMedias: any
  isModal: any
}

interface State {
  hoverIndex: number
}

export default class MediaBottomBar extends Component<Props, State> {
  state: State = {
    hoverIndex: -1,
  }
  config = [
    {
      id: 'create-post-media-page',
      text: T.postPage.createPost,
      icon: <Icons.add fill={AppStore.theme.o.blueOnLight} style={{ paddingLeft: 10 }} />,
      onClick: this.props.onCreatePost,
    },
    {
      id: 'edit-media-button',
      text: T.mediaPage.openInEditor,
      icon: <Icons.uploadFile fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />,
      onClick: this.props.editImage,
    },
    {
      id: 'move-media-page',
      text: T.mediaPage.move,
      icon: <Icons.move fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />,
      onClick: this.props.moveFile,
    },
    {
      id: 'download-media-page',
      text: T.mediaPage.instal,
      icon: <Icons.download fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />,
      onClick: this.props.downloadImages,
    },
    {
      id: 'rename Button',
      text: T.mediaPage.rename,
      icon: <Icons.edit fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />,
      onClick: this.props.renameFile,
    },
    {
      id: 'delete-media-page',
      text: T.postPage.delete,
      icon: <Icons.delete fill={AppStore.theme.o.red} style={{ paddingLeft: 10 }} />,
      onClick: this.props.onDeleteMedias,
    },
  ]
  render() {
    const { onCreatePost, moveFile, renameFile, downloadImages, onDeleteMedias, editImage, selectedMedias, isModal } =
      this.props
    const { hoverIndex } = this.state

    return (
      <BottomBar animation="fade" hcenter>
        {/* <BottomBarItem
            id="create-post-media-page"
            style={{
              borderTopLeftRadius: 16,
              borderBottomLeftRadius: 16,
            }}
            onClick={onCreatePost}
            center
            row
          >
            <Text color={AppStore.theme.o.blueOnLight}>
              <b>{T.postPage.createPost}</b>
            </Text>
            <Icons.add fill={AppStore.theme.o.blueOnLight} style={{ paddingLeft: 10 }} />
          </BottomBarItem>
          <BottomBarItem id="edit-media-button" onClick={editImage} center row>
            <Text color={AppStore.theme.o.black}>
              <b>{T.mediaPage.openInEditor}</b>
            </Text>
            <Icons.uploadFile fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />
          </BottomBarItem>
          <BottomBarItem onClick={moveFile} id="move-media-page" center row>
            <Text color={AppStore.theme.o.black}>
              <b>{T.mediaPage.move}</b>
            </Text>
            <Icons.move fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />
          </BottomBarItem>

          <BottomBarItem id="download-media-page" onClick={downloadImages} center row>
            <Text color={AppStore.theme.o.black}>
              <b>{T.mediaPage.instal}</b>
            </Text>
            <Icons.download fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />
          </BottomBarItem>
          <BottomBarItem id="rename Button" onClick={renameFile} center row>
            <Text color={AppStore.theme.o.black}>
              <b>{T.mediaPage.rename}</b>
            </Text>
            <Icons.edit fill={AppStore.theme.o.black} style={{ paddingLeft: 10 }} />
          </BottomBarItem>
          <BottomBarItem
            style={{
              borderTopRightRadius: 16,
              borderBottomRightRadius: 16,
            }}
            onClick={onDeleteMedias}
            center
            row
            hoverColor={AppStore.theme.o.lightRed}
            id="delete-media-page"
          >
            <Text color={AppStore.theme.o.red}>
              <b> {T.postPage.delete}</b>
            </Text>
            <Icons.delete fill={AppStore.theme.o.red} style={{ paddingLeft: 10 }} />
          </BottomBarItem> */}
        <Box shadow={AppStore.theme.bigOuterShadow} bgPrimaryColor row>
          {this.config.map((item, index) => (
            <BottomBarItem
              key={item.id}
              onClick={item.onClick}
              center
              row
              hoverColor={index === this.config.length - 1 ? AppStore.theme.o.lightRed : AppStore.theme.o.lightBlue}
              style={{
                borderTopLeftRadius: index === 0 ? 16 : 0,
                borderBottomLeftRadius: index === 0 ? 16 : 0,
                borderTopRightRadius: index === this.config.length - 1 ? 16 : 0,
                borderBottomRightRadius: index === this.config.length - 1 ? 16 : 0,
              }}
              onHoverStart={() => this.setState({ hoverIndex: index })}
              onHoverEnd={() => this.setState({ hoverIndex: -1 })}
            >
              <Text
                color={
                  index === 0
                    ? AppStore.theme.o.blueOnLight
                    : index === this.config.length - 1
                    ? AppStore.theme.o.red
                    : index === hoverIndex
                    ? AppStore.theme.o.blueOnLight
                    : AppStore.theme.o.black
                }
              >
                <b>{item.text}</b>
              </Text>
              {item.icon}
            </BottomBarItem>
          ))}
        </Box>
      </BottomBar>
    )
  }
}

const BottomBarItem = styled(Box)<{ hoverColor?: string }>`
  border-right: 1px solid ${() => AppStore.theme.o.lightGrey};
  padding: 8px 20px;
  border-radius: 0px;
  :last-child {
    border-right: none;
  }
  :hover {
    background-color: ${({ hoverColor }) => hoverColor ?? AppStore.theme.o.lightBlue};
  }
  cursor: pointer;
`
const BottomBar = styled(Box)`
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
`
