import { Box, Text } from 'components'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { CustomizeServiceOption } from './SubscriptionProvider'

export const ServiceRow = ({ service }: { service: CustomizeServiceOption }) => {
  return (
    <Box mb={4} row>
      <Box flex>
        <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
          {service.description ?? service.label}
        </Text>
      </Box>
      <Box center width={50}>
        <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
          {(service.quantity ?? 1) * (service.addQuantity ?? 1)} {service.key === 'storage' ? 'gb' : ''}
        </Text>
      </Box>
      <Box center width={70}>
        {service.price && (
          <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
            {service.quantity! * service.price!}
            {T.settings.me}
          </Text>
        )}
      </Box>
    </Box>
  )
}
