import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import React from 'react'
import { AppStore, T } from 'utils'

const DocumentationSection = () => {
  return (
    <DocumentationContainer>
      <DocTitle>{T.settings.apiSection.documentationTitle}</DocTitle>
      <DocDescription>
        {T.settings.apiSection.docDescription}
        <br />
        {T.settings.apiSection.docDescriptionLearnMore}
      </DocDescription>
      <Button variant={'neutral'} size="smaller" style={{ width: 287, height: 40, fontWeight: 600, display: 'flex' }}>
        <ATag style={{ flex: 1 }} href="https://www.asters.io/docs/api" target="_blank" rel="noreferrer">
          {T.settings.apiSection.readDocumentation}
        </ATag>
      </Button>
    </DocumentationContainer>
  )
}

export default DocumentationSection

const DocumentationContainer = styled(Box)`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
`
const DocTitle = styled(Box)`
  font-size: 21px;
  font-weight: 700;
  line-height: 27px;
  color: ${() => AppStore.theme.o.green};
`

const DocDescription = styled(Box)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${() => AppStore.theme.o.black};
`
const ATag = styled.a`
  flex: 1;
  margin: 0 -16px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${() => AppStore.theme.o.black};

  &:hover {
    color: ${() => AppStore.theme.o.black};
  }
`
