import styled from '@emotion/styled'
import { Checkbox, Form } from 'antd'
import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import Utils from 'api/Utils'
import asters_logo from 'assets/asters_logo.svg'
import LoadingIcons from 'assets/loading.json'
import WrongIcons from 'assets/wrong_anim.json'
import { Box, Button, Icons, Input, SocialButton, Spacer } from 'components'
import SignUpAnimation from 'components/Animations/SignUpAnimation'
import { useEffect, useState } from 'react'
import Lottie from 'react-lottie'
import { T } from 'translation/i18n'
import { AppStore, showError } from 'utils'
import { reloadApp } from 'utils/router'
import {
  TRACKEREVENTS,
  getUtmProperties,
  setAuthProperties,
  setUserSignupData,
  setStripeSignupData,
  setWorkspacesData,
} from 'utils/eventsTracker'

export const SignupCard = ({
  onChangeSelected,
  onLoginCallback,
}: {
  onChangeSelected: () => void
  onLoginCallback: any
}) => {
  enum SIGNUPSTEPS {
    SIGNUP_FORM = 0,
    PROFESSION_STEP = 1,
    LOGIN_STEP = 2,
    ERROR_STEP = 3,
  }
  const [name, setName] = useState('')
  const [email, setEmail] = useState(AppStore.parampsOption?.defaultEmail ?? '')
  const [password, setPassword] = useState('')
  const [referral, setReferral] = useState(AppStore.referralCode ?? '')
  const [termsCheckbox, setTermsCheckbox] = useState(false)
  const [privacyCheckbox, setPrivacyCheckbox] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showreferral, setShowreferral] = useState(false)
  //STEP 0: Iscrizione, STEP 1: Selezione professione, STEP 2: Correct server response, STEP 3: wrong server response
  const [signUpStep, setSignUpStep] = useState(SIGNUPSTEPS.SIGNUP_FORM)
  const categories = ['Privato', 'Libero professionista', 'Influencer', 'Azienda', 'Agenzia']

  let signingUp = false

  const resetState = () => {
    setName('')
    setEmail(AppStore.parampsOption?.defaultEmail ?? '')
    setPassword('')
    setReferral(AppStore.referralCode ?? '')
    setTermsCheckbox(false)
    setPrivacyCheckbox(false)
    setShowreferral(false)
  }

  const onSignup = async (category: string) => {
    Tracker.trackEvent(TRACKEREVENTS.SIGNUP_INITIATED, {
      ...setAuthProperties({ auth_method: 'Email', referral_code: referral }),
      ...getUtmProperties(),
    })

    try {
      if (signingUp) return
      signingUp = true
      const user = await Auth.signup(
        name,
        password,
        email,
        category,
        (window as any)?.Rewardful?._cookie?.affiliate?.token,
        referral
      )

      try {
        const signupSuccessTrackProperties = {
          ...setUserSignupData(user),
          ...setStripeSignupData(user),
          ...setAuthProperties({ auth_method: 'Email', referral_code: referral }),
          ...setWorkspacesData(user),
          ...getUtmProperties(),
        }
        Tracker.trackEvent(TRACKEREVENTS.SIGNUP_SUCCESSFUL, signupSuccessTrackProperties)
      } catch (error) {}

      setSignUpStep(SIGNUPSTEPS.LOGIN_STEP)
    } catch (error: any) {
      if (error.toString() === 'Error: USER_ALREADY_EXISTS') {
        showError(T.error.user_already_exist)
      }
      Tracker.trackEvent(
        TRACKEREVENTS.SIGNUP_FAILED,
        setAuthProperties({
          auth_method: 'Email',
          error_message: `An error occured. \n ${error}`,
        })
      )
      setSignUpStep(SIGNUPSTEPS.ERROR_STEP)
    }
    signingUp = false
  }

  const onFacebookLogin = async () => {
    //Tracking Signup Success
    Tracker.trackEvent(TRACKEREVENTS.SIGNUP_INITIATED, {
      ...setAuthProperties({ auth_method: 'Facebook', referral_code: referral }),
      ...getUtmProperties(),
    })

    try {
      Auth.facebookLogin()

      //TODO find signup data after login
      // setSignUpStep(2)
    } catch (error) {
      //Tracking Signup Failed
      Tracker.trackEvent(
        TRACKEREVENTS.SIGNUP_FAILED,
        setAuthProperties({
          auth_method: 'Facebook',
          error_message: `An error occured. \n ${error}`,
        })
      )
      setSignUpStep(SIGNUPSTEPS.ERROR_STEP)
    }
  }

  const onGoogleLogin = async () => {
    //Tracking Signup Success
    Tracker.trackEvent(TRACKEREVENTS.SIGNUP_INITIATED, {
      ...setAuthProperties({ auth_method: 'Google', referral_code: referral }),
      ...getUtmProperties(),
    })

    try {
      Auth.googleLogin()

      //TODO find signup data after login
      // setSignUpStep(2)
    } catch (error) {
      //Tracking Signup Failed
      Tracker.trackEvent(
        TRACKEREVENTS.SIGNUP_FAILED,
        setAuthProperties({
          auth_method: 'Google',
          error_message: `An error occured. \n ${error}`,
        })
      )
      setSignUpStep(SIGNUPSTEPS.ERROR_STEP)
    }
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingIcons,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const wrongOptions = {
    loop: true,
    autoplay: true,
    animationData: WrongIcons,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  const renderSignUpForm = () => {
    return (
      <Box flex>
        <Form
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          onFinish={() => {
            if (!termsCheckbox) return showError({ message: T.error.acceptTerm })
            if (!privacyCheckbox) return showError({ message: T.error.acceptPrivay })

            setSignUpStep(1)
          }}
          autoComplete="off"
        >
          <Box height={358}>
            <Box mv={20} hcenter>
              <img style={{ height: 50, width: 158 }} src={asters_logo} alt="" />
            </Box>
            <Box flex>
              <Form.Item name="name" rules={[{ required: true }]}>
                <Input flex onChange={(txt) => setName(txt)} variant="name" />
              </Form.Item>
              <Spacer />
              <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
                <Input type="email" flex defaultValue={email} onChange={(txt) => setEmail(txt)} variant="email" />
              </Form.Item>
              <Spacer />
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input type={'password'} flex onChange={(txt) => setPassword(txt)} variant="password" />
              </Form.Item>

              <>
                {/*-----------------------------------------— Refferal Code box  -----------------------------------------— */}
                <Box
                  mt={16}
                  row
                  removeHover
                  onClick={() => {
                    showreferral
                      ? SignUpAnimation.hideRefferal('refferalInputBox', 'refferalCodeBox')
                      : SignUpAnimation.showRefferal('refferalInputBox', 'refferalCodeBox')
                    setShowreferral(!showreferral)
                  }}
                >
                  <Text style={{ fontSize: 14, color: AppStore.theme.o.darkGrey }}> Hai un codice referral? </Text>
                  <Icons.openSectionArrow id="ArrowIcon" fill={AppStore.theme.o.darkGrey} />
                </Box>

                <Box id={'refferalCodeBox'} removeHover style={{ position: 'relative', overflow: 'hidden' }}>
                  <Box id="refferalInputBox" style={{ position: 'absolute', top: 0, y: showreferral ? 10 : -100 }}>
                    <Form.Item rules={[{ required: false }]}>
                      <Input
                        style={{ width: '420px' }}
                        type={'referral'}
                        flex
                        defaultValue={referral}
                        onChange={(txt) => setReferral(txt)}
                        placeholder={'referral'}
                      // variant="referral"
                      />
                    </Form.Item>
                  </Box>
                </Box>
              </>
              {/* )} */}
            </Box>

            {/*-----------------------------------------— //privacy and conditions checkbox -----------------------------------------— */}
            <Box id={'checkBoxItem'} mt={12}>
              <Form.Item style={{ width: '100%' }} name="terms">
                <Checkbox
                  onClick={() => setTermsCheckbox(!termsCheckbox)}
                  value={termsCheckbox}
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#A3A1AA',
                  }}
                >
                  <Box row style={{ fontSize: 14 }}>
                    {T.placeholder.iHaveReadUnderstoodAndAcceptedThe} {T.articles.the}
                    <a href="https://asters.ai/terms-of-use" target={'_blank'}>
                      <b style={{ color: AppStore.theme.o.green, marginLeft: 4 }}> {T.settings.confirmPrivacy2}</b>
                    </a>
                  </Box>
                </Checkbox>
              </Form.Item>
              <Form.Item style={{ width: '100%' }} name="privacy">
                <Checkbox
                  onClick={() => setPrivacyCheckbox(!privacyCheckbox)}
                  value={privacyCheckbox}
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#A3A1AA',
                  }}
                >
                  <Box row style={{ fontSize: 14 }}>
                    {T.placeholder.iHaveReadUnderstoodAndAcceptedThe} {T.articles.there}
                    <a href="https://asters.ai/privacy-policy" target={'_blank'}>
                      <b style={{ color: AppStore.theme.o.green, marginLeft: 4 }}> {T.logIn.privacyPolicy}</b>
                    </a>
                  </Box>
                </Checkbox>
              </Form.Item>
            </Box>
            <Spacer height="18px" />
            {!loading && (
              <Box mt={30}>
                <Button variant="primary" flex type="submit">
                  {T.labels.subscribe}
                </Button>
                <Spacer />
                <CustomText center> {T.labels.OrSignUpUsing}</CustomText>
                <Spacer />
                <SocialButton
                  onClick={onFacebookLogin}
                  children={T.placeholder.subscribeInWithFacebook}
                  variant="facebook"
                />
                <Spacer />
                <SocialButton onClick={onGoogleLogin} children={T.placeholder.subscribeInWithGoogle} variant="google" />
                <Spacer />
              </Box>
            )}
          </Box>
        </Form>
      </Box>
    )
  }

  const renderSignUpCategorySelection = () => {
    return (
      <Box flex style={{ maxWidth: 415 }}>
        <Spacer />
        <Box center>
          <img style={{ marginBottom: 20, height: 47, width: 158 }} src={asters_logo} alt="" />
        </Box>
        <Text center pr={51} pl={51} pb={18}>
          {T.logIn.welcomeYouAre[1]} <br />
          <span style={{ textAlign: 'center' }}>{T.logIn.welcomeYouAre[2]}</span>
        </Text>
        <Spacer />

        {!loading &&
          categories.map((category) => {
            return (
              <>
                <Button key={Utils.generateRandomUIDKey()} variant="neutral" flex onClick={() => onSignup(category)}>
                  {category}
                </Button>
                <Spacer height={24} />
              </>
            )
          })}
        {loading && <Lottie options={defaultOptions} height={200} width={200} />}
      </Box>
    )
  }

  const onLogin = async () => {
    // e?.preventDefault?.()
    if (loading) return
    try {
      setLoading(true)
      const res = await Auth.login(email, password)
      await AppStore.setToken(res.access_token)

      AppStore.loggedUser = await Auth.loggedUser()
      if (!!AppStore.loggedUser) {
        return reloadApp()
      }

      onLoginCallback(AppStore.loggedUser)
      // changeLanguage(AppStore.getLanguageValue())

      setLoading(false)
    } catch (error) {
      setLoading(false)
      if ((error as any)?.message === 'USER_NOT_CONFIRMED') return showError(T.error.weSentAMail)

      showError(T.error.user_or_password_wrong)
    }
  }

  const renderSignUpError = () => {
    return (
      <Box flex hcenter>
        <Spacer />
        <img style={{ height: 47, width: 158 }} src={asters_logo} alt="" />
        <Spacer />
        <Card ph={20} center flex>
          <CardImage>
            <Lottie options={wrongOptions} height={90} width={90} />
          </CardImage>
          <Spacer />
          <CardText center>
            {T.logIn.error1} <br />
            {T.logIn.error2}
          </CardText>
          <Spacer />
        </Card>
        <Spacer />
        <Button
          flex
          onClick={() => {
            setSignUpStep(SIGNUPSTEPS.SIGNUP_FORM)
            setTimeout(resetState, 300)
          }}
        >
          {T.singleWord.retry}
        </Button>
      </Box>
    )
  }

  useEffect(() => {
    showreferral
      ? SignUpAnimation.hideRefferal('refferalInputBox', 'refferalCodeBox')
      : SignUpAnimation.showRefferal('refferalInputBox', 'refferalCodeBox')
    setShowreferral(!showreferral)
  }, [])

  return (
    <Box flex height={750}>
      {signUpStep === SIGNUPSTEPS.SIGNUP_FORM && renderSignUpForm()}
      {signUpStep === SIGNUPSTEPS.PROFESSION_STEP && renderSignUpCategorySelection()}
      {signUpStep === SIGNUPSTEPS.LOGIN_STEP && onLogin()}
      {signUpStep === SIGNUPSTEPS.ERROR_STEP && renderSignUpError()}
    </Box>
  )
}

const CustomText = styled(Box)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${() => AppStore.theme.o.darkGrey};
`
const Text = styled(Box)`
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`
const Card = styled(Box)`
  background: ${AppStore.theme.o.background};
  border-radius: 14px;
  width: 100%;
`
const CardText = styled(Box)`
  font-weight: bold;
  font-size: 16px;
  color: ${AppStore.theme.o.black};
  text-align: center;
`
const CardImage = styled(Box)``
