import styled from '@emotion/styled'
import { BackwardIcon, Box, Button, Icons, Text, Title } from 'components'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, getPlanType, showError } from 'utils'
import { refresh } from 'utils/router'
import { CustomizeServiceOption, Service, SubscriptionOption, SubscriptionProvider } from '../SubscriptionProvider'
import { SubscriptionPlanMode } from 'types/SettingsInterfaces'
import { useNavigate } from 'react-router-dom'

interface Props {
  subscriptionMode: SubscriptionPlanMode
  subscriptionOptions: SubscriptionOption[]
  additionalOptions: Service[]
  changeAdditionalOption: (additionalOptions: CustomizeServiceOption[]) => void
  onNext: () => void
  onBack: () => void
  externalAddOn: boolean
}

interface State {
  storageGeneration: number
  extraSocialGeneration: number
  additionalPrice: number
}

const SubscriptionPartTwo = ({
  subscriptionMode,
  subscriptionOptions,
  additionalOptions,
  changeAdditionalOption,
  onNext,
  onBack,
  externalAddOn,
}: Props) => {
  const [storageGeneration, setStorageGeneration] = useState<number>(
    AppStore.loggedUser.rawPlan?.storageGeneration ?? additionalOptions[0].quantity ?? 0
  )
  const [extraSocialGeneration, setExtraSocialGeneration] = useState<number>(
    AppStore.loggedUser.rawPlan?.extraSocialGeneration ?? additionalOptions[1].quantity ?? 0
  )
  const [additionalPrice, setAdditionalPrice] = useState<number>(0)

  const navigate = useNavigate()

  /* ------------------ METHODS */
  const subStract = (index) => {
    if (index === 0) {
      storageGeneration - 1 < 0
        ? showError(T.error.canNotHaveNegativeQuantity)
        : setStorageGeneration((prev) => prev - 1)
    } else {
      extraSocialGeneration - 1 < 0
        ? showError(T.error.canNotHaveNegativeQuantity)
        : setExtraSocialGeneration((prev) => -1)
    }
  }

  const add = (index) => {
    if (index === 0) {
      setStorageGeneration((prev) => prev + 1)
    } else {
      setExtraSocialGeneration((prev) => prev + 1)
    }
  }

  const goNext = () => {
    const additionalOtp = additionalOptions

    onNext()
    additionalOtp[0].quantity! = storageGeneration!
    additionalOtp[1].quantity! = extraSocialGeneration!
    changeAdditionalOption(additionalOtp)
  }

  /* ------------------ RENDER METHODS */
  const renderAddionalOptions = (option: Service, index) => {
    return (
      <AdditionalOptionContainer mr={36}>
        <Box row pv={24} ph={40} style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}>
          <Box flex>
            <Text style={{ marginBottom: 12 }} weight={700} fontSize={16}>
              {option.label}
            </Text>
            <Box vcenter row pv={8}>
              <Text style={{ marginRight: 40, width: 170 }} color={AppStore.theme.o.darkGrey}>
                {option.description}
              </Text>
              <ButtonIcons
                center
                onClick={() => {
                  subStract(index)
                }}
              >
                <Icons.minus fill={AppStore.theme.o.black} />
              </ButtonIcons>
              <Text style={{ width: 48, textAlign: 'center' }}>
                {index === 0 ? storageGeneration : extraSocialGeneration}
              </Text>
              <ButtonIcons
                center
                onClick={() => {
                  add(index)
                }}
              >
                <Icons.plus fill={AppStore.theme.o.black} />
              </ButtonIcons>
            </Box>
          </Box>
          <Box width={100} justify="center" ml={72}>
            <Box row justify={'space-between'}>
              <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                {T.settings.fromPlan}
              </Text>
              <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                {activeSubscriptionOption.services.find((s) => s.key === option.key)?.quantity ?? 0}
              </Text>
            </Box>
            <Box row justify={'space-between'}>
              <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                {T.settings.extra}
              </Text>
              <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                {index === 0
                  ? storageGeneration * (SubscriptionProvider.customizableOptions[0].addQuantity ?? 0)
                  : extraSocialGeneration}
              </Text>
            </Box>
            <Box row justify={'space-between'}>
              <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                {T.settings.total}
              </Text>
              <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                {(activeSubscriptionOption.services.find((s) => s.key === option.key)?.quantity ?? 0) +
                  (index === 0
                    ? storageGeneration * (SubscriptionProvider.customizableOptions[0].addQuantity ?? 0)
                    : extraSocialGeneration)}
              </Text>
            </Box>
          </Box>
        </Box>
      </AdditionalOptionContainer>
    )
  }

  /* ------------------ USEEFFECT */
  useEffect(() => {
    let active = -1
    if (AppStore.loggedUser.rawPlan?.plan === undefined) active = -1
    if (AppStore.loggedUser.rawPlan?.plan === 'pro') active = 0
    else if (AppStore.loggedUser.rawPlan?.plan === 'business') active = 1
    else active = 2

    setAdditionalPrice(storageGeneration * 3 + extraSocialGeneration * 2 + subscriptionOptions[active].price.monthly)
  }, [])

  /* ------------------ VARIABLES */
  const activeSubscriptionOption = subscriptionOptions.find((o) => o.active) as SubscriptionOption
  const monthPrice = activeSubscriptionOption.price[subscriptionMode]
  const offerta = activeSubscriptionOption.offer

  const active = getPlanType()

  return (
    <Box animation="slide">
      <Box mb={24} row vcenter>
        <BackwardIcon
          onClick={
            externalAddOn === true
              ? () => {
                  refresh()
                }
              : onBack
          }
          style={{ marginRight: 8 }}
        />
        <Title style={{ color: AppStore.theme.o.green }}>{T.settings.personalize}</Title>
      </Box>
      <Box row>
        <Box flex>{additionalOptions.map(renderAddionalOptions)}</Box>
        <Box
          pv={24}
          ph={32}
          height={'fit-content'}
          style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}
          width={333}
        >
          {AppStore.loggedUser._stripe! && (
            <Box mb={16} style={{ position: 'relative' }}>
              <Title style={{ color: AppStore.theme.o.darkGrey }}>{T.settings.youArePaying}</Title>
              <Text color={AppStore.theme.o.darkGrey} fontSize={36}>
                {AppStore.mySubscription?.renew_price ?? 0}
                {AppStore.loggedUser.rawPlan?.prices?.some((p) => p.id.includes('ANNUAL'))
                  ? `€/${T.settings.year}`
                  : `€/${T.settings.month}`}
              </Text>
              <Box style={{ position: 'absolute', bottom: -10, right: 0 }}>
                <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                  {T.settings.iva_excluded}
                </Text>
              </Box>
            </Box>
          )}

          {subscriptionMode === 'annual' && (
            <>
              <Title style={{ color: AppStore.theme.o.blueOnLight, marginBottom: 12 }}>{T.settings.youWillPay}</Title>
              <Box style={{ position: 'relative' }}>
                <Text color={AppStore.theme.o.black} fontSize={36}>
                  {Math.floor(
                    ((offerta?.type === 'annual' && offerta?.label === 'Professionista'
                      ? offerta.cost / 12
                      : monthPrice) +
                      extraSocialGeneration * 2 +
                      storageGeneration * 3) *
                      12
                  )}
                  €/{T.settings.year}
                </Text>
                <Box style={{ position: 'absolute', bottom: -10, right: 0 }}>
                  <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                    {T.settings.iva_excluded}
                  </Text>
                </Box>
              </Box>
            </>
          )}
          {subscriptionMode === 'monthly' && (
            <>
              <Title style={{ color: AppStore.theme.o.blueOnLight, marginBottom: 0 }}>{T.settings.youWillPay} </Title>
              <Box style={{ position: 'relative' }}>
                <Text color={AppStore.theme.o.black} fontSize={47}>
                  {Math.floor(monthPrice + extraSocialGeneration * 2 + storageGeneration * 3)}
                  €/{T.settings.month}
                </Text>
                <Box style={{ position: 'absolute', bottom: -10, right: 0 }}>
                  <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
                    {T.settings.iva_excluded}
                  </Text>
                </Box>
              </Box>
            </>
          )}
          <ButtonNext mt={40} size="small" onClick={goNext}>
            {T.settings.continue}
          </ButtonNext>
        </Box>
      </Box>
    </Box>
  )
}

export default SubscriptionPartTwo

const AdditionalOptionContainer = styled(Box)<{ active?: boolean }>`
  margin-top: 12px;
  :nth-child(1) {
    margin-top: 0;
  }
`

const ButtonNext = styled(Button)`
  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
    color: ${() => AppStore.theme.o.blueOnLight};
    box-shadow: none;
  }
`

const ButtonIcons = styled(Box)<{ active?: boolean }>`
  padding: 4px;
  border-radius: 30px;
  height: 24px;
  width: 24px;
  border: 1px solid ${() => AppStore.theme.o.black};
`
