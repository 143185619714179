import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import { Box } from './Box'
import { Icons } from './Icons'
import SearchRoleComponent from './SearchRoleComponent'
import { Workspace } from 'types'
import UserRow from './UserRow'
import { Button } from 'components'
import { refresh } from 'utils/router'

export interface Roles {
  id: string
  permissions: {
    id: string
    number: number
  }[]
}

interface ICollabUser {
  name: string
  email: string
  role: string
  invitationConfirmed: boolean
  id: string
}

interface Props {
  changeSession?: any
  workspace?: any
  users?: any
  rolesNumber?: any
  onWorkspaceChange: (workspace: Workspace) => void
  handleForceUpdate?: () => void
}

export default function UsersGridSection({
  changeSession,
  workspace,
  users,
  rolesNumber,
  onWorkspaceChange,
  handleForceUpdate,
}: Props) {
  /* ------------------- STATE  */
  const [roles, setRoles] = useState<any>([])

  const [Users, setUsers] = useState<Array<ICollabUser | undefined>>([])

  const [orderBy, setOrderBy] = useState({
    id: 'none',
    order: false,
  })

  const [modifyingRole, setModifyingRole] = useState(-1)

  /* ------------------- VARIABLES  */
  const maxPermissions = [
    {
      id: T.teamMembers.rulesTitle.publication,
      number: 4,
    },
    {
      id: T.teamMembers.rulesTitle.calendar,
      number: 1,
    },
    {
      id: T.teamMembers.rulesTitle.media,
      number: 4,
    },
    {
      id: T.teamMembers.rulesTitle.settings,
      number: 6,
    },
  ]

  const sessions = [
    T.teamMembers.tableHeaders.name,
    T.teamMembers.tableHeaders.publication,
    T.teamMembers.tableHeaders.calendar,
    T.teamMembers.tableHeaders.media,
    T.teamMembers.tableHeaders.settings,
    T.teamMembers.tableHeaders.roleName,
  ]

  /* ------------------- METHODS  */
  const countHowManyPermissions = (AsignedRole: string) => {
    const appstoreRole = workspace.roles ?? []
    const roleIndex = appstoreRole?.findIndex((role) => role.name === AsignedRole) ?? 0
  }

  // if admin doesn't exist = false
  const findRole = (roleId: string | undefined, referenceArray?: any) => {
    if (referenceArray !== undefined) {
      if (roleId !== undefined) {
        return referenceArray.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
      }
    }

    if (roleId !== undefined) {
      if (roleId !== 'Undefined' && roleId !== 'Admin') {
        return roles.find((role) => role.roleName.toLowerCase() === roleId.toLowerCase())
      }
    }

    return {
      roleName: 'undefined',
      rolesNumber: [
        {
          name: 'Pubblicazione',
          permissionSelected: 0,
        },
        {
          name: 'Calendario',
          permissionSelected: 0,
        },
        {
          name: 'Media',
          permissionSelected: 0,
        },
        {
          name: 'Impostazioni',
          permissionSelected: 0,
        },
      ],
    }
  }

  const addNewRole = (newRoleName: string) => {
    roles.push({ id: newRoleName, permissions: [] })
  }

  const filterByRole = (role: string) => {
    const sortedUsers = Users.sort((a, b) => {
      if (a === undefined || b === undefined) return 0
      if (role === 'name') return a.name.localeCompare(b.name)
      const aRole = findRole(a.role)
      const bRole = findRole(b.role)

      if (aRole === undefined || bRole === undefined) return 0
      const aPubblicazione = aRole.permissions?.find((permission) => permission.id === role)
      const bPubblicazione = bRole.permissions?.find((permission) => permission.id === role)
      if (aPubblicazione === undefined || bPubblicazione === undefined) return 0
      if (role === orderBy.id && orderBy.order === true) {
        return aPubblicazione.number - bPubblicazione.number
      }
      return bPubblicazione.number - aPubblicazione.number
    })

    setUsers(sortedUsers)
    if (orderBy.id === role) setOrderBy({ id: role, order: !orderBy.order })
    else
      setOrderBy({
        id: role,
        order: true,
      })
  }

  /* ------------------- USEFFECT  */

  useEffect(() => {
    setRoles(rolesNumber)
  }, [rolesNumber])

  useEffect(() => {
    setUsers(users)
  }, [users])

  /* ------------------- COMPONENTS  */
  const NumberComponent = ({ id, roleName }) => {
    let idMaxNumber = 0
    let roleData = 0
    let backGroundColor = AppStore.theme.o.lightGrey
    let textColor = AppStore.theme.o.darkGrey

    if (roleName !== 'Undefined') {
      try {
        idMaxNumber =
          maxPermissions?.filter((permission) => permission.id.toLowerCase() === id.toLowerCase())[0].number ?? 0
        if (roleName === 'Admin') {
          roleData = idMaxNumber
        } else
          roleData =
            roles
              .filter((role) => role.roleName.toLowerCase() === roleName.toLowerCase())[0]
              .rolesNumber.filter((role) => role.name.toLowerCase() === id.toLowerCase())[0].permissionSelected ?? 0
      } catch (e) {
        console.error('error', e)
      }
    }

    if (roleData !== 0) {
      backGroundColor = idMaxNumber === roleData ? AppStore.theme.o.lightBlue : AppStore.theme.o.lightGrey
      textColor = idMaxNumber === roleData ? AppStore.theme.o.blue : AppStore.theme.o.black
    }

    return (
      <Box id={`permission-${id}`} width={120} height={25} center>
        <Box style={{ radius: '50%', backgroundColor: backGroundColor, padding: 4 }} ph={8}>
          <HeaderText style={{ color: textColor, fontSize: 12 }}>
            {roleData}/{idMaxNumber}
          </HeaderText>
        </Box>
      </Box>
    )
  }

  const AddRoleComponent = (user, index) => {
    const color = user.role.toLowerCase() === 'admin' ? AppStore.theme.o.blue : AppStore.theme.o.black

    return (
      <Box relative ml={0}>
        {index === modifyingRole && (
          <SearchRoleComponent
            collabId={user.collabId}
            listOfRoles={roles}
            index={index}
            workspace={workspace}
            userId={user.id}
            userRole={user.role ?? ''}
            addNewRole={addNewRole}
            changeSection={changeSession}
            onWorkspaceChange={onWorkspaceChange}
          />
        )}

        <Button
          width={105}
          height={30}
          style={{ minHeight: 30, padding: 5 }}
          onClick={(e) => {
            if (!user.invitationConfirmed) return
            e.stopPropagation()
            if (index === modifyingRole) setModifyingRole(-1)
            setModifyingRole(index)
          }}
          variant={user.role === 'Admin' ? 'primary' : 'neutral'}
          size="smaller"
        >
          <HeaderText>
            {user.role === undefined ? (
              T.teamMembers.addText
            ) : (
              <RoleText style={{ color: color }}>{user.role ?? '-'}</RoleText>
            )}
          </HeaderText>
          <Icons.user fill={color} style={{ width: 23 }} />
        </Button>
      </Box>
    )
  }

  return (
    <Box removeHover style={{ maxHeight: 570 }} hideBar scroll onClick={() => setModifyingRole(-1)}>
      {/* //---- header ---- ---- ---- ---- ---- ---- ---- ----  */}
      <Box row vcenter id={'header'} ml={25}>
        <Box id={'name'} width={220} height={60} vcenter row>
          <Box height={12} width={12} style={{ backgroundColor: AppStore.theme.o.grey }} ml={-20} mr={8} />
          <HeaderText>{T.teamMembers.tableHeaders.name}</HeaderText>
        </Box>
        <Box row style={{ justifyContent: 'space-around' }} gap={25}>
          {sessions.map((session, index) => {
            if (index !== 0) {
              return (
                <Box
                  id={'header-session-' + index}
                  width={120}
                  hcenter
                  row
                  removeHover
                  center
                  onClick={() => filterByRole(session)}
                >
                  <HeaderText>{session}</HeaderText>
                  <Box>
                    <SortIcon />
                  </Box>
                </Box>
              )
            }
          })}
        </Box>
      </Box>

      <DivisionLine />

      <Box>
        {/* ---- ---- Users ---- ---- ---- ---- ---- ---- ---- ----  */}
        <Box style={{ pointerEvents: 'none' }}>
          <UserRow
            handleForceUpdate={handleForceUpdate}
            AddRoleComponent={AddRoleComponent}
            NumberComponent={NumberComponent}
            findRole={findRole}
            index={-2}
            admin={true}
            user={{
              name: workspace.user.name,
              email: workspace.user.email,
              role: 'Admin',
              invitationConfirmed: true,
              id: 'Admin',
            }}
          />
        </Box>

        {Users.map((user, index) => {
          countHowManyPermissions(user?.role ?? '')
          if (user === undefined) return
          if (user.role !== undefined)
            return (
              <UserRow
                handleForceUpdate={handleForceUpdate}
                AddRoleComponent={AddRoleComponent}
                NumberComponent={NumberComponent}
                findRole={findRole}
                index={index}
                user={user}
                key={index}
              />
            )
        })}
      </Box>
    </Box>
  )
}

const SortIcon = styled(Icons.sortIcon)`
  /* fill: ${() => AppStore.theme.o.darkGrey}; */
  width: 12px;
  height: 12px;
  margin-left: 8px;
`

const DivisionLine = styled.div`
  background-color: ${() => AppStore.theme.o.grey};
  height: 1px;
  width: 100%;
`

const HeaderText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: ${() => AppStore.theme.o.black};
`

const RoleText = styled.p`
  font-family: 'Aktiv Grotesk';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
`
