/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { useDiscoverStore } from 'stores/DiscoverStore'

export const DiscoverMenu = () => {
  /* ____________________ ZUSTAND  ____________________ */
  const { subPageActive, onChangeSubPage } = useDiscoverStore((state) => ({
    subPageActive: state.subPageActive,
    onChangeSubPage: state.onChangeSubPage,
  }))

  return (
    <DiscoverMenuContainer>
      <MenuOption
        className={subPageActive === 'search' ? 'active__menuOption' : ''}
        onClick={() => onChangeSubPage('search')}
      >
        <Icons.searchIconMenu /> {T.aiDiscoverPage.discoverMenu.search}
      </MenuOption>
      <MenuOption
        className={subPageActive === 'follow' ? 'active__menuOption' : ''}
        onClick={() => onChangeSubPage('follow')}
      >
        <Icons.followIconMenu /> {T.aiDiscoverPage.discoverMenu.followed}
      </MenuOption>
      <MenuOption
        className={subPageActive === 'board' ? 'active__menuOption' : ''}
        onClick={() => onChangeSubPage('board')}
      >
        <Icons.boardsIconMenu /> {T.aiDiscoverPage.discoverMenu.boards}
      </MenuOption>
    </DiscoverMenuContainer>
  )
}

const DiscoverMenuContainer = styled(Box)`
  /* Altezza ContentHeader + il gap (66 + 16) */
  margin-top: 82px;

  height: 288px;
  width: 96px;

  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 16px;

  border-radius: 16px;
  background: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.smallBoxShadow};
`

const MenuOption = styled(Box)`
  height: 80px;
  width: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 13px 20px 12px 20px;

  border-radius: 8px;
  background: ${() => AppStore.theme.o.surface};
  border-left: 2px solid transparent;

  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;
  font-family: Aktiv Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;

  svg {
    transition: all 0.3s ease-in-out;
    fill: ${() => AppStore.theme.o.darkGrey};
  }

  &:hover {
    background: ${() => AppStore.theme.o.lightBlue};

    color: ${() => AppStore.theme.o.blue};

    svg {
      fill: ${() => AppStore.theme.o.blue};
    }
  }

  &.active__menuOption {
    border-radius: 8px;
    border-left: 2px solid ${() => AppStore.theme.o.blue};
    background: ${() => AppStore.theme.o.lightBlue};

    color: ${() => AppStore.theme.o.blue};

    svg {
      fill: ${() => AppStore.theme.o.blue};
    }
  }
`
