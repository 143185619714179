import { Workspace } from 'types'
import { AppStore } from 'utils'
import AstersBaseResource from './AstersBaseResource'
import { api } from './api'

export default class Medias extends AstersBaseResource {
  static endpoint = '/medias'

  //upload with formdata where file is the file to upload
  static upload(data: FormData) {
    return api.post<{ presignedUrl: string; filename: string }>(`${this.endpoint}/upload`, data)
  }

  static async createFile(formData: FormData, onUploadProgress?: (progressEvent: ProgressEvent) => void) {
    const check = await AppStore.checkPermission('upload_media')

    if (!check) return

    formData.append('workspace', AppStore.workspace!._id!)
    // create file with formdata return progress and set timeout to 5 minutes
    return api.post<{ progress: number; presignedUrl: string; filename: string } | any>(
      `${this.endpoint}/create/file`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        timeout: 300000,
        onUploadProgress,
      }
    )
  }

  static createFolder(data: { name: string; path: string; workspace?: Workspace; type?: 'file' | 'folder' }) {
    data.workspace = AppStore.workspace
    //remove workspace id from path
    data.path = data.path.replace(`${AppStore.workspace._id}/`, '')
    return api.post(`${this.endpoint}/create/folder`, data)
  }

  static rename(id, name) {
    return api.post(`${this.endpoint}/rename/${id}/${name}`)
  }

  static changePath(data: { media: any; path: string }) {
    return api.post(`${this.endpoint}/changePath`, data)
  }
  static getFolderAWS(workspaceId: string) {
    const data = { workspaceId }
    return api.post(`${this.endpoint}/getFolderSize`, data)
  }

  static getUrl(media) {
    return api.post(`${this.endpoint}/getUrl/${media._id}`)
  }
}
