import * as Sentry from '@sentry/react'
import { version } from '../../package.json'
export const initializeSentry = () => {
  const tracePropagationTargets = (process.env.REACT_APP_TRACE_PROPAGATION_TARGETS || '').split(',')
  const release = `asters-frontend@${version}`

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    release: release,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: tracePropagationTargets,
      }),
      new Sentry.Replay({
        maskAllText: false,
      }),
      new Sentry.Integrations.Breadcrumbs({ console: false })
    ],

    tracesSampleRate: parseFloat(process.env.REACT_APP_TRACE_SAMPLE_RATE || '1.0'),
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_REPLAY_SESSION_SAMPLE_RATE || '0.1'),
    replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_REPLAY_ON_ERROR_SAMPLE_RATE || '1.0'),
  })
}
