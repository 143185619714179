/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore } from 'utils'
import { ToolTip } from './SubscriptionProvider'

/* ------------------- INTERFACES  */
interface Props {
  tooltip: ToolTip
}

export const InfoServiceToolTip = ({ tooltip }: Props) => {
  return (
    <InfoServiceToolTipContainer>
      <InfoIconContainer className={'info-icon'} center>
        <Icons.info fill={AppStore.theme.o.darkGrey} width={16} height={16} />
      </InfoIconContainer>

      <InfoTextsContainer className={'info-service-container'}>
        {tooltip.note === undefined ? <></> : <InfoNoteText>{tooltip.note}</InfoNoteText>}
        <InfoTitleText>{tooltip.title}</InfoTitleText>
        <InfoDescriptionText>{tooltip.text}</InfoDescriptionText>
      </InfoTextsContainer>
    </InfoServiceToolTipContainer>
  )
}

const InfoServiceToolTipContainer = styled(Box)`
  position: relative;
  padding: 0px 10px;
  .info-icon {
    z-index: 10;
  }

  &:hover {
    .info-icon {
      z-index: 20;
    }
    .info-service-container {
      transition-delay: 0.7s;
      z-index: 11;
      visibility: visible;
      opacity: 1;
      width: 220px;
      height: 88px;
      div {
        opacity: 1;
      }
    }
  }
`

const InfoIconContainer = styled(Box)`
  border-radius: 50%;
  width: 16px;
  height: 16px;
  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
`

const InfoTextsContainer = styled(Box)`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  overflow: hidden;
  top: -2px;
  left: 7px;
  padding: 6px 18px;
  width: 20px;
  height: 20px;
  background-color: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.bigOuterShadowNoBox};
  & div {
    transition: all 0.3s ease-in-out;
  }
`

const InfoNoteText = styled(Box)`
  opacity: 0;
  display: inline-block;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: AktivGrotesk-Regular;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  width: 200px;
  padding: 0px 6px;
`

const InfoTitleText = styled(Box)`
  opacity: 0;
  display: inline-block;
  color: ${() => AppStore.theme.o.black};
  font-family: AktivGrotesk-Regular;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  width: 200px;
  margin-top: 12px;
  padding: 0px 4px;
`

const InfoDescriptionText = styled(Box)`
  opacity: 0;
  color: ${() => AppStore.theme.o.black};
  font-family: AktivGrotesk-Regular;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px;
  margin-top: 8px;
  width: 190px;
  padding: 0px 4px;
  min-height: fit-content;
`
