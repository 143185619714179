import axios from 'axios'
import { MetaPreview } from 'types'
import { AppStore } from 'utils'

// <-------------------------INFERFACE------------------------->

export default class ShortUrl {
  static endpoint =
    process.env.REACT_APP_BACKEND_SHORT_URL ?? 'https://asters-shortlink-test-16a8de8f7a28.herokuapp.com'

  // Passando un link e workspaceId ritorna shortUrl e i metadati
  static async getShortLinkAndMetadata(orginUrl: string) {
    const workspaceId = AppStore.getWorkspaceId()

    const data = {
      workspaceId,
      orginUrl,
    }

    return axios
      .post(`${this.endpoint}/shortUrl`, data)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Errore nella chiamata a getShortLinkAndMetadata:', error)
        throw error
      })
  }

  static async modifyMetadata(urlId: string, metadata: MetaPreview) {
    const workspaceId = AppStore.getWorkspaceId()

    const data = {
      workspaceId,
      urlId,
      ...metadata,
    }

    return axios
      .post(`${this.endpoint}/metaData`, data)
      .then((response) => response.data)
      .catch((error) => {
        // Gestisci l'errore qui
        console.error('Errore nella chiamata a modifyMetadata:', error)
        throw error
      })
  }
}
