/* ******************* IMPORT ******************* */
import styled from '@emotion/styled'
import { BackwardIcon, Box, MultiToggleButton, Title } from 'components'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, getPlanType } from 'utils'
import { navigate, refresh } from 'utils/router'

import { RenderOptionCard } from '../RenderOptionCard'
import { SubscriptionOption } from '../SubscriptionProvider'

import { ServicesDisplay } from '../ServicesDisplay'
import { CustomizationDisplay } from '../CustomizationDisplay'
import { Chip } from '../Chip'
import { EnterpriseContactUs } from '../EnterpriseContactUs'

/* ******************* INTERFACE ******************* */

interface Props {
  setActiveSubscriptionOption: (index: number) => void
  subscriptionMode: 'monthly' | 'annual'
  subscriptionOptions: SubscriptionOption[]
  forceUpdate: any
  setSubscriptionMode: (index: number) => void
  onNext: () => void
  setToActivePlan: boolean
}

export const SubscriptionPlanSection = ({
  setActiveSubscriptionOption,
  subscriptionMode,
  subscriptionOptions,
  setSubscriptionMode,
  forceUpdate,
  onNext,
  setToActivePlan,
}: Props) => {
  /* ____________________ STATE ____________________ */
  const [activePlan, setActivePlan] = useState(-1)
  const [isMonthlyOrAnnual, setIsMonthlyOrAnnual] = useState(
    AppStore.loggedUser.rawPlan?.prices.some((p) => p.id.includes('ANNUAL')) ? 'annual' : 'monthly'
  )

  /* ____________________ METHODS ____________________ */
  const activePlanFunc = () => {
    if (!AppStore.loggedUser.rawPlan?.plan && setToActivePlan) {
      setActiveSubscriptionOption(0)
    }
    return getPlanType()
  }

  const handleBackButton = () => {
    navigate('subscription')
    refresh()
  }

  const getSelectedOption = (): SubscriptionOption => {
    return subscriptionOptions.find((o) => o.active)!
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    setActivePlan(activePlanFunc())
  }, [])

  return (
    <SubscriptionPlanSectionContainer animation="slide">
      <Box mr={32}>
        <Box row vcenter pr={32}>
          {AppStore.loggedUser._rawPlan && <BackwardIcon onClick={handleBackButton} style={{ marginRight: 8 }} />}
          <Title style={{ color: AppStore.theme.o.green }}>{T.settings.includedInPlan}</Title>
        </Box>

        {getSelectedOption().label === 'Enterprise' ? (
          <EnterpriseContactUs />
        ) : (
          <>
            <ServicesDisplay selectedOption={getSelectedOption()} />
            <CustomizationDisplay />
          </>
        )}

        {/* <CancelPlanButton /> */}
      </Box>

      <Box width={333} style={{ transform: 'translateY(-20px)' }} hcenter>
        <Box style={{ position: 'relative' }}>
          <Chip position="top-left" text={`2 ${T.settings.freeMonths}`} primaryColor="white" />

          <MultiToggleButton
            preselectedIndex={isMonthlyOrAnnual === 'annual' ? 0 : 1}
            cellStyle={{ fontSize: 12 }}
            style={{ padding: 8, marginBottom: 24 }}
            onChange={(e, index) => {
              setSubscriptionMode(index)
            }}
            values={[T.settings.annual, T.settings.mensil]}
          />
        </Box>
        {subscriptionOptions.map((plan, index) => (
          <RenderOptionCard
            plan={plan}
            activePlan={activePlan}
            isMonthlyOrAnnual={isMonthlyOrAnnual}
            onNext={onNext}
            setActiveSubscriptionOption={setActiveSubscriptionOption}
            subscriptionMode={subscriptionMode}
            index={index}
          />
        ))}
      </Box>
    </SubscriptionPlanSectionContainer>
  )
}

const SubscriptionPlanSectionContainer = styled(Box)`
  flex-direction: row;
`
