import { SocialAccount } from 'types'
import AstersBaseResource from './AstersBaseResource'
import { api, responseErrorCheck } from './api'
import { AppStore } from 'utils'

export default class Socialaccounts extends AstersBaseResource {
  static endpoint = '/socialaccounts'

  //get linkedin socialaccounts
  static async getRequestUrl(provider: string) {
    return api
      .get<string>(`${this.endpoint}/requestUrl`, {
        provider,
      })
      .then(responseErrorCheck)
  }

  //get linkedin socialaccounts
  static async getLinkedinAccounts(code: string, redirectUri: string) {
    return api
      .get<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/linkedin/accounts`, {
        code,
        redirectUri,
      })
      .then(responseErrorCheck)
  }

  //get twitter socialaccounts
  static async getTwitterAccounts(code: string, codeVerifier: string) {
    return api
      .get<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/twitter/accounts`, {
        code,
        codeVerifier,
      })
      .then(responseErrorCheck)
  }

  static async getYoutubeAccounts(code: string) {
    return api
      .post<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/youtube/accounts`, {
        code,
      })
      .then(responseErrorCheck)
  }

  //get tiktok socialaccounts
  static async getTikTok() {
    return api.post(`${this.endpoint}/getTiktokToken`).then(responseErrorCheck)
  }

  // getTiktokUser
  static async getTiktokUser(code: string) {
    return api
      .post<{ socialaccounts: SocialAccount[]; tokens: any }>(`${this.endpoint}/tiktok/accounts`, {
        code,
      })
      .then(responseErrorCheck)
  }

  static async checkRefreshToken(socialaccounts: SocialAccount[], workspaceId?: string): Promise<SocialAccount[]> {
    return api
      .post<SocialAccount[]>(`${this.endpoint}/checkRefreshToken/${workspaceId ?? AppStore.getWorkspaceId()}`, {
        socials: socialaccounts,
      })
      .then(responseErrorCheck)
  }

  static async swichWorkspace(social: SocialAccount, workspaceId: string) {
    const data = { social, workspaceId }
    return api.post(`${this.endpoint}/changeWorkspace`, data)
  }

  static async getOnlineFollower(accountId: string, workspaceId: string, timezone: number) {
    const userTimezoneOffset = new Date().getTimezoneOffset()
    const timezoneOffsetHours = -Math.floor(userTimezoneOffset / 60)

    return api.post(`${this.endpoint}/getOnlineFollowerData`, {
      accountId: accountId,
      workspaceId: workspaceId,
      timeZone: timezone,
    })
  }

  static async connectOauth1(oauthToken: string, oauthVerifier: string, oauthTokenSecret: string) {
    return api
      .post(`${this.endpoint}/x/oauth1`, {
        oauth_token: oauthToken,
        oauth_verifier: oauthVerifier,
        oauth_token_secret: oauthTokenSecret,
      })
      .then(responseErrorCheck)
  }

  static async getOauth1Url() {
    return api.get<string>(`${this.endpoint}/x/oauth1/url`).then(responseErrorCheck)
  }

  /**
   * The function `checkSocialExist` asynchronously sends a POST request to the API endpoint to check
   * the existence of social accounts.
   * @param {SocialAccount[]} accounts - The `accounts` parameter is an array of `SocialAccount`
   * objects.
   * @returns The `checkSocialExist` method is returning a Promise that resolves to the result of the
   * API call made using the `post` method. The API call is sending a POST request to
   * `${this.endpoint}/checkSocialExist/${AppStore.getWorkspaceId()}` with the `accounts` data. The
   * response from the API call is then passed through the `responseErrorCheck` function.
   */
  static async checkSocialExist(accounts: string[]) {
    return api
      .post<SocialAccount[]>(`${this.endpoint}/checkSocialExist/${AppStore.getWorkspaceId()}`, {
        accountIds: accounts,
      })
      .then(responseErrorCheck)
  }
}
