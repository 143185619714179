/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialUser } from 'components/UI/SocialUser'
import { IPostVariant } from 'types/PostInterface'
import { FirstComment } from '../GenericExtras/FirstComment'
import { Tags } from './Tags'
import { PreviewVideoContainer } from '../GenericExtras/PreviewVideoContainer'
import { getFirstMedia } from 'utils/PostUtils/Post'
import { isVisible } from 'utils/CheckVisibleElement'

/* ------------------- INTERFACES  */
interface Props {
  post: IPostVariant
}

export const InstagramExtrasContainer = ({ post }: Props) => {
  const { picture, provider, name } = post.info.account
  const { medias } = post.data
  const { type } = post.info

  return (
    <InstagramExtrasContainerContainer>
      <SocialUser picture={picture} provider={provider} name={name} />

      <ExtrasContent>
        <FirstComment post={post} />

        {/* Da vedere completamente 
        {false && <Location post={post} />}*/}

        {isVisible('createPost.instagramExtra.tags') && <Tags post={post} />}

        {/* Controlla se nei media c'è un video e se è selezionato l'opzione STORY */}
        {medias && medias.length > 0 && getFirstMedia(medias, 'video') && (
          <PreviewVideoContainer post={post} filterVideoUrl={getFirstMedia(medias, 'video')} />
        )}
      </ExtrasContent>
    </InstagramExtrasContainerContainer>
  )
}

const InstagramExtrasContainerContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 40px;
`

const ExtrasContent = styled(Box)`
  width: 100%;
  max-width: 541px;
  flex-direction: column;
  gap: 24px;
  border: none;
`
