import { Text } from 'components'
import { Component } from 'react'
import { AppStore, T } from 'utils'
import CancelModal from '../Subscription/CancelModal'

export default class CancelPlanButton extends Component {
  openCancelModal = () =>
    AppStore.openModal({
      id: 'CancelModal',
      body: <CancelModal hidden={(e) => this.setState({ hidden: e })} />,
      style: {
        // minHeight: 876,
        // display: 'none',

        contentVisibility: 'hidden',
        visibility: 'hidden',
        minWidth: 696,
      },
    })
  render() {
    return (
      <>
        {!!AppStore.mySubscription && (
          <Text
            weight={700}
            fontSize={12}
            style={{
              fontFamily: 'Aktiv Grotesk',
              color: AppStore.theme.o.red,
              textDecoration: 'underline',
            }}
            onClick={this.openCancelModal}
          >
            {T.settings.cancelPlan}
          </Text>
        )}
      </>
    )
  }
}
