import styled from '@emotion/styled'
import { Form } from 'antd'
import Utils from 'api/Utils'
import { Box, Button, Input } from 'components'
import { on } from 'events'
import React, { useState } from 'react'
import CreditCardInput from 'react-credit-card-input'
import { T } from 'translation/i18n'
import { CustomerCard } from 'types/SettingsInterfaces'
import { AppStore, showError, showSuccess } from 'utils'

interface Props {
  handleCardNumberChange: any
  handleCardExpirationChange: any
  handleCardCVCChange: any
  changeName: any
  onChangeChanges?: boolean
}
const CardInput = ({
  handleCardNumberChange,
  handleCardExpirationChange,
  handleCardCVCChange,
  changeName,
  onChangeChanges,
}: Props) => {
  const [name, setName] = useState<any>(
    AppStore.loggedUser.stripe?.billings !== undefined ? AppStore.loggedUser.stripe?.billings.customerCard : ''
  )
  const [customerCard, setCustomerCard] = useState<CustomerCard>({
    isError: true,
    number: '',
  })

  /* ---------------- METHODS */
  const onCardNumberChange = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, number: e.target.value, isError: false }
    })
  }
  const onCardExpirationChange = (e) =>
    setCustomerCard((prev) => {
      return { ...prev, expiration: e.target.value, isError: false }
    })
  const onCardCVCChange = (e) =>
    setCustomerCard((prev) => {
      return { ...prev, cvc: e.target.value, isError: false }
    })

  const onChangeNumber = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, number: e.target.value, isError: false }
    })
    handleCardNumberChange(e.target.value)
  }
  const onChangeExpiration = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, expiration: e.target.value, isError: false }
    })
    handleCardExpirationChange(e.target.value)
  }
  const onChangeCvc = (e) => {
    setCustomerCard((prev) => {
      return { ...prev, cvc: e.target.value, isError: false }
    })
    handleCardCVCChange(e.target.value)
  }

  return (
    <>
      <Form.Item rules={[{ required: true }]} name="customerCard">
        <StyledInput
          label={T.misc.name}
          placeholder={T.settings.type_name}
          value={name}
          onChange={(e) => (onChangeChanges === undefined ? setName(e) : changeName(e))}
        />
      </Form.Item>

      <CreditCardInput
        onError={(e) => console.error(e)}
        inputStyle={{
          backgroundColor: AppStore.theme.o.lightGrey,
          fontSize: 16,
          color: AppStore.theme.o.black,
        }}
        fieldStyle={{ backgroundColor: AppStore.theme.o.lightGrey, height: 46, borderRadius: 10 }}
        cardNumberInputProps={{
          value: customerCard?.number,
          onChange: onChangeChanges ? onChangeNumber : onCardNumberChange,
        }}
        cardExpiryInputProps={{
          value: customerCard?.expiration,
          onChange: onChangeChanges ? onChangeExpiration : onCardExpirationChange,
        }}
        cardCVCInputProps={{
          value: customerCard?.cvc,
          onChange: onChangeChanges ? onChangeCvc : onCardCVCChange,
        }}
        fieldClassName="input"
        customTextLabels={{
          invalidCardNumber: T.settings.invalidCardNumber,
          invalidCardExpiry: T.settings.invalidCardExpiry,
          invalidCardCvc: T.settings.invalidCardCvc,
          expiryPlaceholder: T.settings.expiryPlaceholder,
          cvcPlaceholder: T.settings.cvcPlaceholder,
          cardNumberPlaceholder: T.settings.cardNumberPlaceholder,
          expiryError: {
            invalidExpiryDate: T.settings.invalidExpiryDate,
            monthOutOfRange: T.settings.monthOutOfRange,
            yearOutOfRange: T.settings.yearOutOfRange,
            dateOutOfRange: T.settings.invalidExpiryDate,
          },
        }}
      />
      {!onChangeChanges && (
        <Box
          center
          style={{
            position: 'absolute',
            bottom: -96,
            zIndex: 9999999,
            borderRadius: 20,
          }}
          height={100}
          width={'100%'}
        >
          <Button
            style={{ minWidth: 253, borderRadius: 14, maxHeight: 44 }}
            center
            onClick={async () => {
              await handleCardNumberChange(customerCard?.number)
              await handleCardExpirationChange(customerCard?.expiration)
              await handleCardCVCChange(customerCard?.cvc)
              changeName(name)
              if ((customerCard?.number && customerCard?.expiration && customerCard?.cvc) !== undefined) {
                const billings = { ...AppStore.loggedUser.stripe?.billings, customerCard: name }

                try {
                  await Utils.updatePaymentMethod({
                    cardNumber: customerCard?.number as any,
                    expMonth: Number(customerCard!.expiration!.split('/')[0]),
                    expYear: Number(customerCard!.expiration!.split('/')[1]),
                    cvc: customerCard!.cvc as any,
                    customerCard: name as any,
                    billings: billings,
                  })
                  showSuccess(T.success.creditCardAdded)
                } catch (error) {
                  showError(error)
                }
              }
              AppStore.closeAllModal()
            }}
          >
            {T.settings.confirm}
          </Button>
        </Box>
      )}
    </>
  )
}

export default CardInput

const StyledInput = styled(Input)`
  margin-bottom: 12px;
  padding-left: 16px !important;
  font-size: 14px !important;
`
