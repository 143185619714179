import styled from '@emotion/styled'
import { Box } from 'components'
import React from 'react'
import { TabType } from 'types/SettingsInterfaces'
import { AppStore } from 'utils'

interface Props {
  tab: TabType
  index: number
  onChangeTab: (tab: TabType, index: number) => void
  selectedTab: number
  allTabsLength: number
}

const AsideTab = ({ tab, index, onChangeTab, selectedTab, allTabsLength }: Props) => {
  return (
    <Box id={`${tab.path}-setting-button`} flex>
      <SettingTab
        flex
        removeHover
        hideBoxShadowOnHover
        onClick={() => onChangeTab(tab, index)}
        center
        m={10}
        width={150}
      >
        <TabContainer
          className={`${selectedTab === index ? 'tab-selected' : 'tab-unselected'}`}
          style={{
            border: selectedTab === index ? `2px solid ${AppStore.theme.o.blueOnLight}` : undefined,
            minHeight: 124,
            minWidth: 124,
          }}
          center
        >
          <tab.icon
            style={{ width: 80, height: 80 }}
            fill={selectedTab === index ? AppStore.theme.o.blueOnLight : AppStore.theme.o.grey}
          />
          <TabText className="tab-text">{tab.label}</TabText>
        </TabContainer>
      </SettingTab>
      {index != allTabsLength - 1 && <LineSpacer />}
    </Box>
  )
}

export default AsideTab

const LineSpacer = styled.div`
  height: 1px;
  width: 10px;
  border: 1px dashed ${() => AppStore.theme.o.grey};
  width: 160px;
`

// set fill on all children of this component
const SettingTab = styled(Box)`
  &:hover * {
    color: ${() => AppStore.theme.o.blueOnLight};
    fill: ${() => AppStore.theme.o.blueOnLight};
    transition: 0.4s;
  }
`
const TabContainer = styled(Box)`
  background-color: transparent;
  transition: 0.3s ease-in-out;

  .tab-text {
    color: ${() => AppStore.theme.o.grey};
  }

  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue} !important;

    svg {
      path {
        fill: ${() => AppStore.theme.o.blueOnLight} !important;
      }
    }

    .tab-text {
      color: ${() => AppStore.theme.o.blueOnLight};
    }
  }

  &.tab-selected {
    background-color: ${() => AppStore.theme.o.lightBlue} !important;
    svg {
      path {
        fill: ${() => AppStore.theme.o.blueOnLight} !important;
      }
    }

    .tab-text {
      color: ${() => AppStore.theme.o.blueOnLight};
    }
  }
`

const TabText = styled(Box)`
  font-size: 14px;
  font-weight: 700;
`
