import React from 'react'
import { AppStore, T, __ } from 'utils'
import { Icons } from './Icons'
import { Button } from './Button'
import { Box } from './Box'

interface Props {}

export const HeaderAutopilotButton = ({}: Props) => {
  const onOpenZapierModal = () => {
    return AppStore.openModal({
      id: 'zapier-modal',
      body: (
        <Box width={1400} height={800} style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          ZAPIER MODAL
        </Box>
      ),
      style: {
        minWidth: 1400,
        minHeight: 800,
      },
    })
  }
  return (
    <Button
      variant={'secondary-revert'}
      onClick={onOpenZapierModal} // da cambiare con il componente zapier
      size="smaller"
      style={{ width: 189, height: 40, fontWeight: 500 }}
    >
      <Icons.autopilotElectric style={{ marginRight: 4 }} /> {T.headerAutopilotAndModal.autopilot}
    </Button>
  )
}
