import { Post } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { MixpanelPostData, MixpanelPostDeletedData } from './postInterfaces'
import { calculateReadingTimeInSeconds, getMediaFileFormats, getSocialExtras } from '../utils/utils'

export function getMixpanelPostData(post: Post) {
  return {
    social_channel: post.socialaccount?.provider,
    file_format: post.medias && post.medias.length > 0 ? post.medias[0].mimetype : undefined,
    has_caption: !!post.description,
    has_social_extras: !!post.extraSection,
    post_status: post.status,
  }
}

export function getMixpanelPostDeletedData(post: IPostVariant, isBulk: boolean | undefined): MixpanelPostDeletedData {
  const postData = {
    social_channel: post.info.account.provider,
    file_format: getMediaFileFormats(post),
    has_caption: post.data.text.length > 0,
    has_social_extras: Object.keys(getSocialExtras(post)).length > 0,
    post_status: post.data.status,
  }

  if (typeof isBulk !== 'undefined') {
    return {
      ...postData,
      is_bulk: isBulk,
    }
  }

  return {
    ...postData,
    is_bulk: false,
  }
}

export function getMixpanelPostScheduledData(posts: IPostVariant[], post: IPostVariant): MixpanelPostData {
  const postData: MixpanelPostData = {
    social_channel: post.info.account.provider,
    social_channel_type: post.info.account.profileType ?? 'profile',
    has_caption: post.data.text.length > 0,
    caption_length: post.data.text.length,
    caption_reading_time: calculateReadingTimeInSeconds(post.data.text),
    is_multichannel: posts.length > 1,
    has_file: post.data.medias.length > 0,
    social_format: post.info.type,
    num_files: post.data.medias.length,
    social_extras: getSocialExtras(post),
    post_schedule_time: post.data.date?.toString(),
  }

  if (post.info.account.provider === 'instagram') {
    //* Quando il post è best time to post il tipo della data è un oggetto
    if (typeof post.data.date === 'object') {
      return {
        ...postData,
        is_best_time_to_post: true,
      }
    } else {
      return {
        ...postData,
        is_best_time_to_post: false,
      }
    }
  }

  return postData
}

export function getMixpanelPostDirectlyPublished(posts: IPostVariant[], post: IPostVariant): MixpanelPostData {
  return {
    social_channel: post.info.account.provider,
    social_channel_type: post.info.account.profileType ?? 'profile',
    has_caption: post.data.text.length > 0,
    caption_length: post.data.text.length,
    caption_reading_time: calculateReadingTimeInSeconds(post.data.text),
    is_multichannel: posts.length > 1,
    has_file: post.data.medias.length > 0,
    social_format: post.info.type,
    num_files: post.data.medias.length,
    social_extras: getSocialExtras(post),
  }
}

export function getMixpanelPostDraftData(posts: IPostVariant[], post: IPostVariant): MixpanelPostData {
  return {
    social_channel: post.info.account.provider,
    social_channel_type: post.info.account.profileType ?? 'profile',
    has_caption: post.data.text.length > 0,
    caption_length: post.data.text.length,
    caption_reading_time: calculateReadingTimeInSeconds(post.data.text),
    is_multichannel: posts.length > 1,
    has_file: post.data.medias.length > 0,
    social_format: post.info.type,
    num_files: post.data.medias.length,
    social_extras: getSocialExtras(post),
  }
}
