import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { Workspace } from 'types'
import { NotificationLimit } from 'types/NotifyInterface'
import { AppStore } from 'utils'
import { Box, BoxProps } from './Box'
import { NotificationIcon } from './UI'

export const WorkspaceIcon: React.FC<{
  workspace: Workspace
  hideName?: boolean
  style?: React.CSSProperties
  boxProps?: BoxProps
}> = ({ workspace, style, hideName = false, boxProps }) => {
  const [error, setError] = useState(false)
  const [notificationCount, setNotificationCount] = useState<number>(0)

  // ZUSTAND

  const { workspaceNotifications } = useNotifyStore((s) => ({
    workspaceNotifications: s.workspaceNotifications,
  }))

  useEffect(() => {
    const notifyNumberLength =
      workspaceNotifications?.filter(
        (n) => n.workspaceId === AppStore.workspace._id && !n.notificationReaded.includes(AppStore.loggedUser._id)
      )?.length ?? 0

    // notifyNumberLength += workspaceNotifications.length
    setNotificationCount(notifyNumberLength)
  }, [workspaceNotifications])

  return (
    <WorkspaceIconBox
      row={!hideName}
      center
      style={{ flexDirection: hideName ? 'column' : 'row', alignItems: 'center', color: AppStore.theme.o.black }}
    >
      <img
        style={{
          borderRadius: 35,

          display: error ? 'none' : 'unset',
          ...style,
        }}
        width={35}
        height={35}
        onError={() => setError(true)}
        src={workspace?.image?.presignedUrl ?? ''}
      />

      {!!error && (
        <WorkspaceImageBox
          removeTransition
          style={{ borderRadius: 35, backgroundColor: 'black', position: 'sticky', ...style }}
          width={35}
          height={35}
        />
      )}
      {!hideName ? (
        <>
          <WorkspaceTextContainer>
            <WorkspaceText>{workspace?.name}</WorkspaceText>
          </WorkspaceTextContainer>
        </>
      ) : notificationCount > 0 ? (
        <NotificationIcon
          style={{ position: 'absolute', bottom: '-10px' }}
          count={notificationCount}
          limit={NotificationLimit.DEFAULT}
        />
      ) : null}
    </WorkspaceIconBox>
  )
}

const WorkspaceTextContainer = styled.div`
  max-width: 170px; // Imposta una larghezza massima appropriata
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const WorkspaceIconBox = styled(Box)`
  position: relative;
`
const WorkspaceImageBox = styled(Box)``

const WorkspaceText = styled.b`
  margin-left: 20px;
`
