/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { AppStore, T } from 'utils'
import { MediaContainerPost } from '../../GenericComponents/MediaContainerPost'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { IPostVariant } from 'types/PostInterface'

/* ------------------- INTERFACES  */
interface Props {}

export const ResultsPreviewYoutubeStandard = ({}: Props) => {
  /* ----------------- ZUSTAND  */
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost as IPostVariant,
  }))

  return (
    <ResultsPreviewYoutubeStandardContainer>
      <MediaContainerPost width={'100%'} height={277} arrowsPositions={10} />

      <PreviewTextTitle>
        {activePost?.data?.text?.length > 40 ? (
          <>
            {activePost?.data?.text?.slice(0, 40)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
          </>
        ) : (
          <>{activePost?.data?.text}</>
        )}
      </PreviewTextTitle>

      <PreviewProfileContainer>
        <ProfileInfo>
          <ProfileImg src={activePost?.info?.account?.picture} />
          <ProfileName>
            {activePost?.info?.account?.name?.length > 13 ? (
              <>
                {activePost?.info?.account?.name.slice(0, 13)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
              </>
            ) : (
              <>{activePost?.info?.account?.name}</>
            )}
          </ProfileName>
        </ProfileInfo>

        <ProfileIcons>
          <Likes>
            <Icons.fbLike fill={AppStore.theme.o.darkGrey} width={12} />
            <Icons.fbLike fill={AppStore.theme.o.darkGrey} width={12} style={{ transform: 'rotate(180deg)' }} />
          </Likes>

          <Share>
            <Icons.fbShare fill={AppStore.theme.o.darkGrey} width={12} />
            {T.postPage.share}
          </Share>

          <Download>
            <Icons.youtubeDownload fill={AppStore.theme.o.darkGrey} width={12} />
            {T.postPage.download}
          </Download>

          <ThreeDots>
            <Icons.threeDots fill={AppStore.theme.o.darkGrey} width={12} />
          </ThreeDots>
        </ProfileIcons>
      </PreviewProfileContainer>

      <ContainerDescription>
        <DescriptionStats>{T.postPage.visualizations}</DescriptionStats>

        {activePost?.data?.description && activePost.data.description?.length > 200 ? (
          <>
            {activePost?.data?.description?.slice(0, 200)} <SeeMoreText>... {T.postPage.more}</SeeMoreText>
          </>
        ) : (
          <>{activePost?.data?.description}</>
        )}
      </ContainerDescription>

      <FooterPreview>
        <NumberOfComments>{T.postPage.eightComments}</NumberOfComments>

        <ProfileAddComments>
          <ProfileImgShort src={activePost?.info?.account?.picture} />
          {T.postPage.addComent}
        </ProfileAddComments>
      </FooterPreview>
    </ResultsPreviewYoutubeStandardContainer>
  )
}

const ResultsPreviewYoutubeStandardContainer = styled(Box)`
  width: 100%;
  max-height: 540px;
  border: 1px solid ${() => AppStore.theme.o.grey};
`

const PreviewTextTitle = styled(Box)`
  margin: 16px 14px;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const SeeMoreText = styled.span`
  margin-left: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px;
`

const PreviewProfileContainer = styled(Box)`
  display: flex;
  padding: 8px 12px;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  gap: 6px;
`

const ProfileInfo = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`

const ProfileIcons = styled(Box)`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`

const Likes = styled(Box)`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  height: 20px;
`

const Share = styled(Box)`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  height: 20px;
`

const Download = styled(Box)`
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  height: 20px;
`

const ThreeDots = styled(Box)`
  border-radius: 14px;
  background: ${() => AppStore.theme.o.lightestGrey};
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ContainerDescription = styled(Box)`
  display: flex;
  padding: 12px;
  flex-direction: column;
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 130.023% */
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
`

const DescriptionStats = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px; /* 130.023% */
`

const FooterPreview = styled(Box)`
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`

const NumberOfComments = styled(Box)`
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13.002px; /* 130.023% */
`

const ProfileAddComments = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  color: ${() => AppStore.theme.o.darkGrey};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 13.002px; /* 130.023% */
`

const ProfileImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
`

const ProfileImgShort = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`

const ProfileName = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 13.002px;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
`
