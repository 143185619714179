import { Calendar as AntCalendar } from 'antd'
import locale from 'antd/es/date-picker/locale/it_IT'
import { Box, CalendarCard, Header } from 'components'
import moment from 'moment'
import { useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, showError, showSuccess } from 'utils'
import PostModal from './PostModal'
import { ISocialFilters } from 'stores/interfaces/CalendarPageInterface'
import { IPostVariant } from 'types/PostInterface'
import PostV2 from 'api/PostV2'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getMixpanelPostDeletedData } from 'utils/eventsTracker'

interface Props {
  year: number
  month: number
  months: string[]
  posts: IPostVariant[]
  socialFilters: ISocialFilters[]
  setMonth: (month: number) => void
  setYear: (year: number) => void
  refreshPost: () => void
  goToPost: (bol: boolean) => void
}

export const Calendar = ({
  year,
  month,
  months,
  posts,
  socialFilters,
  setMonth,
  setYear,
  refreshPost,
  goToPost,
}: Props) => {
  /* --------------------------------- STATE */
  const [visibleModal, setVisibleModal] = useState(false)
  const [filterDate, setfilterDate] = useState<string | undefined>()
  const [refresh, setRefresh] = useState(false)

  moment.updateLocale('it', {
    weekdaysMin: T.calendarPage.weekdaysMin,
  })

  const changeDate = (date: moment.Moment) => {
    if (date.month() !== month) setMonth(date.month())
    if (date.year() !== year) setYear(date.year())
  }

  const deletePost = async (id: string, isBulk: boolean | undefined) => {
    setRefresh(!refresh)
    if (!id) return showError(T.error.notEsistente)
    try {
      const results = await PostV2.DeletePost(id)
      AppStore.closeModal('calendar-posts-modal')
      setRefresh(!refresh)
      refreshPost()
      showSuccess(T.success.eliminationSuccess)

      const postToDelete = posts.find((p) => p.data._id === id)
      Tracker.trackEvent(TRACKEREVENTS.POST_DELETED, getMixpanelPostDeletedData(postToDelete!, isBulk))
    } catch (error) {
      showError(error)
    }
  }

  const filterPost = (date: moment.Moment) => {
    return posts.filter(
      (post) =>
        moment(post.data.date).date() === date.date() &&
        moment(post.data.date).month() === date.month() &&
        moment(post.data.date).year() === date.year() &&
        socialFilters
          .filter((s) => s.active)
          .map((s) => s.social)
          .includes(post.info.account.provider) &&
        post.data.status !== 'draft'
    )
  }

  const filterPostsModal = (post: IPostVariant, _filterDate) => {
    //compare date with filterDate is "27/3/2022" and socialFilter is active
    if (_filterDate ?? filterDate) {
      const date = moment(post.data.date)
      const filterDateM = moment(_filterDate ?? filterDate, 'l')
      return (
        date.date() === filterDateM.date() &&
        date.month() === filterDateM.month() &&
        date.year() === filterDateM.year() &&
        socialFilters
          .filter((s) => s.active)
          .map((s) => s.social)
          .includes(post.info.account.provider)
      )
    }
    return false
  }

  const openPostsModal = async (d) => {
    await setfilterDate(`${d}/${month + 1}/${year}`)
    const diff = await moment(`${d}/${month + 1}/${year}`, 'l').diff(moment(), 'days')
    let itemsToRender
    if (diff >= 0) {
      itemsToRender = posts.filter((p) => filterPostsModal(p, `${d}/${month + 1}/${year}`)).length + 1
    } else {
      itemsToRender = posts.filter((p) => filterPostsModal(p, `${d}/${month + 1}/${year}`)).length
    }

    AppStore.openModal({
      body: (
        <PostModal
          posts={posts}
          d={d}
          filterPostsModal={filterPostsModal}
          month={month}
          year={year}
          socialFilters={socialFilters}
          diff={diff}
          deletePost={deletePost}
          goToPost={goToPost}
        />
      ),
      style: {
        backgroundColor: 'transparent',
        borderRadius: 14,
        minWidth: 360,
        maxWidth: 1184,
        minHeight: 363,
        maxHeight: itemsToRender > 4 ? 656 : 363,
      },
    })
  }

  return (
    <Box flex id="calendar-container">
      <AntCalendar
        value={moment({ year, month, day: 1 })}
        locale={locale}
        headerRender={() => (
          <Header currentMonth={month} currentYear={year} months={months} setYear={setYear} setMonth={setMonth} />
        )}
        dateFullCellRender={(date: moment.Moment) => {
          return (
            <CalendarCard
              openModal={(d: any) => {
                if (
                  posts.filter((p) => filterPostsModal(p, `${d}/${month + 1}/${year}`)).length > 0 ||
                  moment(`${d}/${month + 1}/${year}`, 'l').diff(moment(), 'days') >= 0
                ) {
                  openPostsModal(d)
                }
              }}
              date={date}
              month={month}
              posts={filterPost(date)}
              socialFilters={socialFilters}
            />
          )
        }}
        onChange={changeDate}
        prefixCls={'custom'}
        key="calendar-component"
      />
    </Box>
  )
}
