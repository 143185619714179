import styled from '@emotion/styled'
import { Box } from 'components'
import { SocialIcon, SocialIconsProvider } from 'components/SocialIcon'
import moment from 'moment'
import { Post } from 'types'
import { AppStore } from 'utils'
import './index.css'
import { SocialType } from 'api/Utils'
import { IPostVariant } from 'types/PostInterface'

interface Props {
  date: moment.Moment
  month: number
  posts: IPostVariant[]
  socialFilters: { social: string; active: boolean }[]
  openModal: (date: any) => void
}

interface PostGroups {
  social?: SocialType
  count: number
}

export function CalendarCard({ date, month, posts, socialFilters, openModal }: Props) {
  const isDayFromCurrentMonth = date.month() === month
  let bgColor = !isDayFromCurrentMonth ? AppStore.theme.o.lightGrey : AppStore.theme.o.surface

  const isToday = date.isSame(moment(), 'day') && isDayFromCurrentMonth
  // if is today set bgColor to primaryColor
  if (isToday) {
    bgColor = AppStore.theme.o.lightBlue
  }

  const postGroups: PostGroups[] = []
  posts.forEach((post) => {
    const index = postGroups.findIndex(
      (groupedPost) => groupedPost?.social?.toLocaleLowerCase() === post.info.account.provider.toLocaleLowerCase()
    )
    if (index === -1) {
      //if a social is not present in the group we will add it
      postGroups.push({ social: post.info.account.provider, count: 1 })
    } else {
      postGroups[index].count += 1
    }
  })

  return (
    <CellComponent
      hideBoxShadow={isToday}
      removeHover={!isDayFromCurrentMonth}
      shadow={!isToday ? AppStore.theme.smallOuterShadow : undefined}
      bgColor={bgColor}
      onClick={!isDayFromCurrentMonth ? () => {} : () => openModal(date.date())}
    >
      <Box row vcenter justify={'space-between'} style={{ padding: 8 }}>
        <Day color={AppStore.theme.o.darkGrey}>{date.date()}</Day>
        {/* <Icons.createPostCalendar height={24} width={24} fill={AppStore.theme.o.darkGrey} /> */}
      </Box>
      <Box vcenter center row style={{ flexWrap: 'wrap' }}>
        {postGroups.map((post, index) => {
          if (post.count === 0) return
          const isActive = socialFilters.filter((filter) => filter.social === post.social && filter.active).length > 0
          return (
            <Box key={index} center mh={6}>
              <SocialIcon
                social={post.social as SocialIconsProvider}
                active={isActive}
                style={{ width: 52, height: 52 }}
              />
              <Day color={AppStore.theme.o.darkGrey}>+{post.count}</Day>
            </Box>
          )
        })}
      </Box>
    </CellComponent>
  )
}

const CellComponent = styled(Box)<{ bgColor: string }>`
  height: 124px;
  border-radius: 14px;
  margin: 8px;
  flex-direction: column;
  overflow: auto;
  background-color: ${({ bgColor }) => bgColor || '#fff'};
  ${() => AppStore.theme.calendarCardBoxShadow}
`

const Day = styled.p<{ color: string }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: ${({ color }) => color || '#a3a1aa'};
`
