import styled from '@emotion/styled'
import { Checkbox, Form } from 'antd'
import Auth from 'api/Auth'
import { Tracker } from 'api/Tracker'
import asters_logo from 'assets/asters_logo.svg'
import { Box, Button, Icons, Input, SocialButton, Spacer } from 'components'
import { LogoAstersFullDynamicTheme } from 'components/LogoAstersFullDynamicTheme'
import { useState } from 'react'
import { T, __ } from 'translation/i18n'
import { AppStore, showError, showSuccess } from 'utils'
import { reloadApp } from 'utils/router'
import { TRACKEREVENTS, setAuthProperties } from 'utils/eventsTracker'

export const LoginCard = ({ onLoginCallback, onChangeSelected }: { onLoginCallback; onChangeSelected: () => void }) => {
  const [email, setEmail] = useState(window.location.origin.includes('localhost') ? 'cald.daniele@hotmail.it' : '')
  const [password, setPassword] = useState(window.location.origin.includes('localhost') ? '12345pa' : '')
  const [recoverMode, setRecoverMode] = useState(false)
  const [recovering, setRecovering] = useState(false)
  const [checkbox, setCheckbox] = useState(false)
  const [loading, setLoading] = useState(false)

  const onLogin = async (e?: any) => {
    e?.preventDefault?.()
    if (loading) return
    try {
      setLoading(true)
      const res = await Auth.login(email, password)
      await AppStore.setToken(res.access_token)

      AppStore.loggedUser = await Auth.loggedUser()
      if (!!AppStore.loggedUser) {
        Tracker.trackEvent(TRACKEREVENTS.LOGIN_SUCCESSFUL, setAuthProperties({ auth_method: 'Email' }))
        Tracker.trackEvent(TRACKEREVENTS.EMAIL_VERIFICATION_SUBMITTED, {})
        return reloadApp()
      }
      onLoginCallback(AppStore.loggedUser)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      // if ((error as any)?.message === 'USER_NOT_CONFIRMED') return showError(T.error.SentAnEmailForActivation)

      Tracker.trackEvent(
        TRACKEREVENTS.LOGIN_FAILED,
        setAuthProperties({
          auth_method: 'Email',
          error_message: `Email login error. \n ${error}`,
        })
      )

      showError(
        (error as any)?.message === 'ThrottlerException: Too Many Requests'
          ? T.error.toManyRequest
          : __(T.error.user_or_password_wrong)
      )
    }
  }

  const onFacebookLogin = async () => {
    try {
      Auth.facebookLogin()
      Tracker.trackEvent(TRACKEREVENTS.LOGIN_SUCCESSFUL, setAuthProperties({ auth_method: 'Facebook' }))
    } catch (error) {
      Tracker.trackEvent(
        TRACKEREVENTS.LOGIN_FAILED,
        setAuthProperties({
          auth_method: 'Facebook',
          error_message: `Facebook login error. \n ${error}`,
        })
      )
    }
  }

  const onGoogleLogin = async () => {
    try {
      Auth.googleLogin()
      Tracker.trackEvent(TRACKEREVENTS.LOGIN_SUCCESSFUL, setAuthProperties({ auth_method: 'Google' }))
    } catch (error) {
      Tracker.trackEvent(
        TRACKEREVENTS.LOGIN_FAILED,
        setAuthProperties({
          auth_method: 'Google',
          error_message: `Google login error. \n ${error}`,
        })
      )
    }
  }

  const onRecover = async ({ email: recoverEmail }: { email: string }) => {
    if (recovering || !recoverEmail) return
    setRecovering(true)
    try {
      await Auth.recoverPassword(recoverEmail)
      showSuccess(T.error.SentEmailForPasswordRecovery)
      Tracker.trackEvent(TRACKEREVENTS.PASSWORD_RESET_SUBMITTED, {})
    } catch (error) {
      showError(T.error.unableToFindMail)
    }
    setRecovering(false)
    setRecoverMode(false)
  }

  return (
    <Box id="login-card-container">
      {!recoverMode && (
        <Form
          initialValues={{
            email,
            password,
          }}
          style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
          onFinish={onLogin}
          autoComplete="off"
        >
          <Box height={300}>
            <Box hcenter mv={20}>
              {/* <img style={{ height: 50, width: 158 }} src={asters_logo} alt="" /> */}
              <LogoAstersFullDynamicTheme logoHeight={50} logoWidth={158} />
            </Box>
            <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
              <Input flex onChange={(txt) => setEmail(txt)} variant="email" />
            </Form.Item>
            <Spacer />
            <Form.Item name="password" rules={[{ required: true }]}>
              <Input flex onChange={(txt) => setPassword(txt)} variant="password" />
            </Form.Item>
            <Spacer />

            <Box row>
              <Box style={{ justifyContent: 'center' }} flex vcenter row>
                <Checkbox
                  value={true}
                  onClick={() => setCheckbox(!checkbox)}
                  style={{
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#A3A1AA',
                  }}
                >
                  {__(T.labels.remember)}
                </Checkbox>
              </Box>
              <CustomText
                onClick={() => setRecoverMode(true)}
                removeHover
                center
                flex
                style={{ fontSize: 14, fontStyle: 'italic' }}
              >
                {__(T.labels.recoverPassword)}
              </CustomText>
            </Box>
          </Box>

          <Box mt={10}>
            <Button variant="primary" loading={loading} type="submit" disabled={email === '' || password === ''}>
              {__(T.labels.signIn)}
            </Button>

            <div style={{ display: 'none' }}>
              <Spacer />
              <CustomText center> {__(T.labels.orLogInUsing)}</CustomText>
              <Spacer />
              <SocialButton
                onClick={onFacebookLogin}
                children={__(T.placeholder.logInWithFacebook)}
                variant="facebook"
              />
              <Spacer />
              <SocialButton onClick={onGoogleLogin} children={__(T.placeholder.logInWithGoogle)} variant="google" />
              <Spacer />
            </div>

            {/* <CustomText removeHover center onClick={onChangeSelected}>
              <p>
                {__(T.placeholder.dontHaveAnAccountYet)}
                <b style={{ color: '#2b2b2b' }}> {__(T.placeholder.subscribeNow)} </b>
              </p>
            </CustomText> */}
          </Box>
        </Form>
      )}

      {!!recoverMode && (
        <Form style={{ display: 'flex', flexDirection: 'column' }} onFinish={onRecover} autoComplete="off">
          <Box height={300} style={{ position: 'relative' }}>
            <Box
              width={35}
              height={35}
              style={{ backgroundColor: AppStore.theme.o.lightGrey, position: 'absolute', top: 30, border: 1 }}
              center
            >
              <Icons.backward
                fill={AppStore.theme.o.black}
                onClick={() => {
                  setRecoverMode(!recoverMode)
                }}
              />
            </Box>
            <Box hcenter mv={20}>
              <img style={{ height: 50, width: 158 }} src={asters_logo} alt="" />
            </Box>
            <Form.Item name="email" rules={[{ required: true, type: 'email' }]}>
              <Input flex variant="email" />
            </Form.Item>
            <Button mt={50} type="submit" loading={recovering}>
              {T.logIn.reset}
            </Button>
          </Box>
        </Form>
      )}
    </Box>
  )
}

const CustomText = styled(Box)`
  font-weight: 500;
  font-size: 16px;
  color: ${() => AppStore.theme.o.darkGrey};
`
