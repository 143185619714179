import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Text } from 'components/Text'
import { Component } from 'react'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { T } from 'translation/i18n'
import { IPostVariant } from 'types/PostInterface'
import { AppStore } from 'utils'
import { onClickSocialCard } from 'utils/PostUtils/SocialPost'
import { BackwardIcon } from './BackwardIcon'
import { Button } from './Button'
import { ForwardIcon } from './ForwardIcon'
import PopConfirm from './PopConfirm'
import { SocialIcon, SocialIconsProvider } from './SocialIcon'

interface Props {
  post: IPostVariant
  style?: React.CSSProperties
  onPostClick?: (post: IPostVariant) => void
  onPostDelete: (id: string) => void
  goToPost?: (bol: boolean) => void
}

interface State {
  postMedia: number
  hover: boolean
}
const SocialStyle: any = { position: 'absolute', top: -20, left: -20, width: 64, height: 64, zIndex: 20 }
const socialMediaIcons = {
  instagram: <SocialIcon social={'instagram' as SocialIconsProvider} style={SocialStyle} />,
  facebook: <SocialIcon social={'facebook' as SocialIconsProvider} style={SocialStyle} />,
  twitter: <SocialIcon social={'x' as SocialIconsProvider} style={SocialStyle} />,
  linkedin: <SocialIcon social={'linkedin' as SocialIconsProvider} style={SocialStyle} />,
  google: <SocialIcon social={'google' as SocialIconsProvider} style={SocialStyle} />,
  tiktok: <SocialIcon social={'tiktok' as SocialIconsProvider} style={SocialStyle} />,
  youtube: <SocialIcon social={'youtube' as SocialIconsProvider} style={SocialStyle} />,
  pinterest: <SocialIcon social={'pinterest' as SocialIconsProvider} style={SocialStyle} />,
}

export class DraftPost extends Component<Props, State> {
  state: State = {
    postMedia: 0,
    hover: false,
  }

  //circularly
  nextImage = () => {
    const lengthMedias = this.props.post.data.medias?.length ?? 0
    this.setState({
      postMedia: lengthMedias + 1 === lengthMedias ? 0 : lengthMedias + 1,
    })
  }

  //circularly
  prevImage = () => {
    const lengthMedias = this.props.post.data.medias?.length ?? 0
    this.setState({
      postMedia: lengthMedias - 1 === -1 ? lengthMedias - 1 : lengthMedias - 1,
    })
  }

  openDraftPost = (e) => {
    e.stopPropagation()
    const { post, goToPost } = this.props
    const reusablePost = post
    const variantId = onClickSocialCard(post.info.account)

    // Eliminazione campi non necessari per la pubblicazione di una bozza
    delete reusablePost.data?.status
    delete reusablePost.data?.statusMessage
    delete reusablePost.data?.date
    delete reusablePost.data?._id
    delete reusablePost._id
    delete reusablePost.creationDate

    const currentPosts = useCreatePostStore
      .getState()
      .posts.filter((p) => p.info.account.account_id !== reusablePost.info.account.account_id)
    const newPosts = [reusablePost, ...currentPosts]

    if (variantId === 'expired') return
    useCreatePostStore.getState().setPosts(newPosts)

    goToPost && goToPost(true)
    AppStore.closeAllModal()
  }

  render() {
    const { post, style } = this.props
    const mediaSelected = post.data.medias?.[this.state.postMedia]

    return (
      <>
        <div
          style={{
            position: 'relative',
            width: 312,
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            ...style,
          }}
          onClick={() => {}}
          onMouseEnter={async () => this.setState({ hover: true })}
        >
          {this.state.hover && (
            <BOX
              onMouseEnter={async () => this.setState({ hover: true })}
              // messo true per testare pop confirm
              onMouseLeave={async () => this.setState({ hover: false })}
              style={{
                minWidth: '100%',
                minHeight: '100%',
                position: 'absolute',
                zIndex: 10,
              }}
            ></BOX>
          )}
          <Box
            borderRadius={14}
            ph={36}
            pv={20}
            shadow={this.state.hover ? '' : AppStore.theme.bigOuterShadow}
            onMouseEnter={async () => this.setState({ hover: true })}
            // messo true per testare pop confirm
            onMouseLeave={async () => this.setState({ hover: false })}
          >
            {this.state.hover && (
              <Box center>
                <SpecialButton
                  style={{ marginTop: mediaSelected?.url !== undefined ? '430px' : '80px', zIndex: 999 }}
                  size="small"
                  variant="secondary-small"
                  width={123}
                  onClick={this.openDraftPost}
                  onMouseEnter={async () => this.setState({ hover: true })}
                >
                  {T.postPage.createPost}
                </SpecialButton>

                <PopConfirm
                  title={<p>{T.postPage.sureToDeleteDraft}</p>}
                  okText={T.settings.confirm}
                  onConfirm={() => {
                    this.props.onPostDelete(post._id!)
                    this.setState({ hover: false })
                  }}
                >
                  {/* // è il bottone che ci dovrebbe essere */}
                  <SpecialButton
                    style={{
                      marginTop: mediaSelected?.url !== undefined ? '540px' : '190px',
                      zIndex: 999,
                    }}
                    size="small"
                    variant="delete-small"
                    width={123}
                    onClick={(e) => e.stopPropagation()}
                    onMouseEnter={async () => this.setState({ hover: true })}
                  >
                    {T.postPage.delete}
                  </SpecialButton>
                </PopConfirm>
              </Box>
            )}
            <Box row vcenter>
              <img
                style={{ width: '36px', height: '36px', borderRadius: 36, marginRight: 8 }}
                src={post.info.account.picture ?? AppStore?.workspace?.image?.presignedUrl}
              />
              <Box vcenter>
                <Text style={{ lineHeight: 1.2 }}>
                  <b>{post.info.account.name ?? AppStore.workspace.name}</b>
                </Text>
              </Box>
            </Box>
            <Text
              style={{
                marginTop: 10,
                maxHeight: 130,
                minHeight: mediaSelected?.url !== undefined ? 0 : 90,
                overflow: 'auto',
                wordBreak: 'break-word',
              }}
            >
              <p>{post.data.text}</p>
            </Text>
            {(post.data.medias ?? []).length > 0 && (
              <Box row center>
                {(post.data.medias ?? []).length > 1 && <BackwardIcon onClick={this.nextImage} />}
                {!mediaSelected?.mimetype?.includes('video') && mediaSelected?.url && (
                  <IMG
                    yes={this.state.hover}
                    style={{
                      filter: 'br',
                      objectFit: 'cover',
                      height: 380,
                      minWidth: '-webkit-fill-available',
                      marginTop: 16,
                      borderRadius: 30,
                      marginBottom: 16,
                    }}
                    src={mediaSelected.url}
                  />
                )}
                {mediaSelected?.mimetype?.includes('video') && mediaSelected?.url && (
                  <video
                    style={{
                      objectFit: 'cover',
                      height: 380,
                      minWidth: '-webkit-fill-available',
                      marginTop: 16,
                      borderRadius: 30,
                      marginBottom: 16,
                    }}
                    controls
                    src={mediaSelected.url}
                  />
                )}
                {!mediaSelected?.url && <Box height={380} mv={16}></Box>}
                {(post.data.medias ?? []).length > 1 && <ForwardIcon onClick={this.prevImage} />}
              </Box>
            )}
          </Box>
          {post.info.account && socialMediaIcons[post.info.account.provider]}
        </div>
      </>
    )
  }
}

const SpecialButton = styled(Button)`
  position: absolute;
  opacity: 100;
`
interface Pr {
  yes?: boolean
}

const IMG = styled.img<Pr>`
  ${({ yes }) => yes && `filter: brightness(0.45);`}
`

const BOX = styled(Box)`
  /* filter: brightness(0.35); */
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.7);
`
