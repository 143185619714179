import { BackwardIcon } from 'components/BackwardIcon'
import { Box } from 'components/Box'
import { ForwardIcon } from 'components/ForwardIcon'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import { Component } from 'react'
import { SocialAccount, SocialMedias } from 'types'
import { IPostVariant } from 'types/PostInterface'
import { AppStore } from 'utils'

interface Props {
  post: IPostVariant
  hideSocials?: boolean
  style?: React.CSSProperties
  provider?: SocialMedias
  socialAccount?: SocialAccount
  separated?: boolean
  socialAccounts?: SocialAccount[]
}

interface State {
  socialMedia: number
  postMedia: number
  socialMediaS: any
}

export class PreviewPost extends Component<Props, State> {
  socialMediaIcons = {
    instagram: <Icons.instagramPreviewIcon fill={AppStore.darkTheme ? 'white' : '#2E3A59'} />,
    facebook: <Icons.facebookPreviewIcon />,
    twitter: <Icons.twitterPreviewIcon />,
    linkedin: <Icons.linkedinPreviewIcon />,
    google: <Icons.googleMyBusinnesPreviewIcon />,
  }

  state: State = {
    socialMedia: 0,
    postMedia: 0,
    socialMediaS: ['instagram', 'facebook', 'x', 'linkedin', 'google'],
  }

  componentWillReceiveProps(nextProps: Props, prevProps: Props) {
    if (
      (nextProps.socialAccounts !== undefined &&
        nextProps.socialAccounts?.length > 0 &&
        this.props.socialAccounts !== undefined &&
        this.props.socialAccounts?.length <= 0) ||
      nextProps.socialAccounts?.length === 1
    ) {
      setTimeout(() => {
        this.nextSocialMedia()
      }, 200)
    }
    if (!nextProps.provider) return
    this.setState({ socialMedia: Object.keys(this.socialMediaIcons).findIndex((key) => key === nextProps.provider) })
  }

  nextSocialMedia = async () => {
    if (this.props.socialAccounts !== undefined && this.props.socialAccounts?.length > 0) {
      for (let i = 1; i <= 6; i++) {
        if (
          this.props.socialAccounts.filter(
            (socialAccount) =>
              socialAccount.provider ===
              this.state.socialMediaS[
                this.state.socialMedia + i >= Object.keys(this.socialMediaIcons).length
                  ? this.state.socialMedia + i - Object.keys(this.socialMediaIcons).length
                  : this.state.socialMedia + i
              ]
          ).length === 0
        ) {
        } else {
          this.setState({
            socialMedia:
              this.state.socialMedia + i >= Object.keys(this.socialMediaIcons).length
                ? this.state.socialMedia + i - Object.keys(this.socialMediaIcons).length
                : this.state.socialMedia + i,
          })

          break
        }
      }
    } else {
      this.setState({
        socialMedia:
          this.state.socialMedia + 1 === Object.keys(this.socialMediaIcons).length ? 0 : this.state.socialMedia + 1,
      })
    }
  }

  prevSocialMedia = () => {
    // this.setState({
    //   socialMedia:
    //     this.state.socialMedia - 1 === -1 ? Object.keys(this.socialMediaIcons).length - 1 : this.state.socialMedia - 1,
    // })
    if (this.props.socialAccounts !== undefined && this.props.socialAccounts?.length > 0) {
      let count
      for (let i = 1; i <= 6; i++) {
        count = 0
        if (
          this.props.socialAccounts.filter(
            (socialAccount) =>
              socialAccount.provider ===
              this.state.socialMediaS[
                this.state.socialMedia - i < 0
                  ? this.state.socialMedia - i + Object.keys(this.socialMediaIcons).length
                  : this.state.socialMedia - i
              ]
          ).length === 0
        ) {
        } else {
          this.setState({
            socialMedia:
              this.state.socialMedia - i < 0
                ? this.state.socialMedia - i + Object.keys(this.socialMediaIcons).length
                : this.state.socialMedia - i,
          })

          break
        }
      }
    } else {
      this.setState({
        socialMedia:
          this.state.socialMedia - 1 === -1
            ? Object.keys(this.socialMediaIcons).length - 1
            : this.state.socialMedia - 1,
      })
    }
  }
  //circularly
  nextImage = () =>
    this.setState({
      postMedia: this.state.postMedia + 1 === (this.props.post.data.medias ?? []).length ? 0 : this.state.postMedia + 1,
    })

  //circularly
  prevImage = () =>
    this.setState({
      postMedia:
        this.state.postMedia - 1 === -1 ? (this.props.post.data.medias ?? []).length - 1 : this.state.postMedia - 1,
    })

  render() {
    const { post, hideSocials, style, provider, socialAccount, socialAccounts } = this.props
    const { postMedia, socialMedia } = this.state

    const mediaSelected = post?.data.medias?.[postMedia]
    return (
      <Box style={style} width={376}>
        {!hideSocials && !provider && this.props.separated === undefined && (
          <Box
            bgPrimaryColor
            row
            vcenter
            justify={'space-around'}
            shadow={AppStore.theme.simpleOuterShadow}
            height={56}
            mb={16}
          >
            {this.props.socialAccounts === undefined ||
              (this.props.socialAccounts?.length !== 1 && <BackwardIcon onClick={this.prevSocialMedia} />)}

            {Object.values(this.socialMediaIcons)[this.state.socialMedia]}
            {this.props.socialAccounts === undefined ||
              (this.props.socialAccounts?.length !== 1 && <ForwardIcon onClick={this.nextSocialMedia} />)}
          </Box>
        )}
        {!hideSocials && !provider && this.props.separated !== undefined && (
          <Box
            bgPrimaryColor
            row
            vcenter
            justify={'space-around'}
            shadow={AppStore.theme.simpleOuterShadow}
            height={56}
            mb={16}
          >
            {Object.values(this.socialMediaIcons)[this.state.socialMediaS.indexOf(post.info.account.provider)]}
          </Box>
        )}
        <Box bgPrimaryColor borderRadius={14} ph={36} pv={27} shadow={AppStore.theme.bigOuterShadow}>
          <Box row vcenter>
            <img
              style={{ width: '36px', height: '36px', borderRadius: 36, marginRight: 8 }}
              src={socialAccount?.picture ?? AppStore?.workspace?.image?.presignedUrl}
            />
            <Box vcenter>
              <Text style={{ lineHeight: 1.2 }}>
                <b>{socialAccount?.name ?? AppStore.workspace.name}</b>
              </Text>
              {/* <Text style={{ lineHeight: 1, color: AppStore.theme.o.darkGrey }}>Verona</Text> */}
            </Box>
          </Box>
          {socialMedia > 0 && this.props.separated === undefined && (
            <Text
              style={{
                maxHeight: 130,
                width: '100%',
                whiteSpace: 'pre-line',
                marginTop: 20,
                minHeight: 10,
                overflow: 'auto',
                wordBreak: 'break-word',
              }}
            >
              <p>{post.data.text}</p>
            </Text>
          )}
          <Box row center>
            {(post.data.medias ?? []).length > 1 && <BackwardIcon onClick={this.prevImage} />}
            {!mediaSelected?.mimetype?.includes('video') && mediaSelected?.url && (
              <img
                style={{
                  objectFit: 'cover',
                  height: 380,
                  minWidth: '-webkit-fill-available',
                  marginTop: 16,
                  borderRadius: 30,
                  marginBottom: 16,
                }}
                src={mediaSelected.url}
              />
            )}
            {mediaSelected?.mimetype?.includes('video') && mediaSelected?.url && (
              <video
                style={{
                  objectFit: 'cover',
                  height: 380,
                  minWidth: '-webkit-fill-available',
                  marginTop: 16,
                  borderRadius: 30,
                  marginBottom: 16,
                }}
                controls
                src={mediaSelected.url}
              />
            )}
            {!mediaSelected?.url && <Box height={380} mv={16}></Box>}
            {(post.data.medias ?? []).length > 1 && <ForwardIcon onClick={this.nextImage} />}
          </Box>
          {socialMedia === 0 && <Icons.instagramPreviewFooter fill={AppStore.theme.o.black} />}
          {socialMedia === 1 && <Icons.facebookPreviewFooter width={'100%'} fill={AppStore.theme.o.black} />}
          {socialMedia === 2 && <Icons.twitterPreviewFooter width={'100%'} fill={AppStore.theme.o.black} />}
          {socialMedia === 3 && <Icons.facebookPreviewFooter width={'100%'} fill={AppStore.theme.o.black} />}
          {socialMedia === 4 && <Icons.facebookPreviewFooter width={'100%'} fill={AppStore.theme.o.black} />}
          {socialMedia === 0 && this.props.separated === undefined && (
            <Text
              style={{
                width: '100%',
                whiteSpace: 'pre-line',
                maxHeight: 130,
                minHeight: 50,
                overflow: 'auto',
                wordBreak: 'break-word',
              }}
            >
              <p>{post.data.text}</p>
            </Text>
          )}
        </Box>
      </Box>
    )
  }
}
