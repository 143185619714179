import styled from '@emotion/styled'
import Utils from 'api/Utils'
import { Box, Icons, Text, Title } from 'components'
import { Component, useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { Service, SubscriptionOption, SubscriptionProvider } from '../Subscription/SubscriptionProvider'
import CancelPlanButton from './CancelPlanButton'
import ReferralComponent from './ReferralComponent'
import { ServicesDisplay } from '../Subscription/ServicesDisplay'
import { useNavigate } from 'react-router-dom'
import { refresh } from 'utils/router'
import { isVisible } from 'utils/CheckVisibleElement'

interface Props {
  refreshParent?: () => void
}

const MyPlan = ({ refreshParent }: Props) => {
  const [subscriptionOptions, setSubscriptionOptions] = useState<SubscriptionOption[]>(
    JSON.parse(JSON.stringify(SubscriptionProvider.billableOptions))
  )
  const [additionalOptions, setAdditionalOptions] = useState<Service[]>(
    JSON.parse(JSON.stringify(SubscriptionProvider.customizableOptions))
  )

  const [storageGeneration, setStorageGeneration] = useState<any>(
    AppStore.loggedUser.rawPlan?.storageGeneration === undefined ? 0 : AppStore.loggedUser.rawPlan?.storageGeneration
  )
  const [extraSocialGeneration, setExtraSocialGeneration] = useState<any>(
    AppStore.loggedUser.rawPlan?.extraSocialGeneration === undefined
      ? 0
      : AppStore.loggedUser.rawPlan?.extraSocialGeneration
  )
  const [price, setPrice] = useState<number>(0)
  const [planeCost, setPlaneCost] = useState<number>(0)

  const navigate = useNavigate()

  /* -------------------- VARIABLES */
  const activeSubscriptionOption = subscriptionOptions.find((o) =>
    o.stripePrice.monthly.toLowerCase().includes(AppStore.loggedUser.rawPlan?.plan.toLowerCase() ?? '')
  ) as SubscriptionOption

  const planCost =
    AppStore.loggedUser.rawPlan?.prices?.some((p) => p.id.includes('ANNUAL')) ?? false
      ? ` €/ ${T.settings.year}`
      : ` €/ ${T.settings.month}`

  /* -------------------- METHODS */

  const changeAdditionalOption = (addOpts) => setAdditionalOptions(addOpts)

  const subscriptionAddOn = (e, key) => {
    try {
      return activeSubscriptionOption.services.find((s) => s.key === key)?.quantity ?? 0
    } catch {
      return 0
    }
  }

  /* --------------------- RENDER METHODS */
  const renderService = (s: { label: string; bold?: boolean; disabled?: boolean }, index) => (
    <Box pv={12} row key={s.label}>
      <Box flex>
        <Text
          style={
            s.bold
              ? {
                  fontWeight: 700,
                  textDecoration: AppStore.loggedUser.rawPlan?.plan === 'agency' ? 'underline' : 'none',
                }
              : {
                  color: s.disabled ? AppStore.theme.o.darkGrey : AppStore.theme.o.black,
                }
          }
          fontSize={16}
        >
          {s.label}
        </Text>
      </Box>
      <Box center width={16} height={24}>
        {s.disabled && <Icons.close />}
        {!s.disabled && <Icons.activeBox />}
      </Box>
    </Box>
  )

  const renderAddionalOptions = (option: Service, index) => (
    <AdditionalOptionContainer mr={36}>
      <Box row pv={24} ph={40} style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }}>
        <Box flex>
          <Text style={{ marginBottom: 12 }} weight={700} fontSize={16}>
            {option.label}
          </Text>
          <Box vcenter row pv={8}>
            <Text style={{ marginRight: 40, width: 170 }} color={AppStore.theme.o.darkGrey}>
              {option.description}
            </Text>

            <Text style={{ width: 48, textAlign: 'center' }}>
              {(index === 0 ? storageGeneration * 5 : extraSocialGeneration) + (index === 0 ? 'GB' : '')}
              {/* // +this.subscriptionAddOn(this.activeSubscriptionOption.services, option.key) */}
            </Text>
          </Box>
        </Box>
        <Box width={100} justify="center" ml={72}>
          <Box row justify={'space-between'}>
            <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
              {T.settings.fromPlan}
            </Text>
            <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
              {subscriptionAddOn(activeSubscriptionOption.services, option.key)}
            </Text>
          </Box>
          <Box row justify={'space-between'}>
            <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
              {T.settings.extra}
            </Text>
            <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
              {index === 0 ? storageGeneration * 5 : extraSocialGeneration}
            </Text>
          </Box>
          <Box row justify={'space-between'}>
            <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
              {T.settings.total}
            </Text>
            <Text color={AppStore.theme.o.darkGrey} fontSize={14}>
              {(index === 0 ? storageGeneration * 5 : extraSocialGeneration) +
                subscriptionAddOn(activeSubscriptionOption.services, option.key)}
            </Text>
          </Box>
        </Box>
      </Box>
    </AdditionalOptionContainer>
  )

  /* --------------------- API */
  const getSubscription = async () => {
    try {
      AppStore.mySubscription = await Utils.getMySubscription()
    } catch (error) {}
  }

  /* --------------------- USEEFFECT */
  useEffect(() => {
    getSubscription()

    /* forceUpdate() */
  }, [])

  return (
    <Box>
      <Box center style={{ overflow: 'visible' }}>
        <ReferralComponent
          planCost={planCost}
          editOption={(annual) => {
            if (!isVisible('settings.myPlan.referralComponent.navigateOpt')) return
            navigate('/settings/subscription', { state: { from: 'settings', part: 'MyPlan' } })

            AppStore.loggedUser!._rawPlan = AppStore.loggedUser!.rawPlan
            AppStore.loggedUser!._stripe = AppStore.loggedUser!.stripe
            if (annual) AppStore.upgrading = true
            else AppStore.upgrading = false

            /*  this.props.refreshParent() */
          }}
        />
      </Box>

      <Box center>
        <Box width={639}>
          <ServicesDisplay selectedOption={activeSubscriptionOption} />
        </Box>
        <Box ml={500} mt={16} style={{ left: 0 }}>
          <CancelPlanButton />
        </Box>
      </Box>
      <Box ml={120} mt={31} mb={16} row>
        <Title style={{ color: AppStore.theme.o.black }}>{T.settings.addOn}</Title>
        <Box
          ml={20}
          ph={10}
          pv={4}
          row
          id="change-add-on"
          onClick={() => {
            if (!isVisible('settings.myPlan.changeAddOn')) return

            AppStore.loggedUser!._rawPlan = AppStore.loggedUser!.rawPlan
            AppStore.loggedUser!._stripe = AppStore.loggedUser!.stripe
            navigate('/settings/subscription', { state: { from: 'settings', part: 'customization' } })
          }}
          width={200}
        >
          <Icons.edit fill={AppStore.theme.o.darkGrey} />
          <Text
            fontSize={16}
            weight={500}
            style={{
              marginTop: 4,
              color: AppStore.theme.o.darkGrey,
            }}
          >
            {T.settings.modifyAddOn}
          </Text>
        </Box>
      </Box>
      <Box ml={120} width={670}>
        {additionalOptions.map(renderAddionalOptions)}
      </Box>
    </Box>
  )
}

export default MyPlan

const AdditionalOptionContainer = styled(Box)<{ active?: boolean }>`
  margin-top: 12px;
  :nth-child(1) {
    margin-top: 0;
  }
`
