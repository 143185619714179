//add prettier "printWidth": 240 only for this file
//

import styled from '@emotion/styled'
import { Box, Button, Icons, SocialIcon, Spacer, Text, Title, ZoomedContainer } from 'components'
import { Component } from 'react'
import { AppStore, T } from 'utils'

type Template = {
  type?: 'video' | 'image'
  template: string
  designType?: string
  size?: string
  ratio: '16:9' | '9:16' | '1:1'
  customDimensions?: {
    width: number
    height: number
    measureUnits: 'px' | 'cm' | 'in'
  }
}

const templates: {
  [key: string]: Template[]
} =
  // prettier-ignore
  {
  facebook: [
    { template: 'Post', designType: 'facebookSM', customDimensions: { width: 940, height: 788, measureUnits: 'px' }, size: '940 x 788', ratio: '16:9', type: 'image' },
    { template: 'Cover', designType: 'facebookSM', customDimensions: { width: 851, height: 315, measureUnits: 'px' }, size: '851 x 315', ratio: '16:9', type: 'image' },
    { template: 'Event Cover', designType: 'facebookSM', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080', ratio: '16:9', type: 'image' },
    { template: 'Video Story', designType: 'facebookVideoStoryAN', customDimensions: { width: 1080, height: 1920, measureUnits: 'px' }, size: '1080 x 1920', ratio: '9:16', type: 'video' },
    { template: 'Video Cover', designType: 'facebookSM', customDimensions: { width: 1702, height: 630, measureUnits: 'px' }, size: '1702 x 630', ratio: '16:9', type: 'video' },
    { template: 'Formato\npersonalizzato', designType: 'custom',ratio: '1:1' },
  ],
  instagram: [
    { template: 'Quadrato', designType: 'instagramSM', customDimensions: { width: 1080, height: 1080, measureUnits: 'px' }, size: '1080 x 1080', ratio: '1:1', type: 'image' },
    { template: 'Verticale', designType: 'instagramSM', customDimensions: { width: 1080, height: 1350, measureUnits: 'px' }, size: '1080 x 1350', ratio: '9:16', type: 'image',},
    { template: 'Landscape', designType: 'instagramSM', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080', ratio: '16:9', type: 'image' },
    { template: 'Storia', designType: 'instagramSM', customDimensions: { width: 1080, height: 1920, measureUnits: 'px' }, size: '1080 x 1920',ratio: '9:16',type: 'image',},
    { template: 'Video storia', designType: 'instagramVideoStoryAN', customDimensions: { width: 1080, height: 1920, measureUnits: 'px' }, size: '1080 x 1920', ratio: '9:16', type: 'video' },
    { template: 'Formato\npersonalizzato',designType: 'custom',  ratio: '1:1' },
  ],
  x: [
    { template: 'Quadrato', designType: 'twitterSM', customDimensions: { width: 1080, height: 1080, measureUnits: 'px' }, size: '1080 x 1080', ratio: '1:1', type: 'image' },
    { template: 'Verticale', designType: 'twitterSM', customDimensions: { width: 1080, height: 1350, measureUnits: 'px' }, size: '1080 x 1350', ratio: '9:16', type: 'image',},
    { template: 'Orizzontale', designType: 'twitterSM', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080',ratio: '16:9',type: 'image',},
    { template: 'Storia', designType: 'twitterSM', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080', ratio: '9:16', type: 'image' },
    { template: 'In-Stream', designType: 'twitterSM', customDimensions: { width: 1600, height: 900, measureUnits: 'px' }, size: '1600 x 900', ratio: '16:9', type: 'image' },
    { template: 'Formato\npersonalizzato',designType: 'custom',  ratio: '1:1' },
  ],
  linkedin: [
    { template: 'Quadrato',designType: 'linkedInCoverHC', customDimensions: { width: 1200, height: 1200, measureUnits: 'px' }, size: '1200 x 1200', ratio: '1:1', type: 'image' },
    { template: 'Orizzontale',designType: 'linkedInCoverHC', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080',ratio: '16:9',type: 'image',},
    { template: 'Storia',designType: 'linkedInCoverHC', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080', ratio: '9:16', type: 'image' },
    { template: 'Blog post link',designType: 'linkedInCoverHC', customDimensions: { width: 1200, height: 627, measureUnits: 'px' }, size: '1200 x 627', ratio: '9:16', type: 'image' },
    { template: 'Formato\npersonalizzato',designType: 'custom',  ratio: '1:1' },
  ],
  youtube: [
    { template: 'Quadrato', designType: 'youtubeHC', customDimensions: { width: 1200, height: 1200, measureUnits: 'px' }, size: '1200 x 1200', ratio: '1:1', type: 'image' },
    { template: 'Verticale', designType: 'youtubeHC', customDimensions: { width: 1080, height: 1350, measureUnits: 'px' }, size: '1080 x 1350', ratio: '9:16', type: 'image',},
    { template: 'Orizzontale', designType: 'youtubeHC', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080', ratio: '16:9', type: 'image' },
    { template: 'Storia', designType: 'youtubeHC', customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080',ratio: '9:16',type: 'image',},
    { template: 'Thumbnail', designType: 'youtubeHC', customDimensions: { width: 1280, height: 720, measureUnits: 'px' }, size: '1280 x 720', ratio: '16:9', type: 'image' },
    { template: 'Formato\npersonalizzato',designType: 'custom',  ratio: '1:1' },
  ],
  //? Removed GoogleMyBusiness after new createpost
  // google: [
  //   { template: 'Quadrato',  customDimensions: { width: 1200, height: 1200, measureUnits: 'px' }, size: '1200 x 1200', ratio: '1:1', type: 'image' },
  //   { template: 'Verticale',  customDimensions: { width: 1080, height: 1350, measureUnits: 'px' }, size: '1080 x 1350', ratio: '9:16', type: 'image',},
  //   { template: 'Orizzontale',  customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080', ratio: '16:9', type: 'image' },
  //   { template: 'Storia',customDimensions: { width: 1920, height: 1080, measureUnits: 'px' }, size: '1920 x 1080',ratio: '9:16',type: 'image',},
  //   { template: 'Thumbnail',  customDimensions: { width: 1280, height: 720, measureUnits: 'px' }, size: '1280 x 720', ratio: '16:9', type: 'image' },
  //   { template: 'Formato\npersonalizzato', designType: 'custom', ratio: '1:1' },
  // ]
}

type TemplateKeys = keyof typeof templates

interface Props {
  onTemplateClick: (template: Template) => void
}

interface State {
  selectedTemplate: TemplateKeys
  templateHover?: TemplateKeys
}

// prettier-ignore - end

export default class MediaTemplateModal extends Component<Props, State> {
  state: State = {
    selectedTemplate: 'facebook',
    templateHover: undefined,
  }

  render() {
    return (
      <ZoomedContainer pv={48} ph={80}>
        <Title>{T.mediaPage.selectSocial}</Title>
        <Box mt={16} justify={'space-between'} row>
          {(Object.keys(templates) as TemplateKeys[]).map((social) => (
            <SocialTemplateGroup
              onClick={() => this.setState({ selectedTemplate: social })}
              center
              flex
              key={social}
              width={120}
              height={56}
              variant={social === this.state.selectedTemplate ? 'primary-small' : 'neutral'}
            >
              <SocialIcon
                style={{ width: 54, height: 54 }}
                social={social as any}
                active={social === this.state.selectedTemplate}
              />
            </SocialTemplateGroup>
          ))}
        </Box>
        <BoxDashed pv={16} ph={24} mt={24}>
          <Title color={AppStore.theme.o.darkGrey} style={{ marginBottom: 24 }}>
            {T.mediaPage.selectFormat}
          </Title>
          <Grid flex row>
            {templates[this.state.selectedTemplate].map((template) => (
              <TemplateCard
                hideBoxShadowOnHover
                flex
                onClick={() => this.props.onTemplateClick(template)}
                pv={24}
                hcenter
                onMouseEnter={() => this.setState({ templateHover: template.template })}
                onMouseLeave={() => this.setState({ templateHover: undefined })}
              >
                <TemplateRatio aspectRatio={template.ratio} hover={template.template === this.state.templateHover} />
                <Box mt={10} row>
                  {template.type === 'image' && (
                    <Icons.templateImage style={{ marginRight: 8 }} fill={AppStore.theme.o.black} />
                  )}
                  {template.type === 'video' && (
                    <Icons.templateVideo style={{ marginRight: 8 }} fill={AppStore.theme.o.black} />
                  )}
                  <Text style={{ textAlign: 'center', whiteSpace: 'pre-line' }} weight={700} fontSize={21}>
                    {template.template}
                  </Text>
                </Box>
                <Spacer />
                {!template.size && <Spacer height={50} />}
                {template.size && (
                  <Text weight={700} fontSize={16}>
                    {template.size} px
                  </Text>
                )}
              </TemplateCard>
            ))}
          </Grid>
        </BoxDashed>
      </ZoomedContainer>
    )
  }
}

const Grid = styled(Box)`
  gap: 32px 8px;
  flex-wrap: wrap;
`

const TemplateRatio = styled(Box)<{ aspectRatio: '16:9' | '9:16' | '1:1'; hover: boolean }>`
  background: ${() => AppStore.theme.o.lightGrey};
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  border-radius: 14px;
  ${({ hover }) => hover && `background: ${AppStore.theme.o.lightBlue};`}
  ${({ aspectRatio }) => {
    switch (aspectRatio) {
      case '16:9':
        return `width: 82px; height: 50px;`
      case '9:16':
        return `width: 32px; height: 50px;`
      case '1:1':
        return `width: 50px; height: 50px;`
    }
  }}
`

const TemplateCard = styled(Box)`
  ${({}) => AppStore.theme.smallOuterShadow}
  min-width: 250px;
  height: 168px;
  //onHover
  &:hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
  }
`

const BoxDashed = styled(Box)`
  border: 1px dashed ${() => AppStore.theme.o.darkGrey};
  border-radius: 14px;
`

const SocialTemplateGroup = styled(Button)`
  margin-left: 16px;
  ${({}) => AppStore.theme.simpleOuterShadow}
  &:first-child {
    margin-left: 0px;
  }
`
