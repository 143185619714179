import styled from '@emotion/styled'
import Medias from 'api/Medias'
import { Box, FolderModal, Icons, Text } from 'components'
// import { Icons } from 'components'
import { Component } from 'react'
import { Media } from 'types'

import { AppStore, showError, showInfo, showSuccess, T } from 'utils'
import ChangeFileFolder from './ChangeFileFolder'
import PopConfirm from 'components/PopConfirm'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS } from 'utils/eventsTracker'

interface State {
  showOptions: boolean
}

interface Props {
  media: Media
  show: boolean
  path: string
  modifyOn: () => void
  changePath: (path: string) => void
}
export default class IconEdit extends Component<Props, State> {
  state: State = {
    showOptions: false,
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    // if (prevProps.show != this.props.show) this.setState({ showOptions: false })
  }
  onDeleteMedias = async () => {
    try {
      this.props.modifyOn()
      await Medias.delete(this.props.media._id)
      Tracker.trackEvent(TRACKEREVENTS.DELETE_FOLDER_COMPLETED, {})
      showSuccess(T.success.cancelazioneFolder)
      location.reload()
    } catch (error: any) {
      showError(error?.message ?? T.error.error)
    }
  }

  openModalChangeFolder = () => {
    const { path } = this.props
    this.props.modifyOn()
    this.setState({ showOptions: false })
    AppStore.openModal({
      id: 'folder-modal',
      body: (
        <ChangeFileFolder
          path={this.props.path}
          selectedMedia={[this.props.media]}
          fodler={true}
          onFinish={async (movePath: string) => {
            await Medias.changePath({ media: [this.props.media], path: movePath })
            this.props.changePath(movePath)
          }}
        />
      ),
      style: {
        minHeight: '30vh',
        minWidth: '50vw',
        marginBottom: 100,
      },
    })
  }

  renameModal = async () => {}
  render() {
    if (this.props.show)
      return (
        <>
          {/* {!this.state.showOptions && ( */}
          <Box>
            <EditIcon
              onClick={() => {
                this.props.modifyOn()
                this.setState({ showOptions: true })
              }}
              width={30}
              height={30}
            />
            {this.state.showOptions && (
              <Box
                style={{
                  position: 'absolute',
                  minWidth: 25,
                  borderRadius: 4,
                  minHeight: 25,
                  backgroundColor: AppStore.theme.o.background,
                  top: 0,
                  right: 10,
                  zIndex: 99,
                }}
              />
            )}
          </Box>
          {/* )} */}
          {this.state.showOptions && (
            <AbsoluteBox
              onMouseLeave={() => {
                this.props.modifyOn()
                this.setState({ showOptions: false })
              }}
            >
              <BoxNormal
                hideBoxShadowOnHover
                onClick={() => {
                  this.props.modifyOn()
                  this.setState({ showOptions: false })
                  AppStore.openModal({
                    id: 'folder-moda-rename',
                    body: <FolderModal onConfirm={this.renameModal} media={this.props.media._id} />,
                  })
                }}
              >
                <BoxInnrGrey>
                  <ClickText color={AppStore.theme.o.black}>{T.mediaPage.rename}</ClickText>
                </BoxInnrGrey>
              </BoxNormal>

              <BoxNormal onClick={() => this.openModalChangeFolder()}>
                <BoxInnrGrey>
                  <ClickText color={AppStore.theme.o.black}>{T.mediaPage.move}</ClickText>
                </BoxInnrGrey>
              </BoxNormal>
              <PopConfirm
                title={<Text>{T.mediaPage.askDelete}</Text>}
                okText={T.settings.confirm}
                onConfirm={this.onDeleteMedias}
              >
                <BoxDelete>
                  <BoxInnrRed>
                    <ClickText color={AppStore.theme.o.red}>{T.postPage.delete}</ClickText>
                  </BoxInnrRed>
                </BoxDelete>
              </PopConfirm>
            </AbsoluteBox>
          )}
        </>
      )
    else {
      return <></>
    }
  }
}

const BoxInnrGrey = styled(Box)`
  padding-left: 4px;
  border-radius: 8px;
  :hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
`
const BoxInnrRed = styled(Box)`
  padding-left: 4px;
  border-radius: 8px;
  :hover {
    background-color: ${() => AppStore.theme.o.lightRed};
  }
`

const AbsoluteBox = styled(Box)`
  ${() => AppStore.theme.bigOuterShadow}
  position: absolute;
  background-color: ${() => AppStore.theme.o.background} !important;
  border-radius: 4px;
  z-index: 99;
  top: 0;
  right: -130px;
  padding-top: 6px;
  padding-bottom: 6px;
`
const BoxNormal = styled(Box)`
  min-width: 134px;
  min-height: 28px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  background-color: ${() => AppStore.theme.o.background} !important;
`
const BoxDelete = styled(Box)`
  min-width: 134px;
  min-height: 28px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 4px;
  background-color: ${() => AppStore.theme.o.background} !important;
`

const ClickText = styled((props) => <Text {...props} />)<{ color: string }>`
  color: ${() => AppStore.theme.o.black};
  font-weight: 400;
  margin-left: 6px;
  font-size: 16px;
  color: ${(props) => props.color};
`

const EditIcon = styled((props) => <Icons.edit {...props} />)`
  cursor: pointer;
  z-index: 100;
  fill: ${() => AppStore.theme.o.black};
  :hover {
    fill: ${() => AppStore.theme.o.black};
  }
  z-index: 99999;
  top: -2px;
  right: 7.5px;
  position: absolute;
`
