/* ------------------- IMPORTS */
import styled from '@emotion/styled'

import { Box, Button, Icons, Text } from 'components'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { SubscriptionOption, SubscriptionProvider } from './SubscriptionProvider'
import { Chip } from './Chip'
import { GetDemoButton } from './GetDemoButton'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */
interface CardProps {
  active?: boolean
  selected?: boolean
}

interface Props {
  plan: SubscriptionOption
  index: number
  subscriptionMode: 'monthly' | 'annual'
  setActiveSubscriptionOption: any
  onNext: any
  activePlan: number
  isMonthlyOrAnnual: string //'monthly' | 'annual'
}

export const RenderOptionCard = ({
  plan,
  index,
  subscriptionMode,
  setActiveSubscriptionOption,
  onNext,
  activePlan,
  isMonthlyOrAnnual,
}: Props) => {
  /* ----------------- METHODS  */
  const downgradeUpgrade = () => {
    if (activePlan === -1) {
      return T.settings.continue
    }

    if (isMonthlyOrAnnual === 'annual') {
      if (subscriptionMode === 'monthly') {
        return T.settings.downgrade
      } else if (subscriptionMode === 'annual') {
        if (activePlan === index && isMonthlyOrAnnual === subscriptionMode) return T.settings.continue

        if (index > activePlan) {
          if (isMonthlyOrAnnual === subscriptionMode) {
            return T.settings.upgrade
          } else {
            return T.settings.downgrade
          }
        }

        if (index < activePlan) {
          if (isMonthlyOrAnnual === subscriptionMode) {
            return T.settings.downgrade
          } else {
            return T.settings.upgrade
          }
        }
      }
    } else if (isMonthlyOrAnnual === 'monthly') {
      if (subscriptionMode === 'annual') {
        return T.settings.upgrade
      }
      if (subscriptionMode === 'monthly') {
        if (activePlan === index && isMonthlyOrAnnual === subscriptionMode) return T.settings.continue

        if (index > activePlan) {
          if (isMonthlyOrAnnual === subscriptionMode) {
            return T.settings.upgrade
          } else {
            return T.settings.downgrade
          }
        }

        if (index < activePlan) {
          if (isMonthlyOrAnnual === subscriptionMode) {
            return T.settings.downgrade
          } else {
            return T.settings.upgrade
          }
        }
      }
    }
  }

  /* ----------------- STATE  */
  const [btnNextText, setBtnNextText] = useState(downgradeUpgrade())

  useEffect(() => {
    setBtnNextText(downgradeUpgrade())
  }, [subscriptionMode])

  return (
    <RenderOptionCardContainer>
      <PlanOption
        center
        active={plan.active}
        selected={activePlan === index && !plan.active && isMonthlyOrAnnual === subscriptionMode}
        onClick={() => {
          setBtnNextText(downgradeUpgrade())
          setActiveSubscriptionOption(index)
        }}
      >
        {/* CHIP FOR OFFERS */}
        {/* {plan.offer.type === subscriptionMode && <Chip text={plan.offer.text} position="top-left" />} */}

        <CheckboxIcon>
          {plan.active ? (
            <Icons.checkbox width={40} height={40} />
          ) : (
            <Icons.notCheckedBox
              width={20}
              height={20}
              fill={index === activePlan ? AppStore.theme.o.blueOnLight : AppStore.theme.o.lightGrey}
              style={{ marginRight: 10, marginLeft: 10 }}
            />
          )}
        </CheckboxIcon>

        <Box flex ml={4}>
          {activePlan === index && subscriptionMode === 'annual' && isMonthlyOrAnnual === 'monthly' && (
            <Chip text={T.plans.annualVersionChipText} position="top-right" bgColor="blue" />
          )}
          <Text color={plan.active ? AppStore.theme.o.surface : AppStore.theme.o.blue} fontSize={16} weight={700}>
            {activePlan === index && isMonthlyOrAnnual === subscriptionMode
              ? `${T.settings.yourPlan}`
              : `${plan.label}`}
          </Text>

          {subscriptionMode === 'annual' ? (
            <Box row>
              {activePlan === index && isMonthlyOrAnnual === subscriptionMode ? (
                <Text
                  color={plan.active ? AppStore.theme.o.surface : AppStore.theme.o.black}
                  weight={500}
                  fontSize={14}
                >
                  {plan.label}
                </Text>
              ) : (
                <>
                  {plan.label === 'Enterprise' ? (
                    <></>
                  ) : (
                    <Text
                      color={
                        plan.active
                          ? plan.offer !== undefined && plan.offer.type === subscriptionMode
                            ? AppStore.theme.o.surface
                            : AppStore.theme.o.black
                          : AppStore.theme.o.black
                      }
                      weight={500}
                      fontSize={12}
                    >
                      <Text
                        fontSize={12}
                        style={{
                          display: 'inline',
                          color: plan.active ? AppStore.theme.o.surface : AppStore.theme.o.darkGrey,
                        }}
                      >
                        {T.settings.saveThe}
                      </Text>
                      {plan.offer !== undefined && plan.offer.type === subscriptionMode && (
                        <Text
                          fontSize={12}
                          style={{
                            display: 'inline',
                            color: plan.active ? AppStore.theme.o.surface : AppStore.theme.o.blueOnLight,
                          }}
                        >
                          {plan.offer.percentage}%
                        </Text>
                      )}
                    </Text>
                  )}
                </>
              )}
            </Box>
          ) : (
            <>
              {activePlan === index && isMonthlyOrAnnual === subscriptionMode && (
                <Text
                  color={plan.active ? AppStore.theme.o.surface : AppStore.theme.o.black}
                  weight={500}
                  fontSize={14}
                >
                  {plan.label}
                </Text>
              )}
            </>
          )}
        </Box>

        <Box>
          {plan.label === 'Enterprise' ? (
            <></>
          ) : (
            <>
              <Box row style={{ alignItems: 'end', justifyContent: 'right' }}>
                <Text
                  weight={700}
                  fontSize={21}
                  style={{
                    display: 'inline',
                    color: plan.active ? AppStore.theme.o.surface : AppStore.theme.o.black,
                  }}
                >
                  {plan.price[subscriptionMode]}€
                  <Text
                    color={plan.active ? AppStore.theme.o.surface : AppStore.theme.o.black}
                    weight={700}
                    fontSize={14}
                    style={{ marginBottom: 2, display: 'inline' }}
                  >
                    /{T.settings.month}
                  </Text>
                </Text>
              </Box>
              <Text fontSize={9} color={plan.active ? AppStore.theme.o.surface : ''}>
                {plan.offer !== undefined && plan.offer.type === subscriptionMode && `${plan.offer.slogan}`}
              </Text>
              <Box row style={{ alignItems: 'end', justifyContent: 'right' }}>
                {subscriptionMode === 'annual' && plan.label != 'Enterprice' && (
                  <Text
                    color={plan.active ? AppStore.theme.o.surface : AppStore.theme.o.black}
                    weight={700}
                    fontSize={14}
                    style={{ marginRight: 4 }}
                  >
                    {(plan.price[subscriptionMode] * 12).toFixed(0)}€ /{T.settings.year}
                  </Text>
                )}
              </Box>
            </>
          )}
        </Box>
      </PlanOption>
      {plan.active &&
        (plan.label === 'Enterprise' ? (
          <GetDemoButton />
        ) : (
          <ButtonNext animation="slide" size="small" onClick={onNext}>
            {btnNextText}
          </ButtonNext>
        ))}
    </RenderOptionCardContainer>
  )
}

const RenderOptionCardContainer = styled(Box)`
  width: 333px;
`

const ButtonNext = styled(Button)`
  max-width: 154px;
  align-self: center;
  padding: 8px 28px;
  margin-bottom: 16px;
  border-radius: 14px;
  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
    color: ${() => AppStore.theme.o.blueOnLight};
    box-shadow: none;
  }
`

const CheckboxIcon = styled(Box)`
  margin: 20px 0px;
`

const PlanOption = styled(Box)<CardProps>`
  background-color: ${({ active }) => (active === true ? AppStore.theme.o.blueOnLight : AppStore.theme.o.surface)};
  margin-bottom: ${({ active }) => (active === true ? '8px' : '16px')};
  height: 108px;
  width: 100%;
  position: relative;
  flex-direction: row;
  padding-right: 24px;
  padding-left: 12px;

  //Box shadow
  ${() => AppStore.theme.smallOuterShadow}
  ${({ selected }) =>
    selected === true &&
    `
    border-color: 1px solid ${!selected ? 'transparent' : AppStore.theme.o.blueOnLight}!important;
    box-shadow : none !important;
    background-color:${AppStore.theme.o.lightBlue}!important
    `};

  &:hover {
    border-color: ${({ active }) => `1px solid ${!active ? 'transparent' : AppStore.theme.o.blueOnLight} !important`};
    background-color: ${({ active }) =>
      active ? AppStore.theme.o.blueOnLight : AppStore.theme.o.lightBlue} !important;

    box-shadow: none;
  }
`
