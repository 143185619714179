import { AnimatedPlaceholderLogo, Box, Page, Text } from 'components'
import { Calendar } from 'components/Calendar/Calendar'
import { LateralCalendar } from 'components/Calendar/LateralCalendar'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import CalendarHeader from './calendarHeader/CalendarHeader'
import { ISocialFilters, TStatus } from 'stores/interfaces/CalendarPageInterface'
import { AVAILABLE_SOCIALS, mapSocialTypeToSocialMedias } from 'utils/Constants/Constants'
import { IPostVariant } from 'types/PostInterface'
import PostV2 from 'api/PostV2'
import { useNavigate } from 'react-router-dom'
import { useMenuNavigationStore } from 'stores/MenuNavigationStore'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getCalendarData } from 'utils/eventsTracker'

const date = new Date()
const months = T.calendarPage.months

export const CalendarPage = () => {
  /* --------------------------------- ROUTER */
  const navigate = useNavigate()

  const [goToPost, setGoToPost] = useState<boolean>(false)
  const [year, setYear] = useState<number>(date.getFullYear())
  const [month, setMonth] = useState<number>(date.getMonth())
  const [posts, setPosts] = useState<IPostVariant[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [socialFilters, setSocialFilters] = useState<ISocialFilters[]>(
    AVAILABLE_SOCIALS.map((social) => ({ social: mapSocialTypeToSocialMedias(social), active: true }))
  )
  const [status, setStatus] = useState<TStatus>('Tutti')
  const [showCalendar, setShowCalendar] = useState<boolean>(false)

  const refreshPosts = async (newStatus?: TStatus) => {
    setLoading(true)
    setStatus(newStatus)

    const allPostsData = (await PostV2.getPosts<any>())?.results ?? []

    let filteredPosts = []

    //@refactoring - SISTEMARE, SEMBRA CHE I FITRI VENGANO GESTITI IN ITALIANO
    let availableStatus = ['planned', 'published', 'notPublished', 'error', 'publishing']
    switch (newStatus) {
      case T.status.publishing:
        availableStatus = ['publishing']
        break
      case T.status.planned:
        availableStatus = ['planned']
        break
      case T.status.published:
        availableStatus = ['published']
        break
      case T.status.notPublished:
        availableStatus = ['error', 'notPublished']
        break
      default:
        availableStatus = ['planned', 'published', 'notPublished', 'error', 'publishing']
        break
    }

    filteredPosts = allPostsData.filter((post) => availableStatus.includes(post.data.status))

    setPosts(filteredPosts)
    setLoading(false)
  }

  const firstLoad = async () => {
    setLoading(true)
    const check = await AppStore.checkPermission('calendar_show_posts')
    check && refreshPosts('Tutti')
    setShowCalendar(check)
    setLoading(false)
  }

  useEffect(() => {
    firstLoad()
  }, [])

  useEffect(() => {
    if (goToPost) {
      navigate(`/post/preview/basic`)
      useMenuNavigationStore.getState().setLocationPage('post')
    }
  }, [goToPost])

  useEffect(() => {
    if (posts.length > 0) {
      Tracker.trackEvent(TRACKEREVENTS.CALENDAR_PAGE_VIEWED, getCalendarData(posts))
    }
  }, [posts])

  if (!showCalendar) {
    return (
      <Page title={T.indexPage.index}>
        {loading && <AnimatedPlaceholderLogo />}

        {!loading && (
          <Box flex center>
            <img src="/images/coming_soon.png" alt="logo" style={{ width: 200 }} />
            <Text weight={700} fontSize={21}>
              {T.error.notHavePermiseToGetRecord}
            </Text>
          </Box>
        )}
      </Page>
    )
  }

  return (
    <Page title={T.calendarPage.calendario}>
      <Box flex row>
        <Box flex gap={32}>
          {/* ------------------------ TOP HEADER  */}
          <CalendarHeader
            refreshPosts={refreshPosts}
            socialFilters={socialFilters}
            setSocialFilters={setSocialFilters}
            goToPost={setGoToPost}
          />

          {/* ------------------------ CALENDAR  || LOADER */}
          <Box row flex pr={24}>
            <Box flex overflow={'auto'} mr={24}>
              {/* ------------------------ CALENDAR */}
              {!loading && (
                <Calendar
                  year={year}
                  month={month}
                  setMonth={(m) => setMonth(m)}
                  setYear={(y) => setYear(y)}
                  months={months}
                  posts={posts}
                  socialFilters={socialFilters}
                  refreshPost={() => refreshPosts(status)}
                  goToPost={setGoToPost}
                />
              )}

              {/* ------------------------ LOADER  */}
              {loading && <AnimatedPlaceholderLogo containerStyle={{ backgroundColor: 'transparent' }} />}
            </Box>
          </Box>
        </Box>

        {/* ------------------------ ASIDE  */}
        <LateralCalendar
          currentMonth={month}
          currentYear={year}
          months={months}
          setMonth={(m) => setMonth(m)}
          setYear={(y) => setYear(y)}
        />
      </Box>
    </Page>
  )
}
