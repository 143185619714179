import { getMessaging, onMessage } from 'firebase/messaging'
import { useEffect } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { getWorkspaceOwnerData } from 'utils/Constants/LimitUserConstants'
import { requestPermission } from 'utils/firebase/cloud_messaging'

const messaging = getMessaging()

const onMessageListener = (callback: (payload: any) => void) =>
  onMessage(messaging, (payload) => {
    callback(payload)
  })

function PushNotification() {
  const notificationFunctionManager = (_id: string, data: any) => {
    const { updateNotifications, setUpdatedDashboard } = useNotifyStore.getState()

    switch (_id) {
      case 'NOTIFICATION_UPDATE':
        updateNotifications()
        break
      case 'RESOURCE_UPDATE':
        getWorkspaceOwnerData({ setUpdatedDashboard })
        break
      default:
        break
    }
  }

  const notify = (payload: any) => {
    const id = payload?.data?._id
    //se la notifica contiene un id equivale che è una notifica che chiede al front di chiamare un chiamata backend
    if (id) {
      notificationFunctionManager(id, payload.data)
    }
    // Il payload contiene la notifica quando l'app è in background
    // showInfo(payload?.notification?.title)
  }

  useEffect(() => {
    requestPermission()
    onMessageListener(notify)
  }, [])

  return <></>
}
export default PushNotification
