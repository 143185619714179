import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Graph } from '../../Graph/Graph'
import { GraphType, IReportConfig, IReportDataComplete } from 'types/AnalyticsInferfaces'
import Utils from 'api/Utils'
import ResponsiveGridLayout from 'react-grid-layout'
import { positionGridItem, saveLayout } from 'utils/AnalyticsUtils'
import RenderReportName from './RenderReportName'
import { AppStore, showError, T } from 'utils'
import { LogoAstersFullDynamicTheme } from 'components/LogoAstersFullDynamicTheme'
import { useEffect, useState } from 'react'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { ToolTip } from 'pages/settings/Subscription/SubscriptionProvider'
import { darkTheme, lightTheme } from 'config/themes'

interface SelectedDates {
  startDate: string | null
  endDate: string | null
}

interface Props {
  report: IReportConfig
  selectedDates?: SelectedDates | null
}

const RenderReport = ({ report, selectedDates }: Props) => {
  const [scale, setScale] = useState<number>(window.innerWidth / 1920)
  const { setReportUpdateDate, reportPreviewName } = useAnalyticsStore.getState()

  useEffect(() => {
    setReportUpdateDate(report.info.updatedAt)
    addEventListener('resize', () => {
      setScale(window.innerWidth / 1920)
    })
  }, [])

  return (
    <PreviewReportContainer
      className="asters-report-container"
      style={{
        backgroundColor: AppStore.darkTheme ? darkTheme.o.lightestGrey : lightTheme.o.lightestGrey,
      }}
    >
      {/* ______________________________ HEADER */}
      <ReportHeader>
        {/* __________________ LOGO */}
        <ReportLogoContainer>
          {report.info.reportLogo ? (
            <img src={report.info.reportLogo} alt="your_logo" />
          ) : (
            <LogoAstersFullDynamicTheme logoHeight={64} logoWidth={146} />
          )}
        </ReportLogoContainer>
        {/* __________________ REPORT NAME */}
        <RenderReportName />
      </ReportHeader>
      {/* ______________________________ Drag&Drop GRAPH */}
      {
        <ResponsiveGridLayout
          className="layout"
          style={{ position: 'relative', alignItems: 'start' }}
          useCSSTransforms={false}
          rowHeight={428}
          cols={2}
          width={1117}
          onDragStop={(e) => saveLayout(e)}
          isDraggable={true}
          isResizable={true}
          resizeHandles={['e', 'se', 's']}
          draggableHandle=".drag-handle"
          droppingItem={{
            i: 'n',
            w: 2,
            h: 2,
          }}
          isBounded={true}
          transformScale={scale}
          margin={[24, 32]}
        >
          {report.data.map((g: any, i: number) => {
            const position = g.x && g.y && g.width
            const tooltipData: ToolTip = {
              text: g.metricTooltipDescription,
            }

            return (
              <div
                className="grid-item"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: g.metricGraph === 'heatmap' ? 1108 : 542,
                }}
                key={g.metricRef}
                data-grid={
                  position
                    ? {
                        x: parseFloat(g.x),
                        y: parseFloat(g.y),
                        w: parseFloat(g.width),
                        h: 1,
                      }
                    : positionGridItem(i, g.metricGraph === 'heatmap' ? 2 : 1)
                }
              >
                <Graph
                  socialProfile={g.socialProfile}
                  key={Utils.generateRandomUIDKey()}
                  type={g.metricGraph as GraphType}
                  title={g.metricName}
                  socialPicture={g.socialProfile.picture}
                  datasets={g.metricData}
                  numData={g.metricGraph === 'text' ? g.metricData[0].data : undefined}
                  labelText={g.metricGraph === 'text' ? g.metricData[0].label : undefined}
                  headerData={g.metricGraph !== 'text' ? g.metricData[0].headerGraphData : undefined}
                  tooltipLabels={g.metricData[0].config?.tooltip.labels ?? ['', '']}
                  tooltipInfo={tooltipData}
                />
              </div>
            )
          })}
        </ResponsiveGridLayout>
      }

      {selectedDates && selectedDates.startDate && selectedDates.endDate ? (
        <SelectedDate>{`${T.analytics.reportDate}: ${selectedDates?.startDate} - ${selectedDates?.endDate}`}</SelectedDate>
      ) : null}
    </PreviewReportContainer>
  )
}

export default RenderReport

const PreviewReportContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: unset !important;
  width: 1117px;

  .react-grid-layout {
    position: relative !important;
  }
`

const ReportHeader = styled(Box)`
  width: 100%;
  max-height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  padding-inline: 24px;
  margin-bottom: 48px;
`

const ReportLogoContainer = styled(Box)`
  flex: 1;
  justify-content: flex-start;

  img {
    width: 200%;
    max-width: 40%;
    max-height: 150px;
    object-fit: contain;
    border-radius: 14px;
  }

  //GIVING COLOR TO LOGO
  & svg {
    g {
      path {
        // JUST FOR LETTERS
        &:nth-child(-n + 6) {
          fill: ${AppStore.theme.o.black};
        }
      }
    }
  }
`

const SelectedDate = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${() => AppStore.theme.o.darkGrey};
  text-align: center;
`
