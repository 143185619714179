/* ------------------- IMPORTS */
import Utils, { SocialType } from 'api/Utils'
import { GridContainer, SocialCardName } from 'components/UI'
import { SocialAccountsAddable } from 'pages/create-post/Components/SocialAccountsAddable'
import { useCallback } from 'react'
import { SocialAccount } from 'types'
import { checkIsAccountInPosts, onClickSocialCard } from 'utils/PostUtils/SocialPost'

/* ------------------- INTERFACES  */
interface Props {
  provider: SocialType
  accounts: SocialAccount[]
}

export const SocialGrid = ({ provider, accounts }: Props) => {

  const cbOnClickSocialCard = useCallback((account: SocialAccount) => {
    onClickSocialCard(account)
  }, [])

  return (
    <>
      <SocialCardName provider={provider} margin={'16px 0 24px'} />

      <GridContainer
        cols={3}
        data={
          accounts.map((account) => (
            <SocialAccountsAddable
              fullWidth={true}
              isTokenExpired={account.expired}
              account={account}
              key={Utils.generateRandomUIDKey()}
              onClick={() => cbOnClickSocialCard(account)}
              isSelected={checkIsAccountInPosts(account._id!)}
              noCheckBox={account.expired}
            />
          ))}
      />
    </>
  )
}
