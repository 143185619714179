/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons, Text } from 'components'
import { Service, SubscriptionOption } from './SubscriptionProvider'
import { AppStore, T } from 'utils'
import { useState } from 'react'
import { InfoServiceToolTip } from './InfoServiceToolTip'

/* ------------------- TYPES  */

/* ------------------- INTERFACES  */

interface Props {
  service: Service
  selectedPlan: SubscriptionOption
}

export const ServiceElement = ({ service }: Props) => {
  /* ----------------- STATE  */
  const [isInfoOpen, setIsInfoOpen] = useState(false)

  /* ----------------- METHODS  */
  const handleInfoOpen = () => {
    setIsInfoOpen(!isInfoOpen)
  }

  const getSubLabel = (): string => {
    let subLabel = ''
    if (service.quantity) {
      if (service.quantity === -1) subLabel += T.settings.unlimited
      else subLabel += `${service.quantity.toLocaleString().replace(',', '.')} `
    }
    if (service.subLabelText) subLabel += `${service.subLabelText}`
    if (service.comingSoon) subLabel += ' (Coming Soon)'
    return subLabel
  }

  return (
    <ServiceElementContainer key={service.label}>
      <ServiceTextWrapper flex>
        <Box row>
          <ServiceLabel
            style={
              service.disabled
                ? {
                    color: 'gray',
                  }
                : service.serviceType
                ? {
                    fontStyle: 'italic',
                  }
                : undefined
            }
          >
            {service.serviceType === 'base'
              ? `${service.label} ${T.plans.base}`
              : service.serviceType === 'complete'
              ? `${service.label} ${T.plans.complete}`
              : service.label}
          </ServiceLabel>

          {service.tooltip && <InfoServiceToolTip tooltip={service.tooltip} />}
        </Box>
        <ServiceSubLabel height={24}>{getSubLabel()}</ServiceSubLabel>
      </ServiceTextWrapper>

      <Box width={16} height={24} center>
        {!service.disabled ? <Icons.roundedCheckmark /> : <Icons.closeIcon fill={AppStore.theme.o.surface} />}
      </Box>
    </ServiceElementContainer>
  )
}

const ServiceElementContainer = styled(Box)`
  flex-direction: row;
`

const ServiceTextWrapper = styled(Box)`
  flex-direction: column;
`

const ServiceLabel = styled(Box)`
  font-size: 12px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black};
`

const ServiceSubLabel = styled(Box)`
  font-size: 10px;
  color: ${() => AppStore.theme.o.darkGrey};
`
