import styled from '@emotion/styled'
import { Input } from 'antd'
import { Box, Icons } from 'components'
import { Component } from 'react'
import { AppStore } from 'utils'
import PinGsap from './PinGsap'

interface Props {
  createdMention: any
  updateMention: (data: any) => void
  setBoxActive: (data: boolean) => void
  boxActive: boolean
  activeIdSetter: string
  setIfNotOpenBox: (data: boolean) => void
  deleteMention: (data: any) => void
}

interface State {
  hoverTagOption: number
  isHovered: number
  modifyingName: string
}

export default class PinedComponents extends Component<Props, State> {
  state: State = {
    hoverTagOption: -1,
    isHovered: -1,
    modifyingName: '',
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevState.hoverTagOption !== this.state.hoverTagOption) {
      this.props.setIfNotOpenBox(this.state.hoverTagOption !== -1)
      this.closeOpenPinedBox(prevState.hoverTagOption)
    }
  }

  closeOpenPinedBox = async (index: number) => {
    PinGsap.closeOpenPinedBox(index)
  }

  openPinBox = (index: any) => {
    PinGsap.openPinBox(index)
  }

  toggleHover(value: number) {
    this.setState({ isHovered: value })
  }

  escapePressed = () => {
    this.props.setBoxActive(false)
  }

  render() {
    const { boxActive, updateMention, activeIdSetter } = this.props

    const activeMention = this.props.createdMention.filter((item) => item._id === this.props.activeIdSetter)

    return (
      <>
        {boxActive && <Box id={'modalIsNotClosable'}></Box>}
        {activeMention.map((item, index) => (
          <Box
            id={`pinedBox-${index}`}
            absolute
            borderRadius={16}
            border={`1px dashed ${AppStore.theme.o.darkGrey}`}
            relative
            style={{
              zIndex: 1200,
              left: item.cordinates.x * 800 - 26,
              top: item.cordinates.y * 800 - 13,
              backgroundColor: AppStore.theme.o.surface,
              width: 52,
              overflow: 'hidden',
            }}
            onClick={(e) => {
              if (this.state.hoverTagOption === index) {
                this.closeOpenPinedBox(index)
                this.setState({ hoverTagOption: -1, isHovered: -1, modifyingName: '' })
              } else {
                this.openPinBox(index)
                this.setState({ hoverTagOption: index, modifyingName: item.name })
              }
            }}
            onHoverStart={() => {
              PinGsap.iconUser(index, true)
              this.toggleHover(index)
            }}
            onHoverEnd={() => {
              PinGsap.iconUser(index, false)
            }}
            pv={8}
            row
            vcenter
          >
            <Box
              id={`iconContainer-${index}`}
              center
              width={52}
              height={36}
              style={{ backgroundColor: AppStore.theme.o.surface }}
            >
              {this.state.isHovered === index && (
                <Icons.smallEdit id={`iconUser-${index}`} fill={AppStore.theme.o.black} />
              )}
              {this.state.isHovered !== index && <Icons.user id={`iconUser-${index}`} fill={AppStore.theme.o.black} />}
            </Box>
            <Box
              relative
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <Box
                absolute
                vcenter
                style={{
                  backgroundColor: AppStore.theme.o.surface,
                  minWidth: 300,
                  minHeight: 36,
                  zIndex: 100,
                  y: -20,
                  x: 0,
                }}
                onClick={(e) => {
                  this.escapePressed()
                }}
              >
                <Input
                  id={`modifyTextFild-${index}`}
                  value={this.state.modifyingName}
                  placeholder="Modifica il nome"
                  maxLength={31}
                  style={{ width: '34ch', backgroundColor: AppStore.theme.o.surface }}
                  onChange={(e) => {
                    const { value } = e.target
                    const { modifyingName } = this.state
                    if (item.name !== value) {
                      PinGsap.confirmButton(index, true)
                    } else {
                      PinGsap.confirmButton(index, false)
                    }

                    this.setState({ modifyingName: value })
                  }}
                />

                {/*------ ------ ------ ------ confirm button ------ ------ ------ ------*/}
                <BoxOptions
                  id={`confirmButton-${index}`}
                  absolute
                  style={{
                    right: -28,
                    top: 3,
                    border: `1px solid ${AppStore.theme.o.green}`,
                    backgroundColor: AppStore.theme.o.lightGreen,
                    opacity: 0,
                    zIndex: -1,
                  }}
                  center
                  onClick={async (e) => {
                    e.stopPropagation()
                    const { modifyingName } = this.state
                    const { createdMention } = this.props
                    const allTags = createdMention
                    allTags[index].name = modifyingName
                    this.closeOpenPinedBox(index)

                    await updateMention(allTags)
                    this.setState({ hoverTagOption: -1, modifyingName: '', isHovered: -1 })
                  }}
                >
                  <Icons.tagCreate fill={AppStore.theme.o.green} />
                </BoxOptions>
                {/* ------- ------- ------- ------- ------- ------- ------- ------- ------- */}

                {/* Close icon ------- ------- ------- ------- ------- ------- ------- */}
                <BoxOptions
                  onClick={(e) => {
                    if (this.state.hoverTagOption === index) {
                      this.closeOpenPinedBox(index)
                      this.setState({ hoverTagOption: -1, isHovered: -1 })
                    } else {
                      this.openPinBox(index)
                      this.setState({ hoverTagOption: index })
                    }
                  }}
                  id={'closeIcon'}
                  absolute
                  center
                  style={{ right: -70, top: 3, backgroundColor: AppStore.theme.o.surface }}
                >
                  <Icons.tagEditDelete fill={AppStore.theme.o.darkGrey} />
                </BoxOptions>
                {/* ------- ------- ------- ------- ------- ------- ------- ------- ------- */}

                {/* remove tag ------- ------- ------- ------- ------- ------- ------- */}
                <BoxOptions
                  id={`closeIcon-${index}`}
                  absolute
                  style={{
                    right: -28,
                    top: 3,
                    opacity: 1,
                    zIndex: 1,
                  }}
                  onClick={async (e) => {
                    e.stopPropagation()
                    await this.closeOpenPinedBox(index)
                    await this.setState({ hoverTagOption: -1, modifyingName: '', isHovered: -1 })
                    this.props.setIfNotOpenBox(false)
                    const { createdMention } = this.props
                    const removeIndex = createdMention.indexOf(item)
                    createdMention.splice(removeIndex, 1)
                    this.props.deleteMention(createdMention)
                  }}
                  center
                >
                  <Icons.tagDelete fill={AppStore.theme.o.red} />
                </BoxOptions>
                {/* ------- ------- ------- ------- ------- ------- ------- ------- ------- */}
              </Box>
            </Box>
          </Box>
        ))}
      </>
    )
  }
}

const BoxOptions = styled(Box)`
  position: absolute;
  width: 36px;
  height: 36px;
  right: -40px;

  background-color: ${AppStore.theme.o.surface};
`
