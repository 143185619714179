import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { LottieSadSearch } from 'components/CreatePost/MediaComponents/LottieSadSearch'
import TemplateView from 'components/CreatePost/TextComponents/Template/TemplateView'
import { useState } from 'react'
import { useAppStore } from 'stores/AppStore'
import { T } from 'utils'
import { HearderTemplate } from './HeaderTemplate'

interface Props {
  variantId?: string // ha variantId quando siamo in modal
}

export const Template = ({ variantId }: Props) => {
  // Zustand
  const { activeWorkspace } = useAppStore((s) => ({
    activeWorkspace: s.activeWorkspace,
  }))

  // Variables
  const workspacesTemplates = activeWorkspace?.hashtags

  // State
  const [filter, setFilter] = useState(T.postPage.all)

  const templates = workspacesTemplates?.filter((item) => {
    if (filter === T.postPage.all) return true
    return item.type === filter
  })

  return (
    <Container style={variantId ? { maxHeight: 700, overflowY: 'auto' } : { minHeight: 'none', overflowY: 'initial' }}>
      <HearderTemplate setFilter={setFilter} />

      <Box style={{ minHeight: 420 }}>
        {templates?.map((item, index) => (
          <TemplateView index={index} item={item} variantId={variantId} />
        ))}

        {templates?.length === 0 && <LottieSadSearch />}
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  width: 100%;
  padding: 24px;
`
