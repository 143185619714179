import { AppStore, T, showError, showSuccess } from 'utils'
import Comments from 'api/Comments'
import Boards from 'api/Boards'
import { IArticle, IArticleShort, IBoard, IComments, IReply } from './aiDiscoverInterface'
import asters_placeholder from 'assets/asters_placeholder.png'
import Article from 'api/Article'
import AiDiscover from 'api/AiDiscover'
import Topic from 'api/Topic'

/* ___________________ ENUM ___________________ */
const enumPrivateOrShared = {
  all: '',
  private: T.AiDiscoverPage.word.pluralPrivate,
  shared: T.AiDiscoverPage.word.pluralShared,
}

const enumPrivateOrSharedSingular = {
  all: '',
  private: T.AiDiscoverPage.word.private,
  shared: T.AiDiscoverPage.word.shared,
}

const langDir = T.postPage.language

const enumLanguage = {
  bul: langDir.BG,
  ces: langDir.CS,
  dan: langDir.DA,
  deu: langDir.DE,
  ell: langDir.EL,
  eng: langDir.EN,
  spa: langDir.ES,
  est: langDir.ET,
  fin: langDir.FI,
  fra: langDir.FR,
  hun: langDir.HU,
  ind: langDir.ID,
  ita: langDir.IT,
  jpn: langDir.JA,
  lit: langDir.LT,
  lav: langDir.LV,
  nld: langDir.NL,
  pol: langDir.PL,
  por: langDir.PT,
  ron: langDir.RO,
  rus: langDir.RU,
  slk: langDir.SK,
  slv: langDir.SL,
  swe: langDir.SV,
  tur: langDir.TR,
  ukr: langDir.UK,
  zho: langDir.ZH,
}

const enumForTranslateLanguage = {
  bul: 'BG',
  ces: 'CS',
  dan: 'DA',
  deu: 'DE',
  ell: 'EL',
  eng: 'EN',
  spa: 'ES',
  est: 'ET',
  fin: 'FI',
  fra: 'FR',
  hun: 'HU',
  ind: 'ID',
  ita: 'IT',
  jpn: 'JA',
  lit: 'LT',
  lav: 'LV',
  nld: 'NL',
  pol: 'PL',
  por: 'PT',
  ron: 'RO',
  rus: 'RU',
  slk: 'SK',
  slv: 'SL',
  swe: 'SV',
  tur: 'TR',
  ukr: 'UK',
  zho: 'ZH',
}

/* ___________________ MODAL FUNCTION ___________________ */

/**
 * rimuove le parti appena selezionate dove non si va avanti nell'azione di commento per pulire il contenuto dell'articolo da tag non necessari
 *
 * @returns
 */
const removeSpanNoComment = () => {
  const allText = document.querySelector('.article-inner-content')
  if (!allText?.innerHTML && !allText?.innerHTML.includes('<span>')) return

  const modifiedString = allText.innerHTML.replace(/<\s*span\s*>([^<]*?)<\/\s*span\s*>/g, '$1')

  allText.innerHTML = modifiedString
}

const onCreateNewCommentObj = (
  articleId: string,
  articleLanguage: string,
  boardId: string,
  commentText: string,
  articleText: string
) => {
  const newCommentObj: IComments = {
    user: {
      id: AppStore?.loggedUser._id,
      email: AppStore?.loggedUser.email,
      name: AppStore?.loggedUser.name,
      image: '',
    },
    language: articleLanguage,
    articleId,
    boardId,
    comment: commentText,
    articleText,
    reply: [],
    date: new Date(),
  }

  return newCommentObj
}

const onCreateNewReplyObj = (replyText: string) => {
  const newReplyObj: IReply = {
    user: {
      id: AppStore?.loggedUser._id,
      email: AppStore?.loggedUser.email,
      name: AppStore?.loggedUser.name,
      image: '',
    },

    replyText,
    date: new Date(),
  }

  return newReplyObj
}

/* __________ API */
const getComments = async (boardId: string, articleId: string, language: string) => {
  try {
    const response = await Comments.getComments(boardId, articleId, language)

    return response
  } catch (e) {
    return []
  }
}

const postCreateComment = async (comment: IComments, article: IArticle) => {
  try {
    const response = await Comments.createComment(comment, article)

    return response
  } catch (e) {
    return undefined
  }
}

const putUpdateComment = async (boardId: string, comment: string, commentId: string) => {
  try {
    const response = await Comments.updateComment(boardId, comment, commentId)
  } catch (e) {}
}

const deleteComment = async (commentId: string, boardId: string, articleId: string) => {
  try {
    const response = await Comments.deleteComment(commentId, boardId, articleId)

    return response.acknowledged
  } catch (e) {}
}

const putCreateReply = async (reply: IReply, commentId: string, boardId: string) => {
  try {
    const response = await Comments.createAnswer(reply, commentId, boardId)

    return response
  } catch (e) {}
}

const postUpdateAnswer = async (text: string, commentId: string, answerId: string, boardId: string) => {
  try {
    const response = await Comments.updateAnswer(text, commentId, answerId, boardId)
  } catch (e) {}
}

const deleteAnswer = async (commentId: string, answerId: string, boardId: string) => {
  try {
    const response = await Comments.deleteAnswer(commentId, answerId, boardId)
  } catch (e) {}
}

/* ___________________ BOARD FUNCTION ___________________ */

/**
 * creazione di un nuovo oggetto board
 *
 * @param boardTitle stringa che rappresenta il titolo della board
 * @param isPrivate boolean che rappresenta se la board è privata o meno
 * @param articles array di articoli che rappresenta gli articoli che sono stati salvati nella board
 * @returns l'oggetto IBoard
 */
const createBoardOBJ = (boardTitle: string, isPrivate: boolean, articles: IArticleShort[] | [] = []) => {
  const articleOnBoard = articles[0] !== undefined ? articles : []
  const newBoardOBJ: IBoard = {
    private: isPrivate,
    workspaceId: AppStore?.discoverUser?.workspaceId ?? '',
    boardTitle,
    articles: articleOnBoard,
    date: new Date(),
    user: {
      id: AppStore?.loggedUser._id,
      email: AppStore?.loggedUser.email,
      name: AppStore?.loggedUser.name,
      image: '',
    },
  }

  return newBoardOBJ
}

const updateBoard = (boardTitle: string, isPrivate: boolean, articles: IArticleShort[] | [] = []) => {
  const newBoardOBJ: IBoard = {
    private: isPrivate,
    workspaceId: AppStore?.discoverUser?.workspaceId ?? '',
    boardTitle,
    articles,
    date: new Date(),
    user: {
      id: AppStore?.loggedUser._id,
      email: AppStore?.loggedUser.email,
      name: AppStore?.loggedUser.name,
      image: '',
    },
  }

  return newBoardOBJ
}

// Prende come argomento gli ultimi 3 articoli (perchè sono i più recenti) di una board
const previewImages = (articles) => {
  const newArticles = articles.reverse()

  const images: any[] = []

  for (let i = 0; i < 3; i++) {
    if (newArticles[i]) {
      images.push(
        <div key={newArticles + i}>
          <img
            src={newArticles[i].image ?? asters_placeholder}
            style={{ backgroundColor: `${AppStore.theme.o.lightGrey}` }}
          />
        </div>
      )
    } else {
      //Se articles non arriva a 3 articoli aggiunge dei contenitori vuoti
      images.push(<div className="gray_preview_img" key={newArticles + i}></div>)
    }
  }

  return images
}

/* __________ API */

/**
 *
 */

const getBoardsList = async (forceUpdate?: any) => {
  try {
    const response = await Boards.getBoardList()

    AppStore.discoverUserBoardList = response
    forceUpdate && forceUpdate()
    return response as IBoard[]
  } catch (e: any) {
    if (e.message === 'Forbidden') return false

    return false
  }
}

const deleteBoard = async (boardId: string | string[], forceUpdate?) => {
  try {
    const response = await Boards.deleteBoard(boardId)
    const newBoardList = await getBoardsList(forceUpdate)
    showSuccess(T.aiDiscoverPage.sidebar.deleteBoardsModal)
  } catch (e) {
    showError(T.aiDiscoverPage.sidebar.unfollowModalProblem)
  }
}

const getBoard = async (boardId: string) => {
  try {
    const response = await Boards.getBoard(boardId)
    return response
  } catch (e) {}
}

/**
 * ### Creazione nuova board
 *
 * si crea una nuova board e si pusha l'id della nuova board nell'array boardsId dell'utente tramite l'AppStore
 *
 * @param board è l'oggetto board da creare
 */
const postCreateBoard = async (board: IBoard, article?: IArticle) => {
  try {
    const response = await Boards.createBoard(board, article)

    if (AppStore.discoverUser.boardsId) AppStore.discoverUser.boardsId.push(response._id)
  } catch (e) {
    showError(T.AiDiscoverPage.boardCardError.createBoard)
  }
}

const truncateText = (text: string, maxLength: number) => {
  if (!text) return
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...'
  } else {
    return text
  }
}

const putUpdateBoard = async (boardId: IBoard) => {
  try {
    const response = await Boards.updateBoard(boardId)
  } catch (e) {}
}

const postChangeArticleInANewBoard = async (boardId: string, article: IArticleShort, newBoardId: string) => {
  try {
    const response = await Boards.changeArticleInANewBoard(boardId, article, newBoardId)
  } catch (e) {}
}

const postSaveArticleInBoard = async (boardId: string, article?: IArticle) => {
  try {
    const response = await Boards.saveArticleInBoard(boardId, article)
    return true
  } catch (e) {
    const boardName = AppStore.discoverUserBoardList.find((board) => board._id === boardId)?.boardTitle
    showError(`${T.AiDiscoverPage.boardCardError.saveArticle}: "${boardName}"`) //
    return false
  }
}

const postRemoveArticle = async (boardId: string, articleId: string | string[]) => {
  try {
    const response = await Boards.removeArticleInBoard(boardId, articleId)
    getBoardsList()
    showSuccess(T.aiDiscoverPage.sidebar.deleteArticle)
    return response
  } catch (e) {
    showError(T.aiDiscoverPage.sidebar.deleteArticleProblem)
  }
}

/* ___________________ TOPIC FUNCTION ___________________ */

/* __________ API */

// Aggiunge topic nei seguiti dall'user (da passare inizialmente "_id" ritornato dal cerca, poi quel "_id" diventerà l'id del topic dentro user, quindi da utilizzare "id" per future modifiche al topic)
const putUpdateAiDiscoverTopic = async (topicId: string, onForceUpdate?) => {
  try {
    const response = await AiDiscover.putUpdateAiDiscoverTopic(topicId)

    AppStore.discoverUser = response

    onForceUpdate && onForceUpdate()
  } catch (e) {}
}

// Rimuove topic nei seguiti dall'user (da passare "_id" quando si è dentro la searchbar, perchè ancora si ha l'_id del cerca, in ogni altra situazione utilizzare "id" del topic)
const removeAiDiscoverTopic = async (topicId: string | string[], onForceUpdate?) => {
  try {
    const response = await AiDiscover.removeAiDiscoverTopic(topicId)

    AppStore.discoverUser = response
    onForceUpdate && onForceUpdate()
    showSuccess(T.aiDiscoverPage.sidebar.deleteTopicModal)
  } catch (e) {
    showError(T.aiDiscoverPage.sidebar.deleteTopicProblem)
  }
}

// Mostra tutti i topic nel DB
const getAllTopic = async () => {
  try {
    const response = await Topic.getAllTopic()
  } catch (e) {}
}

// Topic searchbar suggested
const getFilteredTopics = async (language: string, title: string, setterFunc?) => {
  try {
    const response = await Topic.getFilteredTopics(language, title)

    setterFunc && setterFunc(response)
  } catch (e) {}
}

// Crea topic nel DB dopo averlo cercato // se esiste già torna comunque dati del topic già esistente
const createTopic = async (title: string, language: string, setSelectedTopic?) => {
  try {
    const response = await Topic.create(title, language)

    setSelectedTopic && setSelectedTopic(response)
  } catch (e) {}
}

// Prende topic seguiti dall'utente
const getUserTopics = async () => {
  try {
    const response = await Topic.getUserTopics()
  } catch (e) {}
}

/* ___________________ ARTICLE FUNCTION ___________________ */

/* __________ API */
/**
 *  Serve per ottenere gli articoli in trend per carosello
 *
 * @param forceUpdate funzione per forzare cambio di stato
 * @param language lingua dei trend
 * @param pageNumber pagina da ingrementare nel caso si volessero nuovi risutlati, di default torna la pagina n° 1
 * @param articleNumber numero di articoli tornati
 * @param startDate data di inizio per filtro risultati es di data:'2023-09-01'
 * @param endDate data di fine per filtro risultati
 */
const getFeedTrendArticles = async (
  forceUpdate?: any,
  language?: string,
  pageNumber?,
  articleNumber = 24,
  startDate?: string,
  endDate?: string
) => {
  try {
    const response = await AiDiscover.getTrendingArticles(
      language ?? 'ita',
      articleNumber,
      pageNumber,
      startDate,
      endDate
    )

    AppStore.discoverTrendArticleList = response
    forceUpdate && forceUpdate()
  } catch (e) {}
}

/**
 *  Serve per ottenere gli articoli in trend dei topic seguiti
 *
 * @param pageNumber pagina da ingrementare nel caso si volessero nuovi risutlati, di default torna la pagina n° 1
 * @param articleNumber numero di articoli tornati
 * @param startDate data di inizio per filtro risultati es di data:'2023-09-01'
 * @param endDate data di fine per filtro risultati
 * @param language lingua dei trend
 */
const getArticlebyTopicList = async (
  pageNumber?: number,
  articleNumber?: number,
  startDate?: string,
  endDate?: string,
  language?: string
) => {
  try {
    const response: IArticle[] = await AiDiscover.getArticlebyTopicList(
      pageNumber,
      articleNumber,
      startDate,
      endDate,
      language
    )
    return response
  } catch (e) {
    return []
  }
}

/**
 *  Serve per ottenere gli articoli in base alla parola cercata (topic)
 *
 * @param keyword parola cercata (topic)
 * @param language lingua dei trend
 * @param pageNumber pagina da ingrementare nel caso si volessero nuovi risutlati, di default torna la pagina n° 1
 * @param articleNumber numero di articoli tornati
 * @param startDate data di inizio per filtro risultati es di data:'2023-09-01'
 * @param endDate data di fine per filtro risultati
 */
const getArticlebyKeyword = async (
  keyword: string,
  language: string,
  pageNumber?: number,
  articleNumber?: number,
  startDate?: string,
  endDate?: string,
  setSearchedContent?: (newData: IArticle[]) => void
) => {
  try {
    const response: IArticle[] = await AiDiscover.getArticlebyKeyword(
      keyword,
      language,
      pageNumber,
      articleNumber,
      startDate,
      endDate
    )

    setSearchedContent && setSearchedContent(response)
    return response
  } catch (e) {}
}

const getArticle = async (articleId: string) => {
  try {
    const response = await AiDiscover.getArticle(articleId)

    return response
  } catch (e) {
    return undefined
  }
}

const postCreateArticle = async (article: IArticle) => {
  try {
    const response = await Article.createArticle(article)

    return response
  } catch (e) {
    return e
  }
}

const getBoardArticleList = async (boardId: string) => {
  try {
    const response = await Article.getBoardArticles(boardId)

    return response
  } catch (e) {}
}

/* ___________________ GENERAL FUNCTION ___________________ */
/**
 *
 * @param timestamp es: 1690552236000
 * @returns es: July 06, 2023 at 10:19AM
 */
const dateToString = (timestamp: number | string | Date, index?: number) => {
  const initialDate = new Date(timestamp)
  const dateToConvert = new Date(timestamp)

  const month = dateToConvert.toLocaleString('en-US', { month: 'long' })

  let day = String(dateToConvert.getDate()).padStart(2, '0')

  const year = dateToConvert.getFullYear()

  const formatTiming = dateToConvert.toLocaleString('en-US', { hour12: true }).slice(-2)

  let hours = String(dateToConvert.getHours()).padStart(2, '0')

  let min = String(dateToConvert.getMinutes()).padStart(2, '0')


  if (typeof timestamp === 'string') {
    const splitTimestamp = timestamp.split(' ')[0].split('T')
    day = splitTimestamp[0].split('-')[2]
    hours = splitTimestamp[1].split(':')[0]
    min = splitTimestamp[1].split(':')[1]
  }

  return `${month} ${day}, ${year} at ${hours}:${min}${formatTiming}`
}
export const simpleFormatDate = (date, format = 'YYYY-MM-DD') => {
  return date ? date.format(format) : undefined
}
/**
 * Serve per vedere se l'immagine va in errore nel caricamento e la cambia con un placeholder
 * @param e è l'evento
 */
const onLoadImageError = (e) => {
  e.currentTarget.src = asters_placeholder
}

// Funzione per decodificare entità HTML
function decodeHTML(html) {
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export {
  // ENUM
  enumPrivateOrShared,
  enumPrivateOrSharedSingular,
  enumLanguage,
  enumForTranslateLanguage,

  // MODAL FUNCTION
  removeSpanNoComment,
  onCreateNewCommentObj,
  onCreateNewReplyObj,

  // modal function API
  getComments,
  postCreateComment,
  putUpdateComment,
  deleteComment,
  putCreateReply,
  postUpdateAnswer,
  deleteAnswer,
  getArticle,

  // GENERAL FUNCTION
  dateToString,
  onLoadImageError,
  truncateText,
  decodeHTML,

  // BOARD FUNCTION
  createBoardOBJ,
  updateBoard,
  previewImages,

  // board function API
  getBoardsList,
  postCreateBoard,
  putUpdateBoard,
  postChangeArticleInANewBoard,
  postSaveArticleInBoard,
  postRemoveArticle,
  deleteBoard,
  getBoard,
  getBoardArticleList,

  // TOPIC FUNCTION

  // TOPIC API
  putUpdateAiDiscoverTopic,
  removeAiDiscoverTopic,
  getAllTopic,
  getFilteredTopics,
  createTopic,
  getUserTopics,

  // ARTICLE FUNCTION

  // ARTICLE API
  postCreateArticle,
  getFeedTrendArticles,
  getArticlebyTopicList,
  getArticlebyKeyword,
}
