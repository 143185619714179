import styled from '@emotion/styled'
import { Box, Button } from 'components'
import { forwardRef, useEffect } from 'react'
import { AppStore, T, showSuccess } from 'utils'
import { IPostVariant } from 'types/PostInterface'
import { ControlInputRange } from './ControlInputRange'
import { ControlButtons } from './ControlButtons'
import { updatePost } from 'utils/PostUtils/Post'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { openMediaModal } from 'components/Modal/ModalManager'
import { Media } from 'types'
import { getSelectedMedias } from 'utils/PostUtils/MediaPost'

type Props = {
  post: IPostVariant

  ref: object
  isSeekbarDisabled?: boolean
  videoCoverTimestampMsState: number
  maxVideoCoverTimestampMs: number
  frames: string[]
 
  changeTimestamp: (value: number) => void
  setVideoCoverTimestampMsState: (newState: number) => void
  setCurrentTimeVideo: (value: number) => void
  onMediaSelected?: (selectedMedias: Media[]) => void
}

export const ControlsVideoPreview = forwardRef<HTMLCanvasElement, Props>((props, ref) => {
  const {
    post,
    videoCoverTimestampMsState,
    maxVideoCoverTimestampMs,
    changeTimestamp,
    frames,
    setVideoCoverTimestampMsState,
    setCurrentTimeVideo,
    
  } = props

  const { provider } = post.info.account
  const { variantId } = post
  const { activePost } = useCreatePostStore((s) => ({
    activePost: s.activePost,
  }))

  const { isSeekbarDisabled } = props
  const { onMediaSelected } = props

  const handleThumbnailClick = () => {
    
    openMediaModal({
      context: ['image/jpeg', 'image/png'],
      onMediaSelected:(selectedMedia) => {
       
       if (onMediaSelected) {
         onMediaSelected(selectedMedia)
       }
     },
      selectedMedias:[]
    })
  }

  return (
    <Box gap={16} width={541} center>
      {/* ----------------- TIK TOK */}
      {provider === 'tiktok' && (
        <ControlsContainer>
          <ControlInputRange
            frames={frames}
            ref={ref}
            post={post}
            videoCoverTimestampMsState={videoCoverTimestampMsState}
            maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
            setVideoCoverTimestampMsState={setVideoCoverTimestampMsState}
            setCurrentTimeVideo={setCurrentTimeVideo}
          />
          <ControlButtons
            videoCoverTimestampMsState={videoCoverTimestampMsState}
            maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
            changeTimestamp={changeTimestamp}
          />
          <Button
            variant="secondary-small"
            borderRadius={14}
            width={105}
            height={28}
            style={{ minHeight: 28 }}
            onClick={() => {
              if (!post.data?.thumbnail) {
                updatePost(variantId, { timestamp: videoCoverTimestampMsState })
                showSuccess(T.postPage.tiktokExtra.confirmedTimestamp)
              }
            }}
          >
            <ConfirmButton>{T.settings.confirm}</ConfirmButton>
          </Button>
        </ControlsContainer>
      )}
      {/* ----------------- YOUTUBE */}
      {provider === 'youtube' && (
        <ControlsContainer>
          <ControlInputRange
            frames={frames}
            ref={ref}
            post={post}
            videoCoverTimestampMsState={videoCoverTimestampMsState}
            maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
            setVideoCoverTimestampMsState={setVideoCoverTimestampMsState}
            setCurrentTimeVideo={setCurrentTimeVideo}
            disabled={isSeekbarDisabled}
           
          />
          <ControlButtons
            videoCoverTimestampMsState={videoCoverTimestampMsState}
            maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
            changeTimestamp={changeTimestamp}
          />
          <ButtonsContainer>
            <AddThumbnailButton onClick={handleThumbnailClick}>
              {T.postPage.youtubeTubeExtra.addThumbnail}
            </AddThumbnailButton>
            <Button
              variant="secondary-small"
              borderRadius={14}
              width={105}
              height={28}
              style={{ minHeight: 28 }}
              onClick={() => {
                if (!post.data?.thumbnail) {
                  updatePost(variantId, { timestamp: videoCoverTimestampMsState })
                  showSuccess(T.postPage.tiktokExtra.confirmedTimestamp)
                } else {
                  showSuccess(T.postPage.tiktokExtra.confirmedTimestamp)
                }
              }}
            >
              <ConfirmButton>{T.settings.confirm}</ConfirmButton>
            </Button>
          </ButtonsContainer>
        </ControlsContainer>
      )}
      {provider === 'instagram' && (
        <ControlsContainer>
          <ControlInputRange
            frames={frames}
            ref={ref}
            post={post}
            videoCoverTimestampMsState={videoCoverTimestampMsState}
            maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
            setVideoCoverTimestampMsState={setVideoCoverTimestampMsState}
            setCurrentTimeVideo={setCurrentTimeVideo}
            disabled={isSeekbarDisabled}
           
          />
          <ControlButtons
            videoCoverTimestampMsState={videoCoverTimestampMsState}
            maxVideoCoverTimestampMs={maxVideoCoverTimestampMs}
            changeTimestamp={changeTimestamp}
          />
          <ButtonsContainer>
            <AddThumbnailButton onClick={handleThumbnailClick}>
              {T.postPage.youtubeTubeExtra.addThumbnail}
            </AddThumbnailButton>
            <Button
              variant="secondary-small"
              borderRadius={14}
              width={105}
              height={28}
              style={{ minHeight: 28 }}
              onClick={() => {
                if (!post.data?.thumbnail) {
                  updatePost(variantId, { timestamp: videoCoverTimestampMsState })
                  showSuccess(T.postPage.tiktokExtra.confirmedTimestamp)
                } else {
                  showSuccess(T.postPage.tiktokExtra.confirmedTimestamp)
                }
              }}
            >
              <ConfirmButton>{T.settings.confirm}</ConfirmButton>
            </Button>
          </ButtonsContainer>
        </ControlsContainer>
      )}
    </Box>
  )
})

const ConfirmButton = styled.p`
  font-family: 'Aktiv Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`

const ControlsContainer = styled(Box)`
  align-items: center;
  gap: 16px;
`

const AddThumbnailButton = styled(Box)`
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  color: ${() => AppStore.theme.o.black};
  padding: 2px 24px;
  border-radius: 14px;
  height: 28px;
  cursor: pointer;
  align-items: center;

  &:hover {
    background-color: ${() => AppStore.theme.o.lightGrey};
  }
`

const ButtonsContainer = styled(Box)`
  flex-direction: row;
  gap: 16px;
`
