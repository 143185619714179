import { SearchOutlined } from '@ant-design/icons'
import Socialaccounts from 'api/Socialaccounts'
import { Tracker } from 'api/Tracker'
import Workspaces from 'api/Workspaces'
import { AddMetaAccountsModal, Box, Button, Input, Text } from 'components'
import { SocialIconsProvider } from 'components/SocialIcon'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { SocialAccount } from 'types'
import { AppStore, getSizeZoomed, showError, showInfo, showSuccess } from 'utils'
import { isVisible } from 'utils/CheckVisibleElement'
import { socialCommingSoon, socials } from 'utils/SettingPageUtils'
import { TRACKEREVENTS, getSocialPageData, getSocialProfileData } from 'utils/eventsTracker'
import { MixpanelSocialProfile } from 'utils/eventsTracker/socialProfile/socialProfileInterfaces'
import {
  getFBAccounts,
  getGoogleMyBusinessAuthUrl,
  getIGAccounts,
  getLinkedinAccounts,
  getLinkedinAuthUrl,
  getTiktokAuthUrl,
  getTiktokUser,
  getTwitterAccounts,
  getTwitterAuthUrl,
  getYoutubeAccounts,
  getYoutubeAuthUrl,
} from 'utils/socials'
import { SocialProvider } from './SocialProfile/SocialProvider'
import { SocialProviderContainer } from './SocialProviderContainer'

interface Props {}

const SocialProfileSection = ({}: Props) => {
  const [socialAccounts, setSocialAccounts] = useState<SocialAccount[]>(AppStore.socialAccounts)
  const [filter, setFilter] = useState<string>('')
  const [socialAccountFiltered, setSocialAccountFiltered] = useState<any[]>([])
  const [SelectedAccount, setSelectedAccount] = useState<any>([])
  const [code, setCode] = useState<string>('')

  /* ----------------------- METHODS */
  const refreshSocialAccounts = async () => {
    try {
      const res = await Workspaces.getSocialAccounts()
      AppStore.refreshSocialaccounts(res, false)
      setSocialAccounts(res as SocialAccount[])
    } catch (error: any) {
      showError(error?.message ?? T.error.error)
    }
  }

  const saveAccount = async (account: SocialAccount, withRefresh = true) => {
    try {
      if (!account.workspace) {
        account.workspace = AppStore.workspace
      }

      await Socialaccounts.save(account)
      if (account.provider === 'x') {
        await xOauth1Url()
      }
    } catch (error: any) {
      showError(error.message)
    }
  }

  const addAccounts = async (social: SocialIconsProvider) => {
    let accounts: SocialAccount[] = []
    switch (social.toLowerCase()) {
      case 'facebook':
        accounts = await getFBAccounts()
        break
      case 'instagram':
        accounts = await getIGAccounts()
        break
      case 'linkedin':
        return getLinkedinAuthUrl()
      case 'google':
        return getGoogleMyBusinessAuthUrl()
      case 'x':
        return getTwitterAuthUrl()
      case 'tiktok':
        return getTiktokAuthUrl()
      case 'youtube':
        return getYoutubeAuthUrl()
      default:
        break
    }

    const unicAccounts = accounts.filter(
      (acc) => !AppStore.socialAccounts.some((a) => a.account_id === acc.account_id && a.provider === acc.provider)
    )
    openAddAccountsModal(unicAccounts)
  }

  const deleteAccount = async (account: SocialAccount) => {
    try {
      await Socialaccounts.delete(account._id)
      showSuccess(T.success.profileSocialEliminated)

      const deletedAccountData: MixpanelSocialProfile = {
        social_channel: account.provider,
        social_channel_type: account.profileType ?? 'profile',
        social_profile_name: account.name,
        social_profile_url: '',
        workspace_name: AppStore.workspace.name,
      }
      Tracker.trackEvent(TRACKEREVENTS.SOCIAL_PROFILE_DELETED, getSocialProfileData(deletedAccountData))

      await refreshSocialAccounts()

      // reloadApp()
    } catch (error) {
      showError((error as any)?.message ?? T.error.unknownError)
    }
  }

  /*  ----------------------- MODALS  */
  const showQueueModal = () =>
    AppStore.openModal({
      id: 'draftModal',
      style: { minWidth: getSizeZoomed(50, 'vw') },
      body: (
        <Box height={getSizeZoomed(80, 'vh')} style={{ marginLeft: 50, marginRight: 50 }}>
          <Input
            style={{ width: 336, marginTop: 50 }}
            placeholder={T.settings.searchSocial}
            prefix={<SearchOutlined />}
            onChange={(filters) => setFilter(filters)}
          />
          <Box
            style={{
              borderStyle: 'dashed',
              borderWidth: 1,
              borderColor: AppStore.theme.o.grey,
              marginTop: 40,
              padding: 20,
              marginBottom: 20,
            }}
            flex
          >
            {socials.map((social, index) => (
              <SocialProvider
                onDeleteAccount={deleteAccount}
                social={social as any}
                onClick={() => addAccounts(social as any)}
                socialAccounts={socialAccountFiltered.filter((acc) => acc.provider === social)}
              />
            ))}
          </Box>
        </Box>
      ),
    })

  const openAddAccountsModal = async (accounts: SocialAccount[]) => {
    AppStore.openModal({
      id: 'add-facebook-modal',
      width: 'auto',
      onCancel: () => {
        refreshSocialAccounts()
      },
      bodyStyle: { marginBottom: 150 },
      body: (
        <AddMetaAccountsModal
          refreshSocials={refreshSocialAccounts}
          accountsProp={accounts}
          saveAccount={saveAccount}
        />
      ),
    })
  }

  /* ----------------------- API */
  const tiktokCallback = async () => {
    const url = new URL(window.location.href)
    const codeString = url.searchParams.get('code')
    //remove code from url and remove /linkedin from url
    if (!codeString) return showError(T.error.authFaild)
    window.history.pushState('', document.title, window.location.pathname.replace('/linkedin', ''))
    setCode(codeString)
    const tiktokAccount = await getTiktokUser(codeString)
    const accountFounded: SocialAccount[] = []
    tiktokAccount.socialaccounts.forEach((e) => {
      socialAccounts.some((a, i) => {
        if (a.account_id === e.account_id) {
          saveAccount(e, false)

          //----- ----- per levare la freccetina di refresh ----- -----
          if (AppStore.socialNearToExpire.indexOf(a.account_id) !== -1) {
            AppStore.socialNearToExpire = AppStore.socialNearToExpire.filter(function (item) {
              return item !== a.account_id
            })
          }
          //----- ----- per aggiungere account ----- -----
          showSuccess(`${T.success.accontLinkediOf} ${e.name} ${T.success.isUpdated}`)
          accountFounded.push(a)
        }
      })
    })

    if (accountFounded.length <= 0 && tiktokAccount.socialaccounts.length > 0)
      openAddAccountsModal(tiktokAccount.socialaccounts)
    else {
      await AppStore.refreshLoggedUser()
      showError(T.error.errorAccount)
    }
  }

  const linkedinCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const codeString = urlParams.get('code')

    //remove code from url and remove /linkedin from url
    if (!codeString) return showError(T.error.authFaild)
    window.history.pushState('', document.title, window.location.pathname.replace('/linkedin', ''))

    setCode(codeString)
    const linkedinAccounts = await getLinkedinAccounts(codeString)

    const accountFounded: number[] = []
    linkedinAccounts.socialaccounts.forEach((e) => {
      socialAccounts.some((a, i) => {
        if (a.account_id === e.account_id) {
          saveAccount(e, false)

          //----- ----- per levare la freccetina di refresh ----- -----
          if (AppStore.socialNearToExpire.indexOf(a.account_id) !== -1) {
            AppStore.socialNearToExpire = AppStore.socialNearToExpire.filter(function (item) {
              return item !== a.account_id
            })
            // setRefresh((prev) => !prev)
          }
          //----- ----- per aggiungere account ----- -----
          showSuccess(`${T.success.accontLinkediOf} ${e.name} ${T.success.isUpdated}`)
          accountFounded.push(i)
        }
      })
    })

    if (linkedinAccounts.socialaccounts.length > 0 && accountFounded.length === 0)
      openAddAccountsModal(linkedinAccounts.socialaccounts)
    else {
      await AppStore.refreshLoggedUser()
      window.location.reload()
    }
  }

  const twitterCallback = async () => {
    const url = new URL(window.location.href)
    const codeString = url.searchParams.get('code') ?? ''
    const codeVerifier = localStorage.getItem('state')

    if (!codeString || !codeVerifier) {
      window.history.pushState('', document.title, window.location.pathname.replace('/x', ''))
      localStorage.removeItem('state')
      return showError(T.error.authFaild)
    }

    localStorage.removeItem('state')
    setCode(codeString)
    const twitterAccounts = await getTwitterAccounts(codeString, codeVerifier)
    window.history.pushState('', document.title, window.location.pathname.replace('/x', ''))

    const accountFounded: any = []
    twitterAccounts.socialaccounts.forEach((e) => {
      socialAccounts.some((a, i) => {
        if (a.account_id === e.account_id) {
          saveAccount(e)
          showSuccess(`${T.success.accontTwiterOf} ${e.name} ${T.success.isUpdated}`)
          //----- ----- per levare la freccetina di refresh ----- -----
          if (AppStore.socialNearToExpire.indexOf(a.account_id) !== -1) {
            AppStore.socialNearToExpire = AppStore.socialNearToExpire.filter(function (item) {
              return item !== a.account_id
            })
          }
          //----- ----- per levare la freccetina di refresh ----- -----
          accountFounded.push(i)
        }
      })
    })

    if (accountFounded.length <= 0) openAddAccountsModal(twitterAccounts.socialaccounts)
    else {
      await AppStore.refreshLoggedUser()
      window.location.reload()
    }
  }

  const xOauth1Callback = async () => {
    // ------------ Constants ------------
    const url = new URL(window.location.href)
    const oauthToken = url.searchParams.get('oauth_token')
    const oauthVerifier = url.searchParams.get('oauth_verifier')
    const oauthTokenSecret = localStorage.getItem('state')

    // ------------ Check if oauthToken and oauthVerifier are present ------------
    if (oauthToken === null || oauthVerifier === null || oauthTokenSecret === null) {
      window.history.pushState('', document.title, window.location.pathname.replace('/xoauth1', ''))
      localStorage.removeItem('state')
      return showError(T.error.authFaild)
    }

    try {
      await Socialaccounts.connectOauth1(oauthToken, oauthVerifier, oauthTokenSecret)
      window.history.pushState('', document.title, window.location.pathname.replace('/xoauth1', ''))
      localStorage.removeItem('state')
      await AppStore.refreshLoggedUser()
    } catch (error) {
      showError(T.error.authFaild)
      window.history.pushState('', document.title, window.location.pathname.replace('/xoauth1', ''))
      localStorage.removeItem('state')
    }
  }

  const xOauth1Url = async () => {
    try {
      const stringUrl = await Socialaccounts.getOauth1Url()
      const codeVerifier = stringUrl.split('&code_verifier=')[1]
      const url = stringUrl.split('&code_verifier=')[0]
      if (codeVerifier !== '') localStorage.setItem('state', codeVerifier)

      window.location.href = url
    } catch (error) {
      showError(error)
    }
  }

  const youtubeCallback = async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const codeString = urlParams.get('code') ?? ''
    if (codeString === undefined) return showError(T.error.authFaild)

    const youtubeAccounts = (await getYoutubeAccounts(codeString)) as any

    const accountFounded: any = []

    if (Object.keys(youtubeAccounts).length === 0) {
      showError(T.error.errorAccount)
    }

    try {
      youtubeAccounts.forEach((e) => {
        socialAccounts.some((a, i) => {
          if (a.account_id === e.account_id) {
            saveAccount(e)
            showSuccess(`${T.success.accontYoutubeOf} ${e.name} ${T.success.isUpdated}`)
            //----- ----- remove refresh style ----- -----
            if (AppStore.socialNearToExpire.indexOf(a.account_id) !== -1) {
              AppStore.socialNearToExpire = AppStore.socialNearToExpire.filter(function (item) {
                return item !== a.account_id
              })
            }

            accountFounded.push(a)
          }
        })
      })

      if (accountFounded.length === 0) openAddAccountsModal(youtubeAccounts)
      else {
        await AppStore.refreshLoggedUser()
        window.history.pushState('', document.title, window.location.pathname.replace(/\?.*/, ''))
        window.location.reload()
      }
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * The function `checkTokens` asynchronously checks for expired social account tokens, updates the
   * filtered accounts, displays information about accounts to reconnect if needed, suggests accounts
   * to reconnect, refreshes social accounts, and refreshes the logged-in user in a TypeScript React
   * application.
   */
  const checkTokens = async () => {
    const socialAccountsExpired = await Socialaccounts.checkRefreshToken(AppStore.socialAccounts)

    const notExpired = socialAccountFiltered.map((acc) => {
      return { ...acc, expired: false }
    })
    setSocialAccountFiltered(notExpired)

    const socialToUpdate = socialAccountsExpired.length
    if (socialToUpdate > 0) {
      showInfo(`${T.settings.there_are} ${socialToUpdate} ${T.settings.accounts_to_reconnect}`)
    } else {
      showSuccess(T.settings.you_don_have_any)
    }

    const suggested: { name: string; provider: SocialAccount['provider'] }[] = []
    socialAccountsExpired.forEach((account) => {
      suggested.push({ name: account.name, provider: account.provider })
    })
    setSelectedAccount(suggested)

    await refreshSocialAccounts()
    AppStore.refreshLoggedUser()
  }

  const firstMount = async () => {
    Tracker.trackEvent(TRACKEREVENTS.SOCIAL_CHANNELS_PAGE_VIEWED, getSocialPageData(socialAccounts))

    await refreshSocialAccounts()

    const url = new URL(window.location.href)
    const subsection = url.pathname.split('/').pop()

    const provider = url.searchParams.get('provider')
    const codeString = url.search

    // ceck if code contain user.info.basic
    if (codeString.includes('user.info.basic')) return tiktokCallback()

    // if (getMatchParams(this.props.paresntProps).subsection === 'linkedin') return this.linkedinCallback()

    if (subsection === 'linkedin') return linkedinCallback()

    if ((subsection === 'x' || subsection === 'twitter') && !url.searchParams.get('oauth_token'))
      return twitterCallback()

    if (url.searchParams.get('oauth_token')) return xOauth1Callback()

    if (provider === 'youtube') return youtubeCallback()
  }

  useEffect(() => {
    firstMount()
  }, [])

  //? Al cambio dei social account (aggiunta / rimozione) aggiorna i dati dell'owner  e aggiornamento socialFilter per vedere i profili aggiornati senza refresh
  // useEffect(() => {
  //   setSocialAccountFiltered(socialAccounts.filter((acc) => acc.name.toLowerCase().includes(filter.toLowerCase())))
  // }, [socialAccounts])

  // //? Al cambio del filtro, filtra i social account
  useEffect(() => {
    setSocialAccountFiltered(socialAccounts.filter((acc) => acc.name.toLowerCase().includes(filter.toLowerCase())))
  }, [filter])

  return (
    <Box height={0} animation="fade" flex mv={64} mh={80}>
      <Text fontSize={21} style={{ color: AppStore.theme.o.green }}>
        <b>{T.settings.profileSocial}</b>
      </Text>

      <Box vcenter row mb={0} mt={24}>
        <Box row>
          <Input
            style={{ width: 336 }}
            placeholder={T.settings.searchSocial}
            prefix={<SearchOutlined />}
            onChange={(filters) => setFilter(filters)}
          />
        </Box>
        {isVisible('calendar.socialProfile.showQueueModalBtn') && (
          <Button ml={30} id="draft-calendar-page" variant="neutral" center onClick={showQueueModal} size="small">
            {T.settings.manageCode}
          </Button>
        )}

        <Button ml={30} id="draft-calendar-page" variant="neutral" center onClick={checkTokens} size="small">
          {T.settings.controlSsocial}
        </Button>
      </Box>
      <Box scroll flex pr={30} id="scroll-social">
        {/* ---------------------- Semplificazione ---------------------- */}
        {socials.map((social) => (
          <SocialProviderContainer
            sugestedClickOnRefresh={SelectedAccount}
            onDeleteAccount={deleteAccount}
            social={social as SocialIconsProvider}
            onClick={() => addAccounts(social as any)}
            socialAccounts={socialAccountFiltered.filter((acc) => acc.provider === social.toLowerCase())}
          />
        ))}
        {socialCommingSoon.map((social) => (
          <SocialProviderContainer
            commingSoon={social}
            sugestedClickOnRefresh={SelectedAccount}
            onDeleteAccount={deleteAccount}
            social={social as any}
            onClick={() => addAccounts(social as any)}
            socialAccounts={socialAccountFiltered.filter((acc) => acc.provider === social)}
          />
        ))}
      </Box>
    </Box>
  )
}

export default SocialProfileSection
