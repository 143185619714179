/* ___________________ IMPORTS ___________________ */
import styled from '@emotion/styled'
import { Box, Button, Icons } from 'components'
import { AppStore, T, showError, showInfo, showWarning } from 'utils'
import BoardCard from '../DiscoverContent/BoardCard'
import IndexComments from 'components/Comments'
import InfoComments from '../UI/InfoComments'
import {
  dateToString,
  decodeHTML,
  enumForTranslateLanguage,
  enumLanguage,
  getComments,
  onCreateNewCommentObj,
  onCreateNewReplyObj,
  postCreateComment,
  putCreateReply,
  removeSpanNoComment,
} from '../commonFunction'
import { IArticle, IBoard, IComments } from '../aiDiscoverInterface'
import { useEffect, useState } from 'react'
import { ChangeLanguageButton } from '../UI/ChangeLanguageButton'
import Article from 'api/Article'
import copy from 'clipboard-copy'

import { useJourneyStore } from 'stores/JourneyStore'
import { PlaceHolderImgNotFound } from 'components/PlaceholderImgNotFound'
import { isAiDiscoverCommentsEnabled } from 'utils/DiscoverUtils'

/* ___________________ INTERFACE ___________________ */
interface Props {
  article: IArticle
  onSelectBoard?: any // funzione da passare alla CardBoard
  isOnBoard?: boolean
  board?: IBoard // board attiva se siamo nella pagina board
  openLanguage: (outputLanguage, setOutputLanguage) => void
  isCreateComment: () => void
  onCommentDelete: (commentsIdSelected: string, board_id: string, article_uri: string) => any
}

export const ArticlePreviewModal = ({
  article,
  onSelectBoard,
  isOnBoard,
  board,
  openLanguage,
  isCreateComment,
  onCommentDelete,
}: Props) => {
  /* ____________________ STATE ____________________ */
  const [articleToView, setArticleToView] = useState<any>()
  const [openBoard, setOpenBoard] = useState<boolean>(false)
  const [comments, setComments] = useState<IComments[]>([])
  const [commentsIdSelected, setCommentsIdSelected] = useState<string>()
  const [openNewComment, setOpenNewComment] = useState<boolean>(false)
  const [userSelection, setUserSelection] = useState<any>()
  const [languageFilterArticle, setLanguageFilterArticle] = useState<string[]>([])

  /* ____________________ METHODS ____________________ */
  const onClickOpenBoard = () => setOpenBoard((prev) => !prev)
  const onOpenNewComment = () => setOpenNewComment(true)

  const onClickGeneratePostBtn = () => {
    // navigate('/post', { state: { link: article.url } })

    const setLinkToGenerate = useJourneyStore.getState().setLinkToGeneratePost

    setLinkToGenerate(article.url)
    AppStore.closeAllModal()
  }

  const copyArticleUrlToClipBoard = () => {
    copy(article.url)
      .then(() => {
        showInfo(T.AiDiscoverPage.linkCopiedInClipboard)
        // You can also provide feedback to the user, such as showing a tooltip or a success message
      })
      .catch((err) => {
        console.error('Error copying text to clipboard', err)
        // Handle the error, such as displaying an error message to the user
      })
  }

  const onSetLanguageFilterArticle = (language: string[]) => {
    const newLanguage = language
    setLanguageFilterArticle([...newLanguage])
    setUserSelection('')
    setOpenNewComment(false)

    if (language[0] === article.lang) {
      onClearLanguageArticle()
      return
    }

    onTranslateArticle(language[0])
  }

  const onClearLanguageArticle = () => {
    const defaultLanguage: string[] = [article.lang, enumLanguage[article.lang]]
    setLanguageFilterArticle([...defaultLanguage])

    onTranslateArticle(defaultLanguage[0])
  }

  const onSetSelection = (textSelection = '') => {
    setUserSelection(textSelection)
  }

  /**
   * ### Funzione che prende il testo selezionato dall'utente
   *
   * controllo che la selezione ci sia per poter andare avanti
   *
   * controllo sia la selezione del testo che l'html così da poter aggiungere un warning se è stato selezionata una parte di testo dentro già ad un altro commento ( riga 230 - riga 250 )
   *
   * se il controllo passa rimuovo gli span senza classe ( quelli senza commenti ma solo da decidere se commentare per poter continuare a selezionare anche la parte di testo già selezionata) e poi modifico il markup con la nuova selezione
   *
   * @returns void testo
   */
  const getTextSelection = () => {
    if (!isOnBoard) return
    const selection = window.getSelection()?.getRangeAt(0)?.cloneContents()
    // testo selezionato

    const selectedText = selection?.textContent

    if (!selectedText || selectedText.length < 5) return

    // controllo l'html per vedere se è stata selezionata una parte già commentata
    const selectionHTML = window.getSelection()
    if (!selectionHTML) return
    const range = selectionHTML?.getRangeAt(0)

    const div = document.createElement('div')
    div.appendChild(range.cloneContents())

    const selectedHtml = div.innerHTML

    let textIsJustSelected = false

    comments.forEach((el) => {
      if (el.articleText.includes(selectedText)) textIsJustSelected = true
    })

    // se viene selezionata una parte già commentata si da un warning, altrimenti si aggiunge uno span
    if (selectedHtml.includes('comment-text') || textIsJustSelected) {
      showWarning(T.AiDiscoverPage.boardCardWarning.noComment)
      onSetSelection()
      removeSpanNoComment()
      return
    } else {
      removeSpanNoComment()

      onSetSelection(selectedText)
      const allText = document.querySelector('.article-inner-content')
      if (!allText?.innerHTML) return

      let newText = `<span>${selectedText}</span>`

      // se non si trova la selezione a causa di caratteri speciali si prova a trovare la selezione con un metodo alternativo
      if (!allText.innerHTML.includes(selectedText)) {
        const findIndexInitialString = allText.innerHTML.indexOf(selectedText.slice(0, 10))

        const findIndexfinalString = allText.innerHTML.indexOf(selectedText.slice(-10))

        const savebefore = allText.innerHTML.substring(0, findIndexInitialString)
        const saveAfter = allText.innerHTML.substring(findIndexfinalString + 10)
        newText = `${savebefore}<span>${selectedText}</span>${saveAfter}}`

        allText.innerHTML = newText
      } else {
        allText.innerHTML = allText.innerHTML.replace(selectedText, newText)
      }
    }
    addSpanCommentEvent()
  }

  /**
   *
   * controllo le immagini e se non sono caricate le rimuovo dal DOM
   *
   * @param image immagine da caricare
   */
  const loadImageHandeler = (image) => {
    const img = new Image()

    img.src = image.src
    img.onload = function () {}
    img.onerror = function () {
      image.remove()
      return
    }
  }

  const onOpenComment = (commentId: string) => {
    setCommentsIdSelected(commentId)
  }

  /**
   * aggiunta dell'evento negli span con classe ".comment-text" per aprire il componente dei commenti specifico al singolo commento
   */
  const addSpanCommentEvent = () => {
    const allCommentText = document.querySelectorAll('.comment-text')

    allCommentText.forEach((commentText) => {
      if (commentText.id === commentsIdSelected) commentText.classList.add('up-to-layer')
      commentText.addEventListener('click', () => {
        const commentId = commentText.id

        commentText.classList.add('up-to-layer')

        onOpenComment(commentId)
      })
    })
  }

  /**
   * Chiusura del componente commenti modificando lo state (commentsIdSelected ) e rimozioni della classe ".up-to-layer" dallo span commento che era stato cliccato per togliergli lo z-index
   */
  const onCloseComment = () => {
    setCommentsIdSelected('')
    setUserSelection('')
    setOpenNewComment(false)

    removeSpanNoComment()
    addSpanCommentEvent()
    const spanUp = document.querySelector('.up-to-layer')
    spanUp?.classList.remove('up-to-layer')
  }

  /* ____________________ API CALL ____________________ */
  const getCommentOnArticle = async (language: string) => {
    if (isOnBoard && board) {
      const getApiComments = await getComments(board._id ?? '', article.uri, language)

      setComments([...getApiComments])
    }
  }

  const onCreateNewComment = async (commentText: string) => {
    const newComment = onCreateNewCommentObj(
      article.uri,
      languageFilterArticle[0] ?? article.lang,
      board?._id ?? '',
      commentText,
      userSelection
    )
    const isCreated = await postCreateComment(newComment, articleToView)

    if (isCreated) {
      removeSpanNoComment()
      getCommentOnArticle(languageFilterArticle[0])
      setCommentsIdSelected(isCreated._id)
    } else {
      showError(T.AiDiscoverPage.boardCardError.createComment)
    }
  }

  const onDeleteComment = async () => {
    if (!commentsIdSelected || !board?._id) return
    const isDeleted = onCommentDelete(commentsIdSelected, board._id, article.uri)

    if (isDeleted) {
      const spanToDelete = document.querySelectorAll('.comment-text')

      spanToDelete.forEach((span) => {
        if (span.id === commentsIdSelected) {
          span?.removeAttribute('class')
          span?.removeAttribute('id')
        }
      })

      removeSpanNoComment()
      onCloseComment()
      getCommentOnArticle(languageFilterArticle[0])
    }
  }

  const replyOnComent = async (replyText: string) => {
    const newReply = onCreateNewReplyObj(replyText)
    const isReplyCreated = await putCreateReply(newReply, commentsIdSelected ?? '', board?._id ?? '')

    if (isReplyCreated) getCommentOnArticle(languageFilterArticle[0])
    else showError(T.AiDiscoverPage.boardCardError.createComment)
  }

  const onTranslateArticle = async (language: string) => {
    if (language !== article.lang) {
      const translatedArticle = await Article.translateArticle(article, enumForTranslateLanguage[language], language)

      if (!translatedArticle) return
      setArticleToView({ ...translatedArticle })
      getCommentOnArticle(language)
      return
    }
    setArticleToView(article)
    getCommentOnArticle(article.lang)
  }

  const removeSelectionListener = (e: Event) => {
    const elementClicked = e.target as HTMLElement

    if (
      elementClicked.classList[0] === 'article-text' ||
      elementClicked.classList[0] === 'comment-text' ||
      elementClicked.classList[0] === 'btn-open-comment' ||
      elementClicked.classList[0] === 'comment-layer' ||
      elementClicked.classList[0] === undefined ||
      !openNewComment
    ) {
      return
    } else {
      removeSpanNoComment()
    }

    return window.removeEventListener('click', removeSelectionListener)
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    const articleCopy = { ...article }

    if (!articleCopy) {
      setArticleToView([])
      AppStore.closeAllModal()
      return
    }
    setArticleToView(articleCopy)
    // setta la lingua

    const defaultLanguage: string[] = [article.lang, enumLanguage[article.lang]]

    setLanguageFilterArticle(defaultLanguage)

    // se non siamo in una board non carico i commenti
    if (isOnBoard) getCommentOnArticle(defaultLanguage[0])

    // controllo dopo 300ms se ci sono immagini da caricare e se ci sono controllo se le carica, altrimenti le rimuovo
    setTimeout(() => {
      const allImg = document.querySelectorAll('.article-inner-content img')

      allImg.forEach((img) => {
        const image: HTMLImageElement = img as HTMLImageElement

        loadImageHandeler(image)
      })
    }, 300)
  }, [])

  // controllo se ci sono delle parti selezionate per i commenti
  useEffect(() => {
    if (!isOnBoard || !isAiDiscoverCommentsEnabled) return

    // prendo tutto il testo dell'articolo
    const allText = document.querySelector('.article-inner-content')

    if (!allText?.innerHTML || !comments) return

    allText.innerHTML = `
    <p class="article-text">
        ${articleToView.body}
      <a href=${articleToView.url} target="_blank" style="padding: 5px">
        ${T.AiDiscoverPage.readFullArticle}
      </a>
    </p>`
    comments.forEach((comment) => {
      if (allText?.innerHTML.includes(comment.articleText)) {
        const commentExist = document.getElementById(`${comment._id}`)
        if (commentExist) return

        const newText = `<span class="comment-text" id="${comment._id}">${comment.articleText}</span>`
        allText.innerHTML = allText.innerHTML
          .replace(comment.articleText, newText)
          .replace(/<span>([^<]*?)<\/span>/g, '$1')
      }
    })

    /* _____________  span comment  event _____________ */

    addSpanCommentEvent()
  }, [comments])

  // useEffect che si attiva quando si clicca su un commento o si esce dal componente dei commenti
  useEffect(() => {
    if ((commentsIdSelected || !isOnBoard) && !isAiDiscoverCommentsEnabled) return

    // se non ci sono commenti selezionati rimuove i possibili span di troppo e rimette gli eventi agli span dei commenti
    removeSpanNoComment()
    addSpanCommentEvent()
  }, [commentsIdSelected])

  // useEffect al click della window per rimuovere il testo selezionato e gli span senza commenti se si clicca al di fuori della parte testuale
  useEffect(() => {
    window.addEventListener('click', removeSelectionListener)

    return () => {}
  })

  /* ____________________ STYLE ____________________ */
  const buttonStyle = {
    fontSize: 14,
    fontWeight: 700,
    maxHeight: 34,
    minHeight: 34,
    gap: 12,
  }

  return (
    <ArticleWrapper>
      {isAiDiscoverCommentsEnabled && isOnBoard && comments && <InfoComments commentsLength={comments.length} />}

      <ArticleContainer className="article-container">
        {articleToView && (
          <>
            {/* ______________ OPTIONS CLICCABILI ______________ */}
            <ArticleOptions>
              <ArticleOptionBtnContainer>
                <Button
                  className="generate-post-btn"
                  variant="primary"
                  pv={8}
                  ph={24}
                  style={buttonStyle}
                  onClick={copyArticleUrlToClipBoard /* COPY TO CLIPBOARD */}
                >
                  <Icons.copyLink fill={AppStore.theme.o.blueOnLight} width={20} height={20} />
                  {T.AiDiscoverPage.copyLink}
                </Button>
                <ChangeLanguageButton
                  fromArticle={true}
                  defaultLanguageArticle={article.lang}
                  languageFilterArticle={languageFilterArticle}
                  setLanguageFilterArticle={onSetLanguageFilterArticle}
                  openLanguageOnArticle={openLanguage}
                  clearLanguage={onClearLanguageArticle}
                />
              </ArticleOptionBtnContainer>
              {!isOnBoard ? (
                <ArticleIconContainer>
                  <SaveArticle
                    className={`save-article-container ${openBoard ? 'active' : ''}`}
                    onClick={onClickOpenBoard}
                  >
                    <Icons.saveIcon fill="currentColor" />
                  </SaveArticle>
                  <BoardCardAnimation className={`board-animation ${openBoard ? 'animation' : ''}`}>
                    <BoardCard
                      isOpen={openBoard}
                      isOnArticleModal={true}
                      onSelectBoardOnModal={onSelectBoard}
                      article={article}
                    />
                  </BoardCardAnimation>
                </ArticleIconContainer>
              ) : (
                <BoardNameInfo>
                  <Icons.users />
                  <BoardNameText>{board?.boardTitle}</BoardNameText>
                </BoardNameInfo>
              )}
            </ArticleOptions>

            {/* ______________ ARTICLE SCROLLABLE ______________ */}
            <ArticleScrollContainer>
              {/* ______________ ARTICLE CONTENT ______________ */}
              <ArticleContent>
                {/* ______________ TITLE ______________ */}
                <ArticleTitle titleLength={articleToView.title.length}>{decodeHTML(articleToView.title)}</ArticleTitle>

                {/* ______________ SOURCE ______________ */}
                <ArticleSource>
                  <a href={`https://${article.source.uri}`} target="_blank">
                    {articleToView.source.title}
                  </a>
                  {`   |   `}
                  {dateToString(articleToView.dateTimePub ?? articleToView.dateTime)}
                </ArticleSource>

                {/* ______________ CONTENT ( ARTICLE-TEXT ) ______________ */}

                <ArticleBodyText
                  className="article-inner-content"
                  onMouseUp={isAiDiscoverCommentsEnabled ? getTextSelection : () => {}}
                  onMouseDown={
                    isAiDiscoverCommentsEnabled
                      ? (e) => {
                          if (e.detail > 1) {
                            e.preventDefault()
                            onSetSelection()
                            removeSpanNoComment()
                          }
                        }
                      : () => {}
                  }
                >
                  <p className="article-text">
                    {articleToView.body}
                    <a href={article.url} target="_blank" style={{ padding: 5 }}>
                      {T.AiDiscoverPage.readFullArticle}
                    </a>
                  </p>
                </ArticleBodyText>

                {articleToView.image ? (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '380px' }}>
                    <img className="article-img" src={articleToView.image} />
                  </div>
                ) : (
                  <PlaceHolderImgNotFound variant="full" />
                )}
              </ArticleContent>
            </ArticleScrollContainer>
          </>
        )}

        {/* ______________ COMMENTS ______________ */}
        {commentsIdSelected || openNewComment ? (
          <>
            {/* ______________ COMMENTS LAYER ______________ */}
            <CommentsLayer className="comment-layer" onClick={onCloseComment} removeHover />
            {/* ______________ COMMETS CONTAINER  ______________ */}
            {/* se il testo è selezionato per fare un nuovo commento apparte il CommentButton per aprire i commenti */}
            <CommentsContainer>
              <IndexComments
                commentsType="on-article"
                comments={comments.filter((c) => c._id === commentsIdSelected)[0]}
                addNewComment={onCreateNewComment}
                addNewReply={replyOnComent}
                deleteComent={onDeleteComment}
                isCreateComment={isCreateComment}
              />
            </CommentsContainer>
          </>
        ) : userSelection ? (
          <CommentButton className="btn-open-comment" onClick={onOpenNewComment}>
            {T.AiDiscoverPage.word.comment} <Icons.commentsWithPoints />
          </CommentButton>
        ) : null}
      </ArticleContainer>
    </ArticleWrapper>
  )
}

/* STYLE */

const ArticleWrapper = styled(Box)`
  width: auto;
  height: auto;
  position: absolute;
`

const ArticleContainer = styled(Box)`
  background-color: ${() => AppStore.theme.o.surface};
  min-height: 900px;
  max-height: 900px;
  min-width: 820px;
  max-width: 820px;
  padding: 32px 70px;
  gap: 24px;
  overflow: hidden;
  position: relative;
`

const ArticleOptions = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0;
`

const ArticleOptionBtnContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  border-radius: 0;
`

const ArticleIconContainer = styled(Box)`
  position: relative;
`

const SaveArticle = styled(Box)`
  align-self: center;
  transition: all 0.3s ease-in-out;
  z-index: 101;

  &.save-article-container {
    background-color: ${() => `${AppStore.theme.o.lightestGrey}`};
    color: ${() => `${AppStore.theme.o.darkGrey}`};
    width: 32px;
    height: 32px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    &:hover {
      background-color: ${() => `${AppStore.theme.o.lightBlue}`};
      color: ${() => `${AppStore.theme.o.blue}`};
    }

    &.active {
      background-color: ${() => `${AppStore.theme.o.lightBlue}`};
      color: ${() => `${AppStore.theme.o.blue}`};
    }
  }

  svg {
    height: 24px;
    width: 24px;
  }
`

const BoardNameInfo = styled(Box)`
  width: fit-content;
  min-width: 158px;
  max-width: 200px;
  height: 36px;
  min-height: 36px;
  max-height: 36px;
  background-color: ${() => `${AppStore.theme.o.lightGreen}`};
  color: ${() => `${AppStore.theme.o.green}`};
  font-size: 14px;
  font-weight: 700;
  line-height: 17.92px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 8px, 16px;
  border: 1px solid ${() => `${AppStore.theme.o.green}`};
  overflow: hidden;

  svg {
    fill: ${() => `${AppStore.theme.o.green}`};
  }
`

const BoardNameText = styled(Box)`
  display: block;
  overflow-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 110px;
  border-radius: 0;
`

const BoardCardAnimation = styled(Box)`
  position: absolute;
  top: 0px;
  right: 12px;
  width: 0;
  height: 0;
  min-height: 0;
  max-height: 0;
  overflow: hidden;
  opacity: 1;
  transition: all 0.4s ease-in-out, opacity 0.3s ease-in-out;

  &.animation {
    top: -12px;
    right: -24px;
    width: 351px;
    height: 423px;
    min-height: 423px;
    max-height: 423px;
    box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};
    opacity: 1;
  }
`

const ArticleScrollContainer = styled(Box)`
  width: 100%;
  max-height: 800px;
  overflow: auto;
  border-radius: 0;

  &::-webkit-scrollbar {
    display: none;
  }

  .article-img {
    border-radius: 14px;
  }
`

const ArticleContent = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  /* margin-bottom: 128px; */
  border-radius: 0;
  gap: 24px;

  img {
    width: 100%;
    max-height: 380px;
    object-fit: cover;
    /* justify-self: center; */
    /* align-self: stretch; */
    user-select: none;
    pointer-events: none;
    border-radius: 14px;
  }
`

const ArticleTitle = styled(Box)<{ titleLength: number }>`
  color: ${() => `${AppStore.theme.o.black}`};
  font-size: ${({ titleLength }) => (titleLength > 75 ? '38px' : '45px')};
  /* font-size: 45px; */
  font-weight: 600;
  line-height: ${({ titleLength }) => (titleLength > 75 ? '45px' : '57.6px')};
  /* line-height: 57.6px; */
  letter-spacing: 0.45px;
  display: flex;
  border-radius: 0px;

  ${({ titleLength }) => (titleLength > 200 ? `overflow: hidden; max-height: 220px;` : ``)}
`

const ArticleSource = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  color: ${() => `${AppStore.theme.o.darkGrey}`};
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  letter-spacing: 0.16px;
  white-space: pre-wrap;

  & a {
    color: ${() => `${AppStore.theme.o.darkGrey}`};
    font-family: Abel;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.16px;
    text-decoration-line: underline;

    &:hover {
      color: ${() => `${AppStore.theme.o.green}`};
    }
  }
`

const ArticleBodyText = styled(Box)`
  p {
    color: ${() => `${AppStore.theme.o.black}`};
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
    margin-bottom: 16px;
    max-height: 200px;

    &::selection {
      background: ${() => `${AppStore.theme.o.lightGreen}`};
      color: ${() => `${AppStore.theme.o.green}`};
    }
  }

  a {
    color: ${() => `${AppStore.theme.o.black}`};
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
    text-decoration: underline;
    user-select: none;

    &:hover {
      color: ${() => `${AppStore.theme.o.green}`};
    }
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    object-fit: cover;
    margin: 32px auto;
    border-radius: 14.08px;
  }

  span {
    color: ${() => `${AppStore.theme.o.green}`};
    background-color: ${() => `${AppStore.theme.o.lightGreen}`};
    position: relative;
    cursor: pointer;
    border-radius: 8px;

    &::selection {
      background: ${() => `${AppStore.theme.o.lightGreen}`};
      color: ${() => `${AppStore.theme.o.green}`};
    }
  }

  span.comment-text {
    color: ${() => `${AppStore.theme.o.green}`};
    background-color: ${() => `${AppStore.theme.o.lightGreen}`};
    border-bottom: 0.5px solid transparent;
    cursor: pointer;

    &::selection {
      background: ${() => `${AppStore.theme.o.lightRed}`};
      color: ${() => `${AppStore.theme.o.red}`};
    }

    &:hover {
      border-bottom: 0.5px solid ${() => `${AppStore.theme.o.green}`};
    }

    &.up-to-layer {
      z-index: 1;
      border-bottom: 0.5px solid ${() => `${AppStore.theme.o.green}`};
    }
  }
`

const CommentsLayer = styled(Box)`
  position: absolute;
  top: 78.08px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${() => `${AppStore.theme.o.surface}cc`};
`

const CommentsContainer = styled(Box)`
  width: 464px;
  min-height: 44px;
  max-height: calc(840px - 78.08px - 24px);
  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);

  overflow: visible;
  z-index: 99;
`

const CommentButton = styled(Box)`
  width: 196px;
  max-width: 196px;
  height: 48px;
  max-height: 48px;
  color: ${() => `${AppStore.theme.o.black}`};
  background-color: ${() => `${AppStore.theme.o.surface}`};
  font-size: 16px;
  font-weight: 700;
  line-height: 20.48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 14px;
  box-shadow: ${() => AppStore.theme.finalNavbarBoxShadow.slice(11)};

  position: absolute;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;

  &:hover {
    background-color: ${() => `${AppStore.theme.o.lightBlue}`};
    color: ${() => `${AppStore.theme.o.blue}`};

    svg {
      path {
        fill: ${() => `${AppStore.theme.o.blue}`};
      }
    }
  }

  svg {
    width: 24px;
    height: 24px;
    path {
      fill: currentColor;
    }
  }
`

// const PlaceHolderImgNotFound = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   border-radius: 14px;
//   width: 100%;
//   height: 408px;
//   background-color: ${() => AppStore.theme.o.lightestGrey};
//   font-size: 9px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: normal;
//   color: ${() => AppStore.theme.o.darkGrey};
//   svg {
//     g {
//       path {
//         fill: ${() => AppStore.theme.o.darkGrey};
//       }
//     }
//   }
// `
