/* ___________________ IMPORTS ___________________ */
import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { Box } from 'components'
import SearchContentNoInput from './SearchContentNoInput'
import { DiscoverSearchBar } from './DiscoverHeader/DiscoverSearchBar'
import { AppStore } from 'utils'
import { SearchContent } from './SearchContent'
import BoardPageheader from './DiscoverHeader/BoardPageHeader'
import BoardPage from './BoardPage'
import FollowPageHeader from './DiscoverHeader/FollowPageHeader'
import { useDiscoverStore } from 'stores/DiscoverStore'
import { TrendArticles } from './TrendArticles'
import { FollowedTopicArticles } from './FollowedTopicArticles'

const DiscoverContent = () => {
  /* ____________________ ZUSTAND  ____________________ */
  const { subPageActive, activeBoard, inputSearchbar, activeTopicSidebar } = useDiscoverStore((state) => ({
    subPageActive: state.subPageActive,
    activeBoard: state.activeBoard,
    inputSearchbar: state.inputSearchbar,
    activeTopicSidebar: state.activeTopicSidebar,
  }))

  /* ____________________ STATE  ____________________ */

  const [openNewBoard, setOpenNewBoard] = useState<boolean>(false)

  /* ____________________ VARIABLES  ____________________ */

  /* ____________________ REF  ____________________ */
  const scrollContainer = useRef<HTMLDivElement>(null)

  /* ____________________ METHODS  ____________________ */
  /**
   * Funzione per aprire il compoonente che serve per creare una nuova board dentro a <BoardPage>
   */
  const onOpenNewBoardHandler = () => {
    const menu = document.getElementById('menu-container')
    const headerNavbar = document.getElementById('setting-buttons')
    if (menu && headerNavbar) {
      if (openNewBoard) {
        menu.style.zIndex = '1'
        headerNavbar.style.zIndex = '1'
      } else {
        menu.style.zIndex = '0'
        headerNavbar.style.zIndex = '0'
      }
    }

    setOpenNewBoard((prev) => !prev)
  }

  const onScrollTop = () => {
    scrollContainer?.current?.scrollTo(0, 0)
  }

  /* ____________________ USEEFFECT  ____________________ */
  useEffect(() => {
    onScrollTop()
  }, [subPageActive, activeBoard])

  return (
    <PageContent>
      <ContentHeader>
        {/* Header contenuto della pagina sezione "search" */}
        {subPageActive === 'search' && <DiscoverSearchBar />}

        {/* Header contenuto della pagina sezione "follow" */}
        {subPageActive === 'follow' && <FollowPageHeader />}

        {/* Header contenuto della pagina sezione "board" */}
        {subPageActive === 'board' && <BoardPageheader onOpenNewBoard={onOpenNewBoardHandler} />}
      </ContentHeader>

      <ContentContainer
        forwardedRef={scrollContainer}
        style={
          subPageActive === 'search' && !inputSearchbar ? {} : { backgroundColor: `${AppStore.theme.o.lightestGrey}` }
        }
      >
        {/* Contenuto della pagina sezione "search" se non è stato cercato qualcosa nella searchbar*/}
        {subPageActive === 'search' && !inputSearchbar && <SearchContentNoInput />}

        {/* Contenuto della pagina sezione "search" dopo che si ha effettuato una ricerca con la searchbar  */}
        {subPageActive === 'search' && inputSearchbar && <SearchContent />}

        {/* Contenuto della pagina sezione "follow" se non c'è un topic seguito attivo */}
        {subPageActive === 'follow' && !activeTopicSidebar && <TrendArticles />}

        {/* Contenuto della pagina sezione "follow" dopo che si ha cliccato un topic seguito */}
        {subPageActive === 'follow' && activeTopicSidebar && <FollowedTopicArticles />}

        {/* Contenuto della pagina sezione "board" */}
        {subPageActive === 'board' && <BoardPage openNewBoard={openNewBoard} onOpenNewBoard={onOpenNewBoardHandler} />}
      </ContentContainer>
    </PageContent>
  )
}

export default DiscoverContent

const PageContent = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 0;
  width: 1180px;
  max-height: calc(100% - 24px);
`

const ContentHeader = styled(Box)`
  width: 100%;
  position: relative;
  height: 66px;
  min-height: 66px;
  justify-content: center;
  align-items: center;
  background: transparent;
`

const ContentContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`
