import { HighchartsAsters } from '../config/HighchartsOptions/highchartsAsters'
import HighchartsReact from 'highcharts-react-official'
import { highchartsOptionsEnum } from '../config/HighchartsOptions/chartOptions/index'
import { GraphType } from 'types/AnalyticsInferfaces'

/* ------------------- INTERFACES  */
interface Props {
  type: GraphType
  dataSeries?: Highcharts.SeriesOptionsType[]
  tooltipLabels?: string[] | string[][]
  xAxisLabels?: string[]
  yAxisLabels?: string[]
}

export const HighChartsGraph = ({ type, dataSeries, xAxisLabels, yAxisLabels, tooltipLabels }: Props) => {
  //Will be used to send down data, categories and so on.
  const chartOptions: Highcharts.Options = {
    ...highchartsOptionsEnum[type],
    series: [{ data: dataSeries, name: tooltipLabels }],
    xAxis: {
      ...highchartsOptionsEnum[type].xAxis,
      categories: xAxisLabels,
      // title: {
      //   text: 'Giorni',
      // },
    },
    yAxis: {
      ...highchartsOptionsEnum[type].yAxis,
      categories: yAxisLabels,
      // title: {
      //   text: 'Visualizzazioni',
      // },
    },
  }

  return <HighchartsReact highcharts={HighchartsAsters} options={chartOptions} />
}

// ESEMPIO BOXPLOT https://codesandbox.io/p/sandbox/react-box-plot-highcharts-jdoh6?file=%2Fsrc%2Findex.js%3A23%2C44
