/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { AVAILABLE_SOCIALS, mapSocialTypeToSocialMedias } from 'utils/Constants/Constants'
import PostV2 from 'api/PostV2'
import Posts from 'api/Posts'

import { Tracker } from 'api/Tracker'
import { AnimatedPlaceholderLogo } from 'components/AnimatedPlaceholderLogo'
import { Box } from 'components/Box'
import { LottieSadSearch } from 'components/CreatePost/MediaComponents/LottieSadSearch'
import { DraftPost } from 'components/DraftPost'
import { MultiToggleButton } from 'components/MultiToggleButton'
import { SocialToggleButton } from 'components/SocialToggleButton'
import { Title } from 'components/Title'
import { ZoomedContainer } from 'components/ZoomedContainer'
import { useEffect, useState } from 'react'
import Masonry from 'react-masonry-css'
import { ISocialFilters } from 'stores/interfaces/CalendarPageInterface'
import { IPostVariant } from 'types/PostInterface'
import { AppStore, T, getSizeZoomed, showError, showSuccess } from 'utils'
import { TRACKEREVENTS, getDraftData, getMixpanelPostDeletedData } from 'utils/eventsTracker'

/* ------------------- INTERFACES  */

interface Props {
  onPostClick: () => void
  goToPost?: (bol: boolean) => void
}

export const DraftModal = ({ onPostClick, goToPost }: Props) => {
  const from = window.location.pathname

  /* ----------------- STATE  */
  const [socialFilters, setSocialFilters] = useState<ISocialFilters[]>(
    AVAILABLE_SOCIALS.map((social) => ({ social: mapSocialTypeToSocialMedias(social), active: true }))
  )
  const [posts, setPosts] = useState<IPostVariant[]>()
  const [loading, setLoading] = useState(true)
  const [typeFilter, setTypeFilter] = useState(T.postPage.draftAll)
  const [refresh, setRefresh] = useState(false)

  /* ----------------- METHODS  */
  const fetchData = async () => {
    try {
      // const postsData = (await Posts.paginate({ status: 'draft', page: 1, limit: 2000 }))?.results
      const postsData = (await PostV2.getDrafts<any>())?.results ?? []
      setPosts(postsData || [])
      setLoading(false)
    } catch (error) {
      showError(T.error.cannotLoadDraft)
      setLoading(false)
    }
  }

  const deletePost = async (id: string) => {
    setRefresh(!refresh)
    if (!id) return showError(T.error.postDontExist)
    try {
      await PostV2.DeletePost(id)
      const updatedPosts = posts?.filter((post) => post.data._id !== id)
      setPosts(updatedPosts)
      showSuccess(T.success.eliminationSuccses)

      const postToDelete = posts?.find((p) => p.data._id === id)
      Tracker.trackEvent(TRACKEREVENTS.POST_DELETED, getMixpanelPostDeletedData(postToDelete!, false))
      setRefresh(!refresh)
    } catch (error) {
      showError(T.error.eliminationFaild)
    }
  }

  const filterForFileType = (post) => {
    switch (typeFilter) {
      case T.postPage.draftAll:
        return true
      case T.postPage.draftPhotos:
        return (post?.data.medias ?? []).filter((m) => m.mimetype.includes('image')).length > 0
      case T.postPage.draftVideo:
        return (post.data.medias ?? []).filter((m) => m.mimetype.includes('video')).length > 0
      case T.postPage.draftText:
        return (post.data.medias ?? []).length === 0
      default:
        return true
    }
  }

  const filterPostsModal = (post: IPostVariant) => {
    return socialFilters
      .filter((socialFilter) => socialFilter.active)
      .filter(() => filterForFileType(post))
      .map((socialFilter) => socialFilter.social)
      .includes(post.info.account.provider)
  }

  const countElementsInFilters = () => {
    const counts = {}

    socialFilters.forEach((socialFilter) => {
      const filteredPosts = posts?.filter((post) => filterForFileType(post))
      const filterCount = filteredPosts?.filter((post) => socialFilter.social === post.info.account.provider).length
      counts[socialFilter.social] = filterCount
    })

    return counts
  }

  const trackDraftOpened = () => {
    try {
      const activeSocialFilters: string[] = []
      socialFilters
        .filter((socialFilter) => socialFilter.active)
        .map((filter) => {
          activeSocialFilters.push(filter.social)
        })
      Tracker.trackEvent(TRACKEREVENTS.DRAFT_OPENED, {
        $current_url: from,
        ...getDraftData({ ...countElementsInFilters() }),
      })
    } catch (e) {}
  }

  /* ----------------- USEEFFECT  */
  useEffect(() => {
    fetchData()
    trackDraftOpened()
  }, [])

  return (
    <>
      {loading ? (
        <LoadingContainer>
          <AnimatedPlaceholderLogo />
        </LoadingContainer>
      ) : (
        <DraftModalContainer height={getSizeZoomed(80, 'vh')}>
          <ZoomedContainer style={{ height: 0, overflow: 'auto' }} zoom={AppStore.zoom} flex pt={30} ph={80}>
            <DraftHeader>
              <Title style={{ fontSize: 27, alignSelf: 'center', wordBreak: 'unset' }}>{T.postPage.yourDrafts}</Title>
              <FilterContainer>
                <MultiToggleButton
                  onChange={(status) => setTypeFilter(status as any)}
                  values={[T.postPage.draftAll, T.postPage.draftPhotos, T.postPage.draftVideo, T.postPage.draftText]}
                />
                <SocialToggleButton socials={socialFilters} setSocial={(socials) => setSocialFilters(socials)} />
              </FilterContainer>
            </DraftHeader>

            <PostContainer>
              <Masonry breakpointCols={4} className="my-masonry-grid" columnClassName="my-masonry-grid_column">
                {posts?.filter(filterPostsModal).map((post) => (
                  <DraftPost onPostDelete={deletePost} onPostClick={onPostClick} post={post} goToPost={goToPost} />
                ))}
                <BoxTrasparent width={'100vw'} center>
                  {posts?.length === 0 && <LottieSadSearch />}
                </BoxTrasparent>
              </Masonry>
            </PostContainer>
          </ZoomedContainer>
        </DraftModalContainer>
      )}
    </>
  )
}

const DraftModalContainer = styled(Box)``

const BoxTrasparent = styled(Box)`
  background-color: transparent !important;
`

const LoadingContainer = styled(Box)`
  height: 80vh;
`

const FilterContainer = styled(Box)`
  flex-direction: row;
  gap: 16px;
`

const DraftHeader = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`

const PostContainer = styled(Box)`
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  margin-bottom: 16px;
`

const EmptyAnimationContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40vw;
  min-height: 20vh;
  margin-left: -30%;
  margin-top: -2%;
`
