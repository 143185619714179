/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box } from 'components'
import { TextContainer } from '../../GenericComponents/TextContainer'
import { PreviewResult } from '../../GenericComponents/PreviewResults'
import { ResultsPreviewInstagramStandard } from './ResultsPreviewInstagramStandard'

/* ------------------- INTERFACES  */
interface Props {}

export const InstagramStandard = ({}: Props) => {
  return (
    <InstagramStandardContainer>
      <PreviewResult component={<ResultsPreviewInstagramStandard />} />
      <TextContainer />
    </InstagramStandardContainer>
  )
}

const InstagramStandardContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  position: relative;
`
