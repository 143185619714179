import styled from '@emotion/styled'
import { Box } from 'components/Box'
import React, { useEffect, useRef } from 'react'
import { AppStore } from 'utils'
import ExtraSelectDropdownItem from './ExtraSelectDropdownItem'

/* ___________________ TYPE ___________________ */

/* ___________________ INTERFACE ___________________ */
interface Props {
  classes?: string
  maxWidth?: number | string
  selectWith?: 'checkbox' | 'default'
  items?: any[] | undefined
  toggleDropdown: () => void
  dropdownHeaderText: string
  isDropdownOpen: boolean
  onSelect: (arg: any) => void
  selectedItems: any
  truncateTextNum?: number
}

const ExtraSelect = ({
  classes = 'select-extra-section',
  maxWidth,
  selectWith = 'default',
  items,
  dropdownHeaderText,
  toggleDropdown,
  isDropdownOpen,
  onSelect,
  selectedItems,
  truncateTextNum = 30,
}: Props) => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  const dropdownHeaderRef = useRef<HTMLDivElement>(null)

  const isExternalElementClicked = (currentElement, elementToCheck): boolean => {
    return !currentElement?.contains(elementToCheck)
  }

  const handleClose = (e) => {
    const elToCheck = e.target as Element
    const selectDropdownContainer = document.querySelector('.select-dropdown-container')

    if (dropdownHeaderRef.current?.contains(elToCheck)) return

    if (selectWith === 'checkbox' && isExternalElementClicked(selectDropdownContainer, elToCheck)) {
      toggleDropdown()
      return
    }
    if (selectWith !== 'checkbox' && isExternalElementClicked(dropdownRef?.current, elToCheck)) {
      toggleDropdown()
    }
  }

  useEffect(() => {
    if (isDropdownOpen) window.addEventListener('click', handleClose)
    return () => window.removeEventListener('click', handleClose)
  })

  return (
    <Dropdown className={classes} style={maxWidth ? { maxWidth: maxWidth } : {}}>
      <DropdownHeader forwardedRef={dropdownHeaderRef} className="dropdown-header" onClick={toggleDropdown}>
        {dropdownHeaderText}
        <Arrow className={isDropdownOpen ? 'arrow open' : 'arrow'} />
      </DropdownHeader>
      {isDropdownOpen && (
        /* ------------------- DROPDOWN - ITEM CON EMPTY STATE */
        <ExtraSelectDropdownItem
          selectWith={selectWith}
          items={items}
          onSelect={onSelect}
          selectedItems={selectedItems}
          truncateTextNum={truncateTextNum}
        />
      )}
    </Dropdown>
  )
}

export default ExtraSelect

const Arrow = styled.i`
  border: solid ${() => AppStore.theme.o.black};
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transition: all 0.2s;

  transform: rotate(45deg);

  &.open {
    transform: rotate(-135deg);
  }
`

const DropdownHeader = styled(Box)`
  color: ${() => AppStore.theme.o.black};
  flex-direction: row;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
`

const Dropdown = styled(Box)`
  height: 32px;
  border: 1px solid ${() => AppStore.theme.o.grey};
  background-color: ${() => AppStore.theme.o.lightestGrey};
  position: relative;
  padding: 8px 16px;
  justify-content: center;
`
