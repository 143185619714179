import Utils from 'api/Utils'
import { Box, Button, Page, Text } from 'components'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import opsAnimation from 'assets/plan-expired.json'
import Lottie from 'react-lottie'

const CourtesyPage = () => {
  const courtesyOptions = {
    loop: true,
    animationData: opsAnimation,
    position: -99999999,
  }

  return (
    <Page title={T.courtesy.subscriptionExpired}>
      <Box flex center>
        <Lottie isClickToPauseDisabled options={courtesyOptions} height={300} width={300} />
        <Text weight={700} fontSize={42} style={{ marginTop: 16 }}>
          {T.courtesy.title}
        </Text>
        <Text
          style={{
            width: '100ch',
            marginTop: 14,
            marginBottom: 14,
            textAlign: 'center',
            fontSize: 21,
            color: AppStore.theme.o.darkGrey,
            fontWeight: 500,
          }}
        >
          {T.courtesy.description}
        </Text>
        <Button
          variant="neutral"
          width={318}
          size="small"
          mt={20}
          onClick={async () => {
            try {
              const results = await Utils.getFrillUserToken({ id: AppStore.loggedUser._id })
              window.open(`mailto:support@asters.ai`, '_blank')
            } catch (error) {}
          }}
        >
          {T.courtesy.cta}
        </Button>
      </Box>
    </Page>
  )
}

export default CourtesyPage
