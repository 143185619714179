import styled from '@emotion/styled'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Component } from 'react'
import { AppStore } from 'utils'
interface Props {
  options: { id?: string; onClick: () => void; text: string; style?: React.CSSProperties; icon?: any }[]
  id?: string
}
interface State {
  showOptions: boolean
}

export class ButtonWithOptions extends Component<Props, State> {
  static defaultProps: Partial<Props> = {
    id: 'button-options-container',
  }
  state: State = {
    showOptions: false,
  }

  closePublishOptions = () => {
    this.setState({ showOptions: false })
  }

  closePlanningOptions = () => {
    this.setState({ showOptions: false })
  }

  render() {
    const { options, children } = this.props
    const { showOptions } = this.state

    return (
      <Box style={{ position: 'relative' }}>
        {showOptions && (
          <Overlay
            onClick={() =>
              this.setState({
                showOptions: false,
              })
            }
          />
        )}
        <Box
          onClick={() => {
            this.setState({ showOptions: true })
          }}
          id={this.props.id}
        >
          {children}
        </Box>
        {showOptions && (
          //add top props with the height of the element with id this.props.id
          <AbsolutePublishButton
            top={document.getElementById(this.props.id ?? 'button-options-container')!.clientHeight}
            animation="fade"
          >
            {options.map(({ id, onClick, text, style, icon }, index) => (
              <PublishButton
                id={id}
                key={index}
                onClick={() => {
                  this.setState({ showOptions: false })
                  onClick()
                }}
                style={style}
                leftIcon={icon}
                variant="secondary-small"
              >
                {text}
              </PublishButton>
            ))}
          </AbsolutePublishButton>
        )}
      </Box>
    )
  }
}

const AbsolutePublishButton = styled(Box)<{ top?: number }>`
  position: absolute;
  border-radius: 14px;
  right: 0;
  position: absolute;
  top: ${({ top = 0 }) => top}px;
  z-index: 99;
`
const PublishButton = styled(Button)`
  border-radius: 0px;
  box-shadow: none;
  color: ${() => AppStore.theme.o.black};
  :hover {
    background-color: ${() => AppStore.theme.o.lightBlue};
  }
  border-radius: 0px;
  box-shadow: none;
  //first and last element with border radius
  &:first-of-type {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
  }
  &:last-child {
    border-bottom-right-radius: 14px;
    border-bottom-left-radius: 14px;
  }
`
const Overlay = styled.div`
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.4);
`
