import { SelectedOpt, SideBarOptions } from 'components/GeneralComponents/GeneralComponentsTypes'
import SideBar from 'components/GeneralComponents/SideBar'
import { Icons } from 'components/Icons'
import { set } from 'date-fns'
import { is } from 'date-fns/locale'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { ESubSection, TSection, TSubsection } from 'types/AnalyticsInferfaces'
import { AppStore, T } from 'utils'
import { navigate } from 'utils/router'

interface Props {
  section: TSection
  subsection: TSubsection
}

export const AnalyticsNavigation = ({ section, subsection }: Props) => {
  /* --------------------------------------------- ROUTER */
  const { reportId } = useParams()

  /* ____________________ ZUSTAND ____________________ */
  const reportPreview = useAnalyticsStore((state) => state.reportPreview)

  /* --------------------------------------------- STATE */
  const [isConditionalIsClicked, setIsConditionalIsClicked] = useState<boolean>(false)
  const [sideBarOptions, SetSideBarOptions] = useState<SideBarOptions>()

  const onSetIsConditionalClicked = () => {
    setIsConditionalIsClicked((prev) => !prev)
  }

  /* --------------------------------------------- VARIABLE - OPTION STEP  */

  // options for step navigation
  const stepOptions = {
    // dashboard option
    dashboard: {
      title: T.analytics.dashboard,
      icon: <Icons.Dashboard />,
      onSelect: {
        navigate: '/analytics',
        state: {},
        function: () => useAnalyticsStore.getState().setReportPreview(null),
      },
    },
    // dasboard new report option
    createConditional: {
      title: T.analytics.new,
      visible(obj) {
        return true
      },
      icon: <Icons.Create />,
      urlSection: '/Create',
      onSelect: { navigate: '/analytics/createReport', state: { subsection: 'setup' } },
    },
    // create report setup option
    setup: {
      title: T.analytics.setUp,
      icon: <Icons.SetUp />,
      onSelect: {
        navigate: `/analytics/createReport${
          window.location.pathname.split('/')[3] ? `/${window.location.pathname.split('/')[3]}` : ''
        }`,
        state: { subsection: 'setup' },
      },
    },
    // create report social option
    addSocial: {
      title: T.analytics.social,
      icon: <Icons.Social />,
      onSelect: {
        navigate: `/analytics/createReport${
          window.location.pathname.split('/')[3] ? `/${window.location.pathname.split('/')[3]}` : ''
        }`,
        state: { subsection: 'social' },
      },
    },
    // create report metrics option
    metrics: {
      title: T.analytics.metrics,
      icon: <Icons.Metriche />,
      onSelect: {
        navigate: `/analytics/createReport${
          window.location.pathname.split('/')[3] ? `/${window.location.pathname.split('/')[3]}` : ''
        }`,
        state: { subsection: 'metrics' },
      },
      /*  notice: showInfo(T.analytics.noSocialProfilesConnectedTo), */
    },

    // report preview option
    report: {
      title: T.analytics.preview,
      icon: <Icons.Preview />,
      onSelect: {
        ...((window.location.pathname.split('/')[3] || reportPreview) && section !== 'report'
          ? { navigate: `/analytics/report/${window.location.pathname.split('/')[3] ?? reportPreview?._id}` }
          : {}),
      },
    },
    // share and download option
    shareDownloadConditional: {
      title: T.analytics.share,
      visible(obj) {
        return true
      },
      icon: <Icons.share />,

      options: [
        {
          disabled: true,
          title: T.analytics.link,
          icon: <Icons.share />,
          position: (i) => (i === 0 ? 0 : 290),
          onSelect: {},
        },
        {
          disabled: true,
          title: T.analytics.savePdf,
          icon: <Icons.saveIcons />,
          onSelect: {
            /* function: () => AppStore.downloadPdf(useAnalyticsStore.getState().reportPreviewName) */
          },
        },
      ],
      onSelect: {
        navigate: `/analytics/report/${window.location.pathname.split('/')[3]}`,
        state: { stateConditionalClicked: 'isConditionalClicked' },
      },
    },
  }

  /* --------------------------------------------- METHODS - FUNCTION STEP  */
  // step dashboard
  const stepDashboard = () => {
    const { dashboard, createConditional } = stepOptions
    SetSideBarOptions({
      id: 'postBar',
      default: 0,
      options: [dashboard],
      conditionalOptions: [createConditional],
    })
  }
  // step create setup report
  const stepSetup = () => {
    const { dashboard, setup, addSocial, metrics, report } = stepOptions
    const optionToShow =
      reportId || reportPreview ? { options: [report, setup, addSocial, metrics] } : { options: [setup] }

    const defaultNumber = reportId || reportPreview ? 1 : 0
    SetSideBarOptions({
      ...sideBarOptions,
      default: defaultNumber,
      conditionalOptions: undefined,
      upperOptions: [dashboard],
      ...optionToShow,
    })
  }
  // step create social report
  const stepAddSocial = () => {
    const { dashboard, setup, addSocial, metrics, report } = stepOptions
    const optionToShow =
      reportId || reportPreview ? { options: [report, setup, addSocial, metrics] } : { options: [setup, addSocial] }

    const defaultNumber = reportId || reportPreview ? 2 : 1
    SetSideBarOptions({
      ...sideBarOptions,
      default: defaultNumber,
      upperOptions: [dashboard],
      conditionalOptions: undefined,
      ...optionToShow,
    })
  }
  // step create metrics report
  const stepMetrics = () => {
    const { dashboard, setup, addSocial, metrics, report } = stepOptions

    const optionToShow =
      reportId || reportPreview
        ? { options: [report, setup, addSocial, metrics] }
        : { options: [setup, addSocial, metrics] }

    const defaultNumber = reportId || reportPreview ? 3 : 2
    SetSideBarOptions({
      ...sideBarOptions,
      default: defaultNumber,
      upperOptions: [dashboard],
      conditionalOptions: undefined,
      ...optionToShow,
    })
  }
  // step report preview
  const stepReportLoading = () => {
    const { dashboard, report } = stepOptions
    SetSideBarOptions({
      ...sideBarOptions,
      default: 0,
      upperOptions: [dashboard],
      options: [report],
      conditionalOptions: [],
    })
  }

  const stepReport = () => {
    const { dashboard, setup, addSocial, metrics, report, shareDownloadConditional } = stepOptions
    SetSideBarOptions({
      ...sideBarOptions,
      default: 0,
      upperOptions: [dashboard],
      options: [report, setup, addSocial, metrics],
      conditionalOptions: [shareDownloadConditional],
    })
  }

  /* --------------------------------------------- USEEFFECT  */

  useEffect(() => {}, [])

  useEffect(() => {
    const steps = [
      { section: 'analytics', subsection: 'setup', function: stepDashboard },
      { section: 'createReport', subsection: 'setup', function: stepSetup },
      { section: 'createReport', subsection: 'social', function: stepAddSocial },
      { section: 'createReport', subsection: 'metrics', function: stepMetrics },
      { section: 'report', subsection: 'loading', function: stepReportLoading },
      { section: 'report', subsection: 'setup', function: stepReport },
    ]

    const stepSelected = steps.findIndex((s) => s.section === section && s.subsection === subsection)

    steps[stepSelected].function()
  }, [section, subsection])

  return sideBarOptions ? (
    <SideBar
      options={sideBarOptions}
      subOptionSelected={ESubSection[subsection]}
      conditionalClicked={isConditionalIsClicked ? 1 : undefined}
      whatPage="analytics"
      setIsConditionalIsClicked={onSetIsConditionalClicked}
    />
  ) : (
    <></>
  )
}
