import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AnalyticsDate, IReport, IReportConfig } from 'types/AnalyticsInferfaces'
import { useAnalyticsStore } from 'stores/AnalyticsStore'
import { SpinnerAstersLogo } from 'components/UI'
import { getReport } from 'utils/AnalyticsUtils'
import RenderReport from './RenderPreviewReport/RenderReport'
import Reports from 'api/Reports'
import { T, showError, showInfo } from 'utils'
import { get } from 'http'

/* ___________________ INTERFACE ___________________ */

interface Props {
  selectedDates?: AnalyticsDate | null
}

export const PreviewReport = ({ selectedDates }: Props) => {
  /* ____________________ ROUTER ____________________ */
  const location = useLocation()

  const newReportConfig = location.state?.newReportConfig ?? undefined
  const idToModifyReport = location.state?.idToModifyReport ?? undefined
  const [reportPreview, setStateReportPreview] = useState<IReportConfig | null>(
    useAnalyticsStore.getState().reportPreview
  )
  const { reportId } = useParams()
  const navigate = useNavigate()

  /* ____________________ REF ____________________ */

  const intervalRef = useRef<NodeJS.Timeout | null>(null)

  /* ____________________ ZUSTAND ____________________ */
  const { setReportPreview, onSetReportName } = useAnalyticsStore.getState()

  const unsubReport = useAnalyticsStore.subscribe((state) => {
    state.reportPreview !== reportPreview && setStateReportPreview(state.reportPreview)
  })

  /* ____________________ API CALL ____________________ */
  const createOrUpdateReport = async () => {
    let report: IReport | '' = ''

    try {
      report = idToModifyReport
        ? await Reports.updateReport({ ...newReportConfig, reportId: idToModifyReport })
        : await Reports.createReport(newReportConfig)
    } catch (err) {
      showError(T.error.generic_error)
      navigate('/analytics')
      return
    }

    if (!window.location.pathname.split('/')[2]) {
      const reportsList = await Reports.getReportList()

      useAnalyticsStore.getState().setReportList(reportsList)
      showInfo(
        `${T.analyticsPage.yourReportNotice} "${(report as IReport).info.reportName}" ${
          T.analyticsPage.yourReportNoticeIsReady
        }`
      )

      return
    }

    report && navigate(`/analytics/report/${report?._id}`, { replace: true, state: { subsection: 'setup' } })
    report && getApiReport(report._id)
  }

  const getApiReport = async (id: string | undefined = undefined) => {
    const idReport = reportId ?? id

    const addReport: any = await getReport(idReport)

    if (!addReport) {
      intervalRef.current = setTimeout(() => getApiReport(idReport), 10000)
      return
    } else {
      navigate(`/analytics/report/${idReport}`, { replace: true, state: { subsection: 'setup' } })

      intervalRef.current = null
    }

    if (!reportPreview || reportPreview._id !== idReport) {
      setTimeout(() => {
        onSetReportName(addReport.info.reportName!)
        setReportPreview(addReport)
      }, 200)
    }
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    if (newReportConfig) createOrUpdateReport()
    else if (reportId) {
      !reportPreview && navigate(`/analytics/report/${reportId}`, { replace: true, state: { subsection: 'loading' } })

      getApiReport()
    } else navigate('/analytics')

    return () => {
      unsubReport()
    }
  }, [])

  return reportPreview && reportPreview._id === reportId ? (
    <RenderReport report={reportPreview} />
  ) : (
    <SpinnerAstersLogo dimension={100} />
  )
}
