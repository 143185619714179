/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Icons } from 'components'
import { useEffect, useState } from 'react'
import { AppStore, T } from 'utils'
import { CloseButton } from './CloseButton'

/* ------------------- INTERFACES  */
export interface IItemList {
  name: string
  action: () => void // Azione che va a compiersi al click dell'item
}

interface Props {
  icon: JSX.Element // Icona del dropdown
  listItems: IItemList[] // Lista degli item del dropdown
  title: string // Titolo del dropdown
  setTitle: (newTitle: string) => void // Funzione per settare il titolo del dropdown
  hasValue: boolean // Valore per indicare se c'è un valore selezionato nel dropdown
  handleReset: (e?: Event) => void // Funzione per gestire il reset del valore
  isDisabled?: boolean // Valore per indicare se il dropdown ha un stato di disabilitazione
  disabledText?: string // Testo tooltip per indicare la motivazione per la quale il dropdwon è disabilitato
  dynamicOpenWidth: boolean // Valore per indicare se il dropdown ha un width dinamico
  setDynamicOpenWidth: (newState) => void // Funzione per settare il valore di dynamicOpenWidth
}

export const AnimatedDropdown = ({
  icon,
  listItems,
  title,
  setTitle,
  hasValue,
  handleReset,
  isDisabled,
  disabledText,
  dynamicOpenWidth,
  setDynamicOpenWidth,
}: Props) => {
  /* ----------------- STATE  */
  const [openWidth, setOpenWidth] = useState<boolean>(false)
  const [openDropdown, setOpenDropdown] = useState<boolean>(false)

  /* ----------------- VARIABLES  */
  //Questa variabile serve per definire se utilizzare "openWidth" o "dynamicOpenWidth"
  const useDynamicOpenWidth: boolean = dynamicOpenWidth !== undefined

  // Variabili per condizioni di stile
  const conditionalActiveClass: string = isDisabled && isDisabled ? 'dropdown_disabled' : ''
  const isOpenWidthClass: string = dynamicOpenWidth ? 'isOpen' : openWidth ? 'isOpen' : ''
  const hasValueClass: string = hasValue ? 'dropdown_has_value' : ''

  /* ----------------- METHODS  */
  const handleOnClickDropdown = () => {
    if (!isDisabled) {
      if (useDynamicOpenWidth) {
        setDynamicOpenWidth((prev) => !prev)
      } else {
        setOpenWidth((prev) => !prev)
      }
      setOpenDropdown((prev) => !prev)
    }
  }

  const handleOnClickDropdownItem = async (e: Event, item: IItemList) => {
    e.stopPropagation()
    setOpenDropdown(false)
    setTitle(item.name)
    item.action()

    // Condizione di "whitelistaggio" per mantenere il dropdown openWidth attivo una volta avviata l'action
    if (item.name !== T.analytics.customize) {
      setOpenWidth(false)
    }
  }

  /* ----------------- USEEFFECT  */
  // Se si resetta il valore di openWidth quando il valore è disabilitato
  useEffect(() => {
    if (useDynamicOpenWidth) {
      setDynamicOpenWidth(false)
    } else {
      setOpenWidth(false)
    }
  }, [isDisabled])

  return (
    <AnimatedDropdownContainer
      className={`
      dropdown
      ${hasValueClass}
      ${conditionalActiveClass}
      ${isOpenWidthClass}
      `}
      onClick={handleOnClickDropdown}
      title={`${isDisabled ? disabledText : ''}`}
    >
      {/* Icona del dropdown */}
      <IconDropdown className="dropdown_icon">{icon}</IconDropdown>

      {/* Testo del dropdown */}
      <ActiveDropdownText className="dropdown_active_text dropdown_conditional_show">{title}</ActiveDropdownText>

      {/* Iconde arrow */}
      <IconArrow
        className={`dropdown_icon_arrow dropdown_conditional_show ${
          openDropdown ? 'arrow_point_bottom' : 'arrow_point_top'
        }`}
      >
        <Icons.dropdownArrow fill={AppStore.theme.o.black} />
      </IconArrow>

      {/* Divider per spazio */}
      <Divider className="dropdown_divider" />

      {/* Container degli item del dropdown */}
      <ContainerFilters className={`dropdown_container dropdown_conditional_show ${openDropdown ? 'open' : ''}`}>
        {listItems.map((item) => (
          <DropdownFilterButton
            onClick={(e) => {
              handleOnClickDropdownItem(e, item)
            }}
            className="dropdown_button"
          >
            {item.name}
          </DropdownFilterButton>
        ))}
      </ContainerFilters>

      {hasValue && <CloseButton action={(e) => handleReset(e)} />}
    </AnimatedDropdownContainer>
  )
}

const AnimatedDropdownContainer = styled(Box)`
  &.dropdown_has_value {
    background: ${() => AppStore.theme.o.lightGreen} !important;
    border: 1px solid ${() => AppStore.theme.o.green} !important;
    color: ${() => AppStore.theme.o.green} !important;
  }

  &.dropdown {
    position: relative;
    padding: 16px 8px;
    height: 38px;
    width: 41px;
    cursor: pointer;
    border-radius: 14px;
    border: 1px solid ${() => AppStore.theme.o.grey};
    background: ${() => AppStore.theme.o.lightestGrey};
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: ${() => AppStore.theme.smallBoxShadow};
    color: ${() => AppStore.theme.o.black};

    .dropdown_conditional_show {
      transition: 0ms;
      transition-delay: 0ms;
      visibility: 0;
      opacity: 0;
      pointer-events: none;
    }

    &:hover,
    &:active {
      width: 230px;

      .dropdown_conditional_show {
        transition: 300ms ease-in-out;
        transition-delay: 300ms;
        visibility: 1;
        opacity: 1;
        pointer-events: auto;
      }
    }

    &.dropdown_disabled {
      cursor: not-allowed;

      &:hover,
      &:active {
        width: 41px;

        .dropdown_conditional_show {
          transition: 0ms;
          visibility: 0;
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    &.isOpen {
      width: 230px;

      .dropdown_conditional_show {
        transition: 300ms ease-in-out;
        transition-delay: 300ms;
        visibility: 1;
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
`

const IconDropdown = styled(Box)``

const ActiveDropdownText = styled(Box)`
  &.dropdown_active_text {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 4px;
  }
`

const IconArrow = styled(Box)`
  &.dropdown_icon_arrow {
    margin-left: auto;

    &.arrow_point_bottom {
      rotate: 0deg;
    }

    &.arrow_point_top {
      rotate: -180deg;
    }
  }
`

const Divider = styled(Box)`
  &.dropdown_divider {
    background: transparent;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 8px;
  }
`

const ContainerFilters = styled(Box)`
  &.dropdown_container {
    box-shadow: ${() => AppStore.theme.smallBoxShadow};
    border-radius: 14px;
    background: ${() => AppStore.theme.o.lightestGrey};
    padding: 0;
    width: 100%;
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
    max-height: 0;

    &.open {
      padding: 8px;
      max-height: 500px;
    }
  }
`

const DropdownFilterButton = styled(Box)`
  &.dropdown_button {
    color: ${() => AppStore.theme.o.black};
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 16px;
    border: 1px solid transparent;

    &:hover {
      background: ${() => AppStore.theme.o.surface};
      border: 1px solid ${() => AppStore.theme.o.grey};
    }
  }
`
