import { Box, Icons, Text } from 'components'
import { T } from 'translation/i18n'
import AccountSection from 'pages/settings/AccountSection'
import SocialProfileSection from 'pages/settings/SocialProfileSection'
import SubscriptionSection from 'pages/settings/SubscriptionSection'
import WorkspaceSection from 'pages/settings/WorkspaceSection'
import AppStore from './AppStore'
import { getSizeZoomed } from 'utils'
import { Days } from 'pages/settings/SocialProfile/Days'
import { TimePicker } from 'antd'
import moment from 'moment'
import styled from '@emotion/styled'

const settingTabs = [
  {
    label: T.settings.account,
    path: 'account',
    icon: Icons.profile,
    component: <AccountSection />,
  },
  {
    label: T.settings.profileSocial,
    path: 'social',
    icon: Icons.socialProfile,
    component: <SocialProfileSection />,
  },
  {
    label: T.settings.environment,
    path: 'workspace',
    icon: Icons.workSpaces,
    component: <WorkspaceSection />,
  },
  {
    label: T.settings.subscription,
    path: 'subscription',
    icon: Icons.subscription,
    component: <SubscriptionSection />,
  },
]

// ------------------------ MODAL
const modifyModal = () =>
  AppStore.openModal({
    id: 'modifyModal',
    style: { width: getSizeZoomed(50, 'vw') },
    body: (
      <>
        {/* ------------------------- EXAMPLES ------------------------- */}

        <Box>
          <Box height={getSizeZoomed(10, 'vh')} style={{ marginLeft: 50, marginRight: 50 }} flex vcenter row>
            <Days />
            <TimePicker defaultValue={moment('00:00:00', 'HH:mm:ss')} />
          </Box>
          <Box height={getSizeZoomed(10, 'vh')} style={{ marginLeft: 50, marginRight: 50 }} flex vcenter row>
            <Days />
            <TimePicker defaultValue={moment('00:00:00', 'HH:mm:ss')} />
          </Box>
          <Box height={getSizeZoomed(10, 'vh')} style={{ marginLeft: 50, marginRight: 50 }} flex vcenter row>
            <Days />
            <TimePicker defaultValue={moment('00:00:00', 'HH:mm:ss')} />
          </Box>
          <Box height={getSizeZoomed(10, 'vh')} style={{ marginLeft: 50, marginRight: 50 }} flex vcenter row>
            <Days />
            <TimePicker defaultValue={moment('00:00:00', 'HH:mm:ss')} />
          </Box>
          <Box height={getSizeZoomed(10, 'vh')} style={{ marginLeft: 50, marginRight: 50 }} flex vcenter row>
            <Days />
            <TimePicker defaultValue={moment('00:00:00', 'HH:mm:ss')} />
          </Box>
        </Box>

        {/* ------------------------- CLICK TO NEW ONE ------------------------- */}
        <Box>
          <Box vcenter justify={'space-evenly'} flex row ml={18} mr={18}>
            <IconContainer
              row
              hideBoxShadowOnHover
              center
              style={{
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 24,
                paddingRight: 24,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <Text onClick={modifyModal}>{T.settings.modify}</Text>
              <Icons.edit />
            </IconContainer>
          </Box>
        </Box>
      </>
    ),
  })

// style per la modale del modifymodal
const IconContainer = styled(Box)`
  border-radius: 14px;
  transition: background-color 0.5s ease-in-out;
  :hover {
    background-color: ${() => AppStore.theme.o.background};
  }
`

export {
  settingTabs,

  // --------------- MODAL
  modifyModal,
}
