import { IFilter } from 'types'
import { T } from 'utils'

/**
 * Social filters Array
 */
export const socialFilters: IFilter[] = [
  { key: 'all', name: T.status.all, icon: 'analytics' },
  { key: 'instagram', name: T.common.socials.instagram, icon: 'instagram' },
  { key: 'facebook', name: T.common.socials.facebook, icon: 'facebook' },
  { key: 'youtube', name: T.common.socials.youtube, icon: 'youtube' },
  { key: 'tiktok', name: T.common.socials.tiktok, icon: 'tiktok' },
  { key: 'linkedin', name: T.common.socials.linkedin, icon: 'linkedin' },
  { key: 'x', name: T.common.socials.xTwitter, icon: 'x' },
]
