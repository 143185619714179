import { T } from 'utils'

export type Promo = {
  text: string
  type: 'monthly' | 'annual'
  label: string
  percentage: number
  cost: number
  slogan: string
}

export type SubscriptionOption = {
  id: number
  label: string
  active?: boolean
  price: {
    annual: number
    monthly: number
  }
  sconto: number
  stripePrice: {
    annual: string
    monthly: string
  }
  services: Service[]
  offer: Promo
}

export type ToolTip = {
  note?: string
  title?: string
  text?: string
}

export type Service = {
  key: string
  label: string
  subLabelText?: string
  description?: string
  quantity?: number | undefined
  disabled?: boolean
  comingSoon?: boolean
  tooltip?: ToolTip
  serviceType?: 'base' | 'complete'
  bold?: boolean
}

export interface CustomizeServiceOption extends Service {
  stripePriceLabel?: string
  price?: number
  addQuantity?: number
}

const planDifferences = {
  professional: {
    quantity: {
      socials: 50,
      members: 5,
      storage: 10,
      copies: 5000,
      images: 2500,
      aiDiscover: 18000,
      autoPilot: 150,
    },
    serviceType: {
      analytics: 'base',
      inbox: 'base',
    },
  },
  business: {
    quantity: {
      socials: 150,
      members: 15,
      storage: 10,
      copies: 5000,
      images: 2500,
      aiDiscover: 18000,
      autoPilot: 150,
    },
    serviceType: {
      analytics: 'base',
      inbox: 'base',
    },
  },
  premium: {
    quantity: {
      socials: 300,
      members: 15,
      storage: 15,
      copies: -1, //illimitate
      images: 6000,
      aiDiscover: 42000,
      autoPilot: 300,
    },
    serviceType: {
      analytics: 'complete',
      inbox: 'complete',
    },
  },
  enterprise: {
    quantity: {
      socials: 300,
      members: 15,
      storage: 15,
      aiDiscover: 42000,
      autoPilot: 300,
    },
    serviceType: {
      analytics: 'complete',
      inbox: 'complete',
    },
  },
}

const commonServices = [
  {
    key: 'socials',
    label: T.plans.professionist.profileSocial,
    tooltip: {
      note: '',
      title: T.plans.tooltips.profileSocial.title,
      text: T.plans.tooltips.profileSocial.text,
    },
  },
  {
    key: 'members',
    label: T.plans.professionist.teamMembers,
    tooltip: {
      note: '',
      title: T.plans.tooltips.teamMembers.title,
      text: T.plans.tooltips.teamMembers.text,
    },
  },
  {
    key: 'allSocials',
    label: T.plans.professionist.allSocial,
    tooltip: {
      note: T.plans.tooltips.allSocial.note,
      title: T.plans.tooltips.allSocial.title,
      text: T.plans.tooltips.allSocial.text,
    },
  },
  {
    key: 'storage',
    label: T.plans.professionist.extraStorege,
    subLabelText: 'GB',
    tooltip: {
      note: T.plans.tooltips.extraStorage.note,
      title: T.plans.tooltips.extraStorage.title,
      text: T.plans.tooltips.extraStorage.text,
    },
  },
  {
    key: 'copies',
    label: T.plans.professionist.generationText,
    tooltip: {
      note: T.plans.tooltips.generationText.note,
      title: T.plans.tooltips.generationText.title,
      text: T.plans.tooltips.generationText.text,
    },
  },
  {
    key: 'whatsapp',
    label: T.plans.professionist.whatsapp,
    disabled: true,
    tooltip: {
      note: T.plans.tooltips.whatsapp.note,
      title: T.plans.tooltips.whatsapp.title,
      text: T.plans.tooltips.whatsapp.text,
    },
  },
  {
    key: 'images',
    label: T.plans.professionist.generationImage,
    tooltip: {
      note: T.plans.tooltips.generationImage.note,
      title: T.plans.tooltips.generationImage.title,
      text: T.plans.tooltips.generationImage.text,
    },
  },
  {
    key: 'analytics',
    label: T.plans.professionist.analytics,
    comingSoon: true,
    tooltip: {
      note: T.plans.tooltips.analytics.note,
      title: T.plans.tooltips.analytics.title,
      text: T.plans.tooltips.analytics.text,
    },
  },
  {
    key: 'aiDiscover',
    label: T.plans.professionist.aiDiscover,
    subLabelText: T.plans.articles,
    tooltip: {
      note: T.plans.tooltips.aiDiscover.note,
      title: T.plans.tooltips.aiDiscover.title,
      text: T.plans.tooltips.aiDiscover.text,
    },
  },
  {
    key: 'inbox',
    label: T.plans.professionist.inbox,
    comingSoon: true,
    tooltip: {
      note: T.plans.tooltips.inbox.note,
      title: T.plans.tooltips.inbox.title,
      text: T.plans.tooltips.inbox.text,
    },
  },
  {
    key: 'ai-time-post',
    label: T.plans.professionist.bestTimeToPost,
    tooltip: {
      note: T.plans.tooltips.bestTimeToPost.note,
      title: T.plans.tooltips.bestTimeToPost.title,
      text: T.plans.tooltips.bestTimeToPost.text,
    },
  },
  {
    key: 'advertising',
    label: T.plans.professionist.advertising,
    comingSoon: true,
    tooltip: {
      note: T.plans.tooltips.advertising.note,
      title: T.plans.tooltips.advertising.title,
      text: T.plans.tooltips.advertising.text,
    },
  },
  {
    key: 'quick-search',
    label: T.plans.professionist.quickSearch,
    tooltip: {
      note: T.plans.tooltips.quickSearch.note,
      title: T.plans.tooltips.quickSearch.title,
      text: T.plans.tooltips.quickSearch.text,
    },
  },
  {
    key: 'autoPilot',
    label: T.plans.professionist.autoPilot,
    subLabelText: 'Tasks',
    comingSoon: true,
    tooltip: {
      note: T.plans.tooltips.autoPilot.note,
      title: T.plans.tooltips.autoPilot.title,
      text: T.plans.tooltips.autoPilot.text,
    },
  },
]

const professionalServices: Service[] = commonServices.map((service: Service) => ({
  ...service,
  quantity: planDifferences.professional.quantity[service.key] || undefined,
  serviceType: planDifferences.professional.serviceType[service.key] || undefined,
  tooltip: {
    ...service.tooltip,
    note: planDifferences.professional.quantity[service.key] || undefined,
  },
}))

const businessServices: Service[] = commonServices.map((service: Service) => ({
  ...service,
  quantity: planDifferences.business.quantity[service.key] || undefined,
  serviceType: planDifferences.professional.serviceType[service.key] || undefined,

  tooltip: {
    ...service.tooltip,
    note: planDifferences.professional.quantity[service.key] || undefined,
  },
}))

const premiumServices: Service[] = commonServices.map((service: Service) => ({
  ...service,
  quantity: planDifferences.premium.quantity[service.key] || undefined,
  serviceType: planDifferences.premium.serviceType[service.key] || undefined,
  tooltip: {
    ...service.tooltip,
    note: planDifferences.professional.quantity[service.key] || undefined,
  },
}))

const enterpriseServices: Service[] = commonServices.map((service: Service) => ({
  ...service,
  quantity: planDifferences.enterprise.quantity[service.key] || undefined,
  serviceType: planDifferences.premium.serviceType[service.key] || undefined,
  comingSoon: false,
  tooltip: {
    ...service.tooltip,
    note: planDifferences.professional.quantity[service.key] || undefined,
  },
}))

export class SubscriptionProvider {
  static billableOptions: SubscriptionOption[] = [
    //PROFESSIONAL
    {
      id: 0,
      label: T.plans.professionist.professional,
      stripePrice: {
        monthly: 'STRIPE_PRO_MONTHLY_PLAN_ID',
        annual: 'STRIPE_PRO_ANNUAL_PLAN_ID',
      },
      price: {
        annual: 82,
        monthly: 99,
      },
      sconto: 17,
      services: professionalServices,
      offer: {
        text: '17% off',
        type: 'annual',
        label: T.plans.professionist.offer.label,
        percentage: 17,
        cost: 165,
        slogan: '',
      },
    },
    //BUSINESS
    {
      id: 1,
      label: T.plans.corporate.corporate,
      stripePrice: {
        monthly: 'STRIPE_BUSINESS_MONTHLY_PLAN_ID',
        annual: 'STRIPE_BUSINESS_ANNUAL_PLAN_ID',
      },
      price: {
        annual: 165,
        monthly: 199,
      },
      sconto: 17,
      services: businessServices,
      offer: {
        text: '17% off',
        type: 'annual',
        label: T.plans.professionist.offer.label,
        percentage: 17,
        cost: 266,
        slogan: '',
      },
    },
    //PREMIUM
    {
      id: 2,
      label: T.plans.premium.premium,
      stripePrice: {
        monthly: 'STRIPE_AGENCY_MONTHLY_PLAN_ID',
        annual: 'STRIPE_AGENCY_ANNUAL_PLAN_ID',
      },
      price: {
        annual: 290,
        monthly: 349,
      },
      sconto: 17,
      services: premiumServices,
      offer: {
        text: '17% off',
        type: 'annual',
        label: T.plans.professionist.offer.label,
        percentage: 17,
        cost: 410,
        slogan: '',
      },
    },

    //ENTERPRISE
    {
      id: 3,
      label: T.plans.enterprise.enterprise,
      stripePrice: {
        monthly: 'STRIPE_ENTERPRISE_MONTHLY_PLAN_ID',
        annual: 'STRIPE_ENTERPRISE_ANNUAL_PLAN_ID',
      },
      price: {
        annual: 0,
        monthly: 0,
      },
      sconto: 17,
      services: enterpriseServices,
      offer: {
        text: '17% off',
        type: 'annual',
        label: T.plans.professionist.offer.label,
        percentage: 17,
        cost: 410,
        slogan: '',
      },
    },
  ]

  static customizableOptions: CustomizeServiceOption[] = [
    {
      stripePriceLabel: 'STRIPE_5_STORAGE',
      label: T.additionalOptions.storage.lable,
      description: T.additionalOptions.storage.description,
      key: 'storage',
      quantity: 0,
      addQuantity: 3,
      price: 2,
    },
    {
      stripePriceLabel: 'STRIPE_1_EXTRA_SOCIAL',
      label: T.additionalOptions.extraSocial.lable,
      description: T.additionalOptions.extraSocial.description,
      key: 'socials',
      quantity: 0,
      addQuantity: 1,
      price: 2,
    },
    {
      stripePriceLabel: 'STRIPE_10_EXTRA_CAPTIONS',
      label: T.additionalOptions.extraCaptions.lable,
      description: T.additionalOptions.extraSocial.description,
      key: 'copies',
      quantity: 0,
      addQuantity: 10,
      price: 3,
    },
    {
      stripePriceLabel: 'STRIPE_10_EXTRA_HASHTAG',
      label: T.additionalOptions.extraHashtag.lable,
      description: T.additionalOptions.extraSocial.description,
      key: 'socials',
      quantity: 0,
      addQuantity: 10,
      price: 2,
    },
  ]

  static subscriptionSteps = [
    { key: 'choose', label: T.subscriptionSteps.step1, done: true, active: true },
    { key: 'customization', label: T.subscriptionSteps.step2, done: false, active: false },
    { key: 'checkout', label: T.subscriptionSteps.step3, done: false, active: false },
  ]
}
