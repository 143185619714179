import styled from '@emotion/styled'
import { Box, Text } from 'components'
import React, { Component } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'

interface Props {
  price?: any
  subscriptionMode: any
  noSubLine?: boolean
  label: string
  subTitle?: boolean
}

const TotalShow = ({ price, subscriptionMode, noSubLine, label, subTitle }: Props) => {
  const noPropPrice = subscriptionMode === 'monthly' ? price : Math.floor(price * 12)

  return (
    <Box>
      {noSubLine === undefined && <Separator />}
      <Box flex row>
        <Box vcenter flex>
          <Text fontSize={16} weight={700} color={subTitle ? AppStore.theme.o.darkGrey : null}>
            {label}
          </Text>
        </Box>
        <Box>
          <Text fontSize={subTitle ? 18 : 28} color={subTitle ? AppStore.theme.o.darkGrey : null}>
            {(Math.round(price * 100) / 100).toFixed(2)}€
            {subTitle === undefined && (
              <>{subscriptionMode === 'monthly' ? `/${T.settings.month}` : `/${T.settings.year}`}</>
            )}
          </Text>
        </Box>
      </Box>
      {noSubLine === undefined && <Separator />}
    </Box>
  )
}

export default TotalShow

const Separator = styled(Box)`
  background-color: ${() => AppStore.theme.o.darkGrey};
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
`
