import { Box } from './Box'
import { HeaderUpgradeButton } from './HeaderUpgradeButton'
import { HeaderButtonEarn } from './HeaderButtonEarn'
import { HeaderButtonWithOptions } from './HeaderButtonWithOptions'
import { HeaderButtonSettings } from './HeaderButtonSettings'
import NotifyOnHeader from './headerComponents/NotifyOnHeader'
import { AppStore, getDaysDiff, T } from 'utils'
import { Text } from './Text'
import { Button } from './Button'
import { Icons } from './Icons'
import { HeaderAutopilotButton } from './HeaderAutopilotButton'
import { isVisible } from 'utils/CheckVisibleElement'
import { RemoteConfigValues } from 'api/Utils'

interface Props {
  title?: string
}

export const HeaderAllButtons = ({ title }: Props) => {
  return (
    <Box row vcenter style={{ zIndex: 1, gap: 8 }}>
      {/* //! Comment for AS-483 */}
      {!AppStore?.workspace?.owner?.enterprise && (
        <>
          {AppStore.loggedUser?.freeTrial && !AppStore.loggedUser.rawPlan && title !== 'Post' && (
            <Text
              style={{ marginLeft: 16 }}
              fontSize={16}
              weight={700}
              color={
                getDaysDiff(AppStore.loggedUser?.freeTrial) * -1 <= 7 ? AppStore.theme.o.red : AppStore.theme.o.yellow
              }
            >
              {T.postPage.freeTrial}
              {getDaysDiff(AppStore.loggedUser?.freeTrial) * -1 < 0
                ? 0
                : getDaysDiff(AppStore.loggedUser?.freeTrial) * -1}
              {T.postPage.remainingDayes}
            </Text>
          )}
        </>
      )}

      {!AppStore?.workspace?.owner?.enterprise && title !== 'Post' && <HeaderUpgradeButton />}

      {!AppStore?.workspace?.owner?.enterprise && title !== 'Post' && <HeaderButtonEarn />}

      {/* -------------- BUTTON AUTOPILOT -------------- */}
      {isVisible(RemoteConfigValues.AutopilotButtonDisabled) && <HeaderAutopilotButton />}

      <HeaderButtonWithOptions />

      {isVisible(RemoteConfigValues.NotificationDisabled) && <NotifyOnHeader />}

      <HeaderButtonSettings />
    </Box>
  )
}
