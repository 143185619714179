import styled from '@emotion/styled'
import { Box } from 'components'
import { Icons } from 'components/Icons'
import { useState } from 'react'
import { useMenuNavigationStore } from 'stores/MenuNavigationStore'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'

interface Props {
  date: string
  goToPost?: (bol: boolean) => void
}

export function CalendarAddPostPlaceholder({ date, goToPost }: Props) {
  const [hover, sethover] = useState(false)

  const goToCreatePost = () => {
    goToPost && goToPost(true)
    AppStore.closeAllModal()
  }

  return (
    <Box style={{ marginLeft: 24, marginRight: 24 }} shadow="none" flex hcenter>
      <Container
        onMouseEnter={() => sethover(true)}
        onMouseLeave={() => sethover(false)}
        onClick={goToCreatePost}
        style={{ border: `1px solid ${AppStore.theme.o.grey}`, boxShadow: 'none', width: 208, height: 208 }}
      >
        <Icons.add
          style={{
            height: 33,
            width: 33,
            transition: '0.3s',
          }}
          fill={hover ? AppStore.theme.o.black : AppStore.theme.o.black}
        />
      </Container>
      <Text>{T.postPage.createPost}</Text>
    </Box>
  )
}

const Container = styled.div<{ heigth?: any; width?: any; radius?: any }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width ?? '208px'};
  height: ${({ heigth }) => heigth ?? '208px'};
  border-radius: ${({ radius }) => radius ?? '14px'};
  margin-bottom: 12px;
  background: ${() => AppStore.theme.o.background};
  transition: 0.5s;
  cursor: pointer;
  ${() => AppStore.theme.calendarCardBoxShadow};
  &:hover {
    background-color: ${AppStore.theme.o.blueOnLight};
    border: 1px solid ${() => AppStore.theme.o.blue};
  }
`

const Text = styled.p`
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`
