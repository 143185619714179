import { SettingsPage } from 'pages'
import CourtesyPage from 'pages/courtesy/CourtesyPage'

const SettingsRouter = [
  {
    path: '/settings/account',
    element: <SettingsPage />,
  },
  {
    path: '/settings/social',
    element: <SettingsPage />,
  },
  {
    path: '/settings/social/x',
    element: <SettingsPage />,
  },
  {
    path: '/settings/social/twitter',
    element: <SettingsPage />,
  },
  {
    path: '/settings/social/linkedin',
    element: <SettingsPage />,
  },
  {
    path: '/settings/social/youtube',
    element: <SettingsPage />,
  },
  {
    path: '/settings/workspace',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/management',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/api-keys',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/api-keys/:workspaceId',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/management/:workspaceId',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/permissions',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/notification',
    element: <SettingsPage />,
  },
  {
    path: 'settings/workspace/notification/:workspaceId',
    element: <SettingsPage />,
  },
  {
    path: '/settings/subscription',
    element: <SettingsPage />,
  },
  {
    path: '/settings/subscription/:subsection',
    element: <SettingsPage />,
  },
  {
    path: '/settings/subscription/trial-expired',
    element: <CourtesyPage />,
  },
]

export { SettingsRouter }
