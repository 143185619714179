import { FolderOutlined } from '@ant-design/icons'
import Medias from 'api/Medias'
import { Tracker } from 'api/Tracker'
import { Box } from 'components/Box'
import { Button } from 'components/Button'
import { Input } from 'components/Input'
import React, { useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { TRACKEREVENTS } from 'utils/eventsTracker'

export const FolderModal: React.FC<{
  onConfirm?: () => void
  path?: string
  media?: string
  moreSocials?: any
}> = ({ path, onConfirm, media, moreSocials }) => {
  const [loading, setloading] = useState(false)
  const [name, setName] = useState('')

  const onSubmit = async (e) => {
    const checking = await !AppStore.checkPermission('manage_folders')
    if (checking) {
    } else {
      if (moreSocials !== undefined) {
        moreSocials.forEach((mediaToRename, index) => {
          if (index !== 0) Medias.rename(mediaToRename._id, `${name}-(${index - 1})`)
        })
      }
      if (media === undefined) {
        if (loading) return
        setloading(true)
        try {
          e.preventDefault()
          if (path === undefined) return
          await Medias.createFolder({ path, name })
          Tracker.trackEvent(TRACKEREVENTS.CREATE_FOLDER_COMPLETED, {})
          onConfirm?.()
          AppStore.closeModal('folder-modal')
        } catch (error) {}
        setloading(false)
      } else {
        // media è il id dell media

        Medias.rename(media, name)
      }
    }
  }

  return (
    <Box row flex vcenter p={50}>
      <form style={{ display: 'flex', flex: 1 }} onSubmit={onSubmit}>
        <Box justify={'space-evenly'} flex>
          <Input
            width={'30vw'}
            autoFocus
            prefix={<FolderOutlined style={{ color: AppStore.theme.o.black }} />}
            style={{ marginBottom: 20 }}
            defaultValue={name}
            placeholder={T.mediaPage.typeHere}
            onChange={setName}
          />
          <Button loading={loading} size="small" variant="neutral" type="submit">
            {media === undefined ? T.mediaPage.create : T.mediaPage.rename}
          </Button>
        </Box>
      </form>
    </Box>
  )
}
