import styled from '@emotion/styled'
import React from 'react'
import { Box } from './Box'
import { AppStore, T } from 'utils'
import { useNavigate } from 'react-router-dom'

export const HeaderUpgradeButton = () => {
  const navigate = useNavigate()
  return (
    <UpgradeButton
      id="upgrade-button"
      hideBoxShadowOnHover
      pv={8}
      ph={32}
      center
      onClick={() => navigate('/settings/subscription', { state: { from: 'header' } })}
    >
      {T.header.increasePlan}
    </UpgradeButton>
  )
}

const UpgradeButton = styled(Box)`
  background: ${AppStore.theme.o.yellow};
  border-radius: 14px;
  font-weight: 800;
  font-size: 21px;
  color: ${() => AppStore.theme.o.surface};
  :hover {
    background: ${AppStore.theme.o.yellow};
  }
`
