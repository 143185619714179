import styled from '@emotion/styled'
import Notify from 'api/Notify'
import { Box } from 'components/Box'
import { useEffect, useRef, useState } from 'react'
import { useNotifyStore } from 'stores/NotifyStore'
import { AppStore } from 'utils'
import NotifyCard from './NotifyCard'
import NotifyEmpty from './NotifyEmpty'

/* ___________________ INTERFACE ___________________ */
interface Props {
  filters: { activeCategory: string[]; onlyUnread: boolean }
}

const ScrollNotifyContainer = ({ filters }: Props) => {
  /* ____________________ REF ____________________ */
  const scrollNotifyRef = useRef<HTMLDivElement>(null)

  /* ____________________ VARIABLES ____________________ */
  const userId = AppStore.loggedUser._id
  // const [workspaceNotifications, setWorkspaceNotifications] = useState<INotifications[]>(
  //   AppStore.workspaceNotifications
  // )

  // const { globalPost } = useAbbalabaStore.getState()

  const { addNotificationReaded } = useNotifyStore.getState()

  const { workspaceNotifications } = useNotifyStore((s) => ({
    workspaceNotifications: s.workspaceNotifications,
  }))

  const [firstLoad, setFirstLoad] = useState(true)

  /* ____________________ FILTERS ____________________ */
  const filteredNotifications = workspaceNotifications
    .filter((n) => {
      const notifyisReaded = n?.notificationReaded?.find((el) => el === userId)

      if (!filters.onlyUnread) {
        return n
      }
      if (filters.onlyUnread && notifyisReaded) return
      if (filters.onlyUnread && !notifyisReaded) return n
    })
    .filter((notice) => {
      if (filters.activeCategory.length === 0) return notice
      return filters.activeCategory.includes(notice.category.toLowerCase())
    })

  const hasFilteredNotifications = filteredNotifications.length > 0
  /* ____________________ METHODS ____________________ */
  /**
   * utilizzato per aggiungere il padding-right e la scrollBar visible al container delle notifiche nel caso sia possibile scrollare
   */
  const handleScrollbarPaddingOnContainer = () => {
    const container = scrollNotifyRef.current
    if (!container) return
    if (workspaceNotifications.length === 0) return container.classList.remove('scrollbar')
    if (container.scrollHeight > container.clientHeight) container.classList.add('scrollbar')
    else container.classList.remove('scrollbar')
  }

  const changeNotifications = async (notifyId?: string) => {
    if (notifyId) addNotificationReaded(notifyId)
    useNotifyStore.getState().updateNotifications()
  }

  /* ____________________ USEEFFECT ____________________ */
  useEffect(() => {
    handleScrollbarPaddingOnContainer()
  })

  useEffect(() => {
    if (firstLoad) {
      changeNotifications()
      setFirstLoad(false)
    }
  }, [])

  return (
    <ScrollNotify forwardedRef={scrollNotifyRef} className="notify-scroll-container">
      {/* __________ NOTICE CARD WITH FILTERS __________ */}
      {hasFilteredNotifications
        ? filteredNotifications.map((el) => {
            return (
              <NotifyCard
                key={el._id + el.workspaceId}
                notice={el}
                changeNotifications={() => changeNotifications(el._id)}
              />
            )
          })
        : workspaceNotifications.length > 0 && <NotifyEmpty forCategories={filters.activeCategory} />}
      {/* __________ EMPTY STATE __________ */}
      {filteredNotifications.length === 0 && <NotifyEmpty />}
    </ScrollNotify>
  )
}

export default ScrollNotifyContainer

const ScrollNotify = styled(Box)`
  flex: 1;
  border-radius: 0;
  overflow-y: scroll;
  gap: 12px;
  padding-right: 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  &.scrollbar {
    padding-right: 6px;
    &::-webkit-scrollbar {
      display: block;
    }
  }
`
