import styled from '@emotion/styled'
import { Form } from 'antd'
import Auth from 'api/Auth'
import asters_logo from 'assets/asters_logo.svg'
import { Box, Button, Input } from 'components'
import { useEffect, useState } from 'react'
import { T } from 'translation/i18n'
import { AppStore, showError, showSuccess } from 'utils'
import { history } from 'utils/router'

const backgroundImages = ['https://asters-general.s3.eu-central-1.amazonaws.com/Asters-background.jpg']

export const RecoverPasswordPage = () => {
  const [activeTab, setActiveTab] = useState(0)
  const [activeBackground, setActiveBackground] = useState(Math.floor(Math.random() * backgroundImages.length))
  const [loading, setLoading] = useState(false)

  //change background image every 5 seconds
  const changeBackground = () => {
    setTimeout(() => {
      setActiveBackground((prev) => (prev + 1) % backgroundImages.length)
      changeBackground()
    }, 10000)
  }

  const onPasswordChange = async ({ password }: { password: string }) => {
    try {
      const token = history.location.search.substring(11)
      await Auth.changePasswordWithToken(token, password)
      const url = window.location.href.split('/').slice(0, -1).join('/')
      window.location.href = `${url}/login`
      showSuccess(T.success.passwordChanged)
    } catch (error) {
      showError(T.error.errorOnChangePasseword)
    }
  }

  useEffect(() => {
    backgroundImages.forEach((backgroundImage) => {
      const img = new Image()
      img.src = backgroundImage
    })

    changeBackground()
  }, [])

  return (
    <LoginPageContainer background={backgroundImages[activeBackground]}>
      <Box center flex>
        <CardContainer>
          <Box flex height={300}>
            <Form
              style={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              onFinish={onPasswordChange}
              autoComplete="off"
            >
              <Box hcenter mv={20}>
                <img style={{ height: 50, width: 158 }} src={asters_logo} alt="" />
              </Box>
              <Form.Item name="password" rules={[{ required: true }]}>
                <Input flex variant="password" />
              </Form.Item>

              <Button mt={50} type="submit" loading={loading}>
                {T.settings.saveThePassword}
              </Button>
            </Form>
          </Box>
        </CardContainer>
      </Box>
    </LoginPageContainer>
  )
}

const LoginPageContainer = styled.div<{ background }>`
  display: flex;
  width: 100%;
  align-items: center;
  padding-left: 5vw;
  justify-content: left;
  background: url(${(props) => props.background}) no-repeat center center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 0.5s ease-in-out;
`

const CardContainer = styled.div`
  display: flex;
  padding: 20px 40px;
  background-color: ${() => AppStore.theme.o.surface};
  border-radius: 16px;
  box-shadow: 0px 20px 20px 0px rgb(87 87 87 / 20%);
  width: 500px;
`
