/* ------------------- IMPORTS */
import styled from '@emotion/styled'
import { Box, Title } from 'components'
import { AppStore, T } from 'utils'
import { SubscriptionProvider } from './SubscriptionProvider'

/* ------------------- INTERFACES  */

interface Props {}

export const CustomizationDisplay = ({}: Props) => {
  return (
    <>
      <Title fontSize={16} style={{ color: AppStore.theme.o.green, marginLeft: '8px' }}>
        {T.settings.customization}
      </Title>
      <CustomizationDisplayContainer center>
        {SubscriptionProvider.customizableOptions.map((customOpt) => (
          <CustomService>{customOpt.label}</CustomService>
        ))}
      </CustomizationDisplayContainer>
    </>
  )
}

const CustomizationDisplayContainer = styled(Box)`
  margin: 4px;
  padding: 12px 42px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 48px;
  max-width: 600px;
  border: 1px dashed ${AppStore.theme.o.darkGrey};
  color: ${() => AppStore.theme.o.black};
`

const CustomService = styled(Box)`
  text-align: left;
  justify-content: center;
  font-size: 12px;
  width: 209px;
  height: 32px;
`
