import styled from '@emotion/styled'
import { Box, BackwardIcon, ForwardIcon } from 'components'
import moment from 'moment'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import './index.css'

interface Props {
  currentMonth: number
  currentYear: number
  months: string[]
  setMonth: (month: number) => void
  setYear: (year: number) => void
}

export function Header({ currentMonth, currentYear, months, setMonth, setYear }: Props) {
  function changeMonthAndyear(isNextPressed: boolean) {
    let newMonth = isNextPressed ? currentMonth + 1 : currentMonth - 1
    //change years too and not only the month
    if (newMonth === -1 || newMonth === 12) {
      if (newMonth < 0) {
        newMonth = 11
        setYear(currentYear - 1)
      } else {
        newMonth = 0
        setYear(currentYear + 1)
      }
    }

    setMonth(newMonth)
  }

  function setTodayDate() {
    const today = moment()
    setMonth(today.month())
    setYear(today.year())
  }

  return (
    <Container>
      <MonthSelector vcenter row justify={'space-around'}>
        <BackwardIcon onClick={() => changeMonthAndyear(false)} />
        <Month center width={'12vw'}>
          {months[currentMonth]}
        </Month>
        <ForwardIcon onClick={() => changeMonthAndyear(true)} />
      </MonthSelector>
      <Today hideBoxShadowOnHover onClick={() => setTodayDate()}>
        {T.calendarPage.today}
      </Today>
    </Container>
  )
}

const MonthSelector = styled(Box)``

const Container = styled(Box)`
  margin-top: 10px;
  margin-bottom: 14px;
  flex-direction: row;
  display: flex;
`

const Month = styled(Box)`
  font-size: 21px;
  font-weight: 700;
  color: ${() => AppStore.theme.o.black || '#fff'};
`

const Today = styled(Box)`
  margin-left: 40px;
  font-weight: 500;
  font-size: 16px;
  color: ${() => AppStore.theme.o.black};
  background: ${() => AppStore.theme.o.surface};
  box-shadow: ${() => AppStore.theme.simpleOuterShadow};
  border-radius: 14px;
  padding-right: 18px;
  padding-left: 18px;
  padding-top: 8px;
  padding-bottom: 8px;
`
