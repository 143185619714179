import { Box } from 'components/Box'
import { Icons } from 'components/Icons'
import { Text } from 'components/Text'
import moment from 'moment'
import { Component } from 'react'
import { T } from 'translation/i18n'
import { IPostVariant } from 'types/PostInterface'
import { AppStore } from 'utils'

interface Props {
  post: IPostVariant
}
interface State {
  socialMedia: any
}
export default class PreviewText extends Component<Props, State> {
  socialMediaIcons = {
    linkedin: <Icons.linkedinPreviewIcon />,
    facebook: <Icons.facebookPreviewIcon />,
    instagram: <Icons.instagramPreviewIcon fill={AppStore.darkTheme ? 'white' : '#2E3A59'} />,
    twitter: <Icons.twitterPreviewIcon />,
    google: <Icons.googleMyBusinnesPreviewIcon />,
  }

  state: State = {
    socialMedia: ['linkedin', 'facebook', 'instagram', 'x', 'google'],
  }

  render() {
    const { post } = this.props
    return (
      <>
        <Box ml={40}>
          <Box mb={27}>
            {post.data.medias.length > 0 && (
              <>
                <Box mt={11}>
                  <Text fontSize={21} weight={700}>
                    {T.postPage.textOfYourPost}
                  </Text>
                </Box>
              </>
            )}
            {post.data.medias.length === 0 && (
              <Box
                bgPrimaryColor
                row
                vcenter
                justify={'space-around'}
                shadow={AppStore.theme.simpleOuterShadow}
                height={56}
                mb={16}
              >
                {Object.values(this.socialMediaIcons)[this.state.socialMedia.indexOf(post.info.account.provider)]}
              </Box>
            )}
          </Box>
          <Box style={{ minHeight: 503, minWidth: 379 }} pl={2} height={503} width={379}>
            <Text
              style={{
                width: '100%',
                whiteSpace: 'pre-line',
                maxHeight: 490,
                minHeight: 50,
                minWidth: 171,
                overflow: 'auto',
                wordBreak: 'break-word',
                borderRadius: 14,
              }}
            >
              <p style={{ backgroundColor: AppStore.theme.o.lightGrey, padding: 20 }}>{post.data.text}</p>
            </Text>
          </Box>
          <Box row vcenter>
            <Text fontSize={16} weight={500} style={{ marginRight: 32 }}>
              {T.DashboardPage.data_and_time}
            </Text>
            <Box style={{ border: `1px dashed ${AppStore.theme.o.darkGrey}` }} ph={16} pv={4}>
              <Text weight={700}>
                {moment(post.data.date).date().toString() +
                  ' / ' +
                  (moment(post.data.date).month() + 1).toString() +
                  ' / ' +
                  moment(post.data.date).year().toString() +
                  '  ' +
                  moment(post.data.date).hour().toString().padStart(2, '0') +
                  ':' +
                  moment(post.data.date).minutes().toString().padStart(2, '0')}
              </Text>
            </Box>
          </Box>
        </Box>
      </>
    )
  }
}
