import React from 'react'
import ReactDOM from 'react-dom'
import 'antd/dist/antd.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import itIT from 'antd/lib/locale/it_IT'
import 'moment/locale/it'
import { MobileDetection } from 'MobileDetection'
import { ToastContainer } from 'react-toastify'
import { Demo } from 'pages'
import RiminiPage from 'components/RiminiPage/RiminiPage'
import { initializeSentry } from 'utils/SentryService'
import PushNotification from 'components/PushNotification'
import { isVisible } from 'utils/CheckVisibleElement'

initializeSentry()

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={itIT}>
      {window.location.pathname.includes('/demo') ? (
        <Demo />
      ) : window.location.pathname.includes('/rimini') ? (
        <RiminiPage />
      ) : /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) &&
        isVisible('index.mobiledetection') ? (
        <MobileDetection />
      ) : (
        <App />
      )}
      <ToastContainer />
      <PushNotification />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
