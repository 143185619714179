import styled from '@emotion/styled'
import { Box, Button, Icons, PreviewPost, Text } from 'components'
import PopConfirm from 'components/PopConfirm'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { T, __ } from 'translation/i18n'
import { PostStatus } from 'types'
import { AppStore, copyToClipboard, showInfo, showSuccess } from 'utils'
import PreviewText from './PreviewText'
import { Image } from 'antd'
import { TRACKEREVENTS, getMediaFileFormats, getMixpanelPostData, getSocialExtras } from 'utils/eventsTracker'
import { Tracker } from 'api/Tracker'
import { useJourneyStore } from 'stores/JourneyStore'
import { usePostStore } from 'stores/PostStore'
import { IPostVariant } from 'types/PostInterface'

import { updateActive, updateGlobal, updatePost } from 'utils/PostUtils/Post'
import { onClickSocialCard } from 'utils/PostUtils/SocialPost'
import { MixpanelPostRecycledData } from 'utils/eventsTracker/posts/postInterfaces'

const SocialIcons = require('assets/icons')

interface Props {
  post: IPostVariant
  onDelete: any
  index: number
  setIsSelected: (post: IPostVariant) => void
  isSelected: boolean
  alwaysHover: boolean
  goToPost?: (bol: boolean) => void
}

export const CalendarPost = ({ post, onDelete, index, setIsSelected, isSelected, alwaysHover, goToPost }: Props) => {
  /* ------------------------------- VARIABLES */
  const hour =
    moment(post.data.date).hour().toString().padStart(2, '0') +
    ':' +
    moment(post.data.date).minutes().toString().padStart(2, '0')

  const firstMedia = post.data.medias?.[0]

  /* ------------------------------- STATE */
  const [hover, setHover] = useState(false)

  /* ------------------------------- METHODS */

  const openDraftPost = (e) => {
    e.stopPropagation()

    const reusablePost = post

    const postRecycledData: MixpanelPostRecycledData = {
      social_channel: reusablePost.info.account.provider,
      has_caption: reusablePost.data.text.length > 0,
      post_status: post.data.status,
      file_format: getMediaFileFormats(reusablePost),
      has_social_extras: Object.keys(getSocialExtras(reusablePost)).length > 0,
    }

    delete reusablePost.data?.status
    delete reusablePost.data?.statusMessage
    delete reusablePost.data?._id
    delete reusablePost.creationDate
    // delete reusablePost._id  //@ serve per editare un post una volta schedulato

    // Gestione delle date del post
    const reusablePostDate = reusablePost.data.date!
    const now = new Date().toISOString()

    // Se la data è nel passato la elimina altrimenti la mantiene
    // @ts-ignore
    if (reusablePostDate < now) {
      reusablePost.data.date = undefined
    } else {
      reusablePost.data.date = reusablePostDate
    }

    // Aggiunge il post al crea post

    const alreadyAdded = onClickSocialCard(post.info.account, undefined, reusablePost.variantId, reusablePost._id)

    if (alreadyAdded === 'alreadyAdded') {
      return
    }

    updatePost(reusablePost.variantId, reusablePost.data)

    if (goToPost) {
      AppStore.closeAllModal()
      Tracker.trackEvent(TRACKEREVENTS.POST_RECYCLED, postRecycledData)
      goToPost(true)
    }
  }

  const closeAndOpenModal = async () => {
    try {
      Array.from(document.getElementsByClassName('ant-modal-root') as HTMLCollectionOf<HTMLElement>)[0].style.opacity =
        '0'
      openPreviewModal()
    } catch (err) {
      console.error(err)
    }
  }

  /* ------------------------------- MODALS */
  const openPreviewModal = () => {
    // const mixpanelData = getMixpanelPostData(post)
    // Tracker.trackEvent(TRACKEREVENTS.POST_PREVIEW_VISITED, mixpanelData)

    AppStore.openModal({
      onCancel: async () => {
        try {
          Array.from(
            document.getElementsByClassName('ant-modal-root') as HTMLCollectionOf<HTMLElement>
          )[0].style.opacity = '100'
        } catch (err) {
          console.error(err)
        }
      },
      id: 'preview-modal',
      body: (
        <Box center>
          <Box
            style={{
              minHeight: 679,
              minWidth: post.data.medias !== undefined && post.data.medias?.length > 0 ? 923 : 584,
            }}
            ph={64}
            pv={40}
          >
            <Box row>
              {post.data.medias !== undefined && post.data.medias?.length > 0 && <PreviewPost post={post} separated />}
              <PreviewText post={post} />
            </Box>
          </Box>
          <InsertContainer>
            <Button height={65} width={220} onClick={openDraftPost} variant="primary">
              {T.calendarPage.reuse}
            </Button>
          </InsertContainer>
        </Box>
      ),
      width: post.data.medias !== undefined && post.data.medias?.length > 0 ? 923 : 504,
    })
  }

  /* ------------------------------- USE-EFFECT */
  useEffect(() => {
    if (alwaysHover) {
      setHover(true)
    } else if (hover) {
      setHover(false)
    }
  }, [alwaysHover])

  return (
    <>
      {post.data.status !== 'draft' && (
        <Box
          width={208}
          style={{
            marginBottom: 30,
            position: 'relative',
            marginLeft: 32,
            marginRight: 32,
          }}
          removeHover
        >
          <Container
            onClick={(e) => {
              // fix al click del pulsante di selezione post in modale di preview

              if (e.target.nodeName === 'rect' || e.target.nodeName === 'svg' || e.target.nodeName === 'path') return
              if (post.data.status !== 'error' && post.data.status !== 'draft' && post.data.status !== 'planned')
                closeAndOpenModal()
            }}
            onMouseEnter={() => {
              if (alwaysHover) return
              setHover(true)
            }}
            onMouseLeave={() => {
              if (alwaysHover) return
              setHover(false)
            }}
          >
            {post.info.account && (
              <>
                {hover && (
                  <BOX
                    center
                    style={{
                      borderRadius: 14,
                      width: 208,
                      height: 208,
                      position: 'absolute',
                      zIndex: 1,
                    }}
                    shadow="none"
                  >
                    {post.data.status !== 'error' && post.data.status !== 'planned' && (
                      <Box style={{ backgroundColor: AppStore.theme.o.darkGrey }} pv={4} ph={8}>
                        <Text
                          fontSize={21}
                          weight={700}
                          color={AppStore.theme.o.black}
                          onClick={closeAndOpenModal}
                          style={{ opacity: 1 }}
                        >
                          {T.calendarPage.preview}
                        </Text>
                      </Box>
                    )}
                  </BOX>
                )}

                {hover && (
                  <Box
                    absolute
                    ml={8}
                    mt={8}
                    width={40}
                    height={40}
                    style={{
                      backgroundColor: 'transparent',
                      borderRadius: 8,
                      zIndex: 199,
                    }}
                    center
                    onClick={() => setIsSelected(post)}
                  >
                    {isSelected ? (
                      <Icons.selectedBox style={{ position: 'absolute', top: 2, left: 2 }} />
                    ) : (
                      <Icons.notSelectedBox style={{ position: 'absolute', top: 2, left: 2 }} />
                    )}
                  </Box>
                )}

                {(post.data.status === 'error' || post.data.status === 'planned') &&
                  hover &&
                  !isSelected &&
                  !alwaysHover && (
                    <Box absolute width={208} height={208} center>
                      <SpecialButton
                        style={{ marginTop: 12, zIndex: 4 }}
                        size="smaller"
                        variant="secondary-small"
                        width={110}
                        onClick={openPreviewModal}
                        onMouseEnter={() => setHover(true)}
                      >
                        Preview
                      </SpecialButton>
                      <SpecialButton
                        style={{ marginTop: 12, zIndex: 4, color: AppStore.theme.o.black }}
                        size="smaller"
                        variant="neutral"
                        width={110}
                        onClick={(e) => openDraftPost(e)}
                        onMouseEnter={() => setHover(true)}
                      >
                        {T.settings.modify}
                      </SpecialButton>
                      <PopConfirm
                        title={<p>{T.calendarPage.sureToRemovePost}</p>}
                        okText={T.settings.confirm}
                        onConfirm={() => {
                          onDelete(post._id)
                          setHover(false)
                          AppStore.closeAllModal()
                        }}
                      >
                        <SpecialButton
                          style={{ marginTop: 12, zIndex: 4 }}
                          size="smaller"
                          variant="delete-small"
                          width={110}
                          onMouseEnter={() => setHover(true)}
                        >
                          {T.postPage.delete}
                        </SpecialButton>
                      </PopConfirm>
                    </Box>
                  )}

                {firstMedia?.url === undefined && (
                  <Box
                    style={{
                      height: 208,
                      width: 208,
                      transition: '0.3s',
                      opacity: hover ? 0.6 : 1,
                      marginBottom: 16,
                    }}
                  >
                    <Icons.noImage style={{ width: 208, height: 208 }} />
                  </Box>
                )}
                {firstMedia?.url !== undefined && (
                  <>
                    {!firstMedia?.mimetype?.includes('video') && (
                      <img
                        src={firstMedia?.url ?? ''}
                        width={208}
                        height={208}
                        style={{ borderRadius: 14, pointerEvents: 'none', marginBottom: 16, objectFit: 'cover' }}
                        // preview={false}
                      />
                    )}
                    {firstMedia?.mimetype?.includes('video') && (
                      <Video
                        controls
                        width={208}
                        height={208}
                        style={{ minHeight: 208, objectFit: 'cover' }}
                        src={firstMedia?.url}
                      />
                    )}
                  </>
                )}
                <Hour>{hour}</Hour>
                <Description>{post.data.text}</Description>
                <Status
                  status={post.data.status ?? 'error'}
                  onClick={() => {
                    if (post.data.status === 'error') {
                      showInfo(T.info.copied_error_in_clipboard)
                      copyToClipboard(post.data.statusMessage)
                    }
                  }}
                >
                  {post.data.status &&
                    (__(T.status[post.data.status]) === 'Draft' ? 'Bozza' : __(T.status[post.data.status]))}
                </Status>
                <Box style={{ position: 'absolute', right: -58, top: -10, alignItems: 'center' }}>
                  <Images
                    url={SocialIcons[post.info.account.provider]}
                    height={'30px'}
                    width={'35px'}
                    style={{ zIndex: 99 }}
                  />
                  <Images
                    url={post.info.account.picture}
                    height={'30px'}
                    width={'30px'}
                    radius={'30px'}
                    style={{ zIndex: 99 }}
                  />
                </Box>
              </>
            )}
          </Container>
        </Box>
      )}
    </>
  )
}

const Container = styled.div<{ onClick?: (e) => void }>`
  width: 160px;
  position: relative;
  margin-right: 20px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`
const Images = styled.div<{ url: string; height?: any; width?: any; radius?: any }>`
  width: ${({ width }) => width ?? '208px'};
  height: ${({ height }) => height ?? '208px'};
  border-radius: ${({ radius }) => radius ?? '14px'};
  margin-bottom: 12px;
  background: ${({ url }) => 'url(' + url + ') no-repeat center center'};
  background-size: cover;
`
const Video = styled.video<{ heigth?: any; width?: any; radius?: any }>`
  width: ${({ width }) => width ?? '160px'};
  height: ${({ heigth }) => heigth ?? '160px'};
  border-radius: ${({ radius }) => radius ?? '14px'};
  margin-bottom: 12px;
  background-size: cover;
`

const Hour = styled.p`
  font-weight: bold;
  font-size: 21px;
  line-height: 27px;
  align-items: center;
  letter-spacing: 0.01em;
  color: ${() => AppStore.theme.o.black};
`

const Description = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: ${() => AppStore.theme.o.black};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StatusColor = {
  planned: AppStore.theme.o.blueOnLight,
  published: AppStore.theme.o.green,
  draft: AppStore.theme.o.darkGrey,
  error: AppStore.theme.o.red,
  publishing: AppStore.theme.o.yellow,
}

const Status = styled.p<{ status: PostStatus }>`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: ${({ status }) => StatusColor[status]};
`

interface PropsIn {
  types: number
}

const BOX = styled(Box)`
  /* filter: brightness(0.35); */
  /* box-shadow: rgba(0, 0, 0, 0.7); */
  /* box-shadow: inset 0 0 0 1000px rgba(8, 7, 7, 0.6); */
  background: linear-gradient(to bottom, #000000 0%, transparent 50%);
  opacity: 1;
`

const SpecialButton = styled(Button)`
  /* position: absolute; */
  /* margin-left: 26px; */
  width: 115px;
  height: 36px;
  border-radius: 14px;
  opacity: 100;
`

const InsertContainer = styled(Box)`
  width: 100px;
  position: absolute;
  bottom: -80px;
  right: 50%;
`
