import { Page } from 'components'
import { T } from 'translation/i18n'
import { AppStore } from 'utils'
import { MediaContainer, MediaContainerComp } from './MediaContainer'

export const MediaPage = (props) => {
  const { noHeader } = props

  return (
    <Page noHeader={noHeader} title={T.mediaPage.media}>
      <MediaContainerComp style={{ marginTop: 20, marginBottom: 20, boxShadow: AppStore.theme.bigOuterShadowNoBox }} />
    </Page>
  )
}
