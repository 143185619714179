import styled from '@emotion/styled'
import { Box, Button, Icons, Text } from 'components'
import { AppStore, T, urlToBlob } from 'utils'

import background from './../../../assets/BackEdit.jpeg'
import { openEditor } from 'components/Modal/ModalManager'
import { CrelloResponse, Media } from 'types'
import { useState } from 'react'
import Medias from 'api/Medias'
import { Tracker } from 'api/Tracker'
import { TRACKEREVENTS, getEditorFileSaveData } from 'utils/eventsTracker'
import { useCreatePostStore } from 'stores/CreatePostStore'
import { updateActive } from 'utils/PostUtils/Post'
import { IPostData } from 'types/PostInterface'

export default function EditorButtom() {
  const onCrelloSave = async (crelloResponse: CrelloResponse) => {
    const { activePost } = useCreatePostStore.getState()
    let activePostMedias: Media[] | undefined = undefined

    const path = `${AppStore.workspace._id}/`
    const promises: Promise<any>[] = []

    const buffer = await urlToBlob(crelloResponse.url)
    const formData = new FormData()

    // Update the formData object
    formData.append('file', buffer, `${crelloResponse.projectName}.${crelloResponse.extension}`)
    formData.append('path', path.replace(`${AppStore.workspace._id}/`, ''))
    promises.push(Medias.createFile(formData) ?? Promise.resolve())
    await Promise.all(promises)

    /*  this.refreshMedia() */
    if ('_id' in activePost && activePost._id === 'GLOBAL') {
      const activePostData = activePost as IPostData
      activePostMedias = activePostData.medias
      updateActive({ medias: [...activePostMedias, { url: crelloResponse.url, type: 'image' }] })
    } else if ('variantId' in activePost) {
      activePostMedias = activePost.data.medias
      updateActive({ medias: [...activePostMedias, { url: crelloResponse.url, type: 'image' }] })
    } else {
      updateActive({ medias: [{ url: crelloResponse.url, type: 'image' }] })
    }

    useCreatePostStore.getState().setRefreshMediaContent(true)

    Tracker.trackEvent(TRACKEREVENTS.EXPORT_GRAPHIC_EDITOR_EXPORT_FILE, getEditorFileSaveData(crelloResponse))
  }

  return (
    <OpenEditorButton
      style={{
        minHeight: 40,
        backgroundImage: `url(${background})`,
        objectFit: 'scale-down',
        backgroundSize: '110%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      onClick={async () => {
        const check = await AppStore.checkPermission('media_editor')

        check && openEditor([], onCrelloSave)
      }}
      variant="neutral"
    >
      <Icons.medias fill={'white'} width={28} />
      <Text style={{ color: 'white', marginRight: 8 }}>{T.mediaPage.openEditor}</Text>
    </OpenEditorButton>
  )
}

const OpenEditorButton = styled(Button)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Aktiv Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 8px;
`
